import { DateTime } from 'luxon';

import { cleanupLatePolicyForGradingPolicy, comparePrepPracticeAssessments, getDefaultDates } from 'utils/assessmentFunctions';
import { CourseAssessmentPresetApi } from 'types/backend/courseAssessmentPresets.types';
import {
  AssessmentApiBase,
  AssessTypeEnum,
  GradingPolicyEnum,
  SummativeAssessmentApi,
} from 'types/backend/assessments.types';
import { PresetHasChanged } from './CourseDetailsController.types';
import { YesNo } from 'types/backend/shared.types';


// make sure the assessment late policy is valid for the grading policy
// if the grading policy has changed, all assessments need an updated grading policy
// but they only need an updated late policy if the grading policy forced a late policy change
// and the assessment did not already have that late policy
// or there was also a late policy change
function reconcileLatePolicyWithGradingPolicyAndDueDateChanges({
  latePolicyHasChanged,
  gradingPolicyHasChanged,
  latePenaltyHasChanged,
  lateDateOffsetHasChanged,
  assessment,
  newPresetGradingPolicy,
  newLateDate,
  newPresetLatePenalty,
  newPresetLatePolicy,
  dueTimeHasChanged,
}: {
  latePolicyHasChanged: boolean
  gradingPolicyHasChanged: boolean
  latePenaltyHasChanged: boolean
  lateDateOffsetHasChanged: boolean
  assessment: AssessmentApiBase
  newPresetGradingPolicy: GradingPolicyEnum
  newLateDate: Date | null
  newPresetLatePenalty: number
  newPresetLatePolicy: YesNo
  dueTimeHasChanged?: boolean
}) {
  let result: { lateDate: Date | null; latePenalty: number | null; latePolicy: YesNo }
    = { lateDate: null, latePenalty: null, latePolicy: YesNo.No };
  if (!gradingPolicyHasChanged && latePolicyHasChanged && newPresetLatePolicy === YesNo.No) {
    // easy case, set the late policy to no and related values to null
    result = { latePolicy: YesNo.No, lateDate: null, latePenalty: null };
  } else if (!gradingPolicyHasChanged && latePolicyHasChanged && newPresetLatePolicy === YesNo.Yes) {
    // in this case we need new late policy values unless the assessment is `no_points` cleanupLatePolicyForGradingPolicy will handle that, dueTime can be ignored because it was included in determining the new lateDate
    result = cleanupLatePolicyForGradingPolicy<Date>({ ...assessment, lateDate: newLateDate, latePenalty: newPresetLatePenalty, latePolicy: newPresetLatePolicy });
  } else if (gradingPolicyHasChanged && newPresetGradingPolicy === GradingPolicyEnum.NoPoints) {
    // if the grading policy has changed to no points, then use the values from cleanupLatePolicyForGradingPolicy. new latePolicy is off by default
    result = cleanupLatePolicyForGradingPolicy<Date>({ ...assessment, gradingPolicy: newPresetGradingPolicy, lateDate: newLateDate, latePenalty: newPresetLatePenalty, latePolicy: newPresetLatePolicy });
  } else if (gradingPolicyHasChanged && newPresetGradingPolicy !== GradingPolicyEnum.NoPoints && !latePolicyHasChanged) {
    // if the grading policy has changed to points, and the late policy has not changed, then use the values from cleanupLatePolicyForGradingPolicy, which could include a new lateDate for an assessment if the dueTime changed
    const tempDate = assessment.lateDate ? new Date(assessment.lateDate) : null;
    result = cleanupLatePolicyForGradingPolicy<Date>({ ...assessment, gradingPolicy: newPresetGradingPolicy, lateDate: tempDate, latePenalty: assessment.latePenalty, latePolicy: assessment.latePolicy });
  } else if (gradingPolicyHasChanged && latePolicyHasChanged) {
    //if the special cases fall through
    result = cleanupLatePolicyForGradingPolicy<Date>({ ...assessment, gradingPolicy: newPresetGradingPolicy, lateDate: newLateDate, latePenalty: newPresetLatePenalty, latePolicy: newPresetLatePolicy });
  } else if (!gradingPolicyHasChanged && !latePolicyHasChanged && assessment.latePolicy === YesNo.Yes) {
    result.lateDate = lateDateOffsetHasChanged || dueTimeHasChanged ? newLateDate : new Date(assessment.lateDate as string); //ok to cast because we know there is a late date for this assessment
    result.latePenalty = latePenaltyHasChanged ? newPresetLatePenalty : assessment.latePenalty;
    result.latePolicy = YesNo.Yes;
  }

  const cleanedUpNewLateDate = !!result.lateDate ? DateTime.fromJSDate(result.lateDate).toUTC().toISO() : result.lateDate;
  const cleanedUpNewLatePenalty = result.latePenalty;
  const cleanedUpNewLatePolicy = result.latePolicy;
  return { cleanedUpNewLateDate, cleanedUpNewLatePenalty, cleanedUpNewLatePolicy };
}

function getAssessmentsToUpdateForCoursePresets(
  availableAssessments: Array<AssessmentApiBase>,
  preset: CourseAssessmentPresetApi,
  presetDelta: PresetHasChanged<CourseAssessmentPresetApi>,
  timeZone: string
) {
  const changedAssessments: Array<AssessmentApiBase> = [];
  for (const assessment of availableAssessments) {
    if ([AssessTypeEnum.PracticeTest, AssessTypeEnum.Prep].includes(assessment.assessType)) {
      continue; // will be handled with the summative assessment
    }
    if (assessment.assessType === AssessTypeEnum.Summative) {
      const { assessType, dueDate } = assessment;
      const { due: newDue, prepLate: newPrepLate, practiceLate: newPracticeLate } = getDefaultDates(assessType, timeZone, dueDate, preset);
      const { prep, practiceTest } = assessment as SummativeAssessmentApi;

      const { cleanedUpNewLateDate: cleanedUpNewPrepLateDate, cleanedUpNewLatePenalty: cleanedUpNewPrepLatePenalty, cleanedUpNewLatePolicy: cleanedUpNewPrepLatePolicy } = reconcileLatePolicyWithGradingPolicyAndDueDateChanges({
        latePolicyHasChanged: presetDelta.studyPathPrepLatePolicyHasChanged,
        gradingPolicyHasChanged: presetDelta.studyPathPrepGradingPolicyHasChanged,
        latePenaltyHasChanged: presetDelta.studyPathPrepLatePenaltyHasChanged,
        lateDateOffsetHasChanged: presetDelta.studyPathPrepLateDateOffsetHasChanged,
        assessment: prep,
        newPresetGradingPolicy: preset.studyPathPrepGradingPolicy,
        newLateDate: newPrepLate as Date,
        newPresetLatePenalty: preset.studyPathPrepLatePenalty,
        newPresetLatePolicy: preset.studyPathPrepLatePolicy,
        dueTimeHasChanged: presetDelta.studyPathDueTimeHasChanged,
      });

      const { cleanedUpNewLateDate: cleanedUpNewPracticeLateDate, cleanedUpNewLatePenalty: cleanedUpNewPracticeLatePenalty, cleanedUpNewLatePolicy: cleanedUpNewPracticeLatePolicy } = reconcileLatePolicyWithGradingPolicyAndDueDateChanges({
        latePolicyHasChanged: presetDelta.studyPathPracticeLatePolicyHasChanged,
        gradingPolicyHasChanged: presetDelta.studyPathPracticeGradingPolicyHasChanged,
        latePenaltyHasChanged: presetDelta.studyPathPracticeLatePenaltyHasChanged,
        lateDateOffsetHasChanged: presetDelta.studyPathPracticeLateDateOffsetHasChanged,
        assessment: practiceTest,
        newPresetGradingPolicy: preset.studyPathPracticeGradingPolicy,
        newLateDate: newPracticeLate as Date,
        newPresetLatePenalty: preset.studyPathPracticeLatePenalty,
        newPresetLatePolicy: preset.studyPathPracticeLatePolicy,
        dueTimeHasChanged: presetDelta.studyPathDueTimeHasChanged,
      });

      const updatedStudyPathAssessmentGroup = {
        ...assessment,
        dueDate: presetDelta.studyPathDueTimeHasChanged ? DateTime.fromJSDate(newDue).toUTC().toISO() : assessment.dueDate,
        prep: {
          ...prep,
          gradingPolicy: presetDelta.studyPathPrepGradingPolicyHasChanged ? preset.studyPathPrepGradingPolicy : prep.gradingPolicy,
          freeAttempts: presetDelta.studyPathPrepFreeAttemptsHasChanged ? preset.studyPathPrepFreeAttempts : prep.freeAttempts,
          pointPenalty: presetDelta.studyPathPrepPointPenaltyHasChanged ? preset.studyPathPrepPointPenalty : prep.pointPenalty,
          latePolicy: cleanedUpNewPrepLatePolicy,
          lateDate: cleanedUpNewPrepLateDate,
          latePenalty: cleanedUpNewPrepLatePenalty,
        },
        practiceTest: {
          ...practiceTest,
          gradingPolicy: presetDelta.studyPathPracticeGradingPolicyHasChanged ? preset.studyPathPracticeGradingPolicy : practiceTest.gradingPolicy,
          freeAttempts: presetDelta.studyPathPracticeFreeAttemptsHasChanged ? preset.studyPathPracticeFreeAttempts : practiceTest.freeAttempts,
          pointPenalty: presetDelta.studyPathPracticePointPenaltyHasChanged ? preset.studyPathPracticePointPenalty : practiceTest.pointPenalty,
          latePolicy: cleanedUpNewPracticeLatePolicy,
          lateDate: cleanedUpNewPracticeLateDate,
          latePenalty: cleanedUpNewPracticeLatePenalty,
        },
      };
      if (hasAssessmentChanged(assessment, updatedStudyPathAssessmentGroup)
        || comparePrepPracticeAssessments(prep, updatedStudyPathAssessmentGroup.prep)
        || comparePrepPracticeAssessments(practiceTest, updatedStudyPathAssessmentGroup.practiceTest)) {
        changedAssessments.push(updatedStudyPathAssessmentGroup);
      }
    } else {
      // preclass, readiness or HW.
      const { assessType, dueDate } = assessment;
      const { open: newDefaultOpen, due: newDue, late: newLate } = getDefaultDates(assessType, timeZone, dueDate, preset);
      if ([AssessTypeEnum.Preclass, AssessTypeEnum.Readiness].includes(assessType)) {

        const newOpen = updateDateAndOrTime(assessment.openDate, newDefaultOpen, timeZone, presetDelta.preclassOpenDateOffsetHasChanged, presetDelta.preclassOpenTimeHasChanged);
        const { cleanedUpNewLateDate, cleanedUpNewLatePenalty, cleanedUpNewLatePolicy } = reconcileLatePolicyWithGradingPolicyAndDueDateChanges({
          latePolicyHasChanged: presetDelta.preclassLatePolicyHasChanged,
          gradingPolicyHasChanged: presetDelta.preclassGradingPolicyHasChanged,
          latePenaltyHasChanged: presetDelta.preclassLatePenaltyHasChanged,
          lateDateOffsetHasChanged: presetDelta.preclassLateDateOffsetHasChanged,
          assessment,
          newPresetGradingPolicy: preset.preclassGradingPolicy,
          newLateDate: newLate as Date,
          newPresetLatePenalty: preset.preclassLatePenalty,
          newPresetLatePolicy: preset.preclassLatePolicy,
          dueTimeHasChanged: presetDelta.preclassDueTimeHasChanged,
        });
        const updatedAssessment = {
          ...assessment,
          dueDate: presetDelta.preclassDueTimeHasChanged ? DateTime.fromJSDate(newDue).toUTC().toISO() : assessment.dueDate,
          openDate: newOpen,
          gradingPolicy: presetDelta.preclassGradingPolicyHasChanged ? preset.preclassGradingPolicy : assessment.gradingPolicy,
          freeAttempts: presetDelta.preclassFreeAttemptsHasChanged ? preset.preclassFreeAttempts : assessment.freeAttempts,
          pointPenalty: presetDelta.preclassPointPenaltyHasChanged ? preset.preclassPointPenalty : assessment.pointPenalty,
          latePolicy: cleanedUpNewLatePolicy,
          lateDate: cleanedUpNewLateDate,
          latePenalty: cleanedUpNewLatePenalty,
        };
        if (hasAssessmentChanged(assessment, updatedAssessment)) {
          changedAssessments.push(updatedAssessment);
          console.debug('updatedAssessment:', updatedAssessment);
        }
      } else {
        const newOpen = updateDateAndOrTime(assessment.openDate, newDefaultOpen, timeZone, presetDelta.homeworkOpenDateOffsetHasChanged, presetDelta.homeworkOpenTimeHasChanged);
        const newHomeworkLatePenalty = preset.homeworkLatePenalty;

        const { cleanedUpNewLateDate, cleanedUpNewLatePenalty, cleanedUpNewLatePolicy } = reconcileLatePolicyWithGradingPolicyAndDueDateChanges({
          latePolicyHasChanged: presetDelta.homeworkLatePolicyHasChanged,
          gradingPolicyHasChanged: presetDelta.homeworkGradingPolicyHasChanged,
          latePenaltyHasChanged: presetDelta.homeworkLatePenaltyHasChanged,
          lateDateOffsetHasChanged: presetDelta.homeworkLateDateOffsetHasChanged,
          assessment,
          newPresetGradingPolicy: preset.homeworkGradingPolicy,
          newLateDate: newLate as Date,
          newPresetLatePenalty: newHomeworkLatePenalty,
          newPresetLatePolicy: preset.homeworkLatePolicy,
          dueTimeHasChanged: presetDelta.homeworkDueTimeHasChanged,
        });
        const updatedAssessment = {
          ...assessment,
          dueDate: presetDelta.homeworkDueTimeHasChanged ? DateTime.fromJSDate(newDue).toUTC().toISO() : assessment.dueDate,
          openDate: newOpen,
          gradingPolicy: presetDelta.homeworkGradingPolicyHasChanged ? preset.homeworkGradingPolicy : assessment.gradingPolicy,
          freeAttempts: presetDelta.homeworkFreeAttemptsHasChanged ? preset.homeworkFreeAttempts : assessment.freeAttempts,
          pointPenalty: presetDelta.homeworkPointPenaltyHasChanged ? preset.homeworkPointPenalty : assessment.pointPenalty,
          latePolicy: cleanedUpNewLatePolicy,
          lateDate: cleanedUpNewLateDate,
          latePenalty: cleanedUpNewLatePenalty,
        };
        if (hasAssessmentChanged(assessment, updatedAssessment)) {
          changedAssessments.push(updatedAssessment);
        }
      }
    }
  }
  return changedAssessments;
}

function getPresetLatePolicyDelta({
  existingGradingPolicy,
  newGradingPolicy,
  dueTimeHasChanged,
  latePolicyHasChanged,
  lateDateOffsetHasChanged,
  latePenaltyHasChanged,
  existingLatePolicy,
}: {
  existingGradingPolicy: GradingPolicyEnum
  newGradingPolicy: GradingPolicyEnum
  dueTimeHasChanged: boolean
  latePolicyHasChanged: boolean
  lateDateOffsetHasChanged: boolean
  latePenaltyHasChanged: boolean
  existingLatePolicy: YesNo
}) {
  const isLatePolicyAffectedByGradingPolicyChange =
    existingGradingPolicy !== GradingPolicyEnum.NoPoints && newGradingPolicy === GradingPolicyEnum.NoPoints && existingLatePolicy === YesNo.Yes;  // late policy it affected when going to no points if there is an existing late policy
  const modifiedLatePolicyHasChanged = isLatePolicyAffectedByGradingPolicyChange || latePolicyHasChanged; // grading policy or direct change to late policy toggle could affect this value
  const doPresetChangesAffectLateDateOffset = modifiedLatePolicyHasChanged || lateDateOffsetHasChanged; // grading policy or late policy toggle or assessment due time or direct change to late date offset could affect this value
  const modifiedLatePenaltyHasChanged = modifiedLatePolicyHasChanged || latePenaltyHasChanged;
  return { modifiedLatePolicyHasChanged, modifiedLateDateOffsetHasChanged: doPresetChangesAffectLateDateOffset, modifiedLatePenaltyHasChanged };
}

function getPresetDelta(existingPreset: CourseAssessmentPresetApi, newPreset: CourseAssessmentPresetApi): PresetHasChanged<CourseAssessmentPresetApi> {
  // get delta of preset values that have changed
  const presetDeltaPartial: Partial<PresetHasChanged<CourseAssessmentPresetApi>> = {};
  const attributesToExclude = ['id', 'createdAt', 'updatedAt'];
  for (const key in newPreset) {
    const typedKey = key as keyof CourseAssessmentPresetApi;
    if (typedKey in existingPreset && !attributesToExclude.includes(key)) {
      if (typeof existingPreset[typedKey] === 'object' && existingPreset[typedKey] !== null) {
        console.warn(`Value for ${typedKey} is an object, not a primitive, delta will be calculated incorrectly`); //future proofing
      }
      const hasChangedKey = `${key}HasChanged` as keyof typeof presetDeltaPartial;
      presetDeltaPartial[hasChangedKey] = newPreset[typedKey] !== existingPreset[typedKey];
    }
  }
  const presetDelta = presetDeltaPartial as PresetHasChanged<CourseAssessmentPresetApi>;

  // calculate dependent deltas for homework
  const homeworkGradingHasChanged = hasGradingChanged(presetDelta, AssessTypeEnum.Homework);
  const { modifiedLatePolicyHasChanged: homeworkLatePolicyHasChanged, modifiedLateDateOffsetHasChanged: homeworkLateDateOffsetHasChanged, modifiedLatePenaltyHasChanged: homeworkLatePenaltyHasChanged } =
    getPresetLatePolicyDelta({
      existingGradingPolicy: existingPreset.homeworkGradingPolicy,
      newGradingPolicy: newPreset.homeworkGradingPolicy,
      dueTimeHasChanged: presetDelta.homeworkDueTimeHasChanged,
      latePolicyHasChanged: presetDelta.homeworkLatePolicyHasChanged,
      lateDateOffsetHasChanged: presetDelta.homeworkLateDateOffsetHasChanged,
      latePenaltyHasChanged: presetDelta.homeworkLatePenaltyHasChanged,
      existingLatePolicy: existingPreset.homeworkLatePolicy,
    });

  // calculate dependent deltas for preclass
  const preclassGradingHasChanged = hasGradingChanged(presetDelta, AssessTypeEnum.Preclass);
  const { modifiedLatePolicyHasChanged: preclassLatePolicyHasChanged, modifiedLateDateOffsetHasChanged: preclassLateDateOffsetHasChanged, modifiedLatePenaltyHasChanged: preclassLatePenaltyHasChanged } =
    getPresetLatePolicyDelta({
      existingGradingPolicy: existingPreset.preclassGradingPolicy,
      newGradingPolicy: newPreset.preclassGradingPolicy,
      dueTimeHasChanged: presetDelta.preclassDueTimeHasChanged,
      latePolicyHasChanged: presetDelta.preclassLatePolicyHasChanged,
      lateDateOffsetHasChanged: presetDelta.preclassLateDateOffsetHasChanged,
      latePenaltyHasChanged: presetDelta.preclassLatePenaltyHasChanged,
      existingLatePolicy: existingPreset.preclassLatePolicy,
    });

  // calculate studyPathPrep dependent deltas
  const studyPathPrepGradingHasChanged = hasGradingChanged(presetDelta, AssessTypeEnum.Prep);
  const { modifiedLatePolicyHasChanged: studyPathPrepLatePolicyHasChanged, modifiedLateDateOffsetHasChanged: studyPathPrepLateDateOffsetHasChanged, modifiedLatePenaltyHasChanged: studyPathPrepLatePenaltyHasChanged } =
    getPresetLatePolicyDelta({
      existingGradingPolicy: existingPreset.studyPathPrepGradingPolicy,
      newGradingPolicy: newPreset.studyPathPrepGradingPolicy,
      dueTimeHasChanged: newPreset.studyPathDueTime !== existingPreset.studyPathDueTime,
      latePolicyHasChanged: presetDelta.studyPathPrepLatePolicyHasChanged,
      lateDateOffsetHasChanged: presetDelta.studyPathPrepLateDateOffsetHasChanged,
      latePenaltyHasChanged: presetDelta.studyPathPrepLatePenaltyHasChanged,
      existingLatePolicy: existingPreset.studyPathPrepLatePolicy,
    });

  // calculate studyPathPractice dependent deltas
  const studyPathPracticeGradingHasChanged = hasGradingChanged(presetDelta, AssessTypeEnum.PracticeTest);
  const { modifiedLatePolicyHasChanged: studyPathPracticeLatePolicyHasChanged, modifiedLateDateOffsetHasChanged: studyPathPracticeLateDateOffsetHasChanged, modifiedLatePenaltyHasChanged: studyPathPracticeLatePenaltyHasChanged } =
    getPresetLatePolicyDelta({
      existingGradingPolicy: existingPreset.studyPathPracticeGradingPolicy,
      newGradingPolicy: newPreset.studyPathPracticeGradingPolicy,
      dueTimeHasChanged: newPreset.studyPathDueTime !== existingPreset.studyPathDueTime,
      latePolicyHasChanged: presetDelta.studyPathPracticeLatePolicyHasChanged,
      lateDateOffsetHasChanged: presetDelta.studyPathPracticeLateDateOffsetHasChanged,
      latePenaltyHasChanged: presetDelta.studyPathPracticeLatePenaltyHasChanged,
      existingLatePolicy: existingPreset.studyPathPracticeLatePolicy,
    });

  return {
    ...presetDelta,
    homeworkGradingPolicyHasChanged: homeworkGradingHasChanged,
    homeworkFreeAttemptsHasChanged: homeworkGradingHasChanged,
    homeworkPointPenaltyHasChanged: homeworkGradingHasChanged,
    homeworkLatePolicyHasChanged,
    homeworkLateDateOffsetHasChanged,
    homeworkLatePenaltyHasChanged,
    preclassGradingPolicyHasChanged: preclassGradingHasChanged,
    preclassFreeAttemptsHasChanged: preclassGradingHasChanged,
    preclassPointPenaltyHasChanged: preclassGradingHasChanged,
    preclassLatePolicyHasChanged,
    preclassLateDateOffsetHasChanged,
    preclassLatePenaltyHasChanged,
    studyPathPrepGradingPolicyHasChanged: studyPathPrepGradingHasChanged,
    studyPathPrepFreeAttemptsHasChanged: studyPathPrepGradingHasChanged,
    studyPathPrepPointPenaltyHasChanged: studyPathPrepGradingHasChanged,
    studyPathPrepLatePolicyHasChanged,
    studyPathPrepLateDateOffsetHasChanged,
    studyPathPrepLatePenaltyHasChanged,
    studyPathPracticeGradingPolicyHasChanged: studyPathPracticeGradingHasChanged,
    studyPathPracticeFreeAttemptsHasChanged: studyPathPracticeGradingHasChanged,
    studyPathPracticePointPenaltyHasChanged: studyPathPracticeGradingHasChanged,
    studyPathPracticeLatePolicyHasChanged,
    studyPathPracticeLateDateOffsetHasChanged,
    studyPathPracticeLatePenaltyHasChanged,
  };
}
function hasGradingChanged(presetChanges: PresetHasChanged<CourseAssessmentPresetApi>, assessType: AssessTypeEnum) {
  switch (assessType) {
    case AssessTypeEnum.Prep:
      return presetChanges.studyPathPrepGradingPolicyHasChanged || presetChanges.studyPathPrepFreeAttemptsHasChanged || presetChanges.studyPathPrepPointPenaltyHasChanged;
    case AssessTypeEnum.PracticeTest:
      return presetChanges.studyPathPracticeGradingPolicyHasChanged || presetChanges.studyPathPracticeFreeAttemptsHasChanged || presetChanges.studyPathPracticePointPenaltyHasChanged;
    case AssessTypeEnum.Preclass:
    case AssessTypeEnum.Readiness:
      return presetChanges.preclassGradingPolicyHasChanged || presetChanges.preclassFreeAttemptsHasChanged || presetChanges.preclassPointPenaltyHasChanged;
    case AssessTypeEnum.Homework:
      return presetChanges.homeworkGradingPolicyHasChanged || presetChanges.homeworkFreeAttemptsHasChanged || presetChanges.homeworkPointPenaltyHasChanged;
    default:
      return false;
  }
}

function hasAssessmentChanged(originalAssessment: AssessmentApiBase, updatedAssessment: AssessmentApiBase) {
  const { dueDate, openDate, gradingPolicy, freeAttempts, pointPenalty, latePolicy, lateDate, latePenalty } = originalAssessment;
  const { dueDate: newDueDate, openDate: newOpenDate, gradingPolicy: newGradingPolicy, freeAttempts: newFreeAttempts, pointPenalty: newPointPenalty, latePolicy: newLatePolicy, lateDate: newLateDate, latePenalty: newLatePenalty } = updatedAssessment;
  return [
    dueDate !== newDueDate,
    openDate !== newOpenDate,
    gradingPolicy !== newGradingPolicy,
    freeAttempts !== newFreeAttempts,
    pointPenalty !== newPointPenalty,
    latePolicy !== newLatePolicy,
    lateDate !== newLateDate,
    latePenalty !== newLatePenalty,
  ].some(Boolean);
}

//depending on whether the open date and or open time have changed for an assessment, make the appropriate changes to the open date and or time
function updateDateAndOrTime(originalOpen: string, defaultOpen: Date, timeZone: string, dateHasChanged: boolean, timeHasChanged: boolean) {
  const originalOpenDateTime = DateTime.fromISO(originalOpen).setZone(timeZone);
  const defaultOpenDateTime = DateTime.fromJSDate(defaultOpen).setZone(timeZone);
  if (dateHasChanged && timeHasChanged) {
    return DateTime.fromJSDate(defaultOpen).toUTC().toISO();
  } else if (dateHasChanged && !timeHasChanged) {
    // return the date from defaultOpen and the time from originalOpen
    return defaultOpenDateTime.set({
      hour: originalOpenDateTime.hour,
      minute: originalOpenDateTime.minute,
      second: originalOpenDateTime.second,
    }).toUTC().toISO();
  } else if (!dateHasChanged && timeHasChanged) {
    // return the date from originalOpen and the time from defaultOpen
    return originalOpenDateTime.set({
      hour: defaultOpenDateTime.hour,
      minute: defaultOpenDateTime.minute,
      second: defaultOpenDateTime.second,
    }).toUTC().toISO();
  } else {
    return originalOpen;
  }
}

export { getPresetDelta, getAssessmentsToUpdateForCoursePresets };
