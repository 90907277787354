// This has been split off from KebabMenu to serve as a more general-use contextual menu
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import useDropdownMenu from 'hooks/useDropdownMenu';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { PositionEnum, StandardMenuItem } from 'types/common.types';
import './ContextMenu.scss';

function ContextMenu({
  className = '',
  disabled,
  items,
  position,
  triggerIcon,
}: {
  className?: string
  disabled?: boolean
  items: Array<StandardMenuItem>
  position?: PositionEnum.Right | PositionEnum.Left
  triggerIcon: React.ReactNode
}) {
  const {
    buttonProps,
    itemProps,
    isOpen,
    setIsOpen,
  } = useDropdownMenu(items.length);
  const contextRef = useRef(null);
  const pos = position || PositionEnum.Right;
  useOnClickOutside(contextRef, () => {
    setIsOpen(false);
  });

  const renderMenuItem = (item: StandardMenuItem, index: number) => {
    const { icon, label, onClick, url, external, show = true } = item;
    if (!show) {
      return null;
    }
    if (!!url) {
      if (external === true) {
        return (
          <a {...itemProps[index]} href={url} className="external-link" target="_blank" rel="noopener noreferrer">
            {label}
          </a>
        );
      }
      return (
        <Link {...itemProps[index]} to={url}>{!!icon && icon}{label}</Link>
      );
    }
    // Disabling some lint rules because a11y is handled by react-accessible-dropdown-menu-hook module
    return (
      <a // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        {...itemProps[index]}
        onClick={onClick}
        data-disabled={disabled}
      >
        {!!icon && icon}{label}
      </a>
    );
  };

  return (
    <div className={`context-menu__wrap ${className}`} data-expanded={isOpen} ref={contextRef}>
      <button
        {...buttonProps}
        className={`context-menu__trigger ${isOpen ? 'active' : ''}`}
        title="Click to set non-class day"
      >
        {triggerIcon}
      </button>
      <ul className={`context-menu__dropdown context-dropdown-${pos}`}>
        {items.map((item, idx) => (
          <li key={item.label}>{renderMenuItem(item, idx)}</li>
        ))}
      </ul>
    </div>
  );
}

export default ContextMenu;
