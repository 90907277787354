import apiNext from 'api-next';

/**
 * Method to save locally error messages and
 * send them in batch rather than individually
 * @param  {string} message
 */
export const saveLogMessage = async (message = '') => {
  const sessionErrorLogsArr: Array<string> = JSON.parse(sessionStorage.getItem('sessionErrorLogs') || '[]');
  const { localStorage, location: { pathname } } = window;
  const userId = localStorage.getItem('userId') || null;
  const masqueradeUserId = localStorage.getItem('masqueradeUserId') || null;
  const fullMessage = message.concat(';', JSON.stringify({ userId, masqueradeUserId, activePath: pathname }));
  sessionErrorLogsArr.push(fullMessage);
  window.sessionStorage.setItem('sessionErrorLogs', JSON.stringify(sessionErrorLogsArr));

  const sessionErrorTimeoutRef: string | null = window.sessionStorage.getItem('sessionErrorTimeoutRef');
  let timeoutRef: number | null = sessionErrorTimeoutRef ? Number(sessionErrorTimeoutRef) : null;

  if (timeoutRef !== null) {
    clearTimeout(timeoutRef);
    window.sessionStorage.removeItem('sessionErrorTimeoutRef');
  }

  timeoutRef = setTimeout(async () => {
    clearTimeout(timeoutRef as number);
    window.sessionStorage.removeItem('sessionErrorLogs');
    window.sessionStorage.removeItem('sessionErrorTimeoutRef');
    await apiNext.postLogMessage(sessionErrorLogsArr);
  }, 15000) as unknown as number; // avoids casting to NodeJS.Timeout type

  window.sessionStorage.setItem('sessionErrorTimeoutRef', JSON.stringify(timeoutRef));
};
