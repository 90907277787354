/**
 * ClassDaysCovered takes classSessionIds and classSessions and returns a correctly formatted string
 */
import React from 'react';
import PropTypes from 'prop-types';
import { fullClassesCoveredString } from 'utils/commonFormattingFunctions';
import { ClassSessionApi } from 'types/backend/classSessions.types';
interface ClassDaysCoveredProps {
  classSessionIds: Array<number>
  classSessions: Array<Pick<ClassSessionApi, 'id' | 'classNumber'>>
}

const ClassDaysCovered = ({ classSessionIds, classSessions }: ClassDaysCoveredProps) => {
  if (!classSessionIds.length) {
    return null;
  }
  const classNumbers = classSessionIds.map((csId) => {
    const { classNumber } = classSessions.find(({ id }) => id === csId) as ClassSessionApi;
    return classNumber;
  });
  return (
    <>
      {fullClassesCoveredString(classNumbers)}
    </>
  );
};

ClassDaysCovered.propTypes = {
  classSessionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  classSessions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ClassDaysCovered;
