/**
 * retrieveEnrichedStudentAssessments
* this selector gathers a bunch of info and returns a handy array of what are essentially refered to by the product team as 'assignments'
* placeholders are added for student assessments that have not been started yet, so that a full list of all the assessments in the course is included in the results
* which has calculated information such as if the student has taken the assessment or how many points it is worth
* useful on the assignments list and student dashboard, and maybe other places as well
*/
import { createSelector } from '@reduxjs/toolkit';
import cloneDeep from 'lodash-es/cloneDeep';
import { DateTime } from 'luxon';

import { determineAssessmentWindow, getIsCloseToPracticeTestDue } from 'utils/assessmentFunctions';
import { getPracticeTestUnlockedPercentage } from 'student/controllers/Course/StudyPathController/sharedStudyPathFunctions';
import retrieveActiveClassSessions from 'store/selectors/retrieveActiveClassSessions';
import retrieveAssessmentsWithEnrollment, { AssessmentWithEnrollment, SummativeAssessmentWithEnrollment } from 'store/selectors/retrieveAssessmentsWithEnrollment';
import { EnrichedActiveClassSession } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { Store } from 'types/store.types';
import { YesNo } from 'types/backend/shared.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { FirstAttemptedEnum, StudentAssessmentQuestionApiWithSaqas } from 'types/backend/studentAssessmentQuestions.types';
import { StudentAssessmentApi } from 'types/backend/studentAssessments.types';
import { StudentAssessmentStatus } from 'types/backend/studentScoresData.types';
import { StudentStudyPathApi } from 'types/backend/studentStudyPaths.types';
import { SummativeAssessmentSupplementsApi } from 'types/backend/summativeAssessmentSupplements.types';

export interface EnrichedStudentAssessment extends AssessmentWithEnrollment {
  allQuestionsAnswered: boolean
  assessmentStatus: StudentAssessmentStatus
  classDaysCovered: Array<number>
  gradedCorrectQuestionCount: number
  hasBeenStarted: boolean
  isAfterLate: boolean
  isBeforeDue: boolean
  isOpen: boolean
  latestCorrectQuestionCount: number
  openToStudents: boolean
  pointsEarned: number
  recapPointsAvailable: number
  totalPoints: number
  totalQuestions: number
}

export type EnrichedStudentSummativeAssessment = EnrichedStudentAssessment & SummativeAssessmentWithEnrollment

export const enrichStudentAssessments = ({
  assessmentQuestions,
  classSessions,
  enrichedAssessments,
  instructorStudentViewCourseId,
  studentAssessmentQuestions,
  studentAssessments,
  studentStudyPath,
  summativeAssessmentSupplements,
}: {
  assessmentQuestions: Array<AssessmentQuestionApi>
  classSessions: Array<EnrichedActiveClassSession>
  enrichedAssessments: Array<AssessmentWithEnrollment>
  instructorStudentViewCourseId: string | null
  studentAssessmentQuestions: Array<StudentAssessmentQuestionApiWithSaqas>
  studentAssessments: Array<StudentAssessmentApi>
  studentStudyPath: StudentStudyPathApi
  summativeAssessmentSupplements: Array<SummativeAssessmentSupplementsApi>
}) => {
  if (!studentStudyPath || !enrichedAssessments || !assessmentQuestions || !studentAssessments || !summativeAssessmentSupplements) {
    return [];
  }
  enrichedAssessments = cloneDeep(enrichedAssessments);
  assessmentQuestions = cloneDeep(assessmentQuestions);
  studentAssessments = cloneDeep(studentAssessments);
  const { studentTopicCardCheckpoints } = studentStudyPath;
  const now = DateTime.local();
  const enrichedStudentAssessmentWithoutOpenToStudents = enrichedAssessments.map(assessment => {
    const { classSessionIds = [] } = assessment;
    const classDaysCovered = classSessions.reduce((acc: Array<number>, session: EnrichedActiveClassSession) => {
      if (classSessionIds.includes(session.id)) {
        acc.push(session.classNumber);
      }
      return acc;
    }, []);

    const questions = assessmentQuestions.filter(aq => aq.assessmentId === assessment.id);
    const totalPoints = questions.reduce((total, question) => {
      total += question.points;
      return total;
    }, 0);

    let studentData = {
      recapPointsAvailable: 0,
      pointsEarned: 0,
      gradedCorrectQuestionCount: 0,
      latestCorrectQuestionCount: 0,
    };
    let allQuestionsAnswered = false;
    const studentAssessment = studentAssessments.find(sa => sa.assessmentId === assessment.id);
    const hasBeenStarted = !!studentAssessment;
    let assessmentStatus: StudentAssessmentStatus;

    const currentWindow = determineAssessmentWindow(assessment.dueDate, assessment.lateDate, assessment.enrollmentAssessmentDueDate);
    const isBeforeDue = currentWindow === FirstAttemptedEnum.BeforeDue;
    const isAfterLate = currentWindow === FirstAttemptedEnum.AfterLate;

    if (studentAssessment) {
      allQuestionsAnswered = studentAssessment.unansweredQuestionCount === 0;
      const studentQuestions = studentAssessmentQuestions.filter(saq => saq.studentAssessmentId === studentAssessment.id);

      studentData = studentQuestions.reduce((acc, sq) => {
        const recapPoints = sq.pointsAvailableToRecap || 0;
        acc.recapPointsAvailable += recapPoints;
        if (sq.gradedStudentAssessmentQuestionAttempt?.isCorrect === YesNo.Yes) {
          acc.gradedCorrectQuestionCount += 1;
        }
        if (sq.latestStudentAssessmentQuestionAttempt?.isCorrect === YesNo.Yes) {
          acc.latestCorrectQuestionCount += 1;
        }
        return acc;
      }, studentData);

      studentData.pointsEarned = studentAssessment.totalPointsEarned;

      const anyQsUnanswered = studentQuestions.some(({ firstAttempted }) => firstAttempted === FirstAttemptedEnum.Never);
      const anyQsAnswered = studentQuestions.some(({ firstAttempted }) => firstAttempted !== FirstAttemptedEnum.Never);
      const anyBeforeLate = studentQuestions.some(({ firstAttempted }) => firstAttempted === FirstAttemptedEnum.BeforeLate);
      const anyAfterLate = studentQuestions.some(({ firstAttempted }) => firstAttempted === FirstAttemptedEnum.AfterLate);
      if (anyQsUnanswered && !anyQsAnswered) {
        // not started
        if (isBeforeDue) {
          assessmentStatus = StudentAssessmentStatus.NotStartedBeforeDue;
        } else if (currentWindow === FirstAttemptedEnum.BeforeLate) {
          assessmentStatus = StudentAssessmentStatus.NotStartedBeforeLate;
        } else { // after late
          assessmentStatus = StudentAssessmentStatus.NotStartedAfterLate;
        }
      } else if (anyQsUnanswered && anyQsAnswered) {
        // in progress
        if (isBeforeDue) {
          assessmentStatus = StudentAssessmentStatus.InProgressBeforeDue;
        } else if (currentWindow === FirstAttemptedEnum.BeforeLate) {
          assessmentStatus = StudentAssessmentStatus.InProgressBeforeLate;
        } else { // after late
          assessmentStatus = StudentAssessmentStatus.InProgressAfterLate;
        }
      } else {
        // completed
        if (anyAfterLate) {
          assessmentStatus = StudentAssessmentStatus.CompletedAfterLate;
        } else if (anyBeforeLate) {
          assessmentStatus = StudentAssessmentStatus.CompletedBeforeLate;
        } else {
          // must be before due
          assessmentStatus = StudentAssessmentStatus.CompletedBeforeDue;
        }
      }
    } else {
      if (isBeforeDue) {
        assessmentStatus = StudentAssessmentStatus.NotStartedBeforeDue;
      } else if (currentWindow === FirstAttemptedEnum.BeforeLate) {
        assessmentStatus = StudentAssessmentStatus.NotStartedBeforeLate;
      } else {
        assessmentStatus = StudentAssessmentStatus.NotStartedAfterLate;
      }
    }


    if (isAfterLate) {
      studentData.recapPointsAvailable = 0;
    } // no point recapture if the lateDate (or dueDate if there is no late policy) has passed
    const isOpen = now > DateTime.fromISO(assessment.mergedOpenDate);

    // if no questions in assessment, then all questions have been answered
    if (questions.length === 0) {
      allQuestionsAnswered = true;
    }

    return {
      ...assessment,
      allQuestionsAnswered,
      assessmentStatus,
      classDaysCovered,
      gradedCorrectQuestionCount: studentData.gradedCorrectQuestionCount,
      hasBeenStarted,
      isBeforeDue,
      isAfterLate,
      isOpen,
      latestCorrectQuestionCount: studentData.latestCorrectQuestionCount,
      pointsEarned: studentData.pointsEarned,
      recapPointsAvailable: studentData.recapPointsAvailable,
      totalPoints,
      totalQuestions: questions.length,
    };
  });

  /**
   * I have moved the calculations into this selector where we already had most of the data sets we needed
   * instead of calculating it on the fly which required a lot of imports and selectors throughout the codebase
   * I feel like this could be simplified
   */
  // doing another loop because getPracticeTestUnlockedPercentage depends on the full array from the above calculations
  let previousStudyPathDueDateLuxon: DateTime;
  const enrichedStudentAssessments: Array<EnrichedStudentAssessment | EnrichedStudentSummativeAssessment> = enrichedStudentAssessmentWithoutOpenToStudents.map((assessment) => {
    const { hasBeenStarted, allQuestionsAnswered, isBeforeDue, published } = assessment;
    // Determine if student should be able to open this assessment
    // TODO CA-2589: I think someday perhaps this should be determined on the backend? maybe?
    let openToStudents = false;

    switch (assessment.assessType) {
      case AssessTypeEnum.Homework:
      case AssessTypeEnum.Readiness:
      case AssessTypeEnum.Preclass: {
        if (!!instructorStudentViewCourseId) {
          // always open for instructor
          openToStudents = true;
        } else {
          openToStudents = assessment.isOpen;
        }
        break;
      }
      case AssessTypeEnum.Prep:
      case AssessTypeEnum.Summative: {
        const { mergedDueDate } = assessment;
        const assessmentDueDateLuxon = DateTime.fromISO(mergedDueDate);
        // get summativeId for this prep question
        const { id: summativeAssessmentId } = summativeAssessmentSupplements.find((sa) => sa.prepAssessmentId === assessment.id) || {};
        // compare to currently active studentStudyPath
        const prepInCurrentSSP = !!summativeAssessmentId && summativeAssessmentId === studentStudyPath.summativeAssessmentId;
        const summativeInCurrentSSP = !!studentStudyPath && assessment.id === studentStudyPath.summativeAssessmentId;
        const assessmentInCurrentSSP = prepInCurrentSSP || summativeInCurrentSSP;

        // first prep and SP links should be active before the first SP is initialized
        const assessmentBeforeFirstSP = !previousStudyPathDueDateLuxon;

        const assessmentDueAfterPreviousSP = !!previousStudyPathDueDateLuxon && previousStudyPathDueDateLuxon < assessmentDueDateLuxon;
        const previousSPDueInFuture = !!previousStudyPathDueDateLuxon && now < previousStudyPathDueDateLuxon;
        const assessmentDueInFuture = now < assessmentDueDateLuxon;
        const assessmentWithinSPWindow = assessmentBeforeFirstSP || (!previousSPDueInFuture && assessmentDueAfterPreviousSP && assessmentDueInFuture);

        openToStudents = assessmentWithinSPWindow || assessmentInCurrentSSP || !isBeforeDue;
        // only set previous SP due date for summative, not prep
        if (assessment.assessType === AssessTypeEnum.Summative) {
          previousStudyPathDueDateLuxon = assessmentDueDateLuxon;
        }
        break;
      }
      case AssessTypeEnum.PracticeTest: {
        if (published === YesNo.No) {
          openToStudents = false;
        } else if (!!instructorStudentViewCourseId) {
          // open for instructor, regardless of SP completion
          openToStudents = true;
        } else {
          const isCloseToPracticeTestDue = getIsCloseToPracticeTestDue(assessment.dueDate);

          let practiceTestUnlocked = false;
          const { id: summativeAssessmentId } = summativeAssessmentSupplements.find((sa) => sa.practiceAssessmentId === assessment.id) || {};

          if (summativeAssessmentId === studentStudyPath.summativeAssessmentId && !!studentTopicCardCheckpoints) {
            const practiceTestUnlockedPercentage = getPracticeTestUnlockedPercentage(studentTopicCardCheckpoints, enrichedStudentAssessmentWithoutOpenToStudents);
            practiceTestUnlocked = practiceTestUnlockedPercentage >= 100;
          }
          openToStudents = [isCloseToPracticeTestDue, practiceTestUnlocked, hasBeenStarted, allQuestionsAnswered, !isBeforeDue].some(Boolean);
        }
        break;
      }
    }
    return {
      ...assessment,
      openToStudents,
    };
  });
  return enrichedStudentAssessments;
};

export default createSelector(
  (store: Store) => store.state.instructorStudentViewCourseId,
  (store: Store) => store.active.assessmentQuestionMaps,
  (store: Store) => store.active.studentAssessments,
  (store: Store) => store.active.studentAssessmentQuestions,
  (store: Store) => store.active.summativeAssessmentSupplements,
  (store: Store) => store.active.studentStudyPath,
  retrieveAssessmentsWithEnrollment,
  retrieveActiveClassSessions,
  (
    instructorStudentViewCourseId: string | null,
    assessmentQuestions: Array<AssessmentQuestionApi>,
    studentAssessments: Array<StudentAssessmentApi>,
    studentAssessmentQuestions: Array<StudentAssessmentQuestionApiWithSaqas>,
    summativeAssessmentSupplements,
    studentStudyPath,
    enrichedAssessments: Array<AssessmentWithEnrollment>,
    classSessions
  ) => {
    return enrichStudentAssessments({
      assessmentQuestions,
      classSessions,
      enrichedAssessments,
      instructorStudentViewCourseId,
      studentAssessmentQuestions,
      studentAssessments,
      studentStudyPath,
      summativeAssessmentSupplements,
    });
  }
);

