import React, { useState } from 'react';
import {
  FaCalendarDay,
  FaInfoCircle,
  FaPencilAlt,
  FaTrashAlt,
} from 'react-icons/fa';

import useInput from 'hooks/useInput';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import { canSessionBeSpecial } from 'utils/classSessionFunctions';
import ClassLabelIcon from 'shared-components/ClassLabelIcon/ClassLabelIcon';
import LoadingButton from 'shared-components/LoadingButton/LoadingButton';
import { BetterClassSession } from 'store/selectors/retrieveBetterClassSessions';
import { CoursePlannerAction, SharedBCPProps } from '../BetterCoursePlannerController';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import { ConfirmationTypeEnum, EventKeyEnum } from 'types/common.types';
import './CoursePlannerClassRow.scss';

function CoursePlannerClassRowDayLabel({
  classSession,
  classSessionId,
  handleCoursePlannerAction,
  initialValues: { label, classType },
  isEditing,
  isProcessing,
  setIsEditing,
  show = false,
}: SharedBCPProps & {
  classSession: BetterClassSession
  initialValues: {
    label: string
    classType: ClassTypeEnum
  }
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  show?: boolean
}) {
  const isSpecial = classType === ClassTypeEnum.Special;
  const [isSpecialChecked, setIsSpecialChecked] = useState(isSpecial);
  const [dayLabelValue, handleDayLabelChange, setDayLabelValue] = useInput(label);
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const { canSessionBeMadeSpecial, sessionDependencies } = canSessionBeSpecial(classSession);
  const saveDisabled = dayLabelValue === '';

  const handleSaveClick = async () => handleCoursePlannerAction(CoursePlannerAction.UpdateClassDayLabel, {
    classSessionId,
    stringValue: dayLabelValue,
    classType: isSpecialChecked ? ClassTypeEnum.Special : ClassTypeEnum.Normal,
  }).then(() => setIsEditing(false)).catch((err) => console.error(err));


  const listenForEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!saveDisabled && event.key === EventKeyEnum.Enter) {
      await handleSaveClick();
    }
  };

  if (!show) {
    return null;
  }

  const handleCancel = () => {
    setDayLabelValue(label);
    setIsEditing(!isEditing);
  };

  const showSpecialForbiddenReasons = () => triggerConfirmationPrompt({
    title: 'You cannot make this class a non-class day',
    message: (
      <div>
        It contains one or more of the following:
        <ul>
          {sessionDependencies.map(sd => (<li key={sd}>{sd}</li>))}
        </ul>
        Please remove these elements from the class session if you'd like to make this a non-class day.
      </div>
    ),
    onConfirm: () => {},
    confirmationType: ConfirmationTypeEnum.Warn,
  });

  const handleRemoveLabelWithConfirmation = () => triggerConfirmationPrompt({
    title: 'Are you sure you want to remove the class day label?',
    message: (
      <div>
        Click OK to remove the label from this class day.
      </div>
    ),
    confirmationType: ConfirmationTypeEnum.Warn,
    onCancel: () => {},
    onConfirm: async () => handleCoursePlannerAction(CoursePlannerAction.UpdateClassDayLabel, {
      classSessionId,
      stringValue: null,
      classType: ClassTypeEnum.Normal,
    }).then(() => setIsEditing(false)).catch((err) => console.error(err)),
  });


  return (
    <div
      className="bcp__class-row__day-label"
      data-classtype={classType}
      data-editing={isEditing}
    >
      <div className="bcp__class-row__day-label__icon">
        {isEditing
          ? <FaCalendarDay className="editing-icon" size={48} />
          : <ClassLabelIcon classType={classType} />
        }
      </div>
      <div className="bcp__class-row__day-label__contents row">
        <div className="bcp__class-row__day-label__label col-xs-9">
          {isEditing ? (
            <input
              className="bcp__class-row__day-label__edit"
              id="class-session-label-input"
              placeholder="Label the day"
              type="text"
              value={dayLabelValue}
              onChange={handleDayLabelChange}
              onKeyDown={listenForEnter}
            />
          ) : (
            <div className="bcp__class-row__day-label__display">
              {label}
              <button
                className="edit-class-label"
                title="Edit this Class Day label"
                onClick={() => setIsEditing(!isEditing)}
              >
                <FaPencilAlt />
              </button>
            </div>
          )}
          <div className="bcp__class-row__day-label__contents__details">
            {isEditing && (
              <>
                <label htmlFor="class-session-label-input">Enter class day label</label>
                <div className="special-class-checkbox__wrap">
                  <label className="special-class-checkbox" data-disabled={!canSessionBeMadeSpecial}>
                    <span>No Class</span>
                    <input
                      type="checkbox"
                      disabled={!canSessionBeMadeSpecial}
                      checked={isSpecialChecked}
                      id="special-class"
                      onChange={() => setIsSpecialChecked(!isSpecialChecked)}
                    />
                  </label>
                  {!canSessionBeMadeSpecial && (
                    <button className="special-class-checkbox__explain-special-forbidden" onClick={showSpecialForbiddenReasons}>
                      <FaInfoCircle />
                    </button>
                  )}
                </div>
              </>
            )}
            {!isEditing && isSpecial && <span className="no-class-label">There is no class today</span>}
          </div>
        </div>
        <div className="bcp__class-row__day-label__actions col-xs-3">
          {isEditing && (
            <div className="bcp__class-row__day-label__actions__buttons">
              <div className="main-action-buttons">
                <LoadingButton
                  disabled={saveDisabled}
                  onClick={handleSaveClick}
                  text="Save"
                  loadingText="Saving..."
                  loading={isProcessing}
                />
                <button onClick={handleCancel}>
                  Cancel
                </button>
              </div>
              <div className="aux-action-buttons">
                <button onClick={handleRemoveLabelWithConfirmation} title="Remove Label from Class Day">
                  <FaTrashAlt />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CoursePlannerClassRowDayLabel;
