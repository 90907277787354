/**
 * This selector returns enriched iclr objects for all iclrs that are connected to classSessions in the course
 *
 * I am really not a fan of doing everything twice for Iclrs and Ooclrs, there should just be CLRs with an attribute that specifies which kind it is, like assessments
*/
import { createSelector } from '@reduxjs/toolkit';
import { ClassSessionIclrApi } from 'types/backend/classSessionIclr.types';
import { ClassSessionOoclrApi } from 'types/backend/classSessionOoclr.types';
import { IclrApi } from 'types/backend/iclr.types';
import { OoclrApi } from 'types/backend/ooclr.types';
import { EnrichedClassSessionClr, InstructionEnum } from 'types/common.types';
import { Store } from 'types/store.types';


// this lives here until we combine Iclrs and Ooclrs, tooo much duplication
export const enrichClassSessionClrs = (
  classSessionClrs: Array<ClassSessionIclrApi | ClassSessionOoclrApi>,
  clrs: Array<IclrApi | OoclrApi>,
  clrType: InstructionEnum
) => {
  if (!classSessionClrs.length || !clrs.length) {
    return [];
  }
  return classSessionClrs.map((csClr) => {
    // if it's not iclrId, it's going to be ooclrId
    const clrId = (csClr as ClassSessionIclrApi).iclrId || (csClr as ClassSessionOoclrApi).ooclrId;
    const { id, title, url, type, userId } = clrs.find((clr) => clr.id === clrId) as IclrApi;
    const classSessionIds = classSessionClrs.reduce((acc, cur) => {
      const { classSessionId } = cur;
      const { iclrId } = cur as ClassSessionIclrApi;
      const { ooclrId } = cur as ClassSessionOoclrApi;
      const csClrId = iclrId || ooclrId;
      return csClrId === id ? [...acc, classSessionId] : acc;
    }, [] as Array<number>);
    return {
      ...csClr,
      classSessionIds,
      clrId,
      clrType,
      title,
      url,
      type,
      userId,
    };
  }) as Array<EnrichedClassSessionClr>;

};

export default createSelector(
  (store: Store) => store.active.classSessionIclrs,
  (store: Store) => store.active.iclrs,
  (classSessionIclrs: Array<ClassSessionIclrApi> = [], iclrs: Array<IclrApi> = []) => {
    return enrichClassSessionClrs(classSessionIclrs, iclrs, InstructionEnum.InClass);
  }
);
