/***
 * retrieveCourseAccessData
 * simple selector that gets the current course and enrollment from state
 * and calls and returns the relevant courseAccessData object
*/

import { createSelector } from '@reduxjs/toolkit';

import { Store } from 'types/store.types';
import { determineCourseAccessData } from 'utils/courseAccessFunctions';

export default createSelector(
  (store: Store) => store.active.course,
  (store: Store) => store.active.enrollment,
  (course, enrollment) => {
    if (!course.id || !enrollment) {
      return null;
    }
    const courseAccessData = determineCourseAccessData(enrollment, course);
    return courseAccessData;
  });
