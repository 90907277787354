import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';
import { StudentStudyPathApi } from 'types/backend/studentStudyPaths.types';

export default function getStudentStudyPathData(summativeAssessmentId: string, enrollmentId: number) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    if (!summativeAssessmentId || !enrollmentId) {
      console.warn('Both summativeAssessmentId and enrollmentId required to getStudentStudyPathData', summativeAssessmentId, enrollmentId);
      return;
    }
    const [studyPathDataFromSummative] = await apiNext.getStudyPathBySummativeAssessmentId(summativeAssessmentId);
    const { id: studyPathId } = studyPathDataFromSummative;
    dispatch(activeSlice.actions.setStudyPath(studyPathDataFromSummative));
    // is there a student study path for course enrollment
    // NOTE: This should always return an array of one if the student study path exists, because Feathers
    const [studentStudyPathData]: Array<StudentStudyPathApi> = await apiNext.getStudentStudyPathByStudyPathIdAndEnrollment(studyPathId, enrollmentId);
    if (studentStudyPathData) {
      dispatch(activeSlice.actions.setStudentStudyPath(studentStudyPathData));
    } else {
      dispatch(activeSlice.actions.setStudentStudyPath({} as StudentStudyPathApi)); //reset to empty if it does not exist and had previously be populated
      console.debug('Student study path does not exist, setting to empty object');
    }
    return;
  })();
}
