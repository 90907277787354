import React, { useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import apiNext from 'api-next';
import useSubmitStatus, { SubmitStatus } from 'hooks/useSubmitStatus';
import LoginBox from './LoginBox';
import PasswordChecklist, { RuleNames } from './PasswordComplexity';

export default function PasswordResetAction() {
  const [token] = useQueryParam('token', StringParam);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [infoMsg, setInfoMsg] = useState<string | null>(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [, setSubmitStatus, submitDisabled] = useSubmitStatus();

  async function doChangePassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setInfoMsg(null);
    setErrorMsg(null);
    setSubmitStatus(SubmitStatus.Submitting);
    try {
      if (!password || !confirmPassword) {
        setErrorMsg('Password or confirm password fields missing');
        setSubmitStatus(SubmitStatus.Failure);
        return false;
      }

      if (password !== confirmPassword) {
        setErrorMsg('Password does not match confirm password');
        setSubmitStatus(SubmitStatus.Failure);
        return false;
      }

      if (!token) {
        setErrorMsg('Reset token invalid');
        setSubmitStatus(SubmitStatus.Failure);
        return false;
      }

      await apiNext.changePassword(token, password);
      setSubmitStatus(SubmitStatus.Success);

      setInfoMsg('Password changed, please login with new password');
      return false;
    } catch (error: any) {
      let errMsg = 'Something went wrong, please try to reset password again.';
      if (error?.response?.data?.message === 'Password reset token has expired.') {
        errMsg = `${error.response.data.message} Please reset again.`;
      }
      setSubmitStatus(SubmitStatus.Failure);
      setErrorMsg(errMsg);
    }
  }

  const toggleVisibilityButton = (
    <button
      className="toggle-password-visibility"
      onClick={(e) => {
        e.preventDefault();
        setPasswordVisible(!passwordVisible);
      }}
      title="Toggle Password Visibility"
    >
      {passwordVisible ? <FaRegEyeSlash /> : <FaRegEye />}
    </button>
  );

  return (
    <LoginBox title="Change Password">
      <form className="password-reset-action" onSubmit={doChangePassword}>
        <fieldset disabled={submitDisabled}>
          <div className="password-input-wrap">
            <input
              onChange={e => setPassword(e.target.value)}
              placeholder="Password"
              aria-label="New Password"
              required
              type={passwordVisible ? 'text' : 'password'}
              value={password}
            />
            {toggleVisibilityButton}
          </div>
          <PasswordChecklist
            className="password-complexity-list"
            rules={[
              RuleNames.Number,
              RuleNames.SpecialChar,
              RuleNames.CapitalAndLowercase,
              RuleNames.MinLength,
              RuleNames.NoSpaces,
              RuleNames.Match,
            ]}
            minLength={12}
            onChange={(isValid) => setPasswordIsValid(isValid)}
            value={password}
            valueAgain={confirmPassword}
          />
          <div className="password-input-wrap">
            <input
              data-invalid={!!password.length && !!confirmPassword.length && password !== confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
              aria-label="Confirm Password"
              required
              type={passwordVisible ? 'text' : 'password'}
              value={confirmPassword}
            />
            {toggleVisibilityButton}
          </div>
          <input type="submit" value="Change password" disabled={!passwordIsValid} />
        </fieldset>
        <div role="alert">
          {!!infoMsg && <div className="login__info">{infoMsg}</div>}
          {!!errorMsg && <div className="login__error">{errorMsg}</div>}
        </div>
      </form>
      <div className="login__footer-link"><a href="/login">Back to Login</a></div>
    </LoginBox>
  );
}
