import React, { useState } from 'react';

const dayValues = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

function ClassDayPicker({ disabled, days, updateDays }: {
  disabled: boolean
  days: Array<number>
  updateDays: (updatedDays: Array<number>) => void
}) {
  const [hasError, setHasError] = useState(false);
  const handleChange = (checkIdx: number, checked: boolean) => {
    const lastDayBeingUnchecked = !checked && days.length === 1;
    // when unchecking the last ticked checkbox, warn that at least one must be selected
    setHasError(lastDayBeingUnchecked);
    const updatedDaysArray = checked ? [...days, checkIdx].sort() : days.filter((v) => v !== checkIdx);
    updateDays(updatedDaysArray);
  };
  return (
    <>
      <div className="day-select">
        {dayValues.map((dayName, idx) => (
          <label className="day-checkbox" key={dayName}>
            <input
              type="checkbox"
              className={`checkbox-${dayName}`}
              checked={days.includes(idx)}
              onChange={({ currentTarget: { checked } }) => handleChange(idx, checked)}
              name={dayName}
              disabled={disabled}
            />
            <div className="day-name">{dayName}</div>
          </label>
        ))}
      </div>
      {hasError && <div className="form-error">Please select at least one day</div>}
    </>
  );
}

export default ClassDayPicker;
