import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import addOoclrToClassSessions from './addOoclrToClassSessions';
import { ClrData } from 'types/common.types';
import { AppDispatch, Store } from 'types/store.types';

export default function createOoclrAndAddToClassSession(
  classSessionIds: Array<number>,
  title: string,
  type: string,
  userId: string,
  url: string | null
) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const ooclrData = {
      title,
      url,
      type,
      userId,
    } as ClrData;

    const createdOoclr = await apiNext.createOoclr(ooclrData);
    dispatch(activeSlice.actions.addActiveOoclr(createdOoclr));

    dispatch(addOoclrToClassSessions(classSessionIds, createdOoclr.id));
  })();
}
