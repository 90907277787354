import { GenericObject } from 'types/backend/shared.types';

// TODO: Implement fuzzy title search to handle multiple words and typos
// basic filter based find
function searchWithinArray<T extends GenericObject>(searchString: string, arrayToSearch: Array<T>, searchKey = 'title') {
  // if arrayItem object doesn't have searchKey should error
  if (!!arrayToSearch[0] && !Object.keys(arrayToSearch[0]).includes(searchKey)) {
    console.error(`searchKey ${searchKey} not found in arrayItem`, arrayToSearch);
    return arrayToSearch;
  }
  // if no search entered, return full list
  if (!searchString.length) {
    return arrayToSearch;
  }
  const normalizedSearchTerm = searchString.toLowerCase();
  // basic split by spaces to match each word
  const wordsInSearch = normalizedSearchTerm.split(' ');
  return arrayToSearch.filter((arrayItem) => {
    const fieldValue = arrayItem[searchKey] as string;
    const normalizedValue = fieldValue.toLowerCase();
    // if search term contains multiple words, return LoItems containing all words
    return wordsInSearch.every((word) => normalizedValue.includes(word));
  });
}

export default searchWithinArray;
