import React, { useState } from 'react';
import Select, { MultiValue } from 'react-select';
import {
  FaCheck,
  FaPencilAlt,
  FaTimes,
  FaTrashAlt,
} from 'react-icons/fa';

import { fullClassesCoveredString } from 'utils/commonFormattingFunctions';
import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import { validUrl } from 'utils';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import { DropdownOption } from 'instructor/components/Dropdown/Dropdown.types';
import { InstructionEnum } from 'types/common.types';
import { InstructionsActionEnum, InstructionsPayload, InstructionsRow } from '../InstructionsController.types';
import './Instructions.scss';

function InstructionEditableRow({ classesDropdown, instruction, editingClrId, toggleEdit, clrDispatch }: {
  classesDropdown: Array<DropdownOption<number>>
  editingClrId: number
  instruction: InstructionsRow
  toggleEdit: (rowId: number) => void
  clrDispatch: (action: InstructionsActionEnum, payload: InstructionsPayload) => void
}) {
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const { clrId, classNumbers, classSessionIds, title, url } = instruction;
  const [editingUrl, setEditingUrl] = useState(url || '');
  const [editingTitle, setEditingTitle] = useState(title);
  const classSessionValues = classesDropdown.filter(({ value }) => classSessionIds.includes(value));
  const [selectedClassSessionValues, setSelectedClassSessionValues] = useState(classSessionValues as MultiValue<DropdownOption<number>>);
  const urlIsValid = editingUrl === '' || validUrl(editingUrl);
  const saveEnabled = urlIsValid && editingTitle !== '';
  const isEditing = editingClrId === clrId;

  const handleUpdate = () => {
    const selectedClassSessionIds = selectedClassSessionValues.map(({ value }) => value);
    clrDispatch(InstructionsActionEnum.Update, {
      clrId,
      classSessionIds: selectedClassSessionIds,
      title: editingTitle,
      url: editingUrl === '' ? null : editingUrl,
    });
  };

  const confirmDelete = () => {
    triggerConfirmationPrompt({
      title: `Confirm delete ${instruction.clrType === InstructionEnum.InClass ? 'material' : 'resource'}`,
      message: `Are you sure you want to delete ${instruction.title}?`,
      onCancel: () => {},
      onConfirm: () => clrDispatch(InstructionsActionEnum.Delete, { classSessionIds: instruction.ids, clrId, title: '' }),
    });
  };

  return (
    <tr className="editable-instruction-row" data-clrid={clrId} key={`instruction-row-${clrId}`}>
      <td className="assigned-instructions__classes">
        <div className="instructions__update__class-session">
          {isEditing ? (
            <Select
              options={classesDropdown}
              closeMenuOnSelect={false}
              className="select-class-session__dropdown"
              classNamePrefix="class-session__dropdown"
              isMulti={true}
              onChange={setSelectedClassSessionValues}
              value={selectedClassSessionValues}
            />
          ) : fullClassesCoveredString(classNumbers)}
        </div>
      </td>
      <td className="assigned-instructions__title editable-title">
        <input
          type="text"
          className="editable"
          readOnly={editingClrId !== clrId}
          onChange={({ target: { value } }) => setEditingTitle(value)}
          name="update-title"
          value={editingTitle}
        />
      </td>
      <td className="assigned-instructions__url">
        {!!url && editingClrId !== clrId
          ? externalLink(url, url)
          : (
            <input
              type="url"
              className="editable"
              onChange={e => setEditingUrl(e.target.value)}
              readOnly={editingClrId !== clrId}
              name="update-url"
              value={editingUrl}
            />
          )}
        {!urlIsValid && (
          <p className="instructions__select__updated-url__warning-invalid">
            Please enter a valid URL
          </p>
        )}
      </td>
      <td>
        <div className="instructions__edit-buttons">
          {isEditing ? (
            <>
              <button
                className="instructions__edit-button save-edits"
                title="Save changes"
                onClick={handleUpdate}
                disabled={!saveEnabled}
              >
                <FaCheck/>
              </button>
              <button
                className="instructions__edit-button cancel-edits"
                title="Cancel without saving"
                onClick={() => toggleEdit(-1)}
              >
                <FaTimes />
              </button>
            </>
          ) : (
            <>
              <button
                className="instructions__edit-button edit-row"
                title="Edit this row"
                disabled={editingClrId !== -1}
                onClick={() => toggleEdit(clrId)}
              >
                <FaPencilAlt/>
              </button>
              <button
                className="instructions__edit-button edit-row"
                title="Delete this row"
                disabled={editingClrId !== -1}
                onClick={confirmDelete}
              >
                <FaTrashAlt/>
              </button>
            </>
          )}
        </div>
      </td>
    </tr>
  );
}

export default InstructionEditableRow;
