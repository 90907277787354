import React from 'react';
import sharedStrings from 'sharedStrings';
import { saveLogMessage } from 'utils/saveLogMessage';
import { classSessionTopicCanBeDeleted } from 'utils/courseOrganizationFunctions';
import { useAppDispatch, useAppSelector } from 'store';
import addTopicToClassSession from 'store/actions/addTopicToClassSession';
import removeTopicFromClassSession from 'store/actions/removeTopicFromClassSession';
import updateClassSession from 'store/actions/updateClassSession';
import createUserTopicAndAddToClassSession from 'store/actions/createUserTopicAndAddToClassSession';
import TopicGrid from './Components/TopicGrid/TopicGrid';
import { SelectItemTypeEnum } from './coursePlanner.types';
import { ClassSessionApi } from 'types/backend/classSessions.types';
import { UnitApi } from 'types/backend/units.types';
import { TopicApi } from 'types/backend/topics.types';

/*
functions / changes that may be necessary as we integrate CP into the backend
- loading the unit and topic maps to classSessions and putting them in global state
- action to add/edit/delete a unit__session map - COMPLETE
- action to add/edit/delete a customUnit - COMPLETE
- action to add/edit/delete a customTopic - COMPLETE
- action to add/edit/delete a topic__session map - COMPLETE
- action to add/edit/delete additional classSessions of various types
- actions to handle turning 'template' units and topic into 'user' units and topics
*/

export default function SimpleCoursePlannerController() {
  const dispatch = useAppDispatch();
  const classSessionTopics = useAppSelector((store) => store.active.classSessionTopics);
  const classSessionLearningObjectives = useAppSelector((store) => store.active.classSessionLearningObjectives);
  const { subjectId: primarySubjectId } = useAppSelector((store) => store.active.course);

  const [alertMessage, setAlertMessage] = React.useState('');

  async function addItemToClassSession(
    itemId: number,
    itemType: SelectItemTypeEnum,
    classSessionId: number,
    parentId: number
  ) {
    console.debug(
      `item ${itemId} of type ${itemType} for session ${classSessionId} with parentId: ${parentId} will be added!`
    );
    if (itemType === SelectItemTypeEnum.Topic) {
      await dispatch(addTopicToClassSession(itemId, classSessionId));
      await saveLogMessage('OCP_action: addTopicToClassSession');
    }
  }

  async function removeItemFromClassSession(
    itemType: SelectItemTypeEnum,
    classSessionMapId: number
  ) {
    if (itemType === SelectItemTypeEnum.Topic) {
      if (classSessionTopicCanBeDeleted(classSessionMapId, classSessionTopics, classSessionLearningObjectives)) {
        await dispatch(removeTopicFromClassSession(classSessionMapId));
        await saveLogMessage('OCP_action: removeTopicFromClassSession');
      } else {
        const message = sharedStrings.REMOVE_TOPIC_MESSAGE;
        setAlertMessage(message);
        return;
      }


    }
  }

  async function addCustomItem(name: string, classSessionId: number) {
    console.debug(`item ${name} for session ${classSessionId}  will be added!`);
    if (classSessionId > 0) {
      await dispatch(
        createUserTopicAndAddToClassSession({
          topicName: name,
          classSessionId,
          subjectId: primarySubjectId,
        })
      );
      await saveLogMessage('OCP_action: createUserTopicAndAddToClassSession');
    }
  }

  async function copyUnitAndTopicToClassSession(classSessionId: number, unitItems: Array<UnitApi>, topicItems: Array<TopicApi>) {
    console.debug(`copy unit and topic to class session: ${classSessionId}`, unitItems, topicItems);
    unitItems.forEach(unitItem => addItemToClassSession(unitItem.id, SelectItemTypeEnum.Unit, classSessionId, 1000));
    topicItems.forEach(topicItem => addItemToClassSession(topicItem.id, SelectItemTypeEnum.Topic, classSessionId, 1000));
    await saveLogMessage('OCP_action: copyUnitAndTopicToClassSession');
  }

  function addRowBelowCurrentRow() {
    //this will need to gather input from the user about the date. Is there a default date that I can use?
    console.warn('addRowBelowCurrentRow NOT IMPLEMENTED');
  }

  function changeClassSessionType(classSessionId: number, typeOfSession: unknown) {
    //do we have a types column in classSessions? What are the options?
    console.warn('changeClassSessionType NOT IMPLEMENTED', typeOfSession);
  }

  function clearContentsFromClassSession(classSessionId: number) {
    //this is easy too - except for possible LO impacts
    console.warn('clearContentsFromClassSession NOT IMPLEMENTED', classSessionId);
  }

  async function saveClassSession(classSession: ClassSessionApi) {
    await dispatch(updateClassSession(classSession));
    await saveLogMessage('OCP_action: updateClassSession');
    return;
  }

  return (
    <div className="course-planner-controller">
      <TopicGrid
        onAddItemToClassSession={addItemToClassSession}
        onRemoveItemFromClassSession={removeItemFromClassSession}
        onAddCustomItem={addCustomItem}
        onCopyUnitAndTopicToClassSession={copyUnitAndTopicToClassSession}
        onAddRowBelowCurrentRow={addRowBelowCurrentRow}
        onChangeClassSessionType={changeClassSessionType}
        onClearContentsFromClassSession={clearContentsFromClassSession}
        controllerAlertMessage={alertMessage}
        setControllerAlertMessage={setAlertMessage}
        saveClassSession={saveClassSession}
      />
      <hr/>
    </div>
  );
}
