import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { AriaLiveAttribute } from 'shared-components/AriaAnnouncer/AriaLive.types';

function TitledRoute({
  title,
  ...props
}: RouteProps & {
  title: string
}) {
  useDocumentTitle(title);
  return (
    <>
      <span className="sr-only" aria-live={AriaLiveAttribute.Polite} id="titled-route-aria-live">{title}</span>
      <Route {...props} />
    </>
  );
}

export default TitledRoute;
