import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import {
  FaCheck,
  FaChevronLeft,
  FaChevronRight,
  FaInfoCircle,
  FaRedoAlt,
  FaRegStar,
} from 'react-icons/fa';
import CodonUrls from 'urls';
import { formatAssessmentItemPlural, formatPlural } from 'utils/commonFormattingFunctions';
import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import AnnouncerMessage from 'shared-components/AriaAnnouncer/AnnouncerMessage';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import ClarityRadioGroup from 'shared-components/ClarityRadioGroup/ClarityRadioGroup';
import {
  AttemptsHash,
  ClarityHash,
  DirectionEnum,
  PositionEnum,
  SurveyItemsHash,
} from 'types/common.types';
import { ClarityEnum, AssessmentLocation } from 'types/backend/shared.types';
import { AriaLiveAttribute } from 'shared-components/AriaAnnouncer/AriaLive.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { AssessmentTakerQuestionStage } from 'student/controllers/Course/AssessmentTakerController/AssessmentTakerController.types';
import './AssessmentTakerActionBar.scss';

const AssessmentTakerActionBar = forwardRef(({
  activeL8yRef,
  assessmentType,
  attemptLimit,
  attemptsHash = {},
  clarityHash = {},
  currentStage,
  disableClarity = false,
  enableFinish = true,
  enableValidate = true,
  handleClarity,
  handleFinish,
  handleNav,
  handleValidate,
  isStudyPath = false,
  itemHasQuestions,
  l8yRefArray,
  location,
  showFinish,
  showRecap,
  surveyItemsHash,
  vatIsFrozen,
}: {
  activeL8yRef: string
  assessmentType: AssessTypeEnum | null
  attemptLimit?: number | null
  attemptsHash?: AttemptsHash
  clarityHash?: ClarityHash
  currentStage: AssessmentTakerQuestionStage
  disableClarity?: boolean
  enableFinish?: boolean
  enableValidate?: boolean
  handleClarity: (clarity: ClarityEnum, l8yRef: string) => void
  handleFinish: () => void
  handleNav: (dir: DirectionEnum) => void
  handleValidate: () => void
  isStudyPath?: boolean
  itemHasQuestions: boolean
  l8yRefArray: Array<string>
  location: AssessmentLocation
  showFinish: boolean
  showRecap: boolean
  surveyItemsHash: SurveyItemsHash
  vatIsFrozen: boolean
}, ref: ForwardedRef<HTMLButtonElement>) => {
  const [showClarityHint, setShowClarityHint] = useState(false);
  const [validateButtonDisabled, setValidateButtonDisabled] = useState(!enableValidate);
  const currentItemAttempts: number = attemptsHash[activeL8yRef] || 0;

  // See if prev/next items exist at our current array index
  const activeItemIndex = l8yRefArray.indexOf(activeL8yRef);
  const prevQuestionL8yRef = l8yRefArray[activeItemIndex - 1];
  const nextQuestionL8yRef = l8yRefArray[activeItemIndex + 1];
  // making this a separate var in case we need other conditions besides last in array
  const isLastQuestion = !nextQuestionL8yRef;
  const isPreview = location === AssessmentLocation.Preview;
  const navText = `${activeItemIndex + 1} of ${l8yRefArray.length}`;
  const activeClarity = clarityHash[activeL8yRef];
  const activeSurveyItem = surveyItemsHash[activeL8yRef];

  const handleValidateClick = () => {
    // disable validate button on click, in case of network lag
    setValidateButtonDisabled(true);
    handleValidate();
  };
  const onClarityChange = (clarity: ClarityEnum) => {
    setShowClarityHint(false);
    handleClarity(clarity, activeL8yRef);
  };

  useEffect(() => {
    // hide clarity hint when question changes
    setShowClarityHint(false);
  }, [activeL8yRef]);

  useEffect(() => {
    // override local disable validate button when enableValidate prop changes post validate API call
    setValidateButtonDisabled(!enableValidate);
  }, [enableValidate]);

  // the latter condition of this ternary is 999 because TS needs it to be an integer
  // if attemptLimit is null it gets overridden by infiniteAttempts
  const attemptsRemaining = attemptLimit ? attemptLimit - currentItemAttempts : 999;
  const infiniteAttempts = attemptLimit === null;
  const hasAttemptsRemaining = infiniteAttempts || attemptsRemaining > 0;
  const itemNomenclature = formatAssessmentItemPlural(1, assessmentType);

  const renderClarity = () => {
    if (disableClarity) {
      return null;
    }
    if (activeSurveyItem) {
      return (
        <div className="at-action-bar__muddy-clear">
          <div className="at-action-bar__muddy-clear-explain survey-explain">
            This is a survey item. {externalLink(CodonUrls.GradingPolicyKB, 'Learn more.')}
          </div>
        </div>
      );
    }
    // only show hint if clarity is not selected
    const showHint = showClarityHint && !activeClarity;
    return (
      <div className="at-action-bar__muddy-clear">
        <div className="at-action-bar__muddy-clear-explain">
          Did you fully understand the concept covered in this {itemHasQuestions ? itemNomenclature : 'section'}?
        </div>
        <div className="at-action-bar__muddy-clear-buttons" data-show-hint={showHint}>
          <ClarityRadioGroup
            clarity={activeClarity || null}
            disabled={vatIsFrozen}
            id={activeL8yRef}
            onChange={onClarityChange}
          />
          {showHint && (
            <div className="clarity-hint-subtitle">
              <small>(Choose one)</small>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderMainButton = (stage: AssessmentTakerQuestionStage) => {

    const nextBtn = (
      <BetterButton
        aria-label={`Next ${itemNomenclature}`}
        icon={() => <FaChevronRight />}
        onClick={() => handleNav(DirectionEnum.Next)}
        text="Next"
        className="at-action-bar__next-question"
        ref={ref}
        primary
      />
    );

    if (isPreview) {
      return (
        <div className="at-action-bar__check-answer__wrap">
          {currentStage !== AssessmentTakerQuestionStage.VALIDATED_CORRECT && (
            <BetterButton
              onClick={() => handleValidateClick()}
              text={activeSurveyItem ? 'Enter' : 'Show Answer'}
              className="at-action-bar__check-answer"
              primary
            />
          )}
        </div>
      );
    }
    if (vatIsFrozen) {
      if (isLastQuestion) {
        // if last question, don't show next button because Finish button will show
        return null;
      }
      return nextBtn;
    } else if (stage === AssessmentTakerQuestionStage.VALIDATED_INCORRECT && hasAttemptsRemaining && assessmentType !== AssessTypeEnum.PracticeTest) {
      return (
        <div className="at-action-bar__post-validated">
          <div className="at-action-bar__post-validated-icon">
            <FaRedoAlt />
          </div>
          <div className="at-action-bar__post-validated-text" aria-atomic="true" aria-live={AriaLiveAttribute.Polite}>
            Select another option to try again<br />
            {!infiniteAttempts && <small>({attemptsRemaining} {formatPlural('attempt', attemptsRemaining)} remaining)</small>}
          </div>
        </div>
      );
    } else {
      return (
        <div className="at-action-bar__check-answer__wrap">
          {/* invisible overlay div to catch clicks on disabled Submit Answer button */}
          {currentStage !== AssessmentTakerQuestionStage.VALIDATED_CORRECT && (
            <>
              {validateButtonDisabled && (
                <button aria-label="Done button unavailable, select Muddy or Clear first" className="disabled-click-catcher" onClick={() => setShowClarityHint(true)}>&nbsp;</button>
              )}
              <BetterButton
                onClick={() => handleValidateClick()}
                icon={() => <FaCheck />}
                text={itemHasQuestions ? 'Submit Answer' : 'Submit'}
                className="at-action-bar__check-answer"
                primary
                disabled={validateButtonDisabled}
              />
            </>
          )}
          {currentStage === AssessmentTakerQuestionStage.VALIDATED_CORRECT && !enableFinish && !isLastQuestion && (
            <>
              {nextBtn}
              <AnnouncerMessage message={`Click the Next button to proceed to the next ${itemNomenclature}`} />
            </>
          )}
        </div>
      );
    }
  };

  function recaptureTooltipMessage(loc: AssessmentLocation) {
    switch (loc) {
      case AssessmentLocation.SP:
        return 'Answer this question correctly before the assignment late date to recapture points.';
      case AssessmentLocation.AT:
        return 'Recapture points on this question by retrying it in the Study Path before the assignment late date.';
      default:
        return;
    }
  }
  const recapTooltipMsg = recaptureTooltipMessage(location);

  // TODO: Show additional messaging (prompt for infinite answer checking) when in SP
  /***
   * Finish button logic
   *   - if currently on non-last question
   *     - if all questions are answered correctly SHOW
   *     - if all questions are answered but not correctly DON'T SHOW
   *     - never show disabled Finish button on non-last questions
   *   - if currently on last question SHOW
   *     - if last question is not answered yet DISABLED
   *     - if last question and all questions answered ENABLED
   *
   * Practice Test Finish behavior considerations
   * currently follows the above logic, but since you only get one answer there might be
   * a case to be made for the scenario where the user skips a question then goes back and
   * answers the question
   *
   * then the logic for PT would be "if all questions are answered"
   * as opposed to "if all questions are answered AND are correct"
   * TODO for a later PR
   */
  const recapIsAvailable = showRecap && !!recapTooltipMsg;
  return (
    <div className={`row at-action-bar action-bar-${location}`}>
      <div className="col-xs-12 col-sm-6">
        {renderClarity()}
      </div>
      <div className="at-action-bar__buttons col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div className="at-action-bar__validate">
          { renderMainButton(currentStage) }
          {!isPreview && (isLastQuestion || showFinish) && (enableFinish ? (
            <BetterButton
              onClick={handleFinish}
              text="Finish"
              className="at-action-bar__finish"
              primary
            />
          ) : (
            <BetterTooltip
              content={() => (
                <>
                  There are 1 or more unanswered {itemNomenclature}s in this assignment.
                </>
              )}
              inModal
              position={PositionEnum.Top}
            >
              <BetterButton
                icon={() => <FaInfoCircle />}
                text="Finish"
                className="at-action-bar__finish"
                primary
                disabled
              />
            </BetterTooltip>
          ))}
        </div>
        <div className="at-action-bar__nav-buttons">
          <div className="at-action-bar__can-recap">
            {recapIsAvailable && (
              <>
                <BetterTooltip
                  content={() => (
                    <>
                      {recapTooltipMsg}&nbsp;
                      {externalLink(CodonUrls.HowToRecaptureKB, 'Learn more.')}
                    </>
                  )}
                  inModal
                  position={PositionEnum.Top}
                >
                  <FaRegStar size={32} />
                </BetterTooltip>
                <AnnouncerMessage message={recapTooltipMsg} />
              </>
            )}
          </div>
          <BetterButton
            onClick={() => handleNav(DirectionEnum.Prev)}
            icon={() => <FaChevronLeft size={26}/>}
            text="Previous"
            aria-label={`Previous ${itemNomenclature}`}
            className="at-action-bar__nav-prev"
            showText={false}
            disabled={!prevQuestionL8yRef}
          />
          <div className="at-action-bar__nav-text">
            {!isStudyPath && navText}
            {/* For SR: e.g. "item/question 2 of 5" */}
            {!isStudyPath && <AnnouncerMessage message={`${itemNomenclature} ${navText}`} />}
          </div>
          <BetterButton
            onClick={() => handleNav(DirectionEnum.Next)}
            icon={() => <FaChevronRight size={26}/>}
            text="Next"
            aria-label={`Next ${itemNomenclature}`}
            className="at-action-bar__nav-next"
            showText={false}
            disabled={isLastQuestion}
          />
        </div>
      </div>
    </div>
  );
});

export default AssessmentTakerActionBar;
