import React, { useState } from 'react';
import { saveLogMessage } from 'utils/saveLogMessage';
import useScript from 'hooks/useScript';
import { useAppSelector } from 'store';
import './StudyPathInstructorGuide.scss';

export default function StudyPathInstructorGuide() {
  useScript('https://js.storylane.io/js/v2/storylane.js');
  const [showGuidedTour, setShowGuidedTour] = useState(true);
  const adminUser = useAppSelector((store) => store.adminUser);

  const handleToggleClick = (value: boolean) => {
    setShowGuidedTour(value);
    !adminUser && saveLogMessage(`INSTRUCTOR_AS_STUDENT: showGuidedTour - ${value}`);
  };

  return (
    <div className="study-path-instructor-guide">
      <div className="study-path__summary-bar row" data-instructor-view={true}>
        <div className="study-path__summary-bar-instructor-view">
          Because the Study Path is a personalized experience for each student, it does not load for instructors previewing the student experience.
          To try it for yourself as a student, return to My Courses and click 'Launch as Student' on an available course.
        </div>
      </div>
      <div className="study-path-instructor-guide__body">
        <div className="study-path-instructor-guide__content-toggle">
          <button
            data-selected={showGuidedTour}
            onClick={() => handleToggleClick(true)}
          >
            Guided Tour
          </button>
          <button
            data-selected={!showGuidedTour}
            onClick={() => handleToggleClick(false)}
          >
            Infographic
          </button>
        </div>
        <div className="study-path-instructor-guide__content">
          <div
            className="study-path-instructor-guide__embed"
            data-hidden={!showGuidedTour}
          >
            <iframe
              title="sp-guided-tour"
              loading="lazy"
              className="study-path-instructor-guide__storylane-demo"
              src="https://app.storylane.io/demo/ejqpncvdiae8?embed=inline"
              name="sl-embed"
              allow="fullscreen"
              allowFullScreen
            >
            </iframe>
          </div>
          <div className="study-path-instructor-guide__infographic" data-hidden={showGuidedTour}>
            <img src="/assets/study-path-info-graphic.png" alt="Study Path Info Graphic" />
          </div>
        </div>
      </div>
    </div>
  );
}
