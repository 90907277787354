import { History, LocationState } from 'history';
import { persistor } from 'store';
import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import adminUserSlice from 'store/slices/adminUser';
import enrollmentsSlice from 'store/slices/enrollments';
import passiveSlice from 'store/slices/passive';
import stateSlice from 'store/slices/state';
import userSlice from 'store/slices/user';
import { AppDispatch } from 'types/store.types';

export default async function logout(dispatch: AppDispatch, history?: History<LocationState>, queryString?: string) {
  await apiNext.revokeToken();
  await apiNext.logout(); // logout from feathers API
  dispatch(passiveSlice.actions.clear());
  dispatch(enrollmentsSlice.actions.clear());
  dispatch(userSlice.actions.clear());
  dispatch(activeSlice.actions.clear());
  dispatch(adminUserSlice.actions.clear());
  dispatch(stateSlice.actions.clear());
  localStorage.removeItem('user');
  localStorage.removeItem('userId');
  localStorage.removeItem('masqueradeUserId');
  await persistor.purge();
  if (history) {
    const redirectUrl = queryString ? `/?${queryString}` : '/';
    console.info(`Reloading at ${redirectUrl}`);
    window.location.href = redirectUrl; // force reload on logout
  }
}
