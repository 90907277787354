import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { DateTime } from 'luxon';

import apiNext from 'api-next';
import { getAssessmentStatus, getTargetTabFromAssessType } from 'utils/assessmentFunctions';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import TextButton from 'shared-components/BetterButton/TextButton';
import ClassRowCardContainer from './ClassRowCardContainer';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { BetterClassSession } from 'store/selectors/retrieveBetterClassSessions';
import { BCPViewMode, CoursePlannerAction, SharedBCPProps } from '../BetterCoursePlannerController';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { InstructorCoursePath } from 'types/instructor.types';
import { LoadingState } from 'types/common.types';

function CoursePlannerClassRowStudyPath({
  classSession,
  classSessionId,
  viewMode,
  handleCoursePlannerAction,
  isEditing = false,
  setIsEditing,
}: SharedBCPProps & {
  classSession: BetterClassSession
  isEditing?: boolean
  setIsEditing: (isEditing: boolean) => void
}) {
  const {
    groupedAssessments: { summative: summativeGroup = [] },
    classDate,
    classNumber,
  } = classSession;
  const [cardRef, inView] = useInView({ triggerOnce: true });
  const { pathname, search } = useLocation();

  const [loadingState, setLoadingState] = useState(LoadingState.Init);
  const [startedAssessmentIds, setStartedAssessmentIds] = useState<Array<string>>([]);
  const [studyPathName, setStudyPathName] = useState('');
  const [studyPathIsExpanded, setStudyPathIsExpanded] = useState(viewMode === BCPViewMode.Full);

  // I tried to do these in one fell swoop with a reduce but the array is so small it's cleaner to just do 2 loops
  const onlyPrepAndPracticeTest = summativeGroup.filter(({ assessType }) => assessType !== AssessTypeEnum.Summative);
  const { name: summativeAssessmentName = '' } = summativeGroup.find(({ assessType }) => assessType === AssessTypeEnum.Summative) || {};

  const totalQuestionCount = onlyPrepAndPracticeTest.reduce((acc, { questionCount }) => acc + questionCount, 0);

  useEffect(() => {
    // only fetch student assessments if card is expanded
    if (studyPathIsExpanded) {
      const getStudentAssessments = async (idsToGet: Array<string>) => apiNext.getStudentAssessmentsByAssessments(idsToGet);
      if (totalQuestionCount > 0) {
        // get student assessments when card is scrolled into view in order to display Started by Students column
        if (loadingState === LoadingState.Init && inView) { // if there are no questions in the assessment don't bother checking if started
          setLoadingState(LoadingState.Loading);
          const prepAndPracticeTestIds = onlyPrepAndPracticeTest.map(({ id }) => id);
          getStudentAssessments(prepAndPracticeTestIds).then((fetchedStudentAssessments) => {
            const updatedStartedAssessmentIds = prepAndPracticeTestIds.filter((assessmentId) => !!fetchedStudentAssessments.find((studentAssessment) => studentAssessment.assessmentId === assessmentId));
            setStartedAssessmentIds(updatedStartedAssessmentIds);
            setLoadingState(LoadingState.Loaded);
          });
        }
      }
    }
  }, [inView, onlyPrepAndPracticeTest, loadingState, studyPathIsExpanded, totalQuestionCount]);

  const handleCreatePlaceholder = async (action: CoursePlannerAction.CreateStudyPathPlaceholder | CoursePlannerAction.CreateStudyPathPlaceholderAndOpenSPB) =>
    handleCoursePlannerAction(action, {
      classSessionId,
      stringValue: studyPathName,
      classDate,
    }).then((result: boolean) => {
      !!result && setIsEditing(false);
    }).catch(console.error);


  if (isEditing) {
    const disableSave = !studyPathName.length;
    return (
      <ClassRowCardContainer
        cardLabel={`Add Study Path to Class ${classNumber}`}
        cardType="study-path"
        className="bcp__class-row__study-path"
      >
        <div className="bcp__class-row__study-path__content">
          <div className="bcp__class-row__study-path__body">
            <div className="bcp__class-row__study-path__body__input">
              <label htmlFor="study-path-title">Title</label>
              <input
                type="text"
                id="study-path-title"
                value={studyPathName}
                onChange={({ target }) => setStudyPathName(target.value)}
              />
            </div>
          </div>
          <div className="bcp__class-row__study-path__actions">
            <TextButton onClick={() => setIsEditing(false)}>Cancel</TextButton>
            <BetterButton
              className="new-button"
              disabled={disableSave}
              text="Add Items"
              secondary
              onClick={() => handleCreatePlaceholder(CoursePlannerAction.CreateStudyPathPlaceholderAndOpenSPB)}
            />
            <BetterButton
              className="new-button"
              disabled={disableSave}
              text="Create Placeholder"
              primary
              onClick={() => handleCreatePlaceholder(CoursePlannerAction.CreateStudyPathPlaceholder)}
            />
          </div>
        </div>
      </ClassRowCardContainer>
    );
  }

  const studyPathSummativeAssessment = summativeGroup.find((summ) => summ.assessType === AssessTypeEnum.Summative);
  // if not editing and no SP, don't show anything
  if (!studyPathSummativeAssessment || !summativeGroup?.length) {
    return null;
  }

  const containsStudyPathRgx = /(study path)/ig;
  const studyPathCardTitle = containsStudyPathRgx.test(summativeAssessmentName)
    ? summativeAssessmentName
    : `${summativeAssessmentName} Study Path`;
  const { id: summativeIdForAssessmentLink } = studyPathSummativeAssessment;
  const assessmentLinkPrefix = `${InstructorCoursePath.AssessmentBuilder}?class-session=${classSessionId}&type=${AssessTypeEnum.Summative}&assessment=${summativeIdForAssessmentLink}`;
  return (
    <ClassRowCardContainer
      cardLabel={studyPathCardTitle}
      cardType="study-path"
      className="bcp__class-row__study-path"
      isExpanded={studyPathIsExpanded}
      setIsExpanded={setStudyPathIsExpanded}
      handleClickTitle={() => setStudyPathIsExpanded(!studyPathIsExpanded)}
      data-bcp-view-mode={viewMode}
    >
      <table className="bcp__class-row__study-path__assessment-table" ref={cardRef}>
        <thead>
          <tr>
            <th>Title</th>
            <th>Status</th>
            <th>Questions</th>
            <th>Due</th>
          </tr>
        </thead>
        <tbody>
          {onlyPrepAndPracticeTest.map((assessment) => {
            const {
              assessType,
              id: assessmentId,
              dueDate: dueDateString,
              name: assessmentName,
              questionCount,
            } = assessment;
            const dueDate = DateTime.fromISO(dueDateString);
            const targetAssessmentBuilderTab = getTargetTabFromAssessType(assessType);

            const returnToLink = encodeURIComponent(`${pathname}${search}?csId=${classSessionId}`);
            const assessmentBuilderLink = `${assessmentLinkPrefix}&tab=${targetAssessmentBuilderTab}&returnTo=${returnToLink}`;
            const startedByStudents = startedAssessmentIds.includes(assessmentId);
            const assessmentStatus = getAssessmentStatus(startedByStudents, assessment.published);
            return (
              <tr
                data-assesstype={assessType}
                key={assessmentId}
              >
                <td>
                  <Link className="assessment-link" data-assessmentlink={AssessTypeEnum.Summative} to={assessmentBuilderLink}>
                    {assessmentName}
                  </Link>
                </td>
                <td className="small-text" data-assessmentstatus={assessmentStatus}>
                  {assessmentStatus}
                </td>
                <td>
                  {questionCount}
                </td>
                <td>
                  {dueDate.toFormat(DateFormatEnum.DateAtTimeMeridean)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </ClassRowCardContainer>
  );
}

export default CoursePlannerClassRowStudyPath;
