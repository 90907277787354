import React from 'react';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import './CancelMovingButton.scss';

function CancelMovingButton({ btnText, message, onClick }: {
  btnText: string
  message: string
  onClick: () => void
}) {
  return (
    <div className="bcp__cancel-moving-topic">
      <div className="bcp__cancel-moving-topic__container">
        <div className="bcp__cancel-moving-topic__message">
          {message}
        </div>
        <BetterButton
          className="bcp__cancel-moving-topic__cancel-button"
          onClick={onClick}
          primary
          text={btnText}
        />
      </div>
    </div>
  );
}

export default CancelMovingButton;
