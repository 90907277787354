import React, { useState, MouseEvent } from 'react';
import PropTypes from 'prop-types';

import BetterButton from 'shared-components/BetterButton/BetterButton';
import TextButton from 'shared-components/BetterButton/TextButton';
import { AccessCodeValidationResult } from './AccessController.types';
import { GenericObject } from 'types/backend/shared.types';

type AccessCodeFormProps = {
  submitAccessCode: (code: string) => Promise<AccessCodeValidationResult>
  handleCancel: () => void
  isEnrolledInstructor: boolean
}

function AccessCodeForm({ handleCancel, submitAccessCode, isEnrolledInstructor }: AccessCodeFormProps) {
  const initCodeObj: GenericObject = {
    'code-0': '',
    'code-1': '',
    'code-2': '',
    'code-3': '',
  };
  const [codeObj, setCodeObj] = useState(initCodeObj);
  const [validateResult, setValidateResult] = useState(null as AccessCodeValidationResult | null);
  const [showCodeFormatAlert, setShowCodeFormatAlert] = useState(false);
  const [disableValidate, setDisableValidate] = useState(true);

  const codeFormatOK = (codeData: GenericObject) => {
    const newCode = Object.values(codeData).toString();
    return /^[a-zA-Z,]+$/.test(newCode);
  };

  const validateCode = (code: GenericObject) => {
    const codeFormatIsOK = codeFormatOK(code);
    if (codeFormatIsOK === showCodeFormatAlert) {
      setShowCodeFormatAlert(!showCodeFormatAlert);
    }
    // enable validate only when the full code is entered and all the characters are valid
    const codeArray = Object.values(code);
    const fullValidCodeEntered = codeFormatIsOK && codeArray.length === 4 && codeArray.every((val) => val.length === 5);
    setDisableValidate(!fullValidCodeEntered);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { maxLength, value, name } = e.target;
    const [, fieldNumber] = name.split('-');
    // auto-focus next input if >= max length
    if (value.length >= maxLength) {
      if (parseInt(fieldNumber) < 3) {
        const nextSibling: HTMLElement | null = document.querySelector(`input[name=code-${parseInt(fieldNumber) + 1}]`);
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
    const updatedCodeObj = {
      ...codeObj,
      [name]: value,
    };
    validateCode(updatedCodeObj);
    setCodeObj(updatedCodeObj);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (e.clipboardData) {
      const pastedCode = e.clipboardData.getData('text');
      const pastedCodeClean = pastedCode.trim();
      const pastedCodeAsArray: Array<string> = pastedCodeClean.split('-');
      const codeObjForValidate = pastedCodeAsArray.reduce((acc, cur, i) => {
        return {
          ...acc,
          [`code-${i}`]: cur,
        };
      }, {});
      validateCode(codeObjForValidate);
      setCodeObj(codeObjForValidate);
    }
  };

  async function verifyAccessCode(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const accessCode = Object.values(codeObj).join('-').toUpperCase();
    const requestResult: AccessCodeValidationResult = await submitAccessCode(accessCode);
    setValidateResult(requestResult);
  }

  return (
    <div className="access-code-form__wrap">
      <div className="access-code-form__header">
        <b>Enter your Access Code</b>
        <TextButton onClick={() => handleCancel()}>cancel</TextButton>
      </div>
      <div className="access-code-form row">
        <div className="access-code-form__inputs col-xs-12 col-sm-8">
          {Object.keys(initCodeObj).map((inputName) => (
            <input
              type="text"
              maxLength={5}
              name={inputName}
              key={inputName}
              value={codeObj[inputName]}
              onChange={handleChange}
              onPaste={handlePaste}
              required
              disabled={isEnrolledInstructor}
            />
          ))}
        </div>
        <div className="access-code-form__verify col-xs-12 col-sm-4">
          <BetterButton
            className="access-code-form-button"
            primary
            text="Verify Code"
            onClick={verifyAccessCode}
            disabled={disableValidate}
          />
        </div>
      </div>
      <div className="access-code-result">
        {isEnrolledInstructor && (
          <div className="access-container__instructor-banner">
            Payment is not active for instructors.
          </div>
        )}
        {showCodeFormatAlert && (
          <div className="access-code-result__hint">
            (Codon access codes do not contain numbers or special characters)
          </div>
        )}
        {validateResult && !validateResult.success && (
          <>
            <div className="access-code-result__error">
              Verification unsuccessful: {validateResult.message}
            </div>
            <div className="access-code-result__hint">
              Please re-enter the code to try again, or contact us using the information below.
            </div>
          </>
        )}
      </div>
    </div>
  );
}

AccessCodeForm.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  submitAccessCode: PropTypes.func.isRequired,
  isEnrolledInstructor: PropTypes.bool,
};

export default AccessCodeForm;
