// sourced from https://github.com/codonlearning/backend/blob/dev/src/services/assessmentSummaryData/assessmentSummaryData.types.ts

import { ClarityEnum, YesNo } from 'types/backend/shared.types';
import { AssessmentDbOut } from 'types/backend/assessments.types';
import { StudentAssessmentQuestionApi, StudentAssessmentQuestionDb } from 'types/backend/studentAssessmentQuestions.types';

export enum AssessmentCompletionStatusEnum {
  Complete = 'complete',
  NotOpen = 'not-open',
  Open = 'open',
  Unpublished = 'unpublished'
}

export enum AssessmentSummaryViewEnum {
  Detail = 'detail',
  Summary = 'summary'
}

export interface AssessmentSummaryDataReq {
  courseId: string
  assessmentId: Array<string>
  view: AssessmentSummaryViewEnum
}

export interface AssessmentQuestionSummaryDataLevel2Api extends Pick<StudentAssessmentQuestionApi, 'studentAssessmentId' | 'assessmentQuestionId' | 'gradedAdjustedPointsEarned' | 'firstAttempted'> {
  firstStudentAssessmentQuestionAttemptClarity: ClarityEnum | null
  firstStudentAssessmentQuestionAttemptId: number | null
  firstStudentAssessmentQuestionAttemptIsCorrect: YesNo | null
}

export interface AssessmentQuestionSummaryDataLevel2Db extends Pick<StudentAssessmentQuestionDb, 'student_assessment__id' | 'assessment_question__id' | 'graded_adjusted_points_earned' | 'first_attempted'> {
  first_student_assessment_question_attempt_clarity: ClarityEnum | null
  first_student_assessment_question_attempt__id: number | null
  first_student_assessment_question_attempt_is_correct: YesNo | null
}

export interface AssessmentSummaryData {
  assessmentId: string
  assessmentStatus: AssessmentCompletionStatusEnum
  totalStudents: number
  startedStudentCount: number
  completedStudentCount: number
}

export type AssessmentDataForSummaryDb = Pick<AssessmentDbOut, 'id' | 'name' | 'assess_type' | 'published' | 'open_date' | 'due_date' | 'late_date'>
