import React, { useContext, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Select, { SingleValue } from 'react-select';
import { LoSelectorContext } from '../../CourseLoSelectorController';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import { DropdownOption } from 'instructor/components/Dropdown/Dropdown.types';
import './MoveTopicToClassSession.scss';

interface MoveTopicToClassSessionProps {
  classSessionTopicId: number
  loString: string
  otherClassSessions: Array<DropdownOption<number>>
  onCancel: () => void
  onMoveTopic: (classSessionTopicId: number, newClassSessionId: number) => void
}
function MoveTopicToClassSession({
  classSessionTopicId,
  loString,
  otherClassSessions,
  onCancel,
  onMoveTopic,
}: MoveTopicToClassSessionProps) {
  const { isProcessing } = useContext(LoSelectorContext);

  const [newClassSessionId, setNewClassSessionId] = useState(otherClassSessions[0] as SingleValue<DropdownOption<number>>);

  const handleMoveTopicToClassSession = () => {
    onMoveTopic(classSessionTopicId, newClassSessionId?.value as number);
  };

  return (
    <div className="move-topic-to-class-session">
      <div className="move-topic-to-class-session__header">
        <label htmlFor='newClassSession'>
          Move Topic{loString} to...
        </label>
        <BetterButton
          className="move-topic-to-class-session__close-button"
          onClick={onCancel}
          showText={false}
          text="Close"
          icon={() => <FaTimes/>}
        />
      </div>
      <div className="move-topic-to-class-session__body">
        <Select
          className="move-topic-to-class-session__select"
          name='newClassSession'
          options={otherClassSessions}
          value={newClassSessionId}
          onChange={setNewClassSessionId}
        />
        <BetterButton
          className="move-topic-to-class-session__move-button"
          disabled={isProcessing}
          onClick={handleMoveTopicToClassSession}
          primary
          text='Move'
        />
      </div>
    </div>
  );
}

MoveTopicToClassSession.propTypes = {
  classSessionTopicId: PropTypes.number.isRequired,
  loString: PropTypes.string.isRequired,
  otherClassSessions: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onMoveTopic: PropTypes.func.isRequired,
};

export default MoveTopicToClassSession;
