import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import Spinner from 'shared-components/Spinner/Spinner';
import TextButton from 'shared-components/BetterButton/TextButton';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { PositionEnum } from 'types/common.types';

const SkipForNow = ({ handleSkip }: { handleSkip: () => void }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const handleSkipConfirm = () => {
    setConfirmed(true);
    handleSkip();
  };
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        addEndListener={(node, done) => {
          node.addEventListener('transitionend', done, false);
        }}
        key={showConfirm ? 'skip' : 'confirm'}
        classNames="fade"
      >
        <div className="skip-for-now">
          {showConfirm ? (
            <>
              {confirmed && <Spinner />}
              <TextButton
                className="skip-for-now__confirm"
                onClick={handleSkipConfirm}
                disabled={confirmed}
                title="Are you sure you want to skip this card for now?"
              >
                Confirm
              </TextButton>
              <span>/</span>
              <TextButton
                className="skip-for-now__cancel"
                onClick={() => setShowConfirm(false)}
                disabled={confirmed}
                title="Cancel Skip for Now"
              >
                Cancel
              </TextButton>
            </>
          ) : (
            <BetterTooltip
              position={PositionEnum.Right}
              className='skip-for-now__tooltip'
              content='Click "Skip for now" to move this Topic Card to the next Checkpoint. You can still access
                skipped questions, but we recommend you avoid skipping so that you can monitor topics that you know
                and don&apos;t know.'
            >
              <TextButton
                className="skip-for-now__skip"
                onClick={() => setShowConfirm(true)}
              >
                Skip for now
              </TextButton>
            </BetterTooltip>
          )}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

SkipForNow.propTypes = {
  handleSkip: PropTypes.func.isRequired,
};

export default SkipForNow;
