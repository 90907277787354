//sourced from https://github.com/codonlearning/backend/blob/dev/src/services/systemReports/systemReports.types.ts

import { AssessmentDbOut } from './assessments.types';
import { CourseDbIn, CourseDbOut } from './courses.types';
import { YesNo } from './shared.types';
import { UserDb } from './users.types';

export enum SystemReportsEnum {
  SaqasByTime = 'saqas-by-time',
  SaqaProjection = 'saqa-projection',
  EnrollmentAndPayment = 'enrollment-and-payment'
}

export enum TimeGroupByEnum {
  Hour = 'hour',
  Day = 'day',
  Minute = 'minute'
}

export interface SystemReportsRequest {
  report: string
  includeCodonEmail: string
  courseStartDate: string
  assessmentDueDate: string
  showGreatestHits: string
  timeGroupBy: TimeGroupByEnum
  avgAttemptsPerQuestion?: number
  loadSaqaData?: YesNo
}

export enum ReportParamsEnum {
  IncludeCodonEmail = 'include-codon-email',
  CourseStartDate = 'course-start-date',
  AssessmentDueDate = 'assessment-due-date',
  GreatestHits = 'greatest-hits',
  TimeGroupBy = 'time-group-by',
  DailyProjection = 'daily-projection',
  loadSaqaData = 'show-saqa-data',
}

export interface SaqasByTime {
  question_attempt_date_time: string
  question_attempt_count: number
}

export interface EnrollmentAndPayment extends Pick<UserDb, 'first_name' | 'last_name'>, Pick<CourseDbIn, 'title' | 'start_date'> {
  course_id: string
  discipline: string
  start_date: string
  enrollment_count: number
  product_title: string
  show_cc: YesNo
  show_code: YesNo
  institution_name: string
  institution_location: string
  have_loaded_chp: number
  have_paid: number
  assessment_count: number
  student_assessment_count: number | null
  student_assessment_question_count: number | null
  student_assessment_question_attempt_count: number | null
}

export interface SaqaProjection extends Pick<UserDb, 'email'>, Pick<AssessmentDbOut, 'assess_type' | 'published'>, Pick<CourseDbOut, 'time_zone'> {
  course_name: string
  assessment_id: number
  question_count: number
  enrollment_count: number
  impact: number
  student_assessments_started: number
  student_attempts_made: number
  due_date: string
}
