import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';

function DateTimePicker({
  className,
  dateFormat = DateFormatEnum.DatePickerDisplayFormat,
  disabled,
  id,
  invalid = false,
  limitTime = false, // when set to true, time selection will be limited to future times only on the current day
  maxDate,
  minDate,
  name,
  onChange,
  value,
}: {
  className: string
  dateFormat?: DateFormatEnum
  disabled?: boolean
  id?: string
  invalid?: boolean
  limitTime?: boolean
  maxDate: Date
  minDate: Date
  name: string
  onChange: (updatedDate: Date) => void
  value: Date | null
}) {

  const roundUpToNextHalfHour = (date: Date) => {
    // this function always rounds up, so 3:30 becomes 4:00. That is OK as we always want a future time.
    const minutes = date.getMinutes();
    const addToNextHalfHour = 30 - (minutes % 30);
    date.setMinutes(minutes + addToNextHalfHour);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };

  const getMinAndMaxTimesForDate = useCallback((date: Date | null) => {
    if (!date || !limitTime) {
      return [undefined, undefined];
    }
    const now = DateTime.now();
    const dateAsDateTime = DateTime.fromJSDate(date);
    let minTimeValue;
    if (dateAsDateTime < now.startOf('day')) {
      // Value is in the past, no times should be selectable
      minTimeValue = now.endOf('day');
    } else if (dateAsDateTime.hasSame(now, 'day')) {
      // Value is today, times in the future only
      if (now < now.endOf('day').minus({ minutes: 10 })) {
        minTimeValue = now.plus({ minutes: 10 }); // add 10 minutes to reduce the chance of a past time being submitted
      } else {
        minTimeValue = now.endOf('day'); // unless it is within 10 minutes of the end of the day, then no times should be selectable
      }
    } else {
      // Value is in the future, any time is selectable
      minTimeValue = dateAsDateTime.startOf('day');
    }
    return [minTimeValue.toJSDate(), DateTime.now().endOf('day').toJSDate()];
  }, [limitTime]);

  const [initialMinTime, initialMaxTime] = getMinAndMaxTimesForDate(value);

  const [localDateValue, setLocalDateValue] = useState(!!value ? DateTime.fromJSDate(value) : null);
  const [minTime, setMinTime] = useState(initialMinTime);
  const [maxTime, setMaxTime] = useState(initialMaxTime);
  const selected = !!value ? new Date(value) : value;
  const cssClassName = `${className}${invalid ? ' invalid' : ''}`;

  // this useEffect is used to update the minTime when the startDate changes
  // which is necessary because the minTime doesn't need to be limited in the future
  // and should be end of day if the startDate is in the past
  useEffect(() => {
    if (limitTime && localDateValue) {
      const [newMinTime, newMaxTime] = getMinAndMaxTimesForDate(localDateValue.toJSDate());
      setMinTime(newMinTime);
      setMaxTime(newMaxTime);
    }
  }, [localDateValue, limitTime, getMinAndMaxTimesForDate]);

  const handleDateChange = (date: Date) => {
    if (!date) {
      return;
    }
    // The onChange event fires when the date is selected even before the user picks a time.
    // When this happens (and limitTime === true) the selected time needs to be set to the future. In this case, the nearest future half-hour.
    if (limitTime && minTime) {
      const now = DateTime.now();
      const selectedDate = DateTime.fromJSDate(date);
      if (selectedDate.hasSame(now, 'day') && selectedDate < now) {
        const roundedMinTime = roundUpToNextHalfHour(now.toJSDate());
        setLocalDateValue(DateTime.fromJSDate(roundedMinTime));
        onChange(roundedMinTime);
        return;
      }
    }
    setLocalDateValue(DateTime.fromJSDate(date));
    onChange(date);
  };

  return (
    <DatePicker
      id={id}
      className={cssClassName}
      dateFormat={dateFormat}
      disabled={disabled}
      maxDate={maxDate}
      minDate={minDate}
      minTime={minTime}
      maxTime={maxTime}
      name={name}
      onChange={handleDateChange}
      selected={selected}
      showTimeSelect
    />
  );
}

DateTimePicker.propTypes = {
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
};

export default DateTimePicker;
