import React from 'react';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useAppSelector } from 'store';
import retrieveEnrolledCourses from 'store/selectors/retrieveEnrolledCourses';
import CourseList from 'shared-components/CourseList/CourseList';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';

function StudentHome() {
  useDocumentTitle('My Courses');
  const enrolledCourses = useAppSelector(retrieveEnrolledCourses);
  const loading = useAppSelector((store) => store.passive.loading);
  if (loading) {
    return <LoadingSpinner id="student-passive-loading" />;
  }
  return (
    <main className="student-home">
      <CourseList courses={enrolledCourses} />
    </main>
  );
}

export default StudentHome;
