import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaRegFrown } from 'react-icons/fa';
import { saveLogMessage } from 'utils/saveLogMessage';
import { useTimeout } from 'hooks/useTimeout';
import CodonUrls from 'urls';
import Spinner from './Spinner';
import styles from 'style-config.scss';
import './Spinner.scss';

const LoadingSpinner = ({
  id = 'default',
  loadingMessage,
  shouldTimeout = true,
  timeoutInSeconds = 30,
}: {
  id?: string
  loadingMessage?: string | JSX.Element
  shouldTimeout?: boolean
  timeoutInSeconds?: number
}) => {
  const [showTimeout, setShowTimeout] = useState(false);

  const triggerTimeout = async () => {
    await saveLogMessage(`LoadingSpinner ${id} timed out`);
    setShowTimeout(true);
  };

  useTimeout(triggerTimeout, shouldTimeout ? timeoutInSeconds * 1000 : null);

  const messageToShow = !showTimeout ? loadingMessage : (
    <span>
      Oops! Loading has timed out.<br/>
      Try reloading the page, or <a href={CodonUrls.SupportKB} rel="noreferrer noopener" target="_blank">visit our support page</a>
    </span>
  );
  return (
    <div className={`loading-spinner ${showTimeout ? 'timed-out' : ''}`}>
      {showTimeout ? <FaRegFrown color={styles.gray} size={96} /> : <Spinner size="6em" />}
      <div className="loading-spinner__loading-text">{messageToShow}</div>
    </div>
  );
};

LoadingSpinner.propTypes = {
  loadingMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  shouldTimeout: PropTypes.bool,
  timeoutInSeconds: PropTypes.number,
};

export default LoadingSpinner;
