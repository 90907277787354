import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';

export default function addTopicToClassSession(topicId: number, classSessionId: number) {
  return (dispatch: AppDispatch, getState: () => Store) =>
    (async () => {
      const { active } = getState();

      if (active.classSessionTopics.some(cst => cst.topicId === topicId && cst.classSessionId === classSessionId)) {
        console.debug('topic already exists in classSession');
        return;
      }

      //make the change on the server:
      const createdClassSessionTopic = await apiNext.createClassSessionTopic({
        classSessionId,
        topicId,
      });

      return dispatch(activeSlice.actions.addActiveClassSessionTopic(createdClassSessionTopic));
    })();
}
