import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { DateTime } from 'luxon';

import { determineFreeAttemptsAndPointPenalty, determineMultipleAttemptPolicy, inferGradingPolicy } from 'utils/assessmentFunctions';
import { formatPlural, getAssessTypeShortName } from 'utils/commonFormattingFunctions';
import LoadingButton from 'shared-components/LoadingButton/LoadingButton';
import CombinedAttemptsPolicy from 'instructor/controllers/Course/AssessmentBuilderController/components/AssessmentDetails/CombinedAttemptsPolicy';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import ToggleSwitch from 'shared-components/ToggleSwitch/ToggleSwitch';
import { MultipleAttemptPolicyEnum } from 'types/common.types';
import { Store } from 'types/store.types';
import { AssessTypeEnum, GradingPolicyEnum } from 'types/backend/assessments.types';
import { CourseAssessmentPresetApi } from 'types/backend/courseAssessmentPresets.types';
import { YesNo } from 'types/backend/shared.types';

enum PresetPropertyNamesEnum {
  preclassOpenTime = 'preclassOpenTime',
  homeworkOpenTime = 'homeworkOpenTime',
  preclassDueTime = 'preclassDueTime',
  homeworkDueTime = 'homeworkDueTime',
  studyPathDueTime = 'studyPathDueTime'
}

enum PresetTabEnum {
  Homework = 'homework',
  PreClass = 'preclass',
  StudyPath = 'study-path'
}

function CourseAssessmentPresets({ onCancel, onSavePreset, presetSubmitEnabled, setPresetSubmitEnabled }: {
  onSavePreset: (preset: CourseAssessmentPresetApi, updateExistingAssessments: boolean) => void
  onCancel: () => void
  presetSubmitEnabled: boolean
  setPresetSubmitEnabled: (val: boolean) => void
}) {
  const [submitting, setSubmitting] = useState(false);
  const [selectedTab, setSelectedTab] = useState(PresetTabEnum.PreClass);
  const submitDisabled = !presetSubmitEnabled || submitting;
  const existingCap = useSelector((store: Store) => store.active.courseAssessmentPreset);
  const assessments = useSelector((store: Store) => store.active.assessments);
  const adminUser = useSelector((store: Store) => store.adminUser);
  const [updatedCap, setUpdatedCap] = useState(existingCap);

  const preclassMap = () => determineMultipleAttemptPolicy(
    updatedCap.preclassFreeAttempts,
    updatedCap.preclassPointPenalty,
    updatedCap.preclassGradingPolicy
  );
  const homeworkMap = () => determineMultipleAttemptPolicy(
    updatedCap.homeworkFreeAttempts,
    updatedCap.homeworkPointPenalty,
    updatedCap.homeworkGradingPolicy
  );
  const prepMap = () => determineMultipleAttemptPolicy(
    updatedCap.studyPathPrepFreeAttempts,
    updatedCap.studyPathPrepPointPenalty,
    updatedCap.studyPathPrepGradingPolicy
  );
  const practiceMap = () => determineMultipleAttemptPolicy(
    updatedCap.studyPathPracticeFreeAttempts,
    updatedCap.studyPathPracticePointPenalty,
    updatedCap.studyPathPracticeGradingPolicy
  );

  const openLateOffsetOptions = Array.from({ length: 21 }, (x, i) => ({ value: i + 1, label: `${i + 1} ${formatPlural('day', i + 1)}` }));

  // this simple useEffect keeps the save button active or inactive depending on if changes have been made
  useEffect(() => {
    const hasChanged = JSON.stringify(existingCap) !== JSON.stringify(updatedCap);
    setPresetSubmitEnabled(hasChanged);
  }, [updatedCap, existingCap, setPresetSubmitEnabled]);

  // this useEffect resets some form values if the existingCap change in Redux
  useEffect(() => {
    setSubmitting(false);
    setUpdatedCap(existingCap);
  }, [existingCap]);

  async function handleSave() {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    return onSavePreset(updatedCap, false);
  }

  async function handleSaveAndApplyToExisting() {
    if (submitting) {
      return;
    }
    return onSavePreset(updatedCap, true);
  }

  const handleSetPolicy = (dropdownType: AssessTypeEnum, policy: MultipleAttemptPolicyEnum) => {
    const result = determineFreeAttemptsAndPointPenalty(policy);
    const newCap = { ...updatedCap };
    switch (dropdownType) {
      case AssessTypeEnum.Homework:
        newCap.homeworkPointPenalty = result.pointPenalty;
        newCap.homeworkFreeAttempts = result.freeAttempts;
        newCap.homeworkGradingPolicy = inferGradingPolicy(policy);
        if (newCap.homeworkGradingPolicy === GradingPolicyEnum.NoPoints) {
          newCap.homeworkLatePolicy = YesNo.No;
        }
        break;
      case AssessTypeEnum.Preclass:
        newCap.preclassPointPenalty = result.pointPenalty;
        newCap.preclassFreeAttempts = result.freeAttempts;
        newCap.preclassGradingPolicy = inferGradingPolicy(policy);
        if (newCap.preclassGradingPolicy === GradingPolicyEnum.NoPoints) {
          newCap.preclassLatePolicy = YesNo.No;
        }
        break;
      case AssessTypeEnum.Prep:
        newCap.studyPathPrepPointPenalty = result.pointPenalty;
        newCap.studyPathPrepFreeAttempts = result.freeAttempts;
        newCap.studyPathPrepGradingPolicy = inferGradingPolicy(policy);
        if (newCap.studyPathPrepGradingPolicy === GradingPolicyEnum.NoPoints) {
          newCap.studyPathPrepLatePolicy = YesNo.No;
        }
        break;
      case AssessTypeEnum.PracticeTest:
      default:
        newCap.studyPathPracticePointPenalty = result.pointPenalty;
        newCap.studyPathPracticeFreeAttempts = result.freeAttempts;
        newCap.studyPathPracticeGradingPolicy = inferGradingPolicy(policy);
        if (newCap.studyPathPracticeGradingPolicy === GradingPolicyEnum.NoPoints) {
          newCap.studyPathPracticeLatePolicy = YesNo.No;
        }
        break;
    }
    setUpdatedCap(newCap);
  };

  const onChangeTime = (updatedTime: Date, propertyName: PresetPropertyNamesEnum) => {
    const newTime = DateTime.fromJSDate(updatedTime).toFormat('HH:mm:ss');
    setUpdatedCap({
      ...updatedCap,
      [propertyName]: newTime,
    });
  };

  const timeConversion = (time: string) => {
    const convertedTime = DateTime.fromISO(time).toJSDate();
    return convertedTime;
  };

  const handleOpenDaysSelectChange = (days: number, tab: PresetTabEnum, cap: CourseAssessmentPresetApi) => {
    const newCap = { ...cap };
    if (tab === PresetTabEnum.PreClass) {
      newCap.preclassOpenDateOffset = days;
    } else {
      newCap.homeworkOpenDateOffset = days;
    }
    setUpdatedCap(newCap);
  };

  const handleLatePolicyChange = (hasLatePolicy: YesNo, assessType: AssessTypeEnum, cap: CourseAssessmentPresetApi) => {
    const newCap = { ...cap };
    switch (assessType) {
      case AssessTypeEnum.Preclass:
        newCap.preclassLatePolicy = hasLatePolicy;
        break;
      case AssessTypeEnum.Homework:
        newCap.homeworkLatePolicy = hasLatePolicy;
        break;
      case AssessTypeEnum.Prep:
        newCap.studyPathPrepLatePolicy = hasLatePolicy;
        break;
      case AssessTypeEnum.PracticeTest:
        newCap.studyPathPracticeLatePolicy = hasLatePolicy;
        break;
    }
    setUpdatedCap(newCap);
  };

  const handleLateDaysSelectChange = (days: number, assessType: AssessTypeEnum, cap: CourseAssessmentPresetApi) => {
    const newCap = { ...cap };
    switch (assessType) {
      case AssessTypeEnum.Preclass:
        newCap.preclassLateDateOffset = days;
        break;
      case AssessTypeEnum.Homework:
        newCap.homeworkLateDateOffset = days;
        break;
      case AssessTypeEnum.Prep:
        newCap.studyPathPrepLateDateOffset = days;
        break;
      case AssessTypeEnum.PracticeTest:
        newCap.studyPathPracticeLateDateOffset = days;
        break;
    }
    setUpdatedCap(newCap);
  };

  const handleLatePenaltyChange = (penalty: number, assessType: AssessTypeEnum, cap: CourseAssessmentPresetApi) => {
    const newCap = { ...cap };
    switch (assessType) {
      case AssessTypeEnum.Preclass:
        newCap.preclassLatePenalty = penalty;
        break;
      case AssessTypeEnum.Homework:
        newCap.homeworkLatePenalty = penalty;
        break;
      case AssessTypeEnum.Prep:
        newCap.studyPathPrepLatePenalty = penalty;
        break;
      case AssessTypeEnum.PracticeTest:
        newCap.studyPathPracticeLatePenalty = penalty;
        break;
    }
    setUpdatedCap(newCap);
  };

  const renderTab = (tab: PresetTabEnum) => {
    let tabText;
    switch (tab) {
      case PresetTabEnum.PreClass:
        tabText = (<>{getAssessTypeShortName(AssessTypeEnum.Readiness)} /<br/> {getAssessTypeShortName(AssessTypeEnum.Preclass)}</>);
        break;
      case PresetTabEnum.Homework:
        tabText = getAssessTypeShortName(AssessTypeEnum.Homework);
        break;
      case PresetTabEnum.StudyPath:
      default:
        tabText = getAssessTypeShortName(AssessTypeEnum.Summative);
    }
    return (
      <button
        className={`assessment-presets-tab ${selectedTab === tab ? 'selected' : 'not-selected'}`}
        onClick={() => setSelectedTab(tab)}
      >
        {tabText}
      </button>
    );
  };

  const renderDueTimeRow = (propertyName: PresetPropertyNamesEnum, dueTime: string, infoText: string) => {
    return (
      <div className="assessment-presets-form-row">
        <div className="form__field">
          <div className="preset-form-row-header">
            Due:
            <span className="header-details">{infoText}</span>
          </div>
          <div className="preset-form-time-input">
            <label htmlFor="preset-form-time-of-day">
              Time of Day
              <DatePicker
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                name="preset-form-time-of-day"
                onChange={(timeVal) => !!timeVal && onChangeTime(timeVal, propertyName)}
                selected={timeConversion(dueTime)}
                className="preset-form-select"
              />
            </label>
          </div>
        </div>
      </div>
    );
  };

  const renderLatePolicy = (assessType: AssessTypeEnum, gradingPolicy: GradingPolicyEnum, latePolicy: YesNo, lateDateOffset: number, latePenalty: number) => {
    let headerText = 'Late Policy:';
    let detailsText = 'assessments';
    if (AssessTypeEnum.Prep === assessType) {
      headerText = 'Late Policy for Prep Questions:';
      detailsText = 'Study Paths';
    } else if (AssessTypeEnum.PracticeTest === assessType) {
      headerText = 'Late Policy for Practice Test:';
      detailsText = 'Study Paths';
    }
    const gradingPolicyIsNoPoints = gradingPolicy === GradingPolicyEnum.NoPoints;
    const latePolicyIsOn = latePolicy === YesNo.Yes;
    return (
      <div className="assessment-presets-form-row">
        <div className="preset-form-row-header">
          {headerText}
          {[AssessTypeEnum.Prep, AssessTypeEnum.PracticeTest].includes(assessType) && (<br/>)}
          <span className="header-details" data-assesstype={assessType}>Late dates will be set when you create {detailsText}</span>
        </div>
        <div className="preset-form-field-row">
          <div className="form__field toggle-row">
            <ToggleSwitch
              id={`${assessType}-late-policy-toggle`}
              checked={latePolicyIsOn}
              disabled={gradingPolicyIsNoPoints}
              onChange={() => handleLatePolicyChange(latePolicyIsOn ? YesNo.No : YesNo.Yes, assessType, updatedCap)}
              data-on="YES"
              data-off="NO"
            />
          </div>
          <div className="preset-late-policy-row">
            <div className="form__field preset-late-offset">
              <span className={gradingPolicyIsNoPoints || !latePolicyIsOn ? 'is-disabled' : ''}>
                Accept late until &nbsp;
              </span>
              <Select
                options={openLateOffsetOptions}
                value={openLateOffsetOptions.find(({ value }) => value === lateDateOffset)}
                className="form-select"
                isDisabled={gradingPolicyIsNoPoints || !latePolicyIsOn}
                name="days-after-due-date"
                onChange={(option) => !!option && handleLateDaysSelectChange(option.value, assessType, updatedCap)}
              />
              <span className={gradingPolicyIsNoPoints || !latePolicyIsOn ? 'is-disabled' : ''}>
                &nbsp; after the due date
              </span>
            </div>
            <div className="form__field">
              <label
                className={gradingPolicyIsNoPoints || !latePolicyIsOn ? 'is-disabled' : ''}
                htmlFor="preset-form-late-penalty-input"
              >
                and deduct &nbsp;
                <input
                  type="number"
                  name="preset-form-late-penalty-input"
                  value={latePenalty}
                  onChange={(e) => handleLatePenaltyChange(parseInt(e.target.value), assessType, updatedCap)}
                  min="0"
                  max="100"
                  disabled={gradingPolicyIsNoPoints || !latePolicyIsOn}
                />
                % of points
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFormForPreOrHW = (tab: PresetTabEnum) => {
    const isPreclass = tab === PresetTabEnum.PreClass;
    const openDateOffset = isPreclass ? updatedCap.preclassOpenDateOffset : updatedCap.homeworkOpenDateOffset;
    const lateDateOffset = isPreclass ? updatedCap.preclassLateDateOffset : updatedCap.homeworkLateDateOffset;
    const dueTime = isPreclass ? updatedCap.preclassDueTime : updatedCap.homeworkDueTime;
    const openTime = isPreclass ? updatedCap.preclassOpenTime : updatedCap.homeworkOpenTime;
    const openPropertyName = isPreclass ? PresetPropertyNamesEnum.preclassOpenTime : PresetPropertyNamesEnum.homeworkOpenTime;
    const duePropertyName = isPreclass ? PresetPropertyNamesEnum.preclassDueTime : PresetPropertyNamesEnum.homeworkDueTime;
    const latePenalty = isPreclass ? updatedCap.preclassLatePenalty : updatedCap.homeworkLatePenalty;
    const latePolicy = isPreclass ? updatedCap.preclassLatePolicy : updatedCap.homeworkLatePolicy;
    const gradingPolicy = isPreclass ? updatedCap.preclassGradingPolicy : updatedCap.homeworkGradingPolicy;
    return (
      <>
        <div className="assessment-presets-form-row">
          <CombinedAttemptsPolicy
            assessType={isPreclass ? AssessTypeEnum.Preclass : AssessTypeEnum.Homework}
            isDisabled={false}
            label="Grading Policy"
            onChange={handleSetPolicy}
            value={isPreclass ? preclassMap() : homeworkMap()}
          />
        </div>
        <div className="assessment-presets-form-row">
          <div className="preset-form-row-header">
            Open To Students:
          </div>
          <div className="preset-form-field-row">
            <div className="form__field preset-form-field-row-cell">
              <label htmlFor="days-before-due-date">
                Days Before Due Date
                <Select
                  options={openLateOffsetOptions}
                  value={openLateOffsetOptions.find(({ value }) => value === openDateOffset)}
                  className="form-select"
                  name="days-before-due-date"
                  onChange={(option) => !!option && handleOpenDaysSelectChange(option.value, tab, updatedCap)}
                />
              </label>
            </div>
            <div className="form__field preset-form-time-input">
              <label htmlFor="preset-form-time-open-select">
                Time Open
                <DatePicker
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="h:mm aa"
                  name="preset-form-time-open-select"
                  onChange={(timeVal) => !!timeVal && onChangeTime(timeVal, openPropertyName)}
                  selected={timeConversion(openTime)}
                  className="preset-form-select"
                />
              </label>
            </div>
          </div>
        </div>
        {renderDueTimeRow(duePropertyName, dueTime, 'Due dates will be set when you create assessments')}
        {renderLatePolicy(isPreclass ? AssessTypeEnum.Preclass : AssessTypeEnum.Homework, gradingPolicy, latePolicy, lateDateOffset, latePenalty)}
      </>
    );
  };

  const renderFormForStudyPath = () => {
    const {
      studyPathPrepLatePolicy: prepLatePolicy,
      studyPathPrepLateDateOffset: prepLateDateOffset,
      studyPathPrepLatePenalty: prepLatePenalty,
      studyPathPrepGradingPolicy: prepGradingPolicy,
      studyPathPracticeLatePolicy: practiceLatePolicy,
      studyPathPracticeLateDateOffset: practiceLateDateOffset,
      studyPathPracticeLatePenalty: practiceLatePenalty,
      studyPathPracticeGradingPolicy: practiceGradingPolicy,
    } = updatedCap;
    return (
      <>
        <div className="assessment-presets-form-row">
          <CombinedAttemptsPolicy
            assessType={AssessTypeEnum.Prep}
            isDisabled={false}
            label="Grading Policy for Prep Questions"
            onChange={handleSetPolicy}
            value={prepMap()}
          />
        </div>
        <div className="assessment-presets-form-row">
          <CombinedAttemptsPolicy
            assessType={AssessTypeEnum.PracticeTest}
            isDisabled={false}
            label="Grading Policy for Practice Test"
            onChange={handleSetPolicy}
            value={practiceMap()}
          />
        </div>
        {renderDueTimeRow(PresetPropertyNamesEnum.studyPathDueTime, updatedCap.studyPathDueTime, 'Due dates will be set when you create Study Paths')}
        {renderLatePolicy(AssessTypeEnum.Prep, prepGradingPolicy, prepLatePolicy, prepLateDateOffset, prepLatePenalty)}
        {renderLatePolicy(AssessTypeEnum.PracticeTest, practiceGradingPolicy, practiceLatePolicy, practiceLateDateOffset, practiceLatePenalty)}
      </>
    );
  };

  return (
    <div className="course-details">
      <div className="course-details__title">Preset Rules</div>
      <div className="assessment-presets-tab-wrapper">
        {renderTab(PresetTabEnum.PreClass)}
        {renderTab(PresetTabEnum.Homework)}
        {renderTab(PresetTabEnum.StudyPath)}
        <div className="assessment-presets-tab-spacer"></div>
      </div>
      <div className={`assessment-presets-form-box ${selectedTab === PresetTabEnum.PreClass && 'first-tab'}`}>
        {selectedTab === PresetTabEnum.StudyPath
          ? renderFormForStudyPath()
          : renderFormForPreOrHW(selectedTab)
        }
        <div className="assessment-presets-form-row button-row">
          <LoadingButton
            className="preset-form__submit"
            disabled={submitDisabled}
            loadingText="Saving..."
            loading={submitting}
            onClick={() => handleSave()}
            text={`Save presets${adminUser ? ' (will only apply to new assessments)' : ''}`}
          />
          {adminUser && !!assessments.length && (
            <>
              <div className="button-spacer"/>
              <BetterButton
                onClick={handleSaveAndApplyToExisting}
                disabled={submitDisabled}
                primary
                text="Save presets and apply to existing assessments"
              />
            </>
          )}
          <BetterButton
            onClick={onCancel}
            secondary
            text="Cancel"
          />
        </div>
      </div>
    </div>
  );
}

CourseAssessmentPresets.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSavePreset: PropTypes.func.isRequired,
  presetSubmitEnabled: PropTypes.bool.isRequired,
  setPresetSubmitEnabled: PropTypes.func.isRequired,
};

export default CourseAssessmentPresets;
