// sourced from https://github.com/codonlearning/backend/blob/dev/src/services/l8ySessions/l8ySessions.types.ts

export enum L8ySessionState {
  initial = 'initial',
  resume = 'resume',
  review = 'review'
}

export interface L8ySessionDb {
  id: string
  state: L8ySessionState
  created_at: string
  updated_at: string
  enrollment__id: number
}

export interface L8ySessionApi {
  id: string
  state: L8ySessionState
  createdAt: string
  updatedAt: string
  enrollmentId: number
}
