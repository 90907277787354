import React from 'react';
import './Home.scss';


function Home() {
  return (
    <div className="admin-home">
      <h2>Admin Dashboard</h2>
      <p>Click the Users tab and search for a user to begin a support masquerade session</p>
    </div>
  );
}

export default Home;
