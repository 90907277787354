// This action reloads questions for a course for a student
// if there are any missing assessmentQuestions that don't have corresponding questions
// it is useful if state gets out of sync

import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';

export default function reloadMissingQuestionsForCourse(
  courseId: string
) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const store = getStore();
    const existingAQMs = store.active.assessmentQuestionMaps;
    const existingQuestions = store.active.templateQuestions;

    const isMissingQuestion = existingAQMs.some(aqm => !existingQuestions.find(eq => eq.id === aqm.questionId));

    if (isMissingQuestion) {
      const newTemplateQuestions = await apiNext.getCourseQuestions(courseId);
      dispatch(activeSlice.actions.setActiveTemplateQuestions(newTemplateQuestions));
    }

    return;
  })();
}
