import React from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import { FiAlertTriangle } from 'react-icons/fi';
import { FaTimes } from 'react-icons/fa';
import { ConfirmationTypeEnum } from 'types/common.types';

const ConfirmationPromptContainer = ({
  children,
  confirmationType,
  title,
  handleCancel,
  ...props
}: {
  children: React.ReactNode
  confirmationType: ConfirmationTypeEnum
  title: string
  handleCancel?: () => void
}) => {
  return (
    <div className="confirmation-modal__overlay">
      <div className="confirmation-modal__wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog" {...props}>
        <FocusLock as="section">
          <div className="confirmation-modal">
            <div className={`confirmation-modal__header header-${confirmationType}`}>
              <span>
                {confirmationType === ConfirmationTypeEnum.Warn
                  ? <FiAlertTriangle className="confirmation-modal__header-icon"/>
                  : <span className="confirmation-modal__header-title">{title}</span>
                }
              </span>
              {handleCancel && (
                <button className="confirmation-modal__header--cancel" onClick={handleCancel}>
                  <FaTimes/>
                </button>
              )}
            </div>
            <div className="confirmation-modal__content">
              {confirmationType === ConfirmationTypeEnum.Warn && (
                <div className="confirmation-modal__title">
                  {title}
                </div>
              )}
              {children}
            </div>
          </div>
        </FocusLock>
      </div>
    </div>
  );
};

ConfirmationPromptContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ConfirmationPromptContainer;
