import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';

import { LoSelectorContext } from '../../CourseLoSelectorController';
import { FeatureFlags } from 'App';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import CustomItemIcon from 'shared-components/CustomItemIcon/CustomItemIcon';
import KebabMenu from 'shared-components/KebabMenu/KebabMenu';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import {
  LoActionEnum,
  LoActionDispatch,
  LoActionPayload,
  LoItem,
} from '../../CourseLoSelector.types';
import './LoLibraryItem.scss';

function LoLibraryItem({
  disabled = false,
  item,
  loAction,
}: {
  disabled?: boolean
  item: LoItem
  loAction: LoActionDispatch
}) {
  const {
    blooms,
    loClassSessions = [],
    id: loId,
    selected,
    stringId,
    title,
    topicId,
    type,
    userId,
    libraryTopicName,
    courseTopicName,
    customTopicParent,
    parentAndCsloTopicMatch,
  } = item;

  const flags = useContext(FeatureFlags);
  const { isProcessing, setIsProcessing } = useContext(LoSelectorContext);

  const handleAddRemoveLo = async () => {
    setIsProcessing(true);
    const action = selected ? LoActionEnum.RemoveLoFromClassSession : LoActionEnum.AddLoToClassSession;
    const loActionResult = await loAction(action, { id: loId } as LoActionPayload);
    console.debug('loActionResult', loActionResult);
  };

  const isUserType = type === LibraryTypeEnum.User;
  const usedInCourse = !!loClassSessions.length;

  const determineBluePillText = (libraryType: LibraryTypeEnum, topicName: string) => {
    return <>This {libraryType === LibraryTypeEnum.User && 'custom'} LO is part of your course topic <strong>{topicName}</strong>.</>;
  };

  const determineGrayPillText = (libraryType: LibraryTypeEnum, hasCustomTopicParent: boolean, topicName: string) => {
    let firstPart = 'This LO is part of the Codon library topic';
    if (libraryType === LibraryTypeEnum.User) {
      firstPart = `This custom LO has been assigned to ${hasCustomTopicParent ? 'your custom' : 'the Codon'} library topic`;
    }
    return <>{firstPart} <strong>{topicName}</strong>.</>;
  };

  return (
    <div
      className="lo-library-item"
      key={loId}
      data-loid={loId}
      data-stringid={stringId}
      data-lo-type={type}
    >
      <div className="lo-library-item__table-row">
        <div className="lo-library-item__gutter-btn">
          {isUserType && (
            <CustomItemIcon
              ownerUserId={userId}
              onClick={() => loAction(LoActionEnum.EditLo, {
                id: loId,
                title,
                topicId,
              })}
            />
          )}
        </div>
        <div className="lo-library-item__table-row__title">
          {title}
          {!!loClassSessions.length && (
            <div className="lo-library-item__class-numbers">
              {loClassSessions.map(csi => (
                <span key={csi.classSessionId} className="lo-library-item__class-number-pill">
                  Class {csi.classNumber}
                </span>)
              )}
            </div>
          )}
        </div>
        {flags.enableCustomLos && (
          <KebabMenu
            className={`lo-library-item__table-row__kebab-button ${!isUserType ? '' : 'hide-kebab'}`}
            items={[
              {
                label: 'Copy and modify LO',
                onClick: () => loAction(LoActionEnum.CopyLo, { title, topicId, parentLearningObjectiveId: loId } as LoActionPayload),
              },
            ]}
          />
        )}
        <div className="lo-library-item__table-row__topic">
          {usedInCourse && (
            <BetterTooltip
              content={() => determineBluePillText(type, courseTopicName)}
            >
              <span
                data-lo-topic-pill-type="course"
                className="lo-library__topic-pill"
              >
                {courseTopicName}
              </span>
            </BetterTooltip>
          )}
          {(!usedInCourse || (usedInCourse && !parentAndCsloTopicMatch)) && (
            <BetterTooltip
              content={() => determineGrayPillText(type, customTopicParent, libraryTopicName)}
            >
              <span
                data-lo-topic-pill-type="library"
                className="lo-library__topic-pill"
              >
                {libraryTopicName}
              </span>
            </BetterTooltip>
          )}
        </div>
        <div className="lo-library-item__table-row__blooms">{blooms}</div>
        <button
          aria-label={selected ? 'Remove Learning Objective' : 'Add Learning Objective'}
          className="lo-library-item__action lo-action"
          onClick={handleAddRemoveLo}
          data-selected={selected}
          disabled={isProcessing}
        >
          {selected
            ? <FaMinusCircle size={20} />
            : <FaPlusCircle size={20} />
          }
        </button>
      </div>
    </div>
  );
}

LoLibraryItem.propTypes = {
  disabled: PropTypes.bool,
  item: PropTypes.object.isRequired,
  loAction: PropTypes.func.isRequired,
};

export default LoLibraryItem;
