import React, { Dispatch, SetStateAction, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { formatPoints } from 'utils/commonFormattingFunctions';
import {
  getNextValidLateDate,
  getPresetLatePenalty,
  getMinDueDateForAssessment,
  getAssessmentStatus,
} from 'utils/assessmentFunctions';
import confirmationMsgs from '../AssessmentBuilderController/AssessmentBuilderConfirmationMessages';
import sharedStrings from 'sharedStrings';
import { useAppSelector } from 'store';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import DateTimePicker from 'shared-components/DateTimePicker/DateTimePicker';
import ToggleSwitch from 'shared-components/ToggleSwitch/ToggleSwitch';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import EnableGradeSyncConfirmationPrompt from './EnableGradeSyncConfirmationPrompt';
import { YesNo } from 'types/backend/shared.types';
import { AssessTypeEnum, GradingPolicyEnum } from 'types/backend/assessments.types';
import { AssessmentStatus, ConfirmationTypeEnum } from 'types/common.types';
import { InstructorCoursePath } from 'types/instructor.types';
import { AssessmentListSummativeEvents, SummativeEventData } from './AssessmentsList.types';
import { TabEnum } from '../AssessmentBuilderController/AssessmentBuilderController.types';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { EnrichedStudentAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';
import './AssessmentsList.scss';

interface AssessmentsListSummativeItemsProps {
  defaultMinOpenDate: Date
  defaultMaxDueDate: Date
  isEditMode: boolean
  prep: EnrichedStudentAssessment
  practiceTest: EnrichedStudentAssessment
  summativeId: string
  summativeDueDate: string
  onChangeSummativeAssessment: (type: AssessmentListSummativeEvents, eventData: SummativeEventData) => void
  startedIds: Array<string>
  hideGradeSyncPrompt: boolean
  setHideGradeSyncPrompt: Dispatch<SetStateAction<boolean>>
}

const AssessmentsListSummativeItems = ({
  defaultMinOpenDate,
  defaultMaxDueDate,
  isEditMode,
  prep,
  practiceTest,
  summativeId,
  summativeDueDate,
  onChangeSummativeAssessment,
  startedIds,
  hideGradeSyncPrompt,
  setHideGradeSyncPrompt,
}: AssessmentsListSummativeItemsProps) => {

  const { id: courseId, isGradeSyncEnabled: courseIsGradeSyncEnabled, timeZone } = useAppSelector((store) => store.active.course);
  const courseAssessmentPreset = useAppSelector((store) => store.active.courseAssessmentPreset);

  const { triggerConfirmationPrompt } = useConfirmationPrompt();

  const {
    id: prepId,
    isGradeSyncEnabled: prepDefaultAssessmentGradeSyncEnabled,
    gradingPolicy: prepGradingPolicy,
    latePolicy: prepLatePolicy,
    lateDate: prepDefaultLateDate,
    latePenalty: prepDefaultLatePenalty,
    name: prepName,
    published: prepPublished,
    totalQuestions: prepTotalQuestions,
    totalPoints: prepTotalPoints,
  } = prep;

  const {
    id: practiceId,
    isGradeSyncEnabled: practiceDefaultAssessmentGradeSyncEnabled,
    gradingPolicy: practiceGradingPolicy,
    latePolicy: practiceLatePolicy,
    lateDate: practiceDefaultLateDate,
    latePenalty: practiceDefaultLatePenalty,
    name: practiceName,
    published: practicePublished,
    totalQuestions: practiceTotalQuestions,
    totalPoints: practiceTotalPoints,
  } = practiceTest;

  const dueDate = new Date(summativeDueDate);

  const [gradeSyncConfirmationIsShowing, setGradeSyncConfirmationIsShowing] = useState(false);

  const prepPresetLatePenalty = getPresetLatePenalty(AssessTypeEnum.Prep, courseAssessmentPreset);
  const [prepPublishedToggle, setPrepPublishedToggle] = useState(prepPublished === YesNo.Yes);
  const [prepGradeSyncToggle, setPrepGradeSyncToggle] = useState(prepDefaultAssessmentGradeSyncEnabled);
  const [prepLatePolicyToggle, setPrepLatePolicyToggle] = useState(prepLatePolicy === YesNo.Yes);
  const [prepLateDate, setPrepLateDate] = useState(!!prepDefaultLateDate ? new Date(prepDefaultLateDate) : null);
  const [prepLatePenalty, setPrepLatePenalty] = useState(prepDefaultLatePenalty !== null ? prepDefaultLatePenalty : null);
  const [prepHasInvalidDueLateDates, setPrepHasInvalidDueLateDates] = useState(false);

  const practicePresetLatePenalty = getPresetLatePenalty(AssessTypeEnum.PracticeTest, courseAssessmentPreset);
  const [practicePublishedToggle, setPracticePublishedToggle] = useState(practicePublished === YesNo.Yes);
  const [practiceGradeSyncToggle, setPracticeGradeSyncToggle] = useState(practiceDefaultAssessmentGradeSyncEnabled);
  const [practiceLatePolicyToggle, setPracticeLatePolicyToggle] = useState(practiceLatePolicy === YesNo.Yes);
  const [practiceLateDate, setPracticeLateDate] = useState(!!practiceDefaultLateDate ? new Date(practiceDefaultLateDate) : null);
  const [practiceLatePenalty, setPracticeLatePenalty] = useState(practiceDefaultLatePenalty !== null ? practiceDefaultLatePenalty : null);
  const [practiceHasInvalidDueLateDates, setPracticeHasInvalidDueLateDates] = useState(false);
  const [disablePracticePublished, setDisablePracticePublished] = useState(prepPublished === YesNo.No);

  const assessmentIdParam = summativeId;
  const assessmentLink = `/instructor/course/${courseId}/${InstructorCoursePath.AssessmentBuilder}?assessment=${assessmentIdParam}&tab=${TabEnum.Editing}`;

  const prepAssessmentStatus = getAssessmentStatus(startedIds.includes(prepId), prepPublished);
  const prepIsStarted = prepAssessmentStatus === AssessmentStatus.Started;
  const prepGradeSyncStatusString = prepGradeSyncToggle ? 'On' : 'Off';

  const practiceAssessmentStatus = getAssessmentStatus(startedIds.includes(practiceId), practicePublished);
  const practiceIsStarted = practiceAssessmentStatus === AssessmentStatus.Started;
  const practiceGradeSyncStatusString = practiceGradeSyncToggle ? 'On' : 'Off';

  const prepAllowEditPublished = isEditMode && !prepIsStarted && !practiceIsStarted;
  const practiceAllowEditPublished = isEditMode && !practiceIsStarted;

  const onChangePrepPublished = (isChecked: boolean) => {
    setPrepPublishedToggle(isChecked);
    if (isChecked) {
      // OFF -> ON
      // set prep pub toggle -> on
      // set prep pub to yes
      // enable prep GS
      // set sum pub to yes
      // enable PT pub toggle
      setDisablePracticePublished(false);
      onChangeSummativeAssessment(
        AssessmentListSummativeEvents.ChangePrepPublished,
        {
          summativeId,
          summativeEventData: {
            published: YesNo.Yes,
            hasInvalidOpenDueDates: false,
            hasInvalidDueLateDates: false,
            assessType: AssessTypeEnum.Summative,
          },
          prepId,
          prepEventData: {
            published: YesNo.Yes,
            isGradeSyncEnabled: prepGradeSyncToggle,
            hasInvalidOpenDueDates: false,
            hasInvalidDueLateDates: prepHasInvalidDueLateDates,
            assessType: AssessTypeEnum.Prep,
          },
          practiceId,
          practiceEventData: {
            published: practicePublishedToggle ? YesNo.Yes : YesNo.No,
            isGradeSyncEnabled: practiceGradeSyncToggle,
            hasInvalidOpenDueDates: false,
            hasInvalidDueLateDates: practiceHasInvalidDueLateDates,
            assessType: AssessTypeEnum.PracticeTest,
          },
        }
      );
    } else {
      // ON -> OFF
      // set prep pub toggle -> off
      // set prep pub to no
      // set prep GS to false
      // disable prep GS
      // set sum pub to no
      // disable PT pub toggle
      // set PT pub toggle -> off
      // set PT pub to no
      // set PT GS to false
      // disable PT GS
      setPrepGradeSyncToggle(false);
      setPracticeGradeSyncToggle(false);
      setDisablePracticePublished(true);
      setPracticePublishedToggle(false);
      onChangeSummativeAssessment(
        AssessmentListSummativeEvents.ChangePrepPublished,
        {
          summativeId,
          summativeEventData: {
            published: YesNo.No,
            hasInvalidOpenDueDates: false,
            hasInvalidDueLateDates: false,
            assessType: AssessTypeEnum.Summative,
          },
          prepId,
          prepEventData: {
            published: YesNo.No,
            isGradeSyncEnabled: false,
            hasInvalidOpenDueDates: false,
            hasInvalidDueLateDates: prepHasInvalidDueLateDates,
            assessType: AssessTypeEnum.Prep,
          },
          practiceId,
          practiceEventData: {
            published: YesNo.No,
            isGradeSyncEnabled: false,
            hasInvalidOpenDueDates: false,
            hasInvalidDueLateDates: practiceHasInvalidDueLateDates,
            assessType: AssessTypeEnum.PracticeTest,
          },
        }
      );
    }
  };

  const onChangePracticePublished = (isChecked: boolean) => {
    setPracticePublishedToggle(isChecked);
    if (!isChecked && practiceGradeSyncToggle) {
      setPracticeGradeSyncToggle(false);
    }
    onChangeSummativeAssessment(
      AssessmentListSummativeEvents.ChangePracticePublished,
      {
        summativeId,
        summativeEventData: {
          hasInvalidOpenDueDates: false,
          hasInvalidDueLateDates: false,
          assessType: AssessTypeEnum.Summative,
        },
        prepId,
        prepEventData: {
          hasInvalidOpenDueDates: false,
          hasInvalidDueLateDates: prepHasInvalidDueLateDates,
          assessType: AssessTypeEnum.Prep,
        },
        practiceId,
        practiceEventData: {
          published: isChecked ? YesNo.Yes : YesNo.No,
          isGradeSyncEnabled: isChecked ? practiceGradeSyncToggle : false,
          hasInvalidOpenDueDates: false,
          hasInvalidDueLateDates: practiceHasInvalidDueLateDates,
          assessType: AssessTypeEnum.PracticeTest,
        },
      }
    );
  };

  const onChangeGradeSync = (isChecked: boolean, assessType: AssessTypeEnum) => {
    if (isChecked && !hideGradeSyncPrompt) {
      setGradeSyncConfirmationIsShowing(true);
    }
    let setGradeSyncToggle = setPrepGradeSyncToggle;
    let eventType = AssessmentListSummativeEvents.ChangePrepGradeSyncEnabled;
    let prepIsGradeSyncEnabled = isChecked;
    let practiceIsGradeSyncEnabled = practiceGradeSyncToggle;
    if (assessType === AssessTypeEnum.PracticeTest) {
      setGradeSyncToggle = setPracticeGradeSyncToggle;
      eventType = AssessmentListSummativeEvents.ChangePracticeGradeSyncEnabled;
      prepIsGradeSyncEnabled = prepGradeSyncToggle;
      practiceIsGradeSyncEnabled = isChecked;
    }
    setGradeSyncToggle(isChecked);
    onChangeSummativeAssessment(
      eventType,
      {
        summativeId,
        summativeEventData: {
          isGradeSyncEnabled: false,
          hasInvalidOpenDueDates: false,
          hasInvalidDueLateDates: false,
          assessType: AssessTypeEnum.Summative,
        },
        prepId,
        prepEventData: {
          isGradeSyncEnabled: prepIsGradeSyncEnabled,
          hasInvalidOpenDueDates: false,
          hasInvalidDueLateDates: prepHasInvalidDueLateDates,
          assessType: AssessTypeEnum.Prep,
        },
        practiceId,
        practiceEventData: {
          isGradeSyncEnabled: practiceIsGradeSyncEnabled,
          hasInvalidOpenDueDates: false,
          hasInvalidDueLateDates: practiceHasInvalidDueLateDates,
          assessType: AssessTypeEnum.PracticeTest,
        },
      }
    );
  };

  const onChangeLateDateCheck = (dateVal: Date, assessType: AssessTypeEnum) => {
    let lateDate = prepLateDate;
    let setHasInvalidDueLateDates = setPrepHasInvalidDueLateDates;
    let hasInvalidDueLateDates = prepHasInvalidDueLateDates;
    let isStarted = prepIsStarted;
    let defaultLateDate = prepDefaultLateDate;
    if (assessType === AssessTypeEnum.PracticeTest) {
      lateDate = practiceLateDate;
      setHasInvalidDueLateDates = setPracticeHasInvalidDueLateDates;
      hasInvalidDueLateDates = practiceHasInvalidDueLateDates;
      isStarted = practiceIsStarted;
      defaultLateDate = practiceDefaultLateDate;
    }
    if (!!lateDate) {
      if (dateVal <= dueDate) {
        setHasInvalidDueLateDates(true);
        triggerConfirmationPrompt({
          title: sharedStrings.INVALID_DATES_TITLE,
          message: confirmationMsgs.dueDatePastLateDateConfMessage,
          onConfirm: () => {},
          confirmationType: ConfirmationTypeEnum.Warn,
        });
      } else if (hasInvalidDueLateDates && dateVal > dueDate) {
        setHasInvalidDueLateDates(false);
      }
      if (isStarted) {
        if (defaultLateDate === null || +dateVal !== +new Date(defaultLateDate)) {
          const now = DateTime.now();
          if (DateTime.fromJSDate(dateVal) >= now && DateTime.fromJSDate(lateDate) < now) {
            triggerConfirmationPrompt({
              title: sharedStrings.EXTEND_LATE_DATE_TITLE,
              message: confirmationMsgs.changeLateDateAfterStudentsStartedConfMessage,
              confirmButtonText: sharedStrings.EXTEND_LATE_DATE_TITLE,
              onConfirm: () => onChangeLateDate(dateVal, dateVal < dueDate, assessType),
              onCancel: () => {},
              confirmationType: ConfirmationTypeEnum.Warn,
            });
            return;
          }
        }
      }
      onChangeLateDate(dateVal, dateVal < dueDate, assessType);
    }
  };

  const onChangeLateDate = (dateVal: Date, invalidDueLateDates: boolean, assessType: AssessTypeEnum) => {
    let setLateDate = setPrepLateDate;
    let eventType = AssessmentListSummativeEvents.ChangePrepLateDate;
    let prepLateDateVal: string | undefined = DateTime.fromJSDate(dateVal).toISO();
    let prepInvalidDueLateDates = invalidDueLateDates;
    let practiceLateDateVal: string | undefined;
    let practiceInvalidDueLateDates = practiceHasInvalidDueLateDates;
    if (assessType === AssessTypeEnum.PracticeTest) {
      setLateDate = setPracticeLateDate;
      eventType = AssessmentListSummativeEvents.ChangePracticeLateDate;
      prepLateDateVal = undefined;
      prepInvalidDueLateDates = prepHasInvalidDueLateDates;
      practiceLateDateVal = DateTime.fromJSDate(dateVal).toISO();
      practiceInvalidDueLateDates = invalidDueLateDates;
    }
    setLateDate(dateVal);
    onChangeSummativeAssessment(
      eventType,
      {
        summativeId,
        summativeEventData: {
          lateDate: null,
          hasInvalidOpenDueDates: false,
          hasInvalidDueLateDates: false,
          assessType: AssessTypeEnum.Summative,
        },
        prepId,
        prepEventData: {
          lateDate: prepLateDateVal,
          hasInvalidOpenDueDates: false,
          hasInvalidDueLateDates: prepInvalidDueLateDates,
          assessType: AssessTypeEnum.Prep,
        },
        practiceId,
        practiceEventData: {
          lateDate: practiceLateDateVal,
          hasInvalidOpenDueDates: false,
          hasInvalidDueLateDates: practiceInvalidDueLateDates,
          assessType: AssessTypeEnum.PracticeTest,
        },
      }
    );
  };

  const onChangeLatePolicy = (isChecked: boolean, assessType: AssessTypeEnum) => {
    if (assessType === AssessTypeEnum.Prep) {
      setPrepLatePolicyToggle(isChecked);
      let newLateDate: Date | null;
      let newLatePenalty: number | null;
      if (isChecked) {
        newLateDate = getNextValidLateDate(assessType, timeZone, dueDate, courseAssessmentPreset);
        newLatePenalty = prepLatePenalty !== null ? prepLatePenalty : prepPresetLatePenalty;
      } else {
        newLateDate = null;
        newLatePenalty = null;
        setPrepHasInvalidDueLateDates(false);
      }
      setPrepLateDate(newLateDate);
      setPrepLatePenalty(newLatePenalty);
      onChangeSummativeAssessment(
        AssessmentListSummativeEvents.ChangePrepLatePolicy,
        {
          summativeId,
          summativeEventData: {
            hasInvalidOpenDueDates: false,
            hasInvalidDueLateDates: false,
            assessType: AssessTypeEnum.Summative,
          },
          prepId,
          prepEventData: {
            latePolicy: isChecked ? YesNo.Yes : YesNo.No,
            lateDate: newLateDate !== null ? DateTime.fromJSDate(newLateDate).toISO() : null,
            latePenalty: newLatePenalty,
            hasInvalidOpenDueDates: false,
            hasInvalidDueLateDates: isChecked ? prepHasInvalidDueLateDates : false,
            assessType: AssessTypeEnum.Prep,
          },
          practiceId,
          practiceEventData: {
            hasInvalidOpenDueDates: false,
            hasInvalidDueLateDates: practiceHasInvalidDueLateDates,
            assessType: AssessTypeEnum.PracticeTest,
          },
        }
      );
    } else {
      // PT
      setPracticeLatePolicyToggle(isChecked);
      let newLateDate: Date | null;
      let newLatePenalty: number | null;
      if (isChecked) {
        newLateDate = getNextValidLateDate(assessType, timeZone, dueDate, courseAssessmentPreset);
        newLatePenalty = practiceLatePenalty !== null ? practiceLatePenalty : practicePresetLatePenalty;
      } else {
        newLateDate = null;
        newLatePenalty = null;
        setPracticeHasInvalidDueLateDates(false);
      }
      setPracticeLateDate(newLateDate);
      setPracticeLatePenalty(newLatePenalty);
      onChangeSummativeAssessment(
        AssessmentListSummativeEvents.ChangePracticeLatePolicy,
        {
          summativeId,
          summativeEventData: {
            hasInvalidOpenDueDates: false,
            hasInvalidDueLateDates: false,
            assessType: AssessTypeEnum.Summative,
          },
          prepId,
          prepEventData: {
            hasInvalidOpenDueDates: false,
            hasInvalidDueLateDates: prepHasInvalidDueLateDates,
            assessType: AssessTypeEnum.Prep,
          },
          practiceId,
          practiceEventData: {
            latePolicy: isChecked ? YesNo.Yes : YesNo.No,
            lateDate: newLateDate !== null ? DateTime.fromJSDate(newLateDate).toISO() : null,
            latePenalty: newLatePenalty,
            hasInvalidOpenDueDates: false,
            hasInvalidDueLateDates: isChecked ? practiceHasInvalidDueLateDates : false,
            assessType: AssessTypeEnum.PracticeTest,
          },
        }
      );
    }
  };

  const renderLatePolicy = (assessType: AssessTypeEnum) => {
    const now = DateTime.now();
    let gradingPolicy = prepGradingPolicy;
    let isStarted = prepIsStarted;
    let latePolicy = prepLatePolicy;
    let lateDate = prepLateDate;
    let latePolicyToggle = prepLatePolicyToggle;
    let hasInvalidDueLateDates = prepHasInvalidDueLateDates;
    let id = prepId;
    let defaultLateDate = prepDefaultLateDate;
    if (assessType === AssessTypeEnum.PracticeTest) {
      gradingPolicy = practiceGradingPolicy;
      isStarted = practiceIsStarted;
      latePolicy = practiceLatePolicy;
      lateDate = practiceLateDate;
      latePolicyToggle = practiceLatePolicyToggle;
      hasInvalidDueLateDates = practiceHasInvalidDueLateDates;
      id = practiceId;
      defaultLateDate = practiceDefaultLateDate;
    }
    const disableEditLatePolicy = gradingPolicy === GradingPolicyEnum.NoPoints
    || (
      isStarted && latePolicy === YesNo.Yes &&
      (+now > +DateTime.fromJSDate(dueDate) || +now > +DateTime.fromJSDate(lateDate as Date))
    );
    if (isEditMode) {
      if ([GradingPolicyEnum.NoPoints, GradingPolicyEnum.External].includes(gradingPolicy)) {
        return (
          <span><em>Assessment not for points</em></span>
        );
      } else {
        return (
          <div className="assessment-list__late-date__content">
            {latePolicyToggle && (
              <DateTimePicker
                className="assessment-date__datetime-picker late-date-picker"
                disabled={false}
                invalid={hasInvalidDueLateDates}
                maxDate={defaultMaxDueDate}
                minDate={getMinDueDateForAssessment(isStarted, true, defaultMinOpenDate)}
                name="late-date-picker"
                onChange={(dateVal) => !!dateVal && onChangeLateDateCheck(dateVal, assessType)}
                value={lateDate}
              />
            )}
            <ToggleSwitch
              id={`${id}-late-policy`}
              checked={latePolicyToggle}
              disabled={disableEditLatePolicy}
              onChange={(isChecked) => onChangeLatePolicy(isChecked, assessType)}
              data-on="YES"
              data-off="NO"
            />
          </div>
        );
      }
    } else {
      if (latePolicy === YesNo.Yes) {
        return (
          <span>{DateTime.fromISO(defaultLateDate as string).toFormat(DateFormatEnum.WeekdayWithShortDateNoYear)}</span>
        );
      } else {
        return null;
      }
    }
  };

  return (
    <>
      <tr
        className="assessment-list__table-row"
        data-assesstype={AssessTypeEnum.Prep}
        data-published={prepPublished}
        data-assessmentid={assessmentIdParam}
      >
        <td data-cell="name" className="assessment-list__name">
          <Link to={assessmentLink}>
            {prepName}
          </Link>
        </td>
        {!!gradeSyncConfirmationIsShowing && (
          <EnableGradeSyncConfirmationPrompt
            setGradeSyncConfirmationIsShowing={setGradeSyncConfirmationIsShowing}
            setHideGradeSyncPrompt={setHideGradeSyncPrompt}
            hideGradeSyncPrompt={hideGradeSyncPrompt}
          />
        )}
        <td className="assessment-list__status-cell" data-cell="status">
          <div>
            {prepIsStarted ? (
              <>
                <span className="hide-md">Started</span>
                <span className="hide-xs show-md">Started by Students</span>
              </>
            ) : (
              <span className={`${isEditMode ? 'hide-xs' : ''} show-md`}>{prepAssessmentStatus}</span>
            )}
            {prepAllowEditPublished && (
              <ToggleSwitch
                id={`${prepId}-is-published`}
                checked={prepPublishedToggle}
                onChange={(isChecked) => onChangePrepPublished(isChecked)}
                data-on="YES"
                data-off="NO"
                disabled={false}
              />
            )}
          </div>
        </td>
        {!!courseIsGradeSyncEnabled && (
          <td className="assessment-list__status-cell" data-cell="grade-sync">
            {isEditMode ? (
              <ToggleSwitch
                id={`${prepId}-grade-sync-enabled`}
                checked={prepGradeSyncToggle}
                onChange={(isChecked) => onChangeGradeSync(isChecked, AssessTypeEnum.Prep)}
                disabled={!prepPublishedToggle}
              />
            ) : prepGradeSyncStatusString}
          </td>
        )}
        <td className="assessment-list__number-cell" data-cell="total-items">
          {prepTotalQuestions}
        </td>
        <td className="assessment-list__number-cell" data-cell="total-points">
          {formatPoints(prepTotalPoints, true)}
        </td>
        <td data-cell="open-date" className="assessment-list__date">
          <BetterTooltip
            content={"Students can start a topic's Prep Questions once they move that topic into Checkpoint 2. This won't happen until they finish a Homework or Pre-class assessment covering that topic."}
            indicate
          />
        </td>
        <td data-cell="due-date" className="assessment-list__date">
          {DateTime.fromISO(summativeDueDate).toFormat(DateFormatEnum.WeekdayWithShortDateNoYear)}
        </td>
        <td data-cell="late-date" data-wide={isEditMode} className="assessment-list__date assessment-list__late-date">
          {renderLatePolicy(AssessTypeEnum.Prep)}
        </td>
      </tr>
      <tr
        className="assessment-list__table-row"
        data-assesstype={AssessTypeEnum.PracticeTest}
        data-published={practicePublished}
        data-assessmentid={assessmentIdParam}
      >
        <td data-cell="name" className="assessment-list__name">
          <Link to={assessmentLink}>
            {practiceName}
          </Link>
        </td>
        {!!gradeSyncConfirmationIsShowing && (
          <EnableGradeSyncConfirmationPrompt
            setGradeSyncConfirmationIsShowing={setGradeSyncConfirmationIsShowing}
            setHideGradeSyncPrompt={setHideGradeSyncPrompt}
            hideGradeSyncPrompt={hideGradeSyncPrompt}
          />
        )}
        <td className="assessment-list__status-cell" data-cell="status">
          <div>
            {practiceIsStarted ? (
              <>
                <span className="hide-md">Started</span>
                <span className="hide-xs show-md">Started by Students</span>
              </>
            ) : (
              <span className={`${isEditMode ? 'hide-xs' : ''} show-md`}>{practiceAssessmentStatus}</span>
            )}
            {practiceAllowEditPublished && (
              <ToggleSwitch
                id={`${practiceId}-is-published`}
                checked={practicePublishedToggle}
                onChange={(isChecked) => onChangePracticePublished(isChecked)}
                data-on="YES"
                data-off="NO"
                disabled={disablePracticePublished}
              />
            )}
          </div>
        </td>
        {!!courseIsGradeSyncEnabled && (
          <td className="assessment-list__status-cell" data-cell="grade-sync">
            {isEditMode ? (
              <ToggleSwitch
                id={`${practiceId}-grade-sync-enabled`}
                checked={practiceGradeSyncToggle}
                onChange={(isChecked) => onChangeGradeSync(isChecked, AssessTypeEnum.PracticeTest)}
                disabled={!practicePublishedToggle}
              />
            ) : practiceGradeSyncStatusString}
          </td>
        )}
        <td className="assessment-list__number-cell" data-cell="total-items">
          {practiceTotalQuestions}
        </td>
        <td className="assessment-list__number-cell" data-cell="total-points">
          {formatPoints(practiceTotalPoints, true)}
        </td>
        <td data-cell="open-date" className="assessment-list__date">
          <BetterTooltip
            content={"Students can start the Practice Test once (1) they've completed all assignments and moved all topics into Checkpoint 3 or (2) the Study Path is due in 3 or fewer days."}
            indicate
          />
        </td>
        <td data-cell="due-date" className="assessment-list__date">
          {DateTime.fromISO(summativeDueDate).toFormat(DateFormatEnum.WeekdayWithShortDateNoYear)}
        </td>
        <td data-cell="late-date" data-wide={isEditMode} className="assessment-list__date assessment-list__late-date">
          {renderLatePolicy(AssessTypeEnum.PracticeTest)}
        </td>
      </tr>
    </>
  );
};

AssessmentsListSummativeItems.propTypes = {
  defaultMinOpenDate: PropTypes.object.isRequired,
  defaultMaxDueDate: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  prep: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assessType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    openDate: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
    gradingPolicy: PropTypes.string.isRequired,
    latePolicy: PropTypes.string.isRequired,
    lateDate: PropTypes.string,
    latePenalty: PropTypes.number,
    isGradeSyncEnabled: PropTypes.bool,
    published: PropTypes.string.isRequired,
    totalQuestions: PropTypes.number,
    totalPoints: PropTypes.number,
  }).isRequired,
  practiceTest: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assessType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    openDate: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
    gradingPolicy: PropTypes.string.isRequired,
    latePolicy: PropTypes.string.isRequired,
    lateDate: PropTypes.string,
    latePenalty: PropTypes.number,
    isGradeSyncEnabled: PropTypes.bool,
    published: PropTypes.string.isRequired,
    totalQuestions: PropTypes.number,
    totalPoints: PropTypes.number,
  }).isRequired,
  summativeId: PropTypes.string.isRequired,
  summativeDueDate: PropTypes.string.isRequired,
  onChangeSummativeAssessment: PropTypes.func.isRequired,
  startedIds: PropTypes.array.isRequired,
  hideGradeSyncPrompt: PropTypes.bool,
  setHideGradeSyncPrompt: PropTypes.func.isRequired,
};

export default AssessmentsListSummativeItems;
