import React from 'react';
import { ClassTypeEnum } from 'types/backend/classSessions.types';

// render standard Class # headers and non-class day labels
function DayHeaderButton({
  classNumber,
  classSessionId,
  classType,
  disabled,
  inWindow,
  isSelected,
  classDayLabel,
  onClick,
}: {
  classNumber?: number
  classSessionId?: number
  classType?: ClassTypeEnum
  disabled?: boolean
  inWindow: boolean
  isSelected: boolean
  classDayLabel?: string | null
  onClick: () => void
}) {
  if (classType === ClassTypeEnum.Special && !!classDayLabel) {
    return (
      <button
        className="better-timeline__class-session-header better-timeline__class-session-header-non-class-button"
        data-isselected={isSelected}
        data-class-session-id={classSessionId}
        disabled={isSelected || disabled}
        onClick={onClick}
        title={isSelected ? 'Currently viewing this day' : 'Click to view non-class day'}
        tabIndex={!!inWindow ? 0 : -1}
        aria-hidden={!inWindow}
      >
        <div className="better-timeline__class-session-header-non-class-label">
          {classDayLabel}
        </div>
      </button>
    );
  }
  return (
    <button
      className="better-timeline__class-session-header better-timeline__class-session-header-button"
      data-isselected={isSelected}
      data-class-session-id={classSessionId}
      data-class-number={classNumber}
      disabled={isSelected || disabled}
      onClick={onClick}
      title={isSelected ? 'Currently viewing this day' : `Click to view Class ${classNumber}`}
      tabIndex={!!inWindow ? 0 : -1}
      aria-hidden={!inWindow}
      style={{ display: !!classSessionId ? 'inherit' : 'none' }}
    >
      Class {classNumber}
    </button>
  );
}

export default DayHeaderButton;
