import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import apiNext from 'api-next';
import { isAdminRole, validUuid } from 'utils';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import CourseQACheck from 'shared-components/CourseQACheck/CourseQACheck';
import { Store } from 'types/store.types';
import { CourseHealthCheckResponseMulti } from 'types/backend/courseHealthCheck.types';
import './MultiQADashboard.scss';


export default function MultiQADashboard() {
  const user = useSelector((store: Store) => store.user);
  const [courses, setCourses] = useState<Array<CourseHealthCheckResponseMulti>>([]);
  const [courseIdsInput, setCourseIdsInput] = useState('');

  const disableSubmitCourseIds = courseIdsInput.split(',').some(cId => !validUuid(cId));

  async function doSearchForCourseHealthChecks(event: React.SyntheticEvent) {
    event.preventDefault();
    const courseIds = courseIdsInput.split(',');
    const newCourseHealthChecks = [];
    if (isAdminRole(user) && !!courseIds.length) {
      const healthChecks = await apiNext.getCourseHealthChecks(courseIds);
      if (!!healthChecks) {
        newCourseHealthChecks.push(...healthChecks);
      }
    }
    setCourses(newCourseHealthChecks);
  }

  return (
    <div className="multi-course-qa-dashboard">
      <div className="multi-course-qa-dashboard-search">
        <form className="multi-course-qa-dashboard-search__form" onSubmit={doSearchForCourseHealthChecks}>
          <label htmlFor="qa-dashboard-courseIds">courseId(s)</label>
          <textarea
            id="qa-dashboard-courseIds"
            placeholder="Enter a comma-separated list of courseIds with no spaces"
            value={courseIdsInput}
            onChange={e => setCourseIdsInput(e.target.value)}
          />
          <BetterButton
            disabled={disableSubmitCourseIds}
            primary
            text="Get Health Checks"
            onClick={doSearchForCourseHealthChecks}
          />
        </form>
      </div>
      <div>
        {!!courses.length && courses.map(course => {
          const {
            id,
            name,
            email,
            qaChecks,
          } = course;
          return (
            <div className="multi-course-qa-dashboard__course" key={id}>
              <div className="multi-course-qa-dashboard__course-data">
                <b>Course id:</b> {id}<br/>
                <b>Course name:</b> {name}<br/>
                <b>Instructor email:</b> {email}
              </div>
              <CourseQACheck
                qaCheckData={qaChecks}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
