// adapted from: https://stackoverflow.com/a/64489535

const groupBy = <T>(array: Array<T>, predicate: (value: T, index: number, array: Array<T>) => string) => {
  return array.reduce((acc, value, index, arr) => {
    (acc[predicate(value, index, arr)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: Array<T> });
};

export default groupBy;
