import React from 'react';
import { FaUserEdit, FaUserFriends } from 'react-icons/fa';

import { useAppSelector } from 'store';
import sharedStrings from 'sharedStrings';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { ContentTypeEnum, PositionEnum } from 'types/common.types';
import { UserApiIdNameEmail } from 'types/backend/users.types';
import './CustomItemIcon.scss';


const CustomItemIcon = ({
  className = '',
  onClick,
  ownerUserId,
  tipPosition,
  contentType = ContentTypeEnum.LearningObjective,
  inModal = false,
}: {
  className?: string
  onClick?: () => void
  ownerUserId: string
  tipPosition?: PositionEnum
  contentType?: ContentTypeEnum
  inModal?: boolean
}) => {
  const instructors = useAppSelector((store) => store.active.instructors);
  const user = useAppSelector((store) => store.user);
  const userIsOwner = user.id === ownerUserId;
  const coinstructor = instructors.find((i) => i.id === ownerUserId) || { firstName: '[unknown]', lastName: '[unknown]' } as UserApiIdNameEmail;

  const isLo = contentType === ContentTypeEnum.LearningObjective;
  const tooltipContent = `This ${isLo ? 'LO' : 'question'} was authored by your co-instructor,  ${coinstructor.firstName} ${coinstructor.lastName}.  To edit${!isLo ? ' its content or linked LOs' : ''}, please reach out to ${coinstructor.firstName} or your ${sharedStrings.INSTRUCTOR_SUPPORT_TITLE}.`;

  if (userIsOwner) {
    if (!onClick) {
      return <FaUserEdit className={`custom-item-icon__owner-indicator ${className}`} />;
    }
    return (
      <button
        className={`custom-item-icon__owner-indicator ${className}`}
        onClick={onClick}
      >
        <FaUserEdit />
      </button>
    );
  }
  return (
    <span className={`custom-item-icon__coinstructor-indicator ${className}`}>
      <BetterTooltip
        inModal={inModal}
        content={tooltipContent}
        position={tipPosition || PositionEnum.BottomRight}
      >
        <FaUserFriends/>
      </BetterTooltip>
    </span>
  );
};

export default CustomItemIcon;
