import React from 'react';
import PropTypes from 'prop-types';

import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import CodonUrls from 'urls';
import DateTimePicker from 'shared-components/DateTimePicker/DateTimePicker';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import ToggleSwitch from 'shared-components/ToggleSwitch/ToggleSwitch';
import { YesNo } from 'types/backend/shared.types';

interface LatePolicyFieldsProps {
  dateInvalid: boolean
  dateValue: Date | null
  disabled: boolean
  fieldName: string
  fieldNamePrefix?: string
  hasBeenStarted: boolean
  latePolicy: YesNo
  minDate: Date
  maxDate: Date
  onChangeDate: (updatedDate: Date) => void
  onChangePenalty: (event: number | null) => void
  onChangePolicy: (event: boolean) => void
  penaltyDisabled: boolean
  penaltyName: string
  penaltyValue: number | null
  toggleDisabled: boolean
}


const LatePolicyFields = ({
  dateInvalid,
  dateValue,
  disabled,
  fieldName,
  fieldNamePrefix = '',
  hasBeenStarted,
  latePolicy,
  minDate,
  maxDate,
  onChangeDate,
  onChangePenalty,
  onChangePolicy,
  penaltyDisabled,
  penaltyName,
  penaltyValue,
  toggleDisabled,
}: LatePolicyFieldsProps) => {
  const latePolicyIsOn = latePolicy === YesNo.Yes;
  const disabledOrOff = disabled || !latePolicyIsOn;
  const latePolicyTooltip = (
    <div>
      Points earned after the due date and before the late date will be reduced by this amount.
      The assignment grading policy will be applied first.
      <br/><br/>
      {disabled && <>Late policies don't apply to Not for Points assessments.<br/><br/></>}
      {externalLink(CodonUrls.LatePolicyKB, 'Learn more.')}
    </div>
  );
  return (
    <div className={`assessment-builder__late-policy ${disabled ? 'is-disabled' : ''}`}>
      <div className="form__field toggle-row late-policy-toggle-row">
        <label className="toggle-label">
          <span className={disabled ? 'is-disabled' : ''}>
            {fieldName}
          </span>
        </label>
        <BetterTooltip
          indicate
          className='late-policy-tooltip'
          content={latePolicyTooltip}
        />
        <ToggleSwitch
          id={`${fieldNamePrefix}late-policy-toggle`}
          checked={latePolicyIsOn}
          disabled={toggleDisabled}
          onChange={() => onChangePolicy(!latePolicyIsOn)}
          data-on="YES"
          data-off="NO"
        />
      </div>
      <div className="late-policy-row">
        <div className="form__field">
          <label
            className={disabledOrOff ? 'is-disabled' : ''}
            htmlFor={`${fieldNamePrefix}late-date-picker`}
          >
            After due date, accept late until&nbsp;
          </label>
          <DateTimePicker
            className="assessment-builder__datetime-picker late-date-picker"
            name={`${fieldNamePrefix}late-date-picker`}
            disabled={disabledOrOff}
            invalid={dateInvalid}
            limitTime={hasBeenStarted}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChangeDate}
            value={dateValue}
          />
        </div>
        <div className="form__field">
          <label
            className={disabledOrOff ? 'is-disabled' : ''}
            htmlFor={penaltyName}
          >
            and deduct&nbsp;
            <input
              type="number"
              id="late-penalty"
              name={penaltyName}
              value={penaltyValue !== null ? penaltyValue : ''}
              onChange={(e) => onChangePenalty(e.target.value !== '' ? parseInt(e.target.value) : null)}
              disabled={disabledOrOff || penaltyDisabled}
              min="0"
              max="100"
            />
            % of points
          </label>
        </div>
      </div>
    </div>
  );
};

LatePolicyFields.propTypes = {
  dateInvalid: PropTypes.bool,
  dateValue: PropTypes.object,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  fieldNamePrefix: PropTypes.string,
  latePolicy: PropTypes.string.isRequired,
  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onChangePenalty: PropTypes.func.isRequired,
  onChangePolicy: PropTypes.func.isRequired,
  penaltyDisabled: PropTypes.bool,
  penaltyName: PropTypes.string.isRequired,
  penaltyValue: PropTypes.number,
  toggleDisabled: PropTypes.bool,
};

export default LatePolicyFields;
