import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialInstructorStudentViewCourseIdState {
  instructorStudentViewCourseId: string | null
}

const stateSlice = createSlice({
  name: 'state',
  initialState: { instructorStudentViewCourseId: null } as InitialInstructorStudentViewCourseIdState,
  reducers: {
    setStateInstructorStudentViewCourseId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        instructorStudentViewCourseId: action.payload,
      };
    },
    clear: () => {
      return { instructorStudentViewCourseId: null };
    },
  },
});

export default stateSlice;
