// Sourced from: https://github.com/codonlearning/backend/blob/dev/src/services/classSessions/classSessions.types.ts

export enum ClassTypeEnum {
  Normal = 'normal',
  Special = 'special'
}

interface ClassSessionDbBase {
  id: number
  course__id: string
  class_type: ClassTypeEnum
  created_at: string
  updated_at: string
  label: string | null
  class_number: number
}

export interface ClassSessionDbIn extends ClassSessionDbBase {
  class_date: string
}

export interface ClassSessionDbOut extends ClassSessionDbBase {
  class_date: Date
}

export interface ClassSessionApi {
  id: number
  classDate: string
  courseId: string
  classType: ClassTypeEnum
  createdAt: string
  updatedAt: string
  label: string | null
  classNumber: number
}

export type ClassSessionDbInsert = Omit<ClassSessionDbIn, 'id' | 'created_at' | 'updated_at'>
