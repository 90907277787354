import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';
import { CourseAssessmentPresetApi } from 'types/backend/courseAssessmentPresets.types';

export default function updateCourseAssessmentPresets(
  courseAssessmentPreset: CourseAssessmentPresetApi
) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const updatedPreset = await apiNext.editCourseAssessmentPreset(courseAssessmentPreset);
    dispatch(activeSlice.actions.updateCourseAssessmentPreset(updatedPreset));
  })();
}
