import { ClarityHash, CorrectHash } from 'types/common.types';
import { ClarityEnum } from 'types/backend/studentAssessmentQuestionAttempts.types';
import { YesNo } from 'types/backend/shared.types';

export interface MetacognitionData {
  clearCorrect: number
  muddyCorrect: number
  clearIncorrect: number
  muddyIncorrect: number
}

export const calculateMetacognition = (correctHashData: CorrectHash, clarityHashData: ClarityHash, nonGradableL8yIds: Array<string>): MetacognitionData => {
  return Object.entries(correctHashData).reduce((acc, [key, value]) => {
    if (nonGradableL8yIds.includes(key)) {
      return acc; // we don't know if non-gradable questions are correct or not, so skip them
    }
    if (value === YesNo.Yes && clarityHashData[key] === ClarityEnum.Clear) {
      acc.clearCorrect += 1;
    } else if (value === YesNo.Yes && clarityHashData[key] === ClarityEnum.Muddy) {
      acc.muddyCorrect += 1;
    } else if (value === YesNo.No && clarityHashData[key] === ClarityEnum.Clear) {
      acc.clearIncorrect += 1;
    } else if (value === YesNo.No && clarityHashData[key] === ClarityEnum.Muddy) {
      acc.muddyIncorrect += 1;
    }
    return acc;
  }, { clearCorrect: 0, muddyCorrect: 0, clearIncorrect: 0, muddyIncorrect: 0 });
};
