import apiNext, { SimplifiedErrorResponse } from 'api-next';
import { errorMessages } from 'sharedStrings';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';
import { LibraryTypeEnum, QuestionUseEnum } from 'types/backend/shared.types';
import { AuthorAppGetItem } from 'types/backend/l8yItems.types';
import { CodonErrorCode } from 'types/backend/error.types';
import { QuestionApiOut } from 'types/backend/questions.types';

export default function createUserQuestion(
  userId: string,
  data: AuthorAppGetItem,
  learningObjectiveIds: Array<number>,
  blooms: number | null,
  questionUse: QuestionUseEnum
) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const itemJson: AuthorAppGetItem = data;
    const createdUserQuestion = await apiNext.createUserQuestion({
      type: LibraryTypeEnum.User,
      userId,
      itemJson,
      blooms,
      questionUse,
      shortTitle: null, // this is currently always null on create because shortTitle edit  is only available after question creation
    });
    const { error } = createdUserQuestion as SimplifiedErrorResponse;
    if (error) {
      let message = errorMessages.NO_CORRECT_ANSWER_SELECTED;
      if (error.errorCode === CodonErrorCode.OpenResponseQuestionMissingSampleAnswer) {
        message = errorMessages.NO_SAMPLE_ANSWER_SELECTED;
      }
      return {
        error,
        message,
      };
    }
    const { id: questionId } = createdUserQuestion as QuestionApiOut;
    const qlosToCreate = learningObjectiveIds.map(loId => ({
      questionId,
      learningObjectiveId: loId,
      type: LibraryTypeEnum.User,
      userId,
    }));
    await apiNext.createUserQuestionLos(qlosToCreate);
    // Append new user question to store.active.userQuestions
    return dispatch(activeSlice.actions.addActiveUserQuestion({
      ...createdUserQuestion,
      learningObjectiveIds,
    } as QuestionApiOut));
  })();
}
