import { ActiveCombinedQuestion } from 'store/selectors/retrieveActiveCombinedQuestions';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { EnrichedActiveLearningObjective } from 'store/selectors/retrieveEnrichedActiveTemplateLearningObjectives';
import { LibraryTypeEnum } from 'types/backend/shared.types';

interface LoDisplayData {
  id: number
  lo: string
  loStringId: string
  title: string
  topicName: string
  loType: LibraryTypeEnum
}

const getLosToShow = ({
  courseLearningObjectives,
  learningObjectiveIds,
  templateLearningObjectives,
}: {
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
  learningObjectiveIds: Array<number>
  templateLearningObjectives: Array<EnrichedActiveLearningObjective>
}) => {
  return learningObjectiveIds.reduce((acc, cur) => {
    const foundLo = acc.find(({ id }) => id === cur);
    if (!foundLo) {
      const foundClo = courseLearningObjectives.find(({ id }) => id === cur);
      if (foundClo) {
        const { id, _derived: { loNumber }, title, topic: { name: topicName }, type: loType, stringId: loStringId } = foundClo;
        acc.push({ id, lo: loNumber, title, topicName, loType, loStringId });
      } else {
        const foundTemplateLo = templateLearningObjectives.find(({ id }) => id === cur);
        if (foundTemplateLo) {
          const { id, stringId, title, _derived: { topic: { name: topicName } }, type: loType } = foundTemplateLo;
          acc.push({ id, lo: stringId, title, topicName, loType, loStringId: stringId });
        }
      }
    }
    return acc;
  }, [] as Array<LoDisplayData>);
};

export const getLosToShowFromArrayOfActiveCombinedQuestions = (acqs: Array<ActiveCombinedQuestion>) => {
  const allLoData = getAllLoDataFromActiveCombinedQuestions(acqs);
  return getLosToShow(allLoData);
};

const getAllLoDataFromActiveCombinedQuestions = (acqs: Array<ActiveCombinedQuestion>) => {
  return acqs.reduce((acc, cur) => {
    const { learningObjectiveIds = [] } = cur;
    learningObjectiveIds.forEach((loId) => {
      if (!acc.learningObjectiveIds.includes(loId)) {
        acc.learningObjectiveIds.push(loId);
        const foundClo = cur.courseLearningObjectives.find(({ id }) => id === loId);
        !!foundClo && acc.courseLearningObjectives.push(foundClo);
        const foundTemplateLo = cur._derived.templateLearningObjectives.find(({ id }) => id === loId);
        !!foundTemplateLo && acc.templateLearningObjectives.push(foundTemplateLo);
      }
    });
    return acc;
  }, {
    learningObjectiveIds: [] as Array<number>,
    courseLearningObjectives: [] as Array<EnrichedCourseLearningObjective>,
    templateLearningObjectives: [] as Array<EnrichedActiveLearningObjective>,
  });
};

const getLoDataFromCombinedQuestion = ({
  _derived: { templateLearningObjectives },
  courseLearningObjectives,
  learningObjectiveIds = [],
}: ActiveCombinedQuestion) => {
  const losToShow = getLosToShow({ courseLearningObjectives, learningObjectiveIds, templateLearningObjectives });
  const selectedLoIds = losToShow.map(({ id }) => id);
  return {
    selectedLoIds,
    losToShow,
  };
};

export default getLoDataFromCombinedQuestion;
