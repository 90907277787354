import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';
import { StudentStudyPathApi } from 'types/backend/studentStudyPaths.types';

export default function createStudentStudyPath(studyPathId: number, enrollmentId: number) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    // creates new student study path and returns full data
    const populatedStudentStudyPathData: StudentStudyPathApi = await apiNext.createStudentStudyPath(studyPathId, enrollmentId);
    return dispatch(activeSlice.actions.setStudentStudyPath(populatedStudentStudyPathData));
  })();
}
