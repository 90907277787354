import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import tooltips from '../../assessmentBuilderTooltips';
import { getShortAssessType } from 'utils/commonFormattingFunctions';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import getUniqueLosSortedByLoNumber from 'utils/getUniqueLosSortedByLoNumber';
import { AvailableAssessmentForAssessmentBuilder } from 'utils/assessmentFunctions';
import LoPillList from 'shared-components/LoPillList/LoPillList';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import AssessmentBuilderActionBar, { ItemTypeEnum } from '../AssessmentBuilderActionBar/AssessmentBuilderActionBar';
import { TabEnum, TabNavigate } from '../../AssessmentBuilderController.types';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import './AssessmentsCovered.scss';

interface AssessmentsCoveredProps {
  availableAssessments: Array<AvailableAssessmentForAssessmentBuilder>
  coveredAssessmentIds: Array<string>
  onChange: (selectedAssessmentIds: Array<string>) => void
  onSave: (selectedAssessmentIds: Array<string>, updatedCoveredLos: Array<EnrichedCourseLearningObjective>) => void
  navigate: TabNavigate
  selectedLos: Array<EnrichedCourseLearningObjective>
  setFormIsDirty: (isDirty: boolean) => void
}

function AssessmentsCovered({
  availableAssessments,
  coveredAssessmentIds,
  navigate,
  onChange,
  onSave,
  selectedLos,
  setFormIsDirty,
}: AssessmentsCoveredProps) {
  const [saving, setSaving] = useState(false);
  // the selected assessment ids array lives in local state and initialized with the default selection, the below useEffect updates the local state with props
  const [selectedAssessmentIds, setSelectedAssessmentIds] = useState(coveredAssessmentIds); // I might not need this state any more

  // loop through prior assessments to allocate selected and available assessment lists as a tuple; instead of needing to do 2 filter loops
  const [selectableAssessments, selectedAssessments] = availableAssessments.reduce(([available, selected], cur) => {
    const isSelected = selectedAssessmentIds.includes(cur.assessmentId);
    return isSelected
      ? [available, [...selected, cur]]
      : [[...available, cur], selected];
  }, [[], []] as [Array<AvailableAssessmentForAssessmentBuilder>, Array<AvailableAssessmentForAssessmentBuilder>]);

  // get map of all covered LOs from selected assessments
  const uniquedCoveredLos = getUniqueLosSortedByLoNumber(selectedLos);

  useEffect(() => {
    return () => {
      setFormIsDirty(false);
    };
  }, [setFormIsDirty]);

  const toggleCoveredAssessment = (assessmentId: string) => {
    const updatedCoveredAssessments = selectedAssessmentIds.includes(assessmentId)
      ? selectedAssessmentIds.filter((id) => id !== assessmentId)
      : [...selectedAssessmentIds, assessmentId];
    setSelectedAssessmentIds(updatedCoveredAssessments);
    onChange(updatedCoveredAssessments);
    setFormIsDirty(true);
  };

  const handleSave = async () => {
    setSaving(true);
    onSave(selectedAssessmentIds, uniquedCoveredLos);
  };

  const renderAssessmentList = (assessmentArray: Array<AvailableAssessmentForAssessmentBuilder>, emptyListMessage: string) => {
    if (!assessmentArray.length) {
      return (
        <div className="assessments-covered-list__empty">
          {emptyListMessage}
        </div>
      );
    }
    const sortedAssessmentArray = assessmentArray.sort((a, b) => +DateTime.fromISO(b.dueDate) - +DateTime.fromISO(a.dueDate));
    return (
      <ul className="assessments-covered-list">
        {sortedAssessmentArray.map(({ assessmentId, assessType: priorAssessType, courseLearningObjectives, dueDate, name }) => (
          <li key={assessmentId} data-assessmentid={assessmentId} className="assessments-covered-list__row">
            <label>
              <div className="assessments-covered-list__name">
                <input
                  type="checkbox"
                  checked={selectedAssessmentIds.includes(assessmentId)}
                  value={assessmentId}
                  onChange={() => toggleCoveredAssessment(assessmentId)}
                />
                <span className={`assess-type-pill ${priorAssessType}`}>
                  {getShortAssessType(priorAssessType)}
                </span>
                {name}
              </div>
              <div>
                Due {DateTime.fromISO(dueDate).toFormat(DateFormatEnum.MonthDay)}
              </div>
            </label>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="assessments-covered">
      <div className="assessments-covered__infobar">
        Which assessments should students review in this Study Path?
        <BetterTooltip
          content={() => tooltips.spbCheckpoint1}
          indicate
          wide
        />
      </div>
      <div className="assessments-covered__row row">
        <div className="assessments-covered__column assessments-covered__column-left col-md-6 col-lg-6 col-xl-6">
          <div className="assessments-covered__section">
            <div className="assessments-covered__section-header">
              Available Prior Assessments
            </div>
            {renderAssessmentList(selectableAssessments, 'No more selectable prior assessments')}
          </div>
        </div>
        <div className="assessments-covered__column assessments-covered__column-right col-md-6 col-lg-6 col-xl-6">
          <div className="assessments-covered__section">
            <div className="assessments-covered__section-header">
              Selected Assessments
            </div>
            {renderAssessmentList(selectedAssessments, 'Select prior assessments from the list on the left')}
          </div>
          {!!uniquedCoveredLos.length && (
            <div className="assessments-covered__section selected-los">
              <div className="assessments-covered__section-header">
                Related Learning Objectives
              </div>
              <LoPillList
                className="assessments-covered__lo-pills"
                courseLearningObjectives={uniquedCoveredLos}
              />
            </div>
          )}
        </div>
      </div>
      <AssessmentBuilderActionBar
        rightSideButtons={[
          {
            className: 'assessment-builder__back',
            disabled: saving,
            itemType: ItemTypeEnum.SecondaryBtn,
            itemId: 'assessmentsCoveredBack',
            itemText: 'BACK',
            onClick: () => navigate(TabEnum.Editing),
          },
          {
            className: 'assessment-builder__continue',
            disabled: saving,
            itemType: ItemTypeEnum.LoadingBtn,
            itemId: 'saveAndContinue',
            itemText: 'SAVE AND CONTINUE',
            loading: saving,
            loadingText: 'SAVING...',
            onClick: () => handleSave(),
          },
        ]}
      />
    </div>
  );
}

AssessmentsCovered.propTypes = {
  coveredAssessmentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default AssessmentsCovered;
