import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';

// this only ever gets used for editing points in AssessmentBuilder
export default function editAssessmentQuestionMap(aqmId: number, delta: Partial<AssessmentQuestionApi>, persist = false) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active: { assessmentQuestionMaps } } = getStore();

    const assessmentQuestionMap = assessmentQuestionMaps.find((aqm) => aqm.id === aqmId);
    if (!assessmentQuestionMap) {
      throw new Error(`aqmId ${aqmId} not found in assessmentQuestionMaps ${JSON.stringify(assessmentQuestionMaps)}`);
    }
    const assessmentQuestionMapsForId = assessmentQuestionMaps.filter((aqm) => aqm.assessmentId === assessmentQuestionMap.assessmentId); //maps for this assessment
    const mapIndex = assessmentQuestionMapsForId.findIndex((aqm) => aqm.id === assessmentQuestionMap.id); //index of this map
    //The API requires that we have previousId for a PUT (though we expect to eventually replace that with a PATCH)
    //but previousId is not returned from the API, so it is not an entity attribute, so we must grab it from state and pass it to the API
    let previousId = 0;
    if (mapIndex > 0) {
      previousId = assessmentQuestionMapsForId[mapIndex - 1].id; //id of previous record, or zero if first in list
    }

    const editedAssessmentQuestionMap = {
      ...assessmentQuestionMap,
      ...delta,
    };

    //make change locally for instant ui:
    dispatch(activeSlice.actions.editActiveAssessmentQuestionMap({ id: aqmId, delta: editedAssessmentQuestionMap }));

    //add the previousId
    editedAssessmentQuestionMap.previousId = previousId;

    //make the change on the server if persist is set to true
    //we have some use cases where we don't reflect a change on the server but do need to see the change in local state
    if (persist) {
      const serverEditedAssessmentQuestionMap = await apiNext.editAssessmentQuestionMap(aqmId, editedAssessmentQuestionMap);

      //Update our local store with the server version
      return dispatch(activeSlice.actions.editActiveAssessmentQuestionMap({ id: aqmId, delta: serverEditedAssessmentQuestionMap }));
    }
  })();
}
