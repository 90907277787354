/**
 * useSubmitStatus
 * Centralized hook for handling form submission. Standardize when form should be disabled based on submit state.
 **/
import { Dispatch, SetStateAction, useState } from 'react';

export enum SubmitStatus {
  Unsubmitted = 'unsubmitted',
  Submitting = 'submitting',
  Success = 'success',
  Failure = 'failure',
  TooManyRequests = 'TooManyRequests'
}

function useSubmitStatus(initialValue = SubmitStatus.Unsubmitted):
  [SubmitStatus, Dispatch<SetStateAction<SubmitStatus>>, boolean] {
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>(initialValue);
  const submitDisabled = [SubmitStatus.Submitting, SubmitStatus.Success].includes(submitStatus);
  return [submitStatus, setSubmitStatus, submitDisabled];
}

export default useSubmitStatus;
