// Ported to TS from: https://github.com/AlmeroSteyn/react-aria-live/blob/master/src/modules/Announcer.js
import React, { useEffect, useState } from 'react';
import { AriaLiveAttribute } from './AriaLive.types';
import AriaMessageBlock from './AriaMessageBlock';

const Announcer = ({
  politeMessage,
  assertiveMessage,
  assertiveMessageId,
  politeMessageId,
}: {
  politeMessage: string
  assertiveMessage: string
  assertiveMessageId: string
  politeMessageId: string
}) => {
  const [assertiveMessage1, setAssertiveMessage1] = useState('');
  const [assertiveMessage2, setAssertiveMessage2] = useState('');
  const [politeMessage1, setPoliteMessage1] = useState('');
  const [politeMessage2, setPoliteMessage2] = useState('');

  const [oldPoliteMessage, setOldPoliteMessage] = useState('');
  const [oldPoliteMessageId, setOldPoliteMessageId] = useState('');
  const [oldAssertiveMessage, setOldAssertiveMessage] = useState('');
  const [oldAssertiveMessageId, setOldAssertiveMessageId] = useState('');

  const [alternatePolite, setAlternatePolite] = useState(false);
  const [alternateAssertive, setAlternateAssertive] = useState(false);

  useEffect(() => {
    if (
      oldPoliteMessage !== politeMessage ||
      oldPoliteMessageId !== politeMessageId
    ) {
      setPoliteMessage1(alternatePolite ? '' : politeMessage);
      setPoliteMessage2(alternatePolite ? politeMessage : '');
      setOldPoliteMessage(politeMessage);
      setOldPoliteMessageId(politeMessageId);
      setAlternatePolite(!alternatePolite);
    }

    if (
      oldAssertiveMessage !== assertiveMessage ||
      oldAssertiveMessageId !== assertiveMessageId
    ) {
      setAssertiveMessage1(alternateAssertive ? '' : assertiveMessage);
      setAssertiveMessage2(alternateAssertive ? assertiveMessage : '');
      setOldAssertiveMessage(assertiveMessage);
      setOldAssertiveMessageId(assertiveMessageId);
      setAlternateAssertive(!alternateAssertive);
    }
  }, [
    assertiveMessage,
    assertiveMessage1,
    assertiveMessage2,
    assertiveMessageId,
    politeMessage,
    politeMessage1,
    politeMessage2,
    politeMessageId,
    oldPoliteMessage,
    oldPoliteMessageId,
    oldAssertiveMessage,
    oldAssertiveMessageId,
    alternatePolite,
    alternateAssertive,
  ]);

  return (
    <div>
      <AriaMessageBlock aria-live={AriaLiveAttribute.Assertive} message={assertiveMessage1} />
      <AriaMessageBlock aria-live={AriaLiveAttribute.Assertive} message={assertiveMessage2} />
      <AriaMessageBlock aria-live={AriaLiveAttribute.Polite} message={politeMessage1} />
      <AriaMessageBlock aria-live={AriaLiveAttribute.Polite} message={politeMessage2} />
    </div>
  );
};

export default Announcer;
