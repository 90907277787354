import { nanoid } from 'nanoid';

import apiNext from 'api-next';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { TopicApi } from 'types/backend/topics.types';
import { AppDispatch, Store } from 'types/store.types';
import { getPreviousIdForUserTopic } from 'utils/courseOrganizationFunctions';
import activeSlice from 'store/slices/active';

export default function editUserTopic(topicToEdit: TopicApi) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active } = getStore();

    const previousId: number = getPreviousIdForUserTopic(topicToEdit, active.topics);

    const editedTopic: TopicApi = {
      id: topicToEdit.id,
      name: topicToEdit.name,
      type: LibraryTypeEnum.User,
      userId: topicToEdit.userId,
      subjectId: topicToEdit.subjectId, //a non DB field that probably shouldn't be here in the long run, but is as part of our Strapi refactor, needed in Redux
      unitId: topicToEdit.unitId,
      order: topicToEdit.order,
      createdAt: topicToEdit.createdAt,
      updatedAt: topicToEdit.updatedAt,
      previousId,
      stringId: nanoid(), //set stringId to guid to avoid duplicate key error
    };

    //make the change on the server:
    const serverTopic = await apiNext.editUserTopic(editedTopic.id, editedTopic);

    //Update our local store with the server version
    return dispatch(activeSlice.actions.editActiveUserTopic({ id: serverTopic.id, delta: editedTopic }));
  })();
}
