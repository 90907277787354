import React from 'react';
import PropTypes from 'prop-types';
import { ChildrenProp } from 'types/common.types';
import './IntroOutro.scss';

const IntroOutro = ({ children }: { children: ChildrenProp }) => {
  return (
    <div className="assessment-intro-outro">
      <div className="assessment-intro-outro__content">
        { children }
      </div>
    </div>
  );
};

IntroOutro.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default IntroOutro;
