/**
 * CoursePlannerTopicEditor
 *
 * This needs to be useable both for creating new custom topics and editing existing custom topics within the BCP
 **/
import React from 'react';

import useInput from 'hooks/useInput';
import ClassRowCardContainer from './ClassRowCardContainer';
import { EventKeyEnum } from 'types/common.types';
import { LibraryTypeEnum } from 'types/backend/shared.types';

function CoursePlannerTopicEditor({
  buttonText,
  handleCancel,
  handleSave,
  initTopicName = '',
  minimal = false,
}: {
  buttonText?: string
  handleCancel?: () => void
  handleSave: (topicValue: string) => Promise<boolean>
  initTopicName?: string
  minimal?: boolean
}) {
  const isEditing = !!initTopicName; // if topic name is present we can infer that we are editing
  const [customTopicValue, handleCustomTopicValueChange, setCustomTopicValue] = useInput(initTopicName);
  const createTopicDisabled = !customTopicValue.length;

  const onSave = async () => handleSave(customTopicValue).then(() => !isEditing && setCustomTopicValue(''));

  const listenForEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!createTopicDisabled && event.key === EventKeyEnum.Enter) {
      await onSave();
    }
  };

  const cardLabel = isEditing
    ? 'Edit Custom Topic Name'
    : 'Add Custom Topic to Class Session';

  const renderTopicForm = () => (
    <div className="bcp__topic-editor__content">
      <label htmlFor="custom-topic-input">
        Custom Topic Name
      </label>
      <div className="bcp__topic-editor__content-form">
        <input
          className="bcp__topic-editor__content__input"
          name="custom-topic-input"
          type="text"
          spellCheck
          onChange={handleCustomTopicValueChange}
          onKeyDown={listenForEnter}
          value={customTopicValue}
        />
        <button
          className="bcp__topic-editor__content__submit"
          disabled={createTopicDisabled}
          onClick={onSave}
        >
          {buttonText || 'Save Changes'}
        </button>
      </div>
    </div>
  );

  if (minimal) {
    return renderTopicForm();
  }

  return (
    <ClassRowCardContainer
      cardLabel={cardLabel}
      cardType={LibraryTypeEnum.User}
      className="bcp__topic-editor"
      handleClose={handleCancel}
    >
      {renderTopicForm()}
    </ClassRowCardContainer>
  );
}

export default CoursePlannerTopicEditor;
