import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import unionBy from 'lodash-es/unionBy';
import PropTypes from 'prop-types';
import BarChart from './BarChart';

import getSortedUniqueLOsForClassSessions from 'utils/getSortedUniqueLOsForClassSessions';
import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import retrieveActiveCourseLearningObjectives, { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import retrieveBetterClassSessions from 'store/selectors/retrieveBetterClassSessions';
import { EnrichedEditingAssessment } from 'instructor/controllers/Course/AssessmentBuilderController/enrichAssessmentForEditing';
import { AssessmentApiBase } from 'types/backend/assessments.types';
import './Charts.scss';

export default function LoChart({ currentAssessment }: { currentAssessment: EnrichedEditingAssessment | AssessmentApiBase }) {
  const assessmentQuestionMaps = useSelector(retrieveActiveAssessmentQuestionMaps);
  const courseLearningObjectives = useSelector(retrieveActiveCourseLearningObjectives);
  const classSessions = useSelector(retrieveBetterClassSessions);
  const [hoverLo, setHoverLo] = useState('');

  interface ChartLo extends EnrichedCourseLearningObjective {
    count: number
    loNumber: string
    sort: number
  }
  // get the LOs that are tied to questions in the assessment
  const losFromAssessment = courseLearningObjectives.reduce((accumulator: Array<ChartLo>, clo) => {
    // what is the purpose of this block?
    if (
      assessmentQuestionMaps.findIndex(
        (aqm) =>
          aqm.assessmentId === currentAssessment.id &&
          aqm._derived.courseLearningObjectives.find(
            (aclo) => aclo.id === clo.id
          )
      ) < 0
    ) {
      return accumulator;
    }
    accumulator.push({
      ...clo,
      id: clo.id,
      loNumber: clo._derived.loNumber,
      count: assessmentQuestionMaps.filter(
        (aqm) =>
          aqm.assessmentId === currentAssessment.id &&
          aqm._derived.courseLearningObjectives.find(
            (aclo) => aclo.id === clo.id
          )
      ).length,
      sort: clo._derived.courseWideSort,
    } as ChartLo);
    return accumulator;
  }, []);

  // get the additional LOs that are tied to the Class Days Covered
  const losFromClassSession = getSortedUniqueLOsForClassSessions(
    [...currentAssessment.classSessionIds],
    [...classSessions]
  );
  const remappedLosFromClassSession = losFromClassSession.map((clo) => ({
    ...clo,
    loNumber: `${clo._derived.loNumber}`,
    count: 0,
    sort: clo._derived.courseWideSort,
  }));

  const combinedLos = unionBy(losFromAssessment, remappedLosFromClassSession, 'id');
  combinedLos.sort((a, b) => a.sort - b.sort);
  const loGraphData = combinedLos.map(({ loNumber, count }) => ({ name: loNumber, value: count }));
  // Get title data based on which LO is hover
  const { title: loTitleToShow, topic } = combinedLos.find(({ loNumber }) => loNumber === hoverLo) || {};
  const { name: topicName } = topic || {};
  return (
    <div className="lo-chart">
      <div className="lo-chart__title">
        Number of Items per LO
      </div>
      <BarChart data={loGraphData} handleHover={(hoverVal) => setHoverLo(hoverVal)} />
      <div className="lo-chart__lo-info">
        <b>
          <u>{topicName}</u><br/>
          {hoverLo}
        </b>
        <div className="lo-chart__lo-info-description">{loTitleToShow}</div>
      </div>
    </div>
  );
}

LoChart.propTypes = {
  currentAssessment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assessType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    classSessionIds: PropTypes.array,
  }),
};
