import React from 'react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import useClassSessionQuery from 'hooks/useClassSessionQuery';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';

import { useAppSelector } from 'store';
import retrieveBetterClassSessions from 'store/selectors/retrieveBetterClassSessions';
import ToasterNotification from 'shared-components/ToasterNotification/ToasterNotification';
import BetterTimeline from 'shared-components/BetterTimeline/BetterTimeline';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import InstructorAssessmentPill from 'shared-components/BetterTimeline/InstructorAssessmentPill';
import DailyCard from 'shared-components/DailyCard/DailyCard';
import ClassDaysCovered from 'shared-components/ClassDaysCovered/ClassDaysCovered';
import ClassSessionNav from 'shared-components/ClassSessionNav/ClassSessionNav';
import Calendar from 'shared-components/Calendar/Calendar';
import DailyPlannerAlignmentList from './DailyPlannerAlignmentList';
import { AssessmentApiBase, AssessTypeEnum } from 'types/backend/assessments.types';
import { SummativeAssessmentSupplementsApi } from 'types/backend/summativeAssessmentSupplements.types';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import { InstructorCoursePath } from 'types/instructor.types';
import { AssessmentStatus, EnrichedClassSessionClr, InstructionEnum } from 'types/common.types';
import { YesNo } from 'types/backend/shared.types';
import './DailyPlannerController.scss';

const nameClass = 'col-xs-5';
const coversClass = 'col-xs-2';
const dueDateClass = 'col-xs-2';
const statusClass = 'col-xs-2';
const buttonSpacerClass = 'col-xs-1';

const DailyPlannerController = () => {
  const course = useAppSelector((store) => store.active.course);
  const classSessions = useAppSelector(retrieveBetterClassSessions);
  const summativeAssessmentSupplements = useAppSelector((store) => store.active.summativeAssessmentSupplements);
  const [selectedClassSessionId, setSelectedClassSessionId] = useClassSessionQuery(classSessions, true);
  const currentActiveClassSession = classSessions.find(({ id }) => id === selectedClassSessionId);

  if (!currentActiveClassSession || !classSessions.length) {
    return <LoadingSpinner loadingMessage="Loading Daily Planner" />;
  }

  const handleCalendarChange = (calendarDate: DateTime) => {
    const classSessionFromDate = classSessions.find(({ luxonDate }) => {
      return calendarDate.hasSame(luxonDate, 'day');
    });
    !!classSessionFromDate && setSelectedClassSessionId(classSessionFromDate.id);
  };
  const {
    classDate,
    id: classSessionId,
    groupedAssessments,
    classNumber,
    classType,
    label: classDayLabel,
    luxonDate,
    iclrs,
    ooclrs,
    topics,
    weekNumber,
  } = currentActiveClassSession;
  const activeClassSessionIndex = classSessions.findIndex(({ id }) => id === classSessionId);
  const { id: prevClassSessionId } = classSessions[activeClassSessionIndex - 1] || {};
  const { id: nextClassSessionId } = classSessions[activeClassSessionIndex + 1] || {};
  const nonClassDay = classType === ClassTypeEnum.Special;

  const { homework: homeworkGroup = [], preclass: preclassGroup = [], summative: summativeGroup = [] } = groupedAssessments;
  const filteredSummativeAssessments = summativeGroup.filter(({ assessType }) => assessType !== AssessTypeEnum.Summative);

  const renderInstructorAssessmentsList = (instructorAssessments: Array<AssessmentApiBase>, assessType: AssessTypeEnum) => {
    return (
      <div className="instructor-assessment-list">
        {instructorAssessments.map((assessment) => {
          const {
            id: assessmentId,
            dueDate: dueDateString,
            name: assessmentName,
            published,
            classSessionIds,
          } = assessment;
          const dueDate = DateTime.fromISO(dueDateString);
          const assessmentLink = `${InstructorCoursePath.AssessmentBuilder}?assessment=${assessmentId}&class-session=${selectedClassSessionId}`;
          return (
            <div className="daily-card__row row" key={`${assessType}AssessmentId_${assessmentId}`} data-published={published}>
              <div className={`daily-card__item-column ${nameClass}`}>
                <Link
                  className="assessment-link"
                  data-assessmentlink={assessType}
                  to={assessmentLink}
                >
                  {assessmentName}
                </Link>
              </div>
              <div className={`daily-card__item-column daily-planner__item-status ${statusClass}`}>
                {published === YesNo.Yes ? AssessmentStatus.Published : AssessmentStatus.Unpublished}
              </div>
              <div className={`daily-card__item-column daily-planner__item-covers ${coversClass}`}>
                <ClassDaysCovered classSessionIds={classSessionIds} classSessions={classSessions} />
              </div>
              <div className="daily-card__item-column daily-planner__item-duedate col-xs-3">
                {dueDate.toFormat(DateFormatEnum.DateAtTimeMeridean)}
              </div>
              <div className={buttonSpacerClass} />
            </div>
          );
        })}
      </div>
    );
  };

  const renderInstructorClassMaterialsList = (clrs: Array<EnrichedClassSessionClr>, instructionType: InstructionEnum) => {
    if (!clrs.length) {
      return null;
    }
    return (
      <div data-instructiontype={instructionType} className="class-materials-list">
        {clrs.map(({ classSessionIds = [], id: clrId, title: clrTitle }) => (
          <div className="daily-card__row row" key={`clr_${clrId}`}>
            <Link
              to={`${InstructorCoursePath.Instructions}?class-session=${selectedClassSessionId}&type=${instructionType}`}
              className={`daily-card__item-column ${nameClass}`}
            >
              {clrTitle}
            </Link>
            <div className="daily-card__item-column col-xs-2"></div>
            <div className="daily-card__item-column col-xs-5">
              <ClassDaysCovered classSessionIds={classSessionIds} classSessions={classSessions} />
            </div>
          </div>
        ))}
      </div>
    );
  };

  // determine when to show LO card
  const hasLos = topics.some(({ courseLearningObjectives }) => !!courseLearningObjectives.length);
  // determine when to show empty card
  const assessmentLinkPrefix = `${InstructorCoursePath.AssessmentBuilder}?class-session=${selectedClassSessionId}`;
  const preClassButtons = [
    { label: 'Add Pre-class Assessment', url: `${assessmentLinkPrefix}&type=${AssessTypeEnum.Preclass}` },
    { label: 'Add Pre-class Resources', url: `${InstructorCoursePath.Instructions}?class-session=${selectedClassSessionId}&type=${InstructionEnum.OutOfClass}` },
    { label: 'Add Readiness Reading', url: `${assessmentLinkPrefix}&type=${AssessTypeEnum.Readiness}` },
  ];
  const preClassButtonsToShow = nonClassDay ? [preClassButtons[0]] : preClassButtons;

  return (
    <div className="daily-planner-controller">
      <ToasterNotification
        id="daily-planner-controller"
        message="Use the Daily Planner to plan learning objectives, schedule assessments, and assign learning resources."
      />
      <div className="daily-planner">
        <BetterTimeline
          currentClassSessionId={classSessionId}
          setClassSessionId={setSelectedClassSessionId}
          renderAssessmentPill={(assessmentPillData) => (
            <InstructorAssessmentPill {...assessmentPillData} />
          )}
        />
        <main className="daily-planner__content row">
          <div className="daily-planner__main col-sm-9">
            <div className="daily-planner__header">
              <h2 className="daily-planner__header-details hreset">
                <div className="daily-planner__header-week-display">Week {weekNumber}</div>
                <div className="daily-planner__header-date-display">{DateTime.fromISO(classDate).toFormat(DateFormatEnum.FullWeekdayMonthDate)}</div>
              </h2>
              <ClassSessionNav
                onPrev={() => setSelectedClassSessionId(prevClassSessionId)}
                onNext={() => setSelectedClassSessionId(nextClassSessionId)}
                prevDisabled={!prevClassSessionId}
                nextDisabled={!nextClassSessionId}
                classNumber={classNumber}
                nonClassDay={nonClassDay}
              />
            </div>
            <div className="daily-planner__class-session-cards">
              <DailyCard
                className="labeled-class-card"
                header={classDayLabel || ''}
                cardType={classType}
                show={!!classDayLabel}
              />
              <DailyCard
                cardButton={{
                  label: 'Add / Edit',
                  url: `${InstructorCoursePath.SelectLearningObjectives}?class-session=${selectedClassSessionId}`,
                }}
                cardType="lo-card"
                className="daily-planner__lo-card"
                header="Learning Objectives"
                empty={!hasLos && !topics.length}
                show={!nonClassDay}
              >
                {topics.map(({ courseLearningObjectives, topicId, name: topicName }) => (
                  <div className="daily-planner__topic-group" key={`topic-group_${topicId}`}>
                    <div className="daily-planner__topic-name">{topicName}</div>
                    <div className="daily-planner__topic-los">
                      {!courseLearningObjectives.length ? (
                        <>There are currently no LOs assigned to this topic on Class {classNumber}</>
                      ) : courseLearningObjectives.map(({ id: courseLoId, title: loTitle, _derived: { loNumber } }) => (
                        <div className="daily-planner__topic-lo" key={`lo_${courseLoId}`}>
                          <div className="daily-planner__topic-lo-number">{loNumber}</div>
                          <div className="daily-planner__topic-lo-title">{loTitle}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </DailyCard>
              <DailyCard
                cardButton={ preClassButtonsToShow }
                cardType={AssessTypeEnum.Preclass}
                className="daily-planner__preclass-card"
                hideColumnLabels={!preclassGroup.length && !!ooclrs.length}
                empty={!preclassGroup.length && !ooclrs.length}
                header={(
                  <div className="daily-card__row row">
                    <div className={`daily-planner__column-title daily-card__header-title ${nameClass}`}>
                      Pre-class Work
                    </div>
                    <div className={`daily-card__column-label ${statusClass}`}>
                      Status
                    </div>
                    <div className={`daily-card__column-label ${coversClass}`}>
                      Covers
                    </div>
                    <div className={`daily-card__column-label ${dueDateClass}`}>
                      Due Date
                    </div>
                    <div className={buttonSpacerClass} />
                  </div>
                )}
              >
                {renderInstructorClassMaterialsList(ooclrs, InstructionEnum.OutOfClass)}
                {!!ooclrs.length && !!preclassGroup.length && <hr/>}
                {renderInstructorAssessmentsList(preclassGroup, AssessTypeEnum.Preclass)}
              </DailyCard>
              {!nonClassDay && (
                <DailyCard
                  cardButton={{ label: 'Add', url: `${InstructorCoursePath.Instructions}?class-session=${selectedClassSessionId}&type=${InstructionEnum.InClass}` }}
                  cardType={InstructionEnum.InClass}
                  className="daily-planner__class-materials"
                  empty={!iclrs.length}
                  header={(
                    <div className="daily-card__row row">
                      <div className={`daily-planner__column-title daily-card__header-title ${nameClass}`}>
                        In-class Materials
                      </div>
                      <div className={`daily-card__column-label ${statusClass}`}></div>
                      <div className={`daily-card__column-label ${coversClass}`}>
                        Covers
                      </div>
                      <div className={buttonSpacerClass} />
                    </div>
                  )}
                >
                  {renderInstructorClassMaterialsList(iclrs, InstructionEnum.InClass)}
                </DailyCard>
              )}
              <DailyCard
                cardButton={{ label: 'Add', url: `${assessmentLinkPrefix}&type=${AssessTypeEnum.Homework}` }}
                className="daily-planner__homework"
                cardType={AssessTypeEnum.Homework}
                empty={!homeworkGroup.length}
                header={(
                  <div className="daily-card__row row">
                    <div className={`daily-planner__column-title daily-card__header-title ${nameClass}`}>
                      Homework
                    </div>
                    <div className={`daily-card__column-label daily-planner__item-status ${statusClass}`}>Status</div>
                    <div className={`daily-card__column-label daily-planner__item-covers ${coversClass}`}>Covers</div>
                    <div className={`daily-card__column-label daily-planner__item-duedate ${dueDateClass}`}>Due Date</div>
                    <div className={buttonSpacerClass} />
                  </div>
                )}
              >
                {renderInstructorAssessmentsList(homeworkGroup, AssessTypeEnum.Homework)}
              </DailyCard>
              <DailyCard
                cardButton={{ label: 'Add', url: `${assessmentLinkPrefix}&type=${AssessTypeEnum.Summative}` }}
                cardType={AssessTypeEnum.Summative}
                className="daily-planner__studypath"
                hideColumnLabels={!filteredSummativeAssessments.length}
                empty={!filteredSummativeAssessments.length}
                header={(
                  <div className="daily-card__row row">
                    <div className="daily-planner__column-title daily-card__header-title col-xs-6">Study Path</div>
                    <div className="daily-card__column-label daily-planner__item-status col-xs-3">Status</div>
                    <div className="daily-card__column-label daily-planner__item-duedate col-xs-3">Due Date</div>
                  </div>
                )}
              >
                <div className="instructor-assessment-list">
                  {filteredSummativeAssessments.map((assessment) => {
                    const {
                      id: assessmentId,
                      dueDate: dueDateString,
                      published,
                    } = assessment;
                    const dueDate = DateTime.fromISO(dueDateString);
                    const { id: summativeIdForAssessmentLink } = summativeAssessmentSupplements.find((supp) => Object.values(supp).includes(assessmentId)) as SummativeAssessmentSupplementsApi;
                    return (
                      <div className="daily-card__row row" key={`summativeAssessmentId_${assessmentId}`} data-published={published}>
                        <div className="daily-card__item-column col-xs-6">
                          <Link className="assessment-link" data-assessmentlink={AssessTypeEnum.Summative} to={`${assessmentLinkPrefix}&assessment=${summativeIdForAssessmentLink}`}>
                            {assessment.name}
                          </Link>
                        </div>
                        <div className="daily-card__item-column daily-planner__item-status col-xs-3">
                          {published === YesNo.Yes ? AssessmentStatus.Published : AssessmentStatus.Unpublished}
                        </div>
                        <div className="daily-card__item-column col-xs-3">
                          {dueDate.toFormat(DateFormatEnum.DateAtTimeMeridean)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </DailyCard>
            </div>
          </div>
          <div className="daily-planner__sidebar col-sm-3">
            <div className="sidebar-card calendar-card">
              <Calendar
                classDays={course.courseDays}
                selectedClassDate={luxonDate}
                startDateString={course.startDate}
                endDateString={course.endDate}
                onChange={handleCalendarChange}
              />
            </div>
            {!nonClassDay && (
              <div className="sidebar-card alignment-card">
                <div className="alignment-card__header">
                  ALIGNMENT
                </div>
                <DailyPlannerAlignmentList currentActiveClassSession={currentActiveClassSession} />
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default DailyPlannerController;
