import apiNext, { SimplifiedErrorResponse } from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';
import { saveLogMessage } from 'utils/saveLogMessage';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';

export default function removeMultipleQuestionsFromAssessment({ questionIds, assessmentId }: {
  questionIds: Array<number>
  assessmentId: string
}) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active: { assessmentQuestionMaps } } = getStore();
    const aqmIdsToRemove = assessmentQuestionMaps.reduce((acc, cur) => {
      if (questionIds.includes(cur.questionId)) {
        acc.push(cur.id);
      }
      return acc;
    }, [] as Array<number>);

    const result = await apiNext.deleteAssessmentQuestionMaps(aqmIdsToRemove);
    const { error } = result as SimplifiedErrorResponse;
    if (error) {
      const errString = `Error from deleteAssessmentQuestionMaps. params: ${JSON.stringify({ assessmentId, questionIds })}, raw error: ${JSON.stringify(error)})`;
      saveLogMessage(errString);
      throw new Error(errString);
    } else {
      const aqmsRemoved = result as Array<AssessmentQuestionApi>;
      dispatch(activeSlice.actions.removeActiveAssessmentQuestionMaps({ aqmIds: aqmsRemoved.map(({ id }) => id) }));
      return result;
    }
  })();
}
