import React from 'react';
import { FaRegStar } from 'react-icons/fa';
import clsx from 'utils/clsx';
import { assessmentHasRecap, recapTooltipMessage } from 'utils/commonFormattingFunctions';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { PositionEnum } from 'types/common.types';
import { EnrichedStudentAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';


function RecapIcon({
  assessment,
  className = '',
  position,
}: {
  assessment: Pick<EnrichedStudentAssessment, 'recapPointsAvailable' | 'assessType' | 'lateDate'>
  className?: string
  position?: PositionEnum
}) {
  const { lateDate } = assessment;
  if (!assessmentHasRecap(assessment)) {
    return null;
  }
  return (
    <BetterTooltip
      content={() => recapTooltipMessage(!!lateDate)}
      position={position || PositionEnum.Right}
    >
      <div className={clsx('recap-icon', className)}>
        <FaRegStar size={18} />
      </div>
    </BetterTooltip>
  );
}

export default RecapIcon;
