/* eslint-disable jsx-a11y/no-autofocus */
import React, { Dispatch, SetStateAction } from 'react';
import PropTypes from 'prop-types';

import sharedStrings from 'sharedStrings';
import confirmationMsgs from '../AssessmentBuilderController/AssessmentBuilderConfirmationMessages';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import ConfirmationPromptContainer from 'shared-components/ConfirmationPrompt/ConfirmationPromptContainer';
import CheckboxItem from 'shared-components/CheckboxItem/CheckboxItem';
import { ConfirmationTypeEnum } from 'types/common.types';


export default function EnableGradeSyncConfirmationPrompt({
  setGradeSyncConfirmationIsShowing,
  setHideGradeSyncPrompt,
  hideGradeSyncPrompt,
}: {
  setGradeSyncConfirmationIsShowing: Dispatch<SetStateAction<boolean>>
  setHideGradeSyncPrompt: Dispatch<SetStateAction<boolean>>
  hideGradeSyncPrompt: boolean
}) {

  const closeModal = () => {
    setGradeSyncConfirmationIsShowing(false);
  };

  return (
    <ConfirmationPromptContainer
      confirmationType={ConfirmationTypeEnum.Warn}
      title={sharedStrings.SYNC_TO_LMS_TITLE}
      handleCancel={() => closeModal()}
    >
      <div className="confirmation-modal__body">
        {confirmationMsgs.enableGradeSyncConfMessage}
        <CheckboxItem
          selected={hideGradeSyncPrompt}
          onClick={() => setHideGradeSyncPrompt(!hideGradeSyncPrompt)}
          className="grade-sync-confirmation-prompt__checkbox"
          label="Don't show this again."
        />
      </div>
      <div className="confirmation-modal__button-bar">
        <BetterButton
          className="confirmation-button"
          primary
          data-dismiss="confirmation-modal"
          onClick={() => closeModal()}
          text="OK"
          autoFocus
        />
      </div>
    </ConfirmationPromptContainer>
  );
}

EnableGradeSyncConfirmationPrompt.propTypes = {
  setGradeSyncConfirmationIsShowing: PropTypes.func.isRequired,
  setHideGradeSyncPrompt: PropTypes.func.isRequired,
  hideGradeSyncPrompt: PropTypes.bool,
};
