import React from 'react';
import PropTypes from 'prop-types';

import './SpecialSessionRow.scss';

const SpecialSessionRow = ({
  label,
}) => {

  return (
    <>
      <td
        colSpan="3"
        className="topics-grid__dayrow-noclass"
      >
        <div className="topics-grid__dayrow-noclass">
          {label}
        </div>
      </td>
    </>
  );
};

SpecialSessionRow.propTypes = {
  label: PropTypes.string,
};

export default SpecialSessionRow;
