import React from 'react';
import Select, { SingleValue } from 'react-select';

import { useGetAllProductsQuery } from 'api-next';
import Spinner from 'shared-components/Spinner/Spinner';
import { DropdownOption } from 'instructor/components/Dropdown/Dropdown.types';
import { YesNo } from 'types/backend/shared.types';
import './Dropdown.scss';

export const allowedOrNot = {
  [YesNo.Yes]: 'Allows',
  [YesNo.No]: 'Does NOT allow',
};

function ProductsDropdown({ onChange, selectedProductId }: {
  onChange: (productId: number) => void
  selectedProductId: number | null
}) {
  const { data: products = [], isLoading } = useGetAllProductsQuery();
  const selectedProduct = products.find(({ id }) => id === selectedProductId);

  const handleChange = (newValue: SingleValue<DropdownOption<number | null>> | null) => {
    if (!newValue?.value) {
      return;
    }
    onChange(newValue.value);
  };
  if (isLoading) {
    return <Spinner />;
  }
  const productsForDropdown = products.map(p => ({
    value: p.id,
    label: p.title,
  }));
  return (
    <div className="products-dropdown">
      <Select
        value={productsForDropdown.find(({ value }) => value === selectedProductId)}
        onChange={handleChange}
        className="form-select"
        options={productsForDropdown}
        name="course-product"
        placeholder="Select Product"
      />
      {selectedProduct && (
        <div className="products-dropdown__display-product-info">
          Details of selected product:
          <ul>
            <li>{allowedOrNot[selectedProduct.showCode]} access codes</li>
            <li>{allowedOrNot[selectedProduct.showCC]} credit cards</li>
            <li>See Course Access tab for access instructions</li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default ProductsDropdown;
