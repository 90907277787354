/***
 * retrieveFilterableQuestionTypes
 * This selector takes the templateQuestions and userQuestions from the store
 * and returns the subset of userFacingQuestionTypes which have only the l8yTypes that exist in the questions
 * This is used to filter the question types in the question filter dropdown
 * and shows only the question types that exist in the questions
*/

import { createSelector } from '@reduxjs/toolkit';
import { userFacingQuestionTypes } from 'codonConstants';
import { Store } from 'types/store.types';
import { QuestionApiOut } from 'types/backend/questions.types';
import { UserFacingQuestionType } from 'instructor/controllers/Course/AssessmentBuilderController/AssessmentBuilderController.types';

export default createSelector(
  (store: Store) => store.active.templateQuestions,
  (store: Store) => store.active.userQuestions,
  (
    templateQuestions: Array<QuestionApiOut>,
    userQuestions: Array<QuestionApiOut>
  ) => {
    if (!templateQuestions && !userQuestions) {
      return userFacingQuestionTypes;
    }
    const allQuestions = [...templateQuestions, ...userQuestions];

    const updatedUserFacingQuestionTypes = userFacingQuestionTypes.reduce((acc, cur) => {
      const { l8yTypes } = cur;
      const atLeastOneQuestionWithType = allQuestions.some(q => {
        if (!q.questionTypes) {return false;}
        return q.questionTypes.find(qt => l8yTypes.includes(qt));
      });
      if (atLeastOneQuestionWithType) {
        acc.push(cur);
      }
      return acc;
    }, [] as Array<UserFacingQuestionType>);

    return updatedUserFacingQuestionTypes;
  });
