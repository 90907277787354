/**
 * retrieveLearningObjectivesWithMetadata
 *
 * Returns an array of all Learning Objectives, adds metadata with relationship data for the LO
 * Previously the only way to get this was spread out across retrieveEnrichedClassSessions and retrieveActiveCourseLearningObjectives
 */
import { createSelector } from '@reduxjs/toolkit';
import unionBy from 'lodash-es/unionBy';
import uniqBy from 'lodash-es/uniqBy';

import { Store } from 'types/store.types';
import { TopicApi } from 'types/backend/topics.types';
import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';
import { Active } from 'store/slices/active';

// formerly EnrichedUserTopic
export interface TopicForSorting extends TopicApi {
  courseWideOrder: number
  classSessionId: number
}

export interface LearningObjectiveWithMetadata extends LearningObjectiveApi {
  metadata?: {
    classSessionIds: Array<number>
    cslos: Array<ClassSessionLearningObjectiveApi>
    loIndex: number
    loDecimalString: string
    loNumberString: string
    topicIndex: number
  }
}

const sortCourseLearningObjectives = (clos: Array<LearningObjectiveWithMetadata>) => {
  return [...clos].sort(({ metadata: csloDataA }, { metadata: csloDataB }) => {
    const { topicIndex: topicIndexA = 0, loIndex: loIndexA = 0 } = csloDataA || {};
    const { topicIndex: topicIndexB = 0, loIndex: loIndexB = 0 } = csloDataB || {};
    return topicIndexA - topicIndexB || loIndexA - loIndexB;
  });
};

// Calculate loNumberString, add additional LO metadata
export function addMetadataToLearningObjectives({
  classSessionLearningObjectives,
  learningObjectives,
  classSessions,
  classSessionTopics,
  topics,
}: Pick<Active, 'classSessionLearningObjectives' | 'learningObjectives' | 'classSessions' | 'classSessionTopics' | 'topics'>): Array<LearningObjectiveWithMetadata> {
  if (classSessions.length === 0) {
    return [];
  }

  // sort class session topics by the first time they appear in the course, this is used for getting `topicIndex`
  const uniqueClassSessionTopics = uniqBy(classSessionTopics, 'topicId');
  const enrichedActiveTopics: Array<TopicForSorting> = uniqueClassSessionTopics.map((cst, index) => {
    const sortedTopic = topics.find(t => t.id === cst.topicId) as TopicApi;
    return {
      ...sortedTopic,
      courseWideOrder: index,
      classSessionId: cst.classSessionId,
    };
  });

  let sortedUserTopics: Array<TopicForSorting> = [];

  // get a unique array of all course LOs from all class sessions, correctly sorted
  const uniquedCourseLearningObjectives = classSessions.reduce((acc: Array<ClassSessionLearningObjectiveApi>, session) => {
    const classSessionLos = classSessionLearningObjectives.filter(cslo => cslo.classSessionId === session.id);
    const sessionUserTopics = enrichedActiveTopics.filter(topic => classSessionLos.find(lo => lo.topicId === topic.id));
    // i don't like this
    sortedUserTopics = unionBy([...sortedUserTopics], sessionUserTopics, 'id');
    const sortedSessionLos = sessionUserTopics.reduce((sortedSessionLosAcc: Array<ClassSessionLearningObjectiveApi>, topic) => {
      sortedSessionLosAcc.push(...classSessionLos.filter(lo => lo.topicId === topic.id));
      return sortedSessionLosAcc;
    }, []);
    if (sortedSessionLos.length > 0) {
      acc = unionBy(acc, sortedSessionLos, 'id');
    }
    return acc;
  }, []);

  //add necessary attributes to each lo in the array
  const enrichedLearningObjectives: Array<LearningObjectiveWithMetadata> = learningObjectives.map((learningObjective) => {
    const existingCslos = classSessionLearningObjectives.filter((cslo) => cslo.learningObjectiveId === learningObjective.id);
    if (!existingCslos.length) {
      return learningObjective;
    }
    const [existingCslo] = existingCslos;
    const topicIndex = sortedUserTopics.findIndex(topic => topic.id === existingCslo.topicId);
    const userTopic = sortedUserTopics[topicIndex];
    const topicLOs = uniquedCourseLearningObjectives.filter((lo) => lo.topicId === userTopic.id);
    const loIndex = topicLOs.findIndex(tlo => tlo.id === existingCslo.id);
    const loDecimalString = `${topicIndex + 1}.${loIndex + 1}`;
    const classSessionIds = existingCslos.map(({ classSessionId }) => classSessionId);
    return {
      ...learningObjective,
      metadata: {
        classSessionIds,
        cslos: existingCslos,
        loDecimalString,
        loIndex,
        loNumberString: `LO ${loDecimalString}`,
        topicIndex,
      },
    };
  });
  return sortCourseLearningObjectives(enrichedLearningObjectives);
}

export default createSelector(
  (store: Store) => store.active.classSessionLearningObjectives,
  (store: Store) => store.active.classSessions,
  (store: Store) => store.active.classSessionTopics,
  (store: Store) => store.active.learningObjectives,
  (store: Store) => store.active.topics,
  (
    classSessionLearningObjectives,
    classSessions,
    classSessionTopics,
    learningObjectives,
    topics
  ) => {
    return addMetadataToLearningObjectives({
      classSessionLearningObjectives,
      learningObjectives,
      classSessions,
      classSessionTopics,
      topics,
    });
  }
);
