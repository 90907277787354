import { QuestionTypeAbbreviationEnum, UserFacingQuestionType } from 'instructor/controllers/Course/AssessmentBuilderController/AssessmentBuilderController.types';
import { QuestionUseEnum } from 'types/backend/shared.types';
import { L8yQuestionType } from 'types/backend/l8y.types';

export const bloomsArray: Array<{ name: string; value: number }> = [
  { name: 'Remember', value: 1 },
  { name: 'Understand', value: 2 },
  { name: 'Apply', value: 3 },
  { name: 'Analyze', value: 4 },
  { name: 'Evaluate', value: 5 },
  { name: 'Create', value: 6 },
];

export const userFacingQuestionTypes: Array<UserFacingQuestionType> = [
  { userText: 'Fill in the Blank', abbreviation: QuestionTypeAbbreviationEnum.Fill, l8yTypes: [L8yQuestionType.ClozeDropDown] },
  { userText: 'Labeling & Hotspot', abbreviation: QuestionTypeAbbreviationEnum.LabelHotspot, l8yTypes: [L8yQuestionType.LabelImageDragAndDrop, L8yQuestionType.LabelImageDropDown, L8yQuestionType.Hotspot] },
  { userText: 'Math & Graphing', abbreviation: QuestionTypeAbbreviationEnum.MathAndGraphing, l8yTypes:
    [
      L8yQuestionType.ClozeFormula,
      L8yQuestionType.ImageClozeFormula,
      L8yQuestionType.GraphPlotting,
      L8yQuestionType.NumberLine,
      L8yQuestionType.NumberLinePlot,
      L8yQuestionType.SimpleChart,
    ],
  },
  { userText: 'Matching', abbreviation: QuestionTypeAbbreviationEnum.Match, l8yTypes: [L8yQuestionType.MatchList] },
  { userText: 'Multiple Choice', abbreviation: QuestionTypeAbbreviationEnum.MultipleChoice, l8yTypes: [L8yQuestionType.MultipleChoiceMultipleSelect] },
  { userText: 'Open Response', abbreviation: QuestionTypeAbbreviationEnum.Open, l8yTypes: [L8yQuestionType.EssayRichText] },
  { userText: 'Sequence', abbreviation: QuestionTypeAbbreviationEnum.Sequence, l8yTypes: [L8yQuestionType.Sequence, L8yQuestionType.OrderList] },
  { userText: 'Sorting', abbreviation: QuestionTypeAbbreviationEnum.Sorting, l8yTypes: [L8yQuestionType.Sorting] },
];

export const questionUseArray = [
  ['Preclass', QuestionUseEnum.Preclass],
  ['Postclass', QuestionUseEnum.Postclass],
  ['Readiness', QuestionUseEnum.Readiness],
] as Array<[string, QuestionUseEnum]>;

export const availableZoneOptions = [
  { value: 'America/New_York', label: 'Eastern (America/New_York)' },
  { value: 'America/Chicago', label: 'Central (America/Chicago)' },
  { value: 'America/Denver', label: 'Mountain (America/Denver)' },
  { value: 'America/Los_Angeles', label: 'Pacific (America/Los_Angeles)' },
  { value: 'America/Anchorage', label: 'Alaska (America/Anchorage)' },
  { value: 'Pacific/Honolulu', label: 'Hawaii (Pacific/Honolulu)' },
];
