import apiNext from 'api-next';
import reloadAssessmentQuestions from 'store/actions/reloadAssessmentQuestions';
import activeSlice from 'store/slices/active';
import { AssessmentApiBase, AssessTypeEnum, SummativeAssessmentApi } from 'types/backend/assessments.types';
import { AppDispatch, Store } from 'types/store.types';

export type AssessmentDelta = Pick<AssessmentApiBase, 'dueDate' | 'freeAttempts' | 'gradingPolicy' | 'lateDate' | 'latePenalty' | 'name' | 'openDate' | 'pointPenalty' | 'published'>

export default function editAssessment(id: string, assessmentDelta: AssessmentDelta) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    async function updateLocalStateIfSuccessful(localEdited: AssessmentApiBase, serverEdited: AssessmentApiBase) {
      if (serverEdited) {
        dispatch(activeSlice.actions.editActiveAssessment({ id: serverEdited.id, delta: serverEdited }));
        // reload AQs because points change if GP changed from no points <-> points
        await dispatch(reloadAssessmentQuestions([serverEdited.id]));
      } else {
        console.error('Assessment not updated', localEdited.id, localEdited);
      }
    }

    const { active: { assessments } } = getStore();

    const assessment = assessments.find((a) => a.id === id) as AssessmentApiBase;
    const editedAssessment = { ...assessment, ...assessmentDelta };
    const serverEditedAssessment = await apiNext.editAssessment(id, editedAssessment);
    await updateLocalStateIfSuccessful(editedAssessment, serverEditedAssessment);
    // also update nested prep, PT in summative
    if (assessment.assessType === AssessTypeEnum.Prep) {
      const summativeAssessment = assessments.find(a => a.assessType === AssessTypeEnum.Summative && (a as SummativeAssessmentApi).prep?.id === assessment.id) as SummativeAssessmentApi;
      dispatch(activeSlice.actions.editActiveAssessment({
        id: summativeAssessment.id,
        delta: {
          ...summativeAssessment,
          prep: serverEditedAssessment,
        },
      }));
    } else if (assessment.assessType === AssessTypeEnum.PracticeTest) {
      const summativeAssessment = assessments.find(a => a.assessType === AssessTypeEnum.Summative && (a as SummativeAssessmentApi).practiceTest?.id === assessment.id) as SummativeAssessmentApi;
      dispatch(activeSlice.actions.editActiveAssessment({
        id: summativeAssessment.id,
        delta: {
          ...summativeAssessment,
          practiceTest: serverEditedAssessment,
        },
      }));
    }
    return serverEditedAssessment;
  })();
}
