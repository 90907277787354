import React from 'react';
import { DateTime } from 'luxon';
import { FaRegQuestionCircle, FaRegStar } from 'react-icons/fa';

import CodonUrls from 'urls';
import { DateFormatEnum, daysUntilExam } from 'utils/dateFormattingFunctions';
import { calculateHeroFontSize, formatPlural } from 'utils/commonFormattingFunctions';
import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import { SummativeAssessmentWithEnrollment } from 'store/selectors/retrieveAssessmentsWithEnrollment';
import Icon, { IconEnum } from 'shared-components/Icon';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { StudyPathSummaryInfo } from '../../StudyPathController.types';
import { PositionEnum } from 'types/common.types';
import './StudyPathSummaryBar.scss';

export default function StudyPathSummaryBar({ activeSummative, selectedSummative, summaryInfo }: {
  summaryInfo?: StudyPathSummaryInfo
  activeSummative: SummativeAssessmentWithEnrollment | null
  selectedSummative: SummativeAssessmentWithEnrollment | undefined
}) {
  const isCurrentSummative = selectedSummative?.id === activeSummative?.id;
  if (!activeSummative && !selectedSummative) {
    return (
      <div className="study-path__summary-bar row">
        <div className="study-path__summary-bar-no-summative">
          <span>
            There are no Study Paths currently scheduled for this course.
          </span>
        </div>
      </div>
    );
  }
  const { totalLOs = 0, availableLOs = 0 } = summaryInfo || {};
  const { name: selectedSummativeName = '' } = selectedSummative || {};
  const { name: activeSummativeName = '', mergedOpenDate: activeSummativeMergedOpenDate = '' } = activeSummative || {};
  const daysUntil = !!activeSummative && isCurrentSummative ? daysUntilExam(activeSummative.mergedOpenDate) : 0;
  const activeSummativeDueString = DateTime.fromISO(activeSummativeMergedOpenDate).toFormat(DateFormatEnum.WeekdayMonthDate);
  const heroFontSize = calculateHeroFontSize(daysUntil.toString().length, [72, 72, 52, 48]);
  return (
    <section className="study-path__summary-bar row">
      <div className="study-path__summary-bar-element hero-border col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="study-path__summary-bar-icon study-path__summary-bar-icon-lo">
          <Icon which={IconEnum.LO} size={72} />
        </div>
        <div className="hero-number">
          {availableLOs}
          <span className="sidekick"> of </span>
          {totalLOs}
        </div>
        <div className="element-description">
          Learning Objectives for {selectedSummativeName} covered so far
        </div>
      </div>
      <div className="study-path__summary-bar-element hero-border col-xs-12 col-sm-6 col-md-3 col-lg-3">
        <div className="study-path__summary-bar-icon study-path__summary-bar-icon-test">
          <Icon which={IconEnum.Test} size={72} />
        </div>
        {isCurrentSummative ? (
          <div className="study-path__summary-bar-days-until">
            <div className="hero-number" style={{ fontSize: `${heroFontSize}px` }}>
              {daysUntil}
            </div>
            <div className="element-description">
              Days until {activeSummativeName}
              <div className="element-description__date">on {activeSummativeDueString}</div>
            </div>
          </div>
        ) : (
          <div className="element-description prior-summative">
            {selectedSummativeName} has already happened.<br/>Use the Study Path to review past questions.
          </div>
        )}
      </div>
      <div className="study-path__summary-bar-element col-xs-12 col-sm-6 col-md-3 col-lg-3">
        <div className="study-path__summary-bar-icon study-path__summary-bar-icon-recapture">
          <FaRegStar size={72} />
        </div>
        <div className="hero-number">{summaryInfo?.questionsForRecapture}</div>
        <div className="element-description">
          {formatPlural('Question', summaryInfo?.questionsForRecapture || 0)} with points left to recapture &nbsp;
          <BetterTooltip
            content={() => (
              <>
                <div className="study-path__summary-bar-tooltip">
                  <div className="study-path__summary-bar-tooltip-icon">
                    <FaRegStar />
                  </div>
                  <div className="study-path__summary-bar-tooltip-text">
                    Look for this icon to find questions with point recapture available.
                  </div>
                </div>
                <br/>
                Think something is missing or not sure how points work?
                <br/>
                {externalLink(CodonUrls.HowToRecaptureKB, 'Learn More')}.
              </>
            )}
            position={PositionEnum.BottomLeft}
            wide
          >
            <FaRegQuestionCircle size={16} />
          </BetterTooltip>
        </div>
      </div>
    </section>
  );
}
