/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/mouse-events-have-key-events */
// JUSTIFICATION: This component will be DEPRECATED with new CoursePlanner
// TopicGrid > TopicSelect > TopicPopup
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FaWindowClose, FaTimes } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';

import { getUnitName } from 'utils/commonFormattingFunctions';
import sharedStrings from 'sharedStrings';
import { SelectItemTypeEnum } from '../../coursePlanner.types';
import Editable from './Editable';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import './TopicPopup.scss';

function TopicSelectPopup({
  selectedTopics,
  classSessionId,
  toggleOff,
  topicOptions,
  units,
  courseLibrary,
  onAddItemToClassSession,
  onRemoveItemFromClassSession,
  onAddCustomItem,
}) {
  const [isHovering, setIsHovering] = useState(false);
  const [customTopicInput, setCustomTopicInput] = useState('');
  const [parentTreeMap, setParentTreeMap] = useState(new Map());
  const [subPopupVisible, toggleSubPopup] = useState(false);
  const [subPopupData, setSubPopupData] = useState({});
  const course = useSelector((store) => store.active.course);
  const subjects = useSelector((store) => store.passive.subjects);
  const user = useSelector((store) => store.user);
  const instructors = useSelector((store) => store.active.instructors);

  const primarySubjectId = course.subjectId;
  const primarySubject = subjects.find(s => s.id === primarySubjectId);
  const additionalSubjects = subjects.filter(s => course.additionalSubjectIds.includes(s.id));
  const [expandedSubjects, setExpandedSubjects] = useState({ [primarySubjectId]: true });

  const toggleExpandedSubjects = (id) => {
    setExpandedSubjects((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the state for the given ID
    }));
  };

  const handleSelectedItemClick = (item) => {
    const classSessionMapId = item.classSessionTopicId;
    onRemoveItemFromClassSession(
      item.value,
      SelectItemTypeEnum.Topic,
      classSessionId,
      item.type,
      classSessionMapId
    );
  };

  function closePopup() {
    toggleOff();
  }

  //this effect handles mouse event listeners and clicks for the popup window
  useEffect(() => {
    function onClickAnywhere() {
      if (!isHovering) {
        toggleOff();
      }
    }
    document.addEventListener('click', onClickAnywhere);
    return () => {
      document.removeEventListener('click', onClickAnywhere);
    };
  }, [isHovering, toggleOff]);

  //this effect loads up a Map collection for tracking expand/collapse state for Topics
  useEffect(() => {
    const tree = new Map();
    selectedTopics.forEach(topic => {
      tree.set(topic.unitId, true);
    });
    setParentTreeMap(tree);
  }, [selectedTopics, topicOptions, units]);

  const handleItemClicked = (item) => {
    const parentId = item.parent.value;
    onAddItemToClassSession(
      item.value,
      SelectItemTypeEnum.Topic,
      classSessionId,
      parentId
    );
  };

  function handleMouseEnter() {
    setIsHovering(true);
  }

  function handleMouseLeave() {
    setIsHovering(false);
    toggleSubPopup(false);
  }

  function handleParentClick(id) {
    const map = parentTreeMap;
    map.set(id, !map.get(id));
    setParentTreeMap(new Map(map));
  }

  function getTopicLOs(itemData) {
    if (itemData.value < 0 || isNaN(itemData.value)) {
      //when it's custom there won't be LOs, and if the  value is not a number, in a nanoId for a recently created item that will go away soon
      return {};
    }
    const { parent: itemParent } = itemData;
    const parentUnit = courseLibrary[itemParent.value];
    const { topics } = parentUnit || {};
    const { learningObjectives } = topics[itemData.value];
    return learningObjectives;
  }

  function showTopicSubPopup(learningObjectives) {
    setSubPopupData(learningObjectives);
    toggleSubPopup(true);
  }

  function subPopupContents(popupData) {
    if (Object.values(popupData).length === 0) {
      return null;
    }
    const isList = Object.keys(popupData).every((k) => !isNaN(k));
    if (!isList) {
      const { title, contents } = popupData;
      return (
        <>
          <b>{title}</b>
          <p>{contents}</p>
        </>
      );
    }
    return (
      <>
        <b>Learning Objectives Available</b>
        <ul>
          {Object.values(popupData).map(({ id, name, type }) => (
            <li key={`lo-${id}-${name}`}>
              {`${name}${type === LibraryTypeEnum.User ? ' (Custom LO)' : ''}`}
            </li>
          ))}
        </ul>
      </>
    );
  }

  function handleCustomPopup() {
    const popupData = {
      title: 'Add Custom Topic',
      contents: 'Use "Add Custom Topic" if the topic you would like to cover isn\'t included in the Codon library.',
    };
    setSubPopupData(popupData);
    toggleSubPopup(true);
  }

  //Adds custom options. Negative values indicate custom options. added in reverse order they are added to course.
  function handleAddCustom(unitId) {
    if (!!customTopicInput.length) {
      const newItem = {};
      const { value } = topicOptions[0] || -1;
      newItem.value = value < 0 ? value - 1 : -1;
      newItem.label = customTopicInput;
      onAddCustomItem(
        newItem.label,
        classSessionId,
        unitId
      );
    }
    setCustomTopicInput('');
  }

  function renderCustomInput(unitId) {
    return (
      <div className="topic-popup__add-custom" key="add-custom">
        <Editable
          text={customTopicInput}
          placeholder={`${sharedStrings.CUSTOM_ITEM_MESSAGE} ${sharedStrings.TOPIC}`}
          type="input"
          handleAddCustom={() => handleAddCustom(unitId)}
          onMouseOver={() => handleCustomPopup()}
          onMouseLeave={() => toggleSubPopup(false)}
        >
          <input
            type="text"
            name="task"
            spellCheck="false"
            placeholder={`${sharedStrings.CUSTOM_ITEM_MESSAGE} ${sharedStrings.TOPIC}`}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            value={customTopicInput}
            onChange={(e) => setCustomTopicInput(e.target.value)}
          />
        </Editable>
      </div>
    );
  }

  const buildUnitDiv = (id, name) => {
    return (
      <div
        className="topic-popup-item-parent"
        onClick={() => handleParentClick(id)}
        key={`popup-item-parent-${id}`}
      >
        <IoIosArrowForward className="expando" size={12} data-expanded={parentTreeMap.get(id)}/>
        {name}
      </div>
    );
  };

  const buildTopicDiv = (isSelected, topicOption, label, hasLearningObjectives, topicLos) => {
    return (
      <div
        onClick={() => handleItemClicked(topicOption)}
        key={topicOption.value}
        className={`topic-popup__item-list-${isSelected ? 'selected' : 'unselected'}`}
        onMouseOver={() => hasLearningObjectives && showTopicSubPopup(topicLos)}
        onMouseLeave={() => toggleSubPopup(false)}
      >
        {label}
      </div>
    );
  };

  function renderUnitTopicList(subjectId) {
    const { name: subjectName } = subjects.find(s => s.id === subjectId);
    const unitList = units.reduce((acc, unit) => {
      if (unit.subjectId !== subjectId) {
        return acc;
      }
      const { id: unitId, type: unitType, userId: unitUserId } = unit;
      const isUnitUser = unitUserId === user.id;
      const unitName = getUnitName({ unit, unitBelongsToThisUser: isUnitUser, instructors, subjectName });
      const unitDiv = buildUnitDiv(unitId, unitName);
      acc.push(unitDiv);
      const topicOptionsForUnit = topicOptions.filter(to => to.parent.value === unitId);
      if (parentTreeMap.get(unitId)) { //if the unit is expanded
        topicOptionsForUnit.forEach(topicOption => {
          const { value, label } = topicOption;
          const topicLOs = getTopicLOs(topicOption);
          const hasLearningObjectives = Object.values(topicLOs).length > 0;
          const isSelected = selectedTopics.findIndex((st) => st.id === value) !== -1;
          const topicDiv = buildTopicDiv(isSelected, topicOption, label, hasLearningObjectives, topicLOs);
          acc.push(topicDiv);
        });
        if (unitType === LibraryTypeEnum.User && isUnitUser) {
          acc.push(renderCustomInput(unitId));
        }
      }
      return acc;
    }, []);
    return unitList;
  }

  function selectedTopicsList() {
    //it it a topic list but nothing special
    if (!selectedTopics || selectedTopics.length === 0) {
      return <>{sharedStrings.SELECT_TOPICS_MESSAGE}</>;
    }

    return (
      <>
        {selectedTopics.map((item) => (
          <span
            className="topic-popup__selected-item"
            onClick={() => handleSelectedItemClick(item)}
            key={`popup-selected-item${item.value}`}
            title={`${item.label} (click to remove)`}
          >
            {item.label}
            <span className="topic-popup__remove-item-hover">
              <FaTimes />
            </span>
          </span>
        ))}
      </>
    );
  }

  return (
    <div
      className="topic-popup__wrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="topic-popup__selected-items-wrapper">
        <div className="topic-popup__selected-items">
          {selectedTopicsList()}
        </div>
        <div className="topic-popup__close" onClick={closePopup}>
          <FaWindowClose />
        </div>
      </div>
      <div className="topic-popup__item-list-container">
        <div className="topic-popup__subject" onClick={() => toggleExpandedSubjects(primarySubject.id)}>
          <IoIosArrowForward className="expando" size={12} data-expanded={expandedSubjects[primarySubjectId]}/>
          {primarySubject.name}
        </div>
        {expandedSubjects[primarySubjectId] && renderUnitTopicList(primarySubject.id)}
        {!!additionalSubjects.length && (
          <div>
            <div className="topic-popup__additional-subjects-divider" />
            <div className="topic-popup__additional-subjects-header">
              Additional Subjects
            </div>
            {additionalSubjects.map((subject) => (
              <div key={`popup-subject-${subject.id}`}>
                <div className="topic-popup__subject" onClick={() => toggleExpandedSubjects(subject.id)}>
                  <IoIosArrowForward className="expando" size={12} data-expanded={expandedSubjects[subject.id]}/>
                  {subject.name}
                </div>
                {expandedSubjects[subject.id] && renderUnitTopicList(subject.id)}
              </div>
            ))}
          </div>
        )}
      </div>
      {subPopupVisible && (
        <div
          className="topic-popup__sub-popup sub-popup__topic"
        >
          {subPopupContents(subPopupData)}
        </div>
      )}
    </div>
  );
}

TopicSelectPopup.propTypes = {
  selectedTopics: PropTypes.array,
  classSessionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  toggleOff: PropTypes.func,
  topicOptions: PropTypes.array,
  units: PropTypes.array,
  courseLibrary: PropTypes.object.isRequired,
  onAddItemToClassSession: PropTypes.func.isRequired,
  onRemoveItemFromClassSession: PropTypes.func.isRequired,
  onAddCustomItem: PropTypes.func.isRequired,
};

export default TopicSelectPopup;
