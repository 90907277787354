// sourced from https://github.com/codonlearning/backend/blob/dev/src/services/studentTopicCards/studentTopicCards.types.ts
import { CheckpointColumn } from 'types/backend/shared.types';

export interface StudentTopicCardDb {
  id: number
  student_study_path__id: number
  topic_card__id: number
  checkpoint: CheckpointColumn
  created_at: string
  updated_at: string
  l8y_session__id: string
}

interface StudentTopicCardApiBase {
  id?: number
  studentStudyPathId: number
  topicCardId: number
  checkpoint: CheckpointColumn
  createdAt?: string
  updatedAt?: string
}

export interface StudentTopicCardReq extends StudentTopicCardApiBase {
  source?: StudentTopicCardSource
  action?: StudentTopicCardAction
}

export interface StudentTopicCardRes extends Required<StudentTopicCardApiBase> {
  l8ySessionId: string
}

export enum StudentTopicCardSource {
  System = 'system',
  User = 'user'
}

export enum StudentTopicCardAction {
  Init = 'init',
  Answered = 'answered',
  Skipped = 'skipped',
  Reinit = 'reinit'
}

export interface StudentTopicCardHistory {
  id: number
  student_topic_card__id: number
  new_checkpoint: CheckpointColumn
  created_at: string
  source: StudentTopicCardSource
  action: StudentTopicCardAction
}
