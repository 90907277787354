import { createAsyncThunk } from '@reduxjs/toolkit';
import apiNext from 'api-next';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { UnitApi } from 'types/backend/units.types';
import { Store } from 'types/store.types';

export const addClassSessionTopic = createAsyncThunk(
  'active/addClassSessionTopic',
  async ({ classSessionId, topicId }: { classSessionId: number; topicId: number }) => {
    return apiNext.createClassSessionTopic({
      classSessionId,
      topicId,
    });
  }
);
export const removeClassSessionTopic = createAsyncThunk(
  'active/removeClassSessionTopic',
  async ({ classSessionTopicId }: { classSessionTopicId: number }) => {
    return apiNext.deleteClassSessionTopic(classSessionTopicId);
  }
);
export const addUserTopic = createAsyncThunk(
  'active/addUserTopic',
  async ({ name, subjectId, userId }: { name: string; subjectId: number; userId: string }, thunkApi) => {
    const { active: { units } } = thunkApi.getState() as Store;
    const userUnit = units.find(unit => unit.type === LibraryTypeEnum.User && unit.subjectId === subjectId && unit.userId === userId) as UnitApi;
    if (!userUnit) {
      throw new Error(`userUnit not found for subjectId: ${subjectId} and userId: ${userId}`);
    }
    const createdTopic = await apiNext.createUserTopic({
      name,
      unitId: userUnit.id,
      type: LibraryTypeEnum.User,
      userId,
    });
    // subjectId is not part of TopicApi on createUserTopic so, since we already know the subjectId, inline it as part of the return object for injection into redux store
    return {
      ...createdTopic,
      subjectId,
    };
  }
);

