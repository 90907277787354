import React from 'react';
import Select, { MenuPlacement } from 'react-select';
import { questionUseArray } from 'codonConstants';
import { OnChangeEvent } from './Dropdown.types';
import { QuestionUseEnum } from 'types/backend/shared.types';

const questionUseOptions = [
  ...questionUseArray.map(([label, value]) => ({
    label,
    value,
  })),
];

function QuestionUseDropdown({ selectedValue, onChange, ...props }: {
  selectedValue: QuestionUseEnum
  onChange: (selectedValue: QuestionUseEnum) => void
  menuPlacement?: MenuPlacement
}) {
  const dropdownValue = questionUseOptions.find(o => o.value === selectedValue);

  const handleChange = (val: OnChangeEvent<QuestionUseEnum>) => {
    const updateValue = val?.value;
    !!updateValue && onChange(updateValue);
  };

  return (
    <Select
      options={questionUseOptions}
      value={dropdownValue}
      defaultValue={questionUseOptions[0]}
      name="blooms"
      onChange={handleChange}
      {...props}
    />
  );
}

export default QuestionUseDropdown;
