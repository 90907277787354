import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { ClassSessionTopicApi } from 'types/backend/classSessionTopics.types';
import { AppDispatch, Store } from 'types/store.types';

export default function moveTopicToClassSession(classSessionTopicId: number, newClassSessionId: number) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active } = getStore();
    const {
      classSessionTopics: currentClassSessionTopics,
      classSessionLearningObjectives: currentClassSessionLearningObjectives,
    } = active;
    const currentClassSessionTopic = currentClassSessionTopics.find(({ id }) => id === classSessionTopicId);
    if (!currentClassSessionTopic) {
      console.warn('classSessionTopic to move not found in current classSessionTopics', classSessionTopicId);
      return;
    }

    // make the API request
    await apiNext.editClassSessionTopic(classSessionTopicId, {
      ...currentClassSessionTopic,
      classSessionId: newClassSessionId,
    });

    // then update the related CSTs and CSLOs in the store
    const relatedClassSessionIds = [currentClassSessionTopic.classSessionId, newClassSessionId];
    const updatedClassSessionTopicsForRelatedClassSessionIds = await apiNext.getClassSessionTopicsForClassSessionIds(relatedClassSessionIds);
    const updatedClassSessionLearningObjectivesForRelatedClassSessionIds = await apiNext.getClassSessionLosForClassSessionIds(relatedClassSessionIds);

    const updatedClassSessionTopics = currentClassSessionTopics.reduce((acc: Array<ClassSessionTopicApi>, cur) => {
      if (relatedClassSessionIds.includes(cur.classSessionId)) {
        const updatedCst = updatedClassSessionTopicsForRelatedClassSessionIds.find(cst => cst.id === cur.id);
        if (!!updatedCst) {
          acc.push(updatedCst);
        }
      } else {
        acc.push(cur);
      }
      return acc;
    }, []);
    dispatch(activeSlice.actions.setActiveClassSessionTopics(updatedClassSessionTopics));

    const updatedClassSessionLearningObjectives = currentClassSessionLearningObjectives.reduce((acc: Array<ClassSessionLearningObjectiveApi>, cur) => {
      if (relatedClassSessionIds.includes(cur.classSessionId)) {
        const updatedCslo = updatedClassSessionLearningObjectivesForRelatedClassSessionIds.find(cslo => cslo.id === cur.id);
        if (!!updatedCslo) {
          acc.push(updatedCslo);
        }
      } else {
        acc.push(cur);
      }
      return acc;
    }, []);
    dispatch(activeSlice.actions.setActiveClassSessionLearningObjectives(updatedClassSessionLearningObjectives));
  })();
}
