import React, { useEffect, useRef } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import useInput from 'hooks/useInput';
import { useDebounce } from 'hooks/useDebounce';
import './SearchInput.scss';

function SearchInput({
  onChange,
  debounce = true,
  className = '',
  ...inputProps
}: {
  className?: string
  debounce?: boolean
  onChange: (newValue: string) => void
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, handleInputChange, setInputValue] = useInput('');
  const debouncedValue = useDebounce(inputValue);

  const resetInput = () => {
    setInputValue('');
    onChange('');
  };

  const handleSearchClick = () => {
    onChange(inputValue);
    if (!inputValue.length && !!inputRef.current) {
      // if search is empty and user clicks the icon, put focus in the input
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  return (
    <div className="search-input" data-search-active={!!inputValue.length}>
      <input
        {...inputProps}
        type="text"
        className={`search-input__input-element ${className}`}
        placeholder="Search Learning Objectives"
        ref={inputRef}
        onChange={handleInputChange}
        value={inputValue}
      />
      <button type="submit" className="search-input__button" onClick={handleSearchClick}>
        {!inputValue.length
          ? <FaSearch className="search-icon" />
          : <FaTimes className="x-icon" onClick={resetInput} />
        }
      </button>
    </div>
  );
}

export default SearchInput;
