/**
 * moveCsloToAnotherTopic
 *
 * Adapted from reorderClassSessionLearningObjective
 *
 **/
import apiNext, { SimplifiedErrorResponse } from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';
import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';

function moveCsloToAnotherTopic(movingCsloId: number, classSessionId: number, targetTopicId: number) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active } = getStore();
    const { classSessionLearningObjectives, course: { id: courseId } } = active;
    const fullCslo = classSessionLearningObjectives.find(({ id }) => id === movingCsloId) as ClassSessionLearningObjectiveApi;
    const modifiedCslo = {
      ...fullCslo,
      topicId: targetTopicId,
      classSessionId,
    };

    const result = await apiNext.editClassSessionLo(modifiedCslo);
    const { error } = result as SimplifiedErrorResponse;
    if (error) {
      console.error('moveCsloToAnotherTopic error', error);

      // if error, reset state cslos to original array
      dispatch(activeSlice.actions.setActiveClassSessionLearningObjectives(classSessionLearningObjectives));
      return result;
    }

    // get all the new cslos for this course
    const updatedCslos = await apiNext.getClassSessionLosByCourseId(courseId);

    dispatch(activeSlice.actions.setActiveClassSessionLearningObjectives(updatedCslos));
    return result;
  })();
}

export default moveCsloToAnotherTopic;
