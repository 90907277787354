import React, { createContext, useContext, useCallback } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ToastNotificationContext.scss';

const ToastContext = createContext({
  notifySuccess: (message: string, autoClose: number | boolean, successType?: string) => {},
  notifyError: (message: string, autoClose: number | boolean) => {},
});

export const useToast = () => useContext(ToastContext);

export const ToastContextProvider = ({ children }: {children: React.ReactNode}) => {
  const notifySuccess = useCallback((message, autoClose, successType = 'success') => {
    toast.success(message, { autoClose, className: `toast-${successType}-container` });
  }, []);

  const notifyError = useCallback((message, autoClose) => {
    toast.error(message, { autoClose });
  }, []);

  return (
    <ToastContext.Provider value={{ notifySuccess, notifyError }}>
      {children}
      <ToastContainer
        position="bottom-right"
        newestOnTop
        hideProgressBar
        autoClose={5000}
      />
    </ToastContext.Provider>
  );
};
