import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import adminUserSlice from 'store/slices/adminUser';
import enrollmentsSlice from 'store/slices/enrollments';
import passiveSlice from 'store/slices/passive';
import stateSlice from 'store/slices/state';
import userSlice from 'store/slices/user';
import loadUserEnrollments from 'store/actions/loadUserEnrollments';
import { AppDispatch, Store } from 'types/store.types';
import { UserApi } from 'types/backend/users.types';

export default function unsetMasqueradeUser() {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const store = getStore();

    dispatch(passiveSlice.actions.clear());
    dispatch(enrollmentsSlice.actions.clear());
    dispatch(activeSlice.actions.clear());
    const adminUser = store.adminUser as UserApi;
    const { id } = adminUser;
    apiNext.masqueradeUserId = null;
    dispatch(stateSlice.actions.clear());
    dispatch(userSlice.actions.setUser(adminUser));
    dispatch(adminUserSlice.actions.clear());
    dispatch(loadUserEnrollments(id));
  })();
}
