import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import apiNext from 'api-next';
import { isAdminRole, validUuid } from 'utils';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import { Store } from 'types/store.types';
import { CourseActivityCheckResponse } from 'types/backend/courseActivityCheck.types';
import './CourseActivityCheck.scss';

export default function CourseActivityCheck() {
  const user = useSelector((store: Store) => store.user);
  const [courses, setCourses] = useState<Array<CourseActivityCheckResponse>>([]);
  const [courseIdsInput, setCourseIdsInput] = useState('');

  const disableSubmitCourseIds = courseIdsInput.split(',').some(cId => !validUuid(cId));

  async function doSearchForCourseActivityChecks(event: React.SyntheticEvent) {
    event.preventDefault();
    const courseIds = courseIdsInput.split(',');
    const newCourseActivityChecks = [];
    if (isAdminRole(user) && !!courseIds.length) {
      const courseActivityChecks = await apiNext.getCourseActivityChecks(courseIds);
      if (!!courseActivityChecks) {
        newCourseActivityChecks.push(...courseActivityChecks);
      }
    }
    setCourses(newCourseActivityChecks);
  }

  return (
    <div className="course-activity-check">
      <div className="course-activity-check-search">
        <form className="course-activity-check-search__form" onSubmit={doSearchForCourseActivityChecks}>
          <label htmlFor="course-activity-check-courseIds">courseId(s)</label>
          <textarea
            id="course-activity-check-courseIds"
            placeholder="Enter a comma-separated list of courseIds with no spaces"
            value={courseIdsInput}
            onChange={e => setCourseIdsInput(e.target.value)}
          />
          <BetterButton
            disabled={disableSubmitCourseIds}
            primary
            text="Get Course Activity Checks"
            onClick={doSearchForCourseActivityChecks}
          />
        </form>
      </div>
      <div>
        {!!courses.length && courses.map(({ id, name, email, entities }) => {
          return (
            <div className="course-activity-check__course" key={id}>
              <div className="course-activity-check__course-data">
                <b>Course id:</b> {id}<br/>
                <b>Course name:</b> {name}<br/>
                <b>Instructor email:</b> {email}
              </div>
              <table className="course-activity-check__table">
                <thead>
                  <tr>
                    <th>
                      Entity type
                    </th>
                    <th>
                      Entity id
                    </th>
                    <th>
                      Entity name
                    </th>
                    <th>
                      Entity updatedAt
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {entities.map(({ entityType, id: entityId, updatedAt, entityName }) => (
                    <tr>
                      <td>{entityType}</td>
                      <td>{entityId}</td>
                      <td>{entityName}</td>
                      <td>{DateTime.fromISO(updatedAt).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    </div>
  );
}
