import React from 'react';
import { FaBullhorn, FaCalendarTimes } from 'react-icons/fa';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import './ClassLabelIcon.scss';

const ClassLabelIcon = ({ classType, size = 48 }: {
  classType: ClassTypeEnum
  size?: number
}) => {
  return (
    <div className="class-label-icon" data-classtype={classType}>
      {classType === ClassTypeEnum.Special
        ? <FaCalendarTimes className="non-class-day" size={size} />
        : <FaBullhorn className="bullhorn" size={size} />
      }
    </div>
  );
};

export default ClassLabelIcon;
