/**
 * ToasterNotification
 *
 * This component can be used for dismissable notifications of any kind, including onboarding
 * current implementation is fairly self-contained for supporting onboarding but should be adaptable
 * to using for error handling or other types of ephemeral notifications, that's the rationale for why
 * it defaults to using localStorage for state so it can be a drop in dismissable notification for basic onboarding
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import useLocalStorage from 'hooks/useLocalStorage';
import { AriaLiveAttribute } from 'shared-components/AriaAnnouncer/AriaLive.types';
import './ToasterNotification.scss';

export enum ToasterFlavorEnum {
  Info = 'info',
  Warn = 'warn',
  Update = 'update',
  Error = 'error',
  Downtime = 'downtime',
}

const ToasterNotification = ({
  className = '',
  dismissable = true,
  id,
  flavor = ToasterFlavorEnum.Info,
  message,
  onHide, // show and onHide is in case we want to handle state from a higher level
  show = false,
}: {
  className?: string
  dismissable?: boolean
  id: string
  flavor?: ToasterFlavorEnum
  message: string | React.ReactNode
  onHide?: () => void
  show?: boolean
}) => {
  const [hideToaster, setHideToaster] = useLocalStorage(`HIDE_TOASTER__${id}`, false);

  // hide notification if localStorage true OR prop func is present to enable both usage modes
  if (hideToaster || (!!onHide && show === false)) {
    return null;
  }

  const handleClose = () => {
    if (!!onHide) {
      onHide();
    } else {
      // only set localstorage value on dismiss if onHide prop is absent
      setHideToaster(true);
    }
  };

  return (
    <div data-flavor={flavor} className={`toaster-header ${className}`}>
      <div className="toaster-header__content">
        <div className="toaster-header__message" aria-live={AriaLiveAttribute.Polite}>
          {message}
        </div>
        {dismissable && (
          <button className="toaster-header__dismiss-button" aria-labelledby="toaster-header__dismiss-button__label" onClick={handleClose}>
            <span hidden id="toaster-header__dismiss-button__label">Dismiss Notification</span>
            <FaTimes />
          </button>
        )}
      </div>
    </div>
  );
};

ToasterNotification.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  flavor: PropTypes.oneOf(Object.values(ToasterFlavorEnum)),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onHide: PropTypes.func,
  show: PropTypes.bool,
};

export default ToasterNotification;
