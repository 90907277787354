import React from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'utils';
import { BetterClassSessionTopic } from 'store/selectors/retrieveBetterClassSessions';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { PositionEnum } from 'types/common.types';

const TopicPill = ({ position, topic }: {
  position: PositionEnum
  topic: BetterClassSessionTopic
}) => {
  const { courseLearningObjectives, id: topicId, name: topicName } = topic;
  return (
    <BetterTooltip
      key={`topic_${topicId}`}
      className="topic-pill-tooltip"
      disableAutoPosition
      position={position}
      content={(
        <div className="topic-pill-tooltip__content">
          <strong className="topic-pill-tooltip__header">
            {topicName}
          </strong>
          <div className="topic-pill-tooltip__body">
            <div className="topic-pill-tooltip__lo-list">
              {courseLearningObjectives.map(({ title, _derived: { loNumber } }) => (
                <div
                  className="topic-pill-tooltip__lo-list-item"
                  key={loNumber}
                  title={`${loNumber}: ${title}`}
                >
                  {loNumber}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    >
      <div className="better-timeline__pill topic-pill" key={`topic_${topicId}`}>
        {truncate(topicName, 32)}
      </div>
    </BetterTooltip>
  );
};

TopicPill.propTypes = {
  topic: PropTypes.object.isRequired,
};

export default TopicPill;
