import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useAppDispatch, useAppSelector } from 'store';
import activeSlice from 'store/slices/active';
import retrieveEnrolledCourses from 'store/selectors/retrieveEnrolledCourses';
import CourseList from 'shared-components/CourseList/CourseList';
import CourseDetailsController from '../Course/CourseDetailsController/CourseDetailsController';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';

function InstructorHome() {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((store) => store.passive.loading);
  const course = useAppSelector((store) => store.active.course);
  const enrolledCourses = useAppSelector(retrieveEnrolledCourses);
  useDocumentTitle('My Courses');

  useEffect(() => {
    // if course is in state when within this component, it should be cleared to avoid carrying redux state to other courses
    if (!!course.id) {
      console.warn('Course is defined when it should not be', course);
      dispatch(activeSlice.actions.clear());
    }
  }, [course, dispatch]);

  if (loading) {
    return <LoadingSpinner id="instructor-passive-loading" />;
  }

  return (
    <main className="instructor-home home">
      <div className="home__main">
        <Switch>
          <Route exact path="/instructor">
            <CourseList
              courses={enrolledCourses}
              isInstructor
            />
          </Route>
          <Route exact path="/instructor/create-course">
            <CourseDetailsController type="new" />
          </Route>
        </Switch>
      </div>
    </main>
  );
}

export default InstructorHome;
