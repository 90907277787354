import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch } from 'types/store.types';


export default function removeLoFromClassSession(classSessionLearningObjectiveId: number) {
  return (dispatch: AppDispatch) => (async () => {
    const deleteClassSessionLoResult = await apiNext.deleteClassSessionLo(classSessionLearningObjectiveId);
    console.debug('deleteClassSessionLoResult', deleteClassSessionLoResult);
    dispatch(activeSlice.actions.removeActiveClassSessionLearningObjectives({ id: classSessionLearningObjectiveId }));
    return deleteClassSessionLoResult;
  })();
}
