// Ported to TS from: https://github.com/AlmeroSteyn/react-aria-live
import React, { useState } from 'react';
import Announcer from './Announcer';
import { ChildrenProp } from 'types/common.types';

const logContextWarning = (message: string, messageId: string) => {
  console.warn('Announcement failed, LiveAnnouncer context is missing', message, messageId);
};

const AnnouncerContext = React.createContext({
  announceAssertive: logContextWarning,
  announcePolite: logContextWarning,
});

export function AnnouncerContextProvider({ children }: { children: ChildrenProp }) {
  const [announcePoliteMessage, setAnnouncePoliteMessage] = useState('');
  const [politeMessageId, setPoliteMessageId] = useState('');
  const [announceAssertiveMessage, setAnnounceAssertiveMessage] = useState('');
  const [assertiveMessageId, setAssertiveMessageId] = useState('');
  const announcePolite = (message: string, messageId: string) => {
    setAnnouncePoliteMessage(message);
    setPoliteMessageId(messageId);
  };
  const announceAssertive = (message: string, messageId: string) => {
    setAnnounceAssertiveMessage(message);
    setAssertiveMessageId(messageId);
  };
  return (
    <AnnouncerContext.Provider value={{ announcePolite, announceAssertive }}>
      {children}
      <Announcer
        assertiveMessage={announceAssertiveMessage}
        assertiveMessageId={assertiveMessageId}
        politeMessage={announcePoliteMessage}
        politeMessageId={politeMessageId}
      />
    </AnnouncerContext.Provider>
  );
}

export default AnnouncerContext;
