import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import useDropdownMenu, { ItemProps } from 'hooks/useDropdownMenu';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { PositionEnum } from 'types/common.types';
import './KebabMenu.scss';

export interface KebabMenuItem {
  icon?: React.ReactNode
  label: string
  onClick?: () => void
  url?: string
  external?: boolean
  show?: boolean
  disabled?: boolean
}

export function KebabDropdownItem({
  disabled = false,
  item,
  index,
  itemProps,
}: {
  disabled?: boolean
  item: KebabMenuItem
  index: number
  itemProps: Array<ItemProps>
}) {
  const { icon, label, onClick, url, external, show = true } = item;
  if (!show) {
    return null;
  }
  if (!!url) {
    if (external === true) {
      return (
        <a {...itemProps[index]} href={url} className="kebab-menu__dropdown__item external-link" target="_blank" rel="noopener noreferrer">
          {label}
        </a>
      );
    }
    return (
      <Link
        className="kebab-menu__dropdown__item"
        {...itemProps[index]}
        to={url}
        data-disabled={disabled}
      >
        {!!icon && icon}{label}
      </Link>
    );
  }
  // Disabling some lint rules because a11y is handled by react-accessible-dropdown-menu-hook module
  return (
    <a // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      {...itemProps[index]}
      className="kebab-menu__dropdown__item"
      onClick={onClick}
      data-disabled={disabled}
    >
      {!!icon && icon}{label}
    </a>
  );
}

const KebabMenu = ({
  className = '',
  disabled,
  items,
  position,
  small = false,
}: {
  className?: string
  disabled?: boolean
  items: Array<KebabMenuItem>
  position?: PositionEnum.Top | PositionEnum.Bottom
  small?: boolean
}) => {
  const {
    buttonProps,
    itemProps,
    isOpen,
    setIsOpen,
  } = useDropdownMenu(items.length);
  const kebabRef = useRef(null);
  const pos = position || PositionEnum.Bottom;
  useOnClickOutside(kebabRef, () => {
    setIsOpen(false);
  });

  return (
    <div
      className={`kebab-menu__wrap ${className} ${small ? 'kebab-menu-small' : ''}`}
      data-expanded={isOpen}
      ref={kebabRef}
    >
      <button
        {...buttonProps}
        className={`kebab-menu__trigger ${isOpen ? 'active' : ''}`}
      >
        <figure></figure>
        <figure className="middle"></figure>
        <div className="cross"><FaTimes size={16} /></div>
        <figure></figure>
      </button>
      <ul className={`kebab-menu__dropdown kebab-dropdown-${pos}`}>
        {items.map((item, idx) => (
          <li key={item.label}>
            <KebabDropdownItem
              disabled={disabled || item.disabled}
              item={item}
              index={idx}
              itemProps={itemProps}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

KebabMenu.propTypes = {
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    url: PropTypes.string,
    onClick: PropTypes.func,
    external: PropTypes.bool,
  })).isRequired,
};

export default KebabMenu;
