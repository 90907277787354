// sourced from https://github.com/codonlearning/backend/blob/dev/src/types/l8y.types.ts
export enum L8yQuestionType {
  MultipleChoiceMultipleSelect = 'mcq',
  ClozeDropDown = 'clozedropdown',
  LabelImageDropDown = 'imageclozedropdown',
  Sorting = 'classification',
  Sequence = 'sortlist',
  EssayRichText = 'longtextV2',
  LabelImageDragAndDrop = 'imageclozeassociationV2',
  Hotspot = 'hotspot',
  OrderList = 'orderlist',
  MatchList = 'association',
  ClozeFormula = 'clozeformula',
  ImageClozeFormula = 'imageclozeformula',
  GraphPlotting = 'graphplotting',
  NumberLine = 'numberline',
  NumberLinePlot = 'numberlineplot',
  SimpleChart = 'simplechart'
}

export enum L8yFeatureType {
  ImageTool = 'imagetool',  // also includes ruler, protractor
  SharedPassage = 'sharedpassage',
  VideoPlayer = 'videoplayer',
  AudioPlayer = 'audioplayer',
  Calculator = 'calculator', // also includes scientific calculator
  LineReader = 'linereader'
}

export enum L8yItemStatus {
  Unpublished = 'unpublished',
  Published = 'published',
  Archived = 'archived'
}

export enum L8ySortField {
  Created = 'created',
  Updated = 'updated',
  Reference = 'reference',
  Title = 'title'
}

export enum L8yWidgetType {
  Response = 'response',
  Feature = 'feature'
}

export interface L8yMcqOption {
  label: string
  value: string
}

export interface L8yMetaBase {
  status: true
  timestamp: number
  versions: {
    requested: string
    mapped: string
    concrete: string
  }
}

export interface L8yMetaGet extends L8yMetaBase {
  records: number
  next?: string // if more results than the limit
}

// TAGS
export enum L8yDefaultTag {
  ChangeMe = 'ChangeMe',
  NA = 'NA'
}

export enum QuestionUseTag {
  Preclass = 'PreClass',
  Postclass = 'PostClass',
  Readiness = 'Readiness'
}

export enum ItemTypeTag {
  Template = 'Template',
  User = 'User'
}

export enum RequestTagType {
  All = 'all',
  Either = 'either',
  None = 'none'
}

export enum CodonEnvTag {
  Prod = 'prod',
  Staging = 'staging',
  Dev = 'dev',
  Local = 'local',
  Perf = 'perf'
}

export enum GradingTypeTag {
  Assessment = 'assessment',
  Survey = 'survey'
}
