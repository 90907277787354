import React from 'react';
import { FaRegStar } from 'react-icons/fa';
import Icon, { IconEnum } from 'shared-components/Icon';
import { QuestionStatus } from 'shared-components/LearnosityContainer/LearnosityContainer.types';
import { ClarityEnum, YesNo } from 'types/backend/shared.types';
/**
 * NOTE: styling handled in ReadinessExperience.scss
 * The *NavItem components are siblings, so they share a scss file. In future they might be unified, but for now I chose this way to limit css duplication
 **/

// Share the logic that determines which icon to show, we also need to generate the string for aria label a11y
export const calculateNavItemStatus = ({
  itemTitle,
  questionStatus,
  showCorrectness = true,
}: {
  itemTitle: string
  questionStatus: QuestionStatus
  showCorrectness?: boolean
}) => {
  const { allORQs, canRecap, clarity, isCorrect, isSurveyItem } = questionStatus;
  // if isCorrect is falsy the Q hasn't been answered yet
  if (!isCorrect) {
    return {
      ariaLabel: `${itemTitle} not yet answered`,
      correctnessIcon: IconEnum.None,
    };
  } else {
    const markedMuddy = clarity === ClarityEnum.Muddy ? ' marked muddy' : '';
    const hasRecapString = canRecap ? ', points can be recaptured' : '';
    if (allORQs || isSurveyItem || !showCorrectness) {
      return {
        ariaLabel: `${itemTitle} answered${markedMuddy}`,
        correctnessIcon: IconEnum.CheckSquare,
      };
    }
    const answeredCorrectly = isCorrect === YesNo.Yes;
    const correctnessAdverb = answeredCorrectly ? 'correctly' : 'incorrectly';
    return {
      ariaLabel: `${itemTitle} answered ${correctnessAdverb}${markedMuddy}${hasRecapString}`,
      correctnessIcon: answeredCorrectly ? IconEnum.CheckCircle : IconEnum.XCircle,
    };
  }
};

function AssessmentTakerNavItem({
  handleClick,
  isActive,
  itemIndex,
  l8yId,
  questionStatus,
  showCorrectness,
}: {
  handleClick: () => void
  isActive: boolean
  itemIndex: number
  l8yId: string
  questionStatus: QuestionStatus
  showCorrectness: boolean
}) {
  const { canRecap, clarity, isCorrect } = questionStatus;
  const itemNomenclature = 'Question';
  const itemTitle = `${itemNomenclature} ${itemIndex + 1}`;
  const { ariaLabel, correctnessIcon } = calculateNavItemStatus({ itemTitle, questionStatus, showCorrectness });
  return (
    <li className="assessment-taker-nav-item" data-l8yid={l8yId}>
      <button
        aria-label={ariaLabel}
        className="assessment-taker-nav-item__button"
        data-active={isActive}
        onClick={handleClick}
      >
        <div className="assessment-taker-nav-item__correctness">
          <Icon which={correctnessIcon} />
        </div>
        <div className="assessment-taker-nav-item__title">
          {itemTitle}
          {canRecap && <FaRegStar />}
        </div>
        <div className="assessment-taker-nav-item__question-status" data-show={!!clarity && !!isCorrect}>
          <Icon which={clarity === ClarityEnum.Muddy ? IconEnum.Muddy : IconEnum.None} />
        </div>
      </button>
    </li>
  );
}

export default AssessmentTakerNavItem;

