import React from 'react';
import Select, { MenuPlacement } from 'react-select';
import { bloomsArray } from 'codonConstants';
import { OnChangeEvent } from './Dropdown.types';

const bloomOptions = [
  { label: 'Not Indicated', value: -1 },
  ...bloomsArray.map(({ name, value }) => ({
    label: `${value}. ${name}`,
    value,
  })),
];

function BloomsDropdown({ selectedBlooms, onChange, ...props }: {
  selectedBlooms: number | null
  onChange: (newSelectedBlooms: number | null) => void
  menuPlacement?: MenuPlacement
}) {
  const selectValue = selectedBlooms === null
    ? bloomOptions.find(o => o.value === -1)
    : bloomOptions.find(o => o.value === selectedBlooms);

  const handleChange = (val: OnChangeEvent<number>) => {
    // convert from -1 to null
    const updateValue = !!val?.value && val?.value !== -1 ? val.value : null;
    onChange(updateValue);
  };

  return (
    <Select
      options={bloomOptions}
      value={selectValue}
      defaultValue={bloomOptions[0]}
      name="blooms"
      onChange={handleChange}
      {...props}
    />
  );
}

export default BloomsDropdown;
