import { AssessmentApiBase, SummativeAssessmentApi } from 'types/backend/assessments.types';

export enum AssessmentListEvents {
  ChangePublished = 'ChangePublished',
  ChangeOpenDate = 'ChangeOpenDate',
  ChangeDueDate = 'ChangeDueDate',
  ChangeLateDate = 'ChangeLateDate',
  ChangeGradeSyncEnabled = 'ChangeGradeSyncEnabled',
  ChangeLatePolicy = 'ChangeLatePolicy',
}

export enum AssessmentListSummativeEvents {
  ChangePrepPublished = 'ChangePrepPublished',
  ChangePrepGradeSyncEnabled = 'ChangePrepGradeSyncEnabled',
  ChangePrepLateDate = 'ChangePrepLateDate',
  ChangePrepLatePolicy = 'ChangePrepLatePolicy',
  ChangePracticePublished = 'ChangePracticePublished',
  ChangePracticeGradeSyncEnabled = 'ChangePracticeGradeSyncEnabled',
  ChangePracticeLateDate = 'ChangePracticeLateDate',
  ChangePracticeLatePolicy = 'ChangePracticeLatePolicy',
}

type AssessmentListAssessmentData = Partial<Pick<AssessmentApiBase, 'openDate' | 'dueDate' | 'published' | 'latePolicy' | 'lateDate' | 'latePenalty' | 'isGradeSyncEnabled' | 'assessType'>>

type HasInvalidDates = {
  hasInvalidOpenDueDates: boolean
  hasInvalidDueLateDates: boolean
}

export type AssessmentListEventData = AssessmentListAssessmentData & HasInvalidDates

export interface EditedAssessmentDatum {
  [key: string]: Required<AssessmentListEventData>
}

type PrepPracticeDatum = Pick<SummativeAssessmentApi, 'id' | 'openDate' | 'dueDate' | 'published' | 'latePolicy' | 'lateDate' | 'latePenalty' | 'isGradeSyncEnabled' | 'assessType'>
  & HasInvalidDates;

export interface EditedSummativeAssessmentDatum {
  [key: string]:
    Pick<SummativeAssessmentApi, 'openDate' | 'dueDate' | 'published' | 'latePolicy' | 'lateDate' | 'latePenalty' | 'isGradeSyncEnabled' | 'assessType'>
    & HasInvalidDates
    & {
      prep: PrepPracticeDatum
      practiceTest: PrepPracticeDatum
    }
}

export interface SummativeEventData {
  summativeId: string
  summativeEventData: AssessmentListEventData
  prepId: string
  prepEventData: AssessmentListEventData
  practiceId: string
  practiceEventData: AssessmentListEventData
}
