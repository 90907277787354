import React from 'react';
import PropTypes from 'prop-types';
import styles from 'style-config.scss';
import './Spinner.scss';

export enum SpeedEnum {
  Fast = 'fast',
  Slow = 'slow',
  Default = 'default',
  Paused = 'paused',
}

function speedSwitch(speed: SpeedEnum) {
  if (speed === SpeedEnum.Fast) {
    return 600;
  } else if (speed === SpeedEnum.Slow) {
    return 900;
  } else if (speed === SpeedEnum.Paused) {
    return 0;
  }
  return 750;
}

interface SpinnerProps {
  className?: string
  color: string
  thickness?: number
  gap?: number
  speed?: SpeedEnum
  size: string
}

const Spinner = ({
  className = '',
  color = styles.gray,
  gap = 4,
  size = '1em',
  speed = SpeedEnum.Default,
  thickness = 4,
}: SpinnerProps) => {
  return (
    <svg
      height={size}
      width={size}
      style={{ animationDuration: `${speedSwitch(speed)}ms` }}
      className={`react-svg-spinner__circle ${className}`}
      role="img"
      aria-labelledby="title desc"
      viewBox="0 0 32 32"
    >
      <title id="title">Circle loading spinner</title>
      <desc id="desc">Image of a partial circle indicating "loading."</desc>
      <circle
        role="presentation"
        cx={16}
        cy={16}
        r={14 - thickness / 2}
        stroke={color}
        fill="none"
        strokeWidth={thickness}
        strokeDasharray={Math.PI * 2 * (11 - gap)}
        strokeLinecap="round"
      />
    </svg>
  );
};

Spinner.propTypes = {
  color: PropTypes.string,
  thickness: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8]),
  gap: PropTypes.oneOf([1, 2, 3, 4, 5]),
  speed: PropTypes.oneOf(Object.values(SpeedEnum)),
  size: PropTypes.string.isRequired,
};

Spinner.defaultProps = {
  color: styles.gray,
  gap: 4,
  thickness: 4,
  size: '1em',
};

export default Spinner;
