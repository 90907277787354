import React from 'react';
import PropTypes from 'prop-types';
import {
  DragDropContext,
  Droppable,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd';
import { FaUserEdit } from 'react-icons/fa';

import getAssessmentQuestionsMetadata, { QuestionPreviewLaunchWithMetadata } from 'utils/getAssessmentQuestionsMetadata';
import { FeatureFlags } from 'App';
import { EnrichedEditingAssessment } from '../../enrichAssessmentForEditing';
import QuestionListSidebarSelectedQuestionsListItem from './QuestionListSidebarSelectedQuestionsListItem';
import { AssessmentApiBase } from 'types/backend/assessments.types';
import {
  AssessmentQuestionForQuestionListSidebar,
  QuestionAction,
  TabEnum,
  TabNavigate,
} from '../../AssessmentBuilderController.types';
import './QuestionListSidebar.scss';

function getListClass(snapshot: DroppableStateSnapshot) {
  if (snapshot.isDraggingOver) {
    return 'is-dragging-over';
  } else if (snapshot.draggingOverWith) {
    return 'is-dragging';
  } else {
    return '';
  }
}

interface QuestionListSidebarQuestionsListProps {
  assessmentQuestions: Array<AssessmentQuestionForQuestionListSidebar>
  currentAssessment: EnrichedEditingAssessment | AssessmentApiBase
  hasBeenStarted: boolean
  navigate: TabNavigate
  onChangeQuestionSort: (assessmentQuestionId: number, previousId: number) => void
  questionAction: QuestionAction
  setAssessmentQuestions: (aq: Array<AssessmentQuestionForQuestionListSidebar>) => void
  setQuestionPreview: (preview: QuestionPreviewLaunchWithMetadata | null) => void
  startedQuestionIds: Array<number>
}

function QuestionListSidebarSelectedQuestionsList({
  assessmentQuestions,
  currentAssessment,
  hasBeenStarted,
  navigate,
  onChangeQuestionSort,
  questionAction,
  setAssessmentQuestions,
  setQuestionPreview,
  startedQuestionIds,
}: QuestionListSidebarQuestionsListProps) {
  const flags = React.useContext(FeatureFlags);

  const assessmentQuestionsMetadata = getAssessmentQuestionsMetadata(assessmentQuestions, currentAssessment, hasBeenStarted, startedQuestionIds);

  function onDragEnd(result: DropResult) {

    //TODO: deal with DropResult = cancel here, make sure
    if (result.reason === 'CANCEL' || !result.source || !result.destination) {
      return; //bail if values are missing. This usually happens if the drag was released in a random spot
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    //Make the change in the component to avoid UI flicker
    const newAQs = [...assessmentQuestions];
    const tmpQuestion = newAQs[sourceIndex];
    let previousId;

    if (destIndex === sourceIndex) {
      return; //item dropped on self, bailing.
    } else if (destIndex < sourceIndex) {
      previousId =
        destIndex > 0 ? newAQs[destIndex - 1].assessmentQuestionId : 0; //set previousId for reference in API call
    } else {
      previousId = destIndex > 0 ? newAQs[destIndex].assessmentQuestionId : 0; //set previousId for reference in API call
    }

    newAQs.splice(sourceIndex, 1); //remove this item from list
    const newDestIndex = destIndex;
    newAQs.splice(newDestIndex, 0, tmpQuestion);
    setAssessmentQuestions(newAQs);

    //make the change up the chain
    onChangeQuestionSort(tmpQuestion.assessmentQuestionId, previousId);
  }


  const addCustomItemButton = !flags.enableCustomQuestions ? null : (
    <button
      className="question-list-sidebar__add-custom-question"
      onClick={() => navigate(TabEnum.CreateQuestion)}
    >
      <FaUserEdit />Create Custom Item
    </button>
  );

  if (!assessmentQuestions.length) {
    return (
      <div className="selected-questions-list__empty">
        {addCustomItemButton}
        <div>Add items from the list</div>
      </div>
    );
  }

  return (
    <div className="question-list-sidebar__selected-questions-list">
      <DragDropContext onDragEnd={onDragEnd} >
        <Droppable droppableId="board" type="moveQuestion" isDropDisabled={hasBeenStarted}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={getListClass(snapshot)}
            >
              {assessmentQuestions.map((question, qIndex) => (
                <QuestionListSidebarSelectedQuestionsListItem
                  currentAssessment={currentAssessment}
                  question={question}
                  questionMetadata={assessmentQuestionsMetadata[qIndex]}
                  qIndex={qIndex}
                  key={question.id}
                  navigate={navigate}
                  questionAction={questionAction}
                  setQuestionPreview={() => setQuestionPreview({ initialQuestionId: question.id, questions: assessmentQuestionsMetadata, activeAssessmentId: currentAssessment.id })}
                  hasBeenStarted={hasBeenStarted}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {addCustomItemButton}
    </div>
  );
}

QuestionListSidebarSelectedQuestionsList.propTypes = {
  currentAssessment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assessType: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    classSessionIds: PropTypes.array,
  }),
  onChangeQuestionSort: PropTypes.func,
  questionAction: PropTypes.func.isRequired,
  navigate: PropTypes.func,
  setQuestionPreview: PropTypes.func.isRequired,
  startedQuestionIds: PropTypes.array.isRequired,
};

export default QuestionListSidebarSelectedQuestionsList;
