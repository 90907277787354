import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import sharedStrings from 'sharedStrings';
import CodonUrls from 'urls';
import apiNext from 'api-next';
import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import useQuery from 'hooks/useQuery';
import useSubmitStatus, { SubmitStatus } from 'hooks/useSubmitStatus';
import { useAppDispatch } from 'store';
import userSlice from 'store/slices/user';
import LoginBox from './LoginBox';
import { LogoutStatusEnum } from 'types/common.types';
import { CodonErrorCode } from 'types/backend/error.types';

export default function Login() {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const queryStatus = query.get('status');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [submitStatus, setSubmitStatus, submitDisabled] = useSubmitStatus();

  async function doLogin(event: React.SyntheticEvent) {
    event.preventDefault();
    setSubmitStatus(SubmitStatus.Submitting);
    if (username !== '' && password !== '') {
      try {
        const user = await apiNext.login(username, password);
        if (user) {
          setSubmitStatus(SubmitStatus.Success);
          dispatch(userSlice.actions.setUser(user));
        } else {
          console.error('There was a problem retrieving user information.');
          setSubmitStatus(SubmitStatus.Failure);
        }
      } catch (err: any) {
        console.error('Login failed', err);
        let defaultMsg = 'Your username and/or password is incorrect. Not sure why? Try resetting your password or check out our';
        const { errorCode } = err.data || {};
        if (errorCode === CodonErrorCode.TooManyInvalidLoginAttempts) {
          setSubmitStatus(SubmitStatus.TooManyRequests);
          defaultMsg = err.message;
        } else {
          setSubmitStatus(SubmitStatus.Failure);
        }
        setErrMsg(defaultMsg);
      }
    }
    return false;
  }
  if (queryStatus === LogoutStatusEnum.IdleLogoutLti || queryStatus === LogoutStatusEnum.LogoutLti) {
    return (
      <LoginBox
        hideSubHead
        title="Logged out"
        notificationMsg={
          <>
            You have been logged out{queryStatus === LogoutStatusEnum.IdleLogoutLti ? ' due to inactivity.' : '.'}
            <br />To resume, please return to your Learning Management System ({sharedStrings.LMS_LIST}) and click the launch link provided by your instructor. Having trouble? {externalLink(CodonUrls.LoginSupportArticle, 'See our FAQ')}.
            <br /><br />If you think you have a valid Codon username and password, <Link to="/login">click here to try logging in again.</Link>
          </>
        }
      />
    );
  }

  return (
    <LoginBox
      notificationMsg={queryStatus === LogoutStatusEnum.IdleLogout && <>You have been logged out.<br />Please log back in to resume.</>}
      title="Login"
    >
      <form onSubmit={doLogin}>
        <fieldset disabled={submitDisabled}>
          <input
            aria-label="Email"
            autoComplete="username"
            id="email"
            onChange={e => setUsername(e.target.value)}
            placeholder="Email"
            required
            type="text"
            value={username}
          />
          <input
            aria-label="Password"
            autoComplete="current-password"
            id="password"
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
            required
            type="password"
            value={password}
          />
          <input type="submit" value="Login" />
        </fieldset>
        <div role="alert">
          {submitStatus === SubmitStatus.Failure && (
            <div className="login__error">
              {errMsg} &nbsp;
              {externalLink(CodonUrls.LoginSupportArticle, 'Login Support Article')}.
            </div>
          )}

          {submitStatus === SubmitStatus.TooManyRequests && (
            <div className="login__error">
              {errMsg}
            </div>
          )}
        </div>
      </form>
      <div className="login__footer-link"><a href="/password-reset/request">Reset password?</a></div>
    </LoginBox>
  );
}


