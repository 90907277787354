import React from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { FaTrashAlt, FaGripLinesVertical, FaEdit } from 'react-icons/fa';
import { useAppSelector } from 'store';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import CustomItemIcon from 'shared-components/CustomItemIcon/CustomItemIcon';
import { LoActionEnum, LoActionDispatch, DragTypeEnum } from '../../CourseLoSelector.types';
import { LibraryTypeEnum } from 'types/backend/shared.types';


function ClassSessionLoListTopicObjectives({
  loAction,
  topicId,
  topicLos,
}: {
  loAction: LoActionDispatch
  topicId: number
  topicLos: Array<EnrichedCourseLearningObjective>
}) {
  const user = useAppSelector((store) => store.user);

  return (
    <Droppable droppableId={`${topicId}`} type={DragTypeEnum.MoveLo}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} className="lo-sel-card-drop-placeholder">
          {topicLos.map((objective, index) => (
            <Draggable key={objective.id} draggableId={`${objective.id}`} index={index}>
              {(prov, snap) => (
                <div className="selected-lo-card" data-loid={objective.id} data-stringid={objective.stringId}>
                  <div
                    {...prov.draggableProps}
                    {...prov.dragHandleProps}
                    ref={prov.innerRef}
                    className="lo-sel-card__objective"
                    style={prov.draggableProps.style}
                  >
                    <p>LO {objective._derived.loDecimalString}</p>
                    <div className="lo-sel-card__objective-wrapper">
                      <FaGripLinesVertical className="lo-sel-card__handle lo-sel-card__icon-bars" />
                      <p>{objective.title}</p>
                    </div>
                    <div className="lo-sel-card__edit-title">
                      <button
                        className="lo-selector__icon-btn"
                        onClick={() => loAction(LoActionEnum.RemoveLoFromClassSession, {
                          id: objective.id,
                        })}
                      >
                        <FaTrashAlt />
                      </button>
                      {objective.userId === user.id && (
                        <button
                          className="lo-selector__icon-btn"
                          onClick={() => loAction(LoActionEnum.EditLo, {
                            id: objective.id,
                            title: objective.title,
                            topicId: objective.libraryTopicId,
                          })}
                        >
                          <FaEdit />
                        </button>
                      )}
                      {objective.type === LibraryTypeEnum.User && (
                        <CustomItemIcon
                          className="lo-selector__icon-btn"
                          ownerUserId={objective.userId}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

ClassSessionLoListTopicObjectives.propTypes = {
  loAction: PropTypes.func.isRequired,
  topicLos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    stringId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    _derived: PropTypes.shape({
      loDecimalString: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired).isRequired,
  topicId: PropTypes.number.isRequired,
};

export default ClassSessionLoListTopicObjectives;


