// This action reloads assessmentQuestions from the API based on an array of assessmentIds
// it then updates Redux with the reloaded AQs and returns them as well.

import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';

export default function reloadAssessmentQuestions(assessmentIds: Array<string>) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const store = getStore();
    const reloadedAQs = await apiNext.getAssessmentQuestionMaps(assessmentIds);
    const existingAQs = store.active.assessmentQuestionMaps;

    const updatedAQs = existingAQs.reduce((acc, cur) => {
      if (assessmentIds.includes(cur.assessmentId)) {
        return acc;
      } else {
        return [
          ...acc,
          cur,
        ];
      }
    }, [...reloadedAQs]);

    dispatch(activeSlice.actions.setActiveAssessmentQuestionMaps(updatedAQs));
    return reloadedAQs;
  })();
}
