/**
 * retrieveSortedActiveCombinedQuestions
 * Sorts all ActiveCombinedQuestions, should only be used by instructors.
 **/
import { createSelector } from '@reduxjs/toolkit';

import retrieveActiveCourseLearningObjectives, { EnrichedCourseLearningObjective } from './retrieveActiveCourseLearningObjectives';
import retrieveActiveCombinedQuestions, { ActiveCombinedQuestion } from './retrieveActiveCombinedQuestions';
import { UnitApi } from 'types/backend/units.types';
import { Store } from 'types/store.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';
import { TopicApi } from 'types/backend/topics.types';
import { CourseApi } from 'types/backend/courses.types';
import { SubjectApi } from 'types/backend/subjects.types';
import { LibraryTypeEnum } from 'types/backend/shared.types';

export const sortActiveCombinedQuestions = ({
  course,
  subjects,
  courseLearningObjectives,
  units,
  topics,
  learningObjectives,
  questions,
}: {
  course: CourseApi
  subjects: Array<SubjectApi>
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
  units: Array<UnitApi>
  topics: Array<TopicApi>
  learningObjectives: Array<LearningObjectiveApi>
  questions: Array<ActiveCombinedQuestion>
}) => {

  const globallySortedLearningObjectives = [...learningObjectives].sort((loA, loB) => {
    // first sort by courseLos
    const courseLoA = courseLearningObjectives.find((clo) => clo.id === loA.id);
    const courseLoB = courseLearningObjectives.find((clo) => clo.id === loB.id);
    if (courseLoA && courseLoB) {
      return courseLoA._derived.courseWideSort - courseLoB._derived.courseWideSort;
    } else if (courseLoA) {
      return -1;
    } else if (courseLoB) {
      return 1;
    }

    // then by the rules for non-courseLos
    const topicA = topics.find((topic) => topic.id === loA.topicId);
    const topicB = topics.find((topic) => topic.id === loB.topicId);
    if (!(topicA && topicB)) {
      return 0; // if, for some weird reason, the topic is missing, leave the order unchanged
    }
    const unitA = units.find((unit) => unit.id === topicA.unitId);
    const unitB = units.find((unit) => unit.id === topicB.unitId);
    if (!(unitA && unitB)) {
      return 0; // if, for some weird reason, the unit is missing, leave the order unchanged
    }
    const subjectA = subjects.find((subject) => subject.id === unitA.subjectId);
    const subjectB = subjects.find((subject) => subject.id === unitB.subjectId);
    if (!(subjectA && subjectB)) {
      return 0; // if, for some weird reason, the subject is missing, leave the order unchanged
    }

    if (subjectA === subjectB) { // if the subjects are the same, follow unit rules
      if (unitA === unitB) { // if the units are the same, follow topic rules
        if (topicA === topicB) { // if the topics are the same, follow LO rules
          if (loA.type === LibraryTypeEnum.User && loB.type === LibraryTypeEnum.Template) { // if one is custom, it should come last
            return 1;
          } else if (loA.type === LibraryTypeEnum.Template && loB.type === LibraryTypeEnum.User) { // if one is custom, it should come last
            return -1;
          } else if (loA.type === LibraryTypeEnum.Template && loB.type === LibraryTypeEnum.Template) { // if both are template, sort by order
            const aOrder = loA.order as number;
            const bOrder = loB.order as number;
            return aOrder - bOrder;
          } else if (loA.type === LibraryTypeEnum.User && loB.type === LibraryTypeEnum.User) { // if both are custom, sort by create date
            const loADate = new Date(loA.createdAt);
            const loBDate = new Date(loB.createdAt);
            if (loADate <= loBDate) {
              return -1;
            } else {
              return 1;
            }
          }
        } else if (topicA.type === LibraryTypeEnum.User && topicB.type === LibraryTypeEnum.Template) { // there should not be user and template topics in the same unit but, but if it happens user should come last.
          return 1;
        } else if (topicA.type === LibraryTypeEnum.Template && topicB.type === LibraryTypeEnum.User) { // there should not be user and template topics in the same unit but, but if it happens user should come last.
          return -1;
        } else if (topicA.type === LibraryTypeEnum.User && topicB.type === LibraryTypeEnum.User) { // if both are custom, sort by title
          return loA.title.localeCompare(loB.title);
        } else if (topicA.type === LibraryTypeEnum.Template && topicB.type === LibraryTypeEnum.Template) { // if both are template, sort by order
          const aOrder = topicA.order as number;
          const bOrder = topicB.order as number;
          return aOrder - bOrder;
        }
      } else if (unitA.type === LibraryTypeEnum.User && unitB.type === LibraryTypeEnum.Template) { // if one is custom, it should come last
        return 1;
      } else if (unitA.type === LibraryTypeEnum.Template && unitB.type === LibraryTypeEnum.User) { // if one is custom, it should come last
        return -1;
      } else {
        return unitA.order - unitB.order;  // Use the `order` attribute if the units are different (there could be two custom units in a coinstructor case)
      }
    } else if (course.subjectId === subjectA.id) { // primary course subject should come first
      return -1;
    } else if (course.subjectId === subjectB.id) { // primary course subject should come first
      return 1;
    } else {
      return subjectA.name.localeCompare(subjectB.name); // last subject sort is by name
    }
    return -1; // if all else fails, 'a' should come first
  });

  // for questions with multiple LOs, make sure the LO are in the correct order
  const questionsWithSortedQlos = questions.map((question) => {
    const sortedQlos = [...question.learningObjectiveIds];
    sortedQlos.sort((a, b) => {
      const loAGlobalIndex = globallySortedLearningObjectives.findIndex((lo) => lo.id === a);
      const loBGlobalIndex = globallySortedLearningObjectives.findIndex((lo) => lo.id === b);
      return loAGlobalIndex - loBGlobalIndex;
    });
    return { ...question, learningObjectiveIds: sortedQlos };
  });

  // sort the questions, by the first LO, then by blooms, then by title
  questionsWithSortedQlos.sort((a, b) => {
    const [aLo] = a.learningObjectiveIds;
    const [bLo] = b.learningObjectiveIds;
    const loAGlobalIndex = globallySortedLearningObjectives.findIndex((lo) => lo.id === aLo);
    const loBGlobalIndex = globallySortedLearningObjectives.findIndex((lo) => lo.id === bLo);

    if (loAGlobalIndex === loBGlobalIndex) {
      if (a.blooms === b.blooms) {
        return a.title.localeCompare(b.title);
      } else {
        return a.blooms - b.blooms;
      }
    } else {
      return loAGlobalIndex - loBGlobalIndex;
    }
  });

  return questionsWithSortedQlos;
};

export default createSelector(
  retrieveActiveCombinedQuestions,
  (store: Store) => store.passive.subjects,
  (store: Store) => store.active.course,
  (store: Store) => store.active.units,
  (store: Store) => store.active.topics,
  (store: Store) => store.active.learningObjectives,
  retrieveActiveCourseLearningObjectives,
  (
    activeCombinedQuestions,
    subjects,
    course,
    units,
    topics,
    learningObjectives,
    courseLearningObjectives
  ) => {

    // Sort questions
    // for now questions are sorted consistently with what is in the codon content library, based on the first LO for each question
    if (!units.length || !topics.length) {
      return activeCombinedQuestions;
    }
    const sortedQuestions = sortActiveCombinedQuestions({ course, subjects, courseLearningObjectives, units, topics, learningObjectives, questions: activeCombinedQuestions });
    return sortedQuestions;
  }
);
