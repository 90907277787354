import { DateTime } from 'luxon';

import { AssessmentWithEnrollment } from 'store/selectors/retrieveAssessmentsWithEnrollment';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { ClassSessionApi } from 'types/backend/classSessions.types';

export const calculateWeekNumber = (classSessionDate: string | DateTime, startWeekNumber: number) => {
  const luxonDate = typeof classSessionDate === 'string' ? DateTime.fromISO(classSessionDate) : classSessionDate;
  // calculate week number from start of week to handle weeks over new years
  const weekOfYear = luxonDate.startOf('week').weekNumber;
  // if course rolls over to next year, keep week number consistent
  if (weekOfYear < startWeekNumber) {
    const weeksLastYear = 52 - startWeekNumber;
    return (weeksLastYear + weekOfYear) + 1;
  }
  return (weekOfYear - startWeekNumber) + 1; // start with week 1
};

// Deliberately vague class session interface to allow these functions to take any permutation of class session as input
type AnyClassSession = Pick<ClassSessionApi, 'id' | 'classDate' | 'classType'>
export const groupAssessmentsByType = (assessments: Array<AssessmentWithEnrollment>) => assessments.reduce((acc, cur) => {
  switch (cur.assessType) {
    case AssessTypeEnum.Homework: {
      acc[AssessTypeEnum.Homework].push(cur);
      break;
    }
    case AssessTypeEnum.Readiness:
    case AssessTypeEnum.Preclass: {
      acc[AssessTypeEnum.Preclass].push(cur);
      break;
    }
    case AssessTypeEnum.Prep:
    case AssessTypeEnum.Summative:
    case AssessTypeEnum.PracticeTest: {
      acc[AssessTypeEnum.Summative].push(cur);
      break;
    }
  }
  return acc;
}, {
  [AssessTypeEnum.Homework]: [] as Array<AssessmentWithEnrollment | string>,
  [AssessTypeEnum.Preclass]: [] as Array<AssessmentWithEnrollment | string>,
  [AssessTypeEnum.Summative]: [] as Array<AssessmentWithEnrollment | string>,
});

export const assessmentIdsByType = (assessments: Array<AssessmentWithEnrollment>) => assessments.reduce((acc, cur) => {
  switch (cur.assessType) {
    case AssessTypeEnum.Homework: {
      if (!acc[AssessTypeEnum.Homework].includes(cur.id)) {
        acc[AssessTypeEnum.Homework].push(cur.id);
      }
      break;
    }
    case AssessTypeEnum.Readiness:
    case AssessTypeEnum.Preclass: {
      if (!acc[AssessTypeEnum.Preclass].includes(cur.id)) {
        acc[AssessTypeEnum.Preclass].push(cur.id);
      }
      break;
    }
    case AssessTypeEnum.Prep:
    case AssessTypeEnum.Summative:
    case AssessTypeEnum.PracticeTest: {
      if (!acc[AssessTypeEnum.Summative].includes(cur.id)) {
        acc[AssessTypeEnum.Summative].push(cur.id);
      }
      break;
    }
  }
  return acc;
}, {
  [AssessTypeEnum.Homework]: [] as Array<string>,
  [AssessTypeEnum.Preclass]: [] as Array<string>,
  [AssessTypeEnum.Summative]: [] as Array<string>,
});

export const getAssessmentsDueBetweenClassSessions = (assessments: Array<AssessmentWithEnrollment>, classSessions: Array<AnyClassSession>, classSessionId: number) => {
  const classSessionIndex = classSessions.findIndex(({ id }) => id === classSessionId);
  const targetClassSession = classSessions[classSessionIndex];
  const nextClassSession = classSessions[classSessionIndex + 1];
  if (!targetClassSession || !nextClassSession) {
    return [];
  }
  const endOfTargetClassDate = DateTime.fromISO(targetClassSession.classDate).endOf('day');
  const startOfNextClassDate = DateTime.fromISO(nextClassSession.classDate).startOf('day');

  // return array of assessments between end of this class date and the beginning of the next class date
  return assessments.filter(({ mergedDueDate }) => {
    const assessmentDue = DateTime.fromISO(mergedDueDate);
    return +endOfTargetClassDate < +assessmentDue && +assessmentDue < +startOfNextClassDate;
  });
};
