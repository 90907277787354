import apiNext from 'api-next';
import { DateTime } from 'luxon';
import { comparePrepPracticeAssessments } from 'utils/assessmentFunctions';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';
import { SummativeAssessmentApi } from 'types/backend/assessments.types';

export default function editStudyPathPreset(editedSummativeAssessment: SummativeAssessmentApi) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active: { assessments } } = getStore();
    // get all assessments from store to start with
    const currentSummativeAssessment = assessments.find(({ id }) => id === editedSummativeAssessment.id) as SummativeAssessmentApi;
    if (!currentSummativeAssessment) {
      return false;
    }
    const { prep: currentFullPrepAssessment, prep: { id: prepAssessmentId }, practiceTest: currentFullPracticeAssessment, practiceTest: { id: practiceAssessmentId } } = currentSummativeAssessment;

    // figure out which assessments need updating
    const { dueDate: oldDueDate } = currentSummativeAssessment;
    const {
      prep: newPrep,
      practiceTest: newPractice,
      dueDate: newDueDateJs,
    } = editedSummativeAssessment;
    if (!currentFullPrepAssessment || !currentFullPracticeAssessment || !newPrep || !newPractice) {
      throw new Error(`updateStudyPath missing required data ${JSON.stringify({ currentFullPrepAssessment, currentFullPracticeAssessment, newPrep, newPractice })}`);
    }

    // determine new openDate for studypath
    const newDueDateLuxon = DateTime.fromISO(newDueDateJs);
    const dueMinusMinuteForOpenDate = newDueDateLuxon.minus({ minutes: 1 });
    const newDueDateISO = newDueDateLuxon.toISO();
    const dueMinusMinuteForOpenDateISO = dueMinusMinuteForOpenDate.toISO();

    // update prep and practice test openDate and dueDate to avoid any temporary datetime rule validation issues
    newPrep.openDate = dueMinusMinuteForOpenDateISO;
    newPractice.openDate = dueMinusMinuteForOpenDateISO;
    newPrep.dueDate = newDueDateISO;
    newPractice.dueDate = newDueDateISO;

    const summativeDetailsChanged = +DateTime.fromISO(oldDueDate) !== +newDueDateLuxon;
    const prepHasChanged = comparePrepPracticeAssessments(currentFullPrepAssessment, newPrep);
    const practiceHasChanged = comparePrepPracticeAssessments(currentFullPracticeAssessment, newPractice);

    // aggregate data for update request
    const { prep, practiceTest, ...currentAssessmentData } = currentSummativeAssessment;
    let newSummativeAssessment: SummativeAssessmentApi = {
      ...currentAssessmentData,
      prep,
      practiceTest,
    };
    if (summativeDetailsChanged) {
      console.debug('summativeDetailsChanged', newDueDateJs);
      newSummativeAssessment = {
        ...newSummativeAssessment,
        openDate: dueMinusMinuteForOpenDateISO,
        dueDate: newDueDateISO,
        prep: {
          ...prep,
          openDate: dueMinusMinuteForOpenDateISO,
          dueDate: newDueDateISO,
        },
        practiceTest: {
          ...practiceTest,
          openDate: dueMinusMinuteForOpenDateISO,
          dueDate: newDueDateISO,
        },
      };
    }
    if (prepHasChanged) {
      const { gradingPolicy, pointPenalty, freeAttempts, isGradeSyncEnabled, latePolicy, lateDate, latePenalty } = newPrep;
      console.debug('prepPolicyChanged', gradingPolicy, pointPenalty, freeAttempts, isGradeSyncEnabled, latePolicy, lateDate, latePenalty);
      const { prep: newPrepAssessment } = newSummativeAssessment;
      newSummativeAssessment = {
        ...newSummativeAssessment,
        prep: {
          ...newPrepAssessment,
          ...newPrep,
        },
      };
    }
    if (practiceHasChanged) {
      const { gradingPolicy, pointPenalty, freeAttempts, isGradeSyncEnabled, latePolicy, lateDate, latePenalty } = newPractice;
      console.debug('practicePolicyChanged', gradingPolicy, pointPenalty, freeAttempts, isGradeSyncEnabled, lateDate, latePenalty, latePolicy);
      const { practiceTest: newPracticeAssessment } = newSummativeAssessment;
      newSummativeAssessment = {
        ...newSummativeAssessment,
        practiceTest: {
          ...newPracticeAssessment,
          ...newPractice,
        },
      };
    }
    const updatedSummative = await apiNext.editAssessment(newSummativeAssessment.id, newSummativeAssessment) as SummativeAssessmentApi;
    console.debug('updatedSummative', updatedSummative);

    const { prep: updatedPrep, practiceTest: updatedPractice } = updatedSummative;
    dispatch(activeSlice.actions.editActiveAssessment({ id: prepAssessmentId, delta: updatedPrep }));
    dispatch(activeSlice.actions.editActiveAssessment({ id: practiceAssessmentId, delta: updatedPractice }));
    dispatch(activeSlice.actions.editActiveAssessment({
      id: currentSummativeAssessment.id,
      delta: {
        ...updatedSummative,
        prep: updatedPrep,
        practiceTest: updatedPractice,
        studyPathId: currentSummativeAssessment.studyPathId,
      },
    }));
    return;
  })();
}
