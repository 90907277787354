// HEAVILY adapted from: https://dev.to/zhiyueyi/how-to-create-a-simple-react-calendar-with-styled-component-42n6
import React, { useState, useEffect } from 'react';
import { DateTime, Interval } from 'luxon';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './Calendar.scss';

interface CalendarProps {
  classDays: Array<number>
  startDateString: string
  endDateString: string
  onChange: (dateString: DateTime) => void
  selectedClassDate: DateTime
}

function Calendar({
  classDays,
  startDateString,
  endDateString,
  onChange,
  selectedClassDate,
}: CalendarProps) {
  const DAYS_OF_THE_WEEK = [
    ['Sun', 'S'],
    ['Mon', 'M'],
    ['Tue', 'Tu'],
    ['Wed', 'W'],
    ['Thu', 'Th'],
    ['Fri', 'F'],
    ['Sat', 'S'],
  ];

  const now = DateTime.local();
  const startDate = DateTime.fromISO(startDateString).startOf('day');
  const endDate = DateTime.fromISO(endDateString).endOf('day');
  const courseInterval = Interval.fromDateTimes(startDate, endDate);
  const [viewDate, setViewDate] = useState(selectedClassDate);
  const [month, setMonth] = useState(viewDate.month);
  const [year, setYear] = useState(viewDate.year);
  const [startDay, setStartDay] = useState(viewDate.startOf('month').weekday);

  useEffect(() => {
    setMonth(viewDate.month);
    setYear(viewDate.year);
    setStartDay(viewDate.startOf('month').weekday);
  }, [viewDate]);

  useEffect(() => {
    setViewDate(selectedClassDate);
    setMonth(selectedClassDate.month);
    setYear(selectedClassDate.year);
    setStartDay(selectedClassDate.startOf('month').weekday);
  }, [selectedClassDate]);

  const handleDaySelect = (dayNumber: number) => {
    setViewDate(DateTime.fromObject({ year, month, day: dayNumber }));
    onChange(DateTime.fromObject({ year, month, day: dayNumber }));
  };

  const handleMonthNav = (months: number) => {
    const nextDate = viewDate.plus({ months });
    setViewDate(nextDate);
  };

  const prevMonthDisabled = startDate.month === month;
  const nextMonthDisabled = endDate.month === month;

  // TODO: If course startDate/endDate is changed after creation, class days before first and after last class date should not be clickable
  const daysInMonth = viewDate.endOf('month').day;
  const calendarDays = Array.from({ length: daysInMonth + startDay }, (x, i) => i - (startDay - 1));
  return (
    <div className="calendar">
      <div className="calendar__header">
        <div className="calendar__header-monthyear">
          {viewDate.toFormat('MMM')} {year}
        </div>
        <div className="calendar__header-nav-btns">
          <button disabled={prevMonthDisabled} onClick={() => handleMonthNav(-1)}>
            <FaChevronLeft />
          </button>
          <button disabled={nextMonthDisabled} onClick={() => handleMonthNav(1)}>
            <FaChevronRight />
          </button>
        </div>
      </div>
      <div className="calendar__body">
        <div className="calendar__weekday-header">
          {DAYS_OF_THE_WEEK.map(([weekday, abbrev]) => (
            <button className="calendar-day__wrap" key={weekday}>
              <strong>{abbrev}</strong>
            </button>
          ))}
        </div>
        {calendarDays.map((day) => {
          const dayDate = DateTime.fromObject({ year, month, day });
          let weekdayNumber = !isNaN(dayDate.weekday) ? dayDate.weekday : -1;
          // deal with Sunday = 7 in luxon but 0 in Codon, there might be a cleaner way to do this but it works
          if (weekdayNumber === 7) {
            weekdayNumber = 0;
          }
          const courseContainsDate = courseInterval.contains(dayDate);
          const isClassDay = courseContainsDate && classDays.includes(weekdayNumber);
          const dayDisabled = !courseContainsDate || !isClassDay;
          const isSelected = dayDate.hasSame(selectedClassDate, 'day');
          const isToday = now.hasSame(dayDate, 'day');
          return (
            <button
              className="calendar-day__wrap"
              data-weekday={weekdayNumber}
              key={`day_${year}-${month}-${day}`}
              disabled={dayDisabled}
              onClick={() => handleDaySelect(day)}
            >
              <div
                className="calendar-day"
                data-today={isToday}
                data-selected={isSelected}
                data-isclassday={isClassDay}
              >
                {day > 0 ? day : ''}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
}


export default Calendar;
