// sourced from https://github.com/codonlearning/backend/blob/dev/src/hooks/pointCalculationFunctions.hook.ts;

import { AssessmentApiBase } from 'types/backend/assessments.types';
import { StudentAssessmentQuestionAttemptApi } from 'types/backend/studentAssessmentQuestionAttempts.types';
import { FirstAttemptedEnum } from 'types/backend/studentAssessmentQuestions.types';

export const calculatePointsEarnedCompletion = (points: number, assessmentWindow: FirstAttemptedEnum, assessment: Pick<AssessmentApiBase, 'latePenalty'>): { adjustedPointsEarned: number; latePointsDeducted: number | null } => {
  switch (assessmentWindow) {
    case FirstAttemptedEnum.BeforeDue:
      return {
        adjustedPointsEarned: points,
        latePointsDeducted: 0,
      };
    case FirstAttemptedEnum.BeforeLate:
      const { latePenalty } = assessment;
      const adjustedPointsEarnedNotLate = points;
      const adjustedPointsEarnedLate = adjustedPointsEarnedNotLate * (100 - (latePenalty as number)) / 100;
      const latePointsDeducted = adjustedPointsEarnedNotLate - adjustedPointsEarnedLate;
      return {
        adjustedPointsEarned: adjustedPointsEarnedLate,
        latePointsDeducted,
      };
    case FirstAttemptedEnum.AfterLate:
    default:
      return {
        adjustedPointsEarned: 0,
        latePointsDeducted: null,
      };
  }
};

export const calculatePointsEarnedCorrectness = (points: number, assessmentWindow: FirstAttemptedEnum, assessment: Pick<AssessmentApiBase, 'freeAttempts' | 'pointPenalty' | 'latePenalty'>, studentAssessmentQuestionAttempt: Pick<StudentAssessmentQuestionAttemptApi, 'rawPointsEarned' | 'attemptNum' | 'rawMaxScore'>): { adjustedPointsEarned: number; latePointsDeducted: number | null } => {
  const { freeAttempts, pointPenalty, latePenalty } = assessment;
  const { rawPointsEarned, rawMaxScore, attemptNum } = studentAssessmentQuestionAttempt;
  if (assessmentWindow === FirstAttemptedEnum.AfterLate) {
    return {
      adjustedPointsEarned: 0,
      latePointsDeducted: null,
    };
  } else {
    const latePen = assessmentWindow === FirstAttemptedEnum.BeforeDue ? 0 : latePenalty as number;
    const pointPen = freeAttempts === null ? 0 : pointPenalty as number;

    // figure out adjusted attempt num based on free attempts and current attempt
    let adjustedAttemptNum = 0;
    if (freeAttempts !== null) {
      adjustedAttemptNum = attemptNum - freeAttempts;
      if (adjustedAttemptNum < 0) {
        adjustedAttemptNum = 0;
      }
    }
    let adjustedPointsEarnedNotLate = points * (rawPointsEarned / rawMaxScore - adjustedAttemptNum * pointPen);
    if (adjustedPointsEarnedNotLate < 0) {
      adjustedPointsEarnedNotLate = 0;
    }
    let adjustedPointsEarnedLate = adjustedPointsEarnedNotLate * (100 - latePen) / 100;
    if (adjustedPointsEarnedLate < 0) {
      adjustedPointsEarnedLate = 0;
    }
    const latePointsDeducted = adjustedPointsEarnedNotLate - adjustedPointsEarnedLate;
    return {
      adjustedPointsEarned: adjustedPointsEarnedLate,
      latePointsDeducted,
    };
  }
};
