import React from 'react';

import useDocumentTitle from 'hooks/useDocumentTitle';
import AssignmentListContainer from './AssignmentListContainer/AssignmentListContainer';

export default function AssignmentListController() {
  useDocumentTitle('Assignment List');
  return (
    <AssignmentListContainer />
  );
}
