// Sourced from https://github.com/codonlearning/backend/blob/dev/src/services/authorInit/authorInit.types.ts
import { GenericObject } from 'types/backend/shared.types';
import { GradingTypeTag } from 'types/backend/l8y.types';

export enum AuthorAppMode {
  ItemEdit = 'item_edit'
}

export interface AuthorInitApi {
  userId: string
  email: string
  mode: AuthorAppMode
  gradingType: GradingTypeTag
  reference: string | false | null // string for editing existing item, false for creating new item, null for item list
  subjectId: number
  subject?: string
  config?: GenericObject
}

export interface L8yUser {
  id: string
  email?: string
  firstname?: string
  lastname?: string
}

export interface L8yAuthorApiRequest {
  config: GenericObject
  mode: AuthorAppMode
  organisation_id: number
  reference?: string | false
  user: L8yUser
}
