import React from 'react';
import { Link } from 'react-router-dom';
import { EnrichedStudentAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { YesNo } from 'types/backend/shared.types';
import { StudentCoursePath } from 'types/student.types';

interface StudentAssessmentLinkProps {
  enrichedStudentAssessment: EnrichedStudentAssessment
  className?: string
  text?: string
  tabIndex?: number
}

const StudentAssessmentLink = ({ enrichedStudentAssessment, text, ...rest }: StudentAssessmentLinkProps) => {
  const {
    assessType,
    id: assessmentId,
    name: assessmentName,
    openToStudents,
    published,
  } = enrichedStudentAssessment;
  const vatLink = `${StudentCoursePath.AssessmentTaker}/${assessmentId}`;
  const spLink = `${StudentCoursePath.StudyPath}?assessmentId=${assessmentId}`;
  // if it is the prep assessment link to SP otherwise link to AT
  const linkTo = [AssessTypeEnum.Prep, AssessTypeEnum.Summative].includes(assessType) ? spLink : vatLink;
  // if text prop is provided use that instead of proper assessment name
  let linkText;
  if (text) {
    linkText = text;
  } else if (assessType === AssessTypeEnum.PracticeTest && published === YesNo.No) {
    linkText = `${assessmentName} (not yet published)`;
  } else {
    linkText = assessmentName;
  }

  return (
    <Link
      to={linkTo}
      data-assesstype={assessType}
      data-disabled={!openToStudents}
      {...rest}
    >
      {linkText}
    </Link>
  );
};

export default StudentAssessmentLink;
