import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from 'style-config.scss';
import './ProgressCircle.scss';

interface ProgressCircleProps {
  size: number
  progress: number
  strokeWidth: number
  bgColor?: string
  color?: string
  text?: string
  minProgress?: number
}

const ProgressCircle = ({
  size,
  progress,
  strokeWidth,
  bgColor,
  color,
  text,
  minProgress = 0,
}: ProgressCircleProps) => {
  const [offset, setOffset] = useState(0);
  const circleRef = useRef<SVGCircleElement | null>(null);

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    if (minProgress > 0 && progressOffset < minProgress) {
      setOffset(((100 - minProgress) / 100) * circumference);
    } else {
      setOffset(progressOffset);
    }
    if (!!circleRef.current) {
      circleRef.current.setAttribute('style', 'transition: stroke-dashoffset 850ms ease-in-out');
    }

  }, [setOffset, minProgress, progress, circumference, offset]);


  return (
    <>
      <svg
        className="svg"
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
      >
        <circle
          className="svg-circle-bg"
          stroke={bgColor}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="svg-circle"
          ref={circleRef}
          stroke={color}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
        <text
          x={`${center}`}
          y={`${center + 4}`}
          className="svg-circle-text"
        >
          {text}
        </text>
      </svg>
    </>
  );
};

ProgressCircle.propTypes = {
  size: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  minProgress: PropTypes.number,
};

ProgressCircle.defaultProps = {
  bgColor: styles.lightGray,
  minProgress: 0,
};

export default ProgressCircle;
