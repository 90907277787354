import apiNext from 'api-next';
import { AppDispatch, Store } from 'types/store.types';
import { ClassSessionIclrApi } from 'types/backend/classSessionIclr.types';
import activeSlice from 'store/slices/active';

export default function addIclrToClassSessions(
  classSessionIds: Array<number>,
  iclrId: number
) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const classSessionIclrData: Array<ClassSessionIclrApi> = await Promise.all(classSessionIds.map(async (id) =>
      await apiNext.createClassSessionIclr(id, iclrId)
    ));

    // Append new classSessionIclrs to store.active.classSessionIclrs
    dispatch(activeSlice.actions.addActiveClassSessionIclrs(classSessionIclrData));
  })();
}
