import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FaTimes } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import useClassSessionQuery from 'hooks/useClassSessionQuery';
import { Store } from 'types/store.types';
import { InstructorCoursePath } from 'types/instructor.types';
import './ExitBlock.scss';

function ExitBlock({ title }: { title: string }) {
  const history = useHistory();
  const course = useSelector((store: Store) => store.active.course);
  const [returnTo] = useQueryParam('returnTo', StringParam);
  const [classSessionIdQuery] = useClassSessionQuery([], false);
  const classSessionSuffix = !!classSessionIdQuery ? `?class-session=${classSessionIdQuery}` : '';
  const exitToUrl = returnTo || `/instructor/course/${course.id}/${InstructorCoursePath.DailyPlanner}${classSessionSuffix}`;
  return (
    <div className="exit-block">
      <h2>{title}</h2>
      <button
        className="exit-block__button"
        onClick={() => history.push(exitToUrl)}
      >
        Close <FaTimes />
      </button>
    </div>
  );
}

ExitBlock.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ExitBlock;
