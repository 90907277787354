import React, { useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import apiNext from 'api-next';
import useSubmitStatus, { SubmitStatus } from 'hooks/useSubmitStatus';
import sharedStrings from 'sharedStrings';
import CodonUrls from 'urls';
import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import LoginBox from './LoginBox';

export default function PasswordResetRequest() {
  const [initEmail] = useQueryParam('email', StringParam);
  const [submitStatus, setSubmitStatus, submitDisabled] = useSubmitStatus();
  const [email, setEmail] = useState(initEmail || '');

  async function doResetRequest(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setSubmitStatus(SubmitStatus.Unsubmitted);

    if (email) {
      try {
        const trimmedEmailInput = email.toLowerCase().trim();
        await apiNext.requestPasswordReset(trimmedEmailInput);
        setSubmitStatus(SubmitStatus.Success);
      } catch (err: any) {
        console.warn(`password reset error message: ${err.message}`);
        setSubmitStatus(SubmitStatus.Failure);
      }
    }
    return false;
  }

  return (
    <LoginBox title="Reset Password">
      <form onSubmit={doResetRequest}>
        <fieldset disabled={submitDisabled} className="login__password-reset-request-form">
          <input
            type="text"
            value={email}
            placeholder="Email"
            aria-label="Enter the email address associated with your Codon account"
            onChange={e => setEmail(e.target.value)}
            required
            readOnly={!!initEmail}
          />
          <input type="submit" value="Request password reset" />
        </fieldset>
        <div role="alert">
          {submitStatus === SubmitStatus.Success && (
            <div className="login__info">
              If there is an account associated with this email address, we have sent instructions to reset your password. If you don’t receive an email from us within 5 minutes, check out our
              &nbsp;{externalLink(CodonUrls.LoginSupportArticle, 'Login Support Article')} or contact us at {sharedStrings.CODON_SUPPORT_EMAIL}.
            </div>
          )}
          {submitStatus === SubmitStatus.Failure && (
            <div className="login__info">
              There was an error sending the reset email, please try again.
            </div>
          )}
        </div>
      </form>
      <div className="login__footer-link"><a href="/login">Back to Login</a></div>
    </LoginBox>
  );
}
