import React from 'react';
import PropTypes from 'prop-types';

import { calculateNavItemStatus } from '../AssessmentTaker/AssessmentTakerNavItem';
import Icon, { IconEnum } from 'shared-components/Icon';
import { QuestionStatus } from 'shared-components/LearnosityContainer/LearnosityContainer.types';
import { ClarityEnum } from 'types/backend/shared.types';
import { AssessmentControllerQuestion } from 'utils/getAssessmentControllerQuestions';

function ReadinessNavItem({
  assessmentQuestion,
  handleClick,
  isActive,
  isSPAT,
  questionStatus,
}: {
  assessmentQuestion: AssessmentControllerQuestion
  handleClick: () => void
  isActive: boolean
  isSPAT: boolean
  questionStatus: QuestionStatus
}) {
  const { clarity, isCorrect } = questionStatus;
  const { l8yId, loData, title, shortTitle } = assessmentQuestion;
  const itemTitle = shortTitle || title;
  const { ariaLabel, correctnessIcon } = calculateNavItemStatus({ itemTitle, questionStatus });
  return (
    <li className="rex-nav-item" data-l8yid={l8yId}>
      <button
        aria-label={ariaLabel}
        className="rex-nav-item__button"
        data-active={isActive}
        onClick={handleClick}
      >
        <div className="rex-nav-item__status">
          {!isSPAT && (
            <div className="rex-nav-item__status-icon" data-show={correctnessIcon}>
              <Icon viewBox="0 0 26 26" which={correctnessIcon} />
            </div>
          )}
          <div className="rex-nav-item__status-icon" data-show={!!clarity && !!isCorrect}>
            <Icon which={clarity === ClarityEnum.Muddy ? IconEnum.Muddy : IconEnum.None} />
          </div>
        </div>
        <div className="rex-nav-item__content">
          <div className="rex-nav-item__title">
            {itemTitle}
          </div>
          <div className="rex-nav-item__lo-list">
            {loData.map(({ loNumber, stringId }) => (
              <span
                className="rex-nav-item__lo-item"
                key={`${l8yId}_${stringId}`}
              >
                <abbr>{loNumber}</abbr>
              </span>
            ))}
          </div>
        </div>
      </button>
    </li>
  );
}

ReadinessNavItem.propTypes = {
  assessmentQuestion: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  questionStatus: PropTypes.object.isRequired,
};

export default ReadinessNavItem;

