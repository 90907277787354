import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch } from 'types/store.types';
import { OoclrApi } from 'types/backend/ooclr.types';

export default function editOoclr(ooclrData: OoclrApi) {
  return (dispatch: AppDispatch) => (async () => {

    const updatedOoclr = await apiNext.editOoclr(ooclrData);

    dispatch(activeSlice.actions.editActiveOoclr({ id: updatedOoclr.id, delta: updatedOoclr }));
  })();
}
