import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { ClassSessionOoclrApi } from 'types/backend/classSessionOoclr.types';
import { AppDispatch, Store } from 'types/store.types';

export default function addOoclrToClassSessions(
  classSessionIds: Array<number>,
  ooclrId: number
) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const classSessionOoclrData: Array<ClassSessionOoclrApi> = await Promise.all(classSessionIds.map(async (id) =>
      await apiNext.createClassSessionOoclr(id, ooclrId)
    ));

    // Append new classSessionOoclrs to store.active.classSessionOoclrs
    dispatch(activeSlice.actions.addActiveClassSessionOoclrs(classSessionOoclrData));
  })();
}
