/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
// JUSTIFICATION: this component will be DEPRECATED with new CoursePlanner
// TopicGrid > TopicSelect > TopicPopup > Editable
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './TopicPopup.scss';

//================================================================================================================================================================
// Taken from off the WEB
// Component accept text, placeholder values and also pass what type of Input - input, textarea so that we can use it for styling accordingly
const Editable = ({
  text,
  type,
  placeholder,
  children,
  handleAddCustom,
  ...props
}) => {
  // Manage the state whether to show the label or the input box. By default, label will be shown.
  // Exercise: It can be made dynamic by accepting initial state as props outside the component
  const [isEditing, setEditing] = useState(false);

  // Event handler while pressing any key while editing
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddCustom();
      setEditing(false);
    }
    if (event.key === 'Escape') {
      setEditing(false);
      handleAddCustom(true);
    }
  };
  const handleBlur = (event) => {
    setEditing(false);
    handleAddCustom();
    event.preventDefault();
  };
  /*
- It will display a label if `isEditing` is false
- It will display the children (input or textarea) if `isEditing` is true
- when input `onBlur`, we will set the default non edit mode
Note: For simplicity purpose, I removed all the classnames, you can check the repo for CSS styles
*/
  return (
    <section {...props}>
      {isEditing ? (
        <div
          onBlur={e => handleBlur(e)}
          onKeyDown={e => handleKeyDown(e, type)}
        >
          {children} (enter to save)
        </div>
      ) : (
        <div onClick={() => setEditing(true)}>
          <span>
            {text || placeholder || 'Editable content'}
          </span>
        </div>
      )}
    </section>
  );
};

Editable.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  handleAddCustom: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Editable;
