import { DateTime } from 'luxon';
import axios from 'axios';
import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import enrollmentsSlice from 'store/slices/enrollments';
import logout from 'utils/logout';
import { AppDispatch, Store } from 'types/store.types';

export default function updateUserEnrollmentOnFirstAccess(enrollmentId: number) {
  return (dispatch: AppDispatch, getState: () => Store) => (async () => {
    try {
      const firstAccessedAt = DateTime.local().toString();
      const updatedEnrollment = await apiNext.editEnrollment(enrollmentId, { id: enrollmentId, firstAccessedAt });
      dispatch(activeSlice.actions.updateActiveEnrollment(updatedEnrollment));
      dispatch(enrollmentsSlice.actions.editEnrollment(updatedEnrollment));
    } catch (error) {
      if (axios.isAxiosError(error) &&
          (error.response?.status === 401 || error.message.includes('401'))) {
        await logout(dispatch);
      }
    }
  })();
}
