import { getAssessmentQuestionIdsTaggedWithOnlySpecificCourseLos } from './assessmentQuestionFunctions';
import { ActiveCombinedQuestion } from 'store/selectors/retrieveActiveCombinedQuestions';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';

/**
 *
 * Disambiguations:
 *
 * loId - learningObjective.id, needed for looking up other class sessions, should not be passed around
 * csloId - classSessionLearningObjective.id, this is the value we want to pass around when doing class session LO verbs
 *
 **/

export const isLoInOtherClassSessions = (
  loId: number,
  classSessionId: number,
  classSessionLearningObjectives: Array<ClassSessionLearningObjectiveApi>
): boolean => {
  const inOthers = classSessionLearningObjectives.find(cslo => cslo.classSessionId !== classSessionId && cslo.learningObjectiveId === loId);
  return !!inOthers;
};

interface RemovableLosAndQuestions {
  assessmentQuestionIdsOnlyInLos: Array<number>
  csloIdsOnlyInThisClassSession: Array<number>
  csloIds: Array<number>
}

interface RemovableLosAndQuestionsInput {
  classSessionId: number
  classSessionLearningObjectives: Array<ClassSessionLearningObjectiveApi>
  assessmentQuestions: Array<AssessmentQuestionApi>
  questions: Array<ActiveCombinedQuestion>
}

export const getRemoveableLosAndQuestions = ({
  csloIds,
  classSessionId,
  classSessionLearningObjectives,
  assessmentQuestions,
  questions,
}: RemovableLosAndQuestionsInput & { csloIds: Array<number> }): RemovableLosAndQuestions => {
  let assessmentQuestionIdsOnlyInLos: Array<number> = [];
  // let csloIdsOnlyInThisClassSession: Array<number> = [];

  // get the full cslos from csloIds for class session relationship calculations
  const fullCslos = classSessionLearningObjectives.filter((cslo) => csloIds.includes(cslo.id));
  const cslosOnlyInThisClassSession = fullCslos.filter(cslo => !isLoInOtherClassSessions(cslo.learningObjectiveId, classSessionId, classSessionLearningObjectives));

  /* <DEALING WITH LO IDS, NOT CSLO IDS> */
  // convert from csloIds to loIds to cross-reference and see if the LOs are only in this class session
  const loIdsOnlyInThisClassSession = cslosOnlyInThisClassSession.map(({ learningObjectiveId }) => learningObjectiveId);

  // if loIds only exist in the current class session, their related assessment questions are eligible for deletion
  if (!!loIdsOnlyInThisClassSession.length) {
    assessmentQuestionIdsOnlyInLos = getAssessmentQuestionIdsTaggedWithOnlySpecificCourseLos(loIdsOnlyInThisClassSession, assessmentQuestions, questions);
  }
  /* </DEALING WITH LO IDS, NOT CSLO IDS> */

  const csloIdsOnlyInThisClassSession = cslosOnlyInThisClassSession.map(({ id }) => id);

  return {
    assessmentQuestionIdsOnlyInLos,
    csloIdsOnlyInThisClassSession,
    csloIds,
  };
};

export const getRemoveableLosAndQuestionsFromTopicId = ({
  topicId,
  classSessionId,
  classSessionLearningObjectives,
  assessmentQuestions,
  questions,
}: RemovableLosAndQuestionsInput & { topicId: number }): RemovableLosAndQuestions => {
  // determine loIds and assessmentQuestionIds to remove based on topicIdToRemove
  // get all cslos associated with class session and topic
  const classSessionTopicLos = classSessionLearningObjectives.filter(cslo =>
    cslo.classSessionId === classSessionId
    && cslo.topicId === topicId);

  return getRemoveableLosAndQuestions({
    csloIds: classSessionTopicLos.map(cslo => cslo.id),
    classSessionId,
    classSessionLearningObjectives,
    assessmentQuestions,
    questions,
  });
};

export function sortLearningObjectivesByOrder<T extends LearningObjectiveApi>(loArray: Array<T>) {
  return loArray.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
}
