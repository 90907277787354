// adapted from: https://github.com/AaronCCWong/react-card-flip
import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  CSSProperties,
} from 'react';

export interface CardFlipProps {
  /**
   * z-Index for the flip card. Used to help solve context stack issues while using multiple flip cards.
   * @default 'auto'
   */
  cardZIndex?: number
  /**Extra css styling that can be applied to the container.
   * @default {}
   */
  containerStyle?: {}
  /**
   * Custom container class name.
   * @default undefined
   */
  containerClassName?: string
  /**
   * Unique identifier for this card
   */
  cardId: string
  /**
   * False to show the front of the card, true to show the back
   * @default undefined
   */
  isFlipped?: boolean
  /**
   * The speed of the flip animation when the card flips, the higher the number the slower the flip animation
   * @default 0.6
   */
  flipSpeed?: number

  cardStyles?: { front?: {}; back?: {} }

  /**Direction of the card flip (options are: 'horizontal' or 'vertical' )
   * @default 'horizontal'
   */
  flipDirection?: 'horizontal' | 'vertical'
  children: [React.ReactNode, React.ReactNode]
}

const CardFlip = (props: CardFlipProps) => {
  const {
    cardStyles,
    cardZIndex,
    containerStyle,
    containerClassName,
    flipDirection,
    flipSpeed = 0.6,
    cardId,
  } = props;

  const { back, front } = cardStyles || {};
  const refTimer = useRef<NodeJS.Timeout | null>(null);
  const refFront = useRef<HTMLDivElement | null>(null);
  const refBack = useRef<HTMLDivElement | null>(null);
  const [isFlipped, setFlipped] = useState(props.isFlipped);
  const [frontIsVisible, setFrontIsVisible] = useState(!props.isFlipped);
  const [backIsVisible, setBackIsVisible] = useState(props.isFlipped);

  const hideDelayMs = flipSpeed * 1000;
  const idString = `card-flip__${cardId}`;

  useEffect(() => {
    if (props.isFlipped !== isFlipped) {
      if (props.isFlipped) {
        setBackIsVisible(true);
        setFlipped(props.isFlipped);
        refTimer.current = setTimeout(() => {
          // !!refBack.current && refBack.current.focus();
          const firstH4 = document.querySelectorAll(`#${idString} .react-card-back h4`)[0] as HTMLHeadingElement;
          !!firstH4 && firstH4.focus();
          setFrontIsVisible(!frontIsVisible);
        }, hideDelayMs);
        // show back, flip, then hide front
      } else {
        setFrontIsVisible(true);
        setFlipped(props.isFlipped);
        refTimer.current = setTimeout(() => {
          // !!refFront.current && refFront.current.focus();
          const firstH4 = document.querySelectorAll(`#${idString} .react-card-front h4`)[0] as HTMLHeadingElement;
          !!firstH4 && firstH4.focus();
          setBackIsVisible(!backIsVisible);
        }, hideDelayMs);
      }
    }
  }, [backIsVisible, frontIsVisible, hideDelayMs, idString, props.isFlipped, isFlipped]);

  useEffect(() => {
    return () => {
      if (refTimer.current !== null) {
        window.clearTimeout(refTimer.current);
      }
    };
  }, []);

  const getContainerClassName = useMemo(() => {
    let className = 'react-card-flip';
    if (containerClassName) {
      className += ` ${containerClassName}`;
    }
    return className;
  }, [containerClassName]);

  const getComponent = (key: 0 | 1) => {
    if (props.children.length !== 2) {
      throw new Error('Component CardFlip requires 2 children to function');
    }
    return props.children[key];
  };

  const frontRotateY = `rotateY(${isFlipped ? 180 : 0}deg)`;
  const backRotateY = `rotateY(${isFlipped ? 0 : -180}deg)`;
  const frontRotateX = `rotateX(${isFlipped ? 180 : 0}deg)`;
  const backRotateX = `rotateX(${isFlipped ? 0 : -180}deg)`;

  const styles: { [key: string]: CSSProperties } = {
    back: {
      WebkitBackfaceVisibility: 'hidden',
      backfaceVisibility: 'hidden',
      visibility: backIsVisible ? 'visible' : 'hidden',
      height: '100%',
      left: '0',
      position: isFlipped ? 'relative' : 'absolute',
      top: '0',
      transform: flipDirection === 'horizontal' ? backRotateY : backRotateX,
      transformStyle: 'preserve-3d',
      transition: `${flipSpeed}s`,
      width: '100%',
      ...back,
    },
    container: {
      perspective: '1000px',
      zIndex: cardZIndex,
    },
    flipper: {
      height: '100%',
      position: 'relative',
      width: '100%',
    },
    front: {
      WebkitBackfaceVisibility: 'hidden',
      backfaceVisibility: 'hidden',
      visibility: frontIsVisible ? 'visible' : 'hidden',
      height: '100%',
      left: '0',
      position: isFlipped ? 'absolute' : 'relative',
      top: '0',
      transform: flipDirection === 'horizontal' ? frontRotateY : frontRotateX,
      transformStyle: 'preserve-3d',
      transition: `${flipSpeed}s`,
      width: '100%',
      zIndex: 2,
      ...front,
    },
  };

  return (
    <div
      className={getContainerClassName}
      data-isflipped={isFlipped}
      id={idString}
      style={{ ...styles.container, ...containerStyle }}
    >
      <div className="react-card-flipper" style={styles.flipper}>
        <div
          aria-hidden={isFlipped}
          className="react-card-front"
          ref={refFront}
          style={styles.front}
        >
          {getComponent(0)}
        </div>
        <div
          aria-hidden={!isFlipped}
          className="react-card-back"
          ref={refBack}
          style={styles.back}
        >
          {getComponent(1)}
        </div>
      </div>
    </div>
  );
};

CardFlip.defaultProps = {
  cardStyles: {
    back: {},
    front: {},
  },
  cardZIndex: 'auto',
  containerStyle: {},
  flipDirection: 'horizontal',
  flipSpeedBackToFront: 0.6,
  flipSpeed: 0.6,
  infinite: false,
  isFlipped: false,
};

export default CardFlip;
