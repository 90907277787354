/**
* retrieveEnrichedAssessmentsForStudentScores
*
* this selector gathers a bunch of info and returns a handy array of what are essentially refered to by the product team as 'assignments'
* placeholders are added for student assessments that have not been started yet, so that a full list of all the assessments in the course is included in the results
* which has calculated information such as if the student has taken the assessment or how many points it is worth
* useful on the assignments list and student dashboard, and maybe other places as well
*/
import { createSelector } from '@reduxjs/toolkit';
import cloneDeep from 'lodash-es/cloneDeep';
import { DateTime } from 'luxon';
import getClassDaysCoveredFromClassSessionIds from 'utils/getClassDaysCoveredFromClassSessionIds';
import retrieveActiveClassSessions from './retrieveActiveClassSessions';
import { AssessmentApiBase } from 'types/backend/assessments.types';
import { Store } from 'types/store.types';
import { EnrichedAssessmentForStudentScores } from 'instructor/controllers/Course/StudentScoresController/studentScores.types';
import { QuestionApiOut } from 'types/backend/questions.types';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';

export default createSelector(
  (store: Store) => store.active.assessments,
  (store: Store) => store.active.assessmentQuestionMaps,
  (store: Store) => store.active.templateQuestions,
  (store: Store) => store.active.userQuestions,
  retrieveActiveClassSessions,
  (
    assessments: Array<AssessmentApiBase>,
    assessmentQuestions: Array<AssessmentQuestionApi>,
    templateQuestions: Array<QuestionApiOut>,
    userQuestions: Array<QuestionApiOut>,
    classSessions
  ) => {
    if (!assessments || !assessmentQuestions) {
      return [];
    }
    const bankQuestions = [...new Set([...templateQuestions, ...userQuestions])];
    assessments = cloneDeep(assessments);
    assessmentQuestions = cloneDeep(assessmentQuestions);
    const now = DateTime.local();
    const enrichedAssessmentsForStudentScores: Array<EnrichedAssessmentForStudentScores> = assessments.map(assessment => {
      const { classSessionIds = [] } = assessment;
      const classDaysCovered = getClassDaysCoveredFromClassSessionIds(classSessionIds, classSessions);
      const questions = assessmentQuestions.filter(aq => aq.assessmentId === assessment.id);
      questions.sort((a, b) => (a.order as number) - (b.order as number));

      const totalPoints = questions.reduce((total, question) => {
        total += question.points;
        return total;
      }, 0);

      const enrichedQuestions = questions.map((question, index) => {
        const sourceQuestion = bankQuestions.find(bq => bq.id === question.questionId) as QuestionApiOut;
        if (!sourceQuestion) {
          console.error(`aqm questionId ${question.questionId} cannot be found in question bank`, bankQuestions);
        }
        const { title, l8yId } = sourceQuestion;
        return {
          ...question,
          qNum: index + 1,
          title,
          l8yId,
          sourceQuestion,
          muddyAttemptData: null,
          incorrectAttemptData: null,
        };
      });

      const openDate = DateTime.fromISO(assessment.openDate);
      const isOpen = now > openDate;

      return {
        ...assessment,
        questions: enrichedQuestions,
        totalPoints,
        isOpen,
        classDaysCovered: [...classDaysCovered],
      };
    });
    return enrichedAssessmentsForStudentScores;
  }
);

