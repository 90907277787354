import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import { getIsCloseToPracticeTestDue } from 'utils/assessmentFunctions';
import getFirstHomeworkInWindow from 'utils/assessments/getFirstHomeworkInWindow';
import { calculateStudyPathPercentages, getPracticeTestUnlockedPercentage } from 'student/controllers/Course/StudyPathController/sharedStudyPathFunctions';
import { StudyPathSummaryInfo, EnrichedStudentStudyPath } from 'student/controllers/Course/StudyPathController/StudyPathController.types';
import { calculateHeroFontSize } from 'utils/commonFormattingFunctions';
import { DateFormatEnum, daysUntilExam } from 'utils/dateFormattingFunctions';
import { studentDashboardStrings } from 'sharedStrings';

import ProgressBar from 'shared-components/ProgressBar/ProgressBar';
import Icon, { IconEnum } from 'shared-components/Icon';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';

import retrieveEnrichedActiveStudyPath from 'store/selectors/retrieveEnrichedActiveStudyPath';
import retrieveEnrichedStudentAssessments, { EnrichedStudentSummativeAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';

import { SummativeAssessmentWithEnrollment } from 'store/selectors/retrieveAssessmentsWithEnrollment';
import PracticeTestStatus from './PracticeTestStatus';
import { PanelStatesEnum } from './NextSummative.types';
import { YesNo } from 'types/backend/shared.types';
import styles from 'style-config.scss';
import './NextSummative.scss';

export default function NextSummative({ activeSummative }: { activeSummative: SummativeAssessmentWithEnrollment }) {
  const enrichedStudentAssessments = useSelector(retrieveEnrichedStudentAssessments);
  const studyPath: EnrichedStudentStudyPath | null = useSelector(retrieveEnrichedActiveStudyPath);
  const { summaryInfo } = studyPath || {};

  const { practiceTest: { id: practiceAssessmentId } } = activeSummative;
  const enrichedStudentAssessmentPracticeTest = enrichedStudentAssessments.find(esa => esa.id === practiceAssessmentId) as EnrichedStudentSummativeAssessment;

  let unlockedPracticeTestPercentage = 0;
  if (studyPath) {
    const { studentTopicCardCheckpoints } = studyPath;
    unlockedPracticeTestPercentage = getPracticeTestUnlockedPercentage(studentTopicCardCheckpoints, enrichedStudentAssessments);
  }
  const now = DateTime.local();

  // calculate summary info, allow for instances where `studyPath` does not exist yet
  const { prepQuestionsReviewedPercentage, topicsReviewedPercentage } = calculateStudyPathPercentages(summaryInfo || {} as StudyPathSummaryInfo);

  const firstHomeworkInWindow = getFirstHomeworkInWindow(activeSummative.id, enrichedStudentAssessments);

  function calculatePanelState() {
    if (!enrichedStudentAssessmentPracticeTest) {
      console.error(`enrichedStudentAssessmentPracticeTest not found for ${practiceAssessmentId} in enrichedStudentAssessments:`, enrichedStudentAssessments);
      return PanelStatesEnum.ShowInitialSPCard;
    }
    const isCloseToPracticeTestDue = getIsCloseToPracticeTestDue(enrichedStudentAssessmentPracticeTest.dueDate);
    if (enrichedStudentAssessmentPracticeTest.allQuestionsAnswered) {
      return PanelStatesEnum.PracticeTestComplete;
    } else if (enrichedStudentAssessmentPracticeTest.hasBeenStarted) {
      return PanelStatesEnum.PracticeTestInProgress;
    } else if ((unlockedPracticeTestPercentage >= 100 || isCloseToPracticeTestDue) && enrichedStudentAssessmentPracticeTest.published === YesNo.Yes) {
      return PanelStatesEnum.PracticeTestReady;
    } else if (firstHomeworkInWindow) {
      const hwDueDate = DateTime.fromISO(firstHomeworkInWindow.mergedDueDate);
      const hwOpenDate = DateTime.fromISO(firstHomeworkInWindow.mergedOpenDate);
      if (hwDueDate > now && hwOpenDate < now && !firstHomeworkInWindow.hasBeenStarted) {
        return PanelStatesEnum.BeforeFirstHomeworkInWindow;
      } else if (studyPath) {
        return PanelStatesEnum.BeforePracticeTestReady;
      }
    }
    return PanelStatesEnum.ShowInitialSPCard;
  }

  function getFooterText(panelState: PanelStatesEnum) {
    // this could be an if but I'm leaving it as a switch because we might have more cases in future
    switch (panelState) {
      case PanelStatesEnum.ShowInitialSPCard:
        return 'The Study Path is a personalized study guide where you can recapture points and prepare for tests.';
      default:
        return 'Spreading studying out over days or weeks leads to better grades than cramming the night before the exam.';
    }
  }

  const renderProgressBars = () => {
    const progressDisplay = [
      {
        title: 'Checkpoint 1',
        percentage: topicsReviewedPercentage,
        color: styles.topicsReviewed,
        tooltip: studentDashboardStrings.TOPICS_REVIEWED_TOOLTIP,
      },
      {
        title: 'Checkpoint 2',
        percentage: prepQuestionsReviewedPercentage,
        color: styles.prep,
        tooltip: studentDashboardStrings.PREP_QUESTIONS_ANSWERED_TOOLTIP,
      },
      {
        title: 'Checkpoint 3',
        percentage: unlockedPracticeTestPercentage,
        color: styles.practiceTest,
        tooltip: studentDashboardStrings.PRACTICE_TEST_UNLOCKED_TOOLTIP,
      },
    ];
    return progressDisplay.map(({ title, percentage, color, tooltip }, i) => (
      <Fragment key={title}>
        <div className="next-summative__progress-row-text">
          <span>{title}</span>
        </div>
        <BetterTooltip content={tooltip}>
          <div className="next-summative__progress-row-bar">
            <ProgressBar
              percentage={percentage}
              color={color}
              displayPercentage
            />
          </div>
        </BetterTooltip>
      </Fragment>
    ));
  };
  const panelState = calculatePanelState();
  const remainingDays = daysUntilExam(activeSummative.mergedOpenDate);
  const heroFontSize = calculateHeroFontSize(remainingDays.toString().length, [72, 64, 48]);
  return (
    <div className="next-summative">
      <div className="next-summative__heading">
        <Icon which={IconEnum.StudyPath} size={48} /><h2>Study Path</h2>
      </div>
      <div className="next-summative__body row">
        <div className="next-summative__left col-xs-12 col-md-7">
          <div className="next-summative__hero-box">
            <div className="next-summative__hero-number" style={{ fontSize: `${heroFontSize}px` }}>
              {remainingDays}
            </div>
            <div className="next-summative__hero-text-box">
              <div className="next-summative__hero-text-name">Days until {activeSummative.name}</div>
              <div className="next-summative__hero-text-date">on {DateTime.fromISO(activeSummative.mergedOpenDate).toFormat(DateFormatEnum.WeekdayMonthDate)}</div>
            </div>
          </div>
          <div className="next-summative__progress">
            {renderProgressBars()}
          </div>
        </div>
        <div className="next-summative__right col-xs-12 col-md-5">
          <PracticeTestStatus
            firstHomework={firstHomeworkInWindow}
            panelState={panelState}
            practiceTest={enrichedStudentAssessmentPracticeTest}
            summaryInfo={summaryInfo}
          />
        </div>
      </div>
      <div className="next-summative__footer">
        <Icon which={IconEnum.Lightbulb} size={73} />
        <aside className="next-summative__footer-text">
          {getFooterText(panelState)}
        </aside>
      </div>
    </div>
  );
}
