import React from 'react';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import Icon, { IconEnum } from 'shared-components/Icon';
import { StudentTopicCardReadinessAssessment } from 'types/backend/studentStudyPaths.types';
import { EnrichedStudentTopicCardLearningObjective } from '../../StudyPathController.types';
import { ClarityEnum, YesNo } from 'types/backend/shared.types';

function StudyPathTopicCardReadinessItem({
  cardLearningObjectives,
  launchAssessmentTaker,
  readinessAssessment,
}: {
  cardLearningObjectives: Array<EnrichedStudentTopicCardLearningObjective>
  launchAssessmentTaker: (l8yId: string, assessmentId: string) => void
  readinessAssessment: StudentTopicCardReadinessAssessment
}) {
  const { assessmentQuestions, id: readinessAssessmentId, title, learningObjectiveIds } = readinessAssessment;
  const readinessLos = cardLearningObjectives.filter((lo) => learningObjectiveIds.includes(lo.id)).sort((a, b) => a.courseWideSort - b.courseWideSort);
  const questionCorrectness = assessmentQuestions.map(({ studentAssessmentQuestion }) => {
    const { latestStudentAssessmentQuestionAttempt } = studentAssessmentQuestion || {};
    return !!(latestStudentAssessmentQuestionAttempt && latestStudentAssessmentQuestionAttempt?.isCorrect === YesNo.Yes);
  });
  const allItemsCorrect = questionCorrectness.every((b) => b === true);

  return (
    <div className="sptc__readiness-item" data-assessmentid={readinessAssessmentId}>
      <div className="sptc__readiness-item__header">
        <div className="sptc__readiness-item__header__all-correct">
          {allItemsCorrect ? <FaCheckCircle size={24} /> : <FaRegCircle size={24} />}
        </div>
        <div className="sptc__readiness-item__header__content">
          <div className="sptc__readiness-item__lo-list">
            {readinessLos.map(({ loNumber }) => <span key={loNumber}>{loNumber}</span>)}
          </div>
          <div className="sptc__readiness-item__title">{title}</div>
        </div>
      </div>
      <ul className="sptc__readiness-item__items-list">
        {assessmentQuestions.map(({ id, order, question, studentAssessmentQuestion }) => {
          const { latestStudentAssessmentQuestionAttempt } = studentAssessmentQuestion || {};
          const { clarity } = latestStudentAssessmentQuestionAttempt || {};
          const isChecked = latestStudentAssessmentQuestionAttempt?.isCorrect === YesNo.Yes;
          return (
            <li className="items-list__item" data-l8yid={question.l8yId} key={`${order}_${id}`}>
              <button className="items-list__item__button" onClick={() => launchAssessmentTaker(question.l8yId, readinessAssessmentId)}>
                <div className="items-list__item__title">
                  <div className="items-list__item__correct-indicator">{isChecked ? <FaCheckCircle /> : <FaRegCircle />}</div>
                  {question.shortTitle || question.title}
                </div>
                <div className="items-list__item__clarity">
                  {clarity === ClarityEnum.Muddy && <Icon size={18} which={IconEnum.Muddy} />}
                </div>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default StudyPathTopicCardReadinessItem;
