import { DateTime } from 'luxon';
import { assessTypeSortWeight } from './assessmentFunctions';
import { AssessmentApiBase } from 'types/backend/assessments.types';

const sortAssessments = (assessmentsArray: Array<AssessmentApiBase>) => {
  return [...assessmentsArray].sort((a, b) => {
    const dateA = DateTime.fromISO(a.dueDate);
    const dateB = DateTime.fromISO(b.dueDate);

    if (dateA.hasSame(dateB, 'day')) {
      // if dates have are on the same day, sort by weight assess types
      if (assessTypeSortWeight[a.assessType] === assessTypeSortWeight[b.assessType]) {
        // if assessTypes are the same, sort by name
        return a.name.localeCompare(b.name);
      }
      return assessTypeSortWeight[a.assessType] - assessTypeSortWeight[b.assessType];
    }
    // otherwise sort by date
    return dateA < dateB ? -1 : 1;
  });
};

export default sortAssessments;
