import React from 'react';
import { Link } from 'react-router-dom';
import { FaUserFriends } from 'react-icons/fa';
import { DateTime } from 'luxon';

import { formatPlural, formatStringsWithAnd, truncateStringAtSeparator } from 'utils/commonFormattingFunctions';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { CourseWithMetadata } from 'store/selectors/retrieveEnrolledCourses';
import { InstructorCoursePath } from 'types/instructor.types';
import { PositionEnum } from 'types/common.types';
import { RoleEnum } from 'types/backend/roles.types';
import { StudentCoursePath } from 'types/student.types';
import { UserApi } from 'types/backend/users.types';
import './CourseCard.scss';

function CourseCard({
  course,
  isInstructor,
}: {
  course: CourseWithMetadata
  isInstructor: boolean
}) {
  const {
    courseNumber,
    id: courseId,
    name: courseName,
    startDate,
    metadata,
  } = course;
  const { enrolledAs, instructorUser, coinstructorUsers } = metadata;
  const enrolledAsBoth = isInstructor && [RoleEnum.Student, RoleEnum.Instructor].every((role) => enrolledAs.includes(role));
  const startDateString = DateTime.fromISO(startDate).toFormat('MMM yyyy');

  const launchAsStudentLink = `/student/course/${courseId}/${StudentCoursePath.Home}`;
  const launchAsInstructorLink = `/instructor/course/${courseId}/${InstructorCoursePath.DailyPlanner}`;

  // fallback to student launch
  let primaryLink = launchAsStudentLink;
  if (isInstructor && enrolledAs.includes(RoleEnum.Instructor)) {
    primaryLink = launchAsInstructorLink;
  }

  const renderCoInstructorIcon = (coInstructors: Array<UserApi>) => {
    // only show list of co-instructors if all of them have names, otherwise display number of co-instructors
    const allInstructorsHaveNames = coInstructors.every(({ firstName, lastName }) => !!firstName && !!lastName);
    const coinstructorsString = allInstructorsHaveNames
      ? formatStringsWithAnd(coInstructors.map(co => `${co.firstName} ${co.lastName}`))
      : `${coInstructors.length} ${formatPlural('instructor', coInstructors.length)}`;
    return (
      <div className="course-card__coinstructor-icon">
        <BetterTooltip content={`This course is co-taught with ${coinstructorsString}.`} position={PositionEnum.Bottom}>
          <FaUserFriends />
        </BetterTooltip>
      </div>
    );
  };

  const { lastName: primaryInstructorLastName } = instructorUser || {};
  const btnColClassName = enrolledAsBoth ? 'col-xs-6' : 'col-xs-12'; // if only enrolled as one role, button spans full width
  return (
    <div className="col-xs-12 col-sm-6 col-md-4">
      <div className="course-card" data-courseid={courseId} data-coursename={courseName} data-isinstructor={isInstructor}>
        <Link className="course-card__clickable" to={primaryLink}>
          <div className="course-card__image__parent">
            <div className="course-card__image" style={{ backgroundImage: 'url("/course-thumbnail-placeholder.jpg")' }} />
          </div>
          <div className="course-card__content">
            <h3 className="course-card__title">
              {truncateStringAtSeparator(courseName, 64)}
            </h3>
            <div className="course-card__details">
              <div className="course-card__course-number">
                Course number: <span>{courseNumber}</span>
              </div>
              <div className="course-card__course-start">
                Starts: <span>{startDateString}</span>
              </div>
              {!!primaryInstructorLastName && (
                <div className="course-card__instructor">
                  Instructor: <span>{primaryInstructorLastName}</span>
                </div>
              )}
            </div>
            {!!coinstructorUsers.length && renderCoInstructorIcon(coinstructorUsers)}
          </div>
        </Link>
        {isInstructor && (
          <div className="course-card__actions row">
            {enrolledAs.includes(RoleEnum.Instructor) && (
              <Link
                className={`course-card__actions__button ${btnColClassName}`}
                data-launchas="instructor"
                to={launchAsInstructorLink}
              >
                View/Edit Course
              </Link>
            )}
            {enrolledAs.includes(RoleEnum.Student) && (
              <Link
                className={`course-card__actions__button ${btnColClassName}`}
                data-launchas="student"
                to={launchAsStudentLink}
              >
                Launch as Student
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CourseCard;
