// Ported to TS from: https://github.com/AlmeroSteyn/react-aria-live
import React, { useContext, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { AriaLiveAttribute } from './AriaLive.types';
import AnnouncerContext from './AnnouncerContext';

function AnnouncerMessage({
  'aria-live': ariaLive = AriaLiveAttribute.Polite,
  message,
}: {
  'aria-live'?: AriaLiveAttribute
  message?: string
}) {
  const { announceAssertive, announcePolite } = useContext(AnnouncerContext);
  const [previousMessage, setPreviousMessage] = useState('');

  useEffect(() => {
    if (!!message && message !== previousMessage) {
      switch (ariaLive) {
        case AriaLiveAttribute.Assertive: {
          announceAssertive(message || '', nanoid());
          break;
        }
        case AriaLiveAttribute.Polite: {
          announcePolite(message || '', nanoid());
          break;
        }
      }
      setPreviousMessage(message);
    }
  }, [announcePolite, announceAssertive, ariaLive, message, previousMessage]);

  return <></>;
}

export default AnnouncerMessage;
