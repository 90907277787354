import styles from 'style-config.scss';

/**
 * getReadableTextColor
 * Accepts RGB(a?) or hex color strings and returns either white or black text depending on background brightness
 */

const parseRGBString = (color: string) => {
  const matchedRGB = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
  if (matchedRGB) {
    return {
      r: parseInt(matchedRGB[1]),
      g: parseInt(matchedRGB[2]),
      b: parseInt(matchedRGB[3]),
    };
  }
};

const convertHexToRGB = (color: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  if (result) {
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
  }
};

export default function getReadableTextColor(bgColor: string) {
  const rgb = bgColor.match(/^rgb/) ? parseRGBString(bgColor) : convertHexToRGB(bgColor);
  // if RGB not derived return black text by default;
  if (!rgb) {
    console.error('Invalid RGB value, unable to convert', bgColor);
    return styles.black;
  }
  // get perceived brightness http://alienryderflex.com/hsp.html
  const { r, g, b } = rgb;
  const hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 157.5) {
    return styles.black;
  } else {
    return styles.white;
  }
}
