import { AuthorAppGetItem } from 'types/backend/l8yItems.types';
import { L8yFeatureType, L8yQuestionType } from 'types/backend/l8y.types';
import { AuthorAppMode } from 'types/backend/authorInit.types';

// https://reference.learnosity.com/author-api/events
export enum OnL8yAuthorEvent {
  RenderItem = 'render:item',
  Save = 'save',
  SaveSuccess = 'save:success',
  SaveActivity = 'save:activity',
  ActivityEditSaveableChanged = 'activityedit:saveable:changed',
  ItemEditChanged = 'itemedit:changed',
  Navigate = 'navigate',
  ActivityEditItemEdit = 'activityedit:item:edit',
  ActivityEditItemsAdded = 'activityedit:items:added',
  WidgetEditEditorReady = 'widgetedit:editor:ready',
  WidgetEditPreviewChanged = 'widgetedit:preview:changed',
  WidgetEditWidgetChanged = 'widgetedit:widget:changed',
  WidgetEditWidgetReady = 'widgetedit:widget:ready',
}

export interface L8yAuthorWidgetReady {
  type: L8yQuestionType | L8yFeatureType
  template: string
  wrapper: Element
}

export interface AuthorAppRef {
  createItem: (referenceId?: string) => void
  on: (event: string, cb: (l8yEvent: unknown) => void) => void
  once: (event: string, cb: (l8yEvent: unknown) => void) => void
  off: (event: string) => void
  getItem: () => AuthorAppGetItem
  getLocation: () => LocationData
  save: (arg?: { navigate: boolean }) => Promise<boolean>
  safeToUnload: (appMode?: AuthorAppMode) => boolean
}

export interface LocationData {
  location: string
  locationEncoded: string
  route: string
}

export interface NavigateEventData {
  data: LocationData
  name: string
}

export enum L8yAuthorRoute {
  ItemsNew = 'items/new',
  ItemsReference = 'items/:reference',
  WidgetsNew = 'items/:reference/widgets/new',
  WidgetsReference = 'items/:reference/widgets/:reference',
}

export enum L8yAuthorStatus {
  Init = 'init',
  HomeNewClean = 'home-new-clean',
  HomeNewDirty = 'home-new-dirty', // this only happens if you edit the title, if you go into any widget editor you are now in Edit mode
  HomeEditClean = 'home-edit-clean',
  HomeEditDirty = 'home-edit-dirty',
  HomeEditError = 'home-edit-error',
  WidgetChooseFeature = 'widget-choose-feature',
  WidgetNewDirty = 'widget-new-dirty',
  WidgetEditDirty = 'widget-edit-dirty',
}
