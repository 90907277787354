import React, { ButtonHTMLAttributes, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './BetterButton.scss';

const TextButton = ({
  children,
  className,
  disabled = false,
  linkTo,
  onClick,
  primary,
  selected,
  title,
  ...props
}: {
  children: string
  className?: string
  disabled?: boolean
  linkTo?: string
  onClick?: (e: MouseEvent) => void
  primary?: string
  selected?: boolean
  title?: string
} & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const history = useHistory();
  const classNameString = `text-button ${primary ? 'primary' : ''} ${selected ? 'selected' : ''} ${className}`;
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (linkTo) {
      // use history.push if linkTo is provided
      history.push(linkTo);
    } else if (!!onClick) {
      // otherwise use default onclick event
      onClick(e);
    }
  };
  return (
    <button
      type="button"
      className={classNameString}
      aria-label={children}
      onClick={handleClick}
      disabled={disabled || false}
      title={title}
      {...props}
    >
      { children }
    </button>
  );
};

TextButton.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  selected: PropTypes.bool,
  title: PropTypes.string,
};

TextButton.defaultProps = {
  onClick: () => {},
  className: '',
  primary: false,
  selected: false,
};

export default TextButton;
