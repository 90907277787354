import React, { useState } from 'react';
import Spinner from 'shared-components/Spinner/Spinner';
import './MoveItemButton.scss';

function MoveItemButton({
  addBtnText,
  addBtnTitle,
  cancelBtnText,
  className,
  disabled,
  onAdd,
  onCancel,
  showCancel,
}: {
  addBtnText: string
  addBtnTitle?: string
  cancelBtnText: string
  className: string
  disabled: boolean
  onAdd: () => Promise<boolean>
  onCancel: () => void
  showCancel: boolean
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleAdd = () => {
    setIsLoading(true);
    onAdd();
  };
  return (
    <div className={`move-item-button ${className}`}>
      {showCancel ? (
        <button
          className="cancel-moving"
          disabled={disabled}
          onClick={onCancel}
        >
          {cancelBtnText}
        </button>
      ) : (
        <button
          className="move-lo"
          disabled={disabled}
          onClick={handleAdd}
          data-loading={isLoading}
          title={addBtnTitle}
        >
          {isLoading && <Spinner className="move-item-button__spinner" />}
          {addBtnText}
        </button>
      )}
    </div>
  );
}

export default MoveItemButton;
