import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCaretRight } from 'react-icons/fa';
import useEncodedUrlPath from 'hooks/useEncodedUrlPath';
import { formatPlural, getAssessTypeShortName } from 'utils/commonFormattingFunctions';
import {
  calculateAqmBreakdown,
  calculateCloData,
  getQuestionHashFromAqmBreakdown,
  QuestionDataHash,
  summativeShouldFill,
} from 'utils/alignmentFunctions';
import { useAppSelector } from 'store';
import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import AlignmentPie from 'shared-components/AlignmentPie/AlignmentPie';
import ClassRowCardContainer from './ClassRowCardContainer';
import CustomItemIcon from 'shared-components/CustomItemIcon/CustomItemIcon';
import LoBuilder from 'instructor/components/LoBuilder/LoBuilder';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { InstructorCoursePath } from 'types/instructor.types';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { SharedBCPProps } from '../BetterCoursePlannerController';

const CoursePlannerLoAlignmentSubsection = ({
  assessType,
  classSessionId,
  sectionFilled,
  questionHash,
}: {
  assessType: AssessTypeEnum
  classSessionId: number
  questionHash: QuestionDataHash
  sectionFilled?: boolean
}) => {
  const returnToLink = useEncodedUrlPath();
  const summativeAssessmentSupplements = useAppSelector((store) => store.active.summativeAssessmentSupplements);
  const assessmentIds = Object.keys(questionHash);
  const isSummative = assessType === AssessTypeEnum.Summative;
  // if rendering Summative section, use the sectionFilled override instead of inferring from assessments count
  const filled = isSummative
    ? sectionFilled
    : !!assessmentIds.length;
  return (
    <div
      className="alignment-item__list-item"
      data-assesstype={assessType}
    >
      <div
        className="alignment-item__list-item-indicator"
        data-assesstype={assessType}
        data-filled={filled}
      >
        &nbsp;
      </div>
      <div className="alignment-item__list-item-details">
        <div className="alignment-item__list-item-assessType">
          {getAssessTypeShortName(assessType)}{assessType === AssessTypeEnum.Preclass && ' Assessment'}
        </div>
        <div className="alignment-item__list-item-info">
          {!assessmentIds.length ? '0 items' : assessmentIds.map((assessmentIdKey) => {
            const { count, assessmentName } = questionHash[assessmentIdKey];
            const { id: summativeIdForAssessmentLink } = summativeAssessmentSupplements.find((supp) => Object.values(supp).includes(assessmentIdKey)) || {};
            const assessmentIdForLink = isSummative ? summativeIdForAssessmentLink : assessmentIdKey;
            const assessmentLink = `${InstructorCoursePath.AssessmentBuilder}?assessment=${assessmentIdForLink}${!!classSessionId ? `&class-session=${classSessionId}` : ''}&returnTo=${returnToLink}`;
            return (
              <div key={`item-info_${assessmentIdKey}`}>
                {count} {formatPlural('item', count)} in <Link to={assessmentLink}>{assessmentName}</Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const CoursePlannerClassTopicLearningObjective = ({
  classSessionId,
  clo,
  disableExpando,
  highlight,
  renderLoActions,
}: SharedBCPProps & {
  clo: EnrichedCourseLearningObjective
  disableExpando: boolean
  highlight: boolean
  renderLoActions: (hasQuestions: boolean, triggerLoEdit: () => void) => React.ReactNode
}) => {
  const assessmentQuestionMaps = useAppSelector(retrieveActiveAssessmentQuestionMaps);

  // should always return an array of one
  const [cloData] = calculateCloData([clo], assessmentQuestionMaps);
  const aqmBreakdown = calculateAqmBreakdown(cloData.aqms);
  const { homework, preclass, summative } = aqmBreakdown;
  const [loExpanded, setLoExpanded] = useState(false);
  const [isEditingLo, setIsEditingLo] = useState(false);
  const {
    _derived: { loNumber },
    stringId,
    title: loTitle,
    libraryTopicId,
    type,
    userId,
  } = clo;

  const loQuestionCount = Object.values(cloData.aqms).flat().length;
  const loHasQuestions = !!loQuestionCount;
  const isCustomLo = type === LibraryTypeEnum.User;

  const {
    preclassQuestionsByClo,
    homeworkQuestionsByClo,
    summativeQuestionsByClo,
  } = getQuestionHashFromAqmBreakdown(aqmBreakdown);
  const summativeFilled = summativeShouldFill(summative);

  if (isCustomLo && isEditingLo) {
    return (
      <ClassRowCardContainer
        cardLabel={`Editing ${loNumber}`}
        cardType={LibraryTypeEnum.User}
        className="bcp__lo-builder bcp__lo-builder__edit"
        handleClose={() => setIsEditingLo(false)}
      >
        <LoBuilder
          initEditState={{
            id: clo.learningObjectiveId,
            title: loTitle,
            libraryTopicId,
          }}
          onSave={() => setIsEditingLo(false)}
        />
      </ClassRowCardContainer>
    );
  }

  return (
    <div
      className="bcp__class-row__card__lo"
      data-expanded={loExpanded}
      data-lo-stringid={stringId}
      data-highlight={highlight}
      data-custom-lo={isCustomLo}
      data-cslo-id={clo.csloId}
    >
      <div className="bcp__class-row__card__lo-row">
        <button
          onClick={() => setLoExpanded(!loExpanded)}
          className="bcp__lo-expando-button"
          disabled={disableExpando}
        >
          <FaCaretRight />
        </button>
        <div className="bcp__lo-alignment">
          <AlignmentPie
            greenSlice={!!preclass.length}
            coralSlice={!!homework.length}
            blueSlice={summativeFilled}
          />
        </div>
        <div className="bcp__lo-details">
          <div className="bcp__lo-number">{loNumber}</div>
          {isCustomLo && (
            <CustomItemIcon
              onClick={() => setIsEditingLo(true)}
              ownerUserId={userId}
            />
          )}
          <div className="bcp__lo-title">
            {loTitle}
          </div>
        </div>
        {renderLoActions(loHasQuestions, () => setIsEditingLo(!isEditingLo))}
      </div>
      {loExpanded && (
        <div className="row bcp__lo-expando__body bcp__lo-expando__lo">
          <div className="bcp__class-row__card__lo__alignment row" data-expanded={loExpanded}>
            <div className="alignment-item__list col-xs-6">
              <CoursePlannerLoAlignmentSubsection
                assessType={AssessTypeEnum.Preclass}
                questionHash={preclassQuestionsByClo}
                classSessionId={classSessionId}
              />
              <CoursePlannerLoAlignmentSubsection
                assessType={AssessTypeEnum.Homework}
                questionHash={homeworkQuestionsByClo}
                classSessionId={classSessionId}
              />
            </div>
            <div className="alignment-item__list col-xs-6">
              <CoursePlannerLoAlignmentSubsection
                assessType={AssessTypeEnum.Summative}
                questionHash={summativeQuestionsByClo}
                classSessionId={classSessionId}
                sectionFilled={summativeFilled}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoursePlannerClassTopicLearningObjective;
