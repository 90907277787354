import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { FaCheckCircle, FaRegStar } from 'react-icons/fa';

import retrieveEnrichedStudentAssessments from 'store/selectors/retrieveEnrichedStudentAssessments';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import StudentAssessmentLink from 'student/components/StudentAssessmentLink/StudentAssessmentLink';
import { AssessmentPillProps } from './BetterTimeline';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { formatPlural, getAssessTypeShortName } from 'utils/commonFormattingFunctions';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { StudentAssessmentStatus } from 'types/backend/studentScoresData.types';
import { PositionEnum } from 'types/common.types';

function StudentAssessmentPill({
  assessType,
  classSessionId,
  id: assessmentId,
  inWindow = true,
  mergedOpenDate,
  mergedDueDate,
  name: assessmentName,
  nearRightSide,
}: AssessmentPillProps) {
  const enrichedStudentAssessments = useSelector(retrieveEnrichedStudentAssessments);
  const enrichedStudentAssessment = enrichedStudentAssessments.find(({ id }) => id === assessmentId);
  if (!enrichedStudentAssessment) {
    console.error(`${assessmentId} not found in enrichedStudentAssessments`, enrichedStudentAssessments);
    return null;
  }

  const renderStatusPill = () => {
    const { assessmentStatus, recapPointsAvailable } = enrichedStudentAssessment;
    const showCompletedIcon = [
      StudentAssessmentStatus.CompletedBeforeDue,
      StudentAssessmentStatus.CompletedBeforeLate,
      StudentAssessmentStatus.CompletedAfterLate,
    ].includes(assessmentStatus);
    let statusIcon = null;
    if (assessType !== AssessTypeEnum.Readiness && recapPointsAvailable > 0) {
      const recapTitle = `${recapPointsAvailable} ${formatPlural('points', recapPointsAvailable)} available to recapture.`;
      statusIcon = <FaRegStar title={recapTitle} />;
    } else if (showCompletedIcon) {
      statusIcon = <FaCheckCircle title="Completed" />;
    } else {
      // if neither completed nor recappable, don't show icon
      return null;
    }
    return (
      <div className="pill-status-icon">
        {statusIcon}
      </div>
    );
  };

  return (
    <div className="student-assessment-pill">
      <BetterTooltip
        key={`${assessmentId}_${classSessionId}`}
        className="better-timeline__assessment-tooltip-wrap"
        delayPopup
        disableAutoPosition
        position={nearRightSide ? PositionEnum.Left : PositionEnum.Right}
        content={(
          <div
            className="better-timeline__assessment-tooltip"
            data-assesstype={assessType}
            data-opentostudents={enrichedStudentAssessment.openToStudents}
          >
            <StudentAssessmentLink
              className="better-timeline__assessment-tooltip-name"
              enrichedStudentAssessment={enrichedStudentAssessment}
              text={assessmentName}
            />
            <div className="better-timeline__assessment-tooltip-body">
              <div className="better-timeline__assessment-tooltip-dates__wrap">
                {assessType !== AssessTypeEnum.Summative && (
                  <div className="better-timeline__assessment-tooltip-date">
                    <div>Open:</div>
                    <div>{DateTime.fromISO(mergedOpenDate).toFormat(DateFormatEnum.DateAtTimeMeridean)}</div>
                  </div>
                )}
                <div className="better-timeline__assessment-tooltip-date">
                  <div>Due:</div>
                  <div>{DateTime.fromISO(mergedDueDate).toFormat(DateFormatEnum.DateAtTimeMeridean)}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      >
        <StudentAssessmentLink
          className="better-timeline__pill assessment-pill"
          enrichedStudentAssessment={enrichedStudentAssessment}
          key={`assessment-link_${assessmentId}`}
          text={getAssessTypeShortName(assessType)}
          tabIndex={inWindow ? 0 : -1}
          aria-hidden={!inWindow}
        />
      </BetterTooltip>
      {renderStatusPill()}
    </div>
  );
}

StudentAssessmentPill.propTypes = {
  assessType: PropTypes.oneOf(Object.values(AssessTypeEnum)).isRequired,
  classSessionId: PropTypes.number,
  mergedOpenDate: PropTypes.string.isRequired,
  mergedDueDate: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default StudentAssessmentPill;
