// sourced from github https://github.com/codonlearning/backend/blob/dev/src/services/workerPipelines/workerPipelines.types.ts
import { GenericObject, ContextMethod } from './shared.types';
import { UserApi } from './users.types';

export interface WorkerData {
  service: string
  method: string
  payload: any
  user: UserApi
  port: MessagePort
}

export enum WorkStatus {
  InProgress = 'in_progress',
  Completed = 'completed',
  Failed = 'failed',
  Scheduled = 'scheduled',
  Cancelled = 'cancelled',
}

export interface WorkerPipelineDb {
  id: string
  service: string
  method: ContextMethod
  user__id: string
  work_status: WorkStatus
  payload: GenericObject
  created_at: string
  updated_at: string
  duration: number | null
  outcome: GenericObject | null
  error_code: number | null
  error: GenericObject | null
  reference_asset_id: string | null
}

export interface WorkerPipelineApi {
  id: string
  service: string
  method: ContextMethod
  userId: string
  workStatus: WorkStatus
  payload: GenericObject
  createdAt: string
  updatedAt: string
  duration: number | null
  outcome: GenericObject | null
  errorCode: number | null
  error: GenericObject | null
  referenceAssetId: string | null
}
