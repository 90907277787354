import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
  enhanceLosWithListMetadata,
  getFilterDefaultsForClassSession,
  getLosForTopicsInFilter,
  sortLearningObjectives,
} from '../../courseLoSelectorFunctions';
import searchWithinArray from 'utils/searchWithinArray';
import { formatPlural } from 'utils/commonFormattingFunctions';
import { FeatureFlags } from 'App';
import retrieveActiveCourseLearningObjectives from 'store/selectors/retrieveActiveCourseLearningObjectives';
import retrieveEnrichedClassSessions from 'store/selectors/retrieveEnrichedClassSessions';
import TextButton from 'shared-components/BetterButton/TextButton';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import LoLibraryItem from './LoLibraryItem';
import LoLibraryFilter from './LoLibraryFilter/LoLibraryFilter';
import SearchInput from 'shared-components/SearchInput/SearchInput';
import { LoActionDispatch } from '../../CourseLoSelector.types';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { Store } from 'types/store.types';
import { TopicApi } from 'types/backend/topics.types';
import { UnitApi } from 'types/backend/units.types';
import './LoLibrary.scss';


export enum SelectUnitOrTopic {
  Unit = 'unit',
  Topic = 'topic',
}

export enum ExpandSubjectOrUnit {
  Subject = 'subject',
  Unit = 'unit',
}

function LoLibrary({ currentClassSessionId, currentSessionLoIds, loAction }: {
  currentClassSessionId: number
  currentSessionLoIds: Array<number>
  loAction: LoActionDispatch
}) {
  const flags = useContext(FeatureFlags);
  const { subjectId: primarySubjectId, additionalSubjectIds } = useSelector((store: Store) => store.active.course);
  const classSessions = useSelector(retrieveEnrichedClassSessions);
  const courseLearningObjectives = useSelector(retrieveActiveCourseLearningObjectives);
  const classSessionLearningObjectives = useSelector((store: Store) => store.active.classSessionLearningObjectives);
  const classSessionTopics = useSelector((store: Store) => store.active.classSessionTopics);
  const topics = useSelector((store: Store) => store.active.topics);
  const units = useSelector((store: Store) => store.active.units);
  const subjects = useSelector((store: Store) => store.passive.subjects);
  const learningObjectives = useSelector((store: Store) => store.active.learningObjectives);

  const availableSubjectIds = [primarySubjectId, ...additionalSubjectIds];

  const [expandedSubjectIds, setExpandedSubjectIds] = useState<Array<number>>([primarySubjectId]);
  const [expandedUnitIds, setExpandedUnitIds] = useState<Array<number>>([]);

  const initialSelectedFilterState = {
    selectedUnitIds: [] as Array<number>,
    selectedTopicIds: [] as Array<number>,
  };
  const [selectedFilters, setSelectedFilters] = useState(initialSelectedFilterState);
  const { selectedUnitIds, selectedTopicIds } = selectedFilters;
  const showAllUnitsAndTopics = !selectedUnitIds.length && !selectedTopicIds.length;
  const [priorSelectedClassSessionId, setPriorSelectedClassSessionId] = useState<number | null>(null);
  const customUnits = units.filter(unit => unit.type === LibraryTypeEnum.User && availableSubjectIds.includes(unit.subjectId));
  const [searchTerm, setSearchTerm] = useState('');

  // Topics for a custom unit may include Codon topics. This happens when there is a custom LO parent or CSLO topic that is a Codon topic.
  // In that case, the topicId needs to be unique.
  // For ease of converting back and forth the inverse of the id is used (id * -1). This conversion appears in some additional LoSelector components as well
  const topicsForFilter = (() => {
    const topicsForCustomUnits = topics.reduce((acc: Array<TopicApi>, cur: TopicApi) => {
      if (acc.find(topic => topic.id === cur.id)) {
        return acc;
      }
      if (cur.type === LibraryTypeEnum.User) {
        acc.push(cur); //if it is a custom topic, add it
      } else {
        // if it is a Codon topic it may also need to be added
        const { id: customUnitIdForSubject } = customUnits.find(cus => cus.subjectId === cur.subjectId) as UnitApi; // custom unitIds vary by subject
        if (learningObjectives.find(lo => lo.topicId === cur.id && lo.type === LibraryTypeEnum.User)) {
          //if it is a Codon topic with custom LO children, add it
          acc.push({ ...cur, id: cur.id * -1, unitId: customUnitIdForSubject }); // id * -1 to create a unique value
          // in the multisubject world, this is not the custom unit id, it is the custom unit id for the subject of the topic
          // the subject of the topic is the subject of the unit unless we have it.
        } else {
          // it if hasn't already been added, check to see if there is a cslo with a custom LO for it
          const topicInCslos = learningObjectives.find(alo => {
            return alo.type === LibraryTypeEnum.User && classSessionLearningObjectives.find(cslo => cslo.learningObjectiveId === alo.id && cslo.topicId === cur.id);
          });
          if (topicInCslos) {
            acc.push({ ...cur, id: cur.id * -1, unitId: customUnitIdForSubject }); // id * -1 to create a unique value
          }
        }
      }
      return acc;
    }, []);

    topicsForCustomUnits.sort((a, b) => a.name.localeCompare(b.name));

    return topics.filter(t => t.type === LibraryTypeEnum.Template).concat(topicsForCustomUnits);
  })();

  // when navigating between class sessions, current filter selection should persist
  const updateFilterSelectionForClassSession = useCallback((additiveSelect?: boolean) => {
    const { subjectIds, topicIds, unitIds } = getFilterDefaultsForClassSession({
      classSessionId: currentClassSessionId,
      classSessionLearningObjectives,
      classSessionTopics,
      learningObjectives,
      topics,
      units,
    });

    const newTopicIds = !additiveSelect ? topicIds : [...new Set([...selectedTopicIds, ...topicIds])];
    const newUnitIds = !additiveSelect ? unitIds : [...new Set([...selectedUnitIds, ...unitIds])];
    setSelectedFilters({
      selectedTopicIds: newTopicIds,
      selectedUnitIds: newUnitIds,
    });
    setExpandedUnitIds(newUnitIds);
    if (!!subjectIds.length) {
      setExpandedSubjectIds(subjectIds);
    }
  }, [currentClassSessionId]); // eslint-disable-line react-hooks/exhaustive-deps

  // load the initial filter state for the LoLibraryFilter, based on the current classSession and prior filter state
  useEffect(() => {
    if (!selectedTopicIds.length && !selectedUnitIds.length && priorSelectedClassSessionId === currentClassSessionId) {
      // if there are no selected topics or units and the classSession is not changing, don't change filter state
      return;
    }
    // if currentClassSessionId changes, update filter state based on CSTs and CSLOs
    updateFilterSelectionForClassSession(true);
    setPriorSelectedClassSessionId(currentClassSessionId);
  }, [currentClassSessionId]); // eslint-disable-line react-hooks/exhaustive-deps

  // clears all filters, expand all subjects
  const handleShowAll = () => {
    setSelectedFilters(initialSelectedFilterState);
    setExpandedUnitIds([]);
    setExpandedSubjectIds(availableSubjectIds);
  };

  const handleSelectedToggle = (unitOrTopic: SelectUnitOrTopic, unitOrTopicId: number) => {
    const unitSet = new Set(selectedUnitIds);
    const topicSet = new Set(selectedTopicIds);
    switch (unitOrTopic) {
      case SelectUnitOrTopic.Unit: {
        const unitId = unitOrTopicId;
        const topicsWithinUnit = topicsForFilter.filter(t => t.unitId === unitId);
        const unitIsSelected = unitSet.has(unitId);
        if (unitIsSelected) {
          unitSet.delete(unitId);
          if (!!topicsWithinUnit.length) {
            topicsWithinUnit.forEach((topic) => topicSet.delete(topic.id));
          }
        } else {
          unitSet.add(unitId);
          topicsWithinUnit.forEach(({ id: topicId }) => {
            topicSet.add(topicId);
          });
          setExpandedUnitIds((prev) => [...prev, unitId]);
        }
        break;
      }
      case SelectUnitOrTopic.Topic: {
        const topicId = unitOrTopicId;
        const selectedTopic = topicsForFilter.find(topic => topic.id === topicId) as TopicApi;
        const selectedUnitFromTopic = units.find(u => u.id === selectedTopic.unitId) as UnitApi;
        const {
          unitId: selectedTopicUnitId,
          subjectId: selectedTopicSubjectId,
        } = selectedTopic;

        if (selectedTopicSubjectId && !expandedSubjectIds.includes(selectedTopicSubjectId)) {
          setExpandedSubjectIds((prev) => [...prev, selectedUnitFromTopic.subjectId]);
        }
        const topicIsSelected = topicSet.has(topicId);
        if (!topicIsSelected) {
          topicSet.add(topicId);
          unitSet.add(selectedTopicUnitId);
        } else {
          topicSet.delete(topicId);
          const noTopicsSelectedInUnit = !topicsForFilter.some(t => t.unitId === selectedTopicUnitId && topicSet.has(t.id));
          if (noTopicsSelectedInUnit) {
            unitSet.delete(selectedTopicUnitId);
          }
        }
        break;
      }
    }
    setSelectedFilters({
      selectedTopicIds: [...topicSet],
      selectedUnitIds: [...unitSet],
    });
  };

  const handleExpandedToggle = (subjectOrUnit: ExpandSubjectOrUnit, subjectOrUnitId: number) => {
    switch (subjectOrUnit) {
      case ExpandSubjectOrUnit.Subject: {
        setExpandedSubjectIds((prev) => {
          return expandedSubjectIds.includes(subjectOrUnitId)
            ? prev.filter((s) => s !== subjectOrUnitId)
            : [...prev, subjectOrUnitId];
        });
        break;
      }
      case ExpandSubjectOrUnit.Unit: {
        setExpandedUnitIds((prev) => {
          return expandedUnitIds.includes(subjectOrUnitId)
            ? prev.filter((s) => s !== subjectOrUnitId)
            : [...prev, subjectOrUnitId];
        });
        break;
      }
    }
  };

  const losForSelectedTopics = getLosForTopicsInFilter({
    learningObjectives,
    classSessionLearningObjectives,
    customUnitIds: customUnits.map(u => u.id),
    primarySubjectId,
    selectedFilters,
    topics,
    units,
  });

  const renderResultHeader = (resultsCount: number, showingAll: boolean, hasSearchTerm: boolean) => {
    const resultsMessage = () => {
      // this is deliberately WET for easier comprehension
      if (hasSearchTerm) {
        // extra &nbsp; to account for kerning width
        const hasSearchBaseMsg = <>{resultsCount} {formatPlural('LO', resultsCount)} found for<em>&nbsp;&quot;{searchTerm}&quot;&nbsp;</em></>;
        if (showingAll) {
          // X LOs found from SEARCH_TERM
          return (
            <>
              {hasSearchBaseMsg} in all topics
            </>
          );
        }
        // X LOs found from SEARCH_TERM [expand]
        return (
          <>
            {hasSearchBaseMsg} within filters.&nbsp;
            <TextButton onClick={handleShowAll}>Expand search</TextButton>
          </>
        );
      } else {
        if (showingAll) {
          // showing all X LOs
          return (
            <>
              Showing all {resultsCount} {formatPlural('LO', resultsCount)}
            </>
          );
        }
        // showing X LOs
        return (
          <>
            Showing {resultsCount} {formatPlural('LO', resultsCount)}
          </>
        );
      }
    };
    return (
      <div
        className="lo-library__table-body__results-header"
        data-has-search-term={hasSearchTerm}
        data-notfound={!resultsCount}
      >
        {resultsMessage()}
      </div>
    );
  };

  const sortedLosForSelectedTopics = sortLearningObjectives(losForSelectedTopics, subjects, units, topics, primarySubjectId);
  const loItemList = enhanceLosWithListMetadata(sortedLosForSelectedTopics, currentSessionLoIds, classSessions, topics, courseLearningObjectives);

  const searchResults = searchWithinArray(searchTerm, loItemList);
  const loListItems = !!searchTerm ? searchResults : loItemList;

  return (
    <main className="lo-library">
      <div className="lo-library__wrapper">
        <div className="lo-library__search-bar row">
          <div className="col-xs-6">
            {showAllUnitsAndTopics
              ? <BetterButton secondary text="Reset Filters to this Class Day" onClick={() => updateFilterSelectionForClassSession()} />
              : <BetterButton secondary text={`Show All ${topics.length} Topics`} onClick={handleShowAll} />
            }
          </div>
          <div className="lo-library__search-bar__spacer col-xs-2"></div>
          <div className="col-xs-4 lo-library__search-wrap">
            <SearchInput onChange={setSearchTerm} />
          </div>
        </div>
        <div className="lo-library__inner">
          <LoLibraryFilter
            expandedUnitIds={expandedUnitIds}
            selectedFilters={selectedFilters}
            onSelectedToggle={handleSelectedToggle}
            onExpandedToggle={handleExpandedToggle}
            expandedSubjectIds={expandedSubjectIds}
            topicsForFilter={topicsForFilter}
          />
          <div className="lo-library__lo-list">
            <div className="lo-library__table-header lo-library-item__table-row">
              <div className="lo-library-item__table-row__title">Learning Objectives</div>
              {flags.enableCustomLos && <div className="lo-library-item__table-row__kebab-button"></div>}
              <div className="lo-library-item__table-row__topic">Topic</div>
              <div className="lo-library-item__table-row__blooms">Bloom's</div>
              <div className="lo-library-item__table-row__spacer"></div>
            </div>
            <div className="lo-library__table-body">
              {renderResultHeader(searchResults.length, showAllUnitsAndTopics, !!searchTerm)}
              {loListItems.map((item) => (
                <LoLibraryItem
                  item={item}
                  key={`${item.id}-${item.topicId}`}
                  loAction={loAction}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

LoLibrary.propTypes = {
  currentClassSessionId: PropTypes.number.isRequired,
  currentSessionLoIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  loAction: PropTypes.func.isRequired,
};

export default LoLibrary;
