import React, { useState } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { DateTime } from 'luxon';

import { GenericObject, OrderByEnum } from 'types/backend/shared.types';

interface AdminDataTableLabel {
  columnLabel: string // The label to display in the table header
  columnKey: string // The key to use to access the data in the data array
  columnIndex?: string // An index to unsure a unique key for react for each row if the columnKey is not unique
  mutate?: (input: string) => string // A function to mutate the data before displaying it in the table
  sortable?: boolean // Whether the column is sortable
}

interface AdminDataTableProps {
  className: string
  labels: Array<AdminDataTableLabel>
  data?: Array<GenericObject>
  indexKey: string
  formatRow?: (datum: GenericObject) => React.CSSProperties
  showRowNum?: boolean
  summaryColumns?: Array<string> // summaryColumns that match columnKey in a label will have every row totalled and displayed at the bottom of the table
}

interface SortInfo {
  columnKey: string
  direction: OrderByEnum
}


const AdminDataTable = ({ className, data, formatRow, indexKey, labels, showRowNum = true, summaryColumns }: AdminDataTableProps) => {
  const [activeSort, setActiveSort] = useState<SortInfo | null>(null);

  const sortActiveData = (sortInfo: SortInfo, incomingData: Array<GenericObject>) => {
    if (!sortInfo.columnKey) {
      return incomingData;
    }
    const dataToSort = [...incomingData];

    function compareValues(a: number | string | null, b: number | string | null) {
      if (a === null) {return -1;}
      if (b === null) {return 1;}
      if (typeof a === 'number' || !isNaN(+a) || typeof b === 'number' || !isNaN(+b)) {
        return +a - +b;
      } else if (DateTime.fromISO(a).isValid && DateTime.fromISO(b).isValid) {
        return DateTime.fromISO(a).valueOf() - DateTime.fromISO(b).valueOf();
      } else {
        return a.toString().localeCompare(b.toString());
      }
    }

    return dataToSort.sort((a, b) => {
      const aVal = a[sortInfo.columnKey];
      const bVal = b[sortInfo.columnKey];
      const comparisonResult = compareValues(aVal, bVal);
      return sortInfo.direction === OrderByEnum.Asc ? comparisonResult : -comparisonResult;
    });

  };

  if (!data || !data.length) {
    return <div>no results</div>;
  }

  let summaryRow: any = {};
  if (summaryColumns && summaryColumns.length) {
    const summaryDataRow = data.reduce((acc, row, index) => {
      labels.forEach((label) => {
        if (summaryColumns.find((summaryColumn) => summaryColumn === label.columnKey)) {
          if (index === 0) {
            acc[label.columnKey] = 0;
          }
          acc[label.columnKey] = acc[label.columnKey] + parseInt(row[label.columnKey]);
        } else {
          acc[label.columnKey] = '';
        }
      });
      return acc;
    }, {});
    summaryRow = summaryDataRow;
  }

  const handleColumnClick = (columnKey: string) => {
    let newSort = activeSort ? { ...activeSort } : null;
    if (newSort && newSort.columnKey === columnKey) {
      const direction = newSort.direction === OrderByEnum.Asc ? OrderByEnum.Desc : OrderByEnum.Asc;
      newSort = { columnKey, direction };
    } else {
      newSort = { columnKey, direction: OrderByEnum.Asc };
    }
    if (newSort) {
      setActiveSort(newSort);
    }
  };

  const sortedData = !!data.length ? sortActiveData(activeSort || { columnKey: '', direction: OrderByEnum.Asc }, data) : [];

  return (
    <table className={className}>
      <thead>
        <tr>
          {showRowNum && (
            <th key="index">Row</th>
          )}
          {labels.map(({ columnLabel, columnKey, sortable }) => (
            <th key={columnLabel}>
              {sortable ? (
                <button className={`${className}-sortable-column-header`} onClick={() => handleColumnClick(columnKey)}>
                  {columnLabel}
                  {activeSort && activeSort.columnKey === columnKey && (
                    activeSort.direction === OrderByEnum.Asc ? <FaArrowUp /> : <FaArrowDown />
                  )}
                </button>
              ) : (
                <>
                  { columnLabel }
                </>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((datum, index) => (
          <tr key={datum[indexKey]} style={!!formatRow ? formatRow(datum) : {}} >
            {showRowNum && (
              <td key="index">{index + 1}</td>
            )}
            {labels.map((label) => {
              const { columnKey, columnIndex, mutate } = label;
              const value = datum[columnKey];
              const formattedValue = !!mutate ? mutate(value) : value;
              return (
                <td key={columnIndex ? columnIndex : columnKey}>{formattedValue}</td>
              );
            })}
          </tr>
        ))}
        {summaryColumns && summaryRow && (
          <tr className="summary-row">
            {showRowNum && (
              <td key="index">Total</td>
            )}
            {labels.map((label) => {
              // If a summaryColumn matches a columnKey, total all the rows, format as per the summary columnKey, and display at the bottom of the table
              const { columnKey, mutate } = label;
              const value = summaryRow[columnKey];
              let formattedValue = '';
              if (summaryColumns.includes(columnKey)) {
                formattedValue = !!mutate ? mutate(value) : value;
              }
              return (
                <td key={`${columnKey}-total}`}>{formattedValue.toLocaleString()}</td>
              );
            })}
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default AdminDataTable;
