import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch } from 'types/store.types';

export default function removeIclr(
  id: number
) {
  return (dispatch: AppDispatch) => (async () => {
    //make the change on the server:
    await apiNext.deleteIclr(id);

    //Delete from local store for instant UI
    dispatch(activeSlice.actions.removeActiveIclr({ id }));
  })();
}
