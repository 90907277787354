import React from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { useQueryParam, StringParam } from 'use-query-params';

import useClassSessionQuery from 'hooks/useClassSessionQuery';
import { compareNumberArrays } from 'utils';
import { getInstructionTypeShortName } from 'utils/commonFormattingFunctions';
import { useAppDispatch } from 'store';
import addIclrToClassSessions from 'store/actions/addIclrToClassSessions';
import addOoclrToClassSessions from 'store/actions/addOoclrToClassSessions';
import createIclrAndAddToClassSession from 'store/actions/createIclrAndAddToClassSession';
import createOoclrAndAddToClassSession from 'store/actions/createOoclrAndAddToClassSession';
import editIclr from 'store/actions/editIclr';
import editOoclr from 'store/actions/editOoclr';
import removeIclr from 'store/actions/removeIclr';
import removeOoclr from 'store/actions/removeOoclrs';
import removeIclrFromClassSessions from 'store/actions/removeIclrFromClassSessions';
import removeOoclrFromClassSessions from 'store/actions/removeOoclrFromClassSessions';
import retrieveBetterClassSessions, { BetterClassSession } from 'store/selectors/retrieveBetterClassSessions';
import retrieveEnrichedIclrs from 'store/selectors/retrieveEnrichedIclrs';
import retrieveEnrichedOoclr from 'store/selectors/retrieveEnrichedOoclrs';
import BetterTimeline from 'shared-components/BetterTimeline/BetterTimeline';
import InstructorAssessmentPill from 'shared-components/BetterTimeline/InstructorAssessmentPill';
import ExitBlock from 'instructor/components/ExitBlock/ExitBlock';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import InstructionsBuilder from './Instructions/InstructionsBuilder';
import { IclrApi } from 'types/backend/iclr.types';
import { OoclrApi } from 'types/backend/ooclr.types';
import { ClassSessionIclrApi } from 'types/backend/classSessionIclr.types';
import { ClassSessionOoclrApi } from 'types/backend/classSessionOoclr.types';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { InstructionEnum } from 'types/common.types';
import { Store } from 'types/store.types';
import { InstructionsRow } from './InstructionsController.types';
import './InstructionsController.scss';

export default function InstructionsController() {
  /******************************************
   * INITIALIZATION
   */
  const dispatch = useAppDispatch();

  // store & state retrievals:
  const userId = useSelector((store: Store) => store.user.id);
  const iclrs = useSelector((store: Store) => store.active.iclrs);
  const classSessionIclrs = useSelector((store: Store) => store.active.classSessionIclrs);
  const ooclrs = useSelector((store: Store) => store.active.ooclrs);
  const classSessionOoclrs = useSelector((store: Store) => store.active.classSessionOoclrs);
  const classSessions = useSelector(retrieveBetterClassSessions);

  const [instructionTypeQuery] = useQueryParam('type', StringParam);
  const [selectedClassSessionId] = useClassSessionQuery(classSessions);
  const clrType = instructionTypeQuery as InstructionEnum;
  const instShortName = getInstructionTypeShortName(clrType);

  // load iclrs and ooclrs
  const enrichedIclrs = useSelector(retrieveEnrichedIclrs);
  const enrichedOoclrs = useSelector(retrieveEnrichedOoclr);
  const enrichedClrs = clrType === InstructionEnum.InClass ? enrichedIclrs : enrichedOoclrs;


  const instructionInfo = enrichedClrs.reduce((acc, clr) => {
    const existingDisplayClr = acc.findIndex((r) => r.clrId === clr.clrId);
    const { id, classNumber } = classSessions.find((cs) => cs.id === clr.classSessionId) as BetterClassSession;
    if (existingDisplayClr === -1) {
      const newClr = {
        ids: [clr.id],
        clrId: clr.clrId,
        clrType,
        classSessionIds: [clr.classSessionId],
        classNumbers: [classNumber],
        title: clr.title,
        url: clr.url,
        type: clr.type,
        userId: clr.userId,
      };
      return [...acc, newClr];
    } else {
      acc[existingDisplayClr].ids.push(clr.id);
      acc[existingDisplayClr].classSessionIds.push(id);
      acc[existingDisplayClr].classNumbers.push(classNumber);
      acc[existingDisplayClr].classNumbers.sort((a, b) => a - b);
    }
    return acc;
  }, [] as Array<InstructionsRow>);

  instructionInfo.sort((a: InstructionsRow, b: InstructionsRow) => compareNumberArrays(a.classNumbers, b.classNumbers));

  function saveInstruction(
    classSessionIds: Array<number>,
    title: string,
    type: LibraryTypeEnum,
    url: string | null
  ) {
    switch (clrType) {
      case InstructionEnum.InClass: {
        return dispatch(createIclrAndAddToClassSession(
          classSessionIds,
          title,
          type,
          userId,
          url
        ));
      }
      case InstructionEnum.OutOfClass: {
        return dispatch(createOoclrAndAddToClassSession(
          classSessionIds,
          title,
          type,
          userId,
          url
        ));
      }
    }
  }

  async function deleteInstruction(
    classSessionClrIds: Array<number>,
    clrId: number
  ) {
    switch (clrType) {
      case InstructionEnum.InClass: {
        await dispatch(removeIclrFromClassSessions(classSessionClrIds));
        return dispatch(removeIclr(clrId));
      }
      case InstructionEnum.OutOfClass: {
        await dispatch(removeOoclrFromClassSessions(classSessionClrIds));
        return dispatch(removeOoclr(clrId));
      }
    }
  }

  async function updateInstruction(
    clrId: number,
    classSessionIds: Array<number>,
    title: string,
    url: string | null
  ) {
    // update clr
    const originalInstructionRow = instructionInfo.find((clr) => clr.clrId === clrId) as InstructionsRow;
    if (originalInstructionRow.title !== title || originalInstructionRow.url !== url) {
      const originalClr: IclrApi | OoclrApi = clrType === InstructionEnum.InClass
        ? iclrs.find((iclr) => iclr.id === clrId) as IclrApi
        : ooclrs.find((ooclr) => ooclr.id === clrId) as OoclrApi;

      const clrData = {
        id: originalClr.id,
        title,
        url,
        type: LibraryTypeEnum.User,
        userId: originalClr.userId,
        createdAt: originalClr.createdAt,
        updatedAt: DateTime.local().toUTC().toString(),
      };


      if (clrType === InstructionEnum.InClass) {
        dispatch(editIclr(clrData));
      } else {
        dispatch(editOoclr(clrData));
      }
    }

    // update class-session/clr mapping
    // if class sessions were not touched, an empty array is passed for classSessionsIds
    if (!!classSessionIds.length) {
      // determine class sessions that need to be added to CLR
      const newClassSessionClrIds: Array<number> = [];
      classSessionIds.forEach(id => {
        if (!originalInstructionRow.classSessionIds.some(csId => csId === id)) {
          newClassSessionClrIds.push(id);
        }
      });

      if (clrType === InstructionEnum.InClass) {
        dispatch(addIclrToClassSessions(newClassSessionClrIds, clrId));
      } else {
        dispatch(addOoclrToClassSessions(newClassSessionClrIds, clrId));
      }

      // determine class sessions that are no longer associated to CLR
      const deleteClassSessionClrIds: Array<number> = [];
      originalInstructionRow.classSessionIds.forEach(csId => {
        if (!classSessionIds.some(id => id === csId)) {
          if (clrType === InstructionEnum.InClass) {
            const csIclr = classSessionIclrs.find(({ classSessionId, iclrId }) => iclrId === clrId && classSessionId === csId) as ClassSessionIclrApi;
            deleteClassSessionClrIds.push(csIclr.id);
          } else {
            const csOoclr = classSessionOoclrs.find(({ classSessionId, ooclrId }) => ooclrId === clrId && classSessionId === csId) as ClassSessionOoclrApi;
            deleteClassSessionClrIds.push(csOoclr.id);
          }
        }
      });
      if (clrType === InstructionEnum.InClass) {
        dispatch(removeIclrFromClassSessions(deleteClassSessionClrIds));
      } else {
        dispatch(removeOoclrFromClassSessions(deleteClassSessionClrIds));
      }
    }
  }

  if (!selectedClassSessionId) {
    return <LoadingSpinner />;
  }

  return (
    <div className="instructions-controller">
      <BetterTimeline
        currentClassSessionId={selectedClassSessionId}
        renderAssessmentPill={(assessmentPillData) => (
          <InstructorAssessmentPill {...assessmentPillData} />
        )}
      />
      <nav aria-label="Exit" className="instruction-nav">
        <ExitBlock title={instShortName}/>
      </nav>
      <InstructionsBuilder
        instructionHeading={instShortName}
        classSessions={classSessions}
        instructions={instructionInfo}
        saveInstruction={saveInstruction}
        deleteInstruction={deleteInstruction}
        updateInstruction={updateInstruction}
      />
    </div>
  );
}
