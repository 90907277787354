/**
 * Extract error message and other data from error object
 * @param  {any} error
 * @returns string
 */

import { GenericObject } from 'types/backend/shared.types';
const version = process.env.REACT_APP_VERSION || '0.0.0';

export interface ErrorData {
  config?: GenericObject
  response?: GenericObject
}

export const generateLogMessage = (error: ErrorData): string => {
  const { config = undefined, response = undefined } = error;
  let logObj = {};

  if (config !== undefined) {
    const { method, url, data } = config;
    let requestData: undefined;
    if (data !== undefined) {
      requestData = JSON.parse(data);
    }
    logObj = { ...logObj, ...{ request: { method, url, requestData } } };
  }

  let status, statusText, errorMessage;
  if (response !== undefined) {
    const { message, code, name } = response?.data;
    errorMessage = message;
    status = code;
    statusText = name;
    logObj = { ...logObj, ...{ response: { code, name, message } } };
  }
  const statusString = status ? `${status} - [${statusText}] - ` : '';
  const logMessage = `API ${version} - ${statusString} ${errorMessage || 'no error message'},
    metadata: ${JSON.stringify(logObj, null, 2)}`;
  return logMessage;
};
