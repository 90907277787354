/**
 * This selector returns enriched oocrl objects for all ooclrs that are connected to classSessions in the course
*/
import { createSelector } from '@reduxjs/toolkit';
import { enrichClassSessionClrs } from './retrieveEnrichedIclrs';
import { ClassSessionOoclrApi } from 'types/backend/classSessionOoclr.types';
import { InstructionEnum } from 'types/common.types';
import { Store } from 'types/store.types';
import { OoclrApi } from 'types/backend/ooclr.types';

export default createSelector(
  (store: Store) => store.active.classSessionOoclrs,
  (store: Store) => store.active.ooclrs,
  (classSessionOoclrs: Array<ClassSessionOoclrApi> = [], ooclrs: Array<OoclrApi> = []) => {
    return enrichClassSessionClrs(classSessionOoclrs, ooclrs, InstructionEnum.OutOfClass);
  }
);
