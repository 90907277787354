// from: https://usehooks-typescript.com/react-hook/use-on-click-outside
import { RefObject, useEffect } from 'react';

type AnyEvent = MouseEvent | TouchEvent

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: AnyEvent) => void,
  isModal?: boolean
): void {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const el = ref?.current;
      if (!el) {
        return;
      }
      const refElement = el as Element;
      const clickedElement = event.target as Element;
      if (isModal) {
        // only trigger the handler if the clicked element matches the className of the ref element
        if (refElement.className === clickedElement.className) {
          return handler(event);
        }
        return;
      }
      // only trigger if click is outside refElement
      if (ref.current && !ref.current.contains(clickedElement)) {
        return handler(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };

    // Reload only if ref or handler changes
  }, [ref, handler, isModal]);
}

export default useOnClickOutside;
