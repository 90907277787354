import React from 'react';
import PropTypes from 'prop-types';
import { FaCheck } from 'react-icons/fa';
import './ItemRemovalProgressMeter.scss';


interface ItemRemovalProgressMeterProps {
  totalItemCount: number
  removedItemCount: number
}

const ItemRemovalProgressMeter = (props: ItemRemovalProgressMeterProps) => {
  const { totalItemCount, removedItemCount } = props;
  const itemsRemoved = Array.from({ length: removedItemCount }, (_, index) => index + 1);

  return (
    <div className="countdown-progress-bar">
      <div className="countdown-progress-bar__item">
        <div className="countdown-progress-bar__status-display">
          {totalItemCount - removedItemCount}
        </div>
        Items remaining
      </div>
      <div className="countdown-progress-bar__progress-box">
        {!!itemsRemoved.length && itemsRemoved.map(el => (
          <FaCheck key={el} />
        ))}
        {totalItemCount === removedItemCount && (
          <p>Success!</p>
        )}
      </div>
    </div>
  );
};

ItemRemovalProgressMeter.propTypes = {
  totalItemCount: PropTypes.number.isRequired,
  removedItemCount: PropTypes.number.isRequired,
};


export default ItemRemovalProgressMeter;
