import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import apiNext from 'api-next';
import { mailToLink } from 'utils/commonFormattingFunctions';
import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import useDocumentTitle from 'hooks/useDocumentTitle';
import useIdleLogout, { IdleLogoutInstance } from 'hooks/useIdleLogout';
import logout from 'utils/logout';
import CodonUrls from 'urls';
import sharedStrings from 'sharedStrings';
import { useAppDispatch, useAppSelector } from 'store';
import userSlice from 'store/slices/user';
import { BooleanHash } from 'types/common.types';
import './Login.scss';

/* Notes for working with this file:
  The actual changes in the institution specific versions are surround with <em> to make the changes easier to keep track of.
  Even though the full terms for each section are repeated.
  We need to remember if we make material changes to our terms of service, we may need to contact the institutions with varied terms to get approval.
*/
export default function UserAgreement() {
  const user = useAppSelector((store) => store.user);
  const dispatch = useAppDispatch();
  useDocumentTitle('User Agreement');
  const isCanada = process.env.REACT_APP_URL?.includes('ca-app');
  useIdleLogout(user, IdleLogoutInstance.TermsOfService, 1);
  const [expandoHash, setExpandoHash] = useState<BooleanHash>({});

  const doUserAgreement = async () => {
    const userData = await apiNext.editUserAgreement();
    if (userData.agreementDate) {
      dispatch(userSlice.actions.setUser(userData));
    }
  };

  const cancelUserAgreement = async () => {
    await logout(dispatch);
  };

  const Expando = ({ id, children, header, expandedHeader, show = true }: { id: string; children: React.ReactNode; header: React.ReactNode; expandedHeader?: React.ReactNode; show?: boolean }) => {
    if (!show) {
      return null;
    }
    // default to closed
    const isExpanded = typeof expandoHash[id] === 'undefined' ? false : expandoHash[id];
    return (
      <div className="persisted-expando" data-expanded={isExpanded} id={id}>
        <button className="expando__trigger" onClick={() => setExpandoHash({ ...expandoHash, [id]: !isExpanded })}>
          <FaChevronDown className="user-agreement__privacy-button"/>
          {isExpanded && expandedHeader ? expandedHeader : header}
        </button>
        {isExpanded && children}
      </div>
    );
  };

  return (
    <main>
      <div className="userAgreement__box">
        <h1 className="h2">Terms of Use for Codon Learning</h1>
        <div>Last updated: Oct 30, 2023</div>
        <div className="content">
          <p>Please read these Terms of Use (&quot;Terms&quot;, &quot;Terms of Use&quot;) carefully before using the {isCanada ? externalLink(CodonUrls.CodonAppCanada) : externalLink(CodonUrls.CodonApp)} application (the &quot;Service&quot;) operated by Codon Learning, Inc. (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;).</p>
          <p>Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who wish to access or use the Service.</p>
          <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms, then you do not have permission to access the Service.</p>

          <h2 className="h4">Communications</h2>
          <p>
            By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send in accordance with our Privacy Policy listed in Exhibit A (&quot;Privacy Policy&quot;). However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.<br />
          </p>

          <h2 className="h4">Purchases</h2>
          <p>If you wish to purchase any product or service made available through the Service (&quot;Purchase&quot;), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information. You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct, and complete.</p>
          <p>The Service may employ the use of third-party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.</p>
          <p>We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.</p>
          <p>We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.</p>
          <p>You may receive a refund for Codon Learning courseware within 7 days of purchase or as otherwise agreed upon. Please submit a support ticket or mail {mailToLink(sharedStrings.CODON_SUPPORT_EMAIL)} to request a refund.</p>

          <h2 className="h4">Availability, Errors, and Inaccuracies</h2>
          <p>We are constantly updating product and service offerings on the Service. We may experience delays in updating information on the Service and in our advertising on other websites. The information found on the Service may contain errors or inaccuracies and may not be complete or current. Products or services may be mispriced, described inaccurately, or unavailable on the Service and we cannot guarantee the accuracy or completeness of any information found on the Service.</p>
          <p>We therefore reserve the right to change or update information, and to correct errors, inaccuracies, or omissions at any time without prior notice, provided that any such modification will not degrade the functionality of the Service in any material manner, unless required by applicable law. We will notify you in advance of any material modifications.</p>

          <h2 className="h4">Content</h2>
          <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (&quot;Content&quot;). You are responsible for the Content that you post on or through the Service, including its legality, reliability, and appropriateness.</p>
          <p>By posting Content on or through the Service, you represent and warrant that: (i) the Content is yours (you own it) and/or you have the right to use it, and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.</p>
          <p>You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through the Service. However, by posting Content using the Service you grant us the right and license to use, modify, perform, display, reproduce, and distribute such Content on and through the Service consistent with these Terms.</p>
          <p>Codon Learning, Inc. has the right but not the obligation to monitor and edit all Content provided by users. Content found on or through this Service are the property of Codon Learning, Inc. or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</p>

          <h2 className="h4">Accounts</h2>
          <p>When you create an account with us, you represent that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service. If you are under the age of 18, you must have permission from a parent or legal guardian to create an account.</p>
          <p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
          <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar, or obscene.</p>
          <p>We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders at our sole discretion.</p>

          <h2 className="h4">Intellectual Property</h2>
          <p>The Service and its original content (excluding Content provided by users), features, and functionality are and will remain the exclusive property of Codon Learning, Inc. and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Codon Learning, Inc.</p>

          <h2 className="h4">Links To Other Websites</h2>
          <p> Our Service may contain links to third-party websites or services that are not owned or controlled by Codon Learning, Inc. Codon Learning, Inc. has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>
          <p>You acknowledge and agree that Codon Learning, Inc. shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third-party websites or services.</p>
          <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.</p>

          <h2 className="h4">Termination</h2>
          <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
          <p>If you wish to terminate your account, you may simply discontinue using the Service. The refund policy for the Service is described above.</p>
          <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

          <h2 className="h4">Indemnification</h2>
          <p>To the extent allowed by Colorado law and the U.S. Constitution, you agree to defend, indemnify, and hold harmless Codon Learning, Inc. and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password; b) a breach of these Terms; or c) Content posted on the Service.</p>

          <h2 className="h4">Limitation Of Liability</h2>
          <p>To the extent allowed by Colorado law and the U.S. Constitution, in no event shall Codon Learning, Inc., nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>

          <h2 className="h4">Disclaimer</h2>
          <p>Your use of the Service is at your sole risk. The Service is provided on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>
          <p>Codon Learning, Inc. its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, securely, or be available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>

          <h2 className="h4">Exclusions</h2>
          <p>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.</p>

          <h2 className="h4">Governing Law</h2>
          <p>These Terms shall be governed and construed in accordance with the laws of Colorado, United States, without regard to its conflict of law provisions.</p>
          <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.</p>

          <h2 className="h4">Changes</h2>
          <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
          <p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</p>

          <h2 className="h4">Contact Us</h2>
          <p>
            If you have any questions about these Terms, please contact us at {mailToLink(sharedStrings.CODON_INFORMATION_EMAIL)}.<br />
          </p>
          <br/>
          <Expando id="privacy" header={(<div className="user-agreement__privacy-button">Show Privacy Policy</div>)} expandedHeader={(<div className="user-agreement__privacy-button">Hide Privacy Policy</div>)}>
            <hr></hr>
            <br/>
            <h1 className="h2">
              Exhibit A: Codon Learning, Inc. Privacy Policy
            </h1>
            <br/>
            <h2 className="h4">Introduction</h2>
            <p>
              Codon Learning, Inc. (“Codon Learning” or “we” or “us”) has created this Privacy Policy in order to disclose its use of consumer data collected about you. This Privacy Policy is effective as of October 30, 2023. Any questions regarding this policy should be directed by email to {mailToLink(sharedStrings.CODON_INFORMATION_EMAIL)}. You may also contact Codon Learning at Codon Learning, Inc., 607 10th Street, Suite 303, Golden, CO  80401. The following discloses Codon Learning’s information gathering and dissemination practices for its applications and website.
            </p>
            <p>
              Codon Learning may be the Controller or Processor of your personal data provided to us by you or collected by us from you during your use of the website and/or applications, as outlined below. If you use the Codon Learning service, you consent to this policy and the use/sharing of your personal data as described herein. You have the choice to opt-out of sharing your data, as described below, and control how we use and share your data.
            </p>
            <h2 className="h4">Information Collected And Received</h2>
            <p>
              Codon Learning may collect or receive personal information about you, including, but not limited to, username, password, first and last name, email address, street address, instructor educational information (such as school name and location, instructor information, and course information), student ID, gender, occupation, and interests. You can choose what information you want to share with Codon Learning, however if you choose to withhold any personal data requested by us, it may not be possible for you to gain access to certain parts of the Codon Learning website and/or application. We collect personal information from you at several different points, including but not limited to the following:
            </p>
            <ul>
              <li>when we correspond with you as a customer or prospective customer;</li>
              <li>when you visit the website and/or application;</li>
              <li>when you register as an end-user of our service and an account is created for you;</li>
              <li>when you provide information, such as survey information;</li>
              <li>when you contact us for help; and</li>
              <li>when the website and/or application sends us error reports or application analytics data.</li>
            </ul>
            <p>Codon Learning does not require you to include sensitive information (e.g. race, ethnicity, first-generation student status, income level/pell grant eligibility, political opinions, religious or philosophical beliefs, trade union memberships, genetic data, biometric data, data concerning health or data concerning sexual orientation). In the event that you provide such sensitive information as listed above you acknowledge that you have provided Codon Learning explicit consent to receive such information.</p>
            <h2 className="h4">Lawful Basis For Processing</h2>
            <p>
              We will only collect and process your personal data where we have a lawful basis. Our lawful basis includes consent (where you have given it), where processing is necessary for the performance of a contract with you, and for the purposes of our legitimate interests or the legitimate interests of our third parties, provided that such interest does not outweigh your rights and freedoms. Examples of legitimate interest include but are not limited to: (i) complying with applicable law, (ii) protecting against security or other threats, (iii) administration of our business interests, including improvements and enhancements of our business, and (iv) customer relationship issues.
            </p>
            <h2 className="h4">Your Rights: Access, Correction, Deletion</h2>
            <p>
              We retain the personal data that you provide to us when you registered with the Codon Learning website and/or application or any other information that you volunteered while using the website and/or application for (i) as long as your account is active or (ii) otherwise for a limited period of time as long as we need to fulfill the purposes for which we have initially collected it, unless otherwise required by law. We retain any information provided as described in the Uses of Information below.
            </p>
            <p>
              If you are a student using our website and/or application as part of a required course adoption and you do not want to provide us with your name, you are able to create an account using an alias and a valid email address used to confirm your registration. It is your responsibility to inform your instructor of your alias, so that your instructor has access to your work, and does not remove you from their course.
            </p>
            <p>
              You have the right to request access to the information that Codon Learning has on you. You can do this by contacting us at {mailToLink(sharedStrings.CODON_INFORMATION_EMAIL)}. In order to comply with your request, we may have to verify your identity. If you believe that the information that we have about you is incorrect, you are able to contact us at {mailToLink(sharedStrings.CODON_INFORMATION_EMAIL)} with the change request. Any data that is no longer needed for the purposes specific below will be deleted. You have the right to request the deletion of any personal data that we have on you. If you wish to do so, please contact us at {mailToLink(sharedStrings.CODON_INFORMATION_EMAIL)}. Note that Codon Learning may not be able to delete any depersonalized data on you.
            </p>
            <h2 className="h4">
              How We Use Your Information
            </h2>
            <p>
              Codon Learning may use personal information that we collect about you to:
            </p>
            <ul>
              <li>deliver the products and services that you have requested;</li>
              <li>manage your customer relationship and provide you with customer support;</li>
              <li>perform analysis about your use of the website and/or application;</li>
              <li>communicate with you by email, postal mail, telephone and/or mobile devices about products or services that may be of interest to you;</li>
              <li>enforce our terms and conditions and other agreements between Codon Learning and you;</li>
              <li>manage our business;</li>
              <li>respond to investigation, court orders, legal process, or to investigate, prevent or take action regarding illegal activities, suspected fraud, or situations involving potential threats to the physical safety of any person, or as otherwise required by law; and</li>
              <li>perform functions as otherwise described to you at the time of collection.</li>
              <li>Codon Learning occasionally uses other companies to perform services necessary to our operations. In the course of providing these services, those companies may have access to your personal information. By contractual agreement, those companies must treat your information in accordance with this Privacy Policy. However, we will not be liable for any damages that may result from the misuse of your personal information by these companies.</li>
            </ul>
            <h2 className="h4">
              How We Share Your Information
            </h2>
            <p>
              (a) Personally Identifiable Information.
            </p>
            <p>
              Codon Learning will never sell your email address or any other personally identifiable information to any third party without your permission. We may store personally identifiable information in locations outside the direct control of Codon Learning (for example, on servers or databases co-located with hosting providers). Any personally identifiable information you elect to make publicly available on our websites and/or applications (such as posting comments, reviewing items, etc.) will be available to others. Except as described in this Section, we will not disclose your personally identifiable information to any third party except consultants working on our behalf, without notifying you of our intent to share the information and giving you an opportunity to prevent your information from being shared. From time to time, we may partner with companies based on the interests of our users. These partner companies will never see your email address or any other information that could identify you or be used to contact you directly.
            </p>
            <p>
              If you are using the website and/or application as integrated with a third-party Learning Management System (“LMS”), depending on the level of integration, we may receive and send your information through such third party. We are not responsible for how the third-party LMS treats your information.
            </p>
            <p>(b) Non-Personally Identifiable Information.</p>
            <p>The Codon Learning site registration form may require users to give Codon Learning contact information (such as your name and email address) and demographic information (such as your zip code, age, or income level). Codon Learning uses your contact information from the registration form to send you information about Codon Learning and promotional material from some of Codon Learning’s partners. Your contact information may also be used to contact you when necessary. Codon Learning may use demographic and/or profile data to tailor your experience on the Codon Learning website and/or applications, show you content that Codon Learning thinks you may be interested in, and display content according to your preferences. The demographic and profile data will be shared with third parties ONLY on an aggregate basis. Furthermore, Codon Learning shall have the right to retain records of all data pertaining to use of the website and applications including, but not limited to, usage, activity logs, and click-throughs. Codon Learning may disclose such data to third parties provided it is grouped with other Codon Learning users’ data and is presented in an aggregate form.</p>
            <p>(c) We May Be Required to Share Your Information.</p>
            <p>Codon Learning reserves the right to disclose information when required by law, such as by a subpoena or other legal proceedings. Codon Learning may also disclose your information if we reasonably believe it necessary to (i) comply with requests of law enforcement or other applicable law; (ii) to enforce any agreement between you and us; (iii) to protect the security and integrity of our site, applications, or services; and/or (iv) to protect us, our users or others.</p>
            <p>(d) What Happens in the Event of a Change of Control.</p>
            <p>We may be involved with an acquisition or divestiture of our business, products or services. Information we maintain including names and email addresses, and other information related to the Codon Learning service may be sold or transferred in connection with such transactions. We may also sell, assign, or otherwise transfer such information in connection with a sale of all or substantially all of our business or assets. You will be notified via email and/or a notice on our site of any applicable change in ownership or uses of your information.</p>
            <p>(e) Behavioral Targeting / Retargeting.</p>
            <p>We may allow advertisers to display advertising on our sites and applications. Our advertisers may use technology such as cookies to gather information about your activities on multiple sites in order to provide you advertising based upon your activities and interests. The only way to opt out of the collection of any information through cookies or other tracking technology is through the settings in your browser or mobile device. Please review your browser or mobile device information for instructions on how to delete and disable cookies, and other tracking/recording tools (to learn more about cookies, clear gifs/web beacons and related technologies, you may wish to visit {externalLink('http://www.allaboutcookies.org')} and/or the Network Advertising Initiative’s online resources, at {externalLink('http://www.networkadvertising.org')}). If you access the Codon Learning service, websites and/or applications on your mobile device, you may not be able to control tracking technologies through the settings. For more information, see the Cookies Section below.</p>
            <p>(f) Testimonials.</p>
            <p>We may also disclose, on an anonymous basis, statements made by our users. However, with your consent, we may post your testimonial along with your name. If at any point you wish to update or delete your testimonial, please contact us at {mailToLink(sharedStrings.CODON_INFORMATION_EMAIL)}.</p>
            <h2 className="h4">
              How We Store And Process Your Information
            </h2>
            <p>
              The information collected by Codon Learning may be processed and/or stored in the United States or any other country in which Codon Learning or its affiliates or service providers maintain operations. Codon Learning may transfer information that we collect, including personally identifiable information, to affiliated entities, or to other third parties across borders and from your country or jurisdiction of domicile to other countries or jurisdictions. If you are located in the European Union or other regions with laws restricting transferring of data, please note that we will comply with laws applicable to us.
            </p>
            <h2 className="h4">
              How We Protect Your Information
            </h2>
            <p>
              Codon Learning is concerned with protecting your privacy and data, and therefore we use industry-standard technologies when transferring and receiving consumer data exchanged between Codon Learning and other companies to help ensure its security. This site has security measures in place designed to protect the loss, misuse, and alteration of the information under Codon Learning’s control. Codon Learning’s servers are backed up regularly and protected by security systems. However, we cannot ensure or guarantee the security of any information you transmit to us or guarantee that your information on the Codon Learning service may not be accessed, disclosed, altered or destroyed by breach of any of our industry standard physical, technical, or managerial safeguards. If you have any questions about security on our website and/or application, you can contact us at {mailToLink(sharedStrings.CODON_INFORMATION_EMAIL)}. We may use an outside help platform, and a credit card processing company to bill you if you purchase services. These companies do not retain, share, store, or use personally identifiable information for any other purposes.
            </p>
            <h2 className="h4">
              Cookies
            </h2>
            <p>
              “Cookies” are small pieces of information that are stored by your browser on your computer's hard drive. Cookies, by themselves, do not tell us your e-mail address or other personally identifiable information unless you choose to provide this information to us by, for example, registering at our website and/or application. However, once you choose to furnish the website and/or application with personal information, this information may be linked to the data stored in the cookie. We use cookies to understand website and/application usage and to improve the content and service. We also may use cookies to offer you products or services. You have many choices with regards to the management of cookies on your computer. All major browsers allow you to block or delete cookies from your system. To learn more about your ability to manage cookies, please consult the privacy features in your browser.
            </p>
            <h2 className="h4">Links</h2>
            <p>
              Codon Learning may create links to other websites. Codon Learning will make a reasonable effort to link only to sites that meet similar standards for maintaining each individual's right to privacy. However, many other sites that are not associated or authorized by Codon Learning may have links leading to our site. Codon Learning cannot control these links and Codon Learning is not responsible for any content appearing on these sites.
            </p>
            <h2 className="h4">Children's Privacy</h2>
            <p>
              Our website and/or applications are intended for use by students enrolled in university or college courses, and the university employees involved in teaching those courses. We do not knowingly collect any information from anyone who we know to be under the age of 13. If you are under the age of 18, you should use this website only with the involvement of a parent or guardian and should not submit any personal information to us. If we discover that a person under the age of 13 has provided us with any personal information, we will use commercially reasonable efforts to delete such person's personal information from all Codon Learning systems.
            </p>
            <h2 className="h4">Compliance With The Family Education Rights & Privacy Act (FERPA)</h2>
            <p>
              The Family Educational Rights & Privacy Act, 20 U.S.C. § 1232(g), restricts the disclosure of personally identifiable information from a student’s education records, unless an exception applies. Our Privacy Policy and practices comply with FERPA and other applicable federal and state education privacy laws.
            </p>
            <p>
              We maintain the confidentiality of FERPA-covered student data, and only use this information to deliver and improve our sites provided to schools and their students. You may also have specific rights related to accessing and/or updating your personal information when FERPA applies. For further information about FERPA compliance, or to access, modify, or remove any such data, please contact us at {mailToLink(sharedStrings.CODON_INFORMATION_EMAIL)}.
            </p>
            <h2 className="h4">Your California Privacy Rights</h2>
            <p>
              Codon Learning does not currently respond to browser “Do Not Track” (DNT) signals or other mechanisms. Third parties may collect personal information about your online activities over time and across sites when you visit the website and/or application.
            </p>
            <p>If you are a California resident, California Civil Code Section 1798.83 permits you to request certain information regarding our disclosure of personal information to third parties for the third parties’ direct marketing purposes. To make such a request, please contact us by sending an email to {mailToLink(sharedStrings.CODON_INFORMATION_EMAIL)}.
            </p>
            <p>
              Our website and/or application are not intended to appeal to minors. However, if you are a California resident under the age of 18, and a registered user of our website and/or application, California Business and Professions Code Section 22581 permits you to request and obtain removal of content or information you have publicly posted. To make such a request, please send an email with a detailed description of the specific content or information to {mailToLink(sharedStrings.CODON_INFORMATION_EMAIL)}. Please be aware that such a request does not ensure complete or comprehensive removal of the content or information you have posted and that there may be circumstances in which the law does not require or allow removal even if requested.
            </p>
            <p>
              Under California law, California residents who have an established business relationship with us may opt-out of our disclosing personal information about them to third parties for their marketing purposes.
            </p>
            <h2 className="h4">International Transfer Of Your Personal Information</h2>
            <p>
              The website and/or application is hosted and operated entirely in the United States and is subject to United States law. Any personal information that we collect from you is currently stored and processed in the United States. If you are accessing the website and/or application outside of the U.S., you need to understand that by accessing our website and/or application, you consent to the transfer of your personal information to the United States. Please be advised that United States law may not offer the same privacy protections as the law in your jurisdiction.
            </p>
            <h2 className="h4">Amendments</h2>
            <p>
              Codon Learning may amend this policy at any time. If Codon Learning is going to use personally identifiable information collected through the Codon Learning site in a manner materially different from that stated at the time of collection, Codon Learning will notify users via email and/or by posting a notice on the Codon Learning site for thirty (30) days prior to such use. This Privacy Policy may not be otherwise amended except in writing that specifically refers to this Privacy Policy and is physically signed by both parties.
            </p>
            <h2 className="h4">Successors And Assigns</h2>
            <p>
              This Privacy Policy inures to the benefit of successors and assigns of Codon Learning.
            </p>
            <h2 className="h4">Contacts</h2>
            <p>
              If you have any questions about this Privacy Policy, the practices of this site, your dealings with this web site, or request access, changes or deletion to information we have about you, you can contact {mailToLink(sharedStrings.CODON_INFORMATION_EMAIL)}. Upon request, Codon Learning will provide you with access to information (e.g., name, address, phone number) that Codon Learning collects and maintains about you. This site gives you the following options for changing, modifying or removing information previously provided: (i) email: {mailToLink(sharedStrings.CODON_INFORMATION_EMAIL)}; or (ii) visit {externalLink(CodonUrls.CodonSite)}.
            </p>
            <h2 className="h4">Opt-out</h2>
            <p>
              The Codon Learning site provides users the opportunity to opt-out of receiving communications from Codon Learning, except transactional correspondence. To opt-out of receiving our communications, you can (i) send email to {mailToLink(sharedStrings.CODON_INFORMATION_EMAIL)}; or (ii) send postal mail to: Codon Learning, Inc., 607 10th Street, Suite 303, Golden, CO  80401.
            </p>
            <br/><br/><br/>
          </Expando>
          <Expando
            id="uga"
            show={!isCanada}
            header={(<div className="user-agreement__privacy-button">Show Additional and/or Changed Terms for University Of Georgia Users</div>)}
            expandedHeader={(<div className="user-agreement__privacy-button">Hide Additional and/or Changed Terms for University Of Georgia Users</div>)
            }
          >
            <br/><br/>

            <h2 className="h4">Links To Other Websites</h2>
            <p>Our Service may contain links to third-party websites or services that are not owned or controlled by Codon Learning, Inc. Codon Learning, Inc. has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>
            <p>You acknowledge and agree that Codon Learning, Inc. shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third-party websites or services.</p>
            <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.</p>
            <p><em>Notwithstanding the above, we shall ensure that any person to whom we subcontract our obligations under these Terms of Use (to whom we may sublicense our rights for that purpose) shall fully comply with the Terms of Use and we shall be liable for their acts and omissions as if they were our own.</em></p>

            <h2 className="h4">Termination</h2>
            <em>
              <p>Either party may terminate this agreement upon material breach of the other, if such breach is not cured within 30 days of receipt of written notice specifying the breach. If you wish to terminate your account, you may simply discontinue using the Service. The refund policy for the Service is described above.</p>
              <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
            </em>

            <h2 className="h4">Indemnification</h2>
            <p><em>There is no indemnification clause for UGA students.</em></p>

            <h2 className="h4">Responsibility</h2>
            <p><em>Each party will perform its activities under these Terms of Use at its own sole cost, risk, and responsibility with respect to third parties.  We acknowledge that you are subject to the Georgia Tort Claims Act which constitutes the exclusive remedy for any tort committed by your officers and employees. (refer to OCGA §50-21-25(a)). In the performance of these terms of Use, each Party shall be solely responsible for their  actions and failures to act including the actions or failures to act of their officers and employees and nothing herein shall be construed to place any responsibility upon a given Party for the actions or failures to act of the other Party or its officers and/or employees.</em></p>

            <h2 className="h4">Limitation Of Liability</h2>
            <p>In no event shall Codon Learning, Inc., nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages <em>exceeding three (3) times the amount payable hereunder</em>, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>

            <h2 className="h4">Governing Law</h2>
            <p>These Terms shall be governed and construed in accordance with the laws of <em>Georgia</em>, United States, without regard to its conflict of law provisions.</p>
            <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.</p>
            <br/><br/>
          </Expando>

          <Expando
            id="wisconsin-milwaukee"
            header={(<div className="user-agreement__privacy-button">Show Additional and/or Changed Terms for University Of Wisconsin-Milwaukee Users</div>)}
            expandedHeader={(<div className="user-agreement__privacy-button">Hide Additional and/or Changed Terms for University Of Wisconsin-Milwaukee Users</div>)}
          >
            <>
              <br/><br/>

              <h2 className="h4">Indemnification</h2>
              <p><em>The Board of Regents of the University of Wisconsin System agrees to provide liability protection for its officers, employees and agents while acting within the scope of their employment. The Board of Regents further agrees to hold harmless Codon Learning, its officers, agents and employees from any and all liability, including claims, demands, losses, costs, damages and expenses of every kind and description (including death), or damages to persons or property arising out of or in connection with or occurring during the course of this agreement where such liability is founded upon and grows out of the acts or omissions of any of the officers, employees or agents of the University of Wisconsin System while acting within the scope of their employment where protection is afforded by ss.893.82 and 895.46(1), Wis. Stats.</em></p>

              <h2 className="h4">Governing Law</h2>
              <p>These Terms shall be governed and construed in accordance with the laws of <em>Wisconsin</em>, United States, without regard to its conflict of law provisions.</p>
              <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.</p>

              <h2 className="h4">Changes</h2>
              <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
              <p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</p>
              <p><em>The University will not be bound to any other terms and conditions set forth in any documents, agreements or policies posted on Codon Learning’s website unless such terms and conditions are set forth in this agreement or agreed upon in writing by UWM.</em></p>

              <h2 className="h4">Renewal</h2>
              <em>
                <p>This rider shall continue in effect during any renewal of the agreement. If the above referenced items change paragraph number in any future iteration of the Agreement, then such Agreement shall be subject to the intent of these modifications regardless of the paragraph numbering scheme.</p>
              </em>
            </>
          </Expando>

          <br/>
        </div>
        <div className="userAgreement__action">
          <button className="agreement__cancel-button" onClick={cancelUserAgreement}>Cancel</button>
          <button className="primary-button agreement__agree-button" onClick={doUserAgreement}>Agree</button>
        </div>
      </div>
    </main>
  );
}
