import React, { useRef } from 'react';
import { DateTime, Interval } from 'luxon';
import { useAppSelector } from 'store';
import { useGetNotificationsQuery } from 'api-next';
import ToasterNotification, { ToasterFlavorEnum } from './ToasterNotification';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { RoleEnum } from 'types/backend/roles.types';
const pollingInterval = Number(process.env.REACT_APP_NOTIFICATION_POLLING_INTERVAL_MINUTES || 15) * 60000;

// NOTE: If we change this interface, we also need to update the notification-schema in system-notifications repo
export interface NotificationData {
  id: number
  message: string
  notificationStart: string // ISO date time to START showing notification
  notificationEnd: string // ISO date time to STOP showing notification
  downtimeStart?: string
  downtimeDuration?: { minutes: number }
  showToRoles?: Array<RoleEnum> // If undefined or empty array, show to all roles and on login page
}

function SystemNotification() {
  const userRoleId = useAppSelector((store) => store.user?.roleId); // userRoleId is optional bc this can show on login page
  const timestampRef = useRef(Date.now()).current;
  const { data: notificationsData = [] } = useGetNotificationsQuery(timestampRef, { pollingInterval });

  const activeNotifications = notificationsData.filter((notification) => {
    const { notificationStart, notificationEnd, showToRoles = [] } = notification || {};
    const startLuxon = DateTime.fromISO(notificationStart).toLocal();
    const endLuxon = DateTime.fromISO(notificationEnd).toLocal();
    const showNotificationInterval = Interval.fromDateTimes(startLuxon, endLuxon);
    const showBecauseRole = !showToRoles.length || (!!userRoleId && showToRoles.includes(userRoleId));
    return showBecauseRole && showNotificationInterval.contains(DateTime.now());
  });

  const renderNotification = ({
    downtimeStart,
    downtimeDuration,
    message,
    notificationStart,
    notificationEnd,
  }: NotificationData) => {
    let messageString = message;
    if (downtimeStart && downtimeDuration) {
      const downtimeStartLuxon = DateTime.fromISO(downtimeStart).toLocal();
      const downtimeDate = downtimeStartLuxon.toFormat('EEEE M/d');
      const timeStart = downtimeStartLuxon.toFormat(DateFormatEnum.TimeMeridian);
      const timeEnd = downtimeStartLuxon.plus(downtimeDuration).toFormat(DateFormatEnum.TimeMeridian);
      const timeZone = downtimeStartLuxon.toFormat('ZZZZ');
      messageString = `${message}. This event will occur ${downtimeDate} between ${timeStart} and ${timeEnd} ${timeZone}.`;
    }
    return (
      <span data-notification-start={notificationStart} data-notification-end={notificationEnd}>
        {messageString}
      </span>
    );
  };

  return (
    <>
      {activeNotifications.map((notification) => (
        <ToasterNotification
          key={notification.id}
          id={`system__${notification.id}`}
          flavor={ToasterFlavorEnum.Downtime}
          message={<>{renderNotification(notification)}</>}
        />
      ))}
    </>
  );
}

export default SystemNotification;
