import { AssessmentApiBase, SummativeAssessmentApi } from 'types/backend/assessments.types';
import { CreateAssessmentBody, CreateSummativeAssessmentBody } from 'types/common.types';


const validateAssessmentPublishedUpdatesForWorker = (
  prevAssessment: AssessmentApiBase | SummativeAssessmentApi,
  newAssessment: CreateAssessmentBody | CreateSummativeAssessmentBody
): boolean => {
  // © Hope's conditions for topicCard updates:
  //  assessment published has changed
  return prevAssessment.published !== newAssessment.published;
};

export default validateAssessmentPublishedUpdatesForWorker;
