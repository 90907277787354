import apiNext from 'api-next';
import { AppDispatch, Store } from 'types/store.types';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import activeSlice from 'store/slices/active';

export default function updateUserQuestionLosForTemplateQuestion(
  questionId: number,
  learningObjectiveIds: Array<number>,
  qLoIdsToRemove?: Array<number>
) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active: { templateQuestions }, user } = getStore();
    const { learningObjectiveIds: currentLoIds = [] } = templateQuestions.find((tq) => tq.id === questionId) || {};

    // Add user learning objectives to template questions
    if (!!learningObjectiveIds.length) {
      const qlosToCreate = learningObjectiveIds.map(loId => ({
        questionId,
        learningObjectiveId: loId,
        type: LibraryTypeEnum.User,
        userId: user.id,
      }));
      const result = await apiNext.createUserQuestionLos(qlosToCreate);
      console.debug('createUserQuestionLos result', result);
    }

    let removedLoIds: Array<number> = [];
    if (!!qLoIdsToRemove) {
      // removal has to be dealt with atomically
      const removeResult = await Promise.all(qLoIdsToRemove.map((qLoId) => apiNext.deleteUserQuestionLo(qLoId)));
      console.debug('deleteUserQuestionLo result', removeResult);
      removedLoIds = removeResult.map(qlo => qlo.learningObjectiveId);
    }
    let netLoIds = currentLoIds.concat(learningObjectiveIds).filter(loId => !removedLoIds.includes(loId));
    netLoIds = [...new Set(netLoIds)];

    return dispatch(activeSlice.actions.editActiveTemplateQuestion({
      questionId,
      delta: {
        learningObjectiveIds: netLoIds,
      },
    }));
  })();
}
