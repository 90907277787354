// sourced from https://github.com/codonlearning/backend/blob/dev/src/services/studentAssessmentQuestions/studentAssessmentQuestions.types.ts
import { YesNo } from 'types/backend/shared.types';
import { StudentAssessmentQuestionAttemptApi } from 'types/backend/studentAssessmentQuestionAttempts.types';
import { StudentAssessmentDb } from 'types/backend/studentAssessments.types';

export enum FirstAttemptedEnum {
  Never = 'never',
  BeforeDue = 'before_due',
  BeforeLate = 'before_late',
  AfterLate = 'after_late'
}

interface StudentAssessmentQuestionDbBase {
  id: number
  student_assessment__id: number
  assessment_question__id: number
  free_play: YesNo
  vat_frozen: YesNo
  points_available_to_recap: number | null
  graded_adjusted_points_earned: number | null
  graded_student_assessment_question_attempt__id: number | null
  latest_student_assessment_question_attempt__id: number | null
  first_attempted: FirstAttemptedEnum
  late_points_deducted: number | null
  is_ever_correct: YesNo  // did the student ever get the question correct?
}

export interface StudentAssessmentQuestionDb extends StudentAssessmentQuestionDbBase {
  latest_vat_student_assessment_question_attempt__id: number | null
  created_at: string
  updated_at: string
}

export interface StudentAssessmentQuestionApi {
  id: number
  studentAssessmentId: number
  assessmentQuestionId: number
  gradedStudentAssessmentQuestionAttemptId: number | null
  latestStudentAssessmentQuestionAttemptId: number | null
  latestVatStudentAssessmentQuestionAttemptId: number | null
  pointsAvailableToRecap: number | null
  freePlay: YesNo
  vatFrozen: YesNo
  gradedAdjustedPointsEarned: number | null
  createdAt: string
  updatedAt: string
  firstAttempted: FirstAttemptedEnum
  latePointsDeducted: number | null
  isEverCorrect: YesNo
}

export interface StudentAssessmentQuestionApiWithSaqas extends StudentAssessmentQuestionApi {
  gradedStudentAssessmentQuestionAttempt: StudentAssessmentQuestionAttemptApi | null
  latestStudentAssessmentQuestionAttempt: StudentAssessmentQuestionAttemptApi | null
  latestVatStudentAssessmentQuestionAttempt: StudentAssessmentQuestionAttemptApi | null
}

export type StudentAssessmentQuestionDbEnrollmentId = StudentAssessmentQuestionDbBase & Pick<StudentAssessmentDb, 'enrollment__id'>

export type StudentAssessmentQuestionApiForPatch = Partial<Pick<StudentAssessmentQuestionApi,
  'id' |
  'freePlay' |
  'vatFrozen' |
  'gradedAdjustedPointsEarned' |
  'pointsAvailableToRecap' |
  'gradedStudentAssessmentQuestionAttemptId' |
  'latestStudentAssessmentQuestionAttemptId' |
  'latestVatStudentAssessmentQuestionAttemptId' |
  'firstAttempted' |
  'latePointsDeducted' |
  'isEverCorrect'
>>

export interface NewSaqValuesForDateChange {
  newFreePlay: YesNo
  newVatFrozen: YesNo
  newGradedAdjustedPointsEarned: number | null
  newPointsToRecap: number | null
  newGradedStudentAssessmentQuestionAttemptId: number | null
  newLatePointsDeducted: number | null
  newFirstAttempted: FirstAttemptedEnum
}
