import { DateTime } from 'luxon';

// if a luxon format is used more than once it should be added to this enum
export enum DateFormatEnum {
  FullWeekdayMonthDate = 'EEEE, MMMM d',                 // Friday, August 5
  ShortMonthDate = 'MMM d, yyyy',                        // Aug 5, 2022
  DateAtTimeMeridean = 'M/d/yy\' at \'h:mm a',           // 8/5/22 at 10:52 AM
  DateShortYear = 'M/d/yy',                              // 8/5/22
  DateLongYear = 'M/d/yyyy',                             // 8/5/2022
  TimeMeridian = 'h:mm a',                               // 10:52 AM
  WeekdayWithLongDateFullYear = 'EEEE M/d/yyyy',         // Friday 8/5/2022
  WeekdayWithShortDateFullYear = 'EEE M/d/yyyy',         // Fri 8/5/2022
  WeekdayWithShortDateNoYear = 'EEE M/d\' at \'h:mm a',  // Fri 8/5 at 11:26 AM
  WeekdayMonthDate = 'EEE, MMM d',                       // Fri, Aug 5
  WeekdayMonthDateShort = 'EEE M/d',                     // Fri, 8/5
  WeekdayDateAtTime = 'EEEE MM/dd/yy\' at \'h:mm a',     // Friday 08/05/22 at 11:26 AM
  MonthDay = 'M/d',                                      // 8/25
  WeekdayDateAtHour = 'EEEE MM/dd/yy\' at \'h a',        // Friday 08/05/22 at 11 AM
  TimeZoneShort = 'ZZZZ',                                // MST
  DatePickerDisplayFormat = 'LLLL d, yyyy h:mm a',       // August 5, 2022 11:59 PM
}

export function daysUntilExam(examDate: string) {
  const asDate = DateTime.fromISO(examDate);
  let diff = asDate.diffNow().as('day');
  diff = Math.round(diff);
  return diff >= 0 ? diff : 0;
}

export function standardDateFormat(dateTime: string | DateTime) {
  const dt = typeof dateTime === 'string' ? DateTime.fromISO(dateTime) : dateTime;
  return dt.toFormat(DateFormatEnum.DateShortYear);
}

export function standardTimeFormat(dateTime: string | DateTime) {
  const dt = typeof dateTime === 'string' ? DateTime.fromISO(dateTime) : dateTime;
  return dt.toFormat(DateFormatEnum.TimeMeridian);
}

export function dateAtTimeFormat(dateTime: string | DateTime) {
  return `${standardDateFormat(dateTime)} at ${standardTimeFormat(dateTime)}`;
}

export function isInThePast(testDate: string) {
  const now = DateTime.local();
  const comparisonDate = DateTime.fromISO(testDate);
  return comparisonDate < now;
}
