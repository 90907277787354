import React from 'react';
import PropTypes from 'prop-types';

import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import Icon, { IconEnum } from 'shared-components/Icon';
import { AttemptBarData } from '../../studentScores.types';
import { PositionEnum } from 'types/common.types';
import './AttemptDataBar.scss';

interface AttemptDataBarProps {
  attemptData: AttemptBarData | null
  attemptedText: string
  iconName: IconEnum
}

function AttemptDataBar(props: AttemptDataBarProps) {
  const { attemptData, attemptedText, iconName } = props;
  if (attemptData === null) {
    return (
      <></>
    );
  }

  const { numerator, denominator, percentage } = attemptData;
  const tooltipText = denominator > 0
    ? `${numerator} of ${denominator}${attemptedText}`
    : 'No students have attempted this item.';

  const width = `${percentage}%`;
  const noDataStyle = !!denominator
    ? ''
    : 'no-data-style';

  return (
    <BetterTooltip
      content={tooltipText}
      position={PositionEnum.BottomRight}
    >
      <div className="attempt-data-bar-chart-container" >
        <div className="attempt-data-percentage-display">
          <Icon which={iconName} size={18} />
        </div>
        <div className={`attempt-data-bar-container ${noDataStyle}`}>
          <div className="attempt-data-bar" style={{ width }}/>
        </div>
        <div className="attempt-data-percentage-display">
          {!!denominator && <>{percentage.toFixed(0)}%</>}
        </div>
      </div>
    </BetterTooltip>
  );
}

AttemptDataBar.propTypes = {
  attemptData: PropTypes.object,
  attemptedText: PropTypes.string.isRequired,
  iconName: PropTypes.oneOf(Object.values(IconEnum) as Array<IconEnum>).isRequired,
};

export default AttemptDataBar;
