// sourced from https://github.com/codonlearning/backend/blob/dev/src/services/assessmentInit/assessmentInit.types.ts
import { AssessmentLocation, GenericObject } from 'types/backend/shared.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { L8ySessionState } from 'types/backend/l8ySessions.types';
import { L8yBasicItem } from 'types/backend/questions.types';

export enum AssessmentModeEnum {
  SubmitPractice = 'submit_practice',
  LocalPractice = 'local_practice',
}

export enum RenderingTypeEnum {
  Assess = 'assess',
  Inline = 'inline',
}

interface L8yItemForItemsApi {
  id: string
  reference: string
  item_pool_id?: string
}

export interface AssessmentInitApi {
  assessmentType: AssessTypeEnum | null // null for preview
  activityId: string // 'instructor-view' if instructor viewing as student
  assessmentMode: AssessmentModeEnum
  items: Array<L8yBasicItem>
  name: string
  userId: string
  renderingType: RenderingTypeEnum
  initState?: L8ySessionState
  location: AssessmentLocation
  l8ySessionId?: string
}

export interface L8yItemsApiRequest {
  organisation_id: number
  rendering_type: RenderingTypeEnum
  activity_id: number | string
  session_id: string
  items: Array<L8yItemForItemsApi>
  name: string
  user_id: string
  type: AssessmentModeEnum
  config: GenericObject
}
