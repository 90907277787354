import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import addIclrToClassSessions from './addIclrToClassSessions';
import { ClrData } from 'types/common.types';
import { AppDispatch, Store } from 'types/store.types';

export default function createIclrAndAddToClassSession(
  classSessionIds: Array<number>,
  title: string,
  type: string,
  userId: string,
  url: string | null
) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const iclrData = {
      title,
      url,
      type,
      userId,
    } as ClrData;
    const createdIclr = await apiNext.createIclr(iclrData);
    dispatch(activeSlice.actions.addActiveIclr(createdIclr));

    dispatch(addIclrToClassSessions(classSessionIds, createdIclr.id));
  })();
}
