import React from 'react';
import { useSelector } from 'react-redux';
import { PieChart } from 'react-minimal-pie-chart';
import PropTypes from 'prop-types';

import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import sharedStrings from 'sharedStrings';
import { MappedAssessmentQuestion } from 'types/common.types';
import styles from 'style-config.scss';
import './Charts.scss';

const BloomsChartProps = {
  currentAssessment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assessType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    classSessionIds: PropTypes.array,
  }).isRequired,
};

function BloomsChart(props: PropTypes.InferProps<typeof BloomsChartProps>) {
  const { currentAssessment } = props;
  const assessmentQuestionMaps: Array<MappedAssessmentQuestion> = useSelector(retrieveActiveAssessmentQuestionMaps);
  const assessmentAqms = assessmentQuestionMaps.filter((aqm) => aqm.assessmentId === currentAssessment.id);

  const highBloomsThreshold = 3;
  const highBloomsCount = assessmentAqms.filter((aqm) => aqm._derived.questionData.blooms >= highBloomsThreshold).length;
  const lowBloomsCount = assessmentAqms.length - highBloomsCount;
  const pieChartData = [
    { title: sharedStrings.HIGH_BLOOMS_LABEL, value: highBloomsCount, color: styles.blue },
    { title: sharedStrings.LOW_BLOOMS_LABEL, value: lowBloomsCount, color: styles.purple },
  ];

  const pieChartDataNoZero = pieChartData.filter(({ value }) => value > 0);

  const lineWidth = 55;
  return (
    <div>
      <div className="piechart-legend">
        {
          pieChartData.map(({ title, color }) => (
            <div className="piechart-legend__label" key={title}>
              <div className="piechart-legend__color" style={{ backgroundColor: color }}>&nbsp;</div>
              <div className="piechart-legend__title">{title}</div>
            </div>
          ))
        }
      </div>
      <PieChart
        style={{
          fontFamily: '"Open Sans", sans-serif',
          fontSize: '8px',
        }}
        lineWidth={lineWidth}
        radius={40}
        startAngle={180}
        label={({ dataEntry }) => dataEntry.value}
        labelPosition={100 - lineWidth / 2}
        labelStyle={{
          fill: styles.white,
          opacity: 0.75,
          pointerEvents: 'none',
        }}
        data={pieChartDataNoZero}
      />
    </div>
  );
}

BloomsChart.propTypes = BloomsChartProps;

export default BloomsChart;
