/***
 * l8yContainerFunctions
 * This function takes an assessment's status and determines whether the finish button should show or not and whether or not it should be disabled when shown
 * The documentation for this logic is here: https://docs.google.com/document/d/13abJF5ypPEjRDpgND4rABGavStEM7ttQxaaws8o-Cy8/edit?usp=sharing
 * tl;dr
 *   - in the SPAT, only enable the finish button when all questions are answered correct
 *   - in the VAT, enable the finish button when all questions are answered, regardless of correctness
 */
import styled from 'styled-components';
import { YesNo } from 'types/backend/shared.types';
import { QuestionStatusHash } from './LearnosityContainer.types';
import { L8yProps } from './LearnosityContainer';

export const determineFinishButtonState = (isStudyPath: boolean, {
  activeL8yRef,
  questionStatusHash,
  todoQuestionL8yIds,
  allQuestionsFrozenOrAttempted,
}: {
  activeL8yRef: string | null
  questionStatusHash: QuestionStatusHash
  todoQuestionL8yIds?: Array<string>
  allQuestionsFrozenOrAttempted?: boolean
}) => {
  if (!activeL8yRef) {
    return {
      enableFinish: false,
      showFinish: false,
    };
  }
  const activeQuestionIsCorrect = questionStatusHash[activeL8yRef] && questionStatusHash[activeL8yRef].isCorrect === YesNo.Yes;
  const allNonSelectedQuestions = Object.keys(questionStatusHash).filter((l8yId) => l8yId !== activeL8yRef);
  const questionStatusArray = Object.values(questionStatusHash);

  // SPAT finish button logic
  if (isStudyPath && todoQuestionL8yIds) {
    // in SPAT, only show finish if all answered AND correct
    const isLastTodo = [...todoQuestionL8yIds].pop() === activeL8yRef;
    const allAnsweredQuestions = questionStatusArray.filter(({ isCorrect }) => !!isCorrect);
    const allQuestionsHaveBeenAnswered = todoQuestionL8yIds.map((l8yId) => questionStatusHash[l8yId]).every(({ isCorrect }) => !!isCorrect);
    // handle instances where a user skips a question then goes back and gets the question correct
    const allOtherQuestionsAreCorrectButNotCurrentQuestion = !activeQuestionIsCorrect && allNonSelectedQuestions.every((l8yId) => questionStatusHash[l8yId].isCorrect === YesNo.Yes);
    const allQuestionsHaveBeenAnsweredCorrectly = allQuestionsHaveBeenAnswered && allAnsweredQuestions.every(({ isCorrect }) => isCorrect === YesNo.Yes);
    // if all questions are correct show finish regardless of which question the user is on currently
    if (allQuestionsHaveBeenAnsweredCorrectly) {
      return {
        enableFinish: true,
        showFinish: true,
      };
    } else if (isLastTodo || allOtherQuestionsAreCorrectButNotCurrentQuestion) {
      // still show disabled finish btn if on last q (or last unanswered q) and all qs have correct answers
      return {
        enableFinish: false,
        showFinish: true,
      };
    }
    return {
      enableFinish: false,
      showFinish: false,
    };
  }

  // VAT finish button logic
  const allQuestionsHaveBeenAnswered = questionStatusArray.every(({ isCorrect }) => !!isCorrect);
  const allL8yIds = Object.keys(questionStatusHash);
  const isLastQuestion = [...allL8yIds].pop() === activeL8yRef;
  const allOtherQuestionsHaveBeenAnswered = allNonSelectedQuestions.map((l8yId) => questionStatusHash[l8yId]).every(({ isCorrect }) => !!isCorrect);
  if (allQuestionsHaveBeenAnswered || allQuestionsFrozenOrAttempted) {
    return {
      enableFinish: true,
      showFinish: true,
    };
  } else if (isLastQuestion || allOtherQuestionsHaveBeenAnswered) {
    return {
      enableFinish: false,
      showFinish: true,
    };
  }
  return {
    enableFinish: false,
    showFinish: false,
  };
};

export const distractorRationaleTemplate = (id: number, isCorrect: YesNo, content: string) => {
  const valid = isCorrect === YesNo.No ? 'Incorrect' : 'Correct';
  const validForCss = valid.toLowerCase();
  return `
    <div id="#${id}_distractor" class="lrn_distractor_rationale_wrapper" role="region" aria-label="distractor rationale">
      <div class="lrn_distractor_rationale_list lrn_distractor_rationale_${validForCss}" aria-label="distractor rationale per response ${validForCss}">
        <div class="lrn_distractor_rationale_list_title">${valid}</div>
        ${!content ? '' : `
          <div class="lrn_distractor_rationale">
            <div class="lrn_distractor_rationale_content">${content}</div>
          </div>
        `}
      </div>
    </div>
  `;
};

// <LearnosityWrap>
export const LearnosityWrap = styled.div<{
  assessmentType: L8yProps['assessmentType']
  attribution: string | false
  inReviewMode: boolean
  pointsString: string | null
  questionItems: string | null
  questionStatusHash: QuestionStatusHash
}>`
  .debug__learnosity {
    position: fixed;
    bottom: 0;
    right: 0;
    visibility: hidden;
  }
  .lrn-assess-content.content.lrn div.row {
    &::before {
      visibility: ${p => p.questionItems === null ? 'hidden' : 'visible'};
      content: '${p => p.questionItems}';
    }
  }
  .lrn-assess-content.content.lrn {
    &::before {
      visibility: ${p => p.pointsString === null ? 'hidden' : 'visible'};
      content: '${p => p.pointsString}';
    }
    &::after {
      visibility: ${p => p.attribution ? 'visible' : 'hidden'};
      content: "${p => p.attribution ? p.attribution : ''}";
    }
  }
`;
// </LearnosityWrap>
