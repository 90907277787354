/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import BetterButton from 'shared-components/BetterButton/BetterButton';
import ConfirmationPromptContainer from 'shared-components/ConfirmationPrompt/ConfirmationPromptContainer';
import { ConfirmationTypeEnum } from 'types/common.types';
import { QuestionUsageData } from '../QuestionBuilderController.types';
import { Store } from 'types/store.types';
import './EditQuestionConfirmation.scss';


export interface EditQuestionConfirmationData {
  assessmentId: string
  selectedQuestionId: number
  selectedLoIds: Array<number>
  classSessionId: number | null
}

export default function EditQuestionConfirmation({
  handleContinue,
  handleCopyQuestion,
  handleClose,
  questionUsageData,
}: {
  handleContinue: () => void
  handleCopyQuestion: () => Promise<void>
  handleClose: () => void
  questionUsageData: Array<QuestionUsageData>
}) {
  const currentCourse = useSelector((store: Store) => store.active.course);
  const currentCourseId = currentCourse?.id;

  return (
    <ConfirmationPromptContainer
      confirmationType={ConfirmationTypeEnum.Warn}
      title="Some students have started"
      handleCancel={handleClose}
    >
      <div className="edit-question-confirmation">
        Students have started assessment(s) with this question:
        <ul className="edit-question-confirmation__assessment_list">
          {questionUsageData.map((questionUsage) => (
            <li key={questionUsage.assessmentId}>
              <em>{questionUsage.assessmentName}</em>
              {questionUsage.courseId !== currentCourseId && (
                <>
                  &nbsp;in course <em>{questionUsage.courseName}</em>
                </>
              )}
            </li>
          ))}
        </ul>
        Edits will immediately be pushed to students, including those who have already answered the question.
        <br /><br />
        What would you like to do?
      </div>
      <div className="confirmation-modal__button-bar edit-question-confirmation">
        <BetterButton
          className="confirmation-button"
          primary
          data-dismiss="confirmation-modal"
          onClick={handleCopyQuestion}
          text='Make a Copy'
          autoFocus
        />
        <div className="edit-question-confirmation__explanatory-text">
          Recommended when updating for the future.
        </div>
        <BetterButton
          className="secondary-button"
          onClick={handleContinue}
          secondary
          text='Edit this Version'
        />
        <div className="edit-question-confirmation__explanatory-text">
          Generally not recommended when making substantive edits. You may want to inform students of changes.
        </div>
      </div>
    </ConfirmationPromptContainer>
  );
}

EditQuestionConfirmation.propTypes = {
  handleContinue: PropTypes.func.isRequired,
  handleCopyQuestion: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  questionUsageData: PropTypes.arrayOf(PropTypes.object).isRequired,
};


