import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FaPlusCircle, FaChevronCircleDown } from 'react-icons/fa';
import useOnClickOutside from 'hooks/useOnClickOutside';
import ClassLabelIcon from 'shared-components/ClassLabelIcon/ClassLabelIcon';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import { InstructionEnum } from 'types/common.types';
import './DailyCard.scss';

type DailyCardType = AssessTypeEnum | InstructionEnum | ClassTypeEnum | 'lo-card';

interface DailyCardButton {
  label: string
  onClick?: (cardType?: DailyCardType) => void
  url?: string
  show?: boolean
}

const DailyCard = ({
  cardButton,
  cardType,
  children,
  className,
  empty = false,
  header,
  hideColumnLabels = false,
  show = true,
}: {
  cardButton?: DailyCardButton | Array<DailyCardButton>
  cardType?: DailyCardType
  children?: React.ReactNode | Array<React.ReactNode>
  className: string
  empty?: boolean
  header: React.ReactNode | string
  hideColumnLabels?: boolean
  show?: boolean
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const popupRef = useRef(null);
  useOnClickOutside(popupRef, () => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  });
  if (!show) {
    return null;
  }
  const columnLabelsShouldHide = hideColumnLabels || empty;
  const doMenu = Array.isArray(cardButton);

  // standardized render method for all buttons, whether they be in a menu or a single header button
  const renderButton = (btn: DailyCardButton, showIcon = true) => {
    const { onClick, url, label = 'Add', show: showBtn = true } = btn || {};
    if (!showBtn) {
      return null;
    }
    if (!!onClick) {
      return (
        <button className="daily-card__action-button" onClick={() => onClick(cardType)}>
          <FaPlusCircle size={20} />
          <div className="daily-card__action-button-label">{label}</div>
        </button>
      );
    } else if (!!url) {
      return (
        <Link className="daily-card__action-button" to={url}>
          <FaPlusCircle size={20} />
          <div className="daily-card__action-button-label">{label}</div>
        </Link>
      );
    }
    return null;
  };

  if (!!cardType && Object.values(ClassTypeEnum).includes(cardType as ClassTypeEnum)) {
    const classType = cardType as ClassTypeEnum;
    return (
      <div key={cardType} data-cardtype={cardType} className="daily-card labeled-class-card">
        <div className="labeled-class-card__icon">
          <ClassLabelIcon classType={classType} />
        </div>
        <div>
          <div className="labeled-class-card__label">{header}</div>
          <div className="labeled-class-card__message">
            {classType === ClassTypeEnum.Special && 'There is no class today'}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div key={cardType} data-cardtype={cardType} className={`daily-card ${className} ${empty && 'empty-card'}`}>
      <div className={`daily-card__header ${columnLabelsShouldHide && 'hide-column-labels'}`}>
        {typeof header === 'string'
          ? <h3 className="daily-card__header-title">{header}</h3>
          : header
        }
        {!!cardButton && (
          <div className="daily-card__action">
            {!doMenu
              ? renderButton(cardButton)
              : (
                <button className="daily-card__action-button" onClick={() => setMenuOpen(!menuOpen)}>
                  {menuOpen
                    ? <FaChevronCircleDown size={20} />
                    : <FaPlusCircle size={20} />
                  }
                  <div className="daily-card__action-button-label">Add</div>
                </button>
              )
            }
          </div>
        )}
        {menuOpen && doMenu && (
          <div ref={popupRef} className="daily-card__action-popup-wrap">
            <ul className="daily-card__action-popup">
              {cardButton.map((btn) => (
                <li key={btn.label}>
                  {renderButton(btn, false)}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {!empty && (
        <div className="daily-card__body">
          {children}
        </div>
      )}
    </div>
  );
};

DailyCard.propTypes = {
  className: PropTypes.string.isRequired,
  header: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default DailyCard;
