import React from 'react';
import { useSelector } from 'react-redux';
import { IoIosArrowForward } from 'react-icons/io';

import { getUnitName } from 'utils/commonFormattingFunctions';
import CheckboxItem from 'shared-components/CheckboxItem/CheckboxItem';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { ExpandSubjectOrUnit, SelectUnitOrTopic } from '../LoLibrary';
import { SubjectApi } from 'types/backend/subjects.types';
import { TopicApi } from 'types/backend/topics.types';
import { Store } from 'types/store.types';
import { UnitApi } from 'types/backend/units.types';
import './LoLibraryFilter.scss';

function LoLibraryFilter({
  expandedUnitIds,
  onSelectedToggle,
  selectedFilters,
  expandedSubjectIds,
  onExpandedToggle,
  topicsForFilter,
}: {
  expandedSubjectIds: Array<number>
  expandedUnitIds: Array<number>
  onSelectedToggle: (unitOrTopic: SelectUnitOrTopic, unitOrTopicId: number) => void
  selectedFilters: {
    selectedUnitIds: Array<number>
    selectedTopicIds: Array<number>
  }
  onExpandedToggle: (subjectOrUnit: ExpandSubjectOrUnit, subjectOrUnitId: number) => void
  topicsForFilter: Array<TopicApi>
}) {
  const user = useSelector((store: Store) => store.user);
  const instructors = useSelector((store: Store) => store.active.instructors);
  const units = useSelector((store: Store) => store.active.units);
  const course = useSelector((store: Store) => store.active.course);
  const subjects = useSelector((store: Store) => store.passive.subjects);
  const primarySubjectId = course.subjectId;
  const primarySubject = subjects.find(s => s.id === primarySubjectId) as SubjectApi;
  const additionalSubjects = subjects.filter(s => course.additionalSubjectIds.includes(s.id));

  const availableSubjectIds = [primarySubjectId, ...course.additionalSubjectIds];
  const customUnits = units.filter(u => u.type === LibraryTypeEnum.User && availableSubjectIds.find(s => s === u.subjectId));

  const { selectedUnitIds, selectedTopicIds } = selectedFilters;

  const renderUnitForSubject = (subjectName: string, unitItem: UnitApi) => {
    const unitTopics = topicsForFilter.filter((t) => t.unitId === unitItem.id);
    const unitHasNoTopics = !unitTopics.length;
    const unitName = getUnitName({ unit: unitItem, unitBelongsToThisUser: unitItem.userId === user.id, instructors, subjectName });
    return (
      <div
        key={unitItem.stringId}
        className="lo-library-filter__unit__wrap"
        data-stringid={unitItem.stringId}
        data-expanded={expandedUnitIds.includes(unitItem.id)}
      >
        <div className="lo-library-filter__unit">
          <button
            className="lo-library-filter__unit-expand"
            data-hidden={unitHasNoTopics}
            data-expanded={expandedUnitIds.includes(unitItem.id)}
            onClick={() => onExpandedToggle(ExpandSubjectOrUnit.Unit, unitItem.id)}
          >
            <IoIosArrowForward />
          </button>
          <CheckboxItem
            className="lo-library-filter__unit-name"
            disabled={unitHasNoTopics}
            disabledHint="This unit has no topics"
            selected={selectedUnitIds.includes(unitItem.id)}
            onClick={() => onSelectedToggle(SelectUnitOrTopic.Unit, unitItem.id)}
            label={unitName}
          />
        </div>
        <ul className={expandedUnitIds.includes(unitItem.id) ? 'showList' : 'hideList'}>
          {unitTopics.map(({ name, stringId, id: topicId, type }) => {
            const templateTopicCustomUnit = !!(type === LibraryTypeEnum.Template && customUnits.find(cus => cus.id === unitItem.id));
            const isTopicSelected = selectedTopicIds.includes(topicId);
            return (
              <li
                className="lo-library-filter__unit__topic"
                key={`${name}_${stringId}`}
                data-template-topic-custom-unit={templateTopicCustomUnit}
                data-stringid={stringId}
              >
                <CheckboxItem
                  selected={isTopicSelected}
                  onClick={() => onSelectedToggle(SelectUnitOrTopic.Topic, topicId)}
                  label={name}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderSubject = ({ id: subjectId, name: subjectName }: SubjectApi) => {
    const subjectUnits = units.filter(unit => unit.subjectId === subjectId);
    const subjectIsExpanded = expandedSubjectIds.includes(subjectId);
    return (
      <div className="lo-library-filter__subject" key={subjectId}>
        <h3 className="lo-library-filter__subject-header">
          <button
            className="lo-library-filter__subject-expand"
            data-expanded={subjectIsExpanded}
            onClick={() => onExpandedToggle(ExpandSubjectOrUnit.Subject, subjectId)}
          >
            <IoIosArrowForward />
          </button>
          <div className="lo-library-filter__subject-name">
            {subjectName}
          </div>
        </h3>
        {(subjectIsExpanded && !!units && !!topicsForFilter) &&
          subjectUnits.map((unitItem) => renderUnitForSubject(subjectName, unitItem))
        }
      </div>

    );
  };

  return (
    <div className="lo-library-filter">
      {primarySubject && renderSubject(primarySubject)}
      {!!additionalSubjects.length && (
        <>
          <div className="lo-library-filter__additional-subjects-divider"/>
          <div className="lo-library-filter__additional-header">
            Additional Subjects
          </div>
          {additionalSubjects.map(renderSubject)}
        </>
      )}
    </div>
  );
}

export default LoLibraryFilter;
