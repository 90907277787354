import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch } from 'types/store.types';

export default function removeTopicFromClassSession(classSessionTopicId: number) {
  return (dispatch: AppDispatch) => (async () => {
    // delete from local store for instant UI
    dispatch(activeSlice.actions.removeActiveClassSessionTopics({ id: classSessionTopicId }));

    // make the change on the server
    await apiNext.deleteClassSessionTopic(classSessionTopicId);

  })();
}
