/**
 * A very simple function that returns true if the user is a student role (not, unfortunately, course specific)
 *
 * @name isStudentRole
 * @function
 * @param {user} a user object

 */

import { getLtiRole } from './../ltiLaunch';
import { RoleEnum, StudentRoles } from 'types/backend/roles.types';
import { UserApi } from 'types/backend/users.types';
import { EnrollmentApi } from 'types/backend/enrollments.types';

export default function isStudentRole(user: UserApi, enrollment?: EnrollmentApi): boolean {
  let studentRoleFlag = false;
  // condition for lti user
  if (!user) {
    return false;
  }
  if (user?.ltiUserId !== null) {
    const ltiData = window.sessionStorage.getItem('lti');
    const ltiRole = ltiData && getLtiRole(JSON.parse(ltiData));
    if (ltiRole === StudentRoles.Student) {
      studentRoleFlag = true;
      return studentRoleFlag;
    }
  }

  if (!enrollment) {
    return user.roleId === RoleEnum.Student;
  }

  // to overwrite instructor role to student based if instructor enrolled as student
  studentRoleFlag = enrollment?.roleId === RoleEnum.Student;
  return studentRoleFlag;
}
