import React, { createContext, useState } from 'react';

import useClassSessionQuery from 'hooks/useClassSessionQuery';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import { getAssessmentQuestionIdsTaggedWithOnlySpecificCourseLos, loadStartedAssessmentQuestionIds } from 'utils/assessmentQuestionFunctions';
import { saveLogMessage } from 'utils/saveLogMessage';
import { getRemoveableLosAndQuestionsFromTopicId, isLoInOtherClassSessions } from 'utils/topicLoFunctions';
import sharedStrings from 'sharedStrings';

import retrieveBetterClassSessions from 'store/selectors/retrieveBetterClassSessions';
import { useAppDispatch, useAppSelector } from 'store';
import retrieveActiveCombinedQuestions from 'store/selectors/retrieveActiveCombinedQuestions';
import moveTopicToClassSession from 'store/actions/moveTopicToClassSession';
import removeLoFromClassSession from 'store/actions/removeLoFromClassSession';
import removeTopicFromClassSession from 'store/actions/removeTopicFromClassSession';
import reorderClassSessionTopic from 'store/actions/reorderClassSessionTopic';
import reorderClassSessionLearningObjective from 'store/actions/reorderClassSessionLearningObjective';
import addLoToClassSession from 'store/actions/addLoToClassSession';
import createUserTopicAndAddToClassSession from 'store/actions/createUserTopicAndAddToClassSession';
import editUserTopic from 'store/actions/editUserTopic';

import BetterTimeline from 'shared-components/BetterTimeline/BetterTimeline';
import ConfirmRemoveTopicsLosQuestions, { RemovalConfirmationData } from 'instructor/components/ConfirmRemoveTopicsLosQuestions/ConfirmRemoveTopicsLosQuestions';
import InstructorAssessmentPill from 'shared-components/BetterTimeline/InstructorAssessmentPill';
import LoLibrary from './Components/LoLibrary/LoLibrary';
import ClassSessionLoList from './Components/ClassSessionLoList/ClassSessionLoList';
import ExitBlock from 'instructor/components/ExitBlock/ExitBlock';
import BetterModal from 'shared-components/BetterModal/BetterModal';
import LoBuilder, { LoBuilderEditInit } from 'instructor/components/LoBuilder/LoBuilder';

import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { ClassSessionTopicApi } from 'types/backend/classSessionTopics.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';
import { TopicApi } from 'types/backend/topics.types';
import { LoActionEnum, LoActionPayload } from './CourseLoSelector.types';
import './CourseLoSelectorController.scss';

interface LoSelectorContextEnum {
  isProcessing: boolean
  setIsProcessing: (updated: boolean) => void
}

export const LoSelectorContext = createContext({} as LoSelectorContextEnum);

/**
 * Split out 'controller logic' for LO Selector since there is a lot of logic here its better to be seperated from the main controller
 *
 * @name CourseLoSelectorController
 * @function
 */
export default function CourseLoSelectorController() {
  const dispatch = useAppDispatch();
  const { triggerConfirmationPrompt } = useConfirmationPrompt();

  const classSessions = useAppSelector(retrieveBetterClassSessions);
  const { subjectId: primarySubjectId, id: courseId } = useAppSelector(store => store.active.course);
  const courseLibraryLearningObjectives = useAppSelector(store => store.active.learningObjectives);
  const classSessionLearningObjectives = useAppSelector(store => store.active.classSessionLearningObjectives);
  const assessmentQuestions = useAppSelector(store => store.active.assessmentQuestionMaps);
  const classSessionTopics = useAppSelector(store => store.active.classSessionTopics);
  const questions = useAppSelector(retrieveActiveCombinedQuestions);

  const [selectedClassSessionId, setSelectedClassSessionId] = useClassSessionQuery(classSessions);
  const currentActiveClassSession = classSessions.find(({ id }) => id === selectedClassSessionId);

  const [loBuilderEditInit, setLoBuilderEditInit] = useState<LoBuilderEditInit | null>(null);
  const [showLoDetails, setShowLoDetails] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [removalConfirmationData, setRemovalConfirmationData] = useState<RemovalConfirmationData | null>(null);

  const currentSessionLoIds = currentActiveClassSession?.courseLearningObjectives.map((clo) => clo.id) || [];

  //changes a topic title
  async function updateUserTopic(topicToEdit: TopicApi) {
    dispatch(editUserTopic(topicToEdit));
    return;
  }

  //add a custom topic
  async function onAddCustomTopic(topicName: string, classSessionId: number) {
    await saveLogMessage('CLOSelector_action: onAddCustomTopic');
    // by default, assign new custom topic to primary subject id
    return dispatch(createUserTopicAndAddToClassSession({
      topicName,
      classSessionId,
      subjectId: primarySubjectId,
    }));
  }
  async function deleteConfirmedLo(loId: number, classSessionId: number) {
    const classSessionLo = classSessionLearningObjectives.find(cslo => {
      return cslo.learningObjectiveId === loId && cslo.classSessionId === classSessionId;
    });
    if (classSessionLo) {
      await dispatch(removeLoFromClassSession(classSessionLo.id));
      setIsProcessing(false);
    }
    return;
  }

  const resetRemovedItemsState = () => {
    setRemovalConfirmationData(null);
    setIsProcessing(false);
  };

  // this gets a wrapper so we can load the latest started questions
  const triggerRemoveConfirmation = async ({
    topicIdToRemove,
    csloIdsToRemove,
    assessmentQuestionIdsToRemove,
  }: {
    topicIdToRemove?: number
    csloIdsToRemove: Array<number>
    assessmentQuestionIdsToRemove: Array<number>
  }) => {
    const startedAssessmentQuestionIds = await loadStartedAssessmentQuestionIds(assessmentQuestionIdsToRemove, courseId);
    setRemovalConfirmationData({
      classSessionId: currentActiveClassSession?.id as number, // this will always be defined at this point
      topicIdToRemove,
      csloIdsToRemove,
      assessmentQuestionIdsToRemove,
      startedAssessmentQuestionIds,
    });
  };

  async function handleLoAction(action: LoActionEnum, payload?: LoActionPayload) {
    if (!currentActiveClassSession) {
      return null;
    }
    switch (action) {
      case LoActionEnum.AddLoToClassSession: {
        if (payload?.id) {
          await dispatch(addLoToClassSession(payload.id, currentActiveClassSession.id, true));
          await saveLogMessage(`CLOSelector_action: ${action}`);
          setIsProcessing(false);
        }
        break;
      }
      case LoActionEnum.RemoveLoFromClassSession: {
        const { id: loId } = payload || {};
        if (!loId) {
          console.warn('no LoId in payload', payload);
          return;
        }
        if (!isLoInOtherClassSessions(loId, currentActiveClassSession.id, classSessionLearningObjectives)) {
          // need to translate from loId to csloId for remove confirmation init
          const { id: csloIdToRemove } = classSessionLearningObjectives.find((cslo) => cslo.learningObjectiveId === loId && cslo.classSessionId === currentActiveClassSession.id) as ClassSessionLearningObjectiveApi;
          const assessmentQuestionIdsToRemove = getAssessmentQuestionIdsTaggedWithOnlySpecificCourseLos([loId], assessmentQuestions, questions);
          if (!!assessmentQuestionIdsToRemove.length) {
            await triggerRemoveConfirmation({
              csloIdsToRemove: [csloIdToRemove],
              assessmentQuestionIdsToRemove,
            });
            return;
          }
        }
        setIsProcessing(true);
        return triggerConfirmationPrompt({
          confirmButtonText: 'Remove LO',
          title: 'Confirmation',
          message: sharedStrings.REMOVE_LO_CONFIRMATION,
          onConfirm: async () => {
            if (payload?.id) {
              deleteConfirmedLo(payload.id, currentActiveClassSession.id);
              await saveLogMessage(`CLOSelector_action: ${action}`);
            }
          },
          onCancel: () => setIsProcessing(false),
        });
      }
      case LoActionEnum.CreateLo: {
        setLoBuilderEditInit(null);
        setShowLoDetails(true);
        break;
      }
      case LoActionEnum.EditLo:
      case LoActionEnum.CopyLo: {
        const { id, title, topicId, parentLearningObjectiveId } = payload as LoActionPayload;
        if (!title || !topicId) {
          console.error(`Missing required data for ${action} - ${JSON.stringify({ title, topicId })}`);
          return null;
        }
        setLoBuilderEditInit({ id, title, libraryTopicId: topicId, parentLearningObjectiveId });
        setShowLoDetails(true);
        break;
      }
    }
  }

  const handleLoSave = async (learningObjective: LearningObjectiveApi, shouldAddToClassSession?: boolean) => {
    if (shouldAddToClassSession) {
      await handleLoAction(LoActionEnum.AddLoToClassSession, { id: learningObjective.id });
    }
    await saveLogMessage(`CLOSelector_action: handleLoSave ${shouldAddToClassSession ? 'create' : 'update'}`);
    setShowLoDetails(false);
    setLoBuilderEditInit(null);
  };

  // moves a topic and its LOs to another class session
  async function onMoveTopic(classSessionTopicId: number, newClassSessionId: number) {
    setIsProcessing(true);
    await dispatch(moveTopicToClassSession(classSessionTopicId, newClassSessionId));
    await saveLogMessage('CLOSelector_action: moveTopicToClassSession');
    setIsProcessing(false);
  }
  // removes a topic from class session
  async function onRemoveTopic(topicIdToRemove: number) {
    const { assessmentQuestionIdsOnlyInLos, csloIdsOnlyInThisClassSession, csloIds } = getRemoveableLosAndQuestionsFromTopicId({
      topicId: topicIdToRemove,
      classSessionId: currentActiveClassSession?.id as number,
      classSessionLearningObjectives,
      assessmentQuestions,
      questions,
    });

    // TODO: this ternary exists in 3 places, could be better
    const csloIdsToRemove = !!csloIdsOnlyInThisClassSession.length
      ? csloIdsOnlyInThisClassSession
      : csloIds;

    await saveLogMessage('CLOSelector_action: onRemoveTopic');
    // if topic is empty, just remove it
    if (!csloIdsToRemove.length && !assessmentQuestionIdsOnlyInLos.length) {
      // look up the class session topic id
      const classSessionTopic = classSessionTopics.find((cst) => cst.classSessionId === currentActiveClassSession?.id && cst.topicId === topicIdToRemove) as ClassSessionTopicApi;
      return dispatch(removeTopicFromClassSession(classSessionTopic.id));
    }

    // else there are no related assessment question ids and we can remove class session topic and LOs
    return triggerRemoveConfirmation({
      topicIdToRemove,
      csloIdsToRemove,
      assessmentQuestionIdsToRemove: assessmentQuestionIdsOnlyInLos,
    });
  }

  //reorder an LO withing a topic in a session
  async function onChangeLoSortWithinTopic(csloToMove: ClassSessionLearningObjectiveApi, previousId: number) {
    await dispatch(reorderClassSessionLearningObjective(csloToMove, previousId));
    await saveLogMessage('CLOSelector_action: onChangeLoSortWithinTopic');
  }

  //Move LOs between topics in a session
  async function onMoveLoToTopic(csloToMove: ClassSessionLearningObjectiveApi, destTopicId: number, previousId: number) {
    if (!currentActiveClassSession) {
      return;
    }
    if (isLoInOtherClassSessions(csloToMove.learningObjectiveId, currentActiveClassSession.id, classSessionLearningObjectives)) {
      window.alert(sharedStrings.MOVE_LO_BETWEEN_TOPICS_VALIDATION);
      return;
    }
    await dispatch(reorderClassSessionLearningObjective(csloToMove, previousId, destTopicId));
    await saveLogMessage('CLOSelector_action: onMoveLoToTopic');
  }

  //reorder topics within in session
  async function onChangeTopicSort(classSessionTopicId: number, previousId: number) {
    return dispatch(reorderClassSessionTopic(classSessionTopicId, previousId));
  }

  if (!courseLibraryLearningObjectives || !currentActiveClassSession || !selectedClassSessionId) {
    return null;
  }

  return (
    <LoSelectorContext.Provider value={{ isProcessing, setIsProcessing }}>
      {!!removalConfirmationData && (
        <ConfirmRemoveTopicsLosQuestions
          handleExit={resetRemovedItemsState}
          removalConfirmationData={removalConfirmationData}
        />
      )}
      <div className="course-page__lo-selector-controller">
        <div className="course-page__lo-selector-wrapper">
          <BetterTimeline
            currentClassSessionId={selectedClassSessionId}
            setClassSessionId={setSelectedClassSessionId}
            renderAssessmentPill={(assessmentPillData) => (
              <InstructorAssessmentPill {...assessmentPillData} />
            )}
          />
          <ExitBlock title="Learning Objective Selector" />
          <main className="course-page__lo-selector">
            <LoLibrary
              currentClassSessionId={currentActiveClassSession.id}
              currentSessionLoIds={currentSessionLoIds}
              loAction={handleLoAction}
            />
            <ClassSessionLoList
              currentClassSession={currentActiveClassSession}
              onMoveTopic={onMoveTopic}
              onRemoveTopic={onRemoveTopic}
              updateUserTopic={updateUserTopic}
              onAddCustomTopic={(topicTitle) => onAddCustomTopic(topicTitle, currentActiveClassSession.id)}
              onChangeLoSortWithinTopic={onChangeLoSortWithinTopic}
              onMoveLoToTopic={onMoveLoToTopic}
              onChangeTopicSort={onChangeTopicSort}
              loAction={handleLoAction}
            />
          </main>
        </div>
        <BetterModal
          className="lo-selector-modal__lo-builder"
          isShowing={showLoDetails}
          hide={() => setShowLoDetails(false)}
        >
          <LoBuilder
            initEditState={loBuilderEditInit}
            onSave={handleLoSave}
          />
        </BetterModal>
      </div>
    </LoSelectorContext.Provider>
  );
}
