import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Prompt } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';

import useClassSessionQuery from 'hooks/useClassSessionQuery';
import useEffectOnce from 'hooks/useEffectOnce';
import tooltips from '../../assessmentBuilderTooltips';
import confirmationMsgs, { hasBeenStartedMessage } from '../../AssessmentBuilderConfirmationMessages';
import sharedStrings from 'sharedStrings';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import {
  determineDefaultMinOpenMaxDueDatesForCourse,
  determineFreeAttemptsAndPointPenalty,
  determineMultipleAttemptPolicyFromAssessment,
  getDefaultDates,
  getDefaultMultipleAttemptPolicy,
  getMinDueDateForAssessment,
  inferGradingPolicy,
  cleanupLatePolicyForGradingPolicy,
  getPresetLatePenalty,
  getPresetLatePolicy,
  getNextValidLateDate,
} from 'utils/assessmentFunctions';

import { useAppSelector } from 'store';
import retrieveBetterClassSessions from 'store/selectors/retrieveBetterClassSessions';
import retrieveEnrichedCurrentClassSession from 'store/selectors/retrieveEnrichedCurrentClassSession';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import ToggleSwitch from 'shared-components/ToggleSwitch/ToggleSwitch';
import DateTimePicker from 'shared-components/DateTimePicker/DateTimePicker';
import { EnrichedClassSession } from 'store/selectors/retrieveEnrichedClassSessions';

import SelectClassDaysCovered from './SelectClassDaysCovered';
import CombinedAttemptsPolicy from './CombinedAttemptsPolicy';
import LatePolicyFields from './LatePolicyFields';
import AssessmentBuilderActionBar, { ItemTypeEnum } from '../AssessmentBuilderActionBar/AssessmentBuilderActionBar';
import { AssessmentBuilderContext } from '../../AssessmentBuilderController';
import { YesNo } from 'types/backend/shared.types';
import { ConfirmationTypeEnum, MultipleAttemptPolicyEnum, PositionEnum } from 'types/common.types';
import { FormValues, TabEnum } from '../../AssessmentBuilderController.types';
import { AssessmentApiBase, AssessTypeEnum } from 'types/backend/assessments.types';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import './AssessmentDetails.scss';


enum FormKeyEnum {
  AssessType = 'assessType',
  AssessmentName = 'assessmentName',
  IsPublished = 'isPublished',
  Instructions = 'instructions',
  SelectedClassIds = 'selectedClassIds',
  IsGradeSyncEnabled = 'isGradeSyncEnabled',
  IsPrepGradeSyncEnabled = 'isPrepGradeSyncEnabled',
  IsPracticeGradeSyncEnabled = 'isPracticeGradeSyncEnabled',
  IsPracticePublished = 'isPracticePublished',
}

enum InvalidDates {
  OpenDue = 'openDue',
  DueLate = 'dueLate'
}

enum DatePickerEnum {
  DueDate = 'dueDate',
  LateDate = 'lateDate',
  LatePolicy = 'latePolicy',
  OpenDate = 'openDate',
  PracticeLateDate = 'practiceLateDate',
  PracticeLatePolicy = 'practiceLatePolicy',
  PrepLateDate = 'prepLateDate',
  PrepLatePolicy = 'prepLatePolicy',
}

function AssessmentDetails({
  setTab,
  onSaveAssessmentDetails,
  onRemoveAssessment,
  setFormIsDirty,
  startedAssessmentIds,
}: {
  setTab: (tab: TabEnum) => void
  onSaveAssessmentDetails: (payload: FormValues) => void
  onRemoveAssessment: (editingAssessmentId: string, isStarted: boolean) => void
  setFormIsDirty: (isDirty: boolean) => void
  startedAssessmentIds: Array<string>
}) {
  const { editingAssessment } = React.useContext(AssessmentBuilderContext);
  const assessments = useAppSelector((store) => store.active.assessments);
  const courseAssessmentPreset = useAppSelector((store) => store.active.courseAssessmentPreset);

  const course = useAppSelector((store) => store.active.course);
  const classSessions = useAppSelector(retrieveBetterClassSessions);
  const currentClassSession = useAppSelector(retrieveEnrichedCurrentClassSession) as EnrichedClassSession;
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const [selectedClassSessionId] = useClassSessionQuery(classSessions);
  const selectedClassSessionData = classSessions.find(({ id }) => id === selectedClassSessionId);
  const isSpecialClass = !!selectedClassSessionData && selectedClassSessionData.classType !== ClassTypeEnum.Normal;
  // if type query is missing, use default
  const TypeParam = withDefault(StringParam, AssessTypeEnum.Homework);
  const [assessTypeQuery, setAssessTypeQuery] = useQueryParam('type', TypeParam);

  const [enableSubmit, setEnableSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  // get values from editingAssessment
  const {
    assessType: existingAssessType,
    classSessionIds = !!selectedClassSessionId && !isSpecialClass ? [selectedClassSessionId] : [],
    instructions: existingInstructions = '',
    name: existingAssessmentName = '',
    published = YesNo.No,
    dueDate: existingDueDateISO,
    openDate: existingOpenDateISO,
    latePolicy: existingLatePolicy,
    lateDate: existingLateDateISO,
    _derived: {
      prepAssessmentId,
      practiceAssessmentId,
    },
    isGradeSyncEnabled: existingGradeSyncEnabled,
  } = editingAssessment || { _derived: {} };

  const initialAssessType = existingAssessType || assessTypeQuery;

  const initialFormState = {
    [FormKeyEnum.AssessType]: initialAssessType as AssessTypeEnum,
    [FormKeyEnum.AssessmentName]: existingAssessmentName,
    [FormKeyEnum.IsPublished]: published === YesNo.Yes,
    [FormKeyEnum.Instructions]: existingInstructions,
    [FormKeyEnum.SelectedClassIds]: classSessionIds,
    [FormKeyEnum.IsGradeSyncEnabled]: existingGradeSyncEnabled || false,
    [FormKeyEnum.IsPrepGradeSyncEnabled]: editingAssessment?.prep?.isGradeSyncEnabled || false,
    [FormKeyEnum.IsPracticeGradeSyncEnabled]: editingAssessment?.practiceTest?.isGradeSyncEnabled || false,
    [FormKeyEnum.IsPracticePublished]: editingAssessment?.practiceTest?.published === YesNo.Yes,
  };

  const [{
    assessType,
    assessmentName,
    isPublished,
    instructions,
    selectedClassIds,
    isGradeSyncEnabled,
    isPrepGradeSyncEnabled,
    isPracticeGradeSyncEnabled,
    isPracticePublished,
  }, setFormState] = useState(initialFormState);

  // deterministic values
  const isPrepStarted = !!prepAssessmentId && startedAssessmentIds.includes(prepAssessmentId);
  const isPracticeStarted = !!practiceAssessmentId && startedAssessmentIds.includes(practiceAssessmentId);
  const isSummative = assessType === AssessTypeEnum.Summative;
  const hasBeenStarted = !!startedAssessmentIds.length;
  const multipleAttemptPoliciesWithoutLatePolicy = [MultipleAttemptPolicyEnum.NotForPoints, MultipleAttemptPolicyEnum.NotFound];

  // dates
  const existingDueDate = !!existingDueDateISO && DateTime.fromISO(existingDueDateISO).toJSDate();
  const existingOpenDate = !!existingOpenDateISO && DateTime.fromISO(existingOpenDateISO).toJSDate();
  const existingLateDate = !!existingLateDateISO && DateTime.fromISO(existingLateDateISO).toJSDate();
  const defaultDates = !!currentClassSession && getDefaultDates(assessType, course.timeZone, currentClassSession.date, courseAssessmentPreset);
  const [openDate, setOpenDate] = useState(existingOpenDate || defaultDates.open);
  const [dueDate, setDueDate] = useState(existingDueDate || defaultDates.due);
  const [hasInvalidOpenDueDates, setHasInvalidOpenDueDates] = useState(false);

  // policies
  const presetLatePolicy = getPresetLatePolicy(assessType, courseAssessmentPreset);
  const presetPrepLatePolicy = getPresetLatePolicy(AssessTypeEnum.Prep, courseAssessmentPreset);
  const presetPracticeLatePolicy = getPresetLatePolicy(AssessTypeEnum.PracticeTest, courseAssessmentPreset);
  const initialSelectedPolicy = !!editingAssessment
    ? determineMultipleAttemptPolicyFromAssessment(editingAssessment)
    : getDefaultMultipleAttemptPolicy(assessType, courseAssessmentPreset);
  const [selectedPolicy, setSelectedPolicy] = useState(initialSelectedPolicy);
  const prepMultiPolicy = getDefaultMultipleAttemptPolicy(AssessTypeEnum.Prep, courseAssessmentPreset);
  const practiceMultiPolicy = getDefaultMultipleAttemptPolicy(AssessTypeEnum.PracticeTest, courseAssessmentPreset);
  const [prepPolicy, setPrepPolicy] = useState(prepMultiPolicy);
  const [practicePolicy, setPracticePolicy] = useState(practiceMultiPolicy);
  const [latePolicy, setLatePolicy] = useState(existingLatePolicy || !multipleAttemptPoliciesWithoutLatePolicy.includes(initialSelectedPolicy) ? presetLatePolicy : YesNo.No);

  // dates that depend on policies
  const [lateDate, setLateDate] = useState<Date | null>(existingLateDate || latePolicy === YesNo.Yes ? defaultDates.late as Date : null);
  const [hasInvalidDueLateDates, setHasInvalidDueLateDates] = useState(false);
  let existingPrepLatePolicy = YesNo.No;
  let existingPracticeLatePolicy = YesNo.No;
  let existingPrepLateDate: Date | null = null;
  let existingPracticeLateDate: Date | null = null;
  if (isSummative) {
    if (!!editingAssessment) {
      const { prep, practiceTest } = editingAssessment;
      existingPrepLatePolicy = prep.latePolicy;
      existingPracticeLatePolicy = practiceTest.latePolicy;
      existingPrepLateDate = existingPrepLatePolicy === YesNo.Yes ? DateTime.fromISO(prep.lateDate as string).toJSDate() : null;
      existingPracticeLateDate = existingPracticeLatePolicy === YesNo.Yes ? DateTime.fromISO(practiceTest.lateDate as string).toJSDate() : null;
    } else {
      existingPrepLatePolicy = presetPrepLatePolicy;
      existingPracticeLatePolicy = presetPracticeLatePolicy;
      if (existingPrepLatePolicy === YesNo.Yes) {
        existingPrepLateDate = defaultDates.prepLate as Date;
      }
      if (existingPracticeLatePolicy === YesNo.Yes) {
        existingPracticeLateDate = defaultDates.practiceLate as Date;
      }
    }
  }
  const [prepLatePolicy, setPrepLatePolicy] = useState(existingPrepLatePolicy);
  const [practiceLatePolicy, setPracticeLatePolicy] = useState(existingPracticeLatePolicy);
  const [prepLateDate, setPrepLateDate] = useState<Date | null>(existingPrepLateDate);
  const [practiceLateDate, setPracticeLateDate] = useState<Date | null>(existingPracticeLateDate);
  const [hasInvalidDueLatePrepDates, setHasInvalidDueLatePrepDates] = useState(false);
  const [hasInvalidDueLatePracticeDates, setHasInvalidDueLatePracticeDates] = useState(false);

  // late penalties
  const presetLatePenalty = getPresetLatePenalty(assessType, courseAssessmentPreset);
  const presetPrepLatePenalty = getPresetLatePenalty(AssessTypeEnum.Prep, courseAssessmentPreset);
  const presetPracticeLatePenalty = getPresetLatePenalty(AssessTypeEnum.PracticeTest, courseAssessmentPreset);
  const defaultLatePenalty = !!editingAssessment ? editingAssessment.latePenalty : presetLatePenalty;
  const defaultPrepLatePenalty = !!editingAssessment?.prep ? editingAssessment.prep.latePenalty : presetPrepLatePenalty;
  const defaultPracticeLatePenalty = !!editingAssessment?.practiceTest ? editingAssessment.practiceTest.latePenalty : presetPracticeLatePenalty;
  const [latePenalty, setLatePenalty] = useState<number | null>(defaultLatePenalty);
  const [prepLatePenalty, setPrepLatePenalty] = useState<number | null>(defaultPrepLatePenalty);
  const [practiceLatePenalty, setPracticeLatePenalty] = useState<number | null>(defaultPracticeLatePenalty);

  const assessTypeOptions = [
    { value: AssessTypeEnum.Preclass, label: 'Preclass' },
    { value: AssessTypeEnum.Homework, label: 'Homework' },
    { value: AssessTypeEnum.Readiness, label: 'Readiness' },
  ];

  // set up min and max dates for DatePickerField
  const { defaultMinOpenDate, defaultMaxDueDate } = determineDefaultMinOpenMaxDueDatesForCourse(course);
  const minDueDate = getMinDueDateForAssessment(hasBeenStarted, isSummative, defaultMinOpenDate);

  // summative related values set here to reduce calculation overhead on re-renders
  useEffectOnce(() => {
    if (isSummative) {
      // get both prep and practice in one loop, return tuple of [prepAssessment, practiceAssessment]
      const [prepAssessment, practiceAssessment] = assessments.reduce((acc, cur) => {
        if (cur.id === prepAssessmentId) {
          return [cur, ...acc];
        } else if (cur.id === practiceAssessmentId) {
          return [...acc, cur];
        }
        return acc;
      }, [] as Array<AssessmentApiBase>);
      if (!!prepAssessment && !!practiceAssessment) {
        const prepMultiPolicyInfo = determineMultipleAttemptPolicyFromAssessment(prepAssessment);
        setPrepPolicy(prepMultiPolicyInfo);
        const practiceMultiPolicyInfo = determineMultipleAttemptPolicyFromAssessment(practiceAssessment);
        setPracticePolicy(practiceMultiPolicyInfo);
        const { lateDate: newPrepLateDate, latePenalty: newPrepLatePenalty, latePolicy: newPrepLatePolicy } = cleanupLatePolicyForGradingPolicy<string>(prepAssessment);
        setPrepLatePolicy(newPrepLatePolicy);
        setPrepLateDate(typeof newPrepLateDate === 'string' ? DateTime.fromISO(newPrepLateDate).toJSDate() : null);
        setPrepLatePenalty(newPrepLatePenalty);
        const { lateDate: newPracticeLateDate, latePenalty: newPracticeLatePenalty, latePolicy: newPracticeLatePolicy } = cleanupLatePolicyForGradingPolicy<string>(practiceAssessment);
        setPracticeLatePolicy(newPracticeLatePolicy);
        setPracticeLateDate(typeof newPracticeLateDate === 'string' ? DateTime.fromISO(newPracticeLateDate).toJSDate() : null);
        setPracticeLatePenalty(newPracticeLatePenalty);
      }
    }
  });

  useEffect(() => {
    if (!!editingAssessment) {
      // update local state on navigation to different editingAssessment
      const ed = editingAssessment;
      setFormState((formState) => {
        return {
          [FormKeyEnum.AssessType]: ed.assessType,
          [FormKeyEnum.AssessmentName]: ed.name,
          [FormKeyEnum.IsPublished]: ed.published === YesNo.Yes,
          [FormKeyEnum.Instructions]: ed.instructions,
          [FormKeyEnum.SelectedClassIds]: ed.classSessionIds,
          [FormKeyEnum.IsGradeSyncEnabled]: ed.isGradeSyncEnabled,
          [FormKeyEnum.IsPrepGradeSyncEnabled]: editingAssessment?.prep?.isGradeSyncEnabled || false,
          [FormKeyEnum.IsPracticeGradeSyncEnabled]: editingAssessment?.practiceTest?.isGradeSyncEnabled || false,
          [FormKeyEnum.IsPracticePublished]: editingAssessment?.practiceTest?.published === YesNo.Yes,
        };
      });
      setDueDate(DateTime.fromISO(ed.dueDate).toJSDate());
      setOpenDate(DateTime.fromISO(ed.openDate).toJSDate());
      setLatePolicy(ed.latePolicy);
      setPrepLatePolicy(ed?.prep?.latePolicy);
      setPracticeLatePolicy(ed?.practiceTest?.latePolicy);
      setLateDate(ed.lateDate ? DateTime.fromISO(ed.lateDate).toJSDate() : null);
      setPrepLateDate(ed?.prep?.lateDate ? DateTime.fromISO(ed.prep.lateDate).toJSDate() : null);
      setPracticeLateDate(ed?.practiceTest?.lateDate ? DateTime.fromISO(ed.practiceTest.lateDate).toJSDate() : null);
      setLatePenalty(ed.latePenalty);
      setPrepLatePenalty(ed?.prep?.latePenalty);
      setPracticeLatePenalty(ed?.practiceTest?.latePenalty);
      const updatedGradingPolicy = determineMultipleAttemptPolicyFromAssessment(ed);
      setSelectedPolicy(updatedGradingPolicy);
      console.debug(`:: editingAssessment changed: ${editingAssessment.id}`);
    }
  }, [editingAssessment]);

  useEffect (() => {
    // currently the only allowable invalid user input is an empty name so check that on changes
    const changedAndNoErrors = assessmentName !== '' && formChanged;
    setEnableSubmit(changedAndNoErrors);
    return;
  }, [assessmentName, formChanged, setFormIsDirty]);

  // centralize basic form changes to enable validation and accurately detect modified form state
  const setForm = (field: FormKeyEnum, payload: string | AssessTypeEnum | Array<number> | boolean | number | null) => {
    handleFormChanged();

    setFormState((formState) => {
      switch (field) {
        case FormKeyEnum.AssessmentName: {
          return {
            ...formState,
            [FormKeyEnum.AssessmentName]: payload as string,
          };
        }
        case FormKeyEnum.IsPublished: {
          return {
            ...formState,
            [FormKeyEnum.IsPublished]: payload as boolean,
            [FormKeyEnum.IsPracticePublished]: payload as boolean ? isPracticePublished : false, // turn off practice published if summative+prep is unpublished
            [FormKeyEnum.IsGradeSyncEnabled]: payload as boolean ? isGradeSyncEnabled : false, // turn off grade sync on unpublished assessments
            [FormKeyEnum.IsPrepGradeSyncEnabled]: payload as boolean ? isPrepGradeSyncEnabled : false, // turn off grade sync on unpublished assessments
            [FormKeyEnum.IsPracticeGradeSyncEnabled]: payload as boolean ? isPracticeGradeSyncEnabled : false, // turn off grade sync on unpublished assessments
          };
        }
        case FormKeyEnum.IsGradeSyncEnabled: {
          return {
            ...formState,
            [FormKeyEnum.IsGradeSyncEnabled]: payload as boolean,
          };
        }
        case FormKeyEnum.IsPrepGradeSyncEnabled: {
          return {
            ...formState,
            [FormKeyEnum.IsPrepGradeSyncEnabled]: payload as boolean,
          };
        }
        case FormKeyEnum.IsPracticeGradeSyncEnabled: {
          return {
            ...formState,
            [FormKeyEnum.IsPracticeGradeSyncEnabled]: payload as boolean,
          };
        }
        case FormKeyEnum.IsPracticePublished: {
          return {
            ...formState,
            [FormKeyEnum.IsPracticePublished]: payload as boolean,
            [FormKeyEnum.IsPracticeGradeSyncEnabled]: payload as boolean ? isPracticeGradeSyncEnabled : false, // turn off grade sync on unpublished assessments
          };
        }
        case FormKeyEnum.AssessType: {
          setAssessTypeQuery(payload as string);
          return {
            ...formState,
            [FormKeyEnum.AssessType]: payload as AssessTypeEnum,
          };
        }
        default: {
          return {
            ...formState,
            [field]: payload,
          };
        }
      }
    });
  };

  const triggerInvalidDatesConfirmationPrompt = (invalidDates: InvalidDates) => {
    triggerConfirmationPrompt({
      title: sharedStrings.INVALID_DATES_TITLE,
      message: invalidDates === InvalidDates.OpenDue ? confirmationMsgs.openDatePastDueDateConfMessage : confirmationMsgs.dueDatePastLateDateConfMessage,
      onConfirm: () => {},
      confirmationType: ConfirmationTypeEnum.Warn,
    });
  };

  // event handling functions
  const handleDateChange = (fromPicker: DatePickerEnum, updatedDate: Date | null) => {
    if (!!updatedDate) {
      switch (fromPicker) {
        case DatePickerEnum.OpenDate:
          setOpenDate(updatedDate);
          if (dueDate <= updatedDate) {
            setHasInvalidOpenDueDates(true);
          } else if (hasInvalidOpenDueDates && dueDate > updatedDate) {
            setHasInvalidOpenDueDates(false);
          }
          break;
        case DatePickerEnum.DueDate:
          if (!isSummative) {
            if (updatedDate <= openDate) {
              setHasInvalidOpenDueDates(true);
            } else if (hasInvalidOpenDueDates && updatedDate > openDate) {
              setHasInvalidOpenDueDates(false);
            }
            if (!!lateDate) {
              if (updatedDate >= lateDate) {
                setHasInvalidDueLateDates(true);
              } else if (hasInvalidDueLateDates && updatedDate < lateDate) {
                setHasInvalidDueLateDates(false);
              }
            }
          } else {
            if (!!prepLateDate) {
              if (updatedDate >= prepLateDate) {
                setHasInvalidDueLatePrepDates(true);
              } else if (hasInvalidDueLatePrepDates && updatedDate < prepLateDate) {
                setHasInvalidDueLatePrepDates(false);
              }
            }
            if (!!practiceLateDate) {
              if (updatedDate >= practiceLateDate) {
                setHasInvalidDueLatePracticeDates(true);
              } else if (hasInvalidDueLatePracticeDates && updatedDate < practiceLateDate) {
                setHasInvalidDueLatePracticeDates(false);
              }
            }
          }
          setDueDate(updatedDate);
          if (isSummative) {
            // if new assessment is summative, open date should be dueDate - 1 minute
            const updatedDateLuxon = DateTime.fromJSDate(updatedDate);
            const newOpenDate = updatedDateLuxon.minus({ minutes: 1 }).toJSDate();
            setOpenDate(newOpenDate);
          }
          break;
        case DatePickerEnum.LateDate:
          if (updatedDate <= dueDate) {
            setHasInvalidDueLateDates(true);
          } else if (hasInvalidDueLateDates && updatedDate > dueDate) {
            setHasInvalidDueLateDates(false);
          }
          setLateDate(updatedDate);
          break;
        case DatePickerEnum.PrepLateDate:
          if (updatedDate <= dueDate) {
            setHasInvalidDueLatePrepDates(true);
          } else if (hasInvalidDueLatePrepDates && updatedDate > dueDate) {
            setHasInvalidDueLatePrepDates(false);
          }
          setPrepLateDate(updatedDate);
          break;
        case DatePickerEnum.PracticeLateDate:
          if (updatedDate <= dueDate) {
            setHasInvalidDueLatePracticeDates(true);
          } else if (hasInvalidDueLatePracticeDates && updatedDate > dueDate) {
            setHasInvalidDueLatePracticeDates(false);
          }
          setPracticeLateDate(updatedDate);
          break;
      }
    }
    handleFormChanged();
  };

  const handleLatePolicyChange = (name: DatePickerEnum, updatedLatePolicy: boolean) => {
    setFormChanged(true);
    const latePolicyIsOn = !!updatedLatePolicy;
    if (name === DatePickerEnum.LatePolicy) {
      setLatePolicy(latePolicyIsOn ? YesNo.Yes : YesNo.No);
      if (latePolicyIsOn) {
        const newLateDate = getNextValidLateDate(assessType, course.timeZone, dueDate, courseAssessmentPreset);
        setLateDate(newLateDate);
        setLatePenalty(presetLatePenalty);
      } else {
        setLateDate(null);
        setLatePenalty(null);
        setHasInvalidDueLateDates(false);
      }
    } else if (name === DatePickerEnum.PrepLatePolicy) {
      setPrepLatePolicy(latePolicyIsOn ? YesNo.Yes : YesNo.No);
      if (latePolicyIsOn) {
        const newLateDate = getNextValidLateDate(AssessTypeEnum.Prep, course.timeZone, dueDate, courseAssessmentPreset);
        setLateDate(newLateDate);
        setPrepLateDate(newLateDate);
        setPrepLatePenalty(presetPrepLatePenalty);
      } else {
        setPrepLateDate(null);
        setPrepLatePenalty(null);
        setHasInvalidDueLatePrepDates(false);
      }
    } else if (name === DatePickerEnum.PracticeLatePolicy) {
      setPracticeLatePolicy(latePolicyIsOn ? YesNo.Yes : YesNo.No);
      if (latePolicyIsOn) {
        const newLateDate = getNextValidLateDate(AssessTypeEnum.PracticeTest, course.timeZone, dueDate, courseAssessmentPreset);
        setPracticeLateDate(newLateDate);
        setPracticeLatePenalty(presetPracticeLatePenalty);
      } else {
        setPracticeLateDate(null);
        setPracticeLatePenalty(null);
        setHasInvalidDueLatePracticeDates(false);
      }
    }
  };

  const handleSubmit = async (showDueDateConfirmation = true) => {
    if (hasInvalidOpenDueDates || hasInvalidDueLateDates || hasInvalidDueLatePrepDates || hasInvalidDueLatePracticeDates) {
      triggerInvalidDatesConfirmationPrompt(hasInvalidOpenDueDates ? InvalidDates.OpenDue : InvalidDates.DueLate);
      return;
    }
    if (hasBeenStarted && showDueDateConfirmation) {
      if (+dueDate !== +existingDueDate) {
        const now = DateTime.now();
        if (DateTime.fromJSDate(dueDate) >= now && !!existingDueDate && DateTime.fromJSDate(existingDueDate) < now) {
          triggerConfirmationPrompt({
            title: sharedStrings.EXTEND_DUE_DATE_TITLE,
            message: confirmationMsgs.changeDueDateAfterStudentsStartedConfMessage,
            confirmButtonText: 'Save and Extend Due Date',
            onConfirm: () => handleSubmit(false),
            onCancel: () => {},
            confirmationType: ConfirmationTypeEnum.Warn,
          });
          return;
        }
      }
    }
    setSubmitting(true);
    const deriveSummativeDetails = (map: MultipleAttemptPolicyEnum, isGradeSync: boolean, sumLateDate: Date | null, sumLatePenalty: number | null, sumLatePolicy: YesNo, pub: boolean, id?: string) => {
      if (assessType !== AssessTypeEnum.Summative) {
        // undefined on purpose for type compatibility
        return undefined;
      }
      const gradingPolicy = inferGradingPolicy(map);
      const { freeAttempts, pointPenalty } = determineFreeAttemptsAndPointPenalty(map);
      const { latePolicy: newLatePolicy, lateDate: newLateDate, latePenalty: newLatePenalty } = cleanupLatePolicyForGradingPolicy<Date>({ gradingPolicy, lateDate: sumLateDate, latePenalty: sumLatePenalty, latePolicy: sumLatePolicy });
      return {
        id,
        freeAttempts,
        gradingPolicy,
        pointPenalty,
        isGradeSyncEnabled: isGradeSync,
        lateDate: newLateDate,
        latePenalty: newLatePenalty,
        latePolicy: newLatePolicy,
        published: pub ? YesNo.Yes : YesNo.No,
      };
    };
    const assessmentDetails = {
      assessType,
      classSessionIds: selectedClassIds,
      dueDate,
      gradingPolicy: inferGradingPolicy(selectedPolicy),
      instructions,
      isGradeSyncEnabled,
      lateDate,
      latePenalty,
      latePolicy,
      multipleAttemptPolicy: selectedPolicy,
      name: assessmentName,
      openDate,
      practiceTest: deriveSummativeDetails(practicePolicy, isPracticeGradeSyncEnabled, practiceLateDate, practiceLatePenalty, practiceLatePolicy, isPracticePublished, practiceAssessmentId),
      prep: deriveSummativeDetails(prepPolicy, isPrepGradeSyncEnabled, prepLateDate, prepLatePenalty, prepLatePolicy, isPublished, prepAssessmentId),
      published: isPublished ? YesNo.Yes : YesNo.No,
    };
    await onSaveAssessmentDetails(assessmentDetails);
    const nextTab = !!isSummative ? TabEnum.AssessmentReview : TabEnum.SelectQuestions;
    setTab(nextTab);
  };

  if (!currentClassSession) {
    console.warn('NO currentClassSession', currentClassSession);
    return null;
  }

  const handleFormChanged = () => {
    setFormIsDirty(true); // TODO this doesn't affect AssessmentBuilderController for most fields
    setFormChanged(true);
  };

  const handleSetPolicy = (dropdownType: AssessTypeEnum, map: MultipleAttemptPolicyEnum) => {
    handleFormChanged();
    switch (dropdownType) {
      case AssessTypeEnum.PracticeTest:
        if (multipleAttemptPoliciesWithoutLatePolicy.includes(map)) {
          // switching to no LP
          setPracticeLatePolicy(YesNo.No);
          setPracticeLateDate(null);
          setHasInvalidDueLatePracticeDates(false);
          setPracticeLatePenalty(null);
          setFormChanged(true);
        } else if (multipleAttemptPoliciesWithoutLatePolicy.includes(practicePolicy)) {
          if (practiceLatePolicy === YesNo.Yes) {
            // switching to LP
            const practiceLate = getNextValidLateDate(AssessTypeEnum.PracticeTest, course.timeZone, dueDate, courseAssessmentPreset);
            handleDateChange(DatePickerEnum.PracticeLateDate, practiceLate);
            setPracticeLatePenalty(defaultPracticeLatePenalty !== null ? defaultPracticeLatePenalty : presetPracticeLatePenalty);
          }
          setFormChanged(true);
        }
        return setPracticePolicy(map);
      case AssessTypeEnum.Prep:
        if (multipleAttemptPoliciesWithoutLatePolicy.includes(map)) {
          setPrepLatePolicy(YesNo.No);
          setPrepLateDate(null);
          setHasInvalidDueLatePrepDates(false);
          setPrepLatePenalty(null);
          setFormChanged(true);
        } else if (multipleAttemptPoliciesWithoutLatePolicy.includes(prepPolicy)) {
          if (prepLatePolicy === YesNo.Yes) {
            const prepLate = getNextValidLateDate(AssessTypeEnum.Prep, course.timeZone, dueDate, courseAssessmentPreset);
            handleDateChange(DatePickerEnum.PrepLateDate, prepLate);
            setPrepLatePenalty(defaultPrepLatePenalty !== null ? defaultPrepLatePenalty : presetPrepLatePenalty);
          }
          setFormChanged(true);
        }
        return setPrepPolicy(map);
      default:
        if (multipleAttemptPoliciesWithoutLatePolicy.includes(map)) {
          setLatePolicy(YesNo.No);
          setLateDate(null);
          setHasInvalidDueLateDates(false);
          setLatePenalty(null);
          setFormChanged(true);
        } else if (multipleAttemptPoliciesWithoutLatePolicy.includes(selectedPolicy)) {
          if (latePolicy === YesNo.Yes) {
            const late = getNextValidLateDate(assessType, course.timeZone, dueDate, courseAssessmentPreset);
            handleDateChange(DatePickerEnum.LateDate, late);
            setLatePenalty(defaultLatePenalty !== null ? defaultLatePenalty : presetLatePenalty);
          }
          setFormChanged(true);
        }
        return setSelectedPolicy(map);
    }
  };

  function renderGradeSyncToggle(isPub: boolean, formKey: FormKeyEnum, isGradeSynced: boolean, toggleLabel: string) {
    const onGradeSyncChange = (formKeyParam: FormKeyEnum, newSyncSetting: boolean) => {
      setForm(formKeyParam, newSyncSetting);
      if (newSyncSetting) {
        triggerConfirmationPrompt({
          title: sharedStrings.SYNC_TO_LMS_TITLE,
          message: confirmationMsgs.enableGradeSyncConfMessage,
          onConfirm: () => {},
          confirmationType: ConfirmationTypeEnum.Warn,
        });
      }
    };
    return (
      <div className="form__field toggle-row grade-sync-toggle-row">
        <label className="toggle-label">
          <span className={isPub ? '' : 'is-disabled'}>
            {toggleLabel}
            <BetterTooltip content={tooltips.gradeSync} indicate position={PositionEnum.Bottom} />
          </span>
        </label>
        <ToggleSwitch
          id={formKey}
          checked={isGradeSynced}
          disabled={!isPub}
          onChange={() => onGradeSyncChange(formKey, !isGradeSynced)}
        />
      </div>
    );
  }

  const submitDisabled = !enableSubmit || submitting;
  const now = DateTime.now();
  const disablePrepToggle = multipleAttemptPoliciesWithoutLatePolicy.includes(prepPolicy)
  || (isPrepStarted && existingPrepLatePolicy === YesNo.Yes && (
    DateTime.fromJSDate(dueDate) < now
    || DateTime.fromJSDate(prepLateDate as Date) < now
  ));
  const disablePracticeToggle = multipleAttemptPoliciesWithoutLatePolicy.includes(practicePolicy)
  || (isPracticeStarted && existingPracticeLatePolicy === YesNo.Yes && (
    DateTime.fromJSDate(dueDate) < now
    || DateTime.fromJSDate(practiceLateDate as Date) < now
  ));
  const disablePrepPublished = isPrepStarted || isPracticeStarted;
  const disablePracticePublished = !isPublished || isPracticeStarted;
  return (
    <div className="assessment-details">
      <div>
        <Prompt
          when={formChanged && !submitting}
          message={(location) => {
            const currentAssessmentId = new URLSearchParams(window.location.search).get('assessment');
            const destinationAssessmentId = new URLSearchParams(location.search).get('assessment');
            return (window.location.pathname === location.pathname && currentAssessmentId === destinationAssessmentId) ||
              `${sharedStrings.ASSESSMENT_DETAILS_DIRTY_PREFIX} ${sharedStrings.DIRTY_NAVIGATE_CONFIRMATION}`;
          }}
        />
      </div>
      <form className="form assessment-builder__form">
        {isSummative ? (
          <div className="form-wrapper summative">
            <div className="form-row">
              <div className="form-col-left no-border">
                <div className="form__field">
                  <label>
                    Assessment Name
                    <input
                      type="text"
                      className="assessment-builder__assessment-name"
                      name={FormKeyEnum.AssessmentName}
                      onChange={({ target: { value } }) => setForm(FormKeyEnum.AssessmentName, value)}
                      value={assessmentName}
                      required
                    />
                  </label>
                </div>
              </div>
              <div className="form-col-right no-border">
                {hasBeenStarted && (
                  <div className="assessment-builder__message">
                    Students have started this assessment. Some settings can no longer be changed.
                    <BetterTooltip
                      content={hasBeenStartedMessage(isSummative)}
                      indicate
                      position={PositionEnum.Left}
                    />
                  </div>
                )}
                <div className="form__field">
                  <label htmlFor="due-date-picker">
                    Due Date
                    <div className="assessment-builder__due-date">
                      <DateTimePicker
                        className="assessment-builder__datetime-picker due-date-picker"
                        name="due-date-picker"
                        disabled={false}
                        invalid={hasInvalidOpenDueDates || hasInvalidDueLateDates || hasInvalidDueLatePrepDates || hasInvalidDueLatePracticeDates}
                        limitTime={hasBeenStarted}
                        minDate={minDueDate}
                        maxDate={defaultMaxDueDate}
                        onChange={(dateVal) => handleDateChange(DatePickerEnum.DueDate, dateVal)}
                        value={dueDate}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-col-left">
                <h3>Checkpoint 1 (Assessments to Review) &<br/>
                  Checkpoint 2 (Prep Questions)</h3>
                <div className={disablePrepPublished ? 'assessment-builder__published is-disabled' : 'assessment-builder__published'}>
                  <div className="form__field toggle-row">
                    <label className="toggle-label" htmlFor="prep-is-published-span">
                      <span id="prep-is-published-span">
                        Publish Study Path and Prep Questions
                      </span>
                      <BetterTooltip content={tooltips.publishingStudyPath} indicate position={PositionEnum.Bottom} />
                    </label>
                    <ToggleSwitch
                      id='prep-is-published'
                      checked={isPublished}
                      disabled={disablePrepPublished}
                      onChange={() => setForm(FormKeyEnum.IsPublished, !isPublished)}
                      data-on="YES"
                      data-off="NO"
                    />
                  </div>
                  <span className="published-info">
                    Once published, students will be able to retry questions from completed assessments, including point recapture on homework in Checkpoint 1.
                    Once they move a topic to Checkpoint 2, students can also start the Prep Questions for that topic.
                  </span>
                </div>
                {course.isGradeSyncEnabled && (
                  <>
                    {renderGradeSyncToggle(isPublished, FormKeyEnum.IsPrepGradeSyncEnabled, isPrepGradeSyncEnabled, 'Enable Sync to LMS')}
                  </>
                )}
                <div className="assessment-builder__summative-settings">
                  <CombinedAttemptsPolicy
                    assessType={AssessTypeEnum.Prep}
                    isDisabled={isPrepStarted}
                    label="Prep Questions Grading Policy"
                    onChange={handleSetPolicy}
                    value={prepPolicy}
                  />
                </div>
                <LatePolicyFields
                  dateInvalid={hasInvalidDueLatePrepDates}
                  dateValue={prepLateDate}
                  disabled={multipleAttemptPoliciesWithoutLatePolicy.includes(prepPolicy)}
                  fieldName="Prep Questions Late Policy"
                  fieldNamePrefix='prep-'
                  hasBeenStarted={isPrepStarted}
                  latePolicy={prepLatePolicy}
                  minDate={minDueDate}
                  maxDate={defaultMaxDueDate}
                  onChangeDate={(dateVal) => handleDateChange(DatePickerEnum.PrepLateDate, dateVal)}
                  onChangePenalty={(val) => { setFormChanged(true); setPrepLatePenalty(val); }}
                  onChangePolicy={(val) => { handleLatePolicyChange(DatePickerEnum.PrepLatePolicy, val); }}
                  penaltyDisabled={isPrepStarted && DateTime.fromJSDate(dueDate) < now}
                  penaltyName="prepLatePenalty"
                  penaltyValue={prepLatePenalty}
                  toggleDisabled={disablePrepToggle}
                />
              </div>
              <div className="form-col-right">
                <h3>Checkpoint 3 (Practice Test)</h3>
                <div className={disablePracticePublished ? 'assessment-builder__published is-disabled' : 'assessment-builder__published'}>
                  <div className="form__field toggle-row">
                    <label className="toggle-label" htmlFor="practice-is-published-span">
                      <span id="practice-is-published-span">
                        Publish Practice Test
                      </span>
                      {isPracticePublished ? (
                        <BetterTooltip content={tooltips.publishingPracticeTestPublished} indicate position={PositionEnum.Bottom} />
                      ) : (
                        <BetterTooltip content={isPublished ? tooltips.publishingPracticeTestStudyPathPublished : tooltips.publishingPracticeTestStudyPathUnpublished} indicate position={PositionEnum.Bottom} />
                      )}
                    </label>
                    <ToggleSwitch
                      id='practice-is-published'
                      checked={isPracticePublished}
                      disabled={disablePracticePublished}
                      onChange={() => setForm(FormKeyEnum.IsPracticePublished, !isPracticePublished)}
                      data-on="YES"
                      data-off="NO"
                    />
                  </div>
                  <span className="published-info">
                    Once published, students will be able to start the Practice Test if (1) they have completed all assessments and moved all topics into Checkpoint 3 or (2) the Study Path is due in three or fewer days.
                  </span>
                </div>
                {course.isGradeSyncEnabled && (
                  <>
                    {renderGradeSyncToggle(isPracticePublished, FormKeyEnum.IsPracticeGradeSyncEnabled, isPracticeGradeSyncEnabled, 'Enable Sync to LMS')}
                  </>
                )}
                <div className="assessment-builder__summative-settings">
                  <CombinedAttemptsPolicy
                    assessType={AssessTypeEnum.PracticeTest}
                    isDisabled={isPracticeStarted}
                    label="Practice Test Grading Policy"
                    onChange={handleSetPolicy}
                    value={practicePolicy}
                  />
                </div>
                <LatePolicyFields
                  dateInvalid={hasInvalidDueLatePracticeDates}
                  dateValue={practiceLateDate}
                  disabled={multipleAttemptPoliciesWithoutLatePolicy.includes(practicePolicy)}
                  fieldNamePrefix='practice_test-'
                  fieldName="Practice Test Late Policy"
                  hasBeenStarted={isPracticeStarted}
                  latePolicy={practiceLatePolicy}
                  minDate={minDueDate}
                  maxDate={defaultMaxDueDate}
                  onChangeDate={(dateVal) => handleDateChange(DatePickerEnum.PracticeLateDate, dateVal)}
                  onChangePenalty={(val) => { setFormChanged(true); setPracticeLatePenalty(val); }}
                  onChangePolicy={(val) => { handleLatePolicyChange(DatePickerEnum.PracticeLatePolicy, val); }}
                  penaltyDisabled={isPracticeStarted && DateTime.fromJSDate(dueDate) < now}
                  penaltyName="practiceLatePenalty"
                  penaltyValue={practiceLatePenalty}
                  toggleDisabled={disablePracticeToggle}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="form-wrapper">
            <div className="form-col-left">
              <div className="form__field">
                <label>
                  Assessment Name
                  <input
                    type="text"
                    className="assessment-builder__assessment-name"
                    name={FormKeyEnum.AssessmentName}
                    onChange={({ target: { value } }) => setForm(FormKeyEnum.AssessmentName, value)}
                    value={assessmentName}
                    required
                  />
                </label>
              </div>
              {!editingAssessment && (
                <div className="form__field">
                  <label htmlFor="assessment-builder__select assess-type-select">
                    Assessment Category <BetterTooltip content={() => tooltips.assessmentCategory} indicate position={PositionEnum.BottomRight} />
                    <Select
                      options={assessTypeOptions}
                      value={assessTypeOptions.find(({ value }) => value === assessType)}
                      className="assessment-builder__select assess-type-select"
                      name="assessment-builder__select assess-type-select"
                      onChange={(type) => !!type && setForm(FormKeyEnum.AssessType, type.value)}
                    />
                  </label>
                </div>
              )}
              <div className="form__field">
                <SelectClassDaysCovered
                  className="class-session-dropdown"
                  selectedClassIds={selectedClassIds}
                  classSessions={classSessions}
                  onChange={(updatedClassIds) => setForm(FormKeyEnum.SelectedClassIds, updatedClassIds)}
                />
              </div>
              <div className="form__field toggle-row">
                <label className="toggle-label" htmlFor="is-published-span">
                  <span id="is-published-span">
                    Publish Assessment
                  </span>
                  <BetterTooltip content={tooltips.publishing} indicate position={PositionEnum.Bottom} />
                </label>
                <ToggleSwitch
                  id='is-published'
                  checked={isPublished}
                  disabled={hasBeenStarted}
                  onChange={() => setForm(FormKeyEnum.IsPublished, !isPublished)}
                  data-on="YES"
                  data-off="NO"
                />
              </div>
              {course.isGradeSyncEnabled && (
                <>
                  {renderGradeSyncToggle(isPublished, FormKeyEnum.IsGradeSyncEnabled, isGradeSyncEnabled, 'Enable Sync to LMS')}
                  <br/>
                </>
              )}
              <div className="form__field">
                <label>
                  Instructions
                  <textarea
                    className="assessment-builder__instructions"
                    name={FormKeyEnum.Instructions}
                    value={instructions}
                    onChange={({ target: { value } }) => setForm(FormKeyEnum.Instructions, value)}
                  />
                </label>
              </div>
            </div>
            <div className="form-col-right">
              {hasBeenStarted && (
                <div className="assessment-builder__message">
                  Students have started this assessment. Some settings can no longer be changed.
                  <BetterTooltip
                    content={hasBeenStartedMessage(isSummative)}
                    indicate
                    position={PositionEnum.Left}
                  />
                </div>
              )}
              <CombinedAttemptsPolicy
                assessType={assessType}
                isDisabled={!isSummative && hasBeenStarted}
                label="Grading Policy"
                onChange={handleSetPolicy}
                value={selectedPolicy}
              />
              <div className="form__field">
                <label htmlFor="open-date-picker">
                  Date Open To Students
                  <DateTimePicker
                    className="assessment-builder__datetime-picker open-date-picker"
                    name="open-date-picker"
                    disabled={hasBeenStarted}
                    invalid={hasInvalidOpenDueDates && !hasBeenStarted}
                    minDate={defaultMinOpenDate}
                    maxDate={defaultMaxDueDate}
                    onChange={(dateVal) => handleDateChange(DatePickerEnum.OpenDate, dateVal)}
                    value={openDate}
                  />
                </label>
              </div>
              <div className="form__field">
                <label htmlFor="due-date-picker">
                  Due Date
                  <div className="assessment-builder__due-date">
                    <DateTimePicker
                      className="assessment-builder__datetime-picker due-date-picker"
                      name="due-date-picker"
                      disabled={false}
                      invalid={hasInvalidOpenDueDates || hasInvalidDueLateDates}
                      limitTime={hasBeenStarted}
                      minDate={minDueDate}
                      maxDate={defaultMaxDueDate}
                      onChange={(dateVal) => handleDateChange(DatePickerEnum.DueDate, dateVal)}
                      value={dueDate}
                    />
                  </div>
                </label>
              </div>
              <LatePolicyFields
                disabled={multipleAttemptPoliciesWithoutLatePolicy.includes(selectedPolicy)}
                dateInvalid={hasInvalidDueLateDates}
                dateValue={lateDate}
                fieldName="Late Policy"
                hasBeenStarted={hasBeenStarted}
                latePolicy={latePolicy}
                minDate={minDueDate}
                maxDate={defaultMaxDueDate}
                onChangeDate={(dateVal) => handleDateChange(DatePickerEnum.LateDate, dateVal)}
                onChangePenalty={(val) => { setFormChanged(true); setLatePenalty(val); }}
                onChangePolicy={(val) => { handleLatePolicyChange(DatePickerEnum.LatePolicy, val); }}
                penaltyDisabled={hasBeenStarted && DateTime.fromJSDate(dueDate) < now}
                penaltyName="latePenalty"
                penaltyValue={latePenalty}
                toggleDisabled={multipleAttemptPoliciesWithoutLatePolicy.includes(selectedPolicy) || (hasBeenStarted && existingLatePolicy === YesNo.Yes && (DateTime.fromJSDate(dueDate) < now || DateTime.fromJSDate(lateDate as Date) < now))}
              />
            </div>
          </div>
        )}
        <AssessmentBuilderActionBar
          leftSideButtons={[
            {
              className: 'assessment-details__delete',
              disabled: hasBeenStarted,
              itemType: ItemTypeEnum.SecondaryBtn,
              itemId: 'deleteAssessment',
              itemText: 'DELETE',
              onClick: () => !!editingAssessment && onRemoveAssessment(editingAssessment.id, hasBeenStarted),
              show: !!editingAssessment,
            },
          ]}
          rightSideButtons={[
            {
              className: 'assessment-builder__continue',
              disabled: submitDisabled,
              itemType: ItemTypeEnum.LoadingBtn,
              itemId: 'saveAndContinue',
              itemText: 'SAVE AND CONTINUE',
              loading: submitting,
              loadingText: 'SAVING...',
              onClick: () => handleSubmit(),
            },
          ]}
        />
      </form>
    </div>
  );
}

AssessmentDetails.propTypes = {
  setTab: PropTypes.func.isRequired,
  onSaveAssessmentDetails: PropTypes.func.isRequired,
  onRemoveAssessment: PropTypes.func.isRequired,
  setFormIsDirty: PropTypes.func.isRequired,
  startedAssessmentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AssessmentDetails;
