/**
* retrieveAssessmentsWithEnrollment
* this selector merges any enrollment-assessments for the student with assessments
* replaces prior confusingly named selector
*/
import { createSelector } from '@reduxjs/toolkit';
import cloneDeep from 'lodash-es/cloneDeep';
import { EnrollmentAssessmentApi } from 'types/backend/enrollmentAssessments.types';
import { AssessmentApiBase, SummativeAssessmentApi } from 'types/backend/assessments.types';
import { Store } from 'types/store.types';
import sortAssessments from 'utils/sortAssessments';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';

export interface AssessmentWithEnrollment extends AssessmentApiBase {
  enrollmentAssessmentOpenDate: string | null
  enrollmentAssessmentDueDate: string | null
  mergedOpenDate: string
  mergedDueDate: string
  questionCount: number
}

export type SummativeAssessmentWithEnrollment = AssessmentWithEnrollment & SummativeAssessmentApi

// enrichAssessmentsWithEnrollment gets to be its own function so we can add the enrollment info as needed if we don't need the full selector array, also helps with testing
export const enrichAssessmentsWithEnrollment = (
  assessments: Array<AssessmentApiBase>,
  enrollmentAssessments: Array<EnrollmentAssessmentApi>,
  assessmentQuestionMaps: Array<AssessmentQuestionApi>
) => assessments.map(assessment => {
  const { id: assessmentId, openDate, dueDate } = assessment;
  const enrollmentAssessment = enrollmentAssessments.find(ea => ea.assessmentId === assessmentId);
  let enrollmentAssessmentOpenDate = null;
  let enrollmentAssessmentDueDate = null;
  if (enrollmentAssessment) {
    enrollmentAssessmentOpenDate = enrollmentAssessment.openDate;
    enrollmentAssessmentDueDate = enrollmentAssessment.dueDate;
  }
  const mergedOpenDate = enrollmentAssessmentOpenDate || openDate;
  const mergedDueDate = enrollmentAssessmentDueDate || dueDate;
  const questionCount = assessmentQuestionMaps.filter((aqm) => aqm.assessmentId === assessmentId).length;
  return {
    ...assessment,
    enrollmentAssessmentOpenDate,
    enrollmentAssessmentDueDate,
    mergedOpenDate,
    mergedDueDate,
    questionCount,
  };
});

export default createSelector(
  (store: Store) => store.active.assessments,
  (store: Store) => store.active.assessmentQuestionMaps,
  (store: Store) => store.active.enrollmentAssessments,
  (
    assessments: Array<AssessmentApiBase>,
    assessmentQuestionMaps: Array<AssessmentQuestionApi>,
    enrollmentAssessments: Array<EnrollmentAssessmentApi>
  ) => {
    if (!assessments) {
      return [];
    }
    assessments = cloneDeep(assessments);
    const sortedAssessments = sortAssessments(assessments);
    const enrichedAssessments: Array<AssessmentWithEnrollment | SummativeAssessmentWithEnrollment> = enrichAssessmentsWithEnrollment(sortedAssessments, enrollmentAssessments, assessmentQuestionMaps);
    return enrichedAssessments;
  }
);

