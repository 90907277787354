import React from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

export type ProtectedRouteProps = {
  isAuthorized: boolean
  redirectPath: string
} & RouteProps;

function ProtectedRoute({ isAuthorized, redirectPath, ...routeProps }: ProtectedRouteProps) {
  const history = useHistory();
  if (isAuthorized) {
    return <Route {...routeProps} />;
  } else {
    console.debug('ProtectedRoute redirect', redirectPath);
    history.push(redirectPath);
    return null;
  }
}

export default ProtectedRoute;
