import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import useEffectOnce from 'hooks/useEffectOnce';
import { useAppSelector } from 'store';
import { moveQuestionOptionsStrings as optionsStrings } from 'sharedStrings';
import { getAssessmentsWithAddRemoveStartedInfo, AssessmentWithAddRemoveStartedInfo } from 'utils/assessmentFunctions';
import { appendStartedToAssessmentName } from 'utils/commonFormattingFunctions';
import LoadingButton from 'shared-components/LoadingButton/LoadingButton';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { AssessmentQuestionMetadata, BasicQuestionForPreview } from 'utils/getAssessmentQuestionsMetadata';
import { QuestionActionEnum } from 'instructor/controllers/Course/AssessmentBuilderController/AssessmentBuilderController.types';
import { QuestionPreviewContext } from 'instructor/controllers/Course/AssessmentBuilderController/AssessmentBuilderController';
import { DropdownOption } from 'instructor/components/Dropdown/Dropdown.types';

enum QuestionStartedStatusEnum {
  Started = 'started',
  NotStarted = 'not-started',
}

function QuestionActionDropdown({
  activeQuestion,
  launchAssessmentId,
}: {
  activeQuestion: BasicQuestionForPreview | AssessmentQuestionMetadata
  launchAssessmentId: string
}) {
  const { handleQuestionAction, checkIfAssessmentQuestionIsStarted } = useContext(QuestionPreviewContext);
  const assessmentQuestions = useAppSelector((store) => store.active.assessmentQuestionMaps);
  const assessments = useAppSelector((store) => store.active.assessments);
  // accepts BasicQuestionForPreview from StudentScoresController or the more complete AssessmentQuestionMetadata for AssessmentBuilder
  const { questionId: qId } = activeQuestion as AssessmentQuestionMetadata;

  // state for the question action dropdown
  const [assessmentForQuestion, setAssessmentForQuestion] = useState<AssessmentWithAddRemoveStartedInfo>();
  const [questionActionOptions, setQuestionActionOptions] = useState([] as Array<DropdownOption<string>>);
  const [availableAssessments, setAvailableAssessments] = useState([] as Array<AssessmentWithAddRemoveStartedInfo>);
  const [questionsStartedStatus, setQuestionsStartedStatus] = useState<{[key: string]: QuestionStartedStatusEnum}>({}); // keeps track of whether a question has been started by students
  const [questionActionClosures, setQuestionActionClosures] = useState<{[key: string]: () => Promise<void>}>();
  const [buttonWords, setButtonWords] = useState<{[key: string]: {buttonText: string; loadingText: string}}>({});
  const [moveOptionsLoaded, setMoveOptionsLoaded] = useState(false);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState('');
  const [isHandlingQuestionAction, setIsHandlingQuestionAction] = useState(false);
  const [{ buttonText, loadingText }, setButtonText] = useState({ buttonText: 'Loading...', loadingText: 'Loading...' });

  const showQuestionActionButton = !!questionActionOptions.length;

  // load the assessments that a question can be moved to
  useEffectOnce(() => {
    const enableQuestionActions = async () => {
      setMoveOptionsLoaded(false);
      const bumpableAssessments = await getAssessmentsWithAddRemoveStartedInfo(assessments);
      bumpableAssessments.sort((a, b) => b.id === launchAssessmentId ? 1 : -1);
      setAvailableAssessments(bumpableAssessments);
    };
    enableQuestionActions();
  });

  // this useEffect checks if the active question has been started by students and sets the questionsStartedStatus if need be
  useEffect(() => {
    async function checkIfQuestionIsStarted() {
      if (!(activeQuestion.questionId in questionsStartedStatus)) {
        const aqForQuestion = assessmentQuestions.find(aq => aq.questionId === activeQuestion.questionId);
        if (!!aqForQuestion) {
          const hasQuestionBeenStarted = await checkIfAssessmentQuestionIsStarted(aqForQuestion.id);
          const status = hasQuestionBeenStarted ? QuestionStartedStatusEnum.Started : QuestionStartedStatusEnum.NotStarted;
          setQuestionsStartedStatus(prevStatus => ({ ...prevStatus, [aqForQuestion.questionId]: status }));
        } else {
          setQuestionsStartedStatus(prevStatus => ({ ...prevStatus, [activeQuestion.questionId]: QuestionStartedStatusEnum.NotStarted }));
        }
      }
    }
    checkIfQuestionIsStarted();
  }, [activeQuestion.questionId, questionsStartedStatus, assessmentQuestions, checkIfAssessmentQuestionIsStarted]);

  // this useEffect sets the move question dropdown options every time the active question changes
  useEffect(() => {
    async function determineQuestionMoveOptions(aqForActiveQuestion: AssessmentQuestionApi | undefined) {
      if (activeQuestion.questionId in questionsStartedStatus && questionsStartedStatus[activeQuestion.questionId] === QuestionStartedStatusEnum.NotStarted) {
        const assessmentForActiveQuestion = availableAssessments.find(a => a.id === aqForActiveQuestion?.assessmentId);
        const newQuestionActionClosures: {[key: string]: () => Promise<void>} = {};
        const newButtonWords: {[key: string]: {buttonText: string; loadingText: string}} = {};
        const options = availableAssessments.reduce((acc: Array<DropdownOption<string>>, cur) => {
          let label = '';
          let buttonString = '';
          let loadingString = '';
          if (aqForActiveQuestion && aqForActiveQuestion.assessmentId === launchAssessmentId) {
            // Move or remove from the assessment QuestionPreview was launched from
            if (cur.id === launchAssessmentId && cur.canRemoveFrom) {
              label = `Remove from this assessment (${cur.name})`;
              buttonString = optionsStrings.REMOVE;
              loadingString = optionsStrings.REMOVING;
              const payload = { questionId: activeQuestion.questionId, assessmentId: launchAssessmentId };
              newQuestionActionClosures[cur.id] = () => handleQuestionAction(QuestionActionEnum.RemoveQuestion, payload, false);
            } else if (cur.canBumpTo) {
              label = `${optionsStrings.MOVE_TO} ${appendStartedToAssessmentName(cur.name, cur.started)}`;
              buttonString = optionsStrings.MOVE;
              loadingString = optionsStrings.MOVING;
              const payload = { questionId: activeQuestion.questionId, assessmentId: launchAssessmentId, points: aqForActiveQuestion.points.toString(), destinationAssessmentId: cur.id };
              newQuestionActionClosures[cur.id] = () => handleQuestionAction(QuestionActionEnum.MoveQuestionToAssessment, payload, false);
            }
          } else if (aqForActiveQuestion && assessmentForActiveQuestion?.canRemoveFrom) {
            // move or remove from another assessment
            if (cur.id !== aqForActiveQuestion.assessmentId && cur.canBumpTo) {
              label = `${optionsStrings.MOVE_TO} ${appendStartedToAssessmentName(cur.name, cur.started)}`;
              buttonString = optionsStrings.MOVE;
              loadingString = optionsStrings.MOVING;
              const payload = { questionId: activeQuestion.questionId, assessmentId: aqForActiveQuestion.assessmentId, points: aqForActiveQuestion.points.toString(), destinationAssessmentId: cur.id };
              newQuestionActionClosures[cur.id] = () => handleQuestionAction(QuestionActionEnum.MoveQuestionToAssessment, payload, false);
            }
          } else if (cur.canBumpTo) {
            // unassigned question, so just add it to an assessment
            label = `${optionsStrings.MOVE_TO} ${appendStartedToAssessmentName(cur.name, cur.started)}`;
            label = cur.id === launchAssessmentId ? `Add to this assessment (${cur.name})` : `${optionsStrings.ADD_TO} ${appendStartedToAssessmentName(cur.name, cur.started)}`;
            buttonString = optionsStrings.ADD;
            loadingString = optionsStrings.ADDING;
            const payload = { questionId: activeQuestion.questionId, assessmentId: cur.id };
            newQuestionActionClosures[cur.id] = () => handleQuestionAction(QuestionActionEnum.AddQuestion, payload, false);
          }
          if (label) { // only add options if there is a label; some assessments can be removed from but not moved to they may not have an option
            acc.push({ label, value: cur.id });
            newButtonWords[cur.id] = { buttonText: buttonString, loadingText: loadingString };
          }
          return acc;
        }, []);

        if (options.length !== 0) {
          setQuestionActionClosures((prev) => ({ ...prev, ...newQuestionActionClosures }));
          setButtonWords((prev) => ({ ...prev, ...newButtonWords }));
          setQuestionActionOptions(() => options);
          setSelectedAssessmentId(options[0].value);
          !isHandlingQuestionAction && setButtonText(newButtonWords[options[0].value]);
        }
      }
      setMoveOptionsLoaded(true);
    }

    const aqForActiveQuestion = assessmentQuestions.find(aq => aq.questionId === activeQuestion.questionId);
    const assessmentForQuestionInfo = availableAssessments.find(a => a.id === aqForActiveQuestion?.assessmentId);
    setAssessmentForQuestion(assessmentForQuestionInfo);
    determineQuestionMoveOptions(aqForActiveQuestion);
  }, [activeQuestion.questionId, questionsStartedStatus, handleQuestionAction, availableAssessments, launchAssessmentId, assessmentQuestions, isHandlingQuestionAction]);


  const handleChangeQuestionOption = async (selectedData: DropdownOption<string> | null) => {
    if (!!questionActionOptions.length) {
      const updatedValue = selectedData?.value || '';
      setSelectedAssessmentId(updatedValue);
      setButtonText(buttonWords[updatedValue]);
    }
  };

  const onQuestionActionButtonClick = async () => {
    const closureKey = selectedAssessmentId;
    if (closureKey && !!questionActionClosures && closureKey in questionActionClosures) {
      setIsHandlingQuestionAction(true);
      const closure = questionActionClosures[closureKey];
      await closure();
      setQuestionActionOptions([]);
      setMoveOptionsLoaded(false);
      setIsHandlingQuestionAction(false);
    }
  };

  const questionCanBeMoved =
    (qId in questionsStartedStatus && questionsStartedStatus[qId] === QuestionStartedStatusEnum.NotStarted)
    && (!assessmentForQuestion || (assessmentForQuestion && assessmentForQuestion.canRemoveFrom));
  const assessmentsAvailable = !!questionActionOptions.length;

  return (
    <>
      <div>
        {!!assessmentForQuestion ? `Assigned in ${appendStartedToAssessmentName(assessmentForQuestion.name, assessmentForQuestion.started)}` : 'Currently unassigned'}
      </div>
      <div className="question-preview__actions-move">
        {questionCanBeMoved && assessmentsAvailable ? (
          <>
            <Select
              className="question-preview__actions-select"
              options={questionActionOptions}
              value={questionActionOptions.find(o => o.value === selectedAssessmentId)}
              placeholder={moveOptionsLoaded ? '' : 'loading question options...'}
              onChange={handleChangeQuestionOption}
            />
            {showQuestionActionButton && (
              <LoadingButton
                className="question-action-button"
                onClick={onQuestionActionButtonClick}
                loadingText={loadingText}
                loading={isHandlingQuestionAction}
                text={buttonText}
                disabled={!selectedAssessmentId || isHandlingQuestionAction}
              />
            )}
          </>
        ) : (
          <div className="question-preview__actions-move-text">
            {!questionCanBeMoved ? 'This item can\'t be moved' : 'No assessments available'}
          </div>
        )}
      </div>
    </>
  );
}

QuestionActionDropdown.propTypes = {
  activeQuestion: PropTypes.shape({ questionId: PropTypes.number }),
  launchAssessmentId: PropTypes.string,
};

export default QuestionActionDropdown;
