enum CodonUrls {
  CodonSite = 'https://www.codonlearning.com',
  CodonApp = 'https://app.codonlearning.com',
  CodonAppCanada = 'https://ca-app.codonlearning.com',
  SupportKB = 'https://codonlearning.freshdesk.com/support/solutions',
  HomeKB = 'https://codonlearning.freshdesk.com/support/home',
  StudentSupportKB = 'https://codonlearning.freshdesk.com/support/solutions/69000414929',
  GradingPolicyKB = 'https://codonlearning.freshdesk.com/a/solutions/articles/69000802487',
  HowToRecaptureKB = 'https://codonlearning.freshdesk.com/support/solutions/articles/69000798960-how-to-recapture-points',
  StudyPathKB = 'https://codonlearning.freshdesk.com/support/solutions/articles/69000739085-what-is-the-study-path-and-why-should-i-use-it-',
  OpenResponseAssessmentBuilderKB = 'https://codonlearning.freshdesk.com/support/solutions/articles/69000826623',
  RevisitPastQuestionsKB = 'https://codonlearning.freshdesk.com/support/solutions/articles/69000828205',
  PracticeTestLockingExplanation = 'https://codonlearning.freshdesk.com/support/solutions/articles/69000832227',
  PrivacyPolicy = 'https://www.codonlearning.com/privacy',
  LoginSupportArticle = 'https://codonlearning.freshdesk.com/support/solutions/articles/69000842304',
  LatePolicyKB = 'https://codonlearning.freshdesk.com/a/solutions/articles/69000843398',
  SurveyItemsKB = 'https://codonlearning.freshdesk.com/a/solutions/articles/69000853667',
}
export default CodonUrls;
