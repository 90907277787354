import React, { ReactElement } from 'react';
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';
import './CheckboxItem.scss';

function CheckboxItem({
  className = '',
  disabled = false,
  disabledHint,
  label,
  name,
  onClick,
  selected,
  ...props
}: {
  className?: string
  disabled?: boolean
  disabledHint?: string
  selected: boolean
  name?: string
  onClick: () => void
  label: ReactElement | string
}) {
  return (
    <button
      className={`checkbox-item__button ${className}`}
      data-selected={selected}
      disabled={disabled}
      onClick={onClick}
      name={name}
      title={(disabled && disabledHint) || ''}
      {...props}
    >
      <div className="checkbox-item__icon">
        {selected ? <FaRegCheckSquare /> : <FaRegSquare />}
      </div>
      <div className="checkbox-item__label">
        {label}
      </div>
    </button>
  );
}

export default CheckboxItem;
