import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';

export enum LoActionEnum {
  CreateLo = 'create-lo',
  EditLo = 'edit-lo',
  AddLoToClassSession = 'add-lo-to-class-session',
  RemoveLoFromClassSession = 'remove-lo-from-class-session',
  RemoveLoFromClassSessionWithQuestions = 'remove-lo-from-class-session-with-questions',
  CopyLo = 'copy-lo',
}

export interface LoActionPayload {
  title?: string
  topicId?: number
  id?: number
  aqIds?: Array<number>
  parentLearningObjectiveId?: number
}

export type LoActionDispatch = (action: LoActionEnum, payload?: LoActionPayload) => void

export enum DragTypeEnum {
  MoveLo = 'moveObjective',
  MoveTopic = 'moveTopic',
}

export interface LoItem extends LearningObjectiveApi {
  loClassSessions: Array<{ classSessionId: number; classNumber: number }>
  selected: boolean
  customTopicParent: boolean
  libraryTopicName: string
  courseTopicName: string
  parentAndCsloTopicMatch: boolean
}

export type CreateUserLoApi = Omit<LearningObjectiveApi, 'id' | 'createdAt' | 'updatedAt' | 'stringId' | 'userId'>;
