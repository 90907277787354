import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import {
  FaGripLinesVertical,
  FaShare,
  FaTrashAlt,
  FaLock,
  FaTimes,
  FaUnlink,
} from 'react-icons/fa';

import { AssessmentQuestionSelectorContext } from '../AssessmentQuestionSelector/AssessmentQuestionSelector';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { appendStartedToAssessmentName, formatPoints } from 'utils/commonFormattingFunctions';
import { getAssessmentsWithAddRemoveStartedInfo } from 'utils/assessmentFunctions';
import { useAppSelector } from 'store';
import { EnrichedEditingAssessment } from '../../enrichAssessmentForEditing';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import LoadingButton from 'shared-components/LoadingButton/LoadingButton';
import CustomItemIcon from 'shared-components/CustomItemIcon/CustomItemIcon';
import { LibraryTypeEnum, QuestionUseEnum, YesNo } from 'types/backend/shared.types';
import { AssessmentApiBase, GradingPolicyEnum } from 'types/backend/assessments.types';
import { AssessmentQuestionMetadata, QuestionPreviewLaunchWithMetadata } from 'utils/getAssessmentQuestionsMetadata';
import { DropdownOption } from 'instructor/components/Dropdown/Dropdown.types';
import { ContentTypeEnum, LoadingState, PositionEnum } from 'types/common.types';
import {
  AssessmentQuestionForQuestionListSidebar,
  QuestionAction,
  QuestionActionEnum,
  TabEnum,
  TabNavigate,
} from '../../AssessmentBuilderController.types';
import { GradingTypeTag } from 'types/backend/l8y.types';
import './QuestionListSidebar.scss';

function QuestionListSidebarSelectedQuestionsListItem({
  currentAssessment,
  hasBeenStarted,
  navigate,
  qIndex,
  question,
  questionAction,
  questionMetadata,
  setQuestionPreview,
}: {
  currentAssessment: EnrichedEditingAssessment | AssessmentApiBase
  hasBeenStarted: boolean
  navigate: TabNavigate
  qIndex: number
  question: AssessmentQuestionForQuestionListSidebar
  questionAction: QuestionAction
  questionMetadata: AssessmentQuestionMetadata
  setQuestionPreview: (preview: QuestionPreviewLaunchWithMetadata | null) => void
}) {
  const { loadingState } = useContext(AssessmentQuestionSelectorContext);
  const {
    blooms,
    courseLearningObjectives,
    l8yId,
    title,
    type,
    userId,
    id: questionId,
    gradingType,
    userId: ownerUserId,
  } = question;
  const [pointsValue, setPointsValue] = useState(formatPoints(question.points));
  const [selectedAssessmentId, setSelectedAssessmentId] = useState('');
  const cloNumbers = courseLearningObjectives.map((clo) => clo._derived.loNumber).join(', ');
  const learningObjectives = useAppSelector((store) => store.active.learningObjectives);
  const isUnalignedQuestion = courseLearningObjectives.length === 0 && learningObjectives.some(lo => question.learningObjectiveIds.includes(lo.id) && lo.isCompetency === YesNo.No);
  const assessmentForPoints = currentAssessment.gradingPolicy !== GradingPolicyEnum.NoPoints;
  const assessments = useAppSelector((store) => store.active.assessments);
  const user = useAppSelector((store) => store.user);
  const isThisUserQuestion = userId === user.id;

  // bump question related state
  const [showBumpQuestionForm, setShowBumpQuestionForm] = useState(false);
  const [assessmentOptions, setAssessmentOptions] = useState([] as Array<DropdownOption<string>>);
  const [questionIsMoving, setQuestionIsMoving] = useState(false);
  const [availableAssessmentsLoaded, setAvailableAssessmentsLoaded] = useState(false);
  const showBumpRef = useRef(null);

  useOnClickOutside(showBumpRef, () => {
    if (showBumpQuestionForm) {
      setShowBumpQuestionForm(false);
    }
  });

  const handleOnBlurQuestionPoints = ({ id }: AssessmentQuestionForQuestionListSidebar, points: string) => {
    const pointsAsFloat = parseFloat(points);
    if (isNaN(pointsAsFloat)) {
      return;
    }
    const fixedPoints = formatPoints(pointsAsFloat);
    questionAction(QuestionActionEnum.AdjustPoints, {
      questionId: id,
      assessmentId: currentAssessment.id,
      points: fixedPoints,
    });
    setPointsValue(fixedPoints);
  };

  const handleMoveQuestion = async () => {
    if (selectedAssessmentId) {
      setQuestionIsMoving(true);
      await questionAction(
        QuestionActionEnum.MoveQuestionToAssessment,
        { questionId, assessmentId: currentAssessment.id, points: pointsValue, destinationAssessmentId: selectedAssessmentId }
      );
      setQuestionIsMoving(false);
    }
    setShowBumpQuestionForm(false);
    return;
  };

  const enableBumpQuestionForm = async () => {
    setAvailableAssessmentsLoaded(false);
    setShowBumpQuestionForm(true);
    const assessmentsData = await getAssessmentsWithAddRemoveStartedInfo(assessments);
    const options = assessmentsData.reduce((acc, cur) => {
      if (cur.id !== currentAssessment.id && cur.canBumpTo) {
        acc.push({ label: `${appendStartedToAssessmentName(cur.name, cur.started)}`, value: cur.id });
      }
      return acc;
    }, [] as Array<DropdownOption<string>>);
    setAssessmentOptions(options);
    setAvailableAssessmentsLoaded(true);
  };

  const handleChangeSelectedAssessment = (selectedData: DropdownOption<string> | null) => {
    const updatedValue = selectedData?.value || '';
    setSelectedAssessmentId(updatedValue);
  };

  const unalignedQuestionTooltip = (
    <div className="selected-question-list-item__info-bar-tooltip">
      <strong>Alignment Alert</strong>: This question is not associated with any LO in your course. Students won't be able to see this question in the Study Path.
      <br/><br/>
      Recommendation: <button onClick={() => questionAction(QuestionActionEnum.AddCslos, { questionId, assessmentId: currentAssessment.id })}>Add its linked LO</button> to your course
      {(type === LibraryTypeEnum.Template || isThisUserQuestion) && <>, or <button onClick={() => navigate(TabEnum.EditQuestion, question)}>link the question to one of your course LOs</button></>}.
    </div>
  );

  const actionsDisabled = loadingState !== LoadingState.Loaded || question.isLocked;
  return (
    <Draggable key={question.id} draggableId={`${question.id}`} index={qIndex} isDragDisabled={hasBeenStarted}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          data-l8yid={l8yId}
          ref={provided.innerRef}
          className={`question-list-sidebar__selected-question-draggable ${snapshot.draggingOver ? 'is-dragging-over' : ''}`}
          style={provided.draggableProps.style}
        >
          <div className="question-list-sidebar__selected-question-wrapper">
            <div className="question-list-sidebar__selected-question" data-questionid={question.id}>
              <div className="selected-question-list-item__drag-handle">
                <FaGripLinesVertical />
              </div>
              <div className="selected-question-list-item__content">
                <div className="selected-question-list-item__title">
                  <button
                    className="selected-question-list-item__title-text"
                    onClick={() => setQuestionPreview({ initialQuestionId: questionMetadata.questionId, questions: [questionMetadata], activeAssessmentId: currentAssessment.id })}
                    title="Click to preview this item"
                  >
                    {title}
                  </button>
                  {gradingType === GradingTypeTag.Survey && (
                    <span className="question-list__badge">
                      Survey
                    </span>
                  )}
                </div>
                <div className="selected-question-list-item__info-row">
                  <div className="selected-question-list-item__info-bar">
                    <div>Q{qIndex + 1}</div>
                    {isUnalignedQuestion && (
                      <div>
                        <BetterTooltip
                          content={unalignedQuestionTooltip}
                          position={PositionEnum.Bottom}
                        >
                          <FaUnlink/>
                        </BetterTooltip>
                      </div>
                    )}
                    {!!cloNumbers && (
                      <div>
                        {cloNumbers}
                      </div>
                    )}
                    {!!blooms && (
                      <div>
                        Bloom's {blooms}
                      </div>
                    )}
                  </div>
                  <div className="selected-question-list-item__icon-bar">
                    {type === LibraryTypeEnum.User && (
                      <CustomItemIcon
                        onClick={() => navigate(TabEnum.EditQuestion, question)}
                        className="selected-question-list-item__icon"
                        ownerUserId={ownerUserId}
                        contentType={ContentTypeEnum.Question}
                      />
                    )}
                    <button
                      className={actionsDisabled ? 'selected-question-list-item__icon-disabled' : 'selected-question-list-item__icon'}
                      disabled={actionsDisabled}
                      onClick={enableBumpQuestionForm}
                      data-questionaction={QuestionActionEnum.MoveQuestionToAssessment}
                      data-isselected={showBumpQuestionForm}
                    >
                      {!actionsDisabled && (
                        <>
                          {showBumpQuestionForm
                            ? (
                              <span>
                                <FaShare />
                              </span>
                            )
                            : <FaShare />
                          }
                        </>
                      )}
                    </button>
                    <button
                      className={actionsDisabled ? 'selected-question-list-item__icon-disabled' : 'selected-question-list-item__icon'}
                      disabled={actionsDisabled}
                      data-questionaction={QuestionActionEnum.RemoveQuestion}
                      onClick={() => questionAction(QuestionActionEnum.RemoveQuestion, { questionId: question.id, assessmentId: currentAssessment.id })}
                    >
                      {actionsDisabled ? (
                        <BetterTooltip
                          content="Students have answered this item. You may no longer remove it, move it to another assessment, or change points assigned."
                          position={PositionEnum.Bottom}
                        >
                          <FaLock className='selected-question-list-item__icon-lock'/>
                        </BetterTooltip>
                      ) : <FaTrashAlt />}
                    </button>
                  </div>
                </div>
              </div>
              {assessmentForPoints && (
                <div className="selected-question-list-item__points">
                  {actionsDisabled ? (
                    <div className="points-display">
                      {pointsValue}
                    </div>
                  ) : (
                    <input
                      type="text"
                      id={`points-${question.id}`}
                      name={`points-${question.id}`}
                      value={pointsValue}
                      onChange={(e) => setPointsValue(e.target.value)}
                      onBlur={(e) => handleOnBlurQuestionPoints(question, e.target.value)}
                      disabled={actionsDisabled}
                    />
                  )}
                  <div>pt(s)</div>
                </div>
              )}
            </div>
            {showBumpQuestionForm && (
              <div className="bump-question__container" ref={showBumpRef}>
                <div className="bump-question__menu-header">
                  <div>
                    {availableAssessmentsLoaded
                      ? 'Move item to...'
                      : 'Loading available assessments'
                    }
                  </div>
                  <button className="bump-question__popup-close-button" onClick={() => setShowBumpQuestionForm(false)}>
                    <FaTimes className="bump-question__popup-close-button-icon"/>
                  </button>
                </div>
                <div className="bump-question__menu-body">
                  <div className="bump-question__assessment-dropdown">
                    {availableAssessmentsLoaded && (
                      <Select
                        options={assessmentOptions}
                        placeholder={!!assessmentOptions.length ? 'Select Assessment' : 'No available assessments'}
                        onChange={handleChangeSelectedAssessment}
                      />
                    )}
                  </div>
                  <div className="bump-question__assessment-button">
                    <LoadingButton
                      loadingText="Saving Changes"
                      loading={questionIsMoving}
                      text="Move"
                      type="button"
                      onClick={handleMoveQuestion}
                      disabled={!selectedAssessmentId}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
}


QuestionListSidebarSelectedQuestionsListItem.propTypes = {
  currentAssessment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assessType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    classSessionIds: PropTypes.array,
    gradingPolicy: PropTypes.oneOf(Object.values(GradingPolicyEnum)).isRequired,
  }).isRequired,
  hasBeenStarted: PropTypes.bool,
  question: PropTypes.shape({
    assessmentQuestionId: PropTypes.number,
    blooms: PropTypes.number,
    courseLearningObjectives: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      _derived: PropTypes.shape({
        loNumber: PropTypes.string.isRequired,
      }).isRequired,
    })).isRequired,
    gradingType: PropTypes.oneOf(Object.values(GradingTypeTag)).isRequired,
    id: PropTypes.number.isRequired,
    isLocked: PropTypes.bool,
    l8yId: PropTypes.string.isRequired,
    learningObjectiveIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    questionUse: PropTypes.oneOf(Object.values(QuestionUseEnum)).isRequired,
    points: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(LibraryTypeEnum)).isRequired,
    userId: PropTypes.string.isRequired,
  }),
  qIndex: PropTypes.number.isRequired,
  questionAction: PropTypes.func.isRequired,
  setQuestionPreview: PropTypes.func.isRequired,
};

export default QuestionListSidebarSelectedQuestionsListItem;
