import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { EventKeyEnum, EventKeyTypeEnum } from 'types/common.types';

function useEscapeKey(handleClose: () => void) {
  const handleEscKey = useCallback((event) => {
    if (event.key === EventKeyEnum.Escape) {
      handleClose();
    }
  }, [handleClose]);

  useEffect(() => {
    document.addEventListener(EventKeyTypeEnum.KeyUp, handleEscKey, false);

    return () => {
      document.removeEventListener(EventKeyTypeEnum.KeyUp, handleEscKey, false);
    };
  }, [handleEscKey]);
}

useEscapeKey.proptypes = {
  handleClose: PropTypes.func.isRequired,
};
export default useEscapeKey;
