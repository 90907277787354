import apiNext from 'api-next';
import { DateTime } from 'luxon';
import { comparePrepPracticeAssessments } from 'utils/assessmentFunctions';
import validateAssessmentUpdatesForWorker from 'utils/assessments/validateAssessmentUpdatesForWorker';
import validateAssessmentPublishedUpdatesForWorker from 'utils/assessments/validateAssessmentPublishedUpdatesForWorker';
import sharedStrings from 'sharedStrings';
import reloadAssessmentQuestions from 'store/actions/reloadAssessmentQuestions';
import { AppDispatch, Store } from 'types/store.types';
import { EnrichedEditingAssessment } from 'instructor/controllers/Course/AssessmentBuilderController/enrichAssessmentForEditing';
import { FormValues, AssessmentWorkerChangeType } from 'instructor/controllers/Course/AssessmentBuilderController/AssessmentBuilderController.types';
import { AssessmentApiBase, SummativeAssessmentApi } from 'types/backend/assessments.types';
import activeSlice from 'store/slices/active';

export default function updateStudyPath(currentEditingAssessment: EnrichedEditingAssessment, assessmentDetails: FormValues, startedAssessmentIds: Array<string>, triggerAssessmentWorker: Function, notifySuccess: Function) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const toastAutoCloseDelay = Number(process.env.REACT_APP_TOAST_AUTOCLOSE_DELAY) || 3000;
    const { active: { assessments } } = getStore();
    // get all assessments from store to start with
    const currentSummativeAssessment = assessments.find(({ id }) => id === currentEditingAssessment.id) as SummativeAssessmentApi;
    const { prep: { id: prepAssessmentId }, practiceTest: { id: practiceAssessmentId } } = currentSummativeAssessment;
    const currentFullPrepAssessment = assessments.find(({ id }) => id === prepAssessmentId) as AssessmentApiBase;
    const currentFullPracticeAssessment = assessments.find(({ id }) => id === practiceAssessmentId) as AssessmentApiBase;

    const {
      name: oldName,
      published: oldPublished,
      dueDate: oldDueDate,
    } = currentSummativeAssessment;
    const {
      prep: newPrep,
      practiceTest: newPractice,
      name: newName,
      published: newPublished,
      dueDate: newDueDateJs,
    } = assessmentDetails;
    if (!currentFullPrepAssessment || !currentFullPracticeAssessment || !newPrep || !newPractice) {
      throw new Error(`updateStudyPath missing required data ${JSON.stringify({ currentFullPrepAssessment, currentFullPracticeAssessment, newPrep, newPractice })}`);
    }

    // determine new openDate for studypath
    const newDueDateLuxon = DateTime.fromJSDate(newDueDateJs);
    const dueMinusMinuteForOpenDate = newDueDateLuxon.minus({ minutes: 1 });
    const newDueDateISO = newDueDateLuxon.toISO();
    const dueMinusMinuteForOpenDateISO = dueMinusMinuteForOpenDate.toISO();

    const summativeDetailsChanged = oldName !== newName
      || oldPublished !== newPublished
      || +DateTime.fromISO(oldDueDate) !== +newDueDateLuxon;

    const prepHasChanged = comparePrepPracticeAssessments(currentFullPrepAssessment, { ...newPrep, lateDate: !!newPrep.lateDate ? DateTime.fromJSDate(newPrep.lateDate).toUTC().toISO() : null });
    const practiceHasChanged = comparePrepPracticeAssessments(currentFullPracticeAssessment, { ...newPractice, lateDate: !!newPractice.lateDate ? DateTime.fromJSDate(newPractice.lateDate).toUTC().toISO() : null });

    const { prep, practiceTest, ...currentAssessmentData } = currentSummativeAssessment;
    // aggregate data for update request
    let newSummativeAssessment: SummativeAssessmentApi = {
      ...currentAssessmentData,
      prep,
      practiceTest,
    };

    if (summativeDetailsChanged) {
      console.debug('summativeDetailsChanged', newName, newPublished, newDueDateJs);
      newSummativeAssessment = {
        ...newSummativeAssessment,
        name: newName,
        published: newPublished,
        openDate: dueMinusMinuteForOpenDateISO,
        dueDate: newDueDateISO,
        prep: {
          ...prep,
          name: `${newName} Prep Questions`,
          openDate: dueMinusMinuteForOpenDateISO,
          dueDate: newDueDateISO,
        },
        practiceTest: {
          ...practiceTest,
          name: `${newName} Practice Test`,
          openDate: dueMinusMinuteForOpenDateISO,
          dueDate: newDueDateISO,
        },
      };
    }
    if (prepHasChanged) {
      const { gradingPolicy, pointPenalty, freeAttempts, isGradeSyncEnabled, lateDate, latePenalty, latePolicy, published } = newPrep;
      console.debug('prepPolicyChanged', gradingPolicy, pointPenalty, freeAttempts, isGradeSyncEnabled, lateDate, latePenalty, latePolicy, published);
      const { prep: newPrepAssessment } = newSummativeAssessment;
      newSummativeAssessment = {
        ...newSummativeAssessment,
        prep: {
          ...newPrepAssessment,
          isGradeSyncEnabled,
          gradingPolicy,
          pointPenalty,
          freeAttempts,
          lateDate: !!lateDate ? DateTime.fromJSDate(lateDate).toUTC().toISO() : null,
          latePenalty,
          latePolicy,
          published,
        },
      };
    }
    if (practiceHasChanged) {
      const { gradingPolicy, pointPenalty, freeAttempts, isGradeSyncEnabled, lateDate, latePenalty, latePolicy, published } = newPractice;
      console.debug('practicePolicyChanged', gradingPolicy, pointPenalty, freeAttempts, isGradeSyncEnabled, lateDate, latePenalty, latePolicy, published);
      const { practiceTest: newPracticeAssessment } = newSummativeAssessment;
      newSummativeAssessment = {
        ...newSummativeAssessment,
        practiceTest: {
          ...newPracticeAssessment,
          isGradeSyncEnabled,
          gradingPolicy,
          pointPenalty,
          freeAttempts,
          lateDate: !!lateDate ? DateTime.fromJSDate(lateDate).toUTC().toISO() : null,
          latePenalty,
          latePolicy,
          published,
        },
      };
    }

    const isPublishedWorkerProcessingEligible = validateAssessmentPublishedUpdatesForWorker(currentSummativeAssessment, newSummativeAssessment) ||
      validateAssessmentPublishedUpdatesForWorker(prep, newSummativeAssessment.prep) ||
      validateAssessmentPublishedUpdatesForWorker(practiceTest, newSummativeAssessment.practiceTest);

    const isRegradeWorkerProcessingEligible = validateAssessmentUpdatesForWorker(currentSummativeAssessment, newSummativeAssessment, startedAssessmentIds) ||
      validateAssessmentUpdatesForWorker(prep, newSummativeAssessment.prep, startedAssessmentIds) ||
      validateAssessmentUpdatesForWorker(practiceTest, newSummativeAssessment.practiceTest, startedAssessmentIds);

    if (isPublishedWorkerProcessingEligible) {
      return await triggerAssessmentWorker(currentSummativeAssessment, newSummativeAssessment, AssessmentWorkerChangeType.Published, { prepHasChanged, practiceHasChanged });
    } else if (isRegradeWorkerProcessingEligible) {
      notifySuccess(`${newSummativeAssessment.name} Study Path ${sharedStrings.ASSESSMENT_DATES_UPDATE_IN_PROGRESS}`, toastAutoCloseDelay, 'info');
      return await triggerAssessmentWorker(currentSummativeAssessment, newSummativeAssessment, AssessmentWorkerChangeType.Dates, { prepHasChanged, practiceHasChanged });
    }

    const updatedSummative = await apiNext.editAssessment(newSummativeAssessment.id, newSummativeAssessment) as SummativeAssessmentApi;
    console.debug('updatedSummative', updatedSummative);

    const { prep: updatedPrep, practiceTest: updatedPractice } = updatedSummative;
    dispatch(activeSlice.actions.editActiveAssessment({ id: prepAssessmentId, delta: updatedPrep }));
    dispatch(activeSlice.actions.editActiveAssessment({ id: practiceAssessmentId, delta: updatedPractice }));

    // reload AQs because points change if GP changed from no points <-> points
    if (prepHasChanged) {
      await dispatch(reloadAssessmentQuestions([prepAssessmentId]));
    }
    if (practiceHasChanged) {
      await dispatch(reloadAssessmentQuestions([practiceAssessmentId]));
    }
    dispatch(activeSlice.actions.editActiveAssessment({
      id: currentSummativeAssessment.id,
      delta: {
        ...updatedSummative,
        prep: updatedPrep,
        practiceTest: updatedPractice,
        studyPathId: currentSummativeAssessment.studyPathId,
      },
    }));

    return {
      ...updatedSummative,
      prep: updatedPrep,
      practiceTest: updatedPractice,
      studyPathId: currentSummativeAssessment.studyPathId,
    };
  })();
}
