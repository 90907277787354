/* eslint-disable @typescript-eslint/no-unused-vars, react/prop-types */
// THIS FILE WILL BE DEPRECATED WITH NEW COURSE PLANNER
// TODO: libraryItems should be renamed to units
// Also TODO: lots of unused code in this component

import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { BiCog } from 'react-icons/bi';

import { getTemplateLosForUserTopics } from 'utils/courseOrganizationFunctions';
import useOnClickOutside from 'hooks/useOnClickOutside';
import TopicSelect from '../TopicSelect/TopicSelect';
import SpecialSessionRow from './SpecialSessionRow';
import SpecialSessionPopup from '../SpecialSessionPopup/SpecialSessionPopup';
import retrieveEnrichedClassSessions from 'store/selectors/retrieveEnrichedClassSessions';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { InstructorCoursePath } from 'types/instructor.types';
import { SelectItemTypeEnum } from '../../coursePlanner.types';
import './TopicGrid.scss';


function TopicGrid({
  onAddItemToClassSession,
  onRemoveItemFromClassSession,
  onAddCustomItem,
  onCopyUnitAndTopicToClassSession,
  onAddRowBelowCurrentRow,
  onChangeClassSessionType,
  onClearContentsFromClassSession,
  controllerAlertMessage,
  setControllerAlertMessage,
  saveClassSession,
}) {

  //popup setup
  const popupRef = useRef(null);

  //global state loading
  const units = useSelector((store) => store.active.units);
  const topics = useSelector((store) => store.active.topics);
  const courseId = useSelector((store) => store.active.course.id);
  const classSessions = useSelector(retrieveEnrichedClassSessions);
  const libraryLearningObjectives = useSelector(
    (store) => store.active.learningObjectives
  );
  const classSessionTopics = useSelector(
    (store) => store.active.classSessionTopics
  );
  const classSessionLearningObjectives = useSelector((store) => store.active.classSessionLearningObjectives);

  //this function creates a big list of all the selected topics. Selected means the user has assigned them to classSessions
  function buildSelectedTopicsCollection(
    classSessionsData,
    classSessionItems,
    libraryItems
  ) {
    let selectedTopicCollection = {};

    selectedTopicCollection = classSessionsData.reduce((acc, cur) => {
      acc = { ...acc, [cur.id]: [] };
      return acc;
    }, {});

    selectedTopicCollection = classSessionItems.reduce((acc, cur) => {
      const newItem = {
        ...libraryItems.find((item) => item.id === cur.topicId),
      };
      newItem.classSessionTopicId = cur.id;
      newItem.order = cur.order;
      newItem.value = newItem.id;
      newItem.label = newItem.name;
      newItem.type = 'TEMPLATE';
      acc[cur.classSessionId].push(newItem);
      return acc;
    }, selectedTopicCollection);
    return selectedTopicCollection;
  }

  const selectedClassSessionTopicsCollection = buildSelectedTopicsCollection(
    classSessions,
    classSessionTopics,
    topics
  );

  //other
  const [alertMessage, setAlertMessage] = useState('');

  function showAlertMessage(message) {
    setAlertMessage(message);
    console.debug(message);
    const timer = setTimeout(() => {
      setAlertMessage('');
    }, 3000);
    return () => clearTimeout(timer);
  }

  const courseLibrary = units.reduce((libraryUnits, { id: unitId, name, subjectId }) => {
    const unitTopics = topics.reduce((acc, cur) => {
      if (cur.unitId === unitId) {
        let topicLos = libraryLearningObjectives.reduce((los, lo) => {
          if (lo.topicId === cur.id) {
            los[lo.id] = {
              ...lo,
              name: lo.title,
            };
          }
          return los;
        }, {});
        if (cur.type === LibraryTypeEnum.User) {
          topicLos = getTemplateLosForUserTopics([cur.id], classSessionLearningObjectives, libraryLearningObjectives)
            .reduce((los, lo) => {
              los[lo.id] = {
                ...lo,
                name: lo.title,
              };
              return los;
            }, topicLos);
        }
        acc.push ({
          ...cur,
          learningObjectives: topicLos,
        });
      }
      return acc;
    }, []);
    const topicsObj = unitTopics.reduce((topicObj, value) => {
      topicObj[value.id] = value;
      return topicObj;
    }, {});
    libraryUnits[unitId] = {
      name,
      subjectId,
      topics: topicsObj,
    };
    return libraryUnits;
  }, {});

  //set the TopicOptions on the select
  const topicOptions = (() => {
    const courseTopics = [];
    topics.forEach((tt) => {
      const { name } = units.find(({ id }) => tt.unitId === id);
      courseTopics.push({
        value: tt.id,
        label: tt.name,
        type: tt.type,
        parent: {
          label: name,
          value: tt.unitId,
        },
      });
    });
    return courseTopics;
  })();

  function removeItemFromClassSession(
    itemId,
    itemType,
    classSessionId,
    libraryType,
    classSessionMapId
  ) {
    console.debug(
      `item ${itemId} of type ${itemType} for session ${classSessionId} with library type: ${libraryType} and mapId of: ${classSessionMapId} will be deleted!`
    );
    onRemoveItemFromClassSession(itemType, classSessionMapId);
  }

  //useEffect to manage alerts coming from controller
  useEffect(() => {
    if (controllerAlertMessage !== '') {
      showAlertMessage(controllerAlertMessage);
    }
    setControllerAlertMessage('');
  }, [controllerAlertMessage, setControllerAlertMessage]);

  function DayRow({ session, weekNumber, rowSpan, saveClassSession: handleSaveClassSession }) {
    const date = DateTime.fromISO(session.date);

    const noClassSession = session.classType === ClassTypeEnum.Special;
    const classPeriod = noClassSession ? 'No Class' : session.classNumber;
    const [cogMenuOpen, setCogMenuOpen] = useState(false);

    useOnClickOutside(popupRef, () => {
      if (cogMenuOpen) {
        setCogMenuOpen(false);
      }
    });

    return (
      <Fragment key={session.id}>
        <tr className="topics-grid__dayrow" key={`tr-${session.id}`}>
          <td
            className={`topics-grid__dayrow-settings ${
              weekNumber % 2 === 0 ? 'even' : 'odd'
            }Week`}
          >
            {!cogMenuOpen
              ? <BiCog onClick={() => setCogMenuOpen(true)} title="click to set non-class day"/>
              : (
                <>
                  <div className="topics-grid__dayrow-settings-menu-open"><BiCog title="click to set non-class day"/></div>
                  <div ref={popupRef}>
                    <SpecialSessionPopup
                      onClose={() => setCogMenuOpen(false)}
                      classSession={session}
                      saveClassSession={handleSaveClassSession}
                    />
                  </div>
                </>
              )}
          </td>
          {rowSpan > 0 ? (
            <td
              rowSpan={rowSpan}
              className={`topics-grid__dayrow-week ${
                weekNumber % 2 === 0 ? 'even' : 'odd'
              }Week`}
            >
              {weekNumber}
            </td>
          ) : (
            <></>
          )}
          <td
            className={`topics-grid__dayrow-item topics-grid__dayrow-class ${
              weekNumber % 2 === 0 ? 'even' : 'odd'
            }Week`}
          >
            <div
              className={`topic-class-cell__container ${
                weekNumber % 2 === 0 ? 'even' : 'odd'
              }Week`}
            >
              <div
                className={`topic-class-cell__class ${
                  weekNumber % 2 === 0 ? 'even' : 'odd'
                }Week`}
              >
                {classPeriod}
              </div>
            </div>
          </td>
          <td
            className={`topics-grid__dayrow-item topics-grid__dayrow-day ${
              weekNumber % 2 === 0 ? 'even' : 'odd'
            }Week`}
          >
            {date.toFormat('ccc')}
          </td>
          <td
            className={`topics-grid__dayrow-item topics-grid__dayrow-date ${
              weekNumber % 2 === 0 ? 'even' : 'odd'
            }Week`}
          >
            {date.toFormat("M'/'dd")}
          </td>
          {noClassSession ? (
            <SpecialSessionRow
              label={session.label}
            />
          ) : (
            <>
              <TopicSelect
                classPeriod={classPeriod}
                topicOptions={topicOptions}
                classSessionId={session.id}
                selectType={SelectItemTypeEnum.Topic}
                courseLibrary={courseLibrary}
                parentUnits={units}
                selectedTopics={selectedClassSessionTopicsCollection[session.id]}
                onAddItemToClassSession={onAddItemToClassSession}
                onRemoveItemFromClassSession={removeItemFromClassSession}
                onAddCustomItem={onAddCustomItem}
                onShowAlertMessage={showAlertMessage}
              />
            </>
          )}
        </tr>
      </Fragment>
    );
  }

  DayRow.propTypes = {
    session: PropTypes.object,
    weekNumber: PropTypes.number,
    rowSpan: PropTypes.number,
    saveClassSession: PropTypes.func,
  };

  function dayRows() {
    let weekNumber = 0;
    const dayRowsFromClassSessions = classSessions.map((session, index) => {
      let rowSpan = 0;
      const weekOffset = DateTime.fromISO(classSessions[index].date).weekNumber;
      const isNewWeek = !!(index === 0 || weekOffset !== DateTime.fromISO(classSessions[index - 1].date).weekNumber);
      let i = index;
      if (isNewWeek) {
        weekNumber = weekNumber + 1;
        do {
          rowSpan = rowSpan + 1;
          i = i + 1;
          if (i === classSessions.length) {
            break;
          }
        } while (
          weekOffset === DateTime.fromISO(classSessions[i].date).weekNumber
        );
      }
      return (
        <DayRow
          key={session.id}
          session={session}
          weekNumber={weekNumber}
          rowSpan={rowSpan}
          saveClassSession={saveClassSession}
        />
      );
    });
    return dayRowsFromClassSessions;
  }

  return (
    <div className="topics-grid__wrapper">
      <div className="topics-grid__title-bar">
        <Link to={`/instructor/course/${courseId}/${InstructorCoursePath.BetterCoursePlanner}`}>
          Go to enhanced Course Planner
        </Link>
        <div className="topics-grid__message-container">{alertMessage}</div>
      </div>
      <div
        className="topics-grid__select-modal"
        id="topics-grid-select-modal"
      ></div>
      <table className="topics-grid__table">
        <thead>
          <tr className="topics-grid__titlerow">
            <th className="topics-grid__titlerow-settings"><BiCog/></th>
            <th className="topics-grid__titlerow-week">WK</th>
            <th className="topics-grid__titlerow-class">CLASS</th>
            <th className="topics-grid__titlerow-day">DAY</th>
            <th className="topics-grid__titlerow-date">DATE</th>
            <th className="topics-grid__titlerow-topic">TOPIC</th>
          </tr>
        </thead>
        <tbody>{dayRows()}</tbody>
      </table>
    </div>
  );
}

TopicGrid.propTypes = {
  onAddItemToClassSession: PropTypes.func,
  onRemoveItemFromClassSession: PropTypes.func,
  onAddCustomItem: PropTypes.func,
  onCopyUnitAndTopicToClassSession: PropTypes.func,
  onAddRowBelowCurrentRow: PropTypes.func,
  onChangeClassSessionType: PropTypes.func,
  onClearContentsFromClassSession: PropTypes.func,
  controllerAlertMessage: PropTypes.string,
  setControllerAlertMessage: PropTypes.func,
  saveClassSession: PropTypes.func,
};

export default TopicGrid;
