import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Store } from 'types/store.types';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { DateTime } from 'luxon';

import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { isAdminRole } from 'utils';
import apiNext from 'api-next';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import AdminDataTable from './AdminDataTable';
import {
  ReportParamsEnum,
  SaqaProjection,
  SystemReportsEnum,
  SystemReportsRequest,
  TimeGroupByEnum,
} from 'types/backend/systemReports.types';
import { GenericObject, YesNo } from 'types/backend/shared.types';
import { DropdownOption } from 'instructor/components/Dropdown/Dropdown.types';
import './SystemReports.scss';


interface SaqaDailyProjection {
  day: string
  questionCount: number
  enrollmentCount: number
  impact: number
  studentAssessmentsStarted: number
  studentAttemptsMade: number
  remainingImpact: number
}

function updateDailyTotals(
  dailyTotals: SaqaDailyProjection,
  assessmentRow: {
    question_count: number
    enrollment_count: number
    impact: number
    student_assessments_started: number
    student_attempts_made: number
  },
  metric: number
) {
  const {
    question_count,
    enrollment_count,
    impact,
    student_assessments_started,
    student_attempts_made,
  } = assessmentRow;
  return {
    ...dailyTotals,
    questionCount: dailyTotals.questionCount + Math.round(question_count * metric),
    enrollmentCount: dailyTotals.enrollmentCount + Math.round(enrollment_count * metric),
    impact: dailyTotals.impact + Math.round(impact * metric),
    studentAssessmentsStarted: dailyTotals.studentAssessmentsStarted + Math.round(student_assessments_started * metric),
    studentAttemptsMade: dailyTotals.studentAttemptsMade + Math.round(student_attempts_made * metric),
  };
}

function processAssessments(assessments: Array<SaqaProjection>, previousDayTotals: SaqaDailyProjection, currentDayTotals: SaqaDailyProjection) {
  assessments.forEach((assessmentRow: SaqaProjection) => {
    const { due_date, time_zone } = assessmentRow;
    const dueDate = DateTime.fromISO(due_date);
    const dueDateInCourseZone = dueDate.setZone(time_zone);
    const dueTimeHour = dueDateInCourseZone.hour;
    if (dueTimeHour < 10) {
      //it's before 10 AM in the course's timezone, so add 50% to the previous day and current day
      const metric = 0.5;
      previousDayTotals = updateDailyTotals({ ...previousDayTotals }, assessmentRow, metric);
      currentDayTotals = updateDailyTotals({ ...currentDayTotals }, assessmentRow, metric);
    } else if (dueTimeHour < 12) {
      //it's before 12 PM in the course's timezone, add 25% to the previous day and 75% to current
      const prevDayMetric = 0.25;
      const curDayMetric = 0.75;
      previousDayTotals = updateDailyTotals({ ...previousDayTotals }, assessmentRow, prevDayMetric);
      currentDayTotals = updateDailyTotals({ ...currentDayTotals }, assessmentRow, curDayMetric);
    } else {
      currentDayTotals = updateDailyTotals({ ...currentDayTotals }, assessmentRow, 1);
    }
  });
  return { previousDayTotals, currentDayTotals };
}


export default function SystemReports() {
  const [selectedReport, setSelectedReport] = useState<SystemReportsEnum | ''>('');
  const [reportResults, setReportResults] = useState<{ [key: string]: any }>({});

  //form state values
  const now = DateTime.local();
  const [courseStartDate, setCourseStartDate] = useState(now.toJSDate());
  const [assessmentDueDate, setAssessmentDueDate] = useState(now.toJSDate());
  const [includeCodonEmail, setIncludeCodonEmail] = useState(false);
  const [showGreatestHits, setShowGreatestHits] = useState(false);
  const [showDailyProjection, setShowDailyProjection] = useState(false);
  const [shouldLoadSaqaData, setShouldLoadSaqaData] = useState(YesNo.No);

  const user = useSelector((store: Store) => store.user);

  // state for saqas report
  const timeOptions: Array<DropdownOption<string>> = Object.entries(TimeGroupByEnum).map(([key, value]) => {
    return { value, label: key };
  });
  const [timeGroupBy, setTimeGroupBy] = useState(timeOptions.find(o => o.value === TimeGroupByEnum.Hour) as DropdownOption<string>);
  const [saqasByTimeFormatData, setSaqasByTimeFormatData] = useState({ timeLabel: '', timeFormat: '' });

  const getTimeFormatString = (value: TimeGroupByEnum) => {
    switch (value) {
      case TimeGroupByEnum.Day:
        return DateFormatEnum.WeekdayWithShortDateFullYear;
      case TimeGroupByEnum.Minute:
        return `${DateFormatEnum.WeekdayWithShortDateNoYear} ${DateFormatEnum.TimeZoneShort}`;
      case TimeGroupByEnum.Hour:
      default:
        return `${DateFormatEnum.WeekdayDateAtHour} ${DateFormatEnum.TimeZoneShort}`;
    }
  };

  const loadReport = async (report: string) => {
    if (isAdminRole(user)) {
      const params: SystemReportsRequest = {
        report,
        courseStartDate: DateTime.fromJSDate(courseStartDate).toISODate(),
        includeCodonEmail: includeCodonEmail.toString(),
        showGreatestHits: showGreatestHits.toString(),
        assessmentDueDate: DateTime.fromJSDate(assessmentDueDate).toISODate(),
        timeGroupBy: timeGroupBy?.value as TimeGroupByEnum,
        loadSaqaData: shouldLoadSaqaData,
      };
      const result = await apiNext.getSystemReport(params);
      const newResults = { ...reportResults };
      newResults[report] = result;
      setReportResults(newResults);
      if (report === SystemReportsEnum.SaqasByTime) {
        setSaqasByTimeFormatData({ timeLabel: timeGroupBy.label, timeFormat: getTimeFormatString((timeGroupBy.value) as TimeGroupByEnum) });
      }
    }
  };

  const handleTimeGroupByChange = (val: DropdownOption<string> | null) => {
    if (val) {
      setTimeGroupBy(val);
    }
  };

  const renderReportParamsForm = (params: Array<ReportParamsEnum>) => {
    return (
      <form className="system-reports__params-form">
        {params.includes(ReportParamsEnum.CourseStartDate) && (
          <>
            <label htmlFor="course-start-date">
              Oldest Course Start Date:
            </label>
            <div className="form-field-spacer" />
            <DatePicker
              name="course-start-date"
              onChange={val => !!val && setCourseStartDate(val)}
              selected={courseStartDate}
            />
            <div className="form-field-spacer" />
          </>
        )}
        {params.includes(ReportParamsEnum.IncludeCodonEmail) && (
          <>
            <label htmlFor="include-codon-email">
              Include Codon Emails:
            </label>
            <div className="form-field-spacer" />
            <input
              id="include-codon-email"
              type="checkbox"
              checked={includeCodonEmail}
              onChange={() => setIncludeCodonEmail(!includeCodonEmail)}
            />
            <div className="form-field-spacer" />
          </>
        )}
        {params.includes(ReportParamsEnum.AssessmentDueDate) && (
          <>
            <label htmlFor="assessment-due-date">
              Starting Assessment Due Date:
            </label>
            <div className="form-field-spacer" />
            <DatePicker
              name="assessment-due-date"
              onChange={val => !!val && setAssessmentDueDate(val)}
              selected={assessmentDueDate}
            />
            <div className="form-field-spacer" />
          </>
        )}
        {params.includes(ReportParamsEnum.GreatestHits) && (
          <>
            <label htmlFor="show-greatest-hits">
              Show Greatest Hits:
            </label>
            <div className="form-field-spacer" />
            <input
              id="show-greatest-hits"
              type="checkbox"
              checked={showGreatestHits}
              onChange={() => setShowGreatestHits(!showGreatestHits)}
            />
            <div className="form-field-spacer" />
          </>
        )}
        {params.includes(ReportParamsEnum.TimeGroupBy) && (
          <div className="system-reports__select-wrap">
            <label htmlFor="group-by">
              Group By:
            </label>
            <div className="system-reports__select">
              <Select
                options={timeOptions}
                value={timeGroupBy}
                defaultValue={timeOptions[0]}
                name="time-group-by"
                placeholder="Select Subject"
                onChange={handleTimeGroupByChange}
              />
            </div>
          </div>
        )}
        {params.includes(ReportParamsEnum.DailyProjection) && (
          <>
            <label htmlFor="show-daily-projection">
              Show Daily Projection:
            </label>
            <div className="form-field-spacer" />
            <input
              id="show-daily-projection"
              type="checkbox"
              checked={showDailyProjection}
              onChange={() => setShowDailyProjection(!showDailyProjection)}
            />
            <div className="form-field-spacer" />
          </>
        )}
        {params.includes(ReportParamsEnum.loadSaqaData) && (
          <>
            <label htmlFor="show-saqa-data">
              Load SAQA Data:
            </label>
            <div className="form-field-spacer" />
            <input
              id="show-saqa-data"
              type="checkbox"
              checked={shouldLoadSaqaData === YesNo.Yes}
              onChange={() => setShouldLoadSaqaData(shouldLoadSaqaData === YesNo.Yes ? YesNo.No : YesNo.Yes)}
            />
            <div className="form-field-spacer" />
          </>
        )}
        <BetterButton
          className="system-reports__button"
          primary
          text="Load Report"
          onClick={() => loadReport(selectedReport)}
        />
        <div className="form-field-spacer" />
      </form>
    );
  };

  const renderReport = () => {
    const reportData = reportResults[selectedReport];
    let reportParams: Array<ReportParamsEnum>;
    switch (selectedReport) {
      case SystemReportsEnum.SaqasByTime:
        reportParams = [ReportParamsEnum.GreatestHits, ReportParamsEnum.TimeGroupBy];
        const { timeLabel, timeFormat } = saqasByTimeFormatData;
        return (
          <>
            <div className="system-reports__report-description">
              This report shows the number of student assessment question attempts (SAQAs) by time.
              The number of records varies depending on the time grouping selected:
              by hour, the most recent 2 weeks; by minute, the most recent 12 hours; and by day: the most recent 90 days. The cutoff for by day is 1 AM Pacific.
              SAQAs are our best single measure of utilization and it is important to know what our peaks and valleys are and that we have enough future capacity.
              Use Show Greatest Hits to see the busiest periods of all time.
            </div>
            {renderReportParamsForm(reportParams)}
            <AdminDataTable
              className="system-reports__all-reports system-reports__saqa-count"
              data={reportData}
              indexKey='question_attempt_date_time'
              labels={[
                { columnLabel: timeLabel, columnKey: 'question_attempt_date_time', mutate: (d) => DateTime.fromISO(d).toFormat(timeFormat) },
                { columnLabel: 'SAQA Records Created', columnKey: 'question_attempt_count' },
              ]}
            />
          </>
        );
      case SystemReportsEnum.SaqaProjection:
        reportParams = [ReportParamsEnum.IncludeCodonEmail, ReportParamsEnum.AssessmentDueDate, ReportParamsEnum.DailyProjection];
        const projectionResults: Array<SaqaDailyProjection> = [];

        if (showDailyProjection && reportData?.length) {
          // this is a basic algorithm for estimating the number of SAQAs on a given day.
          // it assumes a day ends at 1:00 AM Pacific and
          // assessments due before 10 AM in their local timezone count 50% towards the previous day
          // and assessments due before 12 PM in their local timezone count 25% towards the next day
          // this is based on a general sense after looking at these a lot, but a good next step would be to make this more data driven
          // including looking at historical values and probably factoring in assessment type
          let currentProjectionDate = DateTime.fromJSDate(assessmentDueDate); //start one day back to get the previous day's SAQAs
          let previousDayTotals: SaqaDailyProjection = { day: currentProjectionDate.toISODate(), questionCount: 0, enrollmentCount: 0, impact: 0, studentAssessmentsStarted: 0, studentAttemptsMade: 0, remainingImpact: 0 };
          let currentDayTotals: SaqaDailyProjection = { day: currentProjectionDate.plus({ days: 1 }).toISODate(), questionCount: 0, enrollmentCount: 0, impact: 0, studentAssessmentsStarted: 0, studentAttemptsMade: 0, remainingImpact: 0 };
          const lastAssessment = reportData[reportData.length - 1];
          const lastDueDate = DateTime.fromISO(lastAssessment.due_date);
          while (!!reportData.length) {
            const nextProjectionDate = currentProjectionDate.plus({ days: 1 });
            const nextProjectionDateInPT = nextProjectionDate.setZone('America/Los_Angeles').set({ hour: 1, minute: 0, second: 0, millisecond: 0 });
            const currentProjectionDateInPT = currentProjectionDate.setZone('America/Los_Angeles').set({ hour: 1, minute: 0, second: 0, millisecond: 0 });
            currentDayTotals.day = currentProjectionDateInPT.toISODate();
            previousDayTotals.day = currentProjectionDateInPT.minus({ days: 1 }).toISODate();
            // we want to process all assessments that are due on this date, and up to 1 AM the next day
            const assessmentsDueInWindow = reportData.filter((assessmentRow: SaqaProjection) => {
              const dueDate = DateTime.fromISO(assessmentRow.due_date);
              return dueDate.valueOf() < nextProjectionDateInPT.valueOf() && dueDate.valueOf() >= currentProjectionDateInPT.valueOf();
            }) as Array<SaqaProjection>;

            ({ previousDayTotals, currentDayTotals } = processAssessments(assessmentsDueInWindow, { ...previousDayTotals }, { ...currentDayTotals }));
            previousDayTotals.remainingImpact = previousDayTotals.impact - previousDayTotals.studentAttemptsMade;
            projectionResults.push({ ...previousDayTotals }); //push the previous day's totals
            previousDayTotals = { ...currentDayTotals }; // set the previous day's totals to the current day's totals
            currentDayTotals = { day: currentProjectionDate.toISODate(), questionCount: 0, enrollmentCount: 0, impact: 0, studentAssessmentsStarted: 0, studentAttemptsMade: 0, remainingImpact: 0 }; // reset the current day's totals
            currentProjectionDate = nextProjectionDate; // advance one day
            if (currentProjectionDate.valueOf() > lastDueDate.valueOf()) {
              break; // break if no more assessments are due
            }
          }
        }

        const formatSaqaRow = ({ impact }: GenericObject) => {
          let opacity;
          if (impact < 1000) {
            return { backgroundColor: 'rgba(144, 238, 144, 0.3)' };
          } else if (impact < 10000) {
            opacity = impact / 20000; //between .05 and 0.5
            return { backgroundColor: `rgba(255, 92, 92, ${opacity})` };
          } else {
            opacity = impact / 200000 + .5; // >= .55 for the forseeable future
            return { backgroundColor: `rgba(255, 92, 92, ${opacity})` };
          }
        };
        return (
          <>
            <div className="system-reports__report-description">
              This clever report projects how much SAQA activity is coming in the future.
              Note especially the impact column that combines the number of questions on an assessment with the number of enrollments in the course.
              And stay tuned, it's going to get a lot cooler before long!
              <br /><br />
              Now it is! You can select "Show Daily Projection" to aggregrate results by day.
              This grouping uses historical data to estimate the number of SAQAs that will be created on a given day based on an assessment due date
              (e.g. assessments due in the morning tend to get many saqas created the night before). The break between days is 1AM Pacific, 4AM Eastern.
              Assessments due before 10 AM in local zone count 50% to previous day and ones due before 12PM count 25% to previous day.
            </div>
            {renderReportParamsForm(reportParams)}
            {showDailyProjection ? (
              <AdminDataTable
                className="system-reports__all-reports"
                data={projectionResults}
                indexKey="day"
                labels={[
                  { columnLabel: 'Day', columnKey: 'day', mutate: (d) => DateTime.fromISO(d).toFormat(DateFormatEnum.WeekdayWithShortDateFullYear) },
                  { columnLabel: 'Question Count', columnKey: 'questionCount' },
                  { columnLabel: 'Enrollment Count', columnKey: 'enrollmentCount', mutate: (d) => parseInt(d).toLocaleString() },
                  { columnLabel: 'Impact', columnKey: 'impact', mutate: (d) => parseInt(d).toLocaleString() },
                  { columnLabel: 'Assessments Started', columnKey: 'studentAssessmentsStarted', mutate: (d) => parseInt(d).toLocaleString() },
                  { columnLabel: 'Attempts Made', columnKey: 'studentAttemptsMade', mutate: (d) => parseInt(d).toLocaleString() },
                  { columnLabel: 'Remaining Impact', columnKey: 'remainingImpact', mutate: (d) => parseInt(d).toLocaleString() },
                ]}
              />
            ) : (
              <AdminDataTable
                className="system-reports__all-reports"
                data={reportData}
                indexKey="assessment_id"
                formatRow={formatSaqaRow}
                labels={[
                  { columnLabel: 'Course Name', columnKey: 'course_name' },
                  { columnLabel: 'Instructor Email', columnKey: 'email' },
                  { columnLabel: 'Assessment Id', columnKey: 'assessment_id' },
                  { columnLabel: 'Assessment Type', columnKey: 'assess_type' },
                  { columnLabel: 'Assessment Due Day', columnKey: 'due_date', columnIndex: 'due-day', mutate: (d) => DateTime.fromISO(d).toFormat(DateFormatEnum.WeekdayMonthDate) },
                  {
                    columnLabel: 'Due Time',
                    columnKey: 'due_date',
                    mutate: (d) => DateTime.fromISO(d).toFormat(`${DateFormatEnum.TimeMeridian} ${DateFormatEnum.TimeZoneShort}`),
                  },
                  { columnLabel: 'Published', columnKey: 'published' },
                  { columnLabel: 'Question Count', columnKey: 'question_count' },
                  { columnLabel: 'Enrollment Count', columnKey: 'enrollment_count', mutate: (d) => parseInt(d).toLocaleString() },
                  { columnLabel: 'Impact', columnKey: 'impact', mutate: (d) => parseInt(d).toLocaleString() },
                  { columnLabel: 'Assessments Started', columnKey: 'student_assessments_started' },
                  { columnLabel: 'Attempts Made', columnKey: 'student_attempts_made', mutate: (d) => parseInt(d).toLocaleString() },
                ]}
              />
            )}
          </>
        );
      case SystemReportsEnum.EnrollmentAndPayment:
        reportParams = [ReportParamsEnum.IncludeCodonEmail, ReportParamsEnum.CourseStartDate, ReportParamsEnum.loadSaqaData];
        const enrollmentReportLabels = [
          { columnLabel: 'Course ID', columnKey: 'course_id' },
          { columnLabel: 'Title', columnKey: 'title' },
          { columnLabel: 'Institution', columnKey: 'institution_name', sortable: true },
          { columnLabel: 'Location', columnKey: 'institution_location', sortable: true },
          { columnLabel: 'Subject', columnKey: 'discipline', sortable: true },
          { columnLabel: 'Start Date', columnKey: 'start_date', sortable: true, mutate: (d: string) => DateTime.fromISO(d).toFormat(DateFormatEnum.WeekdayWithLongDateFullYear) },
          { columnLabel: 'First Name', columnKey: 'first_name' },
          { columnLabel: 'Last Name', columnKey: 'last_name', sortable: true },
          { columnLabel: 'Product', columnKey: 'product_title', sortable: true },
          { columnLabel: 'Card', columnKey: 'show_cc' },
          { columnLabel: 'Code', columnKey: 'show_code' },
          { columnLabel: 'Enrollment Count', columnKey: 'enrollment_count', sortable: true },
          { columnLabel: 'Have Loaded CHP', columnKey: 'have_loaded_chp' },
          { columnLabel: 'Have Paid', columnKey: 'have_paid' },
          { columnLabel: 'Assessment Count', columnKey: 'assessment_count' },
          { columnLabel: 'SA Count', columnKey: 'student_assessment_count' },
          { columnLabel: 'SAQ Count', columnKey: 'student_assessment_question_count' },
          { columnLabel: 'SAQA Count', columnKey: 'student_assessment_question_attempt_count' },
        ];
        return (
          <>
            <div className="system-reports__report-description">
              This invaluable report is useful at the beginning of semesters.
              It makes it easy to see how many enrollments are in active courses and if those students are successfully getting into the course.
              So cool! (Note: TEPI courseIds from fall 2023 are not included)
            </div>
            {renderReportParamsForm(reportParams)}
            <AdminDataTable
              className="system-reports__all-reports"
              data={reportData}
              indexKey="course_id"
              labels={enrollmentReportLabels}
              summaryColumns={['enrollment_count', 'have_loaded_chp', 'have_paid', 'assessment_count', 'student_assessment_count', 'student_assessment_question_count', 'student_assessment_question_attempt_count']}
            />
          </>
        );
    }
  };
  return (
    <div className="system-reports">
      <div className="system-reports__menu">
        {Object.values(SystemReportsEnum).map(report => (
          <button
            className={`system-reports__menu-item ${report === selectedReport ? 'selected' : ''}`}
            onClick={() => setSelectedReport(report)}
            key={report}
            id={report}
          >
            {report}
          </button>
        ))}
      </div>
      {renderReport()}
    </div>
  );
}
