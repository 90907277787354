import { addClassSessionTopic, addUserTopic } from 'store/slices/activeTopicsThunks';
import { AppDispatch, Store } from 'types/store.types';

export default function createUserTopicAndAddToClassSession({
  topicName,
  classSessionId,
  subjectId,
  userId,
}: {
  topicName: string
  classSessionId: number
  subjectId: number
  userId?: string
}) {
  return (dispatch: AppDispatch, getStore: () => Store) =>
    (async () => {
      const { user } = getStore();
      return dispatch(addUserTopic({ name: topicName, subjectId, userId: userId || user.id })).unwrap().then(async (addedUserTopic) => {
        return dispatch(addClassSessionTopic({ classSessionId, topicId: addedUserTopic.id }));
      });
    })();
}
