/*************
* This selector returns an array of assessmentQuestions (AKA assessmentQuestionMaps)
* and adds a questionNumber, which is the order that question appeared to the student in the assessment
*/
import { createSelector } from '@reduxjs/toolkit';
import cloneDeep from 'lodash-es/cloneDeep';
import retrieveNumberedAssessmentMap from 'store/selectors/retrieveNumberedAssessmentMap';
import { SimpleEnrichedAQM } from 'types/common.types';
import { Store } from 'types/store.types';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';

export default createSelector(
  (store: Store) => store.active.assessmentQuestionMaps,
  retrieveNumberedAssessmentMap,
  (assessmentQuestionMaps: Array<AssessmentQuestionApi>, numberedAssessmentMap: Map<string, number>) => {
    const aqms = cloneDeep(assessmentQuestionMaps);
    const numberedAqms = [] as Array<SimpleEnrichedAQM>;
    let questionNumber = 1;
    aqms.forEach((aqm, index) => {
      const assessmentNumber = numberedAssessmentMap.get(aqm.assessmentId) as number;
      if (index === 0 || aqm.assessmentId !== aqms[index - 1].assessmentId) {
        questionNumber = 1;
      }
      numberedAqms.push({ ...aqm, questionNumber, assessmentNumber });
      questionNumber++;
    });

    return numberedAqms;
  });

