import { determineMultipleAttemptPolicy } from 'utils/assessmentFunctions';
import { AssessmentApiBase, AssessTypeEnum, SummativeAssessmentApi } from 'types/backend/assessments.types';
import { SummativeAssessmentSupplementsApi } from 'types/backend/summativeAssessmentSupplements.types';
import { MultipleAttemptPolicyEnum } from 'types/common.types';

interface EnrichedEditingAssessmentDerived {
  multipleAttemptPolicy: MultipleAttemptPolicyEnum
  practiceAssessmentId?: string
  prepAssessmentId?: string
  topicsCovered?: Array<number>
}

export interface EnrichedEditingAssessment extends SummativeAssessmentApi {
  _derived: EnrichedEditingAssessmentDerived
}

/******
 * takes an Assessment and enriches it with the multipleAttemptPolicy
 * it is written as a pure function so that it can be used in selectors and the selectors and still be safely memoized
 */
const enrichAssessmentWithAttemptPolicy = (assessment: AssessmentApiBase) => {
  const multipleAttemptPolicy = determineMultipleAttemptPolicy(
    assessment.freeAttempts,
    assessment.pointPenalty,
    assessment.gradingPolicy
  );

  return {
    ...assessment,
    _derived: {
      multipleAttemptPolicy,
    },
  } as EnrichedEditingAssessment;
};
const enrichAssessmentForEditing = (
  assessments: Array<AssessmentApiBase | SummativeAssessmentApi>,
  summativeAssessmentSupplements: Array<SummativeAssessmentSupplementsApi>,
  editingAssessmentId?: string
): EnrichedEditingAssessment | null => {
  if (!editingAssessmentId) {
    return null;
  }
  const basicAssessment = assessments.find((a) => a.id === editingAssessmentId) as AssessmentApiBase | SummativeAssessmentApi;
  const fullEditingAssessment = enrichAssessmentWithAttemptPolicy(basicAssessment);
  if (fullEditingAssessment.assessType === AssessTypeEnum.Summative) {
    // if it's a summative assessment, add the prep and practiceTest assessmentIds for convenience
    // heavily leveraged in assessment builder
    const { practiceAssessmentId, prepAssessmentId } = summativeAssessmentSupplements.find((s) => s.id === fullEditingAssessment.id) || {};
    if (!prepAssessmentId || !practiceAssessmentId) {
      console.error(`Prep or Practice id ${fullEditingAssessment.id} not found in summativeAssessmentSupplements`, summativeAssessmentSupplements);
    }
    return {
      ...fullEditingAssessment,
      _derived: {
        ...fullEditingAssessment._derived,
        prepAssessmentId,
        practiceAssessmentId,
      },
    };
  }
  return fullEditingAssessment;
};

export default enrichAssessmentForEditing;
