import axios from 'axios';
import apiNext from 'api-next';
import { AppDispatch, Store } from 'types/store.types';
import { EnrollmentApi } from 'types/backend/enrollments.types';
import enrollmentsSlice from 'store/slices/enrollments';
import activeSlice from 'store/slices/active';
import logout from 'utils/logout';

export default function loadUserEnrollments(userId: string) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    try {
      const { active: { course: { id: courseId } } } = getStore();
      const userEnrollments: Array<EnrollmentApi> = await apiNext.getUserEnrollments(userId);
      dispatch(enrollmentsSlice.actions.setUserEnrollments(userEnrollments));
      const courseEnrollment = userEnrollments.find((enr) => courseId === enr.courseId);
      if (courseEnrollment) {
        dispatch(activeSlice.actions.updateActiveEnrollment(courseEnrollment));
      } else {
        console.debug(`No enrollment found in userEnrollments for ${courseId} - ${JSON.stringify({ userEnrollments })}`);
      }
      return userEnrollments;
    } catch (error) {
      if (axios.isAxiosError(error) && (error.response?.status === 401 || error.message.includes('401'))) {
        await logout(dispatch);
      }
    }
  })();
}
