/* eslint-disable jsx-a11y/no-autofocus */
// JUSTIFICATION: autoFocus is required for making ConfirmationPrompt play nice with keyboards
// this represents one of the valid uses of autoFocus
// For reference, this component was sourced from this article (https://medium.com/nmc-techblog/alternative-to-window-prompt-in-react-a266448e1d2)
// which has its github page here (https://github.com/Liadshiran/react-prompt-context)

import React, { useContext } from 'react';

import useEscapeKey from 'hooks/useEscapeKey';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import TextButton from 'shared-components/BetterButton/TextButton';
import ConfirmationPromptContainer from './ConfirmationPromptContainer';
import { ConfirmationPromptContext } from './ConfirmationPromptContext';
import { ConfirmationTypeEnum } from 'types/common.types';
import './ConfirmationModal.scss';

export const ConfirmationPrompt = () => {
  const [prompt, setPrompt] = useContext(ConfirmationPromptContext);
  const {
    isOpen,
    props: {
      confirmationType = ConfirmationTypeEnum.Alert,
      confirmButtonText = 'OK',
      message,
      onCancel,
      onConfirm,
      title,
    },
  } = prompt;

  const closePrompt = () => setPrompt({ ...prompt, isOpen: false });

  const handleConfirm = () => {
    onConfirm();
    closePrompt();
  };

  const handleCancel = () => {
    onCancel && onCancel();
    closePrompt();
  };

  useEscapeKey(handleCancel);

  if (!isOpen) {
    return (
      <></>
    );
  }

  return (
    <ConfirmationPromptContainer
      confirmationType={confirmationType}
      title={title}
      handleCancel={handleCancel}
    >
      <div className="confirmation-modal__body">
        {message}
      </div>
      <div className="confirmation-modal__button-bar">
        <BetterButton
          className="confirmation-button"
          primary
          data-dismiss="confirmation-modal"
          onClick={handleConfirm}
          text={confirmButtonText}
          autoFocus
        />
        {onCancel && (
          <TextButton
            className="confirmation-text-button"
            data-dismiss="confirmation-modal"
            onClick={handleCancel}
          >
            or Cancel
          </TextButton>
        )}
      </div>
    </ConfirmationPromptContainer>
  );
};
