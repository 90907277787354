import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserApi } from 'types/backend/users.types';

const userSlice = createSlice({
  name: 'user',
  initialState: null as UserApi | null,
  reducers: {
    setUser: (user, action: PayloadAction<UserApi>) => {
      return action.payload;
    },
    clear: () => {
      return null;
    },
  },
});

export default userSlice;
