import React from 'react';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import './LoPillList.scss';

function LoPillList({ className = '', courseLearningObjectives }: {
  className?: string
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
}) {
  return (
    <div className={`lo-pill-list ${className}`}>
      {courseLearningObjectives.map(item => (
        <div className="assessment-builder__linked-lo" key={item._derived.loNumber}>
          <BetterTooltip
            key={item._derived.loNumber}
            content={item.title}
          >
            {item._derived.loNumber}
          </BetterTooltip>
        </div>
      ))}
    </div>
  );
}

export default LoPillList;
