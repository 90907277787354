import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon, { IconEnum } from 'shared-components/Icon';
import TestColumnInfoCard from './TestColumnInfoCard';

import { CheckpointColumn } from 'types/backend/shared.types';
import { StudentCoursePath } from 'types/student.types';
import { EnrichedStudentTopicCardCheckpointStateEnum, StudyPathSummaryInfo } from '../../StudyPathController.types';
import './StudyPathCheckpointColumn.scss';

interface StudyPathCheckpointColumnProps {
  summaryInfo?: StudyPathSummaryInfo
  checkpointState?: EnrichedStudentTopicCardCheckpointStateEnum
  checkpointType: CheckpointColumn
  children: React.ReactNode
  columnTitle: React.ReactNode | string
  percentage: number
  selectedSummativeId?: string
  topicCardArray: Array<any>
}

const StudyPathCheckpointColumn = (props: StudyPathCheckpointColumnProps) => {
  const {
    summaryInfo,
    checkpointType,
    columnTitle,
    topicCardArray,
    children,
    percentage,
    checkpointState,
    selectedSummativeId,
  } = props;
  const renderComplete = (checkType: CheckpointColumn) => {
    // This might go back to a switch statement later but for now it's covered by an if because Prep and Review are identical at present
    if ([CheckpointColumn.Prep, CheckpointColumn.Review].includes(checkType)) {
      return (
        <div className={`empty-column empty-column-${checkType}`}>
          <div className="empty-column-icon"><Icon which={IconEnum.Happy} /></div>
          <div className="empty-column-content">
            <div className="h3">Great Job!</div>
            <div>
              You've cleared all of your topics out of this checkpoint.
            </div>
          </div>
        </div>
      );
    }
    return null;
  };
  const renderActiveWithoutQuestions = (checkType: CheckpointColumn) => {
    // This might go back to a switch statement later but for now it's covered by an if because Prep and Review are identical at present
    if ([CheckpointColumn.Prep, CheckpointColumn.Review].includes(checkType)) {
      return (
        <div className={`empty-column empty-column-${checkType}`}>
          <div className="empty-column-icon"><Icon which={IconEnum.Notepad} /></div>
          <div className="empty-column-content">
            <div className="h3">Good Going!</div>
            <div>
              You've cleared all available topics from this checkpoint. More will get added as you complete additional assignments.
              Visit the <Link to={StudentCoursePath.Assignments}>Assignments page.</Link>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderEmpty = (checkType: CheckpointColumn) => {
    // TODO: Need to know what empty Prep column should look like
    switch (checkType) {
      case CheckpointColumn.Review: {
        return (
          <div className={`empty-column empty-column-${checkType}`}>
            <div className="empty-column-icon"><Icon which={IconEnum.Inbox} /></div>
            <div className="empty-column-content">
              <div className="h3">Nothing here yet!</div>
              <div>
                Check back after completing your next assignment.
                Visit the <Link to={StudentCoursePath.Assignments}>Assignments page.</Link>
              </div>
            </div>
          </div>
        );
      }
      case CheckpointColumn.Prep: {
        return (
          <div className={`empty-column empty-column-${checkType}`}>
            <div className="empty-column-icon"><Icon which={IconEnum.Inbox} /></div>
            <div className="empty-column-content">
              <div className="h3">Nothing here yet!</div>
              <div>
                Once you move a topic over from Checkpoint 1, you can start answering Prep Questions to reinforce your understanding.
              </div>
            </div>
          </div>
        );
      }
      default: return null;
    }
  };

  const renderColumnDetails = (
    checkpointColumnType: CheckpointColumn,
    columnChildren: React.ReactNode,
    state?: EnrichedStudentTopicCardCheckpointStateEnum | null
  ) => {
    //practice test does not yet have a state, and is handled through the default
    switch (state) {
      case EnrichedStudentTopicCardCheckpointStateEnum.NotStarted:
        return renderEmpty(checkpointColumnType);
      case EnrichedStudentTopicCardCheckpointStateEnum.ActiveWithoutQuestions:
        return renderActiveWithoutQuestions(checkpointColumnType);
      case EnrichedStudentTopicCardCheckpointStateEnum.Complete:
        return renderComplete(checkpointColumnType);
      case EnrichedStudentTopicCardCheckpointStateEnum.ActiveWithQuestions:
      default:
        return (
          <>
            {columnChildren}
          </>
        );
    }
  };

  if (!topicCardArray) {
    console.error('topicCardArray is empty', topicCardArray);
    return null;
  }
  const isEmpty = !topicCardArray.length;
  const className = `study-path-checkpoint-column sp-column-${checkpointType} ${isEmpty ? 'sp-column-empty' : ''}`;

  return (
    <div className={className} data-checkpointtype={checkpointType}>
      <div className="column-title">
        { columnTitle }
      </div>
      <div className="column-content">
        {checkpointType === CheckpointColumn.Test && summaryInfo && selectedSummativeId && (
          <TestColumnInfoCard
            percentage={percentage}
            key={percentage}
            selectedSummativeId={selectedSummativeId}
            summaryInfo={summaryInfo}
          />
        )}
        {renderColumnDetails(checkpointType, children, checkpointState)}
      </div>
    </div>
  );
};

StudyPathCheckpointColumn.propTypes = {
  summaryInfo: PropTypes.object,
  checkpointType: PropTypes.oneOf(Object.values(CheckpointColumn)).isRequired,
  columnTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  topicCardArray: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  percentage: PropTypes.number.isRequired,
  checkpointState: PropTypes.oneOf(Object.values(EnrichedStudentTopicCardCheckpointStateEnum)),
  selectedSummativeId: PropTypes.string,
};

export default StudyPathCheckpointColumn;
