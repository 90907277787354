const sharedStrings = {
  //============COURSE PLANNER / LO SELECTOR STRINGS===============
  CUSTOM_ITEM_MESSAGE: 'Add Custom',
  NO_CLASS: 'NO CLASS TODAY',
  ONLY_ONE_UNIT_MESSAGE: 'Only one unit per class session at this time', // SOON OBSOLETE
  REMOVE_UNIT_MESSAGE: 'You can\'t remove a unit if child topics have been selected', // SOON OBSOLETE
  REMOVE_TOPIC_MESSAGE: 'You can\'t remove a topic if Learning Objectives have been selected', // TODO: Enable bulk LO action so this is no longer needed


  REMOVE_LO_MESSAGE: 'This Learning Objective is used in an assessment in the course and can\'t be deleted',
  REMOVE_LO_CONFIRMATION: 'Are you sure you want to remove this Learning Objective?',
  MOVE_LO_BETWEEN_TOPICS_VALIDATION: 'LOs cannot be assigned to more than one topic. You cannot move an LO to another topic if it is assigned on multiple class days. To reassign the topic of this LO, first remove it from other class days.',

  SELECT_TOPICS_MESSAGE: 'Select Topics',
  SPECIAL_SESSION: 'SPECIAL SESSION',
  TOPIC: 'Topic',
  UNIT_SELECT_MESSAGE: 'Select Unit',
  UNIT_REQUIRED_MESSAGE: 'Select a unit to be able to select topics',
  UNIT: 'Unit',

  DIRTY_NAVIGATE_CONFIRMATION: 'Are you sure you want to leave without saving?',

  INSTRUCTOR_PREVIEW_ASSESSMENT: 'You are currently previewing this assessment as a student. Your answers will not be saved when you leave this page. ',
  INSTRUCTOR_PREVIEW_RESTART_ASSESSMENT: 'Click here to restart.',
  INSTRUCTOR_PREVIEW_REVIEW_ASSIGNMENT: 'For instructors, this will restart assessment',

  //============ASSESSMENT BUILDER STRINGS===============
  ASSESSMENT_DELETE_CONFIRMATION: 'This will delete the assessment and remove all the items. This cannot be recovered. Are you sure you want to proceed?',
  ASSESSMENT_DETAILS_DIRTY_PREFIX: 'Assessment Details have been edited.',
  QUESTION_DELETE_CONFIRMATION: 'Are you sure you want to remove this item from the assessment?',
  QUESTION_GROUP_DELETE_CONFIRMATION: 'Are you sure you want to remove this group from the assessment?',
  REQUIRED_ASSESSMENT_CATEGORY_MESSAGE: 'You must select an Assessment Category.',
  REQUIRED_ASSESSMENT_GRADING_POLICY_MESSAGE: 'You must select a Grading Policy.',
  GRADING_POLICY_ENUM_CORRECTNESS_LABEL: 'For Correctness',
  GRADING_POLICY_ENUM_COMPLETION_LABEL: 'For Completion',
  GRADING_POLICY_ENUM_NO_POINTS_LABEL: 'Not for Points',
  GRADING_POLICY_ENUM_EXTERNAL_LABEL: 'Graded Outside of Codon',
  SELECT_GRADING_POLICY_PLACEHOLDER: 'Select Grading Policy',
  GRADING_POLICY_REQUIRED_LABEL: 'Grading Policy *',
  RETRIES_REQUIRED_LABEL: 'Retries *',
  DUE_DATE_REQUIRED: 'Due Date *',
  LOW_BLOOMS_LABEL: 'Low Bloom\'s',
  HIGH_BLOOMS_LABEL: 'High Bloom\'s',
  ASSESSMENT_BUILDER_DUE_DATE_TEXT: 'This is due anytime before the exam.',
  HAS_BEEN_STARTED_TOOLTIP_HEADER: 'After an assessment has been started by students, changes are restricted to prevent impact to in-progress scores.',
  HAS_BEEN_STARTED_TOOLTIP_HEADER_STUDY_PATH: 'After students have started working in a Study Path, changes are restricted to limit impact on in-progress scores.',
  PRACTICE_TEST_AVAILABILITY_INFO: 'Practice Test is available 3 days before the open date OR when students move all of the Topic Cards for this Summative Assessment into the Test Me checkpoint, whichever happens first.',
  PRACTICE_TEST_DUE_INFO: 'Students can complete Practice Test for points any time before the due date (or late date if a late policy is applied).',
  SYNC_TO_LMS_TITLE: 'Sync To LMS',
  NOT_SYNCED: 'Not Synced',
  INVALID_DATES_TITLE: 'Invalid Dates',
  EXTEND_DUE_DATE_TITLE: 'Extend Due Date',
  EXTEND_LATE_DATE_TITLE: 'Extend Late Date',
  MOVE_QUESTION_ERROR_TITLE: 'Error Moving Item To New Assessment',
  ASSESSMENT_DATES_UPDATE_IN_PROGRESS: 'date(s) are being updated. Regrades in progress.',
  ASSESSMENT_DATES_UPDATE_SUCCESS: 'date(s) are updated. Regrades complete.',
  //============STUDENT SCORES STRINGS===============
  SYNC_WARNING_MESSAGE: 'Sync will override existing LMS grades, including those that you have manually changed in the LMS gradebook.',
  HOW_TO_INTERPRET: 'How to interpret this page',
  LATE_SUFFIX: ' (late)',
  //============ASSESSMENTS LIST STRINGS===============
  ASSESSMENT_LIST_DIRTY_PREFIX: 'Some assessments have been edited.',
  //============GENERAL USE STRINGS====================
  LMS_LIST: 'Canvas, Blackboard, Moodle, Brightspace, etc.',
  CODON_SUPPORT_EMAIL: 'support@codonlearning.com',
  CODON_INFORMATION_EMAIL: 'info@codonlearning.com',
  INSTRUCTOR_SUPPORT_TITLE: 'Education Support Specialist',
};

export const assessmentTakerWarningStrings = {
  IN_PROGRESS_QUESTION_REMOVAL_WARNING_TITLE: 'Assignment Update Notification',
  IN_PROGRESS_QUESTION_REMOVAL_WARNING_MESSAGE: 'Your instructor has removed a question from this assignment since you started. Reload the page to show the changes.',
};

export const studentDashboardStrings = {
  CLASSES_COVERED_COLUMN_HEADER: 'COVERS',
  DUE_DATE_COLUMN_HEADER: 'DUE DATE',
  TOPICS_REVIEWED_TOOLTIP: 'Percent of topic cards moved out of Checkpoint 1',
  PREP_QUESTIONS_ANSWERED_TOOLTIP: 'Percent of Prep Questions answered correctly in Checkpoint 2',
  PRACTICE_TEST_UNLOCKED_TOOLTIP: 'Percent of questions moved into Checkpoint 3',
  PRACTICE_TEST_SCORE_DESCRIPTION: 'Practice Test Questions Answered Correctly',
};

export const studentAssessmentStatusStrings = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED_ON_TIME: 'Completed on Time',
  COMPLETED_LATE: 'Completed Late',
  INCOMPLETE: 'Incomplete',
  COMPLETED_AFTER_LATE_DATE: 'Completed After Late Date',
};

export const moveQuestionOptionsStrings = {
  ADD: 'Add',
  ADD_TO: 'Add to',
  ADDING: 'Adding...',
  MOVE: 'Move',
  MOVING: 'Moving...',
  MOVE_TO: 'Move to',
  REMOVE: 'Remove',
  REMOVING: 'Removing...',
};

export const errorMessages = {
  NO_CORRECT_ANSWER_SELECTED: 'Please indicate the correct answer(s) before continuing.',
  NO_SAMPLE_ANSWER_SELECTED: 'Please add a sample answer before continuing.',
  SCORE_SYNC_ERROR_DEFAULT: 'We were unable to sync this assessment. Please contact your Education Support Specialist to troubleshoot.',
  SCORE_SYNC_ERROR_MISSING_LMS: 'Matching assessment no longer exists in the LMS. Try turning “Enable sync for LMS” off and on again, then sync scores again. If problem continues, please contact your Education Support Specialist.',
  SCORE_SYNC_ERROR_NO_SCORES: 'Sync attempted, but no scores to send. Try again later once students have started working on this assessment.',
};

export const studentItemNomenclature = 'question';
export const rexItemNomenclature = 'section';

export default sharedStrings;
