/* ********************************
* this is a simple action that removes a question from an assessment by deleting the entry from the join table
* it replace the `template` and `user` versions of the selector, since those maybe never made sense and are now
* unimportant anyway since all questions are stored in the same table
*/
import apiNext, { SimplifiedErrorResponse } from 'api-next';
import activeSlice from 'store/slices/active';
import { saveLogMessage } from 'utils/saveLogMessage';
import { AppDispatch, Store } from 'types/store.types';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';


interface RemoveAqParams {
  questionId?: number
  assessmentId?: string
  assessmentQuestionId?: number
}

export default function removeQuestionFromAssessment({ questionId, assessmentId, assessmentQuestionId }: RemoveAqParams) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active: { assessmentQuestionMaps } } = getStore();

    //find the assessment question map that maps our question to the assessment
    let aqmToRemove: AssessmentQuestionApi | undefined;
    if (!!questionId && !!assessmentId) {
      aqmToRemove = assessmentQuestionMaps.find(aqm => aqm.questionId === questionId && aqm.assessmentId === assessmentId);
    } else if (!!assessmentQuestionId) {
      aqmToRemove = assessmentQuestionMaps.find(aqm => aqm.id === assessmentQuestionId);
    }
    if (!aqmToRemove) {
      throw new Error(`Cannot remove a non-existent relationship between question and assessment. params: ${JSON.stringify({ assessmentId, assessmentQuestionId, questionId })}`);
    }

    //make change locally for instant ui
    dispatch(activeSlice.actions.removeActiveAssessmentQuestionMap({ id: aqmToRemove.id }));

    //make the change on the server
    const result = await apiNext.deleteAssessmentQuestionMap(aqmToRemove.id);
    const { error } = result as SimplifiedErrorResponse;
    if (error) {
      const errString = `Error removing AQM. questionId: params: ${JSON.stringify({ assessmentId, assessmentQuestionId, questionId })}, raw error: ${JSON.stringify(error)})`;
      console.warn(errString);
      saveLogMessage(errString);
      dispatch(activeSlice.actions.addActiveAssessmentQuestionMap({ ...aqmToRemove }));
      throw new Error(errString);
    } else {
      return result;
    }
  })();
}
