import React from 'react';
import PropTypes from 'prop-types';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import Spinner from 'shared-components/Spinner/Spinner';
import BetterButton from 'shared-components/BetterButton/BetterButton';

interface LoadingButtonProps {
  className?: string
  disabled: boolean
  text: string
  loadingText: string
  loading: boolean
  onClick?: () => void
  type?: 'submit' | 'button'
}

const LoadingButton = (props: LoadingButtonProps) => {
  const {
    className,
    disabled,
    text,
    loadingText,
    loading,
    onClick,
    type = 'submit',
  } = props;
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        addEndListener={(node, done) => { node.addEventListener('transitionend', done, false); }}
        key={loading ? 'clickable' : 'loading'}
        classNames="fade"
      >
        {loading ? (
          <BetterButton
            icon={() => <Spinner />}
            text={loadingText}
            secondary
          />
        ) : (
          <BetterButton
            className={`loading-button ${className}`}
            text={text}
            disabled={disabled}
            onClick={onClick}
            type={type}
            primary
          />
        )}
      </CSSTransition>
    </SwitchTransition>
  );
};

LoadingButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  loadingText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default LoadingButton;
