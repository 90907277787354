import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch } from 'types/store.types';

export default function removeIclrFromClassSessions(
  ids: Array<number>
) {
  return (dispatch: AppDispatch) => (async () => {
    //make the change on the server:
    await Promise.all(ids.map(async (id) => {
      await apiNext.deleteClassSessionIclr(id);
    }));

    //Delete from local store for instant UI
    dispatch(activeSlice.actions.removeActiveClassSessionIclrs({ ids }));
  })();
}
