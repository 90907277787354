import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import defaultState from 'store/defaultState';
import { EnrollmentApi } from 'types/backend/enrollments.types';

const enrollmentsSlice = createSlice({
  name: 'enrollments',
  initialState: defaultState.enrollments,
  reducers: {
    setUserEnrollments: (enrollments, action: PayloadAction<Array<EnrollmentApi>>) => {
      return action.payload;
    },
    editEnrollment: (enrollments, action: PayloadAction<EnrollmentApi>) => {
      return enrollments.map(enrollment => {
        return enrollment.id === action.payload.id
          ? {
            ...enrollment,
            ...action.payload,
          } : enrollment;
      });
    },
    clear: () => {
      return defaultState.enrollments;
    },
  },
});

export default enrollmentsSlice;
