/**
 * retrieveActiveClassSessions
 *
 * In future this should be deprecated in favor of retrieveBetterClassSessions
 *
 **/
import { createSelector } from '@reduxjs/toolkit';
import cloneDeep from 'lodash-es/cloneDeep';
import { DateTime } from 'luxon';
import retrieveActiveCourseLearningObjectives, { EnrichedActiveClassSession, EnrichedCourseLearningObjective } from './retrieveActiveCourseLearningObjectives';
import retrieveEnrichedClassSessions from 'store/selectors/retrieveEnrichedClassSessions';
import retrieveEnrichedIclrs from './retrieveEnrichedIclrs';
import retrieveEnrichedOoclrs from './retrieveEnrichedOoclrs';
import { EnrichedClassSessionClr } from 'types/common.types';
import { Store } from 'types/store.types';
import { AssessmentApiBase } from 'types/backend/assessments.types';
import { GenericObject } from 'types/backend/shared.types';
import { ClassSessionApi } from 'types/backend/classSessions.types';
import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { ClassSessionTopicApi } from 'types/backend/classSessionTopics.types';
import { TopicApi } from 'types/backend/topics.types';

export const hydrateClassSessions = ({
  activeCourseLearningObjectives,
  assessments,
  classSessions,
  classSessionTopics,
  topics,
  iclrs,
  ooclrs,
}: {
  activeCourseLearningObjectives: Array<EnrichedCourseLearningObjective>
  assessments: Array<AssessmentApiBase>
  classSessionTopics: Array<ClassSessionTopicApi>
  classSessions: Array<ClassSessionApi>
  topics: Array<TopicApi>
  iclrs: Array<EnrichedClassSessionClr>
  ooclrs: Array<EnrichedClassSessionClr>
}) => {
  return classSessions.map(cs => {
    const _derived = {} as GenericObject; // TODO: kill this GenericObject, type this more strictly
    //TODO: this can be made more efficient. Doesn't need to be sorted multiple times, or even once in theory
    _derived.luxonDate = DateTime.fromISO((cs as GenericObject).date);

    // build a LO array for each classSession that has many LO attributes
    // these come from activeCourseLearningObjectives that have already had the derived values calculated
    _derived.courseLearningObjectives = [...(cs as GenericObject).learningObjectives];
    if (_derived.courseLearningObjectives) {
      _derived.courseLearningObjectives = _derived.courseLearningObjectives.map((cslo: any) => {
        const activeCourseLearningObjective = activeCourseLearningObjectives.find(aclo => aclo.id === cslo.id);
        if (activeCourseLearningObjective) {
          cslo._derived = activeCourseLearningObjective._derived;
        }
        return cslo;
      });
    }

    _derived.assessments = assessments.filter(({ classSessionIds = [] }) => classSessionIds.includes(cs.id));
    _derived.iclrs = iclrs.filter(iclr => iclr.classSessionId === cs.id);
    _derived.ooclrs = ooclrs.filter(ooclr => ooclr.classSessionId === cs.id);

    // build a topics array for each classSession that has topics and an LO array in each topic
    // this will be built from classSessionTopics
    const filteredClassSessionTopics = classSessionTopics.filter((cst: ClassSessionTopicApi) => cst.classSessionId === cs.id);

    _derived.topics = filteredClassSessionTopics.map((dcst: GenericObject) => {
      const newTopic = topics.find((t: TopicApi) => t.id === dcst.topicId);
      const {
        id,
        name,
        parentTopicId,
        subjectId,
        unitId,
        userId,
        type,
      } = newTopic || {};
      // add the LOs for the topic. These can come from the derived LOs, since those come from classSessionLos;
      const filteredCourseLearningObjectives = _derived.courseLearningObjectives && _derived.courseLearningObjectives.filter((clo: ClassSessionLearningObjectiveApi) => clo.topicId === id);
      return {
        ...dcst,
        classSessionTopicId: dcst.id,
        id, //derived object gets the topic.id
        topicId: id,
        name,
        parentTopicId,
        subjectId,
        unitId,
        type,
        userId,
        // why are we doing classSession._derived.topics._derived??
        // what is the point of nesting just one value in its own deeper object?
        // we may never know
        _derived: {
          // I think this should be named courseLearningObjectives
          learningObjectives: filteredCourseLearningObjectives,
        },
      };
    });
    return { ...cs, _derived } as EnrichedActiveClassSession;
  });
};

export default createSelector(
  retrieveEnrichedClassSessions,
  retrieveActiveCourseLearningObjectives,
  retrieveEnrichedIclrs,
  retrieveEnrichedOoclrs,
  (store: Store) => store.active.assessments,
  (store: Store) => store.active.classSessionTopics,
  (store: Store) => store.active.topics,
  (
    classSessions,
    activeCourseLearningObjectives,
    iclrs,
    ooclrs,
    assessments,
    classSessionTopics,
    topics
  ) => {
    classSessions = cloneDeep(classSessions);
    activeCourseLearningObjectives = cloneDeep(activeCourseLearningObjectives);
    classSessionTopics = cloneDeep(classSessionTopics);

    const hydrated = hydrateClassSessions({
      activeCourseLearningObjectives,
      assessments,
      classSessions,
      classSessionTopics,
      topics,
      iclrs,
      ooclrs,
    });

    return hydrated;
  }
);
