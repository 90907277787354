import React from 'react';
import { DateTime } from 'luxon';
import { FaPlus } from 'react-icons/fa';

import useLocalStorage from 'hooks/useLocalStorage';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import CourseCard from './CourseCard';
import ToggleSwitch from 'shared-components/ToggleSwitch/ToggleSwitch';
import { CourseWithMetadata } from 'store/selectors/retrieveEnrolledCourses';
import { ChildrenProp, LeftRightEnum } from 'types/common.types';
import { InstructorCoursePath } from 'types/instructor.types';
import './CourseList.scss';

enum CourseListSectionType {
  Past = 'Past Courses',
  Current = 'Current Courses',
  Future = 'Future Courses',
}

function CourseListSection({
  children,
  sectionType,
  show,
  solo, // if this course list section is the only section, hide the section heading
}: {
  children: ChildrenProp
  sectionType: CourseListSectionType
  show: boolean
  solo: boolean
}) {
  if (!show) {
    return null;
  }
  return (
    <div className="course-list__section">
      {!solo && (
        <div className="course-list__section__header">
          <h3 className="h5 course-list__section__header-title">{sectionType}</h3>
        </div>
      )}
      <div className="course-list__section__courses row">
        {children}
      </div>
    </div>
  );
}

function CourseList({
  courses,
  isInstructor = false,
}: {
  courses: Array<CourseWithMetadata>
  isInstructor?: boolean
}) {
  const [showPastCourses, setShowPastCourses] = useLocalStorage('INSTRUCTOR_SHOW_PAST_COURSES', false);

  const today = DateTime.now();
  let hasPastCourses = false;
  let hasCurrentCourses = false;
  let hasFutureCourses = false;
  const courseSections = courses.reduce((acc: { [key in CourseListSectionType]: Array<CourseWithMetadata> }, course: CourseWithMetadata) => {
    const startDate = DateTime.fromISO(course.startDate);
    const endDate = DateTime.fromISO(course.endDate);

    if (startDate <= today && today <= endDate) {
      hasCurrentCourses = true;
      return {
        ...acc,
        [CourseListSectionType.Current]: [
          ...acc[CourseListSectionType.Current],
          course,
        ],
      };
    } else if (startDate > today) {
      hasFutureCourses = true;
      return {
        ...acc,
        [CourseListSectionType.Future]: [
          ...acc[CourseListSectionType.Future],
          course,
        ],
      };
    } else if (endDate < today) {
      hasPastCourses = true;
      return {
        ...acc,
        [CourseListSectionType.Past]: [
          ...acc[CourseListSectionType.Past],
          course,
        ],
      };
    }
    return acc;
  }, {
    [CourseListSectionType.Current]: [],
    [CourseListSectionType.Future]: [],
    [CourseListSectionType.Past]: [],
  });

  const onlyOneSection = [hasPastCourses, hasCurrentCourses, hasFutureCourses].filter(Boolean).length === 1;
  return (
    <div className="course-list">
      <div className="course-list__wrapper">
        <div className="course-list__header row">
          <div className="col-xs-6">
            <h2 className="h3 course-list__header-title">My Courses</h2>
          </div>
          <div className="col-xs-6">
            <div className="course-list__header-options">
              {hasPastCourses && (
                <ToggleSwitch
                  data-on=""
                  data-off=""
                  checked={showPastCourses}
                  id="show-past-courses"
                  label="Show Past Courses"
                  onChange={setShowPastCourses}
                />
              )}
              {isInstructor && (
                <BetterButton
                  className="course-list__new-course"
                  icon={() => <FaPlus />}
                  iconAlign={LeftRightEnum.Right}
                  linkTo={`/instructor/${InstructorCoursePath.CreateCourse}`}
                  primary
                  text="Add New Course"
                />
              )}
            </div>
          </div>
        </div>
        <div className="course-list__content">
          {(Object.keys(courseSections) as Array<CourseListSectionType>).map((sectionType) => { // coercing is required here due to how TS handles Object methods
            const coursesArray = courseSections[sectionType];
            const hasCourses = !!coursesArray.length;
            const showSection = sectionType === CourseListSectionType.Past
              ? hasCourses && showPastCourses
              : hasCourses;
            return (
              <CourseListSection
                key={sectionType}
                sectionType={sectionType}
                show={showSection}
                solo={onlyOneSection}
              >
                {coursesArray.map((course) => (
                  <CourseCard
                    key={course.id}
                    course={course}
                    isInstructor={isInstructor}
                  />
                ))}
              </CourseListSection>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CourseList;
