import React from 'react';

export const externalLink = (url: string, text?: string) => {
  const linkText = text || url;
  return <ExternalLink url={url} text={linkText} />;
};

function ExternalLink({ url, text, ...props }: {
  url: string
  text?: string
} & React.HTMLProps<HTMLAnchorElement>) {
  const linkText = text || url;
  return (
    <a href={url} className="external-link" target="_blank" rel="noopener noreferrer" {...props}>
      {linkText}
    </a>
  );
}

export default ExternalLink;
