import React, { useCallback, useEffect } from 'react';
import axios from 'axios';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import useIdleLogout, { IdleLogoutInstance } from 'hooks/useIdleLogout';
import { useAppDispatch, useAppSelector } from 'store';
import { saveLogMessage } from 'utils/saveLogMessage';
import logout from 'utils/logout';

import InstructorHome from 'instructor/controllers/Home/InstructorHome';
import Course from 'instructor/controllers/Course/Course';
import StudentCourse from 'student/controllers/Course/Course';
import SystemNotification from 'shared-components/ToasterNotification/SystemNotification';
import ErrorBoundary from 'shared-components/ErrorBoundary/ErrorBoundary';
import GlobalNav from 'shared-components/GlobalNav/GlobalNav';
import { EnrollmentApi } from 'types/backend/enrollments.types';
import { loadCommonPassiveData, loadInstructorPassiveData } from 'store/slices/passiveThunks';
const version = process.env.REACT_APP_VERSION || '0.0.0';

export default function InstructorBase() {
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  const user = useAppSelector((store) => store.user);
  const enrollments = useAppSelector((store) => store.enrollments);
  useIdleLogout(user, IdleLogoutInstance.Instructor);

  const loadPassiveData = useCallback(async (userId: string, userEnrollments: Array<EnrollmentApi>) => {
    try {
      console.debug(`Loading Instructor Passive Data for user: ${userId}`, userEnrollments);
      await dispatch(loadCommonPassiveData(user.id));
      await dispatch(loadInstructorPassiveData(user.id));
    } catch (error) {
      console.error('loadPassiveData error', error);
      if (axios.isAxiosError(error) &&
          (error.response?.status === 401 || error.message.includes('401'))) {
        await logout(dispatch);
      } else {
        await saveLogMessage(`WEB ${version} - ${JSON.stringify(error, null, 2)}`);
      }
    }
  }, [dispatch, user.id]);

  /**
   * Global Data Fetching Effect
   * When login or reauth is sucesssful and we have a user object,
   * Grab all the non state dependent data from the API and store
   * it for use throughout the application.
  **/
  useEffect(() => {
    if (!user) {
      return;
    }
    loadPassiveData(user.id, enrollments);
  }, [enrollments, loadPassiveData, user]);

  return (
    <div className="instructor-base">
      <div className="app_top">
        <GlobalNav isInstructor userData={user} />
        <SystemNotification />
      </div>
      <div className="app__base">
        <ErrorBoundary isInstructor>
          <Switch>
            <Route exact path={path}><InstructorHome /></Route>
            <Route exact path={`${path}/create-course`}><InstructorHome /></Route>
            <Route exact path={`${path}/course`}><Course /></Route>
            <Route path={`${path}/course/:id`}><Course /></Route>
            <Route path={`${path}/course/:id`}><StudentCourse /></Route>
          </Switch>
        </ErrorBoundary>
      </div>
    </div>
  );
}
