import { InstructionEnum } from 'types/common.types';

export interface InstructionsRow {
  ids: Array<number> // array of class session iclr/ooclr ids
  clrId: number
  clrType: InstructionEnum
  classSessionIds: Array<number>
  classNumbers: Array<number>
  title: string
  url: string | null
  type: string
  userId?: string
}

export enum InstructionsActionEnum {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export interface InstructionsPayload {
  classSessionIds: Array<number>
  title: string
  url?: string | null
  clrId?: number
}
