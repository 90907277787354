import { createAsyncThunk } from '@reduxjs/toolkit';
import apiNext from 'api-next';
import { CourseApi } from 'types/backend/courses.types';

export const updateCourse = createAsyncThunk(
  'passive/updateCourse',
  async (course: CourseApi) => {
    return apiNext.editCourse(course.id, course);
  }
);
export const createCourse = createAsyncThunk(
  'passive/createCourse',
  async (course: Omit<CourseApi, 'id' | 'createdAt' | 'updatedAt'>) => {
    return apiNext.createCourse(course);
  }
);

