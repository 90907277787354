import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, useHistory } from 'react-router-dom';
import {
  FaMask,
  FaRegEnvelope,
  FaRegPlayCircle,
  FaRegQuestionCircle,
  FaRegUserCircle,
  FaSignOutAlt,
  FaUser,
} from 'react-icons/fa';

import CodonUrls from 'urls';
import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import logout from 'utils/logout';
import { saveLogMessage } from 'utils/saveLogMessage';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { useAppDispatch, useAppSelector } from 'store';
import stateSlice from 'store/slices/state';
import getInitialData from 'store/actions/getInitialData';
import unsetMasqueradeUser from 'store/actions/unsetMasqueradeUser';
import TextButton from 'shared-components/BetterButton/TextButton';
import { InstructorCoursePath } from 'types/instructor.types';
import { LogoutStatusEnum } from 'types/common.types';
import styles from 'style-config.scss';
import './GlobalNav.scss';

enum NavMenuEnum {
  Support = 'support',
  User = 'user',
  Masquerade = 'masquerade',
  None = 'none',
}

const GlobalNavProps = {
  isInstructor: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

export default function GlobalNav(props: PropTypes.InferProps<typeof GlobalNavProps>) {
  const { isInstructor, isAdmin, userData } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  // NOTE: Presently it only makes sense to link to the support site since we don't yet have sites for tutorials or FAQS, when we have those sites, enable toggle-able support menu
  const [menuToShow, setMenuToShow] = useState(NavMenuEnum.None);
  const wrapperRef = useRef(null);
  const activeCourse = useAppSelector((store) => store.active.course);
  const instructorStudentViewCourseId = useAppSelector((store) => store.state.instructorStudentViewCourseId);
  const user = useAppSelector((store) => store.user);
  const adminUser = useAppSelector((store) => store.adminUser);

  const [isOnline, setOnline] = useState<boolean>((): boolean => {
    return navigator.onLine;
  });

  const setOnlineToTrue = useCallback((): void => {
    setOnline(true);
  }, []);
  const setOnlineToFalse = useCallback((): void => {
    setOnline(false);
  }, []);

  useEffect(() => {
    window.addEventListener('online', setOnlineToTrue);
    window.addEventListener('offline', setOnlineToFalse);

    return () => {
      window.removeEventListener('online', setOnlineToTrue);
      window.removeEventListener('offline', setOnlineToFalse);
    };
  }, [setOnlineToTrue, setOnlineToFalse]);

  function onLogoutClick() {
    const logoutStatus = user.ltiUserId ? `status=${LogoutStatusEnum.LogoutLti}` : '';
    logout(dispatch, history, logoutStatus);
  }

  function returnToNormalRoleClick() {
    dispatch(unsetMasqueradeUser());
    history.push('/admin/users');
  }

  let userNameString = 'User';
  if (userData && userData.firstName && userData.lastName) {
    const { firstName, lastName } = userData;
    userNameString = `${firstName} ${lastName}`;
  }

  const handleToggleMenu = (menu: NavMenuEnum) => {
    // currently open menu member clicked again, so close
    if (menu === menuToShow) {
      setMenuToShow(NavMenuEnum.None);
    } else {
      setMenuToShow(menu);
    }
  };
  useOnClickOutside(wrapperRef, () => setMenuToShow(NavMenuEnum.None));

  const homeLink = '/';

  const renderStudentViewToggle = (currentCourseId: string) => {
    if (!currentCourseId) {
      return null;
    }
    const toggleStudentGoggles = (courseId: string | null) => {
      if (courseId === null) {
        dispatch(stateSlice.actions.clear());
        // if courseId is null we are returning to instructor from student view, get fresh course data
        !adminUser && saveLogMessage('INSTRUCTOR_AS_STUDENT: toggleStudentGoggles - off');
        dispatch(getInitialData(currentCourseId));
      } else {
        !adminUser && saveLogMessage('INSTRUCTOR_AS_STUDENT: toggleStudentGoggles - on');
        dispatch(stateSlice.actions.setStateInstructorStudentViewCourseId(courseId));
      }
    };
    if (!!instructorStudentViewCourseId) {
      const backToInstructorLink = `/instructor/course/${instructorStudentViewCourseId}/${InstructorCoursePath.DailyPlanner}`;
      return (
        <Link
          to={backToInstructorLink}
          className="global-nav__student-view"
          onClick={() => toggleStudentGoggles(null)}
        >
          Back to instructor view
        </Link>
      );
    }
    return (
      <button
        className="global-nav__student-view"
        onClick={() => toggleStudentGoggles(currentCourseId)}
      >
        See Student Preview
      </button>
    );
  };

  // disable Codon top-left link and hide My Courses while student goggles are on
  return (
    <header className="global-nav">
      <nav className="global-nav__wrapper" aria-label="Global Navigation">
        <NavLink
          to={homeLink}
          className="global-nav__logo"
          data-disabled={!!instructorStudentViewCourseId}
        >
          Codon
        </NavLink>
        <div className="global-nav__center">
          {!instructorStudentViewCourseId && (
            <NavLink to={homeLink} className="global-nav__courses">
              {isAdmin ? 'Home' : 'My Courses'}
            </NavLink>
          )}
          {isInstructor && renderStudentViewToggle(activeCourse?.id)}
        </div>
        <nav aria-label="Tools" className="global-nav__right">
          <div className="global-nav__support-wrap">
            {externalLink(CodonUrls.SupportKB, 'Support')}
            {menuToShow === NavMenuEnum.Support && (
              <div className="global-nav__user-menu-popup">
                <ul>
                  <li>
                    <FaRegPlayCircle />
                    <span>Tutorials</span>
                  </li>
                  <li>
                    <FaRegQuestionCircle />
                    <span>FAQ</span>
                  </li>
                  <li>
                    <FaRegEnvelope />
                    <span>Get Help</span>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div>|</div>
          {adminUser && user && (
            <div className="global-nav__masquerade-wrap">
              <FaMask fill={menuToShow === NavMenuEnum.Masquerade ? styles.white : styles.blue } onClick={() => handleToggleMenu(NavMenuEnum.Masquerade)} />
              {menuToShow === NavMenuEnum.Masquerade && (
                <div className="global-nav__user-menu-popup" ref={wrapperRef}>
                  <ul>
                    <li>
                      <FaSignOutAlt />
                      <TextButton onClick={returnToNormalRoleClick}>Return to my normal role</TextButton>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
          <div className="global-nav__user-menu-wrap">
            <button onClick={() => handleToggleMenu(NavMenuEnum.User)} aria-label="User Menu">
              <FaUser fill={menuToShow === NavMenuEnum.User ? styles.white : styles.blue } />
            </button>
            {menuToShow === NavMenuEnum.User && (
              <div className="global-nav__user-menu-popup" ref={wrapperRef}>
                <ul>
                  <li>
                    <FaRegUserCircle />
                    <span>{userNameString}</span>
                  </li>
                  <li className="dropdown-link">
                    <FaSignOutAlt />
                    <TextButton onClick={onLogoutClick}>Logout</TextButton>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </nav>
      </nav>
      {!isOnline && (
        <div className="global-nav__offline-warning" role="alert">
          No connection detected. Check your network status.
        </div>
      )}
    </header>
  );
}
