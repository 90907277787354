// sourced from: https://github.com/codonlearning/backend/blob/dev/src/services/assessments/assessments.types.ts
import { YesNo } from 'types/backend/shared.types';
import { AssessmentQuestionDb } from 'types/backend/assessmentQuestions.types';

export enum AssessTypeEnum {
  Preclass = 'preclass',
  Homework = 'homework',
  Summative = 'summative',
  Prep = 'prep',
  PracticeTest = 'practice_test',
  Readiness = 'readiness'
}

export enum GradingPolicyEnum {
  Correctness = 'correctness',
  Completion = 'completion',
  NoPoints = 'no_points',
  External = 'external'
}

interface AssessmentDbBase {
  id: string
  name: string
  instructions: string
  assess_type: AssessTypeEnum
  grading_policy: GradingPolicyEnum
  user__id: string
  course__id: string
  class_session__ids: Array<number>
  free_attempts: number | null
  created_at: string
  updated_at: string
  point_penalty: number | null
  attempts_in_at: number | null
  published: YesNo
  is_grade_sync_enabled: boolean
  line_item_id: string | null
  late_penalty: number | null
  late_policy: YesNo
}

// going in to the DB
export interface AssessmentDbIn extends AssessmentDbBase {
  open_date: string
  due_date: string
  late_date: string | null
}

// coming out of the DB
export interface AssessmentDbOut extends AssessmentDbBase {
  open_date: Date
  due_date: Date
  late_date: Date | null
}

export interface AssessmentApiBase {
  id: string
  name: string
  instructions: string
  openDate: string
  dueDate: string
  assessType: AssessTypeEnum
  gradingPolicy: GradingPolicyEnum
  userId: string
  courseId: string
  classSessionIds: Array<number>
  freeAttempts: number | null
  createdAt: string
  updatedAt: string
  pointPenalty: number | null
  attemptsInAt: number | null
  published: YesNo
  isGradeSyncEnabled: boolean
  lineItemId: string | null
  lateDate: string | null
  latePenalty: number | null
  latePolicy: YesNo
}

export interface SummativeAssessmentApi extends AssessmentApiBase {
  prep: AssessmentApiBase
  practiceTest: AssessmentApiBase
  studyPathId: number
}

export interface AssessmentWithAssessmentQuestions extends Pick<AssessmentDbOut, 'id' | 'due_date' | 'assess_type' | 'grading_policy' | 'free_attempts' | 'point_penalty' | 'attempts_in_at' | 'late_date' | 'late_penalty' | 'late_policy'>, Pick<AssessmentQuestionDb, 'points'> {
  assessment_question__id: number
}

export type AssessmentApiForAssessmentQuestions = Pick<AssessmentApiBase, 'id' | 'courseId' | 'assessType' | 'dueDate' | 'gradingPolicy' | 'userId' | 'lineItemId' | 'isGradeSyncEnabled'>
