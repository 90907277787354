import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';

export default function updateUserLearningObjective(loId: number, title: string, topicId: number) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active: { learningObjectives } } = getStore();
    const loData = learningObjectives.find(({ id }) => id === loId) as LearningObjectiveApi;
    if (!loData) {
      console.error(`loId ${loId} not found in active learningObjectives`, learningObjectives);
    }
    const updatedUserLo = await apiNext.editUserLo({
      ...loData,
      title,
      topicId,
    });
    dispatch(activeSlice.actions.updateActiveLearningObjective(updatedUserLo));
    return updatedUserLo;
  })();
}
