import React from 'react';
import PropTypes from 'prop-types';
import { GrDocumentVerified } from 'react-icons/gr';

import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { formatPlural } from 'utils/commonFormattingFunctions';
import { AssessmentSummaryData, AssessmentCompletionStatusEnum } from 'types/backend/assessmentSummaryData.types';

import { PositionEnum } from 'types/common.types';
import styles from 'style-config.scss';
import './CompletionBar.scss';

interface CompletionBarProps {
  summaryData: AssessmentSummaryData | undefined
}

function CompletionBar(props: CompletionBarProps) {
  const { summaryData } = props;
  if (!summaryData) {
    return (
      <>loading status</>
    );
  }

  const { assessmentStatus, completedStudentCount, startedStudentCount, totalStudents } = summaryData;
  const rawPercentage = totalStudents > 0 ? completedStudentCount / totalStudents * 100 : 0;
  const percentage = `${rawPercentage.toFixed()}%`;
  let messageText = '';
  let barColor = 'blue';

  switch (assessmentStatus) {
    case AssessmentCompletionStatusEnum.Unpublished:
      messageText = 'Unpublished';
      break;
    case AssessmentCompletionStatusEnum.NotOpen:
      messageText = 'Not Open';
      break;
    case AssessmentCompletionStatusEnum.Open:
      barColor = styles.purple;
      break;
    case AssessmentCompletionStatusEnum.Complete:
    default:
      barColor = styles.grayDark;
  }

  const tooltipContent = () => (
    <div className="timeline-grid__topic-tooltip">
      <li>{startedStudentCount} of {totalStudents} {formatPlural('student', totalStudents)} {formatPlural('has', totalStudents)} started this assessment.</li>
      <li>{completedStudentCount} of {totalStudents} {formatPlural('student', totalStudents)} {formatPlural('has', totalStudents)} attempted all questions in this assessment.</li>
    </div>
  );

  return (
    <div className="completion-container">
      <div className="completion-icon">
        <BetterTooltip content={tooltipContent} position={PositionEnum.Bottom}>
          <GrDocumentVerified size={16}/>
        </BetterTooltip>
      </div>
      {messageText ? (
        <div className="completion-message">
          {messageText}
        </div>
      ) : (
        <>
          <div className="completion-bar-container">
            <div className="completion-bar" style={{ width: percentage, backgroundColor: barColor }}/>
          </div>
          <div className='completion-percentage'>{percentage}</div>
        </>
      )}
    </div>
  );
}

CompletionBar.propTypes = {
  summaryData: PropTypes.object,
};

export default CompletionBar;
