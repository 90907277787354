import React from 'react';
import PropTypes from 'prop-types';
import './StudyPathTooltipContent.scss';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { prettifyAssessType } from 'utils/commonFormattingFunctions';

const StudyPathAssessmentTooltipProps = {
  title: PropTypes.string.isRequired,
  assessType: PropTypes.oneOf(Object.values(AssessTypeEnum) as Array<AssessTypeEnum>).isRequired,
  assessNumber: PropTypes.number.isRequired,
  questionNum: PropTypes.number.isRequired,
};

export const StudyPathAssessmentTooltip = (props: PropTypes.InferProps<typeof StudyPathAssessmentTooltipProps>) => {
  const { title, assessType, assessNumber, questionNum } = props;
  const capitalizedAssessType = prettifyAssessType(assessType);
  return (
    <div className="study-path-assessment-tooltip-content">
      <div>Assessment Name: {title}</div>
      <div>{capitalizedAssessType} #{assessNumber}</div>
      <div>Question #{questionNum}</div>
    </div>
  );
};

StudyPathAssessmentTooltip.propTypes = StudyPathAssessmentTooltipProps;

