import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { calculateCloData } from 'utils/alignmentFunctions';
import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import { BetterClassSession } from 'store/selectors/retrieveBetterClassSessions';
import DailyPlannerAlignmentItem from './DailyPlannerAlignmentItem';

interface DailyPlannerAlignmentListProps {
  currentActiveClassSession: BetterClassSession
}

const DailyPlannerAlignmentList = ({ currentActiveClassSession }: DailyPlannerAlignmentListProps) => {
  const assessmentQuestionMaps = useSelector(retrieveActiveAssessmentQuestionMaps);
  const { courseLearningObjectives, classNumber } = currentActiveClassSession;

  const cloData = calculateCloData(courseLearningObjectives, assessmentQuestionMaps);

  if (!cloData.length) {
    return (
      <div className="alignment-item-list">
        <div className="alignment-item-list__empty">
          No LOs currently assigned to Class {classNumber}
        </div>
      </div>
    );
  }

  return (
    <div className="alignment-item-list">
      {cloData.map((alignmentData) => (
        <DailyPlannerAlignmentItem
          key={alignmentData.loString}
          alignmentData={alignmentData}
          classSessionId={currentActiveClassSession.id}
        />
      ))}
    </div>
  );
};

DailyPlannerAlignmentList.propTypes = {
  currentActiveClassSession: PropTypes.object.isRequired,
};

export default DailyPlannerAlignmentList;
