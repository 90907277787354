/**
 * retrieveActiveCombinedQuestions
 * Unified place for getting all questions (both user and library), combining, enriching
 **/
import { createSelector } from '@reduxjs/toolkit';
import union from 'lodash-es/union';

import retrieveActiveCourseLearningObjectives from './retrieveActiveCourseLearningObjectives';
import retrieveEnrichedActiveTemplateLearningObjectives, { EnrichedActiveLearningObjective } from './retrieveEnrichedActiveTemplateLearningObjectives';

import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { Store } from 'types/store.types';
import { QuestionApiOut } from 'types/backend/questions.types';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { QuestionGroupQuestionWithGroupApi } from 'types/backend/questionGroupQuestions.types';

export interface ActiveCombinedQuestion extends QuestionApiOut {
  _derived: {
    courseLearningObjectives: Array<EnrichedCourseLearningObjective>
    templateLearningObjectives: Array<EnrichedActiveLearningObjective>
    libraryTopicIds: Array<number>
  }
  assessmentIds: Array<string>
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
  questionGroup?: QuestionGroupQuestionWithGroupApi
  libraryOrder?: number
}

export const enrichActiveCombinedQuestions = ({
  questions,
  activeCourseLearningObjectives,
  assessmentQuestionMaps,
  enrichedTemplateLearningObjectives,
  questionGroups,
}: {
  questions: Array<QuestionApiOut>
  assessmentQuestionMaps: Array<AssessmentQuestionApi>
  activeCourseLearningObjectives: Array<EnrichedCourseLearningObjective>
  enrichedTemplateLearningObjectives: Array<EnrichedActiveLearningObjective>
  questionGroups: Array<QuestionGroupQuestionWithGroupApi>
}) => questions.map(question => {
  // Derive all course learning objective associated with this template question
  const courseLearningObjectives = activeCourseLearningObjectives.filter(clo => {
    return !!question.learningObjectiveIds && question.learningObjectiveIds.indexOf(clo.id) > -1;
  });
  //derive the template learning objectives as well
  const templateLearningObjectives = enrichedTemplateLearningObjectives.filter(tlo => {
    return !!question.learningObjectiveIds && question.learningObjectiveIds.indexOf(tlo.id) > -1;
  });

  const questionGroup = questionGroups.find(({ questionId }) => questionId === question.id);

  // Derive all LIBRARY topic ids associated with this template question (through learningObjectives)
  //potential gotcha here if one of the LO tags is a tag to a library topic and not a user topic
  const { learningObjectiveIds: loIds = [] } = question;
  const libraryTopicIds = loIds.reduce((r: Array<number>, tloId: number) => {
    const templateLo = enrichedTemplateLearningObjectives.find(tlo => tlo.id === tloId);
    if (templateLo) {
      r = union(r, [templateLo.topicId]);
    }
    return r;
  }, []);

  const assessmentIds = assessmentQuestionMaps.reduce((idsArray, aqm) => {
    if (aqm.questionId === question.id) {
      idsArray.push(aqm.assessmentId);
    }
    return idsArray;
  }, [] as Array<string>);

  return {
    ...question,
    courseLearningObjectives,
    questionGroup,
    assessmentIds,
    _derived: {
      courseLearningObjectives,
      templateLearningObjectives,
      libraryTopicIds,
    },
  } as ActiveCombinedQuestion;
});

export default createSelector(
  (store: Store) => store.active.templateQuestions,
  (store: Store) => store.active.userQuestions,
  retrieveActiveCourseLearningObjectives,
  retrieveEnrichedActiveTemplateLearningObjectives,
  (store: Store) => store.active.questionGroups,
  (store: Store) => store.active.assessmentQuestionMaps,
  (
    templateQuestions,
    userQuestions,
    activeCourseLearningObjectives,
    enrichedTemplateLearningObjectives,
    questionGroups,
    assessmentQuestionMaps
  ) => {
    return enrichActiveCombinedQuestions({
      questions: [...userQuestions, ...templateQuestions],
      activeCourseLearningObjectives,
      assessmentQuestionMaps,
      enrichedTemplateLearningObjectives,
      questionGroups,
    });
  }
);
