import React from 'react';
import { DateTime } from 'luxon';
import { NavLink, useParams } from 'react-router-dom';
import { FaRegStar } from 'react-icons/fa';

import Icon, { IconEnum } from 'shared-components/Icon';
import { EnrichedStudentAssessment, EnrichedStudentSummativeAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';

import { StudyPathSummaryInfo } from 'student/controllers/Course/StudyPathController/StudyPathController.types';
import { formatAssessmentItemPlural } from 'utils/commonFormattingFunctions';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { studentDashboardStrings } from 'sharedStrings';
import { StudentCoursePath } from 'types/student.types';
import { PanelStatesEnum } from './NextSummative.types';

export default function PracticeTestStatus({
  firstHomework,
  panelState,
  practiceTest,
  summaryInfo,
}: {
  firstHomework?: Pick<EnrichedStudentAssessment, 'id' | 'mergedDueDate' | 'name'>
  panelState: PanelStatesEnum
  practiceTest?: Pick<EnrichedStudentSummativeAssessment, 'latestCorrectQuestionCount' | 'totalQuestions'>
  summaryInfo?: Pick<StudyPathSummaryInfo, 'muddyCount' | 'questionsForRecapture'>
}) {
  const { id: courseId } = useParams<{ id: string }>();
  // calculate summary info, allow for instances where `studyPath` does not exist yet
  const { muddyCount = 0, questionsForRecapture = 0 } = summaryInfo || {};

  let buttonText = 'Go Study';
  let link = `/student/course/${courseId}/${StudentCoursePath.StudyPath}`;
  switch (panelState) {
    case PanelStatesEnum.BeforeFirstHomeworkInWindow:
      const { id: firstHomeworkId } = firstHomework || {};
      buttonText = 'Start Homework';
      link = `/student/course/${courseId}/${StudentCoursePath.AssessmentTaker}/${firstHomeworkId}`;
      break;
    case PanelStatesEnum.ShowInitialSPCard:
      buttonText = 'Take a Tour';
      break;
    case PanelStatesEnum.BeforePracticeTestReady:
    case PanelStatesEnum.PracticeTestReady:
    case PanelStatesEnum.PracticeTestInProgress:
    case PanelStatesEnum.PracticeTestComplete:
      buttonText = 'Go Study';
      break;
    // no default
  }
  const statusButton = (
    <div className="next-summative__studypath-button-container">
      <div className="next-summative__studypath-button">
        <NavLink exact to={link}>
          <span className="next-summative__studypath-button-text">{buttonText}</span>
        </NavLink>
      </div>
    </div>
  );


  if (panelState === PanelStatesEnum.BeforePracticeTestReady) {
    const recapString = `${formatAssessmentItemPlural(questionsForRecapture)} with points to recapture`;
    const muddyString = `${formatAssessmentItemPlural(muddyCount)} marked as 'muddy'`;
    return (
      <div className="next-summative__right-panel" data-panelstate={PanelStatesEnum.BeforePracticeTestReady}>
        <div className="next-summative__right-panel next-summative-recapture-color">
          <div className="next-summative__right-panel-row">
            <div className="next-summative__right-panel-data">{questionsForRecapture}</div>
            <div className="next-summative__right-icon next-summative-recapture-color">
              <FaRegStar size={32} />
            </div>
          </div>
          <div className="next-summative__right-panel-text">
            {recapString}
          </div>
        </div>
        <div className="next-summative__right-panel-row-spacer"></div>
        <div className="next-summative__right-panel next-summative-muddy-color">
          <div className="next-summative__right-panel-row">
            <div className="next-summative__right-panel-data">{muddyCount}</div>
            <div className="next-summative__right-icon next-summative-muddy-color"><Icon which={IconEnum.Muddy} size={32} /></div>
          </div>
          <div className="next-summative__right-panel-text">
            {muddyString}
          </div>
        </div>
        {statusButton}
      </div>
    );
  }

  const statusIcon = {
    [PanelStatesEnum.ShowInitialSPCard]: IconEnum.StudyPath,
    [PanelStatesEnum.BeforeFirstHomeworkInWindow]: IconEnum.Inbox,
    [PanelStatesEnum.PracticeTestReady]: IconEnum.Pencil,
    [PanelStatesEnum.PracticeTestInProgress]: IconEnum.Pencil,
    [PanelStatesEnum.PracticeTestComplete]: IconEnum.Test,
  };

  const { latestCorrectQuestionCount = 0, totalQuestions = 0 } = practiceTest || {};
  const panelContent = {
    [PanelStatesEnum.ShowInitialSPCard]: (
      <>
        <div className="next-summative__right-panel-title">
          Check out the Study Path
        </div>
        <div className="next-summative__right-panel-text">
          This card will show your progress and give personalized next steps once you complete your first homework assignment.
        </div>
      </>
    ),
    [PanelStatesEnum.BeforeFirstHomeworkInWindow]: !!firstHomework && (
      <>
        <div className="next-summative__right-panel-text">
          Get started on your homework
        </div>
        <div className="next-summative__right-panel-text">
          {firstHomework.name}
        </div>
        <div className="next-summative__right-panel-text">
          Due {DateTime.fromISO(firstHomework.mergedDueDate).toFormat(DateFormatEnum.WeekdayMonthDate)}
        </div>
      </>
    ),
    [PanelStatesEnum.PracticeTestReady]: (
      <div className="next-summative__right-panel-text">
        Practice Test is now available
      </div>
    ),
    [PanelStatesEnum.PracticeTestInProgress]: (
      <div className="next-summative__right-panel-text">
        Practice Test is in progress
      </div>
    ),
    [PanelStatesEnum.PracticeTestComplete]: (
      <>
        <div className="hero">
          {latestCorrectQuestionCount}/{totalQuestions}
        </div>
        <div className="score-description">
          {studentDashboardStrings.PRACTICE_TEST_SCORE_DESCRIPTION}
        </div>
        <div className="next-summative__right-panel-text">
          Look back over your Practice Test answers to study.
        </div>
      </>
    ),
  };

  return (
    <div className="next-summative__right-panel next-summative__right-panel-column" data-panelstate={panelState}>
      <div className="next-summative__right-panel-icon">
        <Icon which={statusIcon[panelState]} size={48} />
      </div>
      {panelContent[panelState]}
      {statusButton}
    </div>
  );
}
