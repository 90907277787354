import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { IclrApi } from 'types/backend/iclr.types';
import { AppDispatch } from 'types/store.types';

export default function editIclr(iclrData: IclrApi) {
  return (dispatch: AppDispatch) => (async () => {

    const updatedIclr = await apiNext.editIclr(iclrData);

    dispatch(activeSlice.actions.editActiveIclr({ id: updatedIclr.id, delta: updatedIclr }));
  })();
}
