import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import { AddLoConfirmationData } from 'instructor/controllers/Course/SingleQADashboardController/SingleQADashboardController';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { AssessmentCourse, CourseHealthCheckQAChecks, UnaffiliatedQuestion } from 'types/backend/courseHealthCheck.types';
import { InstructorCoursePath } from 'types/instructor.types';
import { YesNo } from 'types/backend/shared.types';
import './CourseQACheck.scss';

interface CourseQACheckProps {
  qaCheckData: CourseHealthCheckQAChecks
  setAddLoConfirmationData?: Dispatch<SetStateAction<AddLoConfirmationData | null>>
}

const CourseQACheck = ({ qaCheckData, setAddLoConfirmationData }: CourseQACheckProps) => {
  const {
    unpublishedAssessments,
    assessmentsWithZeroQuestions,
    assessmentsWithoutStudyPath,
    assessmentsOnWrongStudyPath,
    unaffiliatedQuestions,
  } = qaCheckData;

  const renderQAAssessmentList = (listArray: Array<AssessmentCourse>) => {
    if (!listArray.length) {
      return 'none';
    }
    return (
      <ul>
        {
          listArray.map((item) => (<li key={item.id}>{item.name}</li>))
        }
      </ul>
    );
  };

  const renderUnaffiliatedQuestionList = (listArray: Array<UnaffiliatedQuestion>) => {
    if (!listArray.length) {
      return 'none';
    }
    const { learningObjectives } = listArray[0];

    return (
      <>
        {learningObjectives.map(lo => (
          <div className="course-qa-check__recommendation-block">
            <strong>Recommendation: </strong>
            {!!setAddLoConfirmationData ? (
              <button onClick={() => setAddLoConfirmationData({ loId: lo.id, title: lo.title, topicId: lo.topicId, topicName: lo.topicName })}>
                Add {lo.stringId} to your course.
              </button>
            ) : (
              <>
                Add {lo.stringId} to your course.
              </>
            )}
            <ul>
              <li>
                {lo.stringId}: {lo.title}
                <li><strong>Associated Questions:</strong></li>
                <ul>
                  {lo.assessmentQuestions.map(aq => (
                    <li key={aq.id}>
                      {aq.title} assigned in
                      {!!setAddLoConfirmationData ? (
                        <Link
                          className="course-qa-check__link"
                          to={`${InstructorCoursePath.AssessmentBuilder}?assessment=${aq.assessmentId}`}
                        >
                          &nbsp;{aq.assessmentName}&nbsp;
                        </Link>
                      ) : (
                        <>
                          &nbsp;{aq.assessmentName}&nbsp;
                        </>
                      )}
                      due {DateTime.fromISO(aq.assessmentDueDate).toFormat(DateFormatEnum.WeekdayMonthDate)} <em>{aq.assessmentPublished === YesNo.Yes ? '' : ' (unpublished)'}</em>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="course-qa-check">
      <table className="course-qa-check__table">
        <thead>
          <tr>
            <th className="course-qa-check__check">
              QA Check
            </th>
            <th className="course-qa-check__results">
              Results
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Unpublished assessments that are already open but not yet past due OR opening in the next 2 weeks
            </td>
            <td>
              {renderQAAssessmentList(unpublishedAssessments)}
            </td>
          </tr>
          <tr>
            <td>
              Assessments with zero questions that are already open but not yet past due OR opening in the next 2 weeks
            </td>
            <td>
              {renderQAAssessmentList(assessmentsWithZeroQuestions)}
            </td>
          </tr>
          <tr>
            <td>
              Assessments that won't show up on any Study Path
            </td>
            <td>
              {renderQAAssessmentList(assessmentsWithoutStudyPath)}
            </td>
          </tr>
          <tr>
            <td>
              Assessments that might be on the wrong Study Path
            </td>
            <td>
              {renderQAAssessmentList(assessmentsOnWrongStudyPath)}
            </td>
          </tr>
          <tr>
            <td>
              <strong>You have one or more questions that are not currently associated with an LO in your course.</strong>
              <br /><br />
              This suggests a misalignment of assessments and selected LOs. Additionally, students will not be able to access these items in the Study Path. For each question not associated with an LO, students will be unable to recapture points (if assigned on a homework), earn points (if assigned as a prep question), or retry questions for practice (for any assignment type).
            </td>
            <td>
              {renderUnaffiliatedQuestionList(unaffiliatedQuestions)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CourseQACheck;
