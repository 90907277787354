// sourced from https://github.com/codonlearning/backend/blob/dev/src/services/roles/roles.types.ts

export enum RoleEnum {
  Instructor = 1,
  Student,
  Ta,
  Admin
}

export interface RoleDb {
  id: RoleEnum
  name: string
  description: string
  type: string
  created_at: string
  updated_at: string
}

export interface RoleApi {
  id: RoleEnum
  name: string
  description: string
  type: string
  createdAt: string
  updatedAt: string
}

export enum InstructorRoles {
  Instructor = 'instructor',
  Staff = 'staff',
  Mentor = 'mentor',
  ContentDeveloper = 'contentdeveloper'
}

export enum StudentRoles {
  Student = 'student',
  Learner = 'learner',
  TeachingAssistant = 'teachingassistant',
}

export enum AdminRoles {
  Administrator = 'administrator',
  AccountAdmin = 'accountadmin',
  SysAdmin = 'sysadmin'
}
