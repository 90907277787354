import { DateTime } from 'luxon';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { SummativeAssessmentSupplementsApi } from 'types/backend/summativeAssessmentSupplements.types';
import { AssessmentWithEnrollment, SummativeAssessmentWithEnrollment } from 'store/selectors/retrieveAssessmentsWithEnrollment';

//A fairly simple filter. Remember it will fail if enriched Assessment are not correctly sorted first.
export const getMostRecentActiveSummative = (
  enrichedAssessments: Array<AssessmentWithEnrollment | SummativeAssessmentWithEnrollment>,
  supplements: Array<SummativeAssessmentSupplementsApi>
): SummativeAssessmentWithEnrollment | null => {
  const now = DateTime.local();

  const nextSummative = enrichedAssessments.find(as => {
    const asDate = DateTime.fromISO(as.dueDate); // Note: this could be changed to `as.mergedDueDate` if we decide we want enrollmentAssessments to play a role here
    const dateValid = asDate > now;
    return as.assessType === AssessTypeEnum.Summative && dateValid;
  }) as SummativeAssessmentWithEnrollment;
  if (!nextSummative) {
    return null;
  }

  return nextSummative;
};
