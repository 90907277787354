import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { BsPencil } from 'react-icons/bs';

import activeSlice from 'store/slices/active';
import apiNext from 'api-next';
import { checkIfStudentsHaveStartedAssessment, determineDefaultMinOpenMaxDueDatesForCourse } from 'utils/assessmentFunctions';
import useEffectOnce from 'hooks/useEffectOnce';
import sharedStrings from 'sharedStrings';
import confirmationMsgs from '../AssessmentBuilderController/AssessmentBuilderConfirmationMessages';
import { useAppDispatch } from 'store';
import retrieveEnrichedStudentAssessments, { EnrichedStudentAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';
import editAssessment from 'store/actions/editAssessment';
import editStudyPathAssessmentsList from 'store/actions/editStudyPathAssessmentsList';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import LoadingButton from 'shared-components/LoadingButton/LoadingButton';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import validateAssessmentUpdatesForWorker from 'utils/assessments/validateAssessmentUpdatesForWorker';
import validateAssessmentPublishedUpdatesForWorker from 'utils/assessments/validateAssessmentPublishedUpdatesForWorker';
import { usePolling } from 'shared-components/Polling/PollingContext';
import { useToast } from 'shared-components/ToastNotification/ToastNotificationContext';
import reloadAssessmentQuestions from 'store/actions/reloadAssessmentQuestions';
import AssessmentsListItem from './AssessmentsListItem';
import AssessmentsListSummativeItems from './AssessmentsListSummativeItems';
import { ConfirmationTypeEnum } from 'types/common.types';
import { Store } from 'types/store.types';
import { ApiError } from 'shared-components/ApiErrorDisplay/ApiErrorDisplay';
import { AssessmentApiBase, AssessTypeEnum, SummativeAssessmentApi } from 'types/backend/assessments.types';
import {
  AssessmentListEvents,
  AssessmentListEventData,
  EditedAssessmentDatum,
  SummativeEventData,
  AssessmentListSummativeEvents,
  EditedSummativeAssessmentDatum,
} from './AssessmentsList.types';
import { ContextMethod, YesNo } from 'types/backend/shared.types';
import { WorkerPipelineApi, WorkStatus } from 'types/backend/workerPipelines.types';
import { AssessmentWorkerChangeType } from '../AssessmentBuilderController/AssessmentBuilderController.types';
import './AssessmentsList.scss';


export default function AssessmentsListController() {
  const dispatch = useAppDispatch();
  const course = useSelector((store: Store) => store.active.course);
  const assessments = useSelector((store: Store) => store.active.assessments);
  const enrichedStudentAssessments = useSelector(retrieveEnrichedStudentAssessments);
  const { notifySuccess, notifyError } = useToast();
  const { startPolling, stopPolling } = usePolling();
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const [startedIds, setStartedIds] = useState([] as Array<string>);
  const [regradeWorkerAssessmentIds, setRegradeWorkerAssessmentIds] = useState(new Set<string>());
  const [publishedWorkerAssessmentIds, setPublishedWorkerAssessmentIds] = useState(new Set<string>());
  const [regradeWorkerJobIds, setRegradeWorkerJobIds] = useState(new Set<string>());
  const [publishedWorkerJobIds, setPublishedWorkerJobIds] = useState(new Set<string>());
  const [isWorkerProcessingEligible, setIsWorkerProcessingEligible] = useState(false);
  const [isPrepHasChanged, setIsPrepHasChanged] = useState(false);
  const [isPracticeHasChanged, setIsPracticeHasChanged] = useState(false);
  const [isWorkerTriggered, setIsWorkerTriggered] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [editedAssessmentsHash, setEditedAssessmentsHash] = useState<EditedAssessmentDatum>({});
  const [editedSummativeAssessmentsHash, setEditedSummativeAssessmentsHash] = useState<EditedSummativeAssessmentDatum>({});
  const [hideGradeSyncPrompt, setHideGradeSyncPrompt] = useState(false);
  const [reloadKeys, setReloadKeys] = useState<Record<string, number>>({});

  const { isGradeSyncEnabled: courseIsGradeSyncEnabled } = course;

  const { defaultMinOpenDate, defaultMaxDueDate } = determineDefaultMinOpenMaxDueDatesForCourse(course);

  const forceReload = useCallback((id: string) => {
    setReloadKeys(prevKeys => ({
      ...prevKeys,
      [id]: (prevKeys[id] || 0) + 1,
    }));
  }, []);

  const handleAssessmentGradeSyncDisabling = useCallback((
    newValues: AssessmentApiBase | SummativeAssessmentApi,
    updatedAssessment: AssessmentApiBase | SummativeAssessmentApi
  ): void => {
    if (newValues.isGradeSyncEnabled && !updatedAssessment?.isGradeSyncEnabled) {
      forceReload(updatedAssessment.id);
      window.sessionStorage.setItem('setGradeSyncFailed', 'true');
    }
  }, [forceReload]);

  const handleGradeSyncFailureConfirmation = useCallback((): void => {
    if (window.sessionStorage.getItem('setGradeSyncFailed') === 'true') {
      triggerConfirmationPrompt({
        title: sharedStrings.NOT_SYNCED,
        message: confirmationMsgs.failedGradeSyncOneOrMoreConfMessage,
        onConfirm: () => {},
        confirmationType: ConfirmationTypeEnum.Warn,
      });
      window.sessionStorage.removeItem('setGradeSyncFailed');
    }
  // triggerConfirmationPrompt function in array of deps causing re-rendering
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectOnce(() => {
    async function updateAssessmentStudentUsage(esas: Array<EnrichedStudentAssessment>) {
      const ids: Array<string> = [];
      await Promise.all(esas.map(async (assessment) => {
        const id = assessment.id;
        const started = await checkIfStudentsHaveStartedAssessment(id);
        if (started) {ids.push(id);}
      }));
      setStartedIds(ids);
    }
    if (enrichedStudentAssessments) {
      updateAssessmentStudentUsage(enrichedStudentAssessments);
    }
  });

  // NOTE: method to send assessment updates to worker pipeline
  const triggerAssessmentWorker = useCallback(async (
    assessmentId: string,
    assessmentDelta: Required<AssessmentListEventData>,
    changeType: AssessmentWorkerChangeType,
    summativeMetadata?: { prepHasChanged: boolean; practiceHasChanged: boolean }
  ): Promise<string | void> => {
    const toastAutoCloseDelay = Number(process.env.REACT_APP_TOAST_AUTOCLOSE_DELAY) || 3000;
    const originalAssessment = assessments.find(a => a.id === assessmentId) as AssessmentApiBase;
    const editedAssessment = { ...originalAssessment, ...assessmentDelta };

    if (changeType === AssessmentWorkerChangeType.Dates) {
      const studyPathMessage = editedAssessment?.assessType === AssessTypeEnum.Summative ? 'Study Path' : '';
      notifySuccess(`${editedAssessment?.name} ${studyPathMessage} ${sharedStrings.ASSESSMENT_DATES_UPDATE_IN_PROGRESS}`, toastAutoCloseDelay, 'info');
    }

    setIsWorkerTriggered(true);

    const workerResult = await apiNext.postWorkerPipeline('assessments', ContextMethod.Update, editedAssessment);

    const { error, id: jobResultId } = workerResult;
    if (error) {
      return notifyError(`${editedAssessment.name} update failed`, false);
    }

    if (summativeMetadata?.prepHasChanged) {
      setIsPrepHasChanged(true);
    }

    if (summativeMetadata?.practiceHasChanged) {
      setIsPracticeHasChanged(true);
    }

    if (changeType === AssessmentWorkerChangeType.Dates) {
      setRegradeWorkerJobIds(prevIds => new Set([...prevIds, jobResultId]));
    } else if (changeType === AssessmentWorkerChangeType.Published) {
      setPublishedWorkerJobIds(prevIds => new Set([...prevIds, jobResultId]));
    }
    return jobResultId;
  }, [assessments, notifyError, notifySuccess]);

  // NOTE: sends all eligible non summative assessments to worker pipeline on isWorkerProcessingEligible true flag
  useEffect(() => {
    const runAsyncTask = async () => {
      if (isWorkerProcessingEligible && (regradeWorkerAssessmentIds.size || publishedWorkerAssessmentIds.size)) {
        const assessmentIds = Object.keys(editedAssessmentsHash);

        const jobIds: Array<string> = [];
        const pubJobIds: Array<string> = [];
        for (const id of assessmentIds) {
          if (!regradeWorkerAssessmentIds.has(id) && !publishedWorkerAssessmentIds.has(id)) {
            continue;
          }
          const delta = editedAssessmentsHash[id];
          let jobResultId;
          if (regradeWorkerAssessmentIds.has(id)) {
            jobResultId = await triggerAssessmentWorker(id, delta, AssessmentWorkerChangeType.Dates);
            jobResultId && jobIds.push(jobResultId);
            regradeWorkerAssessmentIds.delete(id);
          } else { // published must have it
            jobResultId = await triggerAssessmentWorker(id, delta, AssessmentWorkerChangeType.Published);
            jobResultId && pubJobIds.push(jobResultId);
            publishedWorkerAssessmentIds.delete(id);
          }
        }

        setRegradeWorkerJobIds(prevIds => new Set([...prevIds, ...jobIds]));
        setPublishedWorkerJobIds(prevIds => new Set([...prevIds, ...pubJobIds]));
        if (!regradeWorkerAssessmentIds.size && !publishedWorkerAssessmentIds.size) {
          setIsWorkerProcessingEligible(false);
        }
      }
    };

    runAsyncTask().catch(console.error);

  }, [isWorkerProcessingEligible, editedAssessmentsHash, triggerAssessmentWorker, regradeWorkerAssessmentIds, publishedWorkerAssessmentIds]);

  // NOTE: main function which triggered on regradeWorkerJobIds and publishedWorkerJobIds and checks results of worker jobs
  // and handles dispatching updated state and other util functions
  useEffect(() => {
    if (!regradeWorkerJobIds.size && !publishedWorkerJobIds.size) {
      return;
    }
    const toastAutoCloseLongDelay = Number(process.env.REACT_APP_TOAST_AUTOCLOSE_LONG_DELAY) || 30000;
    const initialPollingDelay = Number(process.env.REACT_APP_INITIAL_POLLING_DELAY) || 1000;
    const finishedUpdates: Array<string> = [];

    const pollingFunction = async () => {
      const unfinishedRegradeAssessmentIds = Array.from(regradeWorkerJobIds).filter(jobId => !finishedUpdates.includes(jobId));
      const unfinishedPublishedAssessmentIds = Array.from(publishedWorkerJobIds).filter(jobId => !finishedUpdates.includes(jobId));
      const regradeResults = await Promise.all(unfinishedRegradeAssessmentIds.map(async (workerId) => {
        const response = await apiNext.getWorkerPipeline(workerId);
        return response;
      }));
      const publishedResults = await Promise.all(unfinishedPublishedAssessmentIds.map(async (workerId) => {
        const response = await apiNext.getWorkerPipeline(workerId);
        return response;
      }));

      const processResults = async (results: Array<WorkerPipelineApi>, changeType: AssessmentWorkerChangeType, setWorkerJobIdsFunction: (value: React.SetStateAction<Set<string>>) => void) => {
        for (const result of results) {
          const { workStatus } = result;
          if (workStatus === WorkStatus.Completed && !finishedUpdates.includes(result.id)) {
            const { payload, outcome } = result;
            const studyPathMessage = outcome?.assessType === AssessTypeEnum.Summative ? 'Study Path' : '';
            if (changeType === AssessmentWorkerChangeType.Dates) {
              notifySuccess(`${outcome?.name} ${studyPathMessage} ${sharedStrings.ASSESSMENT_DATES_UPDATE_SUCCESS}`, toastAutoCloseLongDelay);
            }
            handleAssessmentGradeSyncDisabling(payload as AssessmentApiBase | SummativeAssessmentApi, outcome as AssessmentApiBase | SummativeAssessmentApi);

            if (outcome?.assessType === AssessTypeEnum.Summative) {
              const { prep: updatedPrep, practiceTest: updatedPractice } = outcome;
              handleAssessmentGradeSyncDisabling(payload.prep, outcome.prep);
              handleAssessmentGradeSyncDisabling(payload.practiceTest, outcome.practiceTest);
              dispatch(activeSlice.actions.editActiveAssessment({ id: updatedPrep.id, delta: updatedPrep }));
              dispatch(activeSlice.actions.editActiveAssessment({ id: updatedPractice.id, delta: updatedPractice }));
              if (isPrepHasChanged && !!outcome?.prep) {
                await dispatch(reloadAssessmentQuestions([outcome.prep.id]));
              }

              if (isPracticeHasChanged && !!outcome?.practiceTest) {
                await dispatch(reloadAssessmentQuestions([outcome.practiceTest.id]));
              }
            }

            dispatch(activeSlice.actions.editActiveAssessment({
              id: outcome?.id,
              delta: {
                ...outcome as AssessmentApiBase | SummativeAssessmentApi,
              },
            }));
          }

          if (workStatus === WorkStatus.Failed && !finishedUpdates.includes(result.id)) {
            const { error, payload } = result;
            const { message } = error as ApiError;
            if (changeType === AssessmentWorkerChangeType.Dates) {
              notifyError(`${payload?.name} date(s) extension failed due to ${message}`, false);
            } else {
              notifyError(`Update ${payload?.name} failed due to ${message}`, false);
            }
          }

          if (workStatus !== WorkStatus.InProgress) {
            finishedUpdates.push(result.id);
            setSaveInProgress(true);

            setWorkerJobIdsFunction(prevIds => {
              const newIds = new Set(prevIds);
              newIds.delete(result?.id);
              return newIds;
            });
          }
        }
      };

      await processResults(regradeResults, AssessmentWorkerChangeType.Dates, setRegradeWorkerJobIds);
      await processResults(publishedResults, AssessmentWorkerChangeType.Published, setPublishedWorkerJobIds);

      if (finishedUpdates.length === (regradeWorkerJobIds.size + publishedWorkerJobIds.size)) {
        stopPolling();
        setSaveInProgress(false);
        setEditedAssessmentsHash({});
        setEditedSummativeAssessmentsHash({});
        setEditMode(false);
        handleGradeSyncFailureConfirmation();
      }
    };


    startPolling(pollingFunction, initialPollingDelay);
    return () => {
      if (!regradeWorkerJobIds.size && !publishedWorkerJobIds.size) {
        stopPolling();
        setSaveInProgress(false);
        setEditedAssessmentsHash({});
        setEditedSummativeAssessmentsHash({});
        setEditMode(false);
        handleGradeSyncFailureConfirmation();
      }
    };
  }, [
    regradeWorkerJobIds,
    publishedWorkerJobIds,
    dispatch,
    isPracticeHasChanged,
    isPrepHasChanged,
    notifyError,
    notifySuccess,
    startPolling,
    stopPolling,
    handleAssessmentGradeSyncDisabling,
    handleGradeSyncFailureConfirmation,
  ]);


  const getNewAssessmentValues = (assessmentId: string): AssessmentApiBase => {
    const { openDate, dueDate, latePolicy, lateDate, latePenalty, published, isGradeSyncEnabled } = editedAssessmentsHash[assessmentId];
    const originalAssessment = assessments.find(a => a.id === assessmentId) as AssessmentApiBase;
    return {
      ...originalAssessment,
      openDate,
      dueDate,
      latePolicy,
      lateDate,
      latePenalty,
      isGradeSyncEnabled,
      published,
    };
  };

  const saveChanges = async () => {
    const regradeWorkerAssessmentIdSet = new Set<string>();
    const publishedWorkerAssessmentIdSet = new Set<string>();
    console.debug('Save all updates', editedAssessmentsHash);
    if (Object.keys(editedAssessmentsHash).length || Object.keys(editedSummativeAssessmentsHash).length) {
      // don't allow save if any assessments have invalid dates
      if (Object.values(editedAssessmentsHash).some(v => v.hasInvalidOpenDueDates)) {
        triggerConfirmationPrompt({
          title: sharedStrings.INVALID_DATES_TITLE,
          message: confirmationMsgs.openDatePastDueDateConfMessage,
          onConfirm: () => {},
          confirmationType: ConfirmationTypeEnum.Warn,
        });
        return;
      }
      if (Object.values(editedAssessmentsHash).some(v => v.hasInvalidDueLateDates)
        || Object.values(editedSummativeAssessmentsHash).some(v => v.hasInvalidDueLateDates || v.prep.hasInvalidDueLateDates || v.practiceTest.hasInvalidDueLateDates)) {
        triggerConfirmationPrompt({
          title: sharedStrings.INVALID_DATES_TITLE,
          message: confirmationMsgs.dueDatePastLateDateConfMessage,
          onConfirm: () => {},
          confirmationType: ConfirmationTypeEnum.Warn,
        });
        return;
      }
      setSaveInProgress(true);
      let resultOnValidation: boolean;
      let publishedResultOnValidation: boolean;

      for (const assessmentId in editedAssessmentsHash) {
        const originalAssessment = assessments.find(a => a.id === assessmentId) as AssessmentApiBase;
        const newAssessmentValues = getNewAssessmentValues(assessmentId);

        publishedResultOnValidation = validateAssessmentPublishedUpdatesForWorker(originalAssessment, newAssessmentValues);
        if (publishedResultOnValidation) {
          publishedWorkerAssessmentIdSet.add(originalAssessment.id);
        } else {
          resultOnValidation = validateAssessmentUpdatesForWorker(originalAssessment, newAssessmentValues, startedIds);
          if (resultOnValidation) {
            regradeWorkerAssessmentIdSet.add(originalAssessment.id);
          }
        }
      }

      if (regradeWorkerAssessmentIdSet.size) {
        setIsWorkerProcessingEligible(true);
        setRegradeWorkerAssessmentIds(regradeWorkerAssessmentIdSet);
      }
      if (publishedWorkerAssessmentIdSet.size) {
        setIsWorkerProcessingEligible(true);
        setPublishedWorkerAssessmentIds(publishedWorkerAssessmentIdSet);
      }

      for (const assessmentId in editedAssessmentsHash) {
        if (regradeWorkerAssessmentIdSet.has(assessmentId) || publishedWorkerAssessmentIdSet.has(assessmentId)) {
          continue;
        }
        const newAssessmentValues = getNewAssessmentValues(assessmentId);
        const updatedAssessment = await dispatch(editAssessment(assessmentId, newAssessmentValues));
        if (!!updatedAssessment && typeof updatedAssessment !== 'string') {
          handleAssessmentGradeSyncDisabling(newAssessmentValues, updatedAssessment);
        }
      }
      if (!regradeWorkerAssessmentIdSet.size && !publishedWorkerAssessmentIdSet.size) {
        setEditedAssessmentsHash({});
      }

      for (const assessmentId in editedSummativeAssessmentsHash) {
        const { published, prep, practiceTest } = editedSummativeAssessmentsHash[assessmentId];
        const originalAssessment = assessments.find(a => a.id === assessmentId) as SummativeAssessmentApi;
        const { prep: originalPrep, practiceTest: originalPracticeTest } = originalAssessment;
        const { hasInvalidDueLateDates: prepHasInvalidDueLateDates, hasInvalidOpenDueDates: prepHasInvalidOpenDueDates, ...newPrep } = prep;
        const { hasInvalidDueLateDates: practiceHasInvalidDueLateDates, hasInvalidOpenDueDates: practiceHasInvalidOpenDueDates, ...newPracticeTest } = practiceTest;
        const newAssessmentValues = {
          ...originalAssessment,
          published,
          prep: {
            ...originalPrep,
            ...newPrep,
          },
          practiceTest: {
            ...originalPracticeTest,
            ...newPracticeTest,
          },
        };
        const updatedAssessment: SummativeAssessmentApi | string | void = await dispatch(editStudyPathAssessmentsList(newAssessmentValues, startedIds, triggerAssessmentWorker));
        if (!!updatedAssessment && typeof updatedAssessment !== 'string') {
          handleAssessmentGradeSyncDisabling(newAssessmentValues, updatedAssessment);
          handleAssessmentGradeSyncDisabling(newAssessmentValues.prep, updatedAssessment.prep);
          handleAssessmentGradeSyncDisabling(newAssessmentValues.practiceTest, updatedAssessment.practiceTest);
        }
      }
      setSaveInProgress(false);
      handleGradeSyncFailureConfirmation();
    }
    setEditedSummativeAssessmentsHash({});
    setEditMode(false);
  };

  const turnEditingOff = () => {
    Object.keys(editedAssessmentsHash).forEach((assessmentId: string) => {
      forceReload(assessmentId);
    });
    Object.keys(editedSummativeAssessmentsHash).forEach((assessmentId) => {
      forceReload(assessmentId);
    });
    setEditedAssessmentsHash({});
    setEditedSummativeAssessmentsHash({});
    setEditMode(false);
  };

  const handleChangeAssessmentEvent = (type: AssessmentListEvents, id: string, eventData: AssessmentListEventData) => {
    console.debug(`AssessmentsList change assessment event:: ${type}`, eventData);
    const originalAssessment = enrichedStudentAssessments.find(a => a.id === id);
    const updatedAssessment = editedAssessmentsHash[id];
    const assessmentToEdit = updatedAssessment || originalAssessment;
    if (!assessmentToEdit) {
      console.error(`Original assessment ${id} not found in enrichedStudentAssessments or updated assessments`);
      return;
    }
    const { hasInvalidOpenDueDates, hasInvalidDueLateDates } = eventData;
    switch (type) {
      case AssessmentListEvents.ChangePublished:
        const isGradeSyncEnabled = eventData.published === YesNo.No ? false : assessmentToEdit.isGradeSyncEnabled;
        setEditedAssessmentsHash({
          ...editedAssessmentsHash,
          [id]: {
            openDate: assessmentToEdit.openDate,
            dueDate: assessmentToEdit.dueDate,
            latePolicy: assessmentToEdit.latePolicy,
            lateDate: assessmentToEdit.lateDate,
            latePenalty: assessmentToEdit.latePenalty,
            published: eventData.published as YesNo,
            isGradeSyncEnabled,
            hasInvalidOpenDueDates,
            hasInvalidDueLateDates,
            assessType: assessmentToEdit.assessType,
          },
        });
        break;
      case AssessmentListEvents.ChangeGradeSyncEnabled:
        setEditedAssessmentsHash({
          ...editedAssessmentsHash,
          [id]: {
            openDate: assessmentToEdit.openDate,
            dueDate: assessmentToEdit.dueDate,
            latePolicy: assessmentToEdit.latePolicy,
            lateDate: assessmentToEdit.lateDate,
            latePenalty: assessmentToEdit.latePenalty,
            published: assessmentToEdit.published,
            isGradeSyncEnabled: eventData.isGradeSyncEnabled as boolean,
            hasInvalidOpenDueDates,
            hasInvalidDueLateDates,
            assessType: assessmentToEdit.assessType,
          },
        });
        break;
      case AssessmentListEvents.ChangeOpenDate:
        setEditedAssessmentsHash({
          ...editedAssessmentsHash,
          [id]: {
            openDate: eventData.openDate as string,
            dueDate: assessmentToEdit.dueDate,
            latePolicy: assessmentToEdit.latePolicy,
            lateDate: assessmentToEdit.lateDate,
            latePenalty: assessmentToEdit.latePenalty,
            published: assessmentToEdit.published,
            isGradeSyncEnabled: assessmentToEdit.isGradeSyncEnabled,
            hasInvalidOpenDueDates,
            hasInvalidDueLateDates,
            assessType: assessmentToEdit.assessType,
          },
        });
        break;
      case AssessmentListEvents.ChangeDueDate:
        setEditedAssessmentsHash({
          ...editedAssessmentsHash,
          [id]: {
            openDate: assessmentToEdit.openDate,
            dueDate: eventData.dueDate as string,
            latePolicy: assessmentToEdit.latePolicy,
            lateDate: assessmentToEdit.lateDate,
            latePenalty: assessmentToEdit.latePenalty,
            published: assessmentToEdit.published,
            isGradeSyncEnabled: assessmentToEdit.isGradeSyncEnabled,
            hasInvalidOpenDueDates,
            hasInvalidDueLateDates,
            assessType: assessmentToEdit.assessType,
          },
        });
        break;
      case AssessmentListEvents.ChangeLateDate:
        setEditedAssessmentsHash({
          ...editedAssessmentsHash,
          [id]: {
            openDate: assessmentToEdit.openDate,
            dueDate: assessmentToEdit.dueDate,
            latePolicy: assessmentToEdit.latePolicy,
            lateDate: eventData.lateDate as string,
            latePenalty: assessmentToEdit.latePenalty,
            published: assessmentToEdit.published,
            isGradeSyncEnabled: assessmentToEdit.isGradeSyncEnabled,
            hasInvalidOpenDueDates,
            hasInvalidDueLateDates,
            assessType: assessmentToEdit.assessType,
          },
        });
        break;
      case AssessmentListEvents.ChangeLatePolicy:
        setEditedAssessmentsHash({
          ...editedAssessmentsHash,
          [id]: {
            openDate: assessmentToEdit.openDate,
            dueDate: assessmentToEdit.dueDate,
            latePolicy: eventData.latePolicy as YesNo,
            lateDate: eventData.lateDate as string | null,
            latePenalty: eventData.latePenalty as number | null,
            published: assessmentToEdit.published,
            isGradeSyncEnabled: assessmentToEdit.isGradeSyncEnabled,
            hasInvalidOpenDueDates,
            hasInvalidDueLateDates,
            assessType: assessmentToEdit.assessType,
          },
        });
        break;
      default:
        console.debug(`AssessmentsList change assessment invalid event type: ${type}`, eventData);
    }
  };

  const handleChangeSummativeAssessmentEvent = (type: AssessmentListSummativeEvents, eventData: SummativeEventData) => {
    const { summativeId, summativeEventData, prepId, prepEventData, practiceId, practiceEventData } = eventData;
    console.debug(`handleChangeSummativeAssessmentEvent change assessment event:: ${type}`, summativeEventData, prepEventData, practiceEventData);
    const originalSummativeAssessment = enrichedStudentAssessments.find(a => a.id === summativeId);
    const updatedSummativeAssessment = editedSummativeAssessmentsHash[summativeId];
    const summativeAssessmentToEdit = updatedSummativeAssessment || originalSummativeAssessment;
    if (!summativeAssessmentToEdit) {
      console.error(`Original summative assessment ${summativeId} not found in enrichedStudentAssessments or updated summative assessments`);
      return;
    }
    const { prep: prepAssessmentToEdit, practiceTest: practiceAssessmentToEdit } = summativeAssessmentToEdit;
    switch (type) {
      case AssessmentListSummativeEvents.ChangePrepPublished:
        setEditedSummativeAssessmentsHash({
          ...editedSummativeAssessmentsHash,
          [summativeId]: {
            openDate: summativeAssessmentToEdit.openDate,
            dueDate: summativeAssessmentToEdit.dueDate,
            latePolicy: summativeAssessmentToEdit.latePolicy,
            lateDate: summativeAssessmentToEdit.lateDate,
            latePenalty: summativeAssessmentToEdit.latePenalty,
            published: summativeEventData.published as YesNo,
            isGradeSyncEnabled: false,
            hasInvalidOpenDueDates: summativeEventData.hasInvalidOpenDueDates,
            hasInvalidDueLateDates: summativeEventData.hasInvalidDueLateDates,
            assessType: AssessTypeEnum.Summative,
            prep: {
              id: prepId,
              openDate: prepAssessmentToEdit.openDate,
              dueDate: prepAssessmentToEdit.dueDate,
              latePolicy: prepAssessmentToEdit.latePolicy,
              lateDate: prepAssessmentToEdit.lateDate,
              latePenalty: prepAssessmentToEdit.latePenalty,
              published: prepEventData.published as YesNo,
              isGradeSyncEnabled: prepEventData.isGradeSyncEnabled as boolean,
              hasInvalidOpenDueDates: prepEventData.hasInvalidOpenDueDates,
              hasInvalidDueLateDates: prepEventData.hasInvalidDueLateDates,
              assessType: AssessTypeEnum.Prep,
            },
            practiceTest: {
              id: practiceId,
              openDate: practiceAssessmentToEdit.openDate,
              dueDate: practiceAssessmentToEdit.dueDate,
              latePolicy: practiceAssessmentToEdit.latePolicy,
              lateDate: practiceAssessmentToEdit.lateDate,
              latePenalty: practiceAssessmentToEdit.latePenalty,
              published: practiceEventData.published as YesNo,
              isGradeSyncEnabled: practiceEventData.isGradeSyncEnabled as boolean,
              hasInvalidOpenDueDates: practiceEventData.hasInvalidOpenDueDates,
              hasInvalidDueLateDates: practiceEventData.hasInvalidDueLateDates,
              assessType: AssessTypeEnum.PracticeTest,
            },
          },
        });
        break;
      case AssessmentListSummativeEvents.ChangePracticePublished:
        setEditedSummativeAssessmentsHash({
          ...editedSummativeAssessmentsHash,
          [summativeId]: {
            openDate: summativeAssessmentToEdit.openDate,
            dueDate: summativeAssessmentToEdit.dueDate,
            latePolicy: summativeAssessmentToEdit.latePolicy,
            lateDate: summativeAssessmentToEdit.lateDate,
            latePenalty: summativeAssessmentToEdit.latePenalty,
            published: summativeAssessmentToEdit.published,
            isGradeSyncEnabled: false,
            hasInvalidOpenDueDates: summativeEventData.hasInvalidOpenDueDates,
            hasInvalidDueLateDates: summativeEventData.hasInvalidDueLateDates,
            assessType: AssessTypeEnum.Summative,
            prep: {
              id: prepId,
              openDate: prepAssessmentToEdit.openDate,
              dueDate: prepAssessmentToEdit.dueDate,
              latePolicy: prepAssessmentToEdit.latePolicy,
              lateDate: prepAssessmentToEdit.lateDate,
              latePenalty: prepAssessmentToEdit.latePenalty,
              published: prepAssessmentToEdit.published,
              isGradeSyncEnabled: prepAssessmentToEdit.isGradeSyncEnabled,
              hasInvalidOpenDueDates: prepEventData.hasInvalidOpenDueDates,
              hasInvalidDueLateDates: prepEventData.hasInvalidDueLateDates,
              assessType: AssessTypeEnum.Prep,
            },
            practiceTest: {
              id: practiceId,
              openDate: practiceAssessmentToEdit.openDate,
              dueDate: practiceAssessmentToEdit.dueDate,
              latePolicy: practiceAssessmentToEdit.latePolicy,
              lateDate: practiceAssessmentToEdit.lateDate,
              latePenalty: practiceAssessmentToEdit.latePenalty,
              published: practiceEventData.published as YesNo,
              isGradeSyncEnabled: practiceEventData.isGradeSyncEnabled as boolean,
              hasInvalidOpenDueDates: practiceEventData.hasInvalidOpenDueDates,
              hasInvalidDueLateDates: practiceEventData.hasInvalidDueLateDates,
              assessType: AssessTypeEnum.PracticeTest,
            },
          },
        });
        break;
      case AssessmentListSummativeEvents.ChangePrepGradeSyncEnabled:
      case AssessmentListSummativeEvents.ChangePracticeGradeSyncEnabled:
        setEditedSummativeAssessmentsHash({
          ...editedSummativeAssessmentsHash,
          [summativeId]: {
            openDate: summativeAssessmentToEdit.openDate,
            dueDate: summativeAssessmentToEdit.dueDate,
            latePolicy: summativeAssessmentToEdit.latePolicy,
            lateDate: summativeAssessmentToEdit.lateDate,
            latePenalty: summativeAssessmentToEdit.latePenalty,
            published: summativeAssessmentToEdit.published,
            isGradeSyncEnabled: false,
            hasInvalidOpenDueDates: summativeEventData.hasInvalidOpenDueDates,
            hasInvalidDueLateDates: summativeEventData.hasInvalidDueLateDates,
            assessType: AssessTypeEnum.Summative,
            prep: {
              id: prepId,
              openDate: prepAssessmentToEdit.openDate,
              dueDate: prepAssessmentToEdit.dueDate,
              latePolicy: prepAssessmentToEdit.latePolicy,
              lateDate: prepAssessmentToEdit.lateDate,
              latePenalty: prepAssessmentToEdit.latePenalty,
              published: prepAssessmentToEdit.published,
              isGradeSyncEnabled: prepEventData.isGradeSyncEnabled as boolean,
              hasInvalidOpenDueDates: prepEventData.hasInvalidOpenDueDates,
              hasInvalidDueLateDates: prepEventData.hasInvalidDueLateDates,
              assessType: AssessTypeEnum.Prep,
            },
            practiceTest: {
              id: practiceId,
              openDate: practiceAssessmentToEdit.openDate,
              dueDate: practiceAssessmentToEdit.dueDate,
              latePolicy: practiceAssessmentToEdit.latePolicy,
              lateDate: practiceAssessmentToEdit.lateDate,
              latePenalty: practiceAssessmentToEdit.latePenalty,
              published: practiceAssessmentToEdit.published,
              isGradeSyncEnabled: practiceEventData.isGradeSyncEnabled as boolean,
              hasInvalidOpenDueDates: practiceEventData.hasInvalidOpenDueDates,
              hasInvalidDueLateDates: practiceEventData.hasInvalidDueLateDates,
              assessType: AssessTypeEnum.PracticeTest,
            },
          },
        });
        break;
      case AssessmentListSummativeEvents.ChangePrepLatePolicy:
        setEditedSummativeAssessmentsHash({
          ...editedSummativeAssessmentsHash,
          [summativeId]: {
            openDate: summativeAssessmentToEdit.openDate,
            dueDate: summativeAssessmentToEdit.dueDate,
            latePolicy: summativeAssessmentToEdit.latePolicy,
            lateDate: summativeAssessmentToEdit.lateDate,
            latePenalty: summativeAssessmentToEdit.latePenalty,
            published: summativeAssessmentToEdit.published,
            isGradeSyncEnabled: false,
            hasInvalidOpenDueDates: summativeEventData.hasInvalidOpenDueDates,
            hasInvalidDueLateDates: summativeEventData.hasInvalidDueLateDates,
            assessType: AssessTypeEnum.Summative,
            prep: {
              id: prepId,
              openDate: prepAssessmentToEdit.openDate,
              dueDate: prepAssessmentToEdit.dueDate,
              latePolicy: prepEventData.latePolicy as YesNo,
              lateDate: prepEventData.lateDate as string | null,
              latePenalty: prepEventData.latePenalty as number | null,
              published: prepAssessmentToEdit.published,
              isGradeSyncEnabled: prepAssessmentToEdit.isGradeSyncEnabled,
              hasInvalidOpenDueDates: prepEventData.hasInvalidOpenDueDates,
              hasInvalidDueLateDates: prepEventData.hasInvalidDueLateDates,
              assessType: AssessTypeEnum.Prep,
            },
            practiceTest: {
              id: practiceId,
              openDate: practiceAssessmentToEdit.openDate,
              dueDate: practiceAssessmentToEdit.dueDate,
              latePolicy: practiceAssessmentToEdit.latePolicy,
              lateDate: practiceAssessmentToEdit.lateDate,
              latePenalty: practiceAssessmentToEdit.latePenalty,
              published: practiceAssessmentToEdit.published,
              isGradeSyncEnabled: practiceAssessmentToEdit.isGradeSyncEnabled,
              hasInvalidOpenDueDates: practiceEventData.hasInvalidOpenDueDates,
              hasInvalidDueLateDates: practiceEventData.hasInvalidDueLateDates,
              assessType: AssessTypeEnum.PracticeTest,
            },
          },
        });
        break;
      case AssessmentListSummativeEvents.ChangePracticeLatePolicy:
        setEditedSummativeAssessmentsHash({
          ...editedSummativeAssessmentsHash,
          [summativeId]: {
            openDate: summativeAssessmentToEdit.openDate,
            dueDate: summativeAssessmentToEdit.dueDate,
            latePolicy: summativeAssessmentToEdit.latePolicy,
            lateDate: summativeAssessmentToEdit.lateDate,
            latePenalty: summativeAssessmentToEdit.latePenalty,
            published: summativeAssessmentToEdit.published,
            isGradeSyncEnabled: false,
            hasInvalidOpenDueDates: summativeEventData.hasInvalidOpenDueDates,
            hasInvalidDueLateDates: summativeEventData.hasInvalidDueLateDates,
            assessType: AssessTypeEnum.Summative,
            prep: {
              id: prepId,
              openDate: prepAssessmentToEdit.openDate,
              dueDate: prepAssessmentToEdit.dueDate,
              latePolicy: prepAssessmentToEdit.latePolicy,
              lateDate: prepAssessmentToEdit.lateDate,
              latePenalty: prepAssessmentToEdit.latePenalty,
              published: prepAssessmentToEdit.published,
              isGradeSyncEnabled: prepAssessmentToEdit.isGradeSyncEnabled,
              hasInvalidOpenDueDates: prepEventData.hasInvalidOpenDueDates,
              hasInvalidDueLateDates: prepEventData.hasInvalidDueLateDates,
              assessType: AssessTypeEnum.Prep,
            },
            practiceTest: {
              id: practiceId,
              openDate: practiceAssessmentToEdit.openDate,
              dueDate: practiceAssessmentToEdit.dueDate,
              latePolicy: practiceEventData.latePolicy as YesNo,
              lateDate: practiceEventData.lateDate as string | null,
              latePenalty: practiceEventData.latePenalty as number | null,
              published: practiceAssessmentToEdit.published,
              isGradeSyncEnabled: practiceAssessmentToEdit.isGradeSyncEnabled,
              hasInvalidOpenDueDates: practiceEventData.hasInvalidOpenDueDates,
              hasInvalidDueLateDates: practiceEventData.hasInvalidDueLateDates,
              assessType: AssessTypeEnum.PracticeTest,
            },
          },
        });
        break;
      case AssessmentListSummativeEvents.ChangePrepLateDate:
        setEditedSummativeAssessmentsHash({
          ...editedSummativeAssessmentsHash,
          [summativeId]: {
            openDate: summativeAssessmentToEdit.openDate,
            dueDate: summativeAssessmentToEdit.dueDate,
            latePolicy: summativeAssessmentToEdit.latePolicy,
            lateDate: summativeAssessmentToEdit.lateDate,
            latePenalty: summativeAssessmentToEdit.latePenalty,
            published: summativeAssessmentToEdit.published,
            isGradeSyncEnabled: false,
            hasInvalidOpenDueDates: summativeEventData.hasInvalidOpenDueDates,
            hasInvalidDueLateDates: summativeEventData.hasInvalidDueLateDates,
            assessType: AssessTypeEnum.Summative,
            prep: {
              id: prepId,
              openDate: prepAssessmentToEdit.openDate,
              dueDate: prepAssessmentToEdit.dueDate,
              latePolicy: prepAssessmentToEdit.latePolicy,
              lateDate: prepEventData.lateDate as string | null,
              latePenalty: prepAssessmentToEdit.latePenalty,
              published: prepAssessmentToEdit.published,
              isGradeSyncEnabled: prepAssessmentToEdit.isGradeSyncEnabled,
              hasInvalidOpenDueDates: prepEventData.hasInvalidOpenDueDates,
              hasInvalidDueLateDates: prepEventData.hasInvalidDueLateDates,
              assessType: AssessTypeEnum.Prep,
            },
            practiceTest: {
              id: practiceId,
              openDate: practiceAssessmentToEdit.openDate,
              dueDate: practiceAssessmentToEdit.dueDate,
              latePolicy: practiceAssessmentToEdit.latePolicy,
              lateDate: practiceAssessmentToEdit.lateDate,
              latePenalty: practiceAssessmentToEdit.latePenalty,
              published: practiceAssessmentToEdit.published,
              isGradeSyncEnabled: practiceAssessmentToEdit.isGradeSyncEnabled,
              hasInvalidOpenDueDates: practiceEventData.hasInvalidOpenDueDates,
              hasInvalidDueLateDates: practiceEventData.hasInvalidDueLateDates,
              assessType: AssessTypeEnum.PracticeTest,
            },
          },
        });
        break;
      case AssessmentListSummativeEvents.ChangePracticeLateDate:
        setEditedSummativeAssessmentsHash({
          ...editedSummativeAssessmentsHash,
          [summativeId]: {
            openDate: summativeAssessmentToEdit.openDate,
            dueDate: summativeAssessmentToEdit.dueDate,
            latePolicy: summativeAssessmentToEdit.latePolicy,
            lateDate: summativeAssessmentToEdit.lateDate,
            latePenalty: summativeAssessmentToEdit.latePenalty,
            published: summativeAssessmentToEdit.published,
            isGradeSyncEnabled: false,
            hasInvalidOpenDueDates: summativeEventData.hasInvalidOpenDueDates,
            hasInvalidDueLateDates: summativeEventData.hasInvalidDueLateDates,
            assessType: AssessTypeEnum.Summative,
            prep: {
              id: prepId,
              openDate: prepAssessmentToEdit.openDate,
              dueDate: prepAssessmentToEdit.dueDate,
              latePolicy: prepAssessmentToEdit.latePolicy,
              lateDate: prepAssessmentToEdit.lateDate,
              latePenalty: prepAssessmentToEdit.latePenalty,
              published: prepAssessmentToEdit.published,
              isGradeSyncEnabled: prepAssessmentToEdit.isGradeSyncEnabled,
              hasInvalidOpenDueDates: prepEventData.hasInvalidOpenDueDates,
              hasInvalidDueLateDates: prepEventData.hasInvalidDueLateDates,
              assessType: AssessTypeEnum.Prep,
            },
            practiceTest: {
              id: practiceId,
              openDate: practiceAssessmentToEdit.openDate,
              dueDate: practiceAssessmentToEdit.dueDate,
              latePolicy: practiceAssessmentToEdit.latePolicy,
              lateDate: practiceEventData.lateDate as string | null,
              latePenalty: practiceAssessmentToEdit.latePenalty,
              published: practiceAssessmentToEdit.published,
              isGradeSyncEnabled: practiceAssessmentToEdit.isGradeSyncEnabled,
              hasInvalidOpenDueDates: practiceEventData.hasInvalidOpenDueDates,
              hasInvalidDueLateDates: practiceEventData.hasInvalidDueLateDates,
              assessType: AssessTypeEnum.PracticeTest,
            },
          },
        });
        break;
      default:
        console.debug(`AssessmentsList change summative assessment invalid event type: ${type}`, eventData);
    }
  };

  return (
    <main className="assessment-list-container">
      <Prompt
        when={(!!Object.keys(editedAssessmentsHash).length || !!Object.keys(editedSummativeAssessmentsHash).length) && !isWorkerTriggered}
        message={`${sharedStrings.ASSESSMENT_LIST_DIRTY_PREFIX} ${sharedStrings.DIRTY_NAVIGATE_CONFIRMATION}`}
      />
      <div className="assessment-list-container__heading">
        Assessments
        <div className="assessment-list-container__heading-edit-mode">
          {!isEditMode ? (
            <BetterButton
              className='assessment-list-container__update'
              primary
              text="Update Status and Dates"
              icon={() => <BsPencil />}
              onClick={() => setEditMode(true)}
            />
          ) : (
            <>
              <BetterButton
                className='assessment-list-container__cancel'
                secondary
                text="Cancel"
                onClick={turnEditingOff}
              />
              <LoadingButton
                className="assessment-list-container__save"
                disabled={!Object.keys(editedAssessmentsHash).length && !Object.keys(editedSummativeAssessmentsHash).length}
                loading={saveInProgress}
                loadingText="Saving..."
                text="Save"
                onClick={saveChanges}
              />
            </>
          )}
        </div>
      </div>
      <table className="assessment-list-container__table">
        <thead>
          <tr>
            <th>
              NAME
            </th>
            <th>
              STATUS
            </th>
            {courseIsGradeSyncEnabled && (
              <th>
                LMS SYNC
              </th>
            )}
            <th className="centered">
              ITEMS
            </th>
            <th className="centered">
              PTS.
            </th>
            <th>
              OPEN DATE
            </th>
            <th>
              DUE DATE
            </th>
            <th>
              LATE DATE
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="first-row"/>
          {enrichedStudentAssessments.map((assessment: EnrichedStudentAssessment, index: number) => {
            const { assessType, id, name } = assessment;
            if ([AssessTypeEnum.Preclass, AssessTypeEnum.Homework, AssessTypeEnum.Readiness].includes(assessType)) {
              return (
                <React.Fragment key={`frag-esa-id-${id}_${name}_${reloadKeys[id] || 0}`}>
                  <AssessmentsListItem
                    defaultMinOpenDate={defaultMinOpenDate}
                    defaultMaxDueDate={defaultMaxDueDate}
                    disablePublished={false}
                    isEditMode={isEditMode}
                    assessment={assessment}
                    onChangeAssessment={handleChangeAssessmentEvent}
                    startedIds={startedIds}
                    hideGradeSyncPrompt={hideGradeSyncPrompt}
                    setHideGradeSyncPrompt={setHideGradeSyncPrompt}
                  />
                </React.Fragment>
              );
            } else if (assessType === AssessTypeEnum.Summative) {
              const originalSummative = assessments.find(a => a.assessType === AssessTypeEnum.Summative && (a as SummativeAssessmentApi).id === id) as SummativeAssessmentApi;
              // TODO move these finds to AssessmentListSummativeItems?
              const { prep, practiceTest } = originalSummative;
              const originalPrep = enrichedStudentAssessments.find(a => a.assessType === AssessTypeEnum.Prep && a.id === prep.id) as EnrichedStudentAssessment;
              const originalPracticeTest = enrichedStudentAssessments.find(a => a.assessType === AssessTypeEnum.PracticeTest && a.id === practiceTest.id) as EnrichedStudentAssessment;
              return (
                <React.Fragment key={`frag-esa-id-${id}_${name}_${reloadKeys[id] || 0}`}>
                  <AssessmentsListSummativeItems
                    defaultMinOpenDate={defaultMinOpenDate}
                    defaultMaxDueDate={defaultMaxDueDate}
                    isEditMode={isEditMode}
                    prep={originalPrep}
                    practiceTest={originalPracticeTest}
                    summativeId={id}
                    summativeDueDate={originalSummative.dueDate}
                    onChangeSummativeAssessment={handleChangeSummativeAssessmentEvent}
                    startedIds={startedIds}
                    hideGradeSyncPrompt={hideGradeSyncPrompt}
                    setHideGradeSyncPrompt={setHideGradeSyncPrompt}
                  />
                  {index < enrichedStudentAssessments.length - 1 && (
                    <>
                      <tr className="first-row"/>
                      <tr className='spacer-row'>
                        <td colSpan={8}></td>
                      </tr>
                    </>
                  )}
                </React.Fragment>
              );
            }
            return null;
          })}
          <tr/>
        </tbody>
      </table>
    </main>
  );
}
