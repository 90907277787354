import React from 'react';

import { MetacognitionData, calculateMetacognition } from '../shared/calculateMetacognition';
import { formatAssessmentItemPlural, formatPlural, formatPoints } from 'utils/commonFormattingFunctions';
import { useAppSelector } from 'store';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import IntroOutro from '../components/IntroOutro';
import ProgressCircle from 'shared-components/ProgressCircle/ProgressCircle';
import Icon, { IconEnum } from 'shared-components/Icon';
import MetacognitivePuddles from 'shared-components/Icon/MetacognitivePuddles';
import TextButton from 'shared-components/BetterButton/TextButton';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import sharedStrings from 'sharedStrings';

import { AssessmentTakerProps } from '../AssessmentTakerController.types';
import { ClarityEnum, YesNo } from 'types/backend/shared.types';
import { CorrectIncorrect } from 'types/backend/studentResponses.types';
import { GradingPolicyEnum } from 'types/backend/assessments.types';
import { L8yQuestionType } from 'types/backend/l8y.types';
import { PositionEnum } from 'types/common.types';
import { StudentCoursePath } from 'types/student.types';
import { StudyPathLaunch } from 'student/controllers/Course/StudyPathController/StudyPathController.types';
import { SummativeAssessmentSupplementsApi } from 'types/backend/summativeAssessmentSupplements.types';
import styles from 'style-config.scss';

function OutroPracticeTest({
  assessmentData,
  clarityHash,
  correctHash,
  earnedPoints,
  enrollmentData,
  handleReviewAssessment,
  questions,
  totalLatePointsDeducted,
  totalPoints,
  totalQuestions,
}: AssessmentTakerProps) {
  const isInstructor = useAppSelector((store) => !!store.state.instructorStudentViewCourseId);
  const summativeAssessmentSupplements = useAppSelector((store) => store.active.summativeAssessmentSupplements);

  // an item is considered nonGradable if it is essayRichText or if it is a multipart item and every part is an essayRichText question
  const nonGradableL8yIds = questions.reduce((acc, cur) => {
    if (cur.questionTypes.every((qType) => qType === L8yQuestionType.EssayRichText)) {
      acc.push(cur.l8yId);
    }
    return acc;
  }, [] as Array<string>);
  const metacogData = calculateMetacognition(correctHash, clarityHash, nonGradableL8yIds);

  const getSummativeId = (practiceTestId: string) => {
    const supplement = summativeAssessmentSupplements.find(supp => supp.practiceAssessmentId === practiceTestId) as SummativeAssessmentSupplementsApi;
    return supplement.id;
  };

  const buildMetacogTooltip = (clarity: ClarityEnum, correctness: CorrectIncorrect) => {
    const metacogCellInfo = `These are the questions you marked as ${clarity === ClarityEnum.Clear ? 'clear' : 'muddy'} and answered ${correctness === CorrectIncorrect.Correct ? '' : 'in'}correctly.`;
    const metacogCellSummary = {
      [ClarityEnum.Clear]: {
        [CorrectIncorrect.Correct]: 'This is an indication that you understand these materials AND are aware that you do!',
        [CorrectIncorrect.Incorrect]: "This may be an indication that you don't understand the concepts in these questions as well as you thought.",
      },
      [ClarityEnum.Muddy]: {
        [CorrectIncorrect.Correct]: 'This may be an indication that you understand the concept better than you thought. Or you may have made a lucky guess!',
        [CorrectIncorrect.Incorrect]: 'You probably want to study the concepts covered in these questions more, but you already know that!',
      },
    };
    return <>{metacogCellInfo} {metacogCellSummary[clarity][correctness]}</>;
  };

  const calculateMetacogMatch = (metacognitionData: MetacognitionData) => {
    const total = metacognitionData.clearCorrect + metacognitionData.clearIncorrect + metacognitionData.muddyCorrect + metacognitionData.muddyIncorrect;
    const match = (metacognitionData.clearCorrect + metacognitionData.muddyIncorrect) / total;
    const matchPercent = Math.round(match * 100);
    return total > 0 ? matchPercent : '';
  };

  const renderMetacogCell = (count: number, clarity: ClarityEnum, correctness: CorrectIncorrect) => {
    const confused = (correctness === CorrectIncorrect.Correct && clarity === ClarityEnum.Muddy) ||
      (correctness === CorrectIncorrect.Incorrect && clarity === ClarityEnum.Clear);
    return (
      <td className={`practice-test-metacog-cell metacog__${clarity}-${correctness}`}>
        <BetterTooltip content={buildMetacogTooltip(clarity, correctness)} position={PositionEnum.Bottom}>
          <div className="practice-test-metacog-cell__content">
            <div>{count} {formatPlural('question', count)}</div>
            <div className="practice-test-metacog-cell__icon">
              <MetacognitivePuddles confused={confused} />
            </div>
          </div>
        </BetterTooltip>
      </td>
    );
  };

  const hasMetacogData = Object.keys(clarityHash).length === totalQuestions;  // if there is not clarity data for every questions, then this PT was started before the metacog feature was added
  const hasGradableQuestions = totalQuestions !== nonGradableL8yIds.length;
  const hasOpenResponseQuestions = !!nonGradableL8yIds.length;
  const totalGradableQuestions = totalQuestions - nonGradableL8yIds.length;
  const autoGradedCorrectQuestionCount = metacogData.clearCorrect + metacogData.muddyCorrect;
  const muddyQuestionCount = Object.values(clarityHash).filter(value => value === ClarityEnum.Muddy).length; //used when the assessment has only OR questions.
  const progress = Math.round((autoGradedCorrectQuestionCount / totalGradableQuestions) * 100);
  const { gradingPolicy } = assessmentData;
  const scoredOpenResponseCount = nonGradableL8yIds.reduce((acc, cur) => {
    correctHash[cur] === YesNo.Yes && acc++;
    return acc;
  }, 0);
  return (
    <IntroOutro>
      <div className="assessment-taker-outro__content assessment-taker-outro__content-side-by-side">
        <div className="assessment-taker-outro__side-by-side">
          <div className="assessment-taker-outro__side-by-side-box row">
            <div className="assessment-taker-outro__side-by-side-box-left col-xs-2">
              <ProgressCircle
                size={42}
                progress={progress}
                strokeWidth={10}
                color={styles.grayDark}
                bgColor={styles.grayLight}
              />
            </div>
            <div className="assessment-taker-outro__side-by-side-box-right col-xs-10">
              <div className="assessment-taker-outro__content-title">PERFORMANCE{hasGradableQuestions && `: ${progress}% Correct`}</div>
              <ul>
                {hasGradableQuestions && (
                  <li>{autoGradedCorrectQuestionCount} of {totalGradableQuestions} {!hasOpenResponseQuestions || !hasMetacogData ? '' : 'computer-graded'} {formatAssessmentItemPlural(totalGradableQuestions)} answered correctly</li>
                )}
                {hasOpenResponseQuestions && (
                  <li>{scoredOpenResponseCount} of {nonGradableL8yIds.length} open-response {formatPlural('question', nonGradableL8yIds.length)} completed - check your answer(s) against the sample answer(s) when reviewing your results in the Study Path.</li>
                )}
                {gradingPolicy !== GradingPolicyEnum.NoPoints && (
                  <li>{formatPoints(earnedPoints)} of {totalPoints} {formatPlural('point', totalPoints)} earned</li>
                )}
                {totalLatePointsDeducted > 0 && (
                  <li><em>{formatPoints(totalLatePointsDeducted)} {formatPlural('point', totalLatePointsDeducted)} deducted for late work</em></li>
                )}
              </ul>
            </div>
          </div>
          {hasMetacogData && (
            <div className="assessment-taker-outro__side-by-side-box row">
              <div className="assessment-taker-outro__side-by-side-box-left col-xs-2">
                <Icon which={IconEnum.Muddy} size={42}/>
              </div>
              {hasGradableQuestions ? (
                <div className="assessment-taker-outro__side-by-side-box-right col-xs-10">
                  <div className="assessment-taker-outro__content-title">METACOGNITION: {calculateMetacogMatch(metacogData)}% Match</div>
                  <div className="assessment-taker-outro__practice-test-metacog">
                    <ul>
                      <li>{metacogData.muddyCorrect + metacogData.muddyIncorrect} of {totalGradableQuestions}  {hasOpenResponseQuestions && 'computer-graded'} {formatPlural('question', totalGradableQuestions)} marked muddy</li>
                      {hasGradableQuestions && (
                        <li>Compare your performance (correct or incorrect) to your confidence (muddy or clear) to test your metacognition.</li>
                      )}
                      {hasOpenResponseQuestions && !hasGradableQuestions && (
                        <li>After evaluating your answers in the Study Path, compare your performance (correct or incorrect) to your confidence (muddy or clear) to test your metacognition.</li>
                      )}
                    </ul>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Clear</th>
                          <th>Muddy</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Correct</td>
                          {renderMetacogCell(metacogData.clearCorrect, ClarityEnum.Clear, CorrectIncorrect.Correct)}
                          {renderMetacogCell(metacogData.muddyCorrect, ClarityEnum.Muddy, CorrectIncorrect.Correct)}
                        </tr>
                        <tr>
                          <td>Incorrect</td>
                          {renderMetacogCell(metacogData.clearIncorrect, ClarityEnum.Clear, CorrectIncorrect.Incorrect)}
                          {renderMetacogCell(metacogData.muddyIncorrect, ClarityEnum.Muddy, CorrectIncorrect.Incorrect)}
                        </tr>
                      </tbody>
                    </table>
                    {hasMetacogData && hasOpenResponseQuestions && <em>*Open-response questions are not included in metacognition or performance analyses.</em>}
                  </div>
                </div>
              ) : (
                <div className="assessment-taker-outro__side-by-side-box-right col-xs-10">
                  <div className="assessment-taker-outro__content-title">METACOGNITION</div>
                  <div className="assessment-taker-outro__practice-test-metacog">
                    <ul>
                      <li>{muddyQuestionCount} of {totalQuestions} {formatPlural('question', totalQuestions)} marked muddy</li>
                      <li>After evaluating your answers in the Study Path, compare your performance (correct or incorrect) to your confidence (muddy or clear) to test your metacognition.</li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="assessment__action">
        <div>
          <BetterButton
            className="big-button"
            primary
            text="See my Practice Test Results in the Study Path!"
            linkTo={`/student/course/${enrollmentData.courseId}/${StudentCoursePath.StudyPath}?summativeAssessmentId=${getSummativeId(assessmentData.id)}&launchInto=${StudyPathLaunch.PracticeTestReview}`}
          />
        </div>
        <div>
          or
          <div className="assessment-outro__review">
            <TextButton className="assessment-outro__review-assessment" onClick={handleReviewAssessment}>Review Assignment Info</TextButton>
            {isInstructor && <div className="assessment-outro__review-assessment-instructor">{sharedStrings.INSTRUCTOR_PREVIEW_REVIEW_ASSIGNMENT}</div>}
          </div>
        </div>
      </div>
    </IntroOutro>
  );
}

export default OutroPracticeTest;
