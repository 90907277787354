import React, { createContext, useContext, useState } from 'react';
import { ConfirmationTypeEnum } from 'types/common.types';

export interface ConfirmationPromptProps {
  title: string                                // title provided above the message
  message: React.ReactNode | string            // message provided above the buttons
  confirmButtonText?: string                   // optional text to replace "OK" on button
  confirmationType?: ConfirmationTypeEnum      // controls the formatting / type of the prompt itself (eg, alert or warn formatting)
  onConfirm: () => void                        // called if the confirmation button is clicked
  onCancel?: () => void                        // called if present and if the cancel button is clicked
}

interface ConfirmationPromptContextValue {
  isOpen: boolean
  props: ConfirmationPromptProps
}

type SetConfirmationPromptContextValue = React.Dispatch<
  React.SetStateAction<ConfirmationPromptContextValue>
>;

const defaultValue: ConfirmationPromptContextValue = {
  isOpen: false,
  props: {
    title: '',
    message: '',
    confirmationType: ConfirmationTypeEnum.Alert,
    onConfirm: () => null,
    onCancel: () => null,
  },
};

export const ConfirmationPromptContext = createContext([defaultValue, () => {}] as [ConfirmationPromptContextValue, SetConfirmationPromptContextValue]);

export const ConfirmationPromptContextProvider = ({ children }: {children: React.ReactNode}) => {
  const [confirmationPrompt, setConfirmationPrompt] = useState(defaultValue);

  return (
    <ConfirmationPromptContext.Provider value={[confirmationPrompt, setConfirmationPrompt]}>
      {children}
    </ConfirmationPromptContext.Provider>
  );
};

export const useConfirmationPrompt = () => {
  const [, setConfirmationPrompt] = useContext(ConfirmationPromptContext);

  const triggerConfirmationPrompt = (props: ConfirmationPromptProps) => {
    setConfirmationPrompt({ isOpen: true, props });
  };

  return { triggerConfirmationPrompt };
};
