import cloneDeep from 'lodash-es/cloneDeep';

import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { AppDispatch, Store } from 'types/store.types';


export default function sortAssessmentQuestionMap(movingAssessmentQuestionId: number, previousId: number) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const store = getStore();

    // ordering logic:
    // if prevId missing move to end
    // if prevId = 0 move to beginning (all AQMs must be reorderd on client)
    const assessmentQuestionMaps: Array<AssessmentQuestionApi> = cloneDeep(store.active.assessmentQuestionMaps);

    const movingAqm: AssessmentQuestionApi | undefined = assessmentQuestionMaps.find(qm => qm.id === movingAssessmentQuestionId);

    if (movingAqm === undefined) {
      return; //bail if the AQM doesn't exist, but that shouldn't happen . . .
    }

    movingAqm.previousId = previousId; //used for the API update
    delete movingAqm.order; //delete order for API to work. This recommended but may not be required.

    //update the server
    await apiNext.editAssessmentQuestionMap(movingAqm.id, movingAqm);

    //get all the new maps for this assessment
    const editingQuestionMaps: Array<AssessmentQuestionApi> = await apiNext.getAssessmentQuestionMaps([movingAqm.assessmentId]);

    //update redux with the new maps
    //1. insert the rearranged question maps for this asessment into all question maps
    const startIndex: number = assessmentQuestionMaps.findIndex(aqm => aqm.assessmentId === movingAqm.assessmentId);
    assessmentQuestionMaps.splice(startIndex, editingQuestionMaps.length, ...editingQuestionMaps);

    //2. make change locally for instant ui:
    dispatch(activeSlice.actions.setActiveAssessmentQuestionMaps(assessmentQuestionMaps));

  })();
}
