// sourced from https://github.com/codonlearning/backend/blob/dev/src/services/enrollments/enrollments.types.ts
import { RoleEnum } from 'types/backend/roles.types';

export enum MethodEnum {
  Grace = 'grace',
  Free = 'free',
  CreditCard = 'credit_card',
  AccessCode = 'access_code'
}

interface EnrollmentDbBase {
  id: number
  user__id: string
  course__id: string
  role__id: RoleEnum
  method: MethodEnum
  created_at: string
  updated_at: string
}

export interface EnrollmentDbIn extends EnrollmentDbBase {
  start_date?: string
  end_date?: string | null
  first_accessed_at?: string | null
}

export interface EnrollmentDbOut extends EnrollmentDbBase {
  start_date: Date
  end_date: Date | null
  first_accessed_at: Date | null
}

export interface EnrollmentApi {
  id: number
  userId: string
  courseId: string
  roleId: RoleEnum
  startDate?: string
  endDate?: string | null
  method: MethodEnum
  createdAt: string
  updatedAt: string
  firstAccessedAt?: string | null
}
