import { DateTime } from 'luxon';

import { CourseApi } from 'types/backend/courses.types';
import { EnrollmentApi, MethodEnum } from 'types/backend/enrollments.types';
import { RoleEnum } from 'types/backend/roles.types';
import { AccessStatusEnum } from 'types/common.types';

const paidEnrollmentMethods = [MethodEnum.AccessCode, MethodEnum.CreditCard];

export function determineCourseAccessData(enrollment: EnrollmentApi, course: CourseApi) {
  const { accessDate, graceEndDate } = course;
  const accessStatus = determinePaymentStatus(enrollment, graceEndDate, accessDate);
  const blockAccess = [AccessStatusEnum.FirstAccess, AccessStatusEnum.GracePeriodExpired, AccessStatusEnum.NotStarted].includes(accessStatus) && enrollment.roleId !== RoleEnum.Instructor;
  const accessDateStarted = determineIfCourseAccessStarted(accessDate);
  return { accessStatus, blockAccess, accessDateStarted };
}

function determineIfCourseAccessStarted(accessDate: string) {
  const nowEOD = DateTime.now().endOf('day');
  const accessDateStartOfDay = DateTime.fromISO(accessDate).startOf('day');
  return nowEOD >= accessDateStartOfDay;
}

function determinePaymentStatus(enrollment: EnrollmentApi, graceEndDateString: string, accessDateString: string) {
  const { firstAccessedAt } = enrollment;
  let accessStatus: AccessStatusEnum;
  const nowEOD = DateTime.now().endOf('day');
  const accessDateStartOfDay = DateTime.fromISO(accessDateString).startOf('day');
  const graceEndDateEOD = DateTime.fromISO(graceEndDateString).endOf('day');
  const isEnrolledStudent = enrollment.roleId === RoleEnum.Student;
  const isEnrolledInstructor = enrollment.roleId === RoleEnum.Instructor;

  if (nowEOD < accessDateStartOfDay && isEnrolledStudent) {
    accessStatus = AccessStatusEnum.NotStarted;
  } else if (paidEnrollmentMethods.includes(enrollment.method) && isEnrolledStudent) {
    accessStatus = AccessStatusEnum.Paid;
  } else if (enrollment.method === MethodEnum.Free && isEnrolledStudent) {
    accessStatus = AccessStatusEnum.Free;
  } else if ((enrollment.method === MethodEnum.Grace || isEnrolledInstructor) && graceEndDateEOD < nowEOD) {
    accessStatus = AccessStatusEnum.GracePeriodExpired;
  } else if ((enrollment.method === MethodEnum.Grace || isEnrolledInstructor) && graceEndDateEOD.minus({ days: 5 }) < nowEOD) {
    accessStatus = AccessStatusEnum.GracePeriodWarning;
  } else if (!firstAccessedAt && isEnrolledStudent) {
    accessStatus = AccessStatusEnum.FirstAccess;
  } else {
    accessStatus = AccessStatusEnum.ActiveGracePeriod;
  }
  return accessStatus;
}

//TODO:
// stripe success and failure URLs


