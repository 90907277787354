import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { GenericObject } from 'types/backend/shared.types';
import './ApiErrorDisplay.scss';


export interface ApiError extends GenericObject {
  name: string
  code: number
  message: string
}

type ApiErrorPropDisplayTypes = {
  error: ApiError
  expires?: string
}

function ApiErrorDisplay(props: ApiErrorPropDisplayTypes) {
  const { error: { name, code, message }, expires } = props;
  const isExpired = expires && DateTime.fromISO(expires).diffNow('minutes').minutes < 0;
  if (isExpired) {
    return null;
  }
  return (
    <div className="api-error">
      <h1>Api Error</h1>
      <p><b>name:</b> {name}</p>
      <p><b>code:</b> {code}</p>
      <p><b>message:</b> {message}</p>
    </div>
  );
}

ApiErrorDisplay.propTypes = {
  error: PropTypes.object.isRequired,
  expires: PropTypes.string,
};

export default ApiErrorDisplay;
