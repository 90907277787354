import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';

import apiNext from 'api-next';
import { AnnouncerContextProvider } from 'shared-components/AriaAnnouncer/AnnouncerContext';
import { ConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPrompt';
import { ConfirmationPromptContextProvider } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import { PollingProvider } from 'shared-components/Polling/PollingContext';
import { ToastContextProvider } from 'shared-components/ToastNotification/ToastNotificationContext';

import reduxStore, { persistor, useAppDispatch, useAppSelector } from './store';
import loadUserEnrollments from 'store/actions/loadUserEnrollments';

import AppRoutes from './AppRoutes';
import MfaTokenVerification from 'shared-components/Login/MfaTokenVerification';
import Login from 'shared-components/Login/Login';
import CacheBuster from './CacheBuster';
import ErrorBoundary from 'shared-components/ErrorBoundary/ErrorBoundary';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import UserAgreement from 'shared-components/Login/UserAgreement';
import { BooleanHash, PortalEnum } from 'types/common.types';
import './App.scss';

const version = process.env.REACT_APP_VERSION || '0.0.0';

export const FeatureFlags = React.createContext<BooleanHash>({});

function AppMain() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((store) => store.user);
  const currentUrl = window.location.href;
  const isLtiLaunch = !!currentUrl.includes('lti-launch');
  const passResetRequest = currentUrl.includes('password-reset') || currentUrl.includes('auth-manager');
  if (!user && !isLtiLaunch && !passResetRequest) { //if no user exist and it is not an LTI launch, display login form
    return (
      <Router>
        <Login />
      </Router>
    );
  } else {
    if (user?.isMfaRequired && user?.mfaIssueDate) {
      return (
        <Router>
          <MfaTokenVerification />
        </Router>
      );
    }
    if (user && !user.agreementDate && !apiNext.masqueradeUserId) {
      return (
        <Router>
          <UserAgreement />
        </Router>
      );
    }
    if (!isLtiLaunch && !passResetRequest) {
      dispatch(loadUserEnrollments(user.id));
    }
    const featureFlagData = {
      enableCustomQuestions: true,
      enableCustomLos: true,
      enableReadinessMaterials: true,
    };
    return (
      <FeatureFlags.Provider value={featureFlagData}>
        <ConfirmationPromptContextProvider>
          <PollingProvider>
            <ToastContextProvider>
              <ConfirmationPrompt/>
              <Router>
                <QueryParamProvider
                  adapter={ReactRouter5Adapter}
                  options={{ searchStringToObject: parse, objectToSearchString: stringify }}
                >
                  <AnnouncerContextProvider>
                    <AppRoutes user={user} />
                  </AnnouncerContextProvider>
                </QueryParamProvider>
              </Router>
            </ToastContextProvider>
          </PollingProvider>
        </ConfirmationPromptContextProvider>
      </FeatureFlags.Provider>
    );
  }
}

function App() {
  // this is to handle the current situation where production NODE_ENV = prod but for cache busting we want to treat either as production
  // enable CacheBuster on local when using `npm run serve-static`
  const isProduction = ['production', 'prod', 'staging', 'perf', 'dev', 'local'].includes(process.env.NODE_ENV);
  console.info('Codon version', version);
  return (
    <div className="app">
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction}
        loadingComponent={<LoadingSpinner />}
      >
        <>
          <ReduxProvider store={reduxStore}>
            <PersistGate loading={null} persistor={persistor}>
              <ErrorBoundary>
                <AppMain />
              </ErrorBoundary>
            </PersistGate>
          </ReduxProvider>
          <div className="app__modal" id={PortalEnum.ModalPortal}></div>
          <div className="app__modal" id={PortalEnum.TooltipPortal}></div>
        </>
      </CacheBuster>
    </div>
  );
}

export default App;
