import React from 'react';
import {
  FaAngleDoubleDown,
  FaAngleDoubleUp,
  FaAngleDown,
  FaAngleUp,
} from 'react-icons/fa';
import { UpDownTopBottomEnum } from 'types/common.types';
import './ReorderButtons.scss';

interface ReorderButton {
  action: UpDownTopBottomEnum
  isDisabled: boolean
  icon: React.ReactNode
  title: string
}

function ReorderButtons({
  upDisabled,
  downDisabled,
  isProcessing,
  handleAction,
}: {
  upDisabled: boolean
  downDisabled: boolean
  isProcessing: boolean
  handleAction: (action: UpDownTopBottomEnum) => Promise<void>
}) {
  // not desired with the current BCP reorder LOs implementation but we may want it later in other instances
  const showMoveToTopAndBottom = false;
  const topBottomButtons = [
    {
      action: UpDownTopBottomEnum.Top,
      isDisabled: upDisabled,
      icon: <FaAngleDoubleUp />,
      title: 'Move to Top',
    },
    {
      action: UpDownTopBottomEnum.Bottom,
      isDisabled: downDisabled,
      icon: <FaAngleDoubleDown />,
      title: 'Move to Bottom',
    },
  ];
  const upDownButtons = [
    {
      action: UpDownTopBottomEnum.Up,
      isDisabled: upDisabled,
      icon: <FaAngleUp />,
      title: 'Move Up',
    },
    {
      action: UpDownTopBottomEnum.Down,
      isDisabled: downDisabled,
      icon: <FaAngleDown />,
      title: 'Move Down',
    },
  ];

  const renderBtn = ({ action, isDisabled, icon, title }: ReorderButton) => (
    <button
      disabled={isProcessing || isDisabled}
      onClick={() => handleAction(action)}
      data-action={action}
      key={action}
      title={title}
    >
      {icon}
    </button>
  );

  return (
    <div className="reorder-buttons">
      {showMoveToTopAndBottom && (
        <div className="reorder-buttons__left">
          {topBottomButtons.map(renderBtn)}
        </div>
      )}
      <div className="reorder-buttons__right">
        {upDownButtons.map(renderBtn)}
      </div>
    </div>
  );
}

export default ReorderButtons;
