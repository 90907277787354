// sourced from: https://github.com/codonlearning/backend/blob/dev/src/services/assessmentScoreSync/assessmentScoreSync.types.ts
import { UserApiIdNameEmail } from 'types/backend/users.types';

export enum SyncStatus {
  InProgress = 'in_progress',
  Completed = 'completed',
  Failed = 'failed',
  PartiallyFailed = 'partially_failed'
}

export interface AssessmentScoreSyncDb {
  id: string
  assessment__id: string
  submitted_score_qty: number
  synced_score_qty: number
  sync_status: SyncStatus
  start_timestamp: string
  end_timestamp: string
  created_at: string
  updated_at: string
  sync_job_data?: Array<ScoreSyncJobRequest>
}

export interface ProgressResult {
  failedQty: number
  completedQty: number
  submittedScoreQty: number
}

export interface AssessmentScoreSyncApi {
  id: string
  assessmentId: string
  submittedScoreQty: number
  syncedScoreQty: number
  syncStatus: SyncStatus
  startTimestamp: string
  endTimestamp: string
  createdAt: string
  updatedAt: string
  syncJobData?: Array<ScoreSyncJobRequest>
  syncFailedUsers?: Array<UserApiIdNameEmail>
  progressResult: ProgressResult | null
}

export interface ScoreSyncJobRequest {
  userId: string
  timestamp: string
  scoreGiven: number | undefined
  scoreMaximum: number | undefined
  activityProgress: ActivityProgress
  gradingProgress: GradingProgress
  lineItemId: string
  contextId: string
  comment?: string
  ltiRegistrationId?: number
}

export enum ActivityProgress {
  Initialized = 'Initialized',
  Started = 'Started',
  InProgress = 'InProgress',
  Submitted = 'Submitted',
  Completed = 'Completed'
}

export enum GradingProgress {
  FullyGraded = 'FullyGraded',
  Pending = 'Pending',
  PendingManual = 'PendingManual',
  Failed = 'Failed',
  NotReady = 'NotReady',
}

export interface SyncJobSQSMessage {
  MessageId: string
  ReceiptHandle: string
  MD5OfBody: string
  Body: string
}

export interface StudentAssessmentForSync {
  totalPointsEarned: number
  userId: string
}

export interface ScoreSyncJobSQSMessageBody extends ScoreSyncJobRequest {
  assessmentScoreSyncId: string
}
