import React, { useEffect, useState } from 'react';
import { FaChevronUp } from 'react-icons/fa';
import { CoursePlannerWeek } from 'store/selectors/retrieveBetterCoursePlannerWeeks';
import { BCPViewMode } from '../BetterCoursePlannerController';

const CoursePlannerWeekContainer = ({
  children,
  expanded = true,
  onExpandToggle,
  viewMode,
  week,
}: {
  children: React.ReactNode
  expanded: boolean | null
  onExpandToggle: () => void
  viewMode: BCPViewMode
  week: CoursePlannerWeek
}) => {
  const { weekIsInPast, weekNumber, weekTopics } = week;
  // all that CoursePlannerWeek needs to worry about is expand/collapse, there are currently no week-level actions
  const [weekExpanded, setWeekExpanded] = useState(!weekIsInPast);

  useEffect(() => {
    // if parent expand state is null, its value can be ignored
    if (expanded !== null) {
      // but if it is boolean, update inner expanded state
      if (weekExpanded && !expanded) {
        setWeekExpanded(false);
      } else if (!weekExpanded && expanded) {
        setWeekExpanded(true);
      }
    }
  }, [weekExpanded, expanded]);

  const handleExpandClick = () => {
    // reset parent expand state on internal week expand
    onExpandToggle();
    setWeekExpanded(!weekExpanded);
  };

  return (
    <div
      className="bcp__week"
      data-expanded={weekExpanded}
      data-weeknumber={weekNumber}
      data-bcp-view-mode-week={viewMode}
      data-week-is-in-past={weekIsInPast}
    >
      <button onClick={handleExpandClick} className="bcp__week-header row">
        <div className="bcp__week-header__title col-xs-1">
          Week {weekNumber}
        </div>
        {!weekExpanded && (
          <div className="bcp__week-header__topic-list col-xs-10">
            {weekTopics.map(({ name: topicName }) => topicName).join(' | ')}
          </div>
        )}
        <div
          className="bcp__action-button bcp__week-header__action bcp__expando-caret col-xs-1"
          data-expanded={weekExpanded}
          title={`${weekExpanded ? 'Collapse' : 'Expand'} Week`}
        >
          {weekExpanded ? 'Collapse' : 'Expand'}
          <FaChevronUp />
        </div>
      </button>
      <div className="bcp__week-body" data-expanded={weekExpanded}>
        {weekExpanded && children}
      </div>
    </div>
  );
};

export default CoursePlannerWeekContainer;
