import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from 'react';

function useInput(initialValue = ''): [string, (e: ChangeEvent<HTMLInputElement>) => void, Dispatch<SetStateAction<string>>] {
  const [inputValue, setInputValue] = useState(initialValue);
  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setInputValue(e.currentTarget.value), []);
  return [inputValue, handleInputChange, setInputValue];
}

export default useInput;
