import { AssessmentInitApi } from 'types/backend/assessmentInit.types';
import { L8yQuestionType } from 'types/backend/l8y.types';
import { L8yGetItemsRequest } from 'types/backend/l8yItems.types';
import { ClarityEnum, YesNo } from 'types/backend/shared.types';
import { ClarityHash } from 'types/common.types';

/*
  These events are passed from L8yContainer to whatever component it lives in.
  They don't necessarily exist in L8y.
*/
export enum L8yContainerEvents {
  QUESTION_CHANGED = 'QUESTION_CHANGED',
  ITEM_CHANGED = 'ITEM_CHANGED',
  QUESTIONS_LOADED = 'QUESTIONS_LOADED',
  ITEM_GOTO = 'ITEM_GOTO',
  ITEM_UNLOAD = 'ITEM_UNLOAD',
  HANDLE_CLARITY = 'HANDLE_CLARITY',
  CONTAINER_LOADED = 'CONTAINER_LOADED',
}

/*
  These are L8y-specific events.
  https://reference.learnosity.com/items-api/events
*/
export enum L8yEvents {
  ITEM_LOAD = 'item:load',
  ITEM_CHANGED = 'item:changed',
  ITEM_GOTO = 'item:goto',
  ITEM_UNLOAD = 'item:unload',
  ITEM_SETATTEMPTEDRESPONSE = 'item:setAttemptedResponse',
  ITEMS_FETCH_ERROR = 'items:fetch:error',
  ITEMS_LAZYLOAD_ERROR = 'items:lazyload:error',
  TEST_FINISHED_SUBMIT = 'test:finished:submit',
}

export interface QuestionStatus {
  allORQs: boolean
  canRecap: boolean
  clarity?: ClarityEnum
  isCorrect?: YesNo
  isSurveyItem: boolean
}
export interface QuestionStatusHash {
  [key: string]: QuestionStatus
}

export interface L8yErrorData {
  code: number
  msg: string
  errorUI?: string
  detail?: string
}

export interface L8yContainerEventData {
  activeL8yRef: string
  clarityHash?: ClarityHash
  questionIds?: Array<string>
}

// <Learnosity Method Responses>

export type LearnosityItemsInit = (
  data: AssessmentInitApi,
  region: string,
  listeners: {
    errorListener?: (error: L8yErrorData) => void
    readyListener?: () => void
    customUnload: () => void
  }
) => Promise<LearnosityItemsApi>
export interface LearnosityValidateOptions {
  showCorrectAnswers?: boolean
  showDistractorRationale?: string
}

export interface LearnosityItemsApiQuestion {
  disable: () => void
  resetValidationUI: () => void
  isValid: () => boolean
  isAttempted: () => boolean
  getQuestion: () => LearnosityGetQuestion
  getResponse: () => LearnosityGetResponse
  on: (event: string, callback: () => Promise<void> | void) => void
  mapValidationMetadata: (validation: string) => LearnosityResponseLevel
  trigger: (event: 'validated') => void
  validate: (validateOpts: LearnosityValidateOptions) => void
  getScore: () => { max_score: number; score: number }
}

export interface LearnosityItemsObject {
  goto: (l8yId: string) => void
  next: () => void
  previous: () => void
}

export interface LearnosityItemsApi {
  question: (q: string) => LearnosityItemsApiQuestion
  questions: () => { [key: string]: unknown }
  save: () => void
  getItems: () => { [key: string]: ItemsFromL8y }
  items: () => LearnosityItemsObject
  on: (l8yEvent: L8yEvents, callback: (e: any) => void) => void | Promise<void>
}

export type LearnosityResponseValue = string | Array<Array<number | string> | number | null | string>;

export interface LearnosityGetResponse {
  value: LearnosityResponseValue
  type: string
  apiVersion: string
  revision: number
  updatedFormat?: boolean
}

// this is very similar to l8yQuestions.types L8yQuestion, but based on real world outputs
export interface LearnosityGetQuestion {
  case_sensitive?: boolean
  duplicate_responses?: boolean
  instant_feedback: boolean
  is_math: boolean
  metadata: {
    distractor_rationale?: string
    distractor_rationale_response_level?: Array<string>
    sample_answer?: string
    sheet_reference: string
    valid_response_count: number
    widget_reference: string
  }
  multiple_responses?: boolean
  possible_responses?: Array<string>
  response_id: string
  shuffle_options?: boolean
  stimulus: string
  template?: string
  type: L8yQuestionType
  validation: {
    scoring_type: string
    penalty?: number
    valid_response: {
      value: Array<Array<number | string> | string | number>
      score: number
    }
  }
}

export interface LearnosityCorrect {
  metadata: string
  value: Array<string> | string
  index: number
}

export interface LearnosityIncorrect {
  metadata: string
  value: null | string | Array<string>
  index: number
}

export interface LearnosityResponseLevel {
  correct: Array<LearnosityCorrect>
  incorrect: Array<LearnosityIncorrect>
  unattempted: Array<LearnosityIncorrect>
}

export interface ItemsFromL8y extends L8yGetItemsRequest {
  questions: Array<LearnosityGetQuestion>
}
// </Learnosity Method Responses>
