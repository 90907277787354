/*************
 * retrieveNumberedAssessmentMap
* a small selector that builds a map of assessmentIds and their sequential number by assessType
* used for correct naming in the study path
*/

import { createSelector } from '@reduxjs/toolkit';
import { AssessmentApiBase, AssessTypeEnum } from 'types/backend/assessments.types';
import { Store } from 'types/store.types';

export default createSelector((store: Store) => store.active.assessments, (assessments: Array<AssessmentApiBase>): Map<string, number> => {

  let preCount = 0;
  let hwCount = 0;
  let prepCount = 0;
  let practiceCount = 0;
  const assessmentNumberMap = new Map();
  assessments.forEach(assessment => {
    switch (assessment.assessType) {
      case AssessTypeEnum.Preclass:
        preCount++;
        assessmentNumberMap.set(assessment.id, preCount);
        break;
      case AssessTypeEnum.Homework:
        hwCount++;
        assessmentNumberMap.set(assessment.id, hwCount);
        break;
      case AssessTypeEnum.Prep:
        prepCount++;
        assessmentNumberMap.set(assessment.id, prepCount);
        break;
      case AssessTypeEnum.PracticeTest:
        practiceCount++;
        assessmentNumberMap.set(assessment.id, practiceCount);
        break;
      default:
        break;
    }
  });

  return assessmentNumberMap;
});
