import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import { handleStudentAttemptResponse } from 'utils/assessmentFunctions';

import LearnosityContainer, { L8yContainerValidated } from 'shared-components/LearnosityContainer/LearnosityContainer';
import StudyPathPracticeTestAssessmentTakerNavMenu from './StudyPathPracticeTestAssessmentTakerNavMenu';

import apiNext from 'api-next';
import { useAppDispatch } from 'store';
import loadStudentAssessmentsAndStudentAssessmentQuestions from 'store/actions/loadStudentAssessmentsAndStudentAssessmentQuestions';
import {
  enrichQuestionDataForSPATL8y,
  getStudyPathHashesFromSaqa,
  QuestionDatumForSPATL8y,
  StudyPathEnrichedQuestion,
} from '../../sharedStudyPathFunctions';

import { AssessmentModeEnum, RenderingTypeEnum } from 'types/backend/assessmentInit.types';
import { L8ySessionState } from 'types/backend/l8ySessions.types';
import { L8yBasicItem } from 'types/backend/questions.types';
import { AssessmentLocation } from 'types/backend/shared.types';
import { EnrichedStudentTopicCardLearningObjective } from 'student/controllers/Course/StudyPathController/StudyPathController.types';
import { L8yContainerEvents, L8yContainerEventData, QuestionStatusHash } from 'shared-components/LearnosityContainer/LearnosityContainer.types';
import './StudyPathAssessmentTaker.scss';

interface StudyPathPracticeTestAssessmentTakerProps {
  handleDone: (done: boolean) => void
  l8ySessionId: string
  learningObjectives: Array<EnrichedStudentTopicCardLearningObjective>
  location: AssessmentLocation
  todoQuestions: Array<StudyPathEnrichedQuestion>
  pastQuestions: Array<StudyPathEnrichedQuestion>
  userId: string
}

function StudyPathPracticeTestAssessmentTaker({
  handleDone,
  l8ySessionId,
  learningObjectives,
  location,
  todoQuestions,
  pastQuestions,
  userId,
}: StudyPathPracticeTestAssessmentTakerProps) {
  const dispatch = useAppDispatch();
  const { triggerConfirmationPrompt } = useConfirmationPrompt();

  /*********************
   * This effect causes studentAssessments and StudentAssessmentQuestions to reload and update state
   * which in turn triggers a study path reload from the corresponding dependencies in the Course component
   */
  useEffect(() => {
    return () => {
      dispatch(loadStudentAssessmentsAndStudentAssessmentQuestions());
    };
  }, [dispatch]);

  // since there is no concept of clicking on individual questions when entering the SPPTAT, get the targetQuestionL8yId from the first available question
  const availableQuestions = [...todoQuestions, ...pastQuestions];
  const [firstItem] = availableQuestions;
  const { l8yId: targetQuestionL8yId } = firstItem?.question || {};

  // Get the array of LOs that the target question belongs to in order to show the title data
  const targetLOs = learningObjectives.filter((lo: { assessmentQuestions: Array<{ question: { l8yId: string }}> }) => lo.assessmentQuestions.map(({ question }) => question.l8yId).includes(targetQuestionL8yId));
  const [activeLO, setActiveLO] = useState(targetLOs);
  // it's possible for activeQuestion to get set to undefined initially and then stay undefined, all the following code needs to be fine with that
  const [activeQuestion, setActiveQuestion] = useState<string>(targetQuestionL8yId);

  const topicNames = Array.from(new Set(activeLO.map(({ topicName }) => topicName)));
  const topicCardName = topicNames.join(' | ');

  // get all l8y items except the targetQuestion
  const availableQuestionsL8yIdType = availableQuestions.map(({ question: { l8yId, type, gradingType } }) => ({ l8yId, type, gradingType }));
  const l8yArrayWithoutActiveQ = availableQuestionsL8yIdType.filter(({ l8yId }) => l8yId !== activeQuestion);
  // compose l8yArray with the activeQuestion as the first item in l8y's list
  const l8yArray = [...new Set([availableQuestionsL8yIdType.find(({ l8yId }) => l8yId === activeQuestion) as L8yBasicItem, ...l8yArrayWithoutActiveQ].filter(Boolean))];

  const questionDataForL8y = enrichQuestionDataForSPATL8y(l8yArray, availableQuestions);

  const {
    clarityHash: initClarityHash,
    correctHash: initCorrectHash,
    pointsHash: initPointsHash,
    recapHash: initRecapHash,
  } = getStudyPathHashesFromSaqa(availableQuestions);

  const handleL8yEvents = async ({ type, data }: { type: string; data: L8yContainerEventData }) => {
    console.debug(`LearnosityContainer event:: ${type}`, data);
    switch (type) {
      case L8yContainerEvents.ITEM_CHANGED: {
        const { activeL8yRef } = data;
        setActiveQuestion(activeL8yRef);
        const updatedLO = learningObjectives.filter((lo: { assessmentQuestions: Array<{ question: { l8yId: string }}> }) => lo.assessmentQuestions.map(({ question }) => question.l8yId).includes(activeL8yRef));
        setActiveLO(updatedLO);
        break;
      }
      case L8yContainerEvents.QUESTIONS_LOADED: {
        const { questionIds } = data;
        console.debug(L8yContainerEvents.QUESTIONS_LOADED, questionIds);
        break;
      }
    }
  };

  const handleValidated = async (data: L8yContainerValidated) => {
    const { assessmentQuestionId, attemptData, score, isCorrect, clarity, rawMaxScore } = data;
    const { studentAssessmentId } = questionDataForL8y.find((q) => q.assessmentQuestionId === assessmentQuestionId) as QuestionDatumForSPATL8y;
    const studentAttemptResponse = await apiNext.createStudentAttempt({
      assessmentQuestionId,
      attemptData,
      clarity,
      isCorrect,
      location,
      rawMaxScore,
      rawPointsEarned: score,
      studentAssessmentId,
    });
    return handleStudentAttemptResponse({
      assessmentQuestionId,
      location,
      studentAssessmentId,
      studentAttemptResponse,
      triggerConfirmationPrompt,
    });
  };

  const handleLOChange = (targetLONumber: string, currentActiveLOArray: Array<string>, l8yId: string) => {
    const targetLONumberArray = targetLONumber.split(' | ');
    if (targetLONumberArray !== currentActiveLOArray) {
      const activeLOData = learningObjectives.filter((lo: { assessmentQuestions: Array<{ question: { l8yId: string }}> }) => lo.assessmentQuestions.map(({ question }) => question.l8yId).includes(l8yId));
      setActiveLO(activeLOData);
    }
  };

  const handleQuestionNavItemChange = (l8yId: string, loNumber: string, handleItemNav: (l8yId: string) => void) => {
    const activeLOArray: Array<string> = activeLO.map(lo => lo.loNumber);
    handleLOChange(loNumber, activeLOArray, l8yId);
    setActiveQuestion(l8yId);
    handleItemNav(l8yId);
  };

  const todoQuestionL8yIds = todoQuestions.map(({ question: { l8yId } }) => l8yId);

  return (
    <div className="study-path-assessment-taker__wrap">
      <div className="study-path-assessment-taker__content">
        <div className="assessment-wrap study-path-assessment-taker__assessment-wrap">
          <div className="study-path-assessment-taker__container">
            <div className="assessment-taker__header">
              <div className="assessment-taker__header__topic-title row">
                <h1>{topicCardName}</h1>
              </div>
              {
                activeLO.map((lo) => (
                  <div className="assessment-taker__header__lo-item" key={`activeLO_${lo.id}`}>
                    <span className="assessment-taker__header__lo-number">
                      {lo.loNumber.replace('LO', 'Learning Objective')}
                    </span>
                    : {lo.title}
                  </div>
                ))
              }
            </div>
            <LearnosityContainer
              activityId="study-path-AT"
              assessmentMode={AssessmentModeEnum.SubmitPractice}
              assessmentType={null}
              attemptLimit={null}
              handleEvents={handleL8yEvents}
              handleFinished={() => handleDone(false)}
              handleValidated={handleValidated}
              initAttemptsHash={{}}
              initClarityHash={initClarityHash}
              initCorrectHash={initCorrectHash}
              initPointsHash={initPointsHash}
              initRecapHash={initRecapHash}
              initState={L8ySessionState.review}
              inReviewMode={false}
              items={l8yArray}
              l8yBoxClassName="col-xs-12 col-sm-9"
              l8ySessionId={l8ySessionId}
              location={location}
              name="study-path-practice-test"
              questionData={questionDataForL8y}
              renderingType={RenderingTypeEnum.Assess}
              renderItemNav={(activeL8yRef: string, handleItemNav: (l8yId: string) => void, questionStatusHash: QuestionStatusHash) => (
                <StudyPathPracticeTestAssessmentTakerNavMenu
                  questionStatusHash={questionStatusHash}
                  handleQuestionNav={(l8yId: string, loNumber: string) => handleQuestionNavItemChange(l8yId, loNumber, handleItemNav)}
                  activeQuestion={activeL8yRef}
                  correctQuestions={pastQuestions}
                  incorrectQuestions={todoQuestions}
                />
              )}
              targetL8yId={activeQuestion}
              todoQuestionL8yIds={todoQuestionL8yIds}
              userId={userId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

StudyPathPracticeTestAssessmentTaker.propTypes = {
  handleDone: PropTypes.func.isRequired,
  l8ySessionId: PropTypes.string.isRequired,
  learningObjectives: PropTypes.array.isRequired,
  location: PropTypes.oneOf(Object.values(AssessmentLocation) as Array<AssessmentLocation>).isRequired,
  userId: PropTypes.string.isRequired,
};

export default StudyPathPracticeTestAssessmentTaker;
