import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { AssessmentApiBase, AssessTypeEnum } from 'types/backend/assessments.types';
import { QuestionApiOut } from 'types/backend/questions.types';
import { GradingTypeTag } from 'types/backend/l8y.types';

export interface BasicQuestionForPreview {
  assessmentIds: Array<string>
  questionId: number
  blooms: number
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
  l8yId: string
  title: string
  type: LibraryTypeEnum
  userId: string
  gradingType: GradingTypeTag
}

export interface AssessmentQuestionMetadata extends BasicQuestionForPreview {
  allowAdd: boolean
  allowRemove: boolean
  isAssigned: boolean
}

export interface QuestionPreviewLaunchWithMetadata {
  initialQuestionId: number
  questions: Array<AssessmentQuestionMetadata>
  activeAssessmentId?: string
}

// accepts a basic QuestionApiOut (or ActiveCombinedQuestion) and formats it with the minimum needed for q preview
export const formatBasicQuestionForPreview = (question: QuestionApiOut & {
  courseLearningObjectives?: Array<EnrichedCourseLearningObjective>
  assessmentIds?: Array<string>
}): BasicQuestionForPreview => {
  const {
    assessmentIds = [],
    blooms,
    courseLearningObjectives = [],
    id: questionId,
    l8yId,
    title,
    type,
    userId,
    gradingType,
  } = question;
  return {
    assessmentIds,
    blooms,
    courseLearningObjectives,
    gradingType,
    questionId,
    l8yId,
    title,
    type,
    userId,
  };
};

export const getSingleAssessmentQuestionMetadata = (
  question: QuestionApiOut,
  currentAssessment: AssessmentApiBase | null,
  hasBeenStarted: boolean,
  startedQuestionIds: Array<number>
): AssessmentQuestionMetadata => {
  const basicQuestion = formatBasicQuestionForPreview(question);
  const { assessmentIds, questionId } = basicQuestion;

  const isAssignedToCurrentAssessment = !!currentAssessment?.id && assessmentIds.includes(currentAssessment?.id);
  const notAssignedElsewhere = !assessmentIds.length;

  const allowRemove = isAssignedToCurrentAssessment && (!hasBeenStarted || (hasBeenStarted && !startedQuestionIds.includes(questionId)));
  const allowAdd = notAssignedElsewhere && (!hasBeenStarted || (hasBeenStarted && currentAssessment?.assessType === AssessTypeEnum.Prep));

  return {
    ...basicQuestion,
    allowRemove,
    allowAdd,
    isAssigned: isAssignedToCurrentAssessment,
  };
};

// formerly buildPreviewQuestionData
const getAssessmentQuestionsMetadata = (
  questions: Array<QuestionApiOut>,
  currentAssessment: AssessmentApiBase | null,
  hasBeenStarted: boolean,
  startedQuestionIds: Array<number>
): Array<AssessmentQuestionMetadata> => {
  return questions.map((question) => {
    return getSingleAssessmentQuestionMetadata(question, currentAssessment, hasBeenStarted, startedQuestionIds);
  });
};

export default getAssessmentQuestionsMetadata;
