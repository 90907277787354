import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch } from 'types/store.types';

export default function copyQuestion(questionId: number, userId: string) {
  return (dispatch: AppDispatch) => (async () => {
    const copiedQuestion = await apiNext.createCopyQuestion({ questionId, userId });
    dispatch(activeSlice.actions.addActiveUserQuestion(copiedQuestion));
    return copiedQuestion;
  })();
}
