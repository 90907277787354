import React from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './ClassSessionNav.scss';

interface ClassSessionNavProps {
  onNext: () => void
  onPrev: () => void
  nextDisabled: boolean
  prevDisabled: boolean
  classNumber: number
  nonClassDay: boolean
}

const ClassSessionNav = ({
  onPrev,
  onNext,
  prevDisabled,
  nextDisabled,
  classNumber,
  nonClassDay,
}: ClassSessionNavProps) => {
  return (
    <nav aria-label="Navigate between class sessions" className="class-session-nav">
      <button aria-label="Navigate to previous class session" className="class-session-nav__prev" onClick={onPrev} disabled={prevDisabled}>
        <FaChevronLeft size={22} />
      </button>
      <div className="class-number-display">{nonClassDay ? 'No Class' : `Class ${classNumber}`}</div>
      <button aria-label="Navigate to next class session" className="class-session-nav__next" onClick={onNext} disabled={nextDisabled}>
        <FaChevronRight size={22} />
      </button>
    </nav>
  );
};

ClassSessionNav.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  nextDisabled: PropTypes.bool.isRequired,
  prevDisabled: PropTypes.bool.isRequired,
  classNumber: PropTypes.number.isRequired,
  nonClassDay: PropTypes.bool.isRequired,
};

export default ClassSessionNav;
