import apiNext from 'api-next';
import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import activeSlice from 'store/slices/active';
import { GradingPolicyEnum } from 'types/backend/assessments.types';
import { AppDispatch, Store } from 'types/store.types';

export default function addMultipleQuestionsToAssessment(questionIds: Array<number>, assessmentId: string, gradingPolicy: GradingPolicyEnum, points: number = 1) {
  console.debug('addMultipleQuestionsToAssessment questionId, assessmentId, gradingPolicy', questionIds, assessmentId, gradingPolicy);
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active } = getStore();
    const assessmentQuestionMaps = retrieveActiveAssessmentQuestionMaps(getStore());

    const hasAlready = assessmentQuestionMaps.find(aqm => questionIds.includes(aqm.questionId) && aqm.assessmentId === assessmentId);
    if (hasAlready) {
      throw new Error('This question has already been added to this assessment');
    }

    const { templateQuestions, userQuestions } = active;

    if (![...userQuestions, ...templateQuestions].find(tq => questionIds.includes(tq.id))) {
      throw new Error('Invalid question id passed to addMultipleQuestionsToAssessment');
    }
    const createdAqms = await apiNext.createAssessmentQuestionMaps({
      questionIds,
      assessmentId,
    });
    console.debug('createdAqms createAssessmentQuestionMap', createdAqms, assessmentQuestionMaps);

    return dispatch(activeSlice.actions.addActiveAssessmentQuestionMaps(createdAqms));
  })();
}
