/**
 * getSortedUniqueLOsForClassSessions
 * Sorts an array of classSessions by date, ascending
 * which is the we want it to be, especially for putting in state
 *
 * @name sortClassSessions
 * @function
 * @param {classSession[] (from api usually)} classSessionsArray
 */
import unionBy from 'lodash-es/unionBy';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';

// this simplified interface is for backwards compatibility between BetterClassSession and EnrichedActiveClassSession
interface SimpleClassSessionWithCourseLos {
  id: number
  _derived?: {
    courseLearningObjectives: Array<EnrichedCourseLearningObjective>
  }
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
}

// uses sorted classSessions and sorted courseLearningObjectives to get a list of the sorted unique LOs for the selected class days
// used for "Class Days Covered" in assessment builder and for getting the full list of LOs for the LO Chart
export default function getSortedUniqueLOsForClassSessions(selectedClassSessionIds: Array<number>, classSessions: Array<SimpleClassSessionWithCourseLos>) {
  if (!selectedClassSessionIds) {
    return [];
  }
  const cloList = selectedClassSessionIds.reduce((reduced, selectedCSId) => {
    const session = classSessions.find(cs => cs.id === selectedCSId);
    if (session) {
      const { _derived } = session;
      const clo = !!_derived ? _derived.courseLearningObjectives : session.courseLearningObjectives;
      return unionBy(reduced, clo, 'id');
    }
    return reduced;
  }, [] as Array<EnrichedCourseLearningObjective>);

  return cloList;
}
