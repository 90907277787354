import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import isStudentRole from 'utils/isStudentRole';
import { isAdminRole } from 'utils';
import InstructorBase from './instructor/InstructorBase';
import StudentBase from './student/StudentBase';
import AdminBase from './admin/Base';
import DevBase from './dev'; // space for prototyping components, only available in local dev
import PasswordResetRequest from 'shared-components/Login/PasswordResetRequest';
import PasswordResetAction from 'shared-components/Login/PasswordResetAction';
import UserAgreement from 'shared-components/Login/UserAgreement';
import LtiLaunch from './ltiLaunch';
import Logout from './Logout';

import { useAppSelector } from 'store';
import { UserApi } from 'types/backend/users.types';
import { RoleEnum } from 'types/backend/roles.types';
import { EnrollmentApi } from 'types/backend/enrollments.types';

function AppRoutes({ user }: { user: UserApi }) {
  const enrollments = useAppSelector((store) => store.enrollments);
  const instructorStudentViewCourseId = useAppSelector((store) => store.state.instructorStudentViewCourseId);
  const history = useHistory();
  const { pathname, search } = useLocation();

  const url = `${pathname}${search}`;
  const courseIdRegex = /course\/([a-fA-F0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/i;
  const [, courseIdFromUrl] = url.match(courseIdRegex) || [];

  /***
   * This effect sets up some important initial path information
   * It handles if LTI is in the path for a launch? Right Malik?
   * It also sets the proper path for students and instructors
   * as well as doing some routing for instructors if they are trying to get the student view
   */
  useEffect(() => {
    const ogPathname = `${pathname}${search}`;
    let newPathname = ogPathname;
    // if page still on login but user already authenticated, redirect to root for next route logic
    if (pathname === '/' || newPathname.includes('login')) {
      // default to student
      newPathname = '/student';
    }
    const masqueradeUserId = window.localStorage.getItem('masqueradeUserId');
    if (isAdminRole(user) && !masqueradeUserId) {
      newPathname = newPathname.replace('student', 'admin').replace('instructor', 'admin');
    } else {
      let currentEnrollment;
      if (enrollments && !!courseIdFromUrl) {
        // check in case if instructor enrolled as student
        if (ogPathname.includes('student')) {
          currentEnrollment = enrollments.find(enrollment => enrollment.courseId === courseIdFromUrl && enrollment.roleId === RoleEnum.Student) as EnrollmentApi;
        } else {
          currentEnrollment = enrollments.find(enrollment => enrollment.courseId === courseIdFromUrl && enrollment.roleId === RoleEnum.Instructor) as EnrollmentApi;
        }
      }
      if (isStudentRole(user, currentEnrollment) || instructorStudentViewCourseId) {
        newPathname = newPathname.replace('instructor', 'student').replace('admin', 'student');
      } else if (!!user) {
        newPathname = newPathname.replace('student', 'instructor').replace('admin', 'instructor');
      }
    }
    // Because this runs on every route change, don't bang history.push with identical strings
    if (newPathname !== ogPathname) {
      console.debug('::AppRoutes replace pathname:', ogPathname, newPathname);
      history.push(newPathname);
    }
  }, [courseIdFromUrl, user, instructorStudentViewCourseId, history, pathname, search, enrollments]);

  return (
    <Switch>
      <Route path="/student"><StudentBase /></Route>
      <Route path="/instructor"><InstructorBase /></Route>
      <Route path="/admin"><AdminBase /></Route>
      <Route path="/lti-launch"><LtiLaunch /></Route>
      <Route path="/dev"><DevBase /></Route>
      <Route path="/logout"><Logout /></Route>
      <Route path="/password-reset/request"><PasswordResetRequest /></Route>
      <Route path="/auth-manager/reset"><PasswordResetAction /></Route>
      <Route path="/user-agreement"><UserAgreement /></Route>
    </Switch>
  );
}

export default AppRoutes;
