import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaChevronRight } from 'react-icons/fa';
import { getLosToShowFromArrayOfActiveCombinedQuestions } from 'utils/getLoDataFromCombinedQuestion';
import AddRemoveButton from 'shared-components/AddRemoveButton/AddRemoveButton';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { QuestionAction, QuestionActionEnum } from '../../AssessmentBuilderController.types';
import { AddRemoveEnum, PositionEnum } from 'types/common.types';
import { ActiveCombinedQuestion } from 'store/selectors/retrieveActiveCombinedQuestions';
import { AssessmentQuestionMetadata } from 'utils/getAssessmentQuestionsMetadata';

interface QuestionListItemGroupProps {
  children: Array<JSX.Element>
  currentAssessmentId: string
  groupTitle: string
  groupQuestions: Array<ActiveCombinedQuestion>
  isProcessing: boolean
  assessmentQuestionMetadata: Array<AssessmentQuestionMetadata>
  questionAction: QuestionAction
}

const QuestionListItemGroup = ({
  children,
  currentAssessmentId,
  groupTitle,
  groupQuestions,
  isProcessing,
  assessmentQuestionMetadata,
  questionAction,
}: QuestionListItemGroupProps) => {
  const [expanded, setExpanded] = useState(true);
  const allLosForGroup = getLosToShowFromArrayOfActiveCombinedQuestions(groupQuestions);
  const allQuestionsAreAddable = groupQuestions.every((gq) => assessmentQuestionMetadata.find(({ questionId }) => questionId === gq.id)?.allowAdd);
  const allQuestionsAreRemovable = groupQuestions.every((gq) => assessmentQuestionMetadata.find(({ questionId }) => questionId === gq.id)?.allowRemove);
  const showGroupAddRemove = allQuestionsAreAddable || allQuestionsAreRemovable;
  const buttonVerb = allQuestionsAreAddable ? AddRemoveEnum.Add : AddRemoveEnum.Remove;
  const allQuestionIds = groupQuestions.map(({ id }) => id);
  return (
    <>
      <tr className="question-list__item-row" data-groupexpanded={expanded}>
        <td className="question-list-group__group-title">
          <button onClick={() => setExpanded(!expanded)}>
            <FaChevronRight />
            {groupTitle}
          </button>
        </td>
        <td className="question-list__item-lo-list">
          <div className="question-list__lo-items">
            {allLosForGroup.map(({ id, lo, title, topicName }) => (
              <div key={id} className="question-list__lo-tooltip">
                <BetterTooltip content={<><b>{topicName}</b><p>{title}</p></>} position={PositionEnum.Right}>
                  <div className="question-list__lo-string">{lo}</div>
                </BetterTooltip>
              </div>
            ))}
          </div>
        </td>
        <td></td>
        <td className="question-list__info-action question-list__group-action">
          {showGroupAddRemove && (
            <AddRemoveButton
              allowAdd={allQuestionsAreAddable}
              allowRemove={allQuestionsAreRemovable}
              handleAdd={() => questionAction(QuestionActionEnum.AddQuestionGroup, { assessmentId: currentAssessmentId, questionId: -1, questionIds: allQuestionIds })}
              handleRemove={() => questionAction(QuestionActionEnum.RemoveQuestionGroup, { assessmentId: currentAssessmentId, questionId: -1, questionIds: allQuestionIds })}
              isProcessing={isProcessing}
              verb={buttonVerb}
            />
          )}
        </td>
      </tr>
      {expanded && <>{children}</>}
    </>
  );
};

QuestionListItemGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default QuestionListItemGroup;
