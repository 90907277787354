// types from https://github.com/codonlearning/backend/blob/dev/src/types/shared.types.ts
import { AssessTypeEnum } from 'types/backend/assessments.types';
// re-export for convenience
export { ClarityEnum } from 'types/backend/studentAssessmentQuestionAttempts.types';

export enum LibraryTypeEnum {
  Template = 'template',
  User = 'user'
}

export enum QuestionUseEnum {
  Preclass = 'preclass',
  Postclass = 'postclass',
  Readiness = 'readiness'
}

export enum YesNo {
  Yes = 'yes',
  No = 'no'
}

export enum CheckpointColumn {
  Hidden = 'hidden',
  Review = 'review',
  Prep = 'prep',
  Test = 'test'
}

export enum AssessmentLocation {
  AT = 'AT',
  SP = 'SP',
  SPPTAT = 'SPPTAT',
  Preview = 'preview',
  REX = 'REX',
}

// HookContextOrVoid Omitted

export type FieldMapEntry = {
  camelName: string
  snakeName: string
}

export type GenericObject = {
  [key: string]: any
}

export type StringValueObject = {
  [key: string]: string
}

export type ArrayStringValueObject = {
  [key: string]: Array<string>
}

export enum FeathersSort {
  Asc = 1,
  Desc = -1
}

export enum OrderByEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export enum ResultsFormatEnum {
  Csv = 'csv',
  Json = 'json'
}

export type KnexOrderByEntry = {
  column: string
  order: OrderByEnum
}
// PaginatedView Omitted

export interface QueryWhereOrder {
  where: GenericObject
  orderBy: Array<KnexOrderByEntry>
  select?: Array<string>
  whereIn?: GenericObject
  whereNull?: string
  whereOr?: Array<GenericObject>
}

export interface TopicCardAssessmentQuestionForTopicCardAndEnrollment {
  assess_type: AssessTypeEnum
  is_correct: YesNo | null
  is_after_due: boolean
  unanswered_question_count: number | null
  assessment__id: string
  assessment_question__id: number
}

export enum ContextMethod {
  Find = 'find',
  Get = 'get',
  Create = 'create',
  Update = 'update',
  Patch = 'patch',
  Remove = 'remove'
}

export interface ClassSessionContentApiBase {
  id: number
  classSessionId: number
  order: number
  createdAt: string
  updatedAt: string
}

export type ClassSessionContentApiBaseCreate = Pick<ClassSessionContentApiBase, 'classSessionId' | 'order'>

export interface ClassSessionContentApi extends Omit<ClassSessionContentApiBase, 'order'> {
  order?: number
  previousId?: number
  courseId?: string
}

export interface ClassSessionContentDb {
  id: number
  class_session__id: number
  order: number
  created_at: string
  updated_at: string
  course__id?: string
}
