import React from 'react';
import { useSelector } from 'react-redux';

import { getMostRecentActiveSummative } from 'utils/getMostRecentActiveSummative';
import useClassSessionQuery from 'hooks/useClassSessionQuery';
import useDocumentTitle from 'hooks/useDocumentTitle';
import retrieveBetterClassSessions from 'store/selectors/retrieveBetterClassSessions';
import retrieveAssessmentsWithEnrollment from 'store/selectors/retrieveAssessmentsWithEnrollment';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import StudentClassSessionView from 'student/components/StudentClassSessionView/StudentClassSessionView';
import NextSummative from 'student/components/NextSummative/NextSummative';
import BetterTimeline from 'shared-components/BetterTimeline/BetterTimeline';
import StudentAssessmentPill from 'shared-components/BetterTimeline/StudentAssessmentPill';
import { Store } from 'types/store.types';

function CourseHomeController() {
  const classSessions = useSelector(retrieveBetterClassSessions);
  const [selectedClassSessionId, setSelectedClassSessionId] = useClassSessionQuery(classSessions);
  const currentActiveClassSession = classSessions.find(({ id }) => id === selectedClassSessionId);
  const { id: classSessionId, weekNumber = 1 } = currentActiveClassSession || {};

  const enrichedAssessmentsForStudent = useSelector(retrieveAssessmentsWithEnrollment);
  const summativeAssessmentSupplements = useSelector((store: Store) => store.active.summativeAssessmentSupplements);
  const course = useSelector((store: Store) => store.active.course);
  const activeSummative = getMostRecentActiveSummative(enrichedAssessmentsForStudent, summativeAssessmentSupplements);
  useDocumentTitle(`${course.name} - Home`);

  if (!currentActiveClassSession || !classSessionId) {
    return <LoadingSpinner />;
  }
  return (
    <div className="course-home-controller">
      <div className="student-course-view">
        <BetterTimeline
          currentClassSessionId={classSessionId}
          setClassSessionId={setSelectedClassSessionId}
          renderAssessmentPill={(assessmentPillData) => (
            <StudentAssessmentPill {...assessmentPillData} />
          )}
        />
        <main className="student-course-view__grid row">
          <div className="col gutter">
            <StudentClassSessionView
              currentWeekNumber={weekNumber}
              selectedClassSession={currentActiveClassSession}
            />
          </div>
          {!!activeSummative && (
            <div className="col-xs-12 col-sm-5 col-lg-4 gutter">
              <NextSummative activeSummative={activeSummative} />
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default CourseHomeController;
