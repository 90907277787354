/* eslint-disable jsx-a11y/no-autofocus */

import React, { useMemo, useRef, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';
import { DateTime } from 'luxon';

import { formatPlural } from 'utils/commonFormattingFunctions';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { EnrichedQuestionGroup } from 'store/selectors/retrieveEnrichedQuestionGroups';
import retrieveSortedActiveCombinedQuestions from 'store/selectors/retrieveSortedActiveCombinedQuestions';
import retrieveBetterClassSessions from 'store/selectors/retrieveBetterClassSessions';
import { useAppSelector } from 'store';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import ConfirmationPromptContainer from 'shared-components/ConfirmationPrompt/ConfirmationPromptContainer';
import TextButton from 'shared-components/BetterButton/TextButton';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { ConfirmationTypeEnum, PositionEnum } from 'types/common.types';
import { OnChangeEvent } from 'instructor/components/Dropdown/Dropdown.types';
import { QuestionActionEnum, QuestionActionPayload } from '../../AssessmentBuilderController.types';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import { ActiveCombinedQuestion } from 'store/selectors/retrieveActiveCombinedQuestions';
import { LibraryTypeEnum, YesNo } from 'types/backend/shared.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';
import './AddUnalignedQuestionsConfirmation.scss';


interface QuestionPillData {
  id: number
  itemNumber: string
  title: string
  loIds: Array<number>
}

export enum UnalignedActionsEnum {
  AddSingleQuestion = 'add-single-question',
  AddQuestionGroup = 'add-question-group',
  AddLosOnly = 'add-los-only',
}

export interface UnalignedQuestionsConfirmData {
  assessmentId: string
  questionGroup?: EnrichedQuestionGroup
  selectedQuestionIds: Array<number>
  selectedLoIds: Array<number>
  classSessionId: number | null
  unalignedActionType: UnalignedActionsEnum
}

/*
  This component is used to confirm the alignment of questions or question groups that have LOs that are not yet part of the course.
  If it is used for a single question, the first and only item in the `selectedQuestionIds` array will be the correct questionId.
*/

export default function AddUnalignedQuestionsConfirmationPrompt({
  unalignedQuestionsConfirmData,
  setUnalignedQuestionsConfirmData,
  handleCancel,
  handleQuestionAction,
}: {
  unalignedQuestionsConfirmData: UnalignedQuestionsConfirmData
  setUnalignedQuestionsConfirmData: (updatedConfirmData: UnalignedQuestionsConfirmData) => void
  handleCancel: () => void
  handleQuestionAction: (action: QuestionActionEnum, payload: QuestionActionPayload) => Promise<void>
}) {
  // Redux state acquisition
  const topics = useAppSelector((store) => store.active.topics);
  const learningObjectives = useAppSelector((store) => store.active.learningObjectives);
  const userId = useAppSelector((store) => store.user.id);
  const classSessions = useAppSelector(retrieveBetterClassSessions);
  const combinedQuestions = useAppSelector(retrieveSortedActiveCombinedQuestions);

  const {
    assessmentId,
    classSessionId,
    questionGroup,
    selectedLoIds,
    selectedQuestionIds,
    unalignedActionType,
  } = unalignedQuestionsConfirmData;

  const { groupQuestions = [], groupTitle = '' } = questionGroup || {};

  const losForQuestionsRef = useRef<Array<LearningObjectiveApi>>([]);
  const [primaryButtonDisabled, setPrimaryButtonDisabled] = useState(false);

  const selectedQuestionId = unalignedActionType !== UnalignedActionsEnum.AddQuestionGroup ? selectedQuestionIds[0] : null;

  const questionPills: Array<QuestionPillData> = useMemo(() => {
    if ([UnalignedActionsEnum.AddSingleQuestion, UnalignedActionsEnum.AddLosOnly].includes(unalignedActionType)) {
      const question = combinedQuestions.find(q => q.id === selectedQuestionIds[0]) as ActiveCombinedQuestion;
      losForQuestionsRef.current = learningObjectives.filter(lo => question.learningObjectiveIds.includes(lo.id) && lo.isCompetency === YesNo.No);
      return [];
    }
    // the rest of this handles UnalignedActionsEnum.AddQuestionGroup
    const selectedGroupQuestions: Array<ActiveCombinedQuestion> = groupQuestions.filter(gq => selectedQuestionIds.includes(gq.id)); // this step ensures the order of the questions is preserved, and provides additional question data

    return selectedGroupQuestions.reduce((acc, groupQuestion, index) => {
      const hasNonCompetencyLos = !!learningObjectives.some(lo => groupQuestion.learningObjectiveIds.includes(lo.id) && lo.isCompetency === YesNo.No);
      if (!groupQuestion.courseLearningObjectives.length && hasNonCompetencyLos) {
        // only show the pill if the question has non-competency LOs and is not already aligned to the course

        // get the possible non-competency LOs for this question
        const newLos = learningObjectives.filter(lo => groupQuestion.learningObjectiveIds.includes(lo.id) && lo.isCompetency === YesNo.No && !losForQuestionsRef.current.includes(lo));
        losForQuestionsRef.current = [...losForQuestionsRef.current, ...newLos];

        // create the pill data
        const pillData = {
          id: groupQuestion.id,
          itemNumber: `${index + 1}`,
          title: groupQuestion.title,
          loIds: groupQuestion.learningObjectiveIds,
        };
        acc.push(pillData);
      }
      return acc;
    }, [] as Array<QuestionPillData>);
  }, [
    combinedQuestions,
    groupQuestions,
    learningObjectives,
    selectedQuestionIds,
    unalignedActionType,
  ]);

  // This sort put templates above users and the current user's custom LOs above coinstructors custom LOs within a topic
  // otherwise LOs are left in their default sort
  losForQuestionsRef.current.sort((a, b) => {
    if ((a.type === LibraryTypeEnum.Template && b.type === LibraryTypeEnum.Template) || a.topicId !== b.topicId) {
      return 0;
    } else if (a.type === LibraryTypeEnum.User && b.type === LibraryTypeEnum.Template) {
      return 1;
    } else if (a.type === LibraryTypeEnum.User && b.type === LibraryTypeEnum.User) {
      if (a.userId !== userId && b.userId === userId) {
        return 1;
      }
      if (a.userId === userId && b.userId !== userId) {
        return -1;
      }
    }
    return 0;
  });

  const questionTopics = topics.filter(t => losForQuestionsRef.current.some(lo => lo.topicId === t.id));

  const classSessionOptions = classSessions.reduce((acc: Array<{ value: number; label: string }>, cs) => {
    if (cs.classType === ClassTypeEnum.Special) {
      return acc;
    }
    const label = `Class ${cs.classNumber} (${DateTime.fromISO(cs.classDate).toFormat(DateFormatEnum.WeekdayMonthDate)})`;
    return [
      ...acc,
      { value: cs.id, label },
    ];
  }, []);
  const selectedValue = classSessionOptions.find((opt) => opt.value === classSessionId);

  const handleLoClick = (loId: number) => {
    let newSelectedLoIds = [...selectedLoIds];
    if (selectedLoIds.includes(loId)) {
      newSelectedLoIds = newSelectedLoIds.filter(id => id !== loId);
    } else {
      newSelectedLoIds = [...selectedLoIds, loId];
    }
    setUnalignedQuestionsConfirmData({ ...unalignedQuestionsConfirmData, selectedLoIds: newSelectedLoIds });
    setPrimaryButtonDisabled(!newSelectedLoIds.length);
  };

  const handleSelectedSessionChange = (option: OnChangeEvent<number>) => {
    !!option && setUnalignedQuestionsConfirmData({ ...unalignedQuestionsConfirmData, classSessionId: option.value });
  };

  // using custom styles here to limit the height of the selectable items to prevent any hidden scrollbar issues.
  const customStyles: StylesConfig<{ value: number; label: string }, false> = {
    menu: (provided) => ({
      ...provided,
      maxHeight: 'unset',
      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      overflowY: 'auto',
      maxHeight: '200px',
    }),
  };

  const loString = losForQuestionsRef.current.length > 1 ? 'LOs that are' : 'an LO that is';
  const isPillCovered = (pill: QuestionPillData) => {
    return pill.loIds.some(coveredLoId => selectedLoIds.includes(coveredLoId));
  };

  const getPillContent = (pill: QuestionPillData) => {
    if (isPillCovered(pill)) {
      return `${pill.title} covers ${pill.loIds.length > 1 ? 'LOs' : 'an LO'}  not yet in your course but that are selected below.`;
    } else {
      return `${pill.title} won't be aligned based on your current LO selections.`;
    }
  };

  const getCoveredByText = (loId: number) => {
    const coveredItems = questionPills.filter(pill => pill.loIds.includes(loId));
    const coveredItemNumbers = coveredItems.map(ci => ci.itemNumber);
    switch (coveredItems.length) {
      case 0:
        return '';
      case 1:
        const [{ itemNumber }] = coveredItems;
        return `Covered in item ${itemNumber}`;
      case 2:
        return `Covered in items ${coveredItemNumbers.join(' and ')}`;
      default:
        return `Covered in items ${coveredItemNumbers.join(', ')}`;
    }
  };

  const renderConfirmButtons = () => {
    const pluralLosString = formatPlural('LO', selectedLoIds.length);
    if (selectedQuestionId) {
      if (unalignedActionType === UnalignedActionsEnum.AddLosOnly) {
        return (
          <BetterButton
            className="confirmation-button"
            primary
            data-dismiss="confirmation-modal"
            onClick={() => handleQuestionAction(QuestionActionEnum.AddCslosAfterConfirmation, { assessmentId, questionId: selectedQuestionId })}
            text={`Add ${pluralLosString}`}
            autoFocus
            disabled={primaryButtonDisabled}
          />
        );
      }
      if (unalignedActionType === UnalignedActionsEnum.AddSingleQuestion) {
        return (
          <>
            <BetterButton
              className="confirmation-button"
              primary
              data-dismiss="confirmation-modal"
              onClick={() => handleQuestionAction(QuestionActionEnum.AddQuestionWithLos, { assessmentId, questionId: selectedQuestionId })}
              text={`Add Item and ${pluralLosString}`}
              autoFocus
              disabled={primaryButtonDisabled}
            />
            <BetterButton
              className="secondary-button"
              onClick={() => handleQuestionAction(QuestionActionEnum.AddQuestionWithoutLos, { assessmentId, questionId: selectedQuestionId })}
              secondary
              text={`or Add Item without ${pluralLosString}`}
            />
          </>
        );
      }
    }
    if (unalignedActionType === UnalignedActionsEnum.AddQuestionGroup) {
      return (
        <>
          <BetterButton
            className="confirmation-button"
            primary
            data-dismiss="confirmation-modal"
            data-tri-level-color={questionPills.every(pill => isPillCovered(pill))}
            onClick={() => handleQuestionAction(QuestionActionEnum.AddQuestionGroupAfterConfirmation, { assessmentId, questionId: selectedQuestionIds[0], questionIds: [] })}
            text={`Add all items and ${selectedLoIds.length} ${pluralLosString}`}
            autoFocus
            disabled={primaryButtonDisabled}
          />
          <BetterButton
            className="secondary-button"
            onClick={() => handleQuestionAction(QuestionActionEnum.AddQuestionGroupAfterConfirmationWithoutLos, { assessmentId, questionId: selectedQuestionIds[0], questionIds: [] })}
            secondary
            text="or Add Items without LOs"
          />
        </>
      );
    }
    return null;
  };

  return (
    <ConfirmationPromptContainer
      confirmationType={ConfirmationTypeEnum.Warn}
      title="Alignment Check"
      handleCancel={handleCancel}
    >
      <div className="add-unaligned-questions-confirmation">
        {unalignedActionType === UnalignedActionsEnum.AddQuestionGroup ? (
          <>
            One or more of the LOs in this Readiness Reading "{groupTitle}" cover LOs that are not part of your course. These items will not be visible to students in the Study Path unless you add LO(s) they cover to a class day:
            <div className="add-unaligned-questions-confirmation__question-pill-container">
              {!!questionPills.length && questionPills.map(pill => (
                <BetterTooltip key={pill.id} content={getPillContent(pill)} inModal position={PositionEnum.BottomRight}>
                  <span
                    className="add-unaligned-questions-confirmation__question-pill-container__pill"
                    data-uncovered={!isPillCovered(pill)}
                  >
                    Item {pill.itemNumber}
                  </span>
                </BetterTooltip>
              ))}
            </div>
          </>
        ) : (
          <>
            This item covers {loString} not part of your course. The item will not be visible to students in the Study Path unless you add an LO it covers to your course:
          </>
        )}
        <div className="add-unaligned-questions-confirmation__learning-objective-list">
          {questionTopics.map((topic) => losForQuestionsRef.current.map((lo) => lo.topicId !== topic.id ? null : (
            <button className="add-unaligned-questions-confirmation__lo" key={lo.id} onClick={() => handleLoClick(lo.id)}>
              <div className="add-unaligned-questions-confirmation__checkbox">
                {selectedLoIds.includes(lo.id) ? <FaRegCheckSquare /> : <FaRegSquare />}
              </div>
              <div className="add-unaligned-questions-confirmation__text">
                <span className="add-unaligned-questions-confirmation__topic">
                  {topic.name}
                </span>
                <span className="add-unaligned-questions-confirmation__item-description">
                  {lo.title}
                </span>
                {unalignedActionType === UnalignedActionsEnum.AddQuestionGroup && (
                  <div className="add-unaligned-questions-confirmation__item-coverage">
                    <em>{getCoveredByText(lo.id)}</em>
                  </div>
                )}
              </div>
            </button>
          )))}
        </div>
        <div className="add-unaligned-questions-confirmation__class-session">
          <div className="add-unaligned-questions-confirmation__class-session-message">Would you like to add the selected {formatPlural('LO', selectedLoIds.length)} to a class day?</div>
          <div className="add-unaligned-questions-confirmation__class-session-select row middle around-xs">
            <label htmlFor="class-session-select col-xs-3">
              Assign {formatPlural('LO', selectedLoIds.length)} to:
            </label>
            <Select
              name='class-session-select'
              className="col-xs-8"
              isDisabled={!selectedLoIds.length}
              options={classSessionOptions}
              value={selectedValue}
              menuPlacement="top"
              onChange={handleSelectedSessionChange}
              styles={customStyles}
            />
          </div>
        </div>
      </div>
      <div className="confirmation-modal__button-bar">
        {renderConfirmButtons()}
        <TextButton
          className="confirmation-text-button"
          data-dismiss="confirmation-modal"
          onClick={handleCancel}
        >
          or Cancel
        </TextButton>
      </div>
    </ConfirmationPromptContainer>
  );
}

