import './DevUtils.scss';

import React from 'react';
import { useSelector } from 'react-redux';

import retrieveEnrichedClassSessions from 'store/selectors/retrieveEnrichedClassSessions';
import retrieveActiveClassSessions from 'store/selectors/retrieveActiveClassSessions';
import retrieveActiveCourseLearningObjectives from 'store/selectors/retrieveActiveCourseLearningObjectives';
import retrieveEnrichedActiveTopics from 'store/selectors/retrieveEnrichedActiveTopics';
import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import retrieveSortedActiveCombinedQuestions from 'store/selectors/retrieveSortedActiveCombinedQuestions';
import retrieveEnrichedCourseTopics from 'store/selectors/retrieveEnrichedCourseTopics';


export default function DevUtils() {
  const course = useSelector((store) => store.active.course);
  console.debug('DevUtils course', course);

  function renderEnriched(selectorName, selector) {
    console.debug(`Logging ${selectorName}`, selector);
    return (
      <div className="dev-utils__container">
        <div className="dev-utils__header">{selectorName}</div>
        {JSON.stringify(selector, null, 2)}
      </div>
    );

  }

  return (
    <div>
      <div>
        future home of developer utilities
      </div>
      <div>
        {renderEnriched('retrieveEnrichedClassSessions', useSelector(retrieveEnrichedClassSessions))}
      </div>
      <div>
        {renderEnriched('retrieveActiveClassSessions', useSelector(retrieveActiveClassSessions))}
      </div>
      <div>
        {renderEnriched('retrieveActiveCourseLearningObjectives', useSelector(retrieveActiveCourseLearningObjectives))}
      </div>
      <div>
        {renderEnriched('retrieveEnrichedActiveTopics', useSelector(retrieveEnrichedActiveTopics))}
      </div>
      <div>
        {renderEnriched('retrieveActiveAssessmentQuestionMaps', useSelector(retrieveActiveAssessmentQuestionMaps))}
      </div>
      <div>
        {renderEnriched('retrieveSortedActiveCombinedQuestions', useSelector(retrieveSortedActiveCombinedQuestions))}
      </div>
      <div>
        {renderEnriched('retrieveEnrichedCourseTopics', useSelector(retrieveEnrichedCourseTopics))}
      </div>
      <hr />
    </div>
  );
}
