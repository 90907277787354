import { ClarityHash } from 'types/common.types';
import { ClarityEnum } from 'types/backend/shared.types';
import { AssessmentControllerQuestion } from 'utils/getAssessmentControllerQuestions';

const calculateClarity = ({
  clarityHash,
  assessmentControllerQuestions,
}: {
  clarityHash: ClarityHash
  assessmentControllerQuestions: Array<AssessmentControllerQuestion>
}) => {
  // get array of refs for muddy questions
  const muddyQs = Object.keys(clarityHash).filter((l8yRef) => clarityHash[l8yRef] === ClarityEnum.Muddy);
  // get LO data from l8y question ref
  const muddyQuestionData = assessmentControllerQuestions.filter(({ l8yId }) => muddyQs.includes(l8yId));
  // get LO data for muddy LOs
  const muddyLOArray = muddyQuestionData.map(({ loData }) => loData).reduce((acc, val) => acc.concat(val), []);
  return {
    muddyLOArray,
    muddyQuestionCount: muddyQs.length,
  };

};

export default calculateClarity;
