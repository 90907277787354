import React, { useState } from 'react';
import { Store } from 'types/store.types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import apiNext from 'api-next';
import UsersContainer from './UsersContainer';
import { isAdminRole } from 'utils';
import { useAppDispatch } from 'store';
import setMasqueradeUser from 'store/actions/setMasqueradeUser';
import { RoleEnum } from 'types/backend/roles.types';
import { UserApi } from 'types/backend/users.types';

export default function UsersController() {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const user = useSelector((store: Store) => store.user);
  const [users, setUsers] = useState<Array<UserApi>>([]);
  const [moreUsersThanLimit, setMoreUsersThanLimit] = useState(false);

  const launchUserDashboard = (masqueradeUser: UserApi) => {
    dispatch(setMasqueradeUser(masqueradeUser));
    const startRoute = masqueradeUser.roleId === RoleEnum.Student
      ? '/student'
      : '/instructor';
    history.push(startRoute);
  };

  const getUserById = async (userId: string) => {
    const newUsers = [];
    let moreUsers = false;
    if (isAdminRole(user) && userId !== '') {
      const { data, total, limit } = await apiNext.getNonAdminUserById(userId);
      if (!!data) {
        newUsers.push(...data);
      }
      moreUsers = total > limit;
    }
    setMoreUsersThanLimit(moreUsers);
    setUsers(newUsers);
  };

  const getUsersByEmail = async (email: string) => {
    const newUsers = [];
    let moreUsers = false;
    if (isAdminRole(user) && email !== '') {
      const { data, total, limit } = await apiNext.getNonAdminUsersByEmail(email);
      if (!!data) {
        newUsers.push(...data);
      }
      moreUsers = total > limit;
    }
    setMoreUsersThanLimit(moreUsers);
    setUsers(newUsers);
  };

  const getUsersByName = async (firstName: string | null, lastName: string | null) => {
    const newUsers = [];
    let moreUsers = false;
    if (isAdminRole(user) && !!(firstName || lastName)) {
      let searchString = '';
      if (!!firstName) {
        searchString = searchString.concat(`&firstName[$ilike]=${encodeURIComponent(firstName)}`);
      }
      if (!!lastName) {
        searchString = searchString.concat(`&lastName[$ilike]=${encodeURIComponent(lastName)}`);
      }
      const { data, total, limit } = await apiNext.getNonAdminUsersByName(searchString);
      if (!!data) {
        newUsers.push(...data);
      }
      moreUsers = total > limit;
    }
    setMoreUsersThanLimit(moreUsers);
    setUsers(newUsers);
  };

  return (
    <UsersContainer
      users={users}
      moreUsersThanLimit={moreUsersThanLimit}
      getUserById={getUserById}
      getUsersByEmail={getUsersByEmail}
      getUsersByName={getUsersByName}
      launchUserDashboard={launchUserDashboard}
    />
  );
}
