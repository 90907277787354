import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { LibraryTypeEnum, YesNo } from 'types/backend/shared.types';
import { AppDispatch, Store } from 'types/store.types';
import { CreateUserLoApi } from 'instructor/controllers/Course/CourseLoSelectorController/CourseLoSelector.types';

export default function createUserLearningObjective(title: string, topicId: number, parentLearningObjectiveId?: number) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    let newLoPayload: CreateUserLoApi = {
      title,
      details: '',
      blooms: null,
      topicId,
      type: LibraryTypeEnum.User,
      isCompetency: YesNo.No,
    };

    if (!!parentLearningObjectiveId) {
      // copying an existing LO: fill in the rest based on the parent LO
      const { active } = getStore();
      const { learningObjectives } = active;
      const parentLearningObjective = learningObjectives.find(({ id }) => id === parentLearningObjectiveId);
      if (!parentLearningObjective) {
        throw new Error('Cannot save LO, parentLearningObjective not found');
      }
      const { details, blooms, order } = parentLearningObjective;
      newLoPayload = {
        ...newLoPayload,
        details,
        blooms,
        order,
        parentLearningObjectiveId,
      };
    }

    const createdUserLo = await apiNext.createUserLo(newLoPayload);
    dispatch(activeSlice.actions.addActiveLearningObjective(createdUserLo));
    return createdUserLo;
  })();
}
