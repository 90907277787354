import { AssessmentApiBase, AssessTypeEnum } from 'types/backend/assessments.types';

//this functions works because assessments are sorted by dueDate in state and HW in state preceed Summative in state if on same day
function getFirstHomeworkInWindow<A extends AssessmentApiBase>(activeSummativeId: string, studentAssessments: Array<A>): A | undefined {
  // plz add comments explaining how this function works
  const summativeIndex = studentAssessments.findIndex(a => a.id === activeSummativeId);
  let assessmentsBeforeSummative = studentAssessments.slice(0, summativeIndex - 1);
  assessmentsBeforeSummative.reverse();
  const priorSummativeIndex = assessmentsBeforeSummative.findIndex(a => a.assessType === AssessTypeEnum.Summative);
  if (priorSummativeIndex > -1) {
    assessmentsBeforeSummative = assessmentsBeforeSummative.slice(priorSummativeIndex);
  }
  assessmentsBeforeSummative.reverse();

  const firstHomeworkBeforeSummative = assessmentsBeforeSummative.find(a => a.assessType === AssessTypeEnum.Homework);
  if (!firstHomeworkBeforeSummative) {
    console.warn('No homework found before summative');
  }
  return firstHomeworkBeforeSummative;
}

export default getFirstHomeworkInWindow;
