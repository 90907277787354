import React, { useEffect, useState } from 'react';
import {
  FaShare,
  FaSort,
  FaTrashAlt,
  FaUserEdit,
} from 'react-icons/fa';
import { BsCalendar } from 'react-icons/bs';
import { truncate } from 'utils';
import { isLoInOtherClassSessions } from 'utils/topicLoFunctions';
import { useAppDispatch, useAppSelector } from 'store';
import editUserTopic from 'store/actions/editUserTopic';
import reorderClassSessionLearningObjective from 'store/actions/reorderClassSessionLearningObjective';

import CoursePlannerTopicEditor from './CoursePlannerTopicEditor';
import CoursePlannerClassTopicLearningObjective from './CoursePlannerClassTopicLearningObjective';
import ClassRowCardContainer from './ClassRowCardContainer';
import KebabMenu from 'shared-components/KebabMenu/KebabMenu';
import MoveItemButton from './MoveItemButton';
import ReorderButtons from 'instructor/components/ReorderButtons/ReorderButtons';

import {
  BCPViewMode,
  CoursePlannerAction,
  MovingLo,
  MovingTopic,
  SharedBCPProps,
} from '../BetterCoursePlannerController';
import { BetterClassSessionTopic } from 'store/selectors/retrieveBetterClassSessions';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { UpDownTopBottomEnum } from 'types/common.types';
import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';

const CoursePlannerClassTopic = ({
  addingItems, // need to be able to auto expand topics when adding topics/LOs
  cardLabel,
  expanded,
  highlight,
  mode = LibraryTypeEnum.Template,
  movingLo,
  movingTopic,
  onExpandToggle,
  topic,
  ...sharedClassSessionProps
}: SharedBCPProps & {
  addingItems?: boolean
  cardLabel: string | JSX.Element
  expanded: boolean | null
  highlight?: boolean
  mode?: LibraryTypeEnum
  movingLo: MovingLo | null
  movingTopic: MovingTopic | null
  onExpandToggle: () => void
  topic: BetterClassSessionTopic
  viewMode?: BCPViewMode
}) => {
  const {
    classSessionId,
    classNumber,
    viewMode = BCPViewMode.Full,
    handleCoursePlannerAction,
    isProcessing,
  } = sharedClassSessionProps;
  const dispatch = useAppDispatch();
  const classSessionLearningObjectives = useAppSelector(store => store.active.classSessionLearningObjectives);
  const user = useAppSelector(store => store.user);
  const { courseLearningObjectives = [], stringId, type: topicType, userId: topicUserId } = topic;

  // if topic has no associated LOs, it can be deleted without confirmation
  const canQuickDelete = !courseLearningObjectives?.length;

  const isCustomTopic = topicType === LibraryTypeEnum.User && topicUserId === user.id;
  const expandEnabled = viewMode === BCPViewMode.Compact;

  const [editingCustomTopic, setEditingCustomTopic] = useState(false);
  const [topicExpanded, setTopicExpanded] = useState(true);

  const [reorderingLos, setReorderingLos] = useState(false);
  const [reorderProcessing, setReorderProcessing] = useState(false);
  const [lastActionHighlightId, setLastActionHighlightId] = useState<number>(0);

  useEffect(() => {
    // this useEffect expands collapsed topics in a class session when the BetterTopicPicker is open
    // it also means the user cannot collapse topics while adding, so we disable the expando if addingItems
    if (!topicExpanded && addingItems) {
      setTopicExpanded(true);
    }
  }, [topicExpanded, addingItems]);

  useEffect(() => {
    // if parent expand state is null, its value can be ignored
    if (!addingItems && expanded !== null) {
      // but if it is boolean, update inner expanded state
      if (topicExpanded && !expanded) {
        setTopicExpanded(false);
      } else if (!topicExpanded && expanded) {
        setTopicExpanded(true);
      }
    }
  }, [addingItems, topicExpanded, expanded]);

  const handleDoneReordering = () => {
    setReorderingLos(false);
    setLastActionHighlightId(0);
  };

  const handleSave = async (updatedTopicName: string) => {
    await dispatch(editUserTopic({ ...topic, name: updatedTopicName })).then(() => {
      setEditingCustomTopic(false);
    });
    return true;
  };

  const handleTopicExpand = (newVal: boolean) => {
    // reset parent expandAllTopics when user starts toggling
    onExpandToggle();
    setTopicExpanded(newVal);
  };


  const handleReorderLo = async (direction: UpDownTopBottomEnum, movingCsloId: number) => {
    // prevent duplicate inputs while processing
    if (reorderProcessing) {
      return;
    }
    setReorderProcessing(true);
    if (movingCsloId !== lastActionHighlightId) {
      setLastActionHighlightId(0);
    }
    const csloToMove = classSessionLearningObjectives.find((fullCslo) => fullCslo.id === movingCsloId && fullCslo.classSessionId === classSessionId) as ClassSessionLearningObjectiveApi;
    if (!csloToMove) {
      console.error(`cslo not found for csloId: ${movingCsloId}, classSessionId: ${classSessionId}`);
      return;
    }
    const movingLoIndex = courseLearningObjectives.findIndex((clo) => clo.csloId === movingCsloId);
    if (movingLoIndex === -1) {
      console.error(`csloId ${movingCsloId} not found`);
      return;
    }
    let newPreviousId = 0;
    switch (direction) {
      case UpDownTopBottomEnum.Top: {
        newPreviousId = 0;
        break;
      }
      case UpDownTopBottomEnum.Up: {
        // get the LO two spots up from the moving LO
        const upwardsLo = courseLearningObjectives[movingLoIndex - 2];
        // if moving up and moving LO is the second from the top, set previousId to 0
        if (!upwardsLo) {
          newPreviousId = 0;
        } else {
          newPreviousId = upwardsLo.csloId;
        }
        break;
      }
      case UpDownTopBottomEnum.Down: {
        const downwardsLo = courseLearningObjectives[movingLoIndex + 1];
        newPreviousId = downwardsLo.csloId;
        break;
      }
      case UpDownTopBottomEnum.Bottom: {
        const lastLoInTopic = courseLearningObjectives[courseLearningObjectives.length - 1];
        newPreviousId = lastLoInTopic.csloId;
        break;
      }
    }
    setLastActionHighlightId(movingCsloId);
    await dispatch(reorderClassSessionLearningObjective(csloToMove, newPreviousId)).then((result) => {
      setReorderProcessing(false);
    });
  };

  const renderTopicActions = ({
    name: topicName,
    id: topicId,
    classSessionTopicId,
  }: BetterClassSessionTopic) => {
    // while moving topic, don't show topic actions
    if (movingTopic) {
      return null;
    }
    if (movingLo) {
      const isCurrentTopic = movingLo.fromTopicId === topic.id;
      return (
        <MoveItemButton
          addBtnText="Move to this topic"
          cancelBtnText="Cancel moving LO"
          className="bcp__class-row__move-lo"
          disabled={isProcessing}
          onAdd={() => handleCoursePlannerAction(CoursePlannerAction.MoveLoToThisTopic, { classSessionId, topicId })}
          onCancel={() => handleCoursePlannerAction(CoursePlannerAction.CancelMovingLo, { classSessionId })}
          showCancel={isCurrentTopic}
        />
      );
    }
    // while reordering LOs, show done button
    if (reorderingLos) {
      return (
        <button className="bcp__header-button" onClick={handleDoneReordering}>
          Done
        </button>
      );
    }
    const truncatedTopicName = truncate(topicName, 140);
    return (
      <KebabMenu
        className="bcp__kebab-menu bcp__topic-kebab"
        items={[
          {
            label: 'Edit Topic Name',
            icon: <FaUserEdit />,
            onClick: () => setEditingCustomTopic(true),
            show: isCustomTopic,
          },
          {
            label: `Move ${truncatedTopicName} to Another Class Session`,
            icon: <BsCalendar />,
            onClick: () => handleCoursePlannerAction(CoursePlannerAction.StartMovingThisTopic, {
              classSessionId,
              stringValue: truncatedTopicName,
              topicId: classSessionTopicId,
            }),
          },
          // these remove topic menu items do different things
          { // if topic has no connections, delete without confirming
            label: 'Remove Topic from Class Day',
            icon: <FaTrashAlt />,
            onClick: () => handleCoursePlannerAction(CoursePlannerAction.DeleteEmptyClassSessionTopic, { classSessionId, topicId: classSessionTopicId }),
            show: canQuickDelete,
          },
          { // if topic has connections, show confirm prompt
            label: 'Remove Topic and LOs from Class Day...',
            icon: <FaTrashAlt />,
            onClick: () => handleCoursePlannerAction(CoursePlannerAction.RemoveTopicFromClassSession, { classSessionId, topicId }),
            show: !canQuickDelete,
          },
          {
            label: 'Reorder LOs in this topic',
            icon: <FaSort />,
            onClick: () => setReorderingLos(true),
            show: courseLearningObjectives.length > 1 && !reorderingLos,
          },
        ]}
      />
    );
  };

  const renderLoActions = (
    hasQuestions: boolean, // we already calculate question stuff in CPCTLO, we only need to know the result at this level
    triggerLoEdit: () => void, // The LO editing state lives in the CPCTLO component but we need to trigger it from here, hence this passed callback
    cslo: Pick<EnrichedCourseLearningObjective, 'csloId' | 'type' | 'title' | 'learningObjectiveId' | 'libraryTopicId' | 'topicId' | 'userId'>,
    csloIndex: number
  ) => {
    if (reorderingLos) {
      return (
        <ReorderButtons
          upDisabled={csloIndex === 0}
          downDisabled={csloIndex === courseLearningObjectives.length - 1}
          isProcessing={isProcessing}
          handleAction={(action: UpDownTopBottomEnum) => handleReorderLo(action, cslo.csloId)}
        />
      );
    }
    const loAssignedElsewhere = isLoInOtherClassSessions(cslo.learningObjectiveId, classSessionId, classSessionLearningObjectives);
    return (
      <KebabMenu
        className="bcp__kebab-menu bcp__lo-kebab"
        disabled={isProcessing || !!movingLo}
        items={[
          {
            label: 'Reorder LOs in this topic',
            icon: <FaSort />,
            onClick: () => setReorderingLos(true),
            show: courseLearningObjectives.length > 1 && !reorderingLos,
          },
          {
            label: 'Move LO to another Topic',
            icon: <FaShare />,
            onClick: () => {
              // if topicId is diff from libraryTopicId the LO has been moved, show cancel in the current topic
              const hasLibraryTopic = cslo.topicId === cslo.libraryTopicId;
              const movingFromTopicId = hasLibraryTopic ? cslo.libraryTopicId : cslo.topicId;
              return handleCoursePlannerAction(CoursePlannerAction.StartMovingThisLo, { classSessionId, stringValue: topic.name, csloId: cslo.csloId, topicId: movingFromTopicId });
            },
            show: !loAssignedElsewhere,
          },
          {
            label: 'Remove LO from Class Day...',
            icon: <FaTrashAlt />,
            onClick: () => handleCoursePlannerAction(CoursePlannerAction.RemoveLoAndQuestionsFromClassSession, { classSessionId, csloId: cslo.csloId }),
            show: hasQuestions,
          },
          {
            label: 'Remove LO from Class Day',
            icon: <FaTrashAlt />,
            onClick: () => handleCoursePlannerAction(CoursePlannerAction.DeleteEmptyClassSessionLearningObjective, { classSessionId, csloId: cslo.csloId }),
            show: !hasQuestions,
          },
          {
            label: 'Edit Custom LO',
            icon: <FaUserEdit />,
            onClick: triggerLoEdit,
            show: cslo.type === LibraryTypeEnum.User && cslo.userId === user.id,
          },
        ]}
      />
    );
  };

  return (
    <ClassRowCardContainer
      cardLabel={cardLabel}
      cardType={mode}
      className={`bcp__class-topic ${viewMode}_${topicExpanded ? 'expanded' : 'collapsed'}`}
      disableExpando={addingItems || reorderingLos || !!movingLo}
      data-bcp-view-mode={viewMode}
      data-topic-stringid={stringId}
      data-highlight={highlight}
      handleClickTitle={expandEnabled ? () => handleTopicExpand(!topicExpanded) : undefined}
      isExpanded={topicExpanded}
      setIsExpanded={expandEnabled ? () => handleTopicExpand(!topicExpanded) : false}
      renderActions={() => renderTopicActions(topic)}
      replaceHeader={editingCustomTopic && (() => (
        <CoursePlannerTopicEditor
          handleCancel={() => setEditingCustomTopic(false)}
          handleSave={handleSave}
          initTopicName={topic.name}
        />
      ))}
    >
      {!!courseLearningObjectives.length
        ? courseLearningObjectives.map((clo, idx) => (
          <CoursePlannerClassTopicLearningObjective
            {...sharedClassSessionProps}
            disableExpando={reorderingLos}
            clo={clo}
            highlight={lastActionHighlightId === clo.csloId}
            isProcessing={reorderProcessing}
            key={`${classSessionId}_${topic.id}_${clo.stringId}_${clo.order}`}
            renderLoActions={(hasQuestions, triggerLoEdit) => renderLoActions(hasQuestions, triggerLoEdit, clo, idx)}
          />
        )) : (
          <div className="bcp__class-row__card__body__empty">
            There are currently no LOs assigned to this topic on class {classNumber}
          </div>
        )
      }
    </ClassRowCardContainer>
  );
};

export default CoursePlannerClassTopic;
