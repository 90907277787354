import React from 'react';
import PropTypes from 'prop-types';
import Select, { SingleValue } from 'react-select';
import { SummativeAssessmentApi } from 'types/backend/assessments.types';

type OnChangeEvent = SingleValue<{ value: string | undefined; label: string}>;

export default function SelectSummativeDropdown({
  handleSummativeIdChange,
  selectedSummative,
  summativeAssessments,
}: {
  handleSummativeIdChange: (data: string) => void
  selectedSummative: SummativeAssessmentApi | undefined
  summativeAssessments: Array<SummativeAssessmentApi>
}) {
  const options = summativeAssessments.map(a => {
    return { value: a.id, label: a.name };
  });

  const defaultValue = selectedSummative ? options.find(o => o.value === selectedSummative.id) : undefined;

  function resetActiveSummative(option: OnChangeEvent) {
    if (option && option.value) {
      handleSummativeIdChange(option.value);
    }
  }

  return (
    <Select
      onChange={resetActiveSummative}
      options={options}
      defaultValue={defaultValue}
      classNamePrefix="select-summative-dropdown"
    />
  );
}

SelectSummativeDropdown.propTypes = {
  summativeAssessments: PropTypes.array.isRequired,
  selectedSummative: PropTypes.shape({
    id: PropTypes.string,
  }),
  handleSummativeIdChange: PropTypes.func.isRequired,
};
