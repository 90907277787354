import React, { useContext } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';

import {
  calculateStudyPathPercentages,
  getPracticeTestUnlockedPercentage,
  determinePrepOrReviewCheckpointState,
} from 'student/controllers/Course/StudyPathController/sharedStudyPathFunctions';
import { isInThePast } from 'utils/dateFormattingFunctions';
import { useAppSelector } from 'store';
import retrieveAssessmentsWithEnrollment, { AssessmentWithEnrollment, SummativeAssessmentWithEnrollment } from 'store/selectors/retrieveAssessmentsWithEnrollment';
import retrieveEnrichedStudentAssessments, { EnrichedStudentAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';
import SelectSummativeDropdown from './SelectSummativeDropdown';
import StudyPathSummaryBar from '../StudyPathSummaryBar/StudyPathSummaryBar';
import StudyPathCheckpointColumn from '../StudyPathCheckpointColumn/StudyPathCheckpointColumn';
import StudyPathTopicCard from '../StudyPathTopicCard/StudyPathTopicCard';
import StudyPathTooltipContent, { StudyPathTooltipContentEnum } from '../StudyPathTooltipContent/StudyPathTooltipContent';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import Icon, { IconEnum } from 'shared-components/Icon';
import { EnrichedStudentStudyPath, HandleCardActionData } from '../../StudyPathController.types';
import { CheckpointColumn } from 'types/backend/shared.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { PositionEnum } from 'types/common.types';
import { StudentAppContext } from 'student/StudentBase';
import './StudyPathContainer.scss';

export default function StudyPathContainer({
  activeSummative,
  enrichedStudyPath,
  handleCardSkip,
  handleSummativeIdChange,
  onModalClose,
  selectedSummative,
}: {
  activeSummative: SummativeAssessmentWithEnrollment | null
  enrichedStudyPath: EnrichedStudentStudyPath | null //null indicates there is no study path
  handleCardSkip: (data: HandleCardActionData) => void
  handleSummativeIdChange: (data: string) => void
  onModalClose: () => void
  selectedSummative?: SummativeAssessmentWithEnrollment
}) {
  const { studentAppState: { modalIsOpen } } = useContext(StudentAppContext);
  const enrichedStudentAssessments: Array<EnrichedStudentAssessment> = useAppSelector(retrieveEnrichedStudentAssessments);
  const enrichedAssessments: Array<AssessmentWithEnrollment> = useAppSelector(retrieveAssessmentsWithEnrollment);
  const summativeAssessments = enrichedAssessments.filter(a => a.assessType === AssessTypeEnum.Summative) as Array<SummativeAssessmentWithEnrollment>;

  const priorSummativeAssessments = summativeAssessments.filter(a => {
    const isAfterDue = isInThePast(a.mergedDueDate);
    const isActiveSummative = a.id === activeSummative?.id;
    return isAfterDue || isActiveSummative;
  });

  if (selectedSummative) {
    const { practiceTest: { id: practiceAssessmentId } } = selectedSummative;
    const enrichedStudentAssessmentPracticeTest = enrichedStudentAssessments.find(esa => esa.id === practiceAssessmentId) as EnrichedStudentAssessment;
    if (!enrichedStudentAssessmentPracticeTest) {
      console.error(`enrichedStudentAssessmentPracticeTest not found for ${practiceAssessmentId} in enrichedStudentAssessments:`, enrichedStudentAssessments);
    }
  }

  const showAllTooltips = false;

  const renderStudyPathColumns = (enrichedStudentStudyPath: EnrichedStudentStudyPath) => {
    const { studentTopicCardCheckpoints, summaryInfo } = enrichedStudentStudyPath;
    const practiceTestUnlockedPercentage = getPracticeTestUnlockedPercentage(studentTopicCardCheckpoints, enrichedStudentAssessments);
    const { prep, review, test } = studentTopicCardCheckpoints;
    const {
      topicsReviewedPercentage,
      prepQuestionsReviewedPercentage,
    } = calculateStudyPathPercentages(summaryInfo);
    return (
      <div className="row study-path-columns__wrap">
        <div className="study-path-column study-path-column__review col-xs-12 col-sm-6 col-md-4">
          <StudyPathCheckpointColumn
            checkpointType={CheckpointColumn.Review}
            checkpointState={determinePrepOrReviewCheckpointState(CheckpointColumn.Review, studentTopicCardCheckpoints)}
            topicCardArray={review}
            percentage={topicsReviewedPercentage}
            columnTitle={
              <div className="study-path-column-title">
                <div className="checkpoint-number">
                  Checkpoint 1
                </div>
                <h3 className="h4 checkpoint-name">
                  I Should Review
                  { showAllTooltips && (
                    <BetterTooltip
                      position={PositionEnum.Bottom}
                      content={() => <StudyPathTooltipContent which={StudyPathTooltipContentEnum.IShouldReview} />}
                      className="study-path-column-title__tooltip study-path-tooltip"
                    >
                      <FaRegQuestionCircle size={18} />
                    </BetterTooltip>
                  )}
                </h3>
              </div>
            }
          >
            {
              review.map((topicCardData) => (
                <StudyPathTopicCard
                  key={topicCardData.topicCardId}
                  checkpointType={CheckpointColumn.Review}
                  topicCardData={topicCardData}
                  handleCardSkip={handleCardSkip}
                  onModalClose={onModalClose}
                />
              ))
            }
          </StudyPathCheckpointColumn>
        </div>
        <div className="study-path-column study-path-column__prep col-xs-12 col-sm-6 col-md-4">
          <StudyPathCheckpointColumn
            checkpointType={CheckpointColumn.Prep}
            checkpointState={determinePrepOrReviewCheckpointState(CheckpointColumn.Prep, studentTopicCardCheckpoints)}
            topicCardArray={prep}
            percentage={prepQuestionsReviewedPercentage}
            columnTitle={
              <div className="study-path-column-title">
                <div className="checkpoint-number">
                  Checkpoint 2
                </div>
                <h3 className="h4 checkpoint-name">
                  Prep Me
                  { showAllTooltips && (
                    <BetterTooltip
                      position={PositionEnum.Bottom}
                      content={() => <StudyPathTooltipContent which={StudyPathTooltipContentEnum.PrepMe} />}
                      className="study-path-column-title__tooltip study-path-tooltip"
                    >
                      <FaRegQuestionCircle size={18} />
                    </BetterTooltip>
                  )}
                </h3>
              </div>
            }
          >
            {
              prep.map((topicCardData) => (
                <StudyPathTopicCard
                  key={topicCardData.topicCardId}
                  checkpointType={CheckpointColumn.Prep}
                  topicCardData={topicCardData}
                  handleCardSkip={handleCardSkip}
                  onModalClose={onModalClose}
                />
              ))
            }
          </StudyPathCheckpointColumn>
        </div>
        <div className="study-path-column study-path-column__test col-xs-12 col-sm-12 col-md-4">
          <StudyPathCheckpointColumn
            checkpointType={CheckpointColumn.Test}
            topicCardArray={test}
            percentage={practiceTestUnlockedPercentage}
            summaryInfo={summaryInfo}
            selectedSummativeId={selectedSummative?.id}
            columnTitle={
              <div className="study-path-column-title">
                <div className="checkpoint-number">
                  Checkpoint 3
                </div>
                <h3 className="h4 checkpoint-name">
                  Test Me!
                  { showAllTooltips && (
                    <BetterTooltip
                      position={PositionEnum.Bottom}
                      content={() => <StudyPathTooltipContent which={StudyPathTooltipContentEnum.TestMe} />}
                      className="study-path-column-title__tooltip study-path-tooltip"
                    >
                      <FaRegQuestionCircle size={18} />
                    </BetterTooltip>
                  )}
                </h3>
              </div>
            }
          >
            {
              test.map((topicCardData) => (
                <StudyPathTopicCard
                  key={topicCardData.topicCardId}
                  checkpointType={CheckpointColumn.Test}
                  topicCardData={topicCardData}
                  handleCardSkip={handleCardSkip}
                  onModalClose={onModalClose}
                />
              ))
            }
          </StudyPathCheckpointColumn>
        </div>
      </div>
    );
  };

  return (
    <main className="study-path-container">
      <StudyPathSummaryBar
        summaryInfo={enrichedStudyPath ? enrichedStudyPath.summaryInfo : undefined}
        activeSummative={activeSummative}
        selectedSummative={selectedSummative}
      />
      <div className="study-path-header row">
        <div className="study-path-header-left col col-md-8 col-lg-8 col-xl-8">
          <h2 className="h3 student-page-title">My Study Path</h2>
          <BetterTooltip
            wide
            position={PositionEnum.Right}
            content={() => <StudyPathTooltipContent which={StudyPathTooltipContentEnum.WhatIsStudyPath} />}
            className="study-path-header__tooltip study-path-tooltip"
          >
            <FaRegQuestionCircle />
          </BetterTooltip>
        </div>
        <div className="study-path-header-right col col-md-4 col-lg-4 col-xl-4">
          <Icon which={IconEnum.Book} size={36} />
          {!priorSummativeAssessments.length ? (
            <>No Study Paths Scheduled</>
          ) : (
            <>
              <div>
                Studying For:
              </div>
              <div className="study-path-header-right__select-summative">
                {!modalIsOpen && (
                  <SelectSummativeDropdown
                    summativeAssessments={priorSummativeAssessments}
                    selectedSummative={selectedSummative}
                    handleSummativeIdChange={handleSummativeIdChange}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      { selectedSummative && enrichedStudyPath && renderStudyPathColumns(enrichedStudyPath)}
    </main>
  );
}
