/****
 * retrieveActiveAssessmentQuestionMaps
 * This selector returns all the assessmentQuestionMaps in state.active
 * And it significantly enhances them with bonus attributes, so it should probably be named:
 * `retrieveActiveAssessmentQuestionMaps`
 *
 * I want to make Vance convert this to TypeScript
 * He did it!
 *****/

import { createSelector } from '@reduxjs/toolkit';
import cloneDeep from 'lodash-es/cloneDeep';
import intersectionBy from 'lodash-es/intersectionBy';
import retrieveActiveCombinedQuestions, { ActiveCombinedQuestion } from './retrieveActiveCombinedQuestions';
import retrieveEnrichedClassSessions from 'store/selectors/retrieveEnrichedClassSessions';
import { MappedAssessmentQuestion } from 'types/common.types';
import { AssessmentApiBase } from 'types/backend/assessments.types';
import { Store } from 'types/store.types';

export default createSelector(
  (store: Store) => store.active.assessmentQuestionMaps,
  retrieveEnrichedClassSessions,
  retrieveActiveCombinedQuestions,
  (store: Store) => store.active.assessments,
  (assessmentQuestionMaps, enrichedClassSessions, activeCombinedQuestions, assessments) => {
    const newAssessmentQuestionMaps = cloneDeep(assessmentQuestionMaps);
    const enrichedAssessmentQuestionMaps: Array<MappedAssessmentQuestion> = newAssessmentQuestionMaps.map(aqm => {
      // this new test is good for now, but we will need to address 'user' type questions when we have them.
      const questionData = activeCombinedQuestions.find(tq => tq.id === aqm.questionId) as ActiveCombinedQuestion;
      if (!questionData) {
        console.error(`questionId ${aqm.questionId} from aqms not found in activeCombinedQuestions`, aqm, activeCombinedQuestions);
      }
      const assessmentData = assessments.find(a => a.id === aqm.assessmentId) as AssessmentApiBase;
      // copying a value a level up for convenience.
      const courseLearningObjectives = questionData?._derived.courseLearningObjectives || [];
      // Get any class sessions this assessmentQuestionMap is associated with
      const classSessionIds = enrichedClassSessions.reduce((r, cs) => {
        if (intersectionBy(cs.learningObjectives, courseLearningObjectives, 'id').length) {
          r.push(cs.id);
        }
        return r;
      }, [] as Array<number>);
      const _derived = {
        assessmentData,
        classSessionIds,
        courseLearningObjectives,
        questionData,
      };
      return { ...aqm, _derived };
    });
    return enrichedAssessmentQuestionMaps;
  }
);
