import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';
import { ClassSessionApi } from 'types/backend/classSessions.types';
import reloadAssessments from './reloadAssessments';

export default function updateClassSession(newClassSession: ClassSessionApi) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { id, courseId } = newClassSession;
    await apiNext.editClassSession(id, newClassSession);

    const reloadedClassSessions: Array<ClassSessionApi> = await apiNext.getSortedCourseClassSessions(courseId);

    //reload assessments since classDaysCovered may have changed
    await dispatch(reloadAssessments(courseId));

    return dispatch(activeSlice.actions.setActiveClassSessions(reloadedClassSessions));
  })();
}
