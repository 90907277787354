import React from 'react';
import PropTypes from 'prop-types';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { AddRemoveEnum } from 'types/common.types';
import './AddRemoveButton.scss';

function AddRemoveButton({
  allowAdd,
  allowRemove,
  className = 'add-remove',
  disabled,
  handleAdd,
  handleRemove,
  isProcessing,
  size = 20,
  title = '',
  verb,
  ...props
}: {
  allowAdd: boolean
  allowRemove: boolean
  className?: string
  disabled?: boolean
  handleAdd: React.MouseEventHandler<HTMLButtonElement>
  handleRemove: React.MouseEventHandler<HTMLButtonElement>
  isProcessing?: boolean
  size?: number
  title?: string
  verb: AddRemoveEnum
}) {
  const isAdd = verb === AddRemoveEnum.Add;
  const button = isAdd
    ? <FaPlusCircle size={size} />
    : <FaMinusCircle size={size} />;
  const buttonDisabled = disabled || (verb === AddRemoveEnum.Add && !allowAdd) || (verb === AddRemoveEnum.Remove && !allowRemove);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    return isAdd
      ? handleAdd(e)
      : handleRemove(e);
  };
  return (
    <button
      className={`add-remove-button action-${isAdd ? 'plus' : 'minus'} ${className}__action-${isAdd ? 'plus' : 'minus'}`}
      data-verb={verb}
      data-isprocessing={isProcessing}
      disabled={buttonDisabled || isProcessing}
      onClick={handleClick}
      title={title}
      {...props}
    >
      {button}
    </button>
  );
}

AddRemoveButton.propTypes = {
  allowAdd: PropTypes.bool.isRequired,
  allowRemove: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleAdd: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool,
  title: PropTypes.string,
  verb: PropTypes.oneOf([AddRemoveEnum.Add, AddRemoveEnum.Remove]),
};

export default AddRemoveButton;
