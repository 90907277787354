
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FaTimes } from 'react-icons/fa';

import useEffectOnce from 'hooks/useEffectOnce';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import retrieveBetterClassSessions, { BetterClassSession } from 'store/selectors/retrieveBetterClassSessions';
import LoadingButton from 'shared-components/LoadingButton/LoadingButton';
import { EnrichedClassSession } from 'store/selectors/retrieveEnrichedClassSessions';
import { ConfirmationTypeEnum } from 'types/common.types';
import { Store } from 'types/store.types';
import { ClassSessionApi, ClassTypeEnum } from 'types/backend/classSessions.types';
import './SpecialSessionPopup.scss';

interface SpecialSessionPopUpProps {
  classSession: EnrichedClassSession
  onClose: () => void
  saveClassSession: (session: ClassSessionApi) => void
}

const SpecialSessionPopUp = ({
  classSession,
  onClose,
  saveClassSession,
}: SpecialSessionPopUpProps) => {

  const { classType } = classSession;
  const label = classSession.label || '';
  const [classTypeInput, setClassTypeInput] = useState(classType);
  const [classLabelInput, setClassLabelInput] = useState(label);
  const [saving, setSaving] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const { triggerConfirmationPrompt } = useConfirmationPrompt();

  const activeClassSessions = useSelector((store: Store) => store.active.classSessions);
  const betterClassSessions = useSelector(retrieveBetterClassSessions);
  const betterClassSession = betterClassSessions.find(bsc => bsc.id === classSession.id) as BetterClassSession;

  const { canSessionBeMadeSpecial, sessionDependencies } = (() => {
    const dependencies = [];
    if (!!betterClassSession.topics.length) {dependencies.push('topics');}
    if (!!betterClassSession.courseLearningObjectives.length) {dependencies.push('learning objectives');}
    if (!!betterClassSession.iclrs.length) {dependencies.push('in-class materials');}
    if (!!betterClassSession.ooclrs.length) {dependencies.push('pre-class resources');}

    return {
      canSessionBeMadeSpecial: !dependencies.length,
      sessionDependencies: dependencies,
    };
  })();

  // This useEffect handles tracking if any form values have changed since initial load
  useEffect(() => {
    setHasChanged(classLabelInput !== label || classTypeInput !== classType);
  }, [classTypeInput, classLabelInput, classType, label]);

  //this useEffectOnce throws the confirmation prompt if the session cannot be made special
  useEffectOnce(() => {
    if (!canSessionBeMadeSpecial) {
      triggerConfirmationPrompt({
        title: 'You cannot make this class a non-class day',
        message: (
          <div>
            It contains one or more of the following:
            <ul>
              {sessionDependencies.map(sd => (<li key={sd}>{sd}</li>))}
            </ul>
            Please remove these items from the class session before continuing.
          </div>
        ),
        onConfirm: onClose,
        confirmationType: ConfirmationTypeEnum.Warn,
      });

    }
  });

  async function handleSaveSession() {
    setSaving(true);
    const originalSession = activeClassSessions.find(sess => sess.id === classSession.id);
    if (originalSession) {
      await saveClassSession({
        ...originalSession,
        classType: classTypeInput,
        label: classLabelInput || 'No Class',
      });
    }
    setSaving(false);
    onClose();
  }

  if (!canSessionBeMadeSpecial) {
    return null;
  }
  return (
    <div className="special-session__container">
      <div className="special-session__popup">
        <div className="special-session__popup-header">
          <div>Edit Day</div>
          <button className="special-session__popup-close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="special-session__popup-body">
          <div className="special-session__popup-body-row">
            <input
              id="class-session-type-checkbox"
              type="checkbox"
              checked={classTypeInput === ClassTypeEnum.Special}
              onChange={({ currentTarget: { checked } }) => setClassTypeInput(checked ? ClassTypeEnum.Special : ClassTypeEnum.Normal)}
              className="special-session__popup-body-checkbox"
            />
            <label htmlFor="class-session-type-checkbox">
              This is a non-class day
            </label>
          </div>
          <div className="special-session__popup-body-row">
            <input
              id="class-session-label-input"
              placeholder="Label the day"
              type="text"
              value={classLabelInput}
              onChange={e => setClassLabelInput(e.target.value)}
            />
          </div>
          <div className="special-session__popup-body-row popup-body-row-note">
            Note: Marking this day as a non-class day will automatically bump its class number (e.g. Class 6) to the next scheduled class in the course.
          </div>
        </div>
        <div className="special-session__popup-footer">
          <div className="special-session__popup-footer-button">
            <LoadingButton
              loadingText="Saving Changes"
              loading={saving}
              text="Apply"
              type="button"
              onClick={handleSaveSession}
              disabled={!hasChanged}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SpecialSessionPopUp.propTypes = {
  classSession: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  saveClassSession: PropTypes.func.isRequired,
};

export default SpecialSessionPopUp;

