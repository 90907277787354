/******
 * This is a very simple input component that simplifies a pattern we use a lot for inline inputs
 * where we need a text input for which save is triggered by the press of an enter key
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { EventKeyEnum } from 'types/common.types';

type SimpleInputProps = {
  className?: string
  defaultValue: string
  placeholder: string
  onSave: (value: string) => void
  setIsEditing: (value: boolean) => void
}
function SimpleInput({
  className,
  defaultValue,
  placeholder,
  onSave,
  setIsEditing,
}: SimpleInputProps) {
  const inputEl = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputEl.current) {
      // use this in place of autoFocus prop for a11y, so that input gets focus when SimpleInput mounts
      inputEl.current.focus();
    }
  }, []);
  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === EventKeyEnum.Enter) {
      setIsEditing(false);
      const target = event.target as HTMLInputElement;
      if (target.value !== '') {
        onSave(target.value);
      }
      event.preventDefault();
    }
    if (event.key === EventKeyEnum.Escape) {
      setIsEditing(false);
    }
  }
  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    setIsEditing(false);
    event.preventDefault();
  }

  return (
    <input
      className={`simple-input ${className}`}
      type="text"
      ref={inputEl}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
    />
  );
}

SimpleInput.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  onSave: PropTypes.func,
  setIsEditing: PropTypes.func,
};

export default SimpleInput;
