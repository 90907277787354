import apiNext from 'api-next';
import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import activeSlice from 'store/slices/active';
import { GradingPolicyEnum } from 'types/backend/assessments.types';
import { AppDispatch, Store } from 'types/store.types';

export default function addQuestionToAssessment(questionId: number, assessmentId: string, gradingPolicy: GradingPolicyEnum, points: number = 1) {
  console.debug('addQuestionToAssessment questionId, assessmentId, gradingPolicy', questionId, assessmentId, gradingPolicy);
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active } = getStore();
    const assessmentQuestionMaps = retrieveActiveAssessmentQuestionMaps(getStore());

    //Exit if the assesment already has this template question.
    const hasAlready = assessmentQuestionMaps.find(aqm => aqm.questionId === questionId && aqm.assessmentId === assessmentId);
    if (hasAlready) {
      throw new Error('This question has already been added to this assessment');
    }

    const { templateQuestions, userQuestions } = active;

    if (![...userQuestions, ...templateQuestions].find(tq => tq.id === questionId)) {
      throw new Error('Invalid question id passed to addQuestionToAssessment');
    }

    //Get the order for new item (it goes at the end)
    const createdAqm = await apiNext.createAssessmentQuestionMap({
      questionId,
      assessmentId,
      points: gradingPolicy === GradingPolicyEnum.NoPoints ? 0 : points,
    });
    console.debug('createdAqm createAssessmentQuestionMap', createdAqm, assessmentQuestionMaps);

    return dispatch(activeSlice.actions.addActiveAssessmentQuestionMap(createdAqm));
  })();
}
