import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import apiNext from 'api-next';
import { simpleConfirm, delay } from 'utils';
import { checkIfStudentsHaveStartedAssessment } from 'utils/assessmentFunctions';
import { isInThePast } from 'utils/dateFormattingFunctions';
import { getPresetDelta, getAssessmentsToUpdateForCoursePresets } from './coursePresetFunctions';
import ApplyPresetsToExistingAssessmentsConfirmation from './components/ApplyPresetsToExistingConfirmation/ApplyPresetsToExistingConfirmation';
import CourseDetails from './components/CourseDetails';
import CourseAssessmentPresetForm from './components/CourseAssessmentPresetForm';
import getInitialData from 'store/actions/getInitialData';
import { useAppDispatch, useAppSelector } from 'store';
import enrollmentsSlice from 'store/slices/enrollments';
import passiveSlice from 'store/slices/passive';
import activeSlice from 'store/slices/active';
import editAssessment from 'store/actions/editAssessment';
import editStudyPathPreset from 'store/actions/editStudyPathPreset';
import updateCourseAssessmentPreset from 'store/actions/updateCourseAssessmentPreset';
import { CourseApi } from 'types/backend/courses.types';
import { CourseAssessmentPresetApi } from 'types/backend/courseAssessmentPresets.types';
import { EnrollmentApi } from 'types/backend/enrollments.types';
import { InstructorCoursePath } from 'types/instructor.types';
import { AssessmentApiBase, AssessTypeEnum, SummativeAssessmentApi } from 'types/backend/assessments.types';
import { PresetHasChanged } from './CourseDetailsController.types';
import './components/CourseDetails.scss';


interface PresetState {
  assessmentsToUpdate: Array<AssessmentApiBase> | null
  startedFutureAssessmentCount: number | null
  updatedPreset: CourseAssessmentPresetApi | null
}

function CourseDetailsController({ type }: { type: 'edit' | 'new' }) {
  const history = useHistory();

  const course = useAppSelector((store) => store.active.course);
  const userId = useAppSelector((store) => store.user.id);
  const assessments = useAppSelector((store) => store.active.assessments);
  const students = useAppSelector((store) => store.active.students);
  const existingCap = useAppSelector((store) => store.active.courseAssessmentPreset);
  const dispatch = useAppDispatch();
  const [presetSubmitEnabled, setPresetSubmitEnabled] = useState(false);
  const [presetChanges, setPresetChanges] = useState<PresetHasChanged<CourseAssessmentPresetApi> | null>(null);

  // state values for applying presets to existing assessments
  const [showApplyPresetConfirmation, setShowApplyPresetConfirmation] = useState(false);
  const initPresetState: PresetState = {
    assessmentsToUpdate: null,
    startedFutureAssessmentCount: null,
    updatedPreset: null,
  };
  const [{ assessmentsToUpdate, startedFutureAssessmentCount, updatedPreset }, setPresetState] = useState(initPresetState);
  const [updatedItemCount, setUpdatedItemCount] = useState(0);

  async function handleSaveCourse(courseDetails: CourseApi) {
    const updatedCourse = await apiNext.editCourse(courseDetails.id, courseDetails);
    dispatch(activeSlice.actions.setActiveCourse(updatedCourse));
    dispatch(passiveSlice.actions.updatePassiveInstructorCourse(updatedCourse));
    const oldSubjectIds = course.additionalSubjectIds;
    const newSubjectIds = updatedCourse.additionalSubjectIds;
    if (!(oldSubjectIds.every((id) => newSubjectIds.includes(id)) && newSubjectIds.every((id) => oldSubjectIds.includes(id)))) {
      //reload getInitialData if `course.additionalSubjects` has changed
      await dispatch(getInitialData(updatedCourse.id));
    }
    history.push(`/instructor/course/${updatedCourse.id}/${InstructorCoursePath.DailyPlanner}`);
  }

  async function handleCreateCourse(courseDetails: Omit<CourseApi, 'id' | 'createdAt' | 'updatedAt'>) {
    const newCourse = await apiNext.createCourse(courseDetails);
    dispatch(passiveSlice.actions.appendNewInstructorCourse(newCourse));
    history.push(`/instructor/course/${newCourse.id}/${InstructorCoursePath.DailyPlanner}`);
    const userEnrollments: Array<EnrollmentApi> = await apiNext.getUserEnrollments(userId);
    dispatch(enrollmentsSlice.actions.setUserEnrollments(userEnrollments));
  }

  async function onSaveCourseAssessmentPreset(preset: CourseAssessmentPresetApi, updateExistingAssessments: boolean) {
    if (updateExistingAssessments && !!assessments.length) {
      setShowApplyPresetConfirmation(true);
      const presetDelta = getPresetDelta(existingCap, preset);
      setPresetChanges(presetDelta);
      prepareAffectedAssessmentsData(preset, presetDelta);
    } else {
      await dispatch(updateCourseAssessmentPreset(preset));
    }
  }

  function resetApplyToExistingAssessmentsState() {
    setUpdatedItemCount(0);
    setPresetState(initPresetState);

    setShowApplyPresetConfirmation(false);
  }

  async function prepareAffectedAssessmentsData(newPreset: CourseAssessmentPresetApi, presetDelta: PresetHasChanged<CourseAssessmentPresetApi>) {
    // get all assessments that are in the future, based on openDate
    // prep and practiceTest can be skipped since the summative openDate controls them
    const futureAssessments = assessments.filter(({ openDate, assessType }) => !isInThePast(openDate) && ![AssessTypeEnum.PracticeTest, AssessTypeEnum.Prep].includes(assessType));

    // get array of assessments with updated values based on course preset changes
    const assessmentsPotentiallyAffected = getAssessmentsToUpdateForCoursePresets(futureAssessments, newPreset, presetDelta, course.timeZone);

    // determine future assessments that have already been started
    let startedAssessmentIds: Array<string | null> = [];
    if (!!students.length) {
      const assessmentIdsPotentiallyStarted = assessmentsPotentiallyAffected.reduce((acc: Array<string>, assessment) => {
        const { assessType } = assessment;
        if (assessType === AssessTypeEnum.Summative) {
          const { prep, practiceTest } = assessment as SummativeAssessmentApi;
          acc.push(prep.id);
          acc.push(practiceTest.id);
        }
        return acc;
      }, []);
      const startedAssessmentIdsPromises = assessmentIdsPotentiallyStarted.map(async (id) => {
        const started = await checkIfStudentsHaveStartedAssessment(id);
        return started ? id : null;
      });
      const startedAssessmentResults = await Promise.all(startedAssessmentIdsPromises);
      startedAssessmentIds = startedAssessmentResults.filter((id) => id !== null);
    }

    // don't try to update assessments that have already been started
    const assessmentsToUpdateData = assessmentsPotentiallyAffected.filter((assessment) => {
      const isStarted = startedAssessmentIds.includes(assessment.id);
      const { assessType, prep, practiceTest } = assessment as SummativeAssessmentApi;
      const prepOrPracticeIsStarted = assessType === AssessTypeEnum.Summative && (
        startedAssessmentIds.includes(prep.id) || startedAssessmentIds.includes(practiceTest.id)
      );
      return !(isStarted || prepOrPracticeIsStarted);
    });

    // set state values
    setPresetState({ assessmentsToUpdate: assessmentsToUpdateData, startedFutureAssessmentCount: startedAssessmentIds.length, updatedPreset: newPreset });
    return;
  }

  async function updatePresetAndAssessments() {
    if (!updatedPreset || !assessmentsToUpdate) {
      console.warn('No preset or assessments to update');
      return;
    }
    //preset changes must be applied before assessment changes to ensure the assessment changes are calculated correctly on the BE
    await onSaveCourseAssessmentPreset(updatedPreset, false);
    // this could be changed to a Promise.all, but this makes it less impactful to the BE for now
    for (const assessment of assessmentsToUpdate) {
      if (assessment.assessType === AssessTypeEnum.Summative) {
        await dispatch(editStudyPathPreset(assessment as SummativeAssessmentApi));
      } else {
        await dispatch(editAssessment(assessment.id, assessment));
      }
      setUpdatedItemCount((prevCount) => prevCount + 1);
    }
    return delay(400).then(() => {
      resetApplyToExistingAssessmentsState();
    });
  }

  const handleCancel = () => {
    // if editing course, return to course home on cancel, else return to course list
    if (presetSubmitEnabled && !simpleConfirm('There are unsaved preset changes. If you click "OK" they will be lost.')) {
      return;
    }
    const dest = !!course.id ? `/instructor/course/${course.id}/${InstructorCoursePath.DailyPlanner}` : '/';
    history.push(dest);
  };

  if (type === 'new') {
    return (
      <CourseDetails
        onCancel={handleCancel}
        onCreate={handleCreateCourse}
      />
    );
  }
  return (
    <>
      {!!showApplyPresetConfirmation && (
        <ApplyPresetsToExistingAssessmentsConfirmation
          assessmentsToUpdate={assessmentsToUpdate}
          resetApplyToExistingAssessmentsState={resetApplyToExistingAssessmentsState}
          startedFutureAssessmentCount={startedFutureAssessmentCount}
          updatePresetAndAssessments={updatePresetAndAssessments}
          updatedItemCount={updatedItemCount}
          presetChanges={presetChanges}
        />
      )}
      <div className="course-details__wrapper">
        <CourseDetails
          course={course}
          onCancel={handleCancel}
          onSave={handleSaveCourse}
        />
        <CourseAssessmentPresetForm
          onCancel={handleCancel}
          onSavePreset={onSaveCourseAssessmentPreset}
          presetSubmitEnabled={presetSubmitEnabled}
          setPresetSubmitEnabled={setPresetSubmitEnabled}
        />
      </div>
    </>
  );
}

CourseDetailsController.propTypes = {
  type: PropTypes.oneOf(['new', 'edit']).isRequired,
};

export default CourseDetailsController;
