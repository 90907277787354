import { ReactNode } from 'react';
import { GradingTypeTag } from 'types/backend/l8y.types';
import { QuestionUseEnum } from 'types/backend/shared.types';
import { LeftRightEnum } from 'types/common.types';

export enum QuestionBuilderStep {
  ChooseLO = 'choose-lo',
  Authoring = 'authoring',
  Reviewing = 'reviewing',
  Close = 'close',
}

export enum ButtonTypeEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  Loading = 'loading',
}

export enum ButtonAlias {
  Cancel = 'cancel',
  Done = 'done',
  GoBack = 'back',
  GoNext = 'next',
  GoNextSurvey = 'next-survey',
  OnlySave = 'only-save',
  SaveAndDone = 'save-and-done',
  SaveAndAdd = 'save-and-add',
  SaveAndGoBack = 'save-and-back',
  SaveAndGoNext = 'save-and-next',
}

export interface QuestionBuilderFooterButton {
  alias: ButtonAlias
  buttonType?: ButtonTypeEnum
  disabled?: boolean
  icon?: () => ReactNode
  iconAlign?: LeftRightEnum
  isLoading?: boolean
  label: string
  loadingText?: string
  onClick: () => void
  show?: boolean
}

export interface QuestionBuilderFooterButtonGroup {
  leftButtons: Array<QuestionBuilderFooterButton>
  rightButtons: Array<QuestionBuilderFooterButton>
}

export interface QuestionBuilderFooterObject {
  [QuestionBuilderStep.ChooseLO]: QuestionBuilderFooterButtonGroup
  [QuestionBuilderStep.Authoring]: QuestionBuilderFooterButtonGroup
  [QuestionBuilderStep.Reviewing]: QuestionBuilderFooterButtonGroup
  [QuestionBuilderStep.Close]: QuestionBuilderFooterButtonGroup
}

export interface InitQuestionBuilder {
  allowAdd?: boolean
  blooms: number | null
  editingAssessmentId: string | null
  gradingType: GradingTypeTag | null
  l8yId: string | false
  questionId: number | false
  questionUse: QuestionUseEnum
  selectedLoIds: Array<number>
  shortTitle: string | null
}

export interface QuestionUsageData {
  courseName: string
  courseId: string
  assessmentName: string
  assessmentId: string
}
