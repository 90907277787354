import apiNext from 'api-next';
import { AssessmentApiBase } from 'types/backend/assessments.types';
import { EnrollmentApi } from 'types/backend/enrollments.types';

const createStudentAssessment = async function({ assessmentData, enrollmentData }: {
  assessmentData: Pick<AssessmentApiBase, 'id'>
  enrollmentData: Pick<EnrollmentApi, 'id'>
}) {
  const studentAssessmentDataArray = await apiNext.getStudentAssessment(assessmentData.id, enrollmentData.id);
  const studentAssessmentData = studentAssessmentDataArray.find(({ assessmentId }) => assessmentId === assessmentData.id);
  if (studentAssessmentData) {
    // assessment was created previously
    return studentAssessmentData;
  }
  const { id: enrollmentId } = enrollmentData;
  return await apiNext.createStudentAssessment(assessmentData.id, enrollmentId);
};

export default createStudentAssessment;
