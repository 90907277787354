import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { QuestionApiOut } from 'types/backend/questions.types';
import { TopicApi } from 'types/backend/topics.types';

// simplified EnrichedCourseLearningObjective
export interface SimpleCourseLo {
  id: number
  loNumber: string
  stringId: string
  title: string
  topic: TopicApi
}

export interface AssessmentControllerQuestion extends Pick<QuestionApiOut,
  'gradingType' |
  'id' |
  'learningObjectiveIds' |
  'l8yId' |
  'questionTypes' |
  'shortTitle' |
  'title' |
  'type'
> {
  assessmentQuestionId?: number
  assessmentQuestionPoints?: number
  loData: Array<SimpleCourseLo>
}

const getAssessmentControllerQuestions = ({
  assessmentId,
  assessmentQuestionMaps,
  courseLearningObjectives,
  questions,
}: {
  assessmentId: string
  assessmentQuestionMaps: Array<AssessmentQuestionApi>
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
  questions: Array<QuestionApiOut>
}): Array<AssessmentControllerQuestion> => {
  return assessmentQuestionMaps.reduce((acc: Array<AssessmentControllerQuestion>, aqm) => {
    if (aqm.assessmentId === assessmentId) {
      const questionFromAqm = questions.find((atq) => atq.id === aqm.questionId);
      if (!questionFromAqm) {
        console.error(`aqm question ${aqm.questionId} not found in questions`);
        return acc;
      }
      const { learningObjectiveIds } = questionFromAqm;
      const loData = courseLearningObjectives.reduce((clos: Array<SimpleCourseLo>, clo) => {
        if (!learningObjectiveIds.includes(clo.id)) {
          return clos;
        }
        const { _derived: { loNumber }, id, stringId, title, topic } = clo;
        return [
          ...clos,
          {
            id,
            loNumber,
            stringId,
            title,
            topic,
          },
        ];
      }, []);

      const { id: assessmentQuestionId, points: assessmentQuestionPoints } = aqm;
      const acq: AssessmentControllerQuestion = {
        ...questionFromAqm,
        assessmentQuestionId,
        assessmentQuestionPoints,
        loData,
      };
      acc.push(acq);
    }
    return acc;
  }, []);
};

export default getAssessmentControllerQuestions;
