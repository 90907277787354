import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import defaultState from 'store/defaultState';
import { UserApi } from 'types/backend/users.types';

const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState: defaultState.adminUser as UserApi | null,
  reducers: {
    setAdminUser: (adminUser, action: PayloadAction<UserApi>) => {
      return action.payload;
    },
    clear: () => {
      return defaultState.adminUser;
    },
  },
});

export default adminUserSlice;
