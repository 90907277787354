import React, { useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { FaTimes } from 'react-icons/fa';

import { useAppDispatch, useAppSelector } from 'store';
import { addUserTopic } from 'store/slices/activeTopicsThunks';
import createUserLearningObjective from 'store/actions/createUserLearningObjective';
import updateUserLearningObjective from 'store/actions/updateUserLearningObjective';
import reloadAllQuestions from 'store/actions/reloadAllQuestions';
import LoadingButton from 'shared-components/LoadingButton/LoadingButton';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import CoursePlannerTopicEditor from 'instructor/controllers/Course/BetterCoursePlannerController/components/CoursePlannerTopicEditor';
import { DropdownOption, GroupedOption } from 'instructor/components/Dropdown/Dropdown.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { PositionEnum } from 'types/common.types';
import './LoBuilder.scss';

export interface LoSaveData {
  title: string
  topicId: number
  parentLearningObjectiveId?: number
}

export interface LoBuilderEditInit {
  id?: number // id is optional to allow for copy and modify LO
  title: string
  libraryTopicId: number // LoBuilder only cares about library topic, can't change CSLO topic id
  parentLearningObjectiveId?: number
}

function LoBuilder({
  initEditState,
  onSave,
}: {
  initEditState?: LoBuilderEditInit | null
  onSave: (lo: LearningObjectiveApi, shouldAddToClassSession?: boolean) => void
}) {
  const { id: loId, title, libraryTopicId, parentLearningObjectiveId } = initEditState || {};
  const dispatch = useAppDispatch();
  const user = useAppSelector((store) => store.user);
  const course = useAppSelector((store) => store.active.course);
  const subjects = useAppSelector((store) => store.passive.subjects);
  const units = useAppSelector((store) => store.active.units);
  const topics = useAppSelector((store) => store.active.topics);
  const loTopic = topics.find((topic) => topic.id === libraryTopicId);
  const initialSubjectId = units.find(unit => unit.id === loTopic?.unitId)?.subjectId || course.subjectId;
  const loSubjectName = subjects.find(subject => subject.id === initialSubjectId)?.name as string;
  const isEditingLo = !!initEditState && !!loId;
  const isNewLoOrInPrimarySubject = !isEditingLo || course.subjectId === initialSubjectId;
  const isCopyingExistingLo = !!initEditState && !loId && !!parentLearningObjectiveId;

  const [loTitle, setLoTitle] = useState(title || '');
  const [loTopicId, setLoTopicId] = useState(libraryTopicId || -1);
  const [loSubjectId, setLoSubjectId] = useState(initialSubjectId);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showCreateTopic, setShowCreateTopic] = useState(false);

  const minTitleLength = 1;
  // valid if content has changed, > min length, topic id selected
  const isValid = (title !== loTitle || libraryTopicId !== loTopicId) && loTitle.length >= minTitleLength && loTopicId !== -1;

  /* Subject Select */
  const sortedSubjects = [...subjects].sort((a, b) => b.id === course.subjectId ? 1 : -1);
  const subjectOptions = sortedSubjects.reduce((acc, cur) => {
    if ([course.subjectId, ...course.additionalSubjectIds].includes(cur.id)) {
      acc.push({ label: cur.name, value: cur.id });
    }
    return acc;
  }, [] as Array<DropdownOption<number>>);

  const handleSubjectChange = (newSubjectId: number) => {
    if (newSubjectId !== loSubjectId) {
      setLoSubjectId(newSubjectId);
      setLoTopicId(initialSubjectId === newSubjectId ? loTopicId : -1);
    }
  };

  /* Topic Select */
  const getGroupedTopicOptions = (groupSubjectId: number) => units.reduce((acc, { id: unitId, name: unitName, subjectId }) => {
    if (subjectId === groupSubjectId) {
      const unitTopics = topics.filter((topic) => topic.unitId === unitId && (topic.type === LibraryTypeEnum.Template || topic.userId === user.id));
      if (unitTopics.length) {
        acc.push({
          label: unitName,
          options: unitTopics.map(({ id, name }) => ({ label: name, value: id })),
        });
      }
    }
    return acc;
  }, [] as Array<{ label: string; options: Array<DropdownOption<number>> }>);
  const groupedTopicOptions = getGroupedTopicOptions(loSubjectId);
  // bit of a hack but it works, prepend the Create New Topic option to the top of the topic select with an arbitrary large negative number value
  const createNewTopicValue = -1000;
  const groupedTopicOptionsWithNew = [{ label: 'Create New Topic', value: createNewTopicValue }, ...groupedTopicOptions];
  const handleTopicChange = (option: SingleValue<DropdownOption<number>>) => {
    if (!option) {
      return;
    }
    if (option.value === createNewTopicValue) {
      setShowCreateTopic(true);
    } else {
      setLoTopicId(option.value);
    }
  };
  const getTopicOptionForTopicId = (topicIdToFind: number) => {
    const selectedTopicOption = groupedTopicOptions.reduce((acc, { options }) => {
      const topicOption = options.find((option) => option.value === topicIdToFind);
      if (topicOption) {
        acc = topicOption;
      }
      return acc;
    }, null as DropdownOption<number> | null);
    return selectedTopicOption;
  };

  /* Topic Create */
  const handleNewTopicSave = async (topicName: string) => {
    const { subjectId } = course;
    const addedUserTopic = await dispatch(addUserTopic({ name: topicName, subjectId, userId: user.id })).unwrap();
    setLoTopicId(addedUserTopic.id);
    setShowCreateTopic(false);
    return true;
  };

  // LO from scratch
  let headerText = 'Write A Custom Learning Objective';
  if (isCopyingExistingLo) {
    // copying existing LO
    headerText = 'Edit Copy of Learning Objective';
  } else if (!!initEditState) {
    // editing existing LO
    headerText = 'Edit Learning Objective';
  }

  const saveLo = async () => {
    setIsProcessing(true);
    if (isEditingLo) {
      const loResult = await dispatch(updateUserLearningObjective(loId, loTitle, loTopicId));
      onSave(loResult);
    } else {
      const loResult = await dispatch(createUserLearningObjective(loTitle, loTopicId, parentLearningObjectiveId));
      if (!!parentLearningObjectiveId) {
        await dispatch(reloadAllQuestions());
      }
      onSave(loResult, true);
    }
    setIsProcessing(false);
  };

  return (
    <div className="lo-builder">
      <h2>
        {headerText}
      </h2>
      <div className="lo-builder__form">
        <div className="lo-builder__form-item">
          <label htmlFor="subject" className="lo-builder__dropdown-label">
            Subject
          </label>
          <Select
            isDisabled={!isCopyingExistingLo}
            closeMenuOnSelect={true}
            id="subject"
            name="subject"
            className="lo-builder__select"
            classNamePrefix="lo-builder__select-subject"
            onChange={(option) => option && handleSubjectChange(option.value)}
            options={subjectOptions}
            value={subjectOptions.find(so => so.value === loSubjectId)}
            formatGroupLabel={(groupOption: GroupedOption<number>) => (
              <div className="lo-builder__unit-group-header">
                {groupOption.label}
              </div>
            )}
          />
        </div>
        {showCreateTopic ? (
          <div className="lo-builder__form-item lo-builder__create-topic-wrap">
            <CoursePlannerTopicEditor
              buttonText="Save New Topic"
              handleCancel={() => {}}
              handleSave={handleNewTopicSave}
              minimal
            />
            <button
              className="lo-builder__cancel-create-topic"
              onClick={() => setShowCreateTopic(false)}
              title="Cancel Creating Topic"
            >
              <FaTimes />
            </button>
          </div>
        ) : (
          <div className="lo-builder__form-item lo-builder__select-topic-wrap">
            <label htmlFor="topic" className="lo-builder__dropdown-label">
              Topic
            </label>
            <Select
              isDisabled={!isNewLoOrInPrimarySubject}
              closeMenuOnSelect={true}
              id="topic"
              name="topic"
              className="lo-builder__select"
              classNamePrefix="lo-builder__select-topic"
              onChange={handleTopicChange}
              options={groupedTopicOptionsWithNew}
              value={getTopicOptionForTopicId(loTopicId)}
              formatGroupLabel={(groupOption: GroupedOption<number>) => (
                <div className="lo-builder__unit-group-header">
                  {groupOption.label}
                </div>
              )}
            />
            {!isNewLoOrInPrimarySubject && (
              <div className="lo-builder__topic-warning">
                You cannot change the library topic of this LO. You can still drag this LO into any topic you'd like once it's assigned in your course.
                <BetterTooltip
                  content={`This LO was created in a course under the ${loSubjectName} subject. To change the topic of this LO, try again in a course of that subject.`}
                  indicate
                  inModal
                  position={PositionEnum.Bottom}
                />
              </div>
            )}
          </div>
        )}
        <div className="lo-builder__form-item">
          <label htmlFor="title">
            LO Text
          </label>
          <textarea
            id="title"
            name="title"
            className="lo-builder__title"
            placeholder="Compose the text of your Learning Objective."
            onChange={({ target: { value } }) => setLoTitle(value)}
            required
            value={loTitle}
          />
          {loTitle !== '' && loTitle.length < minTitleLength && (
            <div className="form-error lo-builder__error">
              LO Title must be at least {minTitleLength} characters
            </div>
          )}
        </div>
        {isCopyingExistingLo && (
          <div className="lo-builder__copy-lo-warning">
            "Making a copy" of this LO means that we'll automatically link the new LO to all questions associated with the original LO.
            You'll want to double check question alignment if you change the substance of the LO.
            If you'd rather start fresh, click "Create Custom LO" instead.
          </div>
        )}
        <LoadingButton
          className="lo-builder__save"
          disabled={!isValid}
          onClick={saveLo}
          text={initEditState ? 'Save Learning Objective' : 'Create Learning Objective'}
          loadingText='Saving Learning Objective'
          loading={isProcessing}
        />
      </div>
    </div>
  );
}

export default LoBuilder;
