import React, { SVGProps } from 'react';
import PropTypes from 'prop-types';
import styles from 'style-config.scss';

interface AlignmentPieProps extends SVGProps<SVGSVGElement> {
  blueSlice?: boolean
  coralSlice?: boolean
  greenSlice?: boolean
}

const AlignmentPie = ({
  blueSlice,
  coralSlice,
  greenSlice,
  ...props
}: AlignmentPieProps) => {
  return (
    <svg
      viewBox="0 0 139 139"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      {...props}
    >
      <g id="wedge-coral" onMouseOver={(e) => console.debug('coral hover', e)}>
        <path
          d="M300.53 314.23c-12.303 18.747-33.511 31.137-57.59 31.137-24.512 0-46.049-12.84-58.245-32.154l58.245-32.789 57.59 33.806Z"
          style={{ fill: styles.coral, opacity: coralSlice ? 1 : 0.1 }}
          transform="translate(-173.381 -206.627)"
          id="wedge-blue-fill"
        />
        <path
          d="M300.53 314.23c-12.303 18.747-33.511 31.137-57.59 31.137-24.512 0-46.049-12.84-58.245-32.154l58.245-32.789 57.59 33.806Zm-8.751 1.82c-11.525 14.22-29.127 23.317-48.839 23.317-20.107 0-38.019-9.466-49.525-24.178l49.463-27.845 48.901 28.706Z"
          style={{ fill: styles.coral }}
          transform="translate(-173.381 -206.627)"
          id="wedge-blue-outline"
        />
      </g>
      <g id="wedge-blue" onMouseOver={(e) => console.debug('blue hover', e)}>
        <path
          d="M300.53 314.23c-12.303 18.747-33.511 31.137-57.59 31.137-24.512 0-46.049-12.84-58.245-32.154l58.245-32.789 57.59 33.806Z"
          style={{ fill: styles.purple, opacity: blueSlice ? 1 : 0.1 }}
          transform="rotate(119.583 216.33 122.405)"
          id="wedge-blue-fill"
        />
        <path
          d="M300.53 314.23c-12.303 18.747-33.511 31.137-57.59 31.137-24.512 0-46.049-12.84-58.245-32.154l58.245-32.789 57.59 33.806Zm-8.751 1.82c-11.525 14.22-29.127 23.317-48.839 23.317-20.107 0-38.019-9.466-49.525-24.178l49.463-27.845 48.901 28.706Z"
          style={{ fill: styles.purple }}
          transform="rotate(119.583 216.33 122.405)"
          id="wedge-coral-outline"
        />
      </g>
      <g id="wedge-green" onMouseOver={(e) => console.debug('green hover', e)}>
        <path
          d="M300.53 314.23c-12.303 18.747-33.511 31.137-57.59 31.137-24.512 0-46.049-12.84-58.245-32.154l58.245-32.789 57.59 33.806Z"
          style={{ fill: styles.aquamarine, opacity: greenSlice ? 1 : 0.1 }}
          transform="scale(1 -1) rotate(-60.417 -140.883 252.436)"
          id="wedge-green-fill"
        />
        <path
          d="M300.53 314.23c-12.303 18.747-33.511 31.137-57.59 31.137-24.512 0-46.049-12.84-58.245-32.154l58.245-32.789 57.59 33.806Zm-8.751 1.82-48.901-28.706-49.463 27.845c11.506 14.712 29.418 24.178 49.525 24.178 19.712 0 37.314-9.097 48.839-23.317Z"
          style={{ fill: styles.aquamarine }}
          transform="scale(1 -1) rotate(-60.417 -140.883 252.436)"
          id="wedge-green-outline"
        />
      </g>
    </svg>
  );
};

AlignmentPie.propTypes = {
  coralSlice: PropTypes.bool,
  greenSlice: PropTypes.bool,
  blueSlice: PropTypes.bool,
};

export default AlignmentPie;
