import apiNext from 'api-next';
import { AppDispatch } from 'store';
import addTopicToClassSession from 'store/actions/addTopicToClassSession';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';
import activeSlice from 'store/slices/active';
import { Store } from 'types/store.types';

//this actions adds an LO to a classSession, using the default topic for that LO or the topic used for that same LO in previous sessions.
//it is also capable of auto adding the topic and the unit if need be
export default function addLoToClassSession(learningObjectiveId: number, classSessionId: number, autoAddTopicToClassSession = true) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active } = getStore();
    const {
      learningObjectives,
      classSessionTopics,
      classSessionLearningObjectives,
    } = active;

    //is this LO already used in another classSession? If so, use that topicId, if not, use the topidId from the LO in the library
    const loToBeAdded = learningObjectives.find(lo => lo.id === learningObjectiveId) as LearningObjectiveApi;
    const loInOtherClassSession = classSessionLearningObjectives.find(cslo =>
      cslo.learningObjectiveId === learningObjectiveId && cslo.classSessionId !== classSessionId
    );

    const newTopicId = !!loInOtherClassSession ? loInOtherClassSession.topicId : loToBeAdded.topicId;
    const classSessionTopic = classSessionTopics.find(cst =>
      cst.classSessionId === classSessionId && cst.topicId === newTopicId
    );

    //if the topic has not been added to the classSession it will need to be added
    if (!classSessionTopic) {
      if (autoAddTopicToClassSession) {
        console.info('add the topic to class session', classSessionId);
        await dispatch(addTopicToClassSession(newTopicId, classSessionId));
      } else {
        console.debug('Cannot add this LO. classSessionTopic does not exist and autoAddTopic is false');
        return;
      }
    }

    //validate the classSessionTopic now exists
    const updatedState = getStore();
    const nowHasCst = updatedState.active.classSessionTopics.find(cst => cst.topicId === newTopicId && cst.classSessionId === classSessionId);
    if (!nowHasCst) {
      console.debug('classSessionTopic has not been successfully added, cannot add LO to classSession');
      return;
    }
    // JP: This block was never actually doing anything of value but I'm preserving it because it's the weirdest thing I've seen in our codebase
    // trying to modify the previousId value of classSessionLearningObjectives (an ARRAY), what was this supposed to do?
    //what previousId should be used? What starting order for instant UI
    // const losForTopicInSession = classSessionLearningObjectives.filter((cslo) => cslo.topicid === newClassSessionLo.topicId && cslo.classSessionId === classSessionId);
    // classSessionLearningObjectives.previousId = losForTopicInSession.length ? losForTopicInSession[losForTopicInSession.length - 1].id : 0;

    //make the change on the server:
    const serverClassSessionLo = await apiNext.createClassSessionLo({
      classSessionId,
      learningObjectiveId,
      topicId: newTopicId,
    });

    //Update our local store with the server version
    //TODO: There is a sorting issue here and full array of CSLOs should really be replaced. See CA-433 in Jira
    return dispatch(activeSlice.actions.addActiveClassSessionLearningObjectives({ ...serverClassSessionLo }));

  })();
}
