import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

function useDocumentTitle(title: string, override = false): void {
  useIsomorphicLayoutEffect(() => {
    if (override) {
      window.document.title = title;
    } else {
      window.document.title = `${title} - Codon Learning`;
    }
  }, [title]);
}

export default useDocumentTitle;
