import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AssessTypeEnum, SummativeAssessmentApi } from 'types/backend/assessments.types';
import { CreateAssessmentBody, CreateSummativeAssessmentBody } from 'types/common.types';
import { AppDispatch } from 'types/store.types';

export default function createAssessment(assessmentData: CreateAssessmentBody | CreateSummativeAssessmentBody) {
  return (dispatch: AppDispatch) => (async () => {
    //make the change on the server:
    const createdAssessment = await apiNext.createAssessment(assessmentData);
    dispatch(activeSlice.actions.addActiveAssessment(createdAssessment));

    //update local with server version:
    if (assessmentData.assessType === AssessTypeEnum.Summative) {
      const { prep, practiceTest } = createdAssessment as SummativeAssessmentApi;
      dispatch(activeSlice.actions.addActiveAssessment(prep));
      dispatch(activeSlice.actions.addActiveAssessment(practiceTest));
      dispatch(activeSlice.actions.addActiveSummativeAssessmentSupplement({
        id: createdAssessment.id,
        prepAssessmentId: prep.id,
        practiceAssessmentId: practiceTest.id,
      }));
    }

    return createdAssessment;
  })();
}
