import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import {
  calculateAqmBreakdown,
  getQuestionHashFromAqmBreakdown,
  QuestionDataHash,
  summativeShouldFill,
} from 'utils/alignmentFunctions';
import { formatPlural, getAssessTypeShortName } from 'utils/commonFormattingFunctions';
import AlignmentPie from 'shared-components/AlignmentPie/AlignmentPie';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { MappedAssessmentQuestion } from 'types/common.types';
import { Store } from 'types/store.types';
import { InstructorCoursePath } from 'types/instructor.types';


export interface AlignmentItem {
  clo: Array<EnrichedCourseLearningObjective>
  aqms: Array<MappedAssessmentQuestion>
  loString: string
}

interface DailyPlannerAlignmentItemProps {
  alignmentData: AlignmentItem
  classSessionId: number
}

const DailyPlannerAlignmentItem = ({ alignmentData, classSessionId }: DailyPlannerAlignmentItemProps) => {
  const summativeAssessmentSupplements = useSelector((store: Store) => store.active.summativeAssessmentSupplements);
  const { aqms, loString } = alignmentData;
  const [expanded, setExpanded] = useState(true);

  // break the AQMs down by category for the 3 subsections of the expando
  const aqmBreakdown = calculateAqmBreakdown(aqms);
  const { preclass, homework, summative } = aqmBreakdown;
  const { preclassQuestionsByClo, homeworkQuestionsByClo, summativeQuestionsByClo } = getQuestionHashFromAqmBreakdown(aqmBreakdown);

  const renderSubsection = (assessType: AssessTypeEnum, questionHash: QuestionDataHash, sectionFilled?: boolean) => {
    const assessmentIds = Object.keys(questionHash);
    const isSummative = assessType === AssessTypeEnum.Summative;
    // if rendering Summative section, use the sectionFilled argument instead of inferring from assessments count
    const filled = isSummative
      ? sectionFilled
      : !!assessmentIds.length;
    return (
      <div
        className="alignment-item__list-item"
        data-assesstype={assessType}
        key={assessType}
      >
        <div
          className="alignment-item__list-item-indicator"
          data-assesstype={assessType}
          data-filled={filled}
        >
          &nbsp;
        </div>
        <div className="alignment-item__list-item-details">
          <div className="alignment-item__list-item-assessType">
            {getAssessTypeShortName(assessType)}{assessType === AssessTypeEnum.Preclass && ' Assessment'}
          </div>
          <div className="alignment-item__list-item-info">
            {!assessmentIds.length ? '0 items' : assessmentIds.map((assessmentIdKey) => {
              const { count, assessmentName } = questionHash[assessmentIdKey];
              const { id: summativeIdForAssessmentLink } = summativeAssessmentSupplements.find((supp) => Object.values(supp).includes(assessmentIdKey)) || {};
              const assessmentIdForLink = isSummative ? summativeIdForAssessmentLink : assessmentIdKey;
              const assessmentLink = `${InstructorCoursePath.AssessmentBuilder}?assessment=${assessmentIdForLink}${!!classSessionId ? `&class-session=${classSessionId}` : ''}`;
              return (
                <div key={`item-info_${assessmentIdKey}`}>
                  {count} {formatPlural('item', count)} in <Link to={assessmentLink}>{assessmentName}</Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const summativeFilled = summativeShouldFill(summative);

  return (
    <div className="alignment-item">
      <div className="alignment-item__header" data-expanded={expanded}>
        <button onClick={() => setExpanded(!expanded)} className="alignment-item__header-expand">
          {expanded
            ? <FaCaretDown size={18} />
            : <FaCaretRight size={18} />
          }
        </button>
        <div className="alignment-item__header-pie">
          <AlignmentPie
            greenSlice={!!preclass.length}
            coralSlice={!!homework.length}
            blueSlice={summativeFilled}
          />
        </div>
        <div className="alignment-item__header-lo-string">
          {loString}
        </div>
      </div>
      {expanded && (
        <div className="alignment-item__content expanded">
          <div className="alignment-item__list">
            {renderSubsection(AssessTypeEnum.Preclass, preclassQuestionsByClo)}
            {renderSubsection(AssessTypeEnum.Homework, homeworkQuestionsByClo)}
            {renderSubsection(AssessTypeEnum.Summative, summativeQuestionsByClo, summativeFilled)}
          </div>
        </div>
      )}
    </div>
  );
};

DailyPlannerAlignmentItem.propTypes = {
  alignmentData: PropTypes.object.isRequired,
  classSessionId: PropTypes.number.isRequired,
};

export default DailyPlannerAlignmentItem;
