import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FaRegStar } from 'react-icons/fa';
import { IoArrowRedoSharp, IoArrowUndoSharp } from 'react-icons/io5';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { GiBookshelf } from 'react-icons/gi';
import { DateTime } from 'luxon';

import getClassDaysCoveredFromClassSessionIds from 'utils/getClassDaysCoveredFromClassSessionIds';
import { canShowAssessmentQuestionsInStudyPath, sprexIsAvailable } from '../../sharedStudyPathFunctions';
import { useAppSelector } from 'store';
import BetterModal from 'shared-components/BetterModal/BetterModal';
import Icon, { IconEnum } from 'shared-components/Icon';
import CardFlip from './CardFlip';
import SkipForNow from './SkipForNow';
import StudyPathAssessmentTaker from '../StudyPathAssessmentTaker/StudyPathAssessmentTaker';
import StudyPathReadinessContainer from '../StudyPathAssessmentTaker/StudyPathReadinessContainer';
import StudyPathTopicCardReadinessItem from './StudyPathTopicCardReadinessItem';
import StudyPathTopicCardLoItem from './StudyPathTopicCardLoItem';

import { EnrichedStudentTopicCard, HandleCardActionData } from '../../StudyPathController.types';
import { StudentTopicCardLearningObjectiveAssessmentQuestion } from 'types/backend/studentStudyPaths.types';
import { StudentAssessmentApi } from 'types/backend/studentAssessments.types';
import { StudentAppContext } from 'student/StudentBase';
import { CheckpointColumn, ClarityEnum } from 'types/backend/shared.types';
import './StudyPathTopicCard.scss';

enum WhichSPModalEnum {
  SPAT = 'spat',
  REX = 'rex',
}

interface ModalData {
  whichModal: WhichSPModalEnum
  showModal: boolean
  targetL8yId: string
  launchAssessmentId: string
}

function StudyPathTopicCard({
  checkpointType,
  handleCardSkip,
  onModalClose,
  topicCardData,
}: {
  checkpointType: CheckpointColumn
  handleCardSkip: (data: HandleCardActionData) => void
  onModalClose: () => void
  topicCardData: EnrichedStudentTopicCard
}) {
  const {
    topic,
    learningObjectives,
    studentTopicCardId,
    topicCardId,
    classSessionIds,
    l8ySessionId,
    readinessAssessments,
  } = topicCardData;
  const { stringId: topicStringId, name: topicName } = topic;
  const { setStudentAppState } = useContext(StudentAppContext);
  const classSessions = useAppSelector((store) => store.active.classSessions);
  const studentAssessments = useAppSelector((store) => store.active.studentAssessments);
  const availableSprexAssessments = readinessAssessments.filter((rexAssessment) => sprexIsAvailable(rexAssessment, studentAssessments));
  const hasReadiness = !!availableSprexAssessments.length;
  const defaultToFlipped = hasReadiness && !learningObjectives.some(({ assessmentQuestions }) => !!assessmentQuestions.length);

  const [modalData, setModalData] = useState<ModalData>({
    whichModal: WhichSPModalEnum.SPAT,
    showModal: false,
    targetL8yId: '',
    launchAssessmentId: '',
  });

  const { showModal, targetL8yId, launchAssessmentId } = modalData;

  const [flipped, toggleFlipped] = useState(false);
  const [isOpen, toggleIsOpen] = useState(false);
  const handleOpen = () => {
    if (!isOpen && defaultToFlipped) {
      toggleFlipped(true);
    }
    toggleIsOpen(!isOpen);
  };

  const handleTopicCardSkip = async () => handleCardSkip({
    checkpoint: checkpointType,
    studentTopicCardId,
    topicCardId,
  });

  const handleSpatLaunch = (whichModal: WhichSPModalEnum, l8yId: string, assessmentId = '') => {
    setStudentAppState((prev) => ({ ...prev, modalIsOpen: true }));
    setModalData({
      launchAssessmentId: assessmentId,
      showModal: true,
      targetL8yId: l8yId,
      whichModal,
    });
  };

  const handleSpatClose = () => {
    setStudentAppState((prev) => ({ ...prev, modalIsOpen: false }));
    setModalData((prev) => ({
      ...prev,
      showModal: false,
      targetL8yId: '',
      launchAssessmentId: '',
    }));
    onModalClose();
  };

  const getClarityAndRecap = (assessmentQuestions: Array<StudentTopicCardLearningObjectiveAssessmentQuestion>) => {
    const now = DateTime.local();
    const statusHash = assessmentQuestions.reduce((acc: Array<{ pointsAvailableToRecap: number; isMuddy: boolean}>, cur) => {
      const { studentAssessmentQuestion } = cur;
      if (studentAssessmentQuestion) {
        const studentAssessment = studentAssessments.find(({ assessmentId }) => assessmentId === cur.assessment.id) as StudentAssessmentApi;
        if (canShowAssessmentQuestionsInStudyPath(cur, studentAssessment, now)) {
          const { pointsAvailableToRecap } = studentAssessmentQuestion;
          const { clarity } = studentAssessmentQuestion.latestStudentAssessmentQuestionAttempt || {};
          const statusBlob = {
            pointsAvailableToRecap: pointsAvailableToRecap || 0,
            isMuddy: clarity === ClarityEnum.Muddy,
          };
          return [
            ...acc,
            statusBlob,
          ];
        }
      }
      return acc;
    }, []);
    const showMuddy = statusHash.some(({ isMuddy }: { isMuddy: boolean }) => isMuddy === true);
    const showRecap = statusHash.some(({ pointsAvailableToRecap }) => pointsAvailableToRecap > 0);
    return {
      showMuddy,
      showRecap,
      statusHash,
    };
  };

  const classNumbers = getClassDaysCoveredFromClassSessionIds(classSessionIds, classSessions);
  const classDays = classNumbers.map((csId: number) => `Class ${csId}`).join(' ');
  const showSkip = [CheckpointColumn.Review, CheckpointColumn.Prep].includes(checkpointType);
  const topicCardStatus = learningObjectives.map(({ assessmentQuestions }) => getClarityAndRecap(assessmentQuestions));

  const showCardMuddy = topicCardStatus.some(({ showMuddy }) => showMuddy === true);
  const showCardRecap = topicCardStatus.some(({ showRecap }) => showRecap === true);

  const getCardStatusTitle = () => {
    let cardStatusString;
    if (showCardRecap && showCardMuddy) {
      cardStatusString = 'muddy questions & recap points available';
    } else if (showCardRecap) {
      cardStatusString = 'recap points available';
    } else if (showCardMuddy) {
      cardStatusString = 'muddy questions';
    } else {
      return '';
    }
    return `This card has ${cardStatusString}.`;
  };

  return (
    <div data-stringid={topicStringId} className={`study-path-topic-card ${isOpen ? 'open' : 'closed'}`} data-hasreadiness={hasReadiness}>
      <CardFlip containerClassName="study-path-topic-card__flip-container" cardId={`${topicCardId}` || 'THISSHOULDNOTHAPPEN'} isFlipped={flipped}>
        <div className="study-path-topic-card__front">
          <button className="study-path-topic-card__header" onClick={() => handleOpen()} aria-expanded={isOpen}>
            <h4 className="hreset header-text" tabIndex={-1}>
              <div className="class-number">{ classDays }</div>
              <div className="topic-name">{ topicName }</div>
            </h4>
            <div className="header-card-status">
              <div className="header-card-status-icons" title={getCardStatusTitle()}>
                {!isOpen && showCardMuddy && (
                  <span className="topic-card-status-icon">
                    <Icon size={20} which={IconEnum.Muddy} />
                  </span>
                )}
                {!isOpen && showCardRecap && (
                  <span className="topic-card-status-icon">
                    <FaRegStar size={20} />
                  </span>
                )}
              </div>
              <div className="header-expand-button" title="Expand Card" aria-hidden="true">
                {isOpen ? 'Hide' : 'View'}
              </div>
            </div>
          </button>
          {isOpen && (
            <div className="study-path-topic-card__expanded-content">
              <div>
                {learningObjectives.map((lo) => (
                  <StudyPathTopicCardLoItem
                    key={`${lo.id}_${lo.loNumber}`}
                    learningObjective={lo}
                    checkpointType={checkpointType}
                    launchAssessmentTaker={(l8yId: string) => handleSpatLaunch(WhichSPModalEnum.SPAT, l8yId)}
                  />
                ))}
              </div>
              <div className="study-path-topic-card__gutter">
                <div>
                  {showSkip && <SkipForNow handleSkip={handleTopicCardSkip} />}
                </div>
                {hasReadiness && (
                  <button onClick={() => toggleFlipped(!flipped)} className="readiness-link">
                    Review resources
                    <IoArrowRedoSharp />
                  </button>
                )}
              </div>
              <button className="study-path-topic-card__flipper" title="Flip this card" onClick={() => toggleFlipped(!flipped)}>
                <BsChevronCompactRight />
                <BsChevronCompactRight />
                <BsChevronCompactRight />
              </button>
            </div>
          )}
        </div>
        <div className="study-path-topic-card__back">
          <div className="topic-card-flipside">
            <button className="study-path-topic-card__flipper" title="Flip this card" onClick={() => toggleFlipped(!flipped)}>
              <BsChevronCompactLeft />
              <BsChevronCompactLeft />
              <BsChevronCompactLeft />
            </button>
            <div className="flipside-content">
              <h4 className="hreset flipside-content__resources-title" tabIndex={-1}>
                <div className="flipside-content__resources-title__icon"><GiBookshelf size={30} /></div>
                Resources for {topicName}
              </h4>
              {availableSprexAssessments.map((readinessAssessment) => (
                <StudyPathTopicCardReadinessItem
                  cardLearningObjectives={learningObjectives}
                  key={`flipside_${readinessAssessment.id}`}
                  launchAssessmentTaker={(l8yId: string, assessmentId: string) => handleSpatLaunch(WhichSPModalEnum.REX, l8yId, assessmentId)}
                  readinessAssessment={readinessAssessment}
                />
              ))}
            </div>
            <div className="flipside-footer">
              <button onClick={() => toggleFlipped(!flipped)} className="flip-link">
                <IoArrowUndoSharp />&nbsp;Retry questions
              </button>
            </div>
          </div>
        </div>
      </CardFlip>
      <BetterModal
        className="study-path-topic-card__modal"
        isShowing={showModal}
        hide={handleSpatClose}
        trapFocus
      >
        {modalData.whichModal === WhichSPModalEnum.SPAT
          ? (
            <StudyPathAssessmentTaker
              handleDone={handleSpatClose}
              checkpointType={checkpointType}
              l8ySessionId={l8ySessionId}
              learningObjectives={learningObjectives}
              targetL8yId={targetL8yId}
              topicName={topicName}
            />
          ) : (
            <StudyPathReadinessContainer
              assessmentId={launchAssessmentId}
              handleDone={handleSpatClose}
              l8ySessionId={l8ySessionId}
              readinessAssessments={readinessAssessments}
              targetL8yId={targetL8yId}
            />
          )
        }
      </BetterModal>
    </div>
  );
}

StudyPathTopicCard.propTypes = {
  topicCardData: PropTypes.shape({
    classSessionIds: PropTypes.array,
    studentTopicCardId: PropTypes.number,
    topicCardId: PropTypes.number,
    topic: PropTypes.shape({
      name: PropTypes.string.isRequired,
      stringId: PropTypes.string.isRequired,
    }).isRequired,
    learningObjectives: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      loNumber: PropTypes.string.isRequired,
      assessmentQuestions: PropTypes.array.isRequired,
    }).isRequired).isRequired,
    l8ySessionId: PropTypes.string.isRequired,
    readinessAssessments: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default StudyPathTopicCard;
