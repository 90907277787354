import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { FaTrashAlt, FaGripLinesVertical } from 'react-icons/fa';
import { BsCalendar, BsPencil } from 'react-icons/bs';
import { HiArrowUturnLeft, HiArrowUturnRight } from 'react-icons/hi2';
import { DateTime } from 'luxon';
import { useAppSelector } from 'store';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { LoSelectorContext } from '../../CourseLoSelectorController';
import ClassSessionLoListTopicObjectives from './ClassSessionLoListTopicObjectives';
import MoveTopicToClassSession from './MoveTopicToClassSession';
import KebabMenu from 'shared-components/KebabMenu/KebabMenu';
import SimpleInput from 'shared-components/SimpleInput/SimpleInput';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { TopicApi } from 'types/backend/topics.types';
import { LoActionDispatch } from '../../CourseLoSelector.types';
import { BetterClassSession, BetterClassSessionTopic } from 'store/selectors/retrieveBetterClassSessions';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { DropdownOption } from 'instructor/components/Dropdown/Dropdown.types';

function ClassSessionLoListTopic({
  classSessions,
  currentClassSessionId,
  currentTopic,
  loAction,
  nextClassSessionId,
  onMoveTopic,
  onRemoveTopic,
  prevClassSessionId,
  topicIndex,
  topicLos,
  updateUserTopic,
}: {
  classSessions: Array<BetterClassSession>
  currentClassSessionId: number
  currentTopic: BetterClassSessionTopic
  loAction: LoActionDispatch
  nextClassSessionId: number
  onMoveTopic: (classSessionTopicId: number, newClassSessionId: number) => void
  onRemoveTopic: (topicId: number) => void
  prevClassSessionId: number
  topicIndex: number
  topicLos: Array<EnrichedCourseLearningObjective>
  updateUserTopic: (updatedTopic: TopicApi) => void
}) {

  const { isProcessing } = useContext(LoSelectorContext);
  const user = useAppSelector((store) => store.user);
  const [isEditingTopic, setIsEditingTopic] = useState(false);
  const [isMovingTopic, setIsMovingTopic] = useState(false);

  const { id: topicId, name, type, classSessionTopicId, stringId, userId } = currentTopic;
  const topicBelongsToThisUser = type === LibraryTypeEnum.User && userId === user.id;

  const otherClassSessions = classSessions.reduce((acc, cur) => {
    if (cur.id !== currentClassSessionId) {
      acc.push({
        value: cur.id,
        label: `Class ${cur.classNumber} (${DateTime.fromISO(cur.classDate).toFormat(DateFormatEnum.WeekdayMonthDate)})`,
      });
    }
    return acc;
  }, [] as Array<DropdownOption<number>>);

  const editTopicName = () => topicBelongsToThisUser && setIsEditingTopic(true);

  const loString = !!topicLos.length ? ' and LOs' : '';

  const menuItems = [
    {
      label: `Remove Topic${loString} from Class Day`,
      icon: <FaTrashAlt />,
      onClick: () => onRemoveTopic(topicId),
    },
    {
      icon: <HiArrowUturnLeft />,
      label: `Move Topic${loString} to Previous Day`,
      onClick: () => onMoveTopic(classSessionTopicId, prevClassSessionId),
      show: !!prevClassSessionId,
    },
    {
      label: `Move Topic${loString} to Next Day`,
      icon: <HiArrowUturnRight />,
      onClick: () => onMoveTopic(classSessionTopicId, nextClassSessionId),
      show: !!nextClassSessionId,
    },
    {
      label: `Move Topic${loString} to Another Day`,
      icon: <BsCalendar />,
      onClick: () => setIsMovingTopic(true),
      show: !!otherClassSessions.length,
    },
    {
      label: 'Edit Topic Name',
      icon: <BsPencil />,
      onClick: editTopicName,
      show: topicBelongsToThisUser,
    },
  ];

  return (
    <Draggable key={topicId} draggableId={`${topicId}`} index={topicIndex}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={`lo-sel-card__topic-item ${!topicBelongsToThisUser ? 'template-topic' : 'custom-topic'}`}
          style={provided.draggableProps.style}
          data-topicid={topicId}
          data-topic-stringid={stringId}
        >
          <div>
            <div className="lo-sel-card__topic-header">
              <div className="lo-sel-card__topic-details">
                <FaGripLinesVertical className="lo-sel-card__topic-handle" />
                <button
                  className="lo-sel-card__topic-details-name"
                  onClick={editTopicName}
                >
                  {topicBelongsToThisUser && (
                    <div className="lo-sel-card__edit-hint">
                      <BsPencil />
                    </div>
                  )}
                  {isEditingTopic && topicBelongsToThisUser ? (
                    <SimpleInput
                      key={`topic-${topicId}`}
                      defaultValue={name}
                      placeholder={name}
                      onSave={(newTitle: string) => updateUserTopic({ ...currentTopic, name: newTitle })}
                      setIsEditing={setIsEditingTopic}
                    />
                  ) : (
                    <span>{name}</span>
                  )}
                </button>
                <KebabMenu
                  className="lo-selector-card__kebab-menu"
                  disabled={isProcessing}
                  items={menuItems}
                />
              </div>
              {isMovingTopic && (
                <MoveTopicToClassSession
                  classSessionTopicId={classSessionTopicId}
                  loString={loString}
                  otherClassSessions={otherClassSessions}
                  onCancel={() => setIsMovingTopic(false)}
                  onMoveTopic={onMoveTopic}
                />
              )}
            </div>
            <ClassSessionLoListTopicObjectives
              loAction={loAction}
              topicLos={topicLos}
              topicId={topicId}
            />
          </div>
        </div>
      )}
    </Draggable>
  );
}

ClassSessionLoListTopic.propTypes = {
  classSessions: PropTypes.array.isRequired,
  currentClassSessionId: PropTypes.number.isRequired,
  currentTopic: PropTypes.object.isRequired,
  loAction: PropTypes.func.isRequired,
  nextClassSessionId: PropTypes.number,
  onMoveTopic: PropTypes.func.isRequired,
  onRemoveTopic: PropTypes.func.isRequired,
  prevClassSessionId: PropTypes.number,
  topicIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  topicLos: PropTypes.array,
  updateUserTopic: PropTypes.func.isRequired,
};

export default ClassSessionLoListTopic;
