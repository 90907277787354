import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import apiNext from 'api-next';
import { saveLogMessage } from 'utils/saveLogMessage';
import { useAppDispatch } from 'store';
import userSlice from 'store/slices/user';
import loadUserEnrollments from 'store/actions/loadUserEnrollments';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import { AdminRoles, InstructorRoles, StudentRoles } from 'types/backend/roles.types';
import { GenericObject } from 'types/backend/shared.types';
import { InstructorCoursePath } from 'types/instructor.types';

const getLtiRedirectPath = (role: string, courseId?: string) => {
  const isInstructor = role === InstructorRoles.Instructor;
  const prefix = isInstructor ? '/instructor' : '/student';
  const coursePath = !!courseId ? `/course/${courseId}` : '';
  const postfix = isInstructor ? `/${InstructorCoursePath.DailyPlanner}` : '';
  return `${prefix}${coursePath}${postfix}`;
};

export default function LtiLaunch() {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleLtiLaunch = useCallback(async () => {
    try {
      const ltiResponse = await apiNext.ltiLaunch();
      if (!!ltiResponse) {
        const { email, courseId, ltiUserId } = ltiResponse;
        const ltiUser = await apiNext.ltiLogin(email, ltiUserId);
        window.sessionStorage.setItem('lti', JSON.stringify(ltiResponse));
        if (ltiUser) {
          const userRole = getLtiRole(ltiResponse);
          const redirectPath = getLtiRedirectPath(userRole, courseId);
          dispatch(userSlice.actions.setUser(ltiUser));
          await dispatch(loadUserEnrollments(ltiUser.id));
          // for some reason this DOES NOT WORK without a timeout
          setTimeout(() => {
            console.debug(`redirecting to ${redirectPath} for LTI`);
            history.push(redirectPath);
          }, 1000);
        }
      } else {
        throw new Error('lti-log FE: Unauthorized LTI access');
      }
    } catch (error: any) {
      await saveLogMessage(error.message);
      console.error(error.message);
      history.push('/login');
    }
  }, [dispatch, history]);

  useEffect(() => {
    handleLtiLaunch();
  }, [handleLtiLaunch]);

  return (
    <LoadingSpinner loadingMessage="Launching Codon" />
  );
}


export const getLtiRole = (lti: GenericObject): string => {
  // default less permission role
  const role = StudentRoles.Student;
  const ltiRoles: Array<string> = lti['https://purl.imsglobal.org/spec/lti/claim/roles'];

  if (!ltiRoles?.length) {
    // most likely will never happen as backend will fail on missing LTI roles param (required one)
    console.error('Not able to get value from LTI roles');
    return role;
  }

  const isInstructor = ltiRoles.some(value => {
    return Object.values(InstructorRoles).find(itemRole => value.toLowerCase().includes(itemRole));
  });

  if (isInstructor) {
    return InstructorRoles.Instructor;
  }

  const isStudent = ltiRoles.some(value => {
    return Object.values(StudentRoles).find(itemRole => value.toLowerCase().includes(itemRole));
  });

  if (isStudent) {
    return StudentRoles.Student;
  }

  const isAdmin = ltiRoles.some(value => {
    return Object.values(AdminRoles).find(itemRole => value.toLowerCase().includes(itemRole));
  });

  if (isAdmin) {
    // NOTE: returning instructor, as App supports only 2 routes: student or instructor
    console.debug('LTI admin role switched to instructor role');
    return InstructorRoles.Instructor;
  }

  return role;
};
