import React from 'react';
import PropTypes from 'prop-types';

import BetterButton from 'shared-components/BetterButton/BetterButton';
import TextButton from 'shared-components/BetterButton/TextButton';
import LoadingButton from 'shared-components/LoadingButton/LoadingButton';
import './AssessmentBuilderActionBar.scss';

export enum ItemTypeEnum {
  PrimaryBtn = 'abab-primary-btn',
  SecondaryBtn = 'abab-secondary-btn',
  TextBtn = 'abab-text-btn',
  LoadingBtn = 'abab-loading-btn',
}

interface ActionBarItem {
  className: string
  disabled?: boolean
  itemId: string
  itemText: string
  itemType: ItemTypeEnum
  loading?: boolean
  loadingText?: string
  onClick: (btnId: string) => void
  show?: boolean
}

interface AssessmentBuilderActionBarProps {
  leftSideButtons?: Array<ActionBarItem>
  rightSideButtons?: Array<ActionBarItem>
}

const AssessmentBuilderActionBar = ({
  leftSideButtons,
  rightSideButtons,
}: AssessmentBuilderActionBarProps) => {
  const renderActionBarItem = (item: ActionBarItem) => {
    const {
      className,
      disabled = false,
      itemId,
      itemText,
      itemType,
      onClick,
      show = true,
    } = item;
    if (!show) {
      return null;
    }
    switch (itemType) {
      case ItemTypeEnum.TextBtn: {
        return (
          <TextButton
            className={`${itemType} ${className}`}
            disabled={disabled}
            key={itemId}
            onClick={() => onClick(itemId)}
          >
            {itemText}
          </TextButton>
        );
      }
      case ItemTypeEnum.PrimaryBtn:
      case ItemTypeEnum.SecondaryBtn: {
        return (
          <BetterButton
            className={`${itemType} ${className}`}
            disabled={disabled}
            key={itemId}
            onClick={() => onClick(itemId)}
            primary={itemType === ItemTypeEnum.PrimaryBtn}
            secondary={itemType === ItemTypeEnum.SecondaryBtn}
            text={itemText}
          />
        );
      }
      case ItemTypeEnum.LoadingBtn: {
        const { loading, loadingText } = item;
        if (typeof loading === 'undefined' || typeof loadingText === 'undefined') {
          console.warn('Invalid item object passed for LoadingBtn', item);
          return null;
        }
        return (
          <LoadingButton
            className={`${itemType} ${className}`}
            disabled={disabled}
            key={itemId}
            loading={loading}
            loadingText={loadingText}
            onClick={() => onClick(itemId)}
            text={itemText}
            type="button"
          />
        );
      }
      default:
        return null;
    }
  };
  return (
    <div className="assessment-builder__buttons assessment-builder-actionbar">
      <div className="actionbar-nav__left-side">
        {!!leftSideButtons && leftSideButtons.map(renderActionBarItem)}
      </div>
      <div className="actionbar-nav__right-side">
        {!!rightSideButtons && rightSideButtons.map(renderActionBarItem)}
      </div>
    </div>
  );
};

AssessmentBuilderActionBar.propTypes = {
  leftSideButtons: PropTypes.arrayOf(PropTypes.object),
  rightSideButtons: PropTypes.arrayOf(PropTypes.object),
};

export default AssessmentBuilderActionBar;
