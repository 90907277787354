import feathers from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';
import rest from '@feathersjs/rest-client';
import axios from 'axios';

// this is copied from feathers docs, pretty sure the rest client is only used for login at the moment
const restClient = rest(process.env.REACT_APP_API_NEXT_URL);
const app = feathers();

export const addExtraQueryParams = (extraParams: { ltiUserId: string }) => {
  const interceptorId = axios.interceptors.request.use((config) => {
    config.params = { ...config.params, ...extraParams };
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
  window.sessionStorage.setItem('interceptorId', JSON.stringify(interceptorId));
};

app.configure(restClient.axios(axios));

app.configure(auth({ storageKey: 'auth' }));

export default app;
