/**
 * useToggledArray
 *
 * Use this any time we have a list with items we want to toggle.
 * For example, keeping track of expanded state in a list of items
 */
import { Dispatch, SetStateAction, useState } from 'react';

export function useToggledArray<T extends string | number>(initialItemArray: Array<T>): [Array<T>, (value: T) => void, Dispatch<SetStateAction<Array<T>>>] {
  const [arrayOfItems, setArrayOfItems] = useState<Array<T>>(initialItemArray);
  const toggleArrayItem = (itemValue: T) => {
    setArrayOfItems((prev) => prev.includes(itemValue)
      ? prev.filter((id) => id !== itemValue)
      : [...prev, itemValue]
    );
  };

  return [arrayOfItems, toggleArrayItem, setArrayOfItems];
}
