import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';

import BetterButton from 'shared-components/BetterButton/BetterButton';
import BetterModal from 'shared-components/BetterModal/BetterModal';
import ConfirmationPromptContainer from 'shared-components/ConfirmationPrompt/ConfirmationPromptContainer';
import ToasterNotification from 'shared-components/ToasterNotification/ToasterNotification';
import QuestionPreview from 'shared-components/QuestionPreview/QuestionPreview';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import StudentScoresContainer from './components/StudentScoresContainer/StudentScoresContainer';
import { formatName, sortUsersByFullNames } from 'utils/commonFormattingFunctions';
import { errorMessages } from 'sharedStrings';

import apiNext, { SimplifiedErrorResponse } from 'api-next';
import retrieveEnrichedAssessmentsForStudentScores from 'store/selectors/retrieveEnrichedAssessmentsForStudentScores';

import { BasicQuestionForPreview } from 'utils/getAssessmentQuestionsMetadata';
import {
  ClarityEnum,
  GenericObject,
  ResultsFormatEnum,
  YesNo,
} from 'types/backend/shared.types';
import { Store } from 'types/store.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { AssessmentQuestionSummaryDataLevel2Api, AssessmentSummaryData, AssessmentSummaryViewEnum } from 'types/backend/assessmentSummaryData.types';
import { AssessmentScoreSyncApi } from 'types/backend/assessmentScoreSync.types';
import { EnrollmentApi } from 'types/backend/enrollments.types';
import { UserApi } from 'types/backend/users.types';
import { StudentResponsesForAssessmentQuestionRes, StudentResponseRes } from 'types/backend/studentResponses.types';
import { RoleEnum } from 'types/backend/roles.types';
import { FirstAttemptedEnum } from 'types/backend/studentAssessmentQuestions.types';
import { StudentRow } from 'types/backend/studentScoresData.types';
import {
  AssessmentQuestionAnswer,
  StudentScoresRow,
  AssessmentGridRow,
  StudentScoresViewEnum,
  StudentScoresStudent,
  EnrichedAssessmentForStudentScores,
  EnrichedAssessmentQuestionForStudentScores,
  ScoresType,
  StudentScoresRowScores,
  AttemptBarData,
} from './studentScores.types';
import { ConfirmationTypeEnum } from 'types/common.types';
import { CodonErrorCode } from 'types/backend/error.types';
import { GradingTypeTag } from 'types/backend/l8y.types';

export default function StudentScoresController() {
  const activeCourse = useSelector((store: Store) => store.active.course);
  const { id: courseId, name: courseName } = activeCourse || {};
  const assessments = useSelector(retrieveEnrichedAssessmentsForStudentScores);
  const user = useSelector((store: Store) => store.user);
  const [studentsAndEnrollments, setStudentsAndEnrollments] = useState([] as Array<StudentScoresStudent>);
  const [isLoading, setIsLoading] = useState(true);
  const [syncFailedMessages, setSyncFailedMessages] = useState<Array<{ assessmentName: string; message: string }>>([]);
  const [showSyncErrors, setShowSyncErrors] = useState(false);
  const [studentScores, setStudentScores] = useState([] as Array<StudentScoresRow>);
  const [studentScoresView, setStudentScoresView] = useState(StudentScoresViewEnum.ALL_ASSESSMENTS_VIEW);
  const [assessmentGrid, setAssessmentGrid] = useState([] as Array<AssessmentGridRow>);
  const [questions, setQuestions] = useState([] as Array<EnrichedAssessmentQuestionForStudentScores>);
  const [activeAssessment, setActiveAssessment] = useState({} as EnrichedAssessmentForStudentScores);
  const [showQuestionPreview, setShowQuestionPreview] = useState<BasicQuestionForPreview | false>(false);
  const [activeAssessmentQuestion, setActiveAssessmentQuestion] = useState({} as EnrichedAssessmentQuestionForStudentScores);
  const [studentAssessmentQuestionResponses, setStudentAssessmentQuestionResponses] = useState([] as Array<StudentResponseRes>);
  const [assessmentsSummaryData, setAssessmentsSummaryData] = useState([] as Array<AssessmentSummaryData>);

  function addAttemptBarDataToQuestions(questionsWithoutBarData: Array<EnrichedAssessmentQuestionForStudentScores>, attemptData: Array<AssessmentQuestionSummaryDataLevel2Api>) {
    const questionsWithBarData = questionsWithoutBarData.reduce((acc, question) => {
      const attemptsForQuestion = attemptData.filter(ad => ad.assessmentQuestionId === question.id);
      let muddyAttemptData: AttemptBarData | null = { numerator: 0, denominator: 0, percentage: 0 };
      let incorrectAttemptData: AttemptBarData | null = { numerator: 0, denominator: 0, percentage: 0 };
      if (question.sourceQuestion.gradingType === GradingTypeTag.Survey) {
        muddyAttemptData = null;
        incorrectAttemptData = null;
      } else if (attemptsForQuestion.length) {
        const denominator = attemptsForQuestion.filter(curAttempt => !!curAttempt.firstStudentAssessmentQuestionAttemptId).length;

        const muddyNumerator = attemptsForQuestion.reduce((num, curAttempt) => {
          num = curAttempt.firstStudentAssessmentQuestionAttemptClarity === ClarityEnum.Muddy ? num + 1 : num;
          return num;
        }, 0);
        const incorrectAttemptNumerator = attemptsForQuestion.reduce((num, curAttempt) => {
          num = curAttempt.firstStudentAssessmentQuestionAttemptIsCorrect === YesNo.No ? num + 1 : num;
          return num;
        }, 0);

        const muddyPercentage = muddyNumerator / denominator * 100 || 0;
        const incorrectAttemptPercentage = incorrectAttemptNumerator / denominator * 100 || 0;

        muddyAttemptData = { numerator: muddyNumerator, denominator, percentage: muddyPercentage };
        incorrectAttemptData = { numerator: incorrectAttemptNumerator, denominator, percentage: incorrectAttemptPercentage };
      }
      acc.push({ ...question, muddyAttemptData, incorrectAttemptData });
      return acc;
    }, [] as Array<EnrichedAssessmentQuestionForStudentScores>);
    return questionsWithBarData;
  }

  const loadStudentScoresMainGrid = useCallback(async (loadCourseId: string) => {
    // TODO get students from the store instead
    const courseEnrollments: Array<EnrollmentApi> = await apiNext.getCourseEnrollmentsByRole(loadCourseId, RoleEnum.Student);
    const unsortedStudents: Array<UserApi> = await apiNext.getUsersByCourseAndRole(loadCourseId, RoleEnum.Student);
    const students = sortUsersByFullNames(unsortedStudents);

    const studentScoresStudents: Array<StudentScoresStudent> = students.map(student => {
      const enrollment = courseEnrollments.find(ce => ce.userId === student.id) as EnrollmentApi;
      const studentName = formatName(student.lastName, student.firstName);
      return { id: student.id, enrollmentId: enrollment.id, studentName };
    });

    await apiNext.getStudentScoresData(loadCourseId).then((studentScoresDataResponse) => {
      const { error } = studentScoresDataResponse as SimplifiedErrorResponse;
      if (error) {
        throw new Error(`getStudentScoresData error - ${JSON.stringify(error)}`);
      }
      const studentScoresData = studentScoresDataResponse as Array<StudentRow>;
      // map to correct data format for main grid view
      const newStudentScores: Array<StudentScoresRow> = studentScoresData.map((ssd) => {
        const studentScoresRow = assessments.reduce((acc: Array<StudentScoresRowScores>, a) => {
          const scoreDetail = ssd[a.id];
          if (!scoreDetail && a.assessType !== AssessTypeEnum.Summative) {
            throw new Error(`Possible assessments and score mismatch, assessment: ${JSON.stringify(a)} is not present in studentScoresRow ${JSON.stringify(ssd)}.`);
          }
          if (scoreDetail !== null && typeof scoreDetail === 'object') {
            acc.push({
              assessmentId: a.id,
              ...scoreDetail,
            });
          }
          return acc;
        }, []);
        return {
          studentName: ssd.name,
          studentEnrollmentId: ssd.enrollmentId,
          scores: studentScoresRow,
        };
      });
      setStudentsAndEnrollments(studentScoresStudents);
      setStudentScores(newStudentScores);
      setIsLoading(false);
    });
  }, [assessments]);

  useEffect(() => {
    loadStudentScoresMainGrid(courseId);
  }, [courseId, loadStudentScoresMainGrid]);

  function generateCsvFilename(scoresType: ScoresType, assessmentName?: string, questionNumber?: number) {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const responsesSuffix = scoresType === ScoresType.Responses ? `_${assessmentName}_Question${questionNumber}` : '';
    const filename = `${courseName}${responsesSuffix}_${scoresType}_${yyyy}-${mm}-${dd}`.replace(/[\\/:"*?<>|.#$&%`+@!={}~'\s]+/g, '');
    return `${filename}.csv`;
  }

  async function loadAndSaveStudentScoresData() {
    await apiNext.getStudentScoresDataCsv(courseId).then((studentScoresDataResponse) => {
      const { error } = studentScoresDataResponse as SimplifiedErrorResponse;
      if (error) {
        throw new Error(`getStudentScoresDataCsv error ${JSON.stringify(error)}`);
      }
      const studentScoresData = studentScoresDataResponse as string;
      const blob = new Blob([studentScoresData], { type: 'text/plain;charset=utf-8' });
      const filename = generateCsvFilename(ScoresType.Scores);
      saveAs(blob, filename);
    });
  }

  async function loadAndSaveStudentResponsesData() {
    const studentResponsesData: string = await apiNext.getStudentResponsesByAssessmentQuestion(activeAssessmentQuestion.id, ResultsFormatEnum.Csv) as string;
    const blob = new Blob([studentResponsesData], { type: 'text/plain;charset=utf-8' });

    const filename = generateCsvFilename(ScoresType.Responses, activeAssessment.name, activeAssessmentQuestion.qNum);
    saveAs(blob, filename);
  }

  async function handleAssessmentSync(assessmentIdsToSync: Array<string>) {
    const postResults: Array<AssessmentScoreSyncApi> = [];
    const errors = {} as Record<string, { errorCode: string }>;
    for (const id of assessmentIdsToSync) {
      const result = await apiNext.initAssessmentScoreSync(id);
      const { error } = result as GenericObject;
      if (error) {
        errors[id] = {
          errorCode: error.errorCode || 'default',
        };
        console.error(`There was an error initiating sync for assessment ${id}`, result);
      } else {
        postResults.push(result as AssessmentScoreSyncApi);
      }
    }

    if (Object.keys(errors).length) {
      const formattedFailedMessages = formatErrorMessages(errors, assessments);
      setSyncFailedMessages(formattedFailedMessages);
      setShowSyncErrors(true);
    }
    return postResults;
  }

  function formatErrorMessages(errors: Record<string, { errorCode: string }>, assessmentList: Array<EnrichedAssessmentForStudentScores>) {
    const assessmentIdWithErrors = Object.keys(errors);
    return assessmentList.reduce((acc: Array<{ assessmentName: string; message: string }>, cur) => {
      if (assessmentIdWithErrors.includes(cur.id)) {
        acc.push({
          assessmentName: cur.name,
          message: convertToUserFacingErrorMessage(errors[cur.id].errorCode),
        });
      }
      return acc;
    }, []);
  }

  function convertToUserFacingErrorMessage(errorCode: string): string {
    switch (errorCode) {
      case CodonErrorCode.LineItemNotFound:
        return errorMessages.SCORE_SYNC_ERROR_MISSING_LMS;
      case CodonErrorCode.InvalidGradeSyncAction:
        return errorMessages.SCORE_SYNC_ERROR_NO_SCORES;
      default:
        return errorMessages.SCORE_SYNC_ERROR_DEFAULT;
    }
  }

  // Loads the data for the view of each question for a single assessment
  async function loadAssessmentGrid(assessmentId: string) {
    const newActiveAssessment = assessments.find(assessment => assessment.id === assessmentId) as EnrichedAssessmentForStudentScores;
    setActiveAssessment(newActiveAssessment);

    const studentsAssessmentQuestions = await apiNext.getAssessmentSummaryData(courseId, [newActiveAssessment.id], AssessmentSummaryViewEnum.Detail) as Array<AssessmentQuestionSummaryDataLevel2Api>;

    const { questions: assessmentQuestions } = newActiveAssessment;
    const updatedQuestions = addAttemptBarDataToQuestions(assessmentQuestions, studentsAssessmentQuestions);

    const updatedAssessmentGrid = studentScores.reduce((acc, studEnroll) => {
      let studentAnswers = [] as Array<AssessmentQuestionSummaryDataLevel2Api>;
      const { studentEnrollmentId, studentName, scores } = studEnroll;
      const { id } = studentsAndEnrollments.find(s => s.enrollmentId === studentEnrollmentId) as StudentScoresStudent;
      const { studentAssessmentId, assessmentStatus } = scores.find(sa => sa.assessmentId === assessmentId) as StudentScoresRowScores;
      if (studentAssessmentId) {
        studentAnswers = studentsAssessmentQuestions.filter(saq => saq.studentAssessmentId === studentAssessmentId);
      }
      const assessmentGridAnswers = updatedQuestions.reduce((answersAcc, q) => {
        let studentAnswer = {} as AssessmentQuestionSummaryDataLevel2Api | undefined;
        if (studentAnswers) {
          studentAnswer = studentAnswers.find(sa => sa.assessmentQuestionId === q.id);
        }
        const { gradedAdjustedPointsEarned, firstAttempted } = studentAnswer || { firstAttempted: FirstAttemptedEnum.Never };
        const hasBeenAttempted = firstAttempted !== FirstAttemptedEnum.Never;
        return [
          ...answersAcc,
          {
            questionId: q.id,
            hasBeenAttempted,
            score: gradedAdjustedPointsEarned ? gradedAdjustedPointsEarned : 0,
            firstAttempted,
          },
        ];
      }, [] as Array<AssessmentQuestionAnswer>);
      return [
        ...acc,
        {
          userId: id,
          studentName,
          assessmentStatus,
          assessmentQuestionAnswers: assessmentGridAnswers,
        },
      ];
    }, [] as Array<AssessmentGridRow>);
    setAssessmentGrid([...updatedAssessmentGrid]);
    setQuestions([...updatedQuestions]);
    setIsLoading(false);
  }

  async function loadAssessmentSummaryData(assessmentIdsToLoad: Array<string>) {
    const additionalSummaryData = await apiNext.getAssessmentSummaryData(courseId, assessmentIdsToLoad, AssessmentSummaryViewEnum.Summary) as Array<AssessmentSummaryData>;
    const newSummaryData = additionalSummaryData.reduce((acc, cur) => {
      let existingData = acc.find(asd => asd.assessmentId === cur.assessmentId);
      if (existingData) {
        existingData = { ...existingData, ...cur };
      } else {
        acc.push(cur);
      }
      return acc;
    }, [...assessmentsSummaryData]);
    setAssessmentsSummaryData(newSummaryData);
  }

  async function loadAssessmentQuestionResponseGrid(assessmentQuestionId: number) {
    const assessmentQuestion = questions.find(aq => aq.id === assessmentQuestionId) as EnrichedAssessmentQuestionForStudentScores;
    const { correctResponse, studentResponses: studentResponsesRes } = await apiNext.getStudentResponsesByAssessmentQuestion(assessmentQuestionId, ResultsFormatEnum.Json) as StudentResponsesForAssessmentQuestionRes;
    setActiveAssessmentQuestion({
      ...assessmentQuestion,
      correctResponse,
    });
    setStudentAssessmentQuestionResponses(studentResponsesRes);
    setIsLoading(false);
  }

  function handleStudentScoresView(view: StudentScoresViewEnum, selectedId?: string | number) {
    switch (view) {
      case StudentScoresViewEnum.ASSESSMENT_DETAIL_VIEW:
        setIsLoading(true);
        loadAssessmentGrid(selectedId as string);
        setStudentScoresView(StudentScoresViewEnum.ASSESSMENT_DETAIL_VIEW);
        break;
      case StudentScoresViewEnum.ASSESSMENT_QUESTION_VIEW:
        setIsLoading(true);
        loadAssessmentQuestionResponseGrid(selectedId as number);
        setStudentScoresView(StudentScoresViewEnum.ASSESSMENT_QUESTION_VIEW);
        break;
      case StudentScoresViewEnum.GRADE_SYNC_VIEW:
        setStudentScoresView(StudentScoresViewEnum.GRADE_SYNC_VIEW);
        break;
      case StudentScoresViewEnum.ALL_ASSESSMENTS_VIEW:
      default:
        setStudentScoresView(StudentScoresViewEnum.ALL_ASSESSMENTS_VIEW);
    }
  }
  if (isLoading) {
    return (
      <LoadingSpinner
        loadingMessage={(
          <>
            Student scores are loading<br />
            This may take a while for large courses.
          </>
        )}
        shouldTimeout={false}
      />
    );
  }

  return (
    <>
      <ToasterNotification
        id="student-scores-controller"
        message="View more detailed performance data by clicking on the column headers."
      />
      <StudentScoresContainer
        assessments={assessments}
        activeAssessment={activeAssessment}
        activeAssessmentQuestion={activeAssessmentQuestion}
        studentScores={studentScores}
        studentScoresView={studentScoresView}
        assessmentGrid={assessmentGrid}
        questions={questions}
        handleStudentScoresView={handleStudentScoresView}
        setShowQuestionPreview={setShowQuestionPreview}
        studentAssessmentQuestionResponses={studentAssessmentQuestionResponses}
        loadAndSaveStudentScoresData={loadAndSaveStudentScoresData}
        loadAndSaveStudentResponsesData={loadAndSaveStudentResponsesData}
        assessmentsSummaryData={assessmentsSummaryData}
        handleAssessmentSync={handleAssessmentSync}
        loadAssessmentSummaryData={loadAssessmentSummaryData}
      />
      {showSyncErrors && !!syncFailedMessages.length && (
        <ConfirmationPromptContainer
          confirmationType={ConfirmationTypeEnum.Warn}
          title="Failed to sync grades to LMS"
          handleCancel={() => setShowSyncErrors(false)}
        >
          <div className="confirmation-modal__body">
            <ul>
              {syncFailedMessages.map(({ assessmentName, message }) => (
                <li key={assessmentName}>
                  <b>{assessmentName}</b>: {message}
                </li>
              ))}
            </ul>
          </div>
          <div className="confirmation-modal__button-bar">
            <BetterButton
              className="confirmation-button"
              primary
              data-dismiss="confirmation-modal"
              onClick={() => setShowSyncErrors(false)}
              text="OK"
            />
          </div>
        </ConfirmationPromptContainer>
      )}
      {!!showQuestionPreview && (
        <BetterModal
          isShowing={true}
          hide={() => setShowQuestionPreview(false)}
        >
          <QuestionPreview
            userId={user.id}
            questions={[showQuestionPreview]}
          />
        </BetterModal>
      )}
    </>

  );
}
