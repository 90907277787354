import apiNext from 'api-next';
import { UserApi } from 'types/backend/users.types';
import adminUserSlice from 'store/slices/adminUser';
import userSlice from 'store/slices/user';
import loadUserEnrollments from 'store/actions/loadUserEnrollments';
import { AppDispatch, Store } from 'types/store.types';

export default function setMasqueradeUser(masqueradeUser: UserApi) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const store = getStore();
    const user = store.user;
    const { id } = masqueradeUser;
    apiNext.masqueradeUserId = id;
    dispatch(loadUserEnrollments(id));
    dispatch(adminUserSlice.actions.setAdminUser(user));
    dispatch(userSlice.actions.setUser(masqueradeUser));
  })();
}
