// sourced from https://github.com/codonlearning/backend/blob/dev/src/services/studentScoresData/studentScoresData.types.ts
import { AssessmentDbOut } from 'types/backend/assessments.types';
import { CourseDbOut } from 'types/backend/courses.types';
import { StudentAssessmentDb } from 'types/backend/studentAssessments.types';
import { FirstAttemptedEnum } from 'types/backend/studentAssessmentQuestions.types';
import { UserApi, UserDb } from 'types/backend/users.types';


export interface BuildScoresGridQuery {
  courseId: string
}

export enum StudentAssessmentStatus {
  NotStartedBeforeDue = 'NotStartedBeforeDue',
  InProgressBeforeDue = 'InProgressBeforeDue',
  CompletedBeforeDue = 'CompletedBeforeDue',
  NotStartedBeforeLate = 'NotStartedBeforeLate',
  InProgressBeforeLate = 'InProgressBeforeLate',
  CompletedBeforeLate = 'CompletedBeforeLate',
  NotStartedAfterLate = 'NotStartedAfterLate',
  InProgressAfterLate = 'InProgressAfterLate',
  CompletedAfterLate = 'CompletedAfterLate'
}

export interface ScoreDetail {
  pointsEarned: number | null
  studentAssessmentId: number | null
  anyAttempts: boolean
  assessmentStatus: StudentAssessmentStatus
}

export interface HeaderRow {
  [key: string]: string
}

export interface StudentRow extends Required<Pick<UserApi, 'personSourcedid'>> {
  [key: string]: ScoreDetail | string | number | null
  name: string
  idPlaceholder: string
  userId: string
  enrollmentId: number
}

interface BaseStudentAssessmentRow extends
  Pick<UserDb, 'lti_user_id' | 'person_sourcedid' | 'email'>,
  Pick<StudentAssessmentDb, 'total_points_earned' | 'unanswered_question_count' | 'enrollment__id' | 'assessment__id'>
{
  user__id: string
  student_assessment__id: number | null
  ea__due_date: Date | null
}

export type StudentAssessmentRow = BaseStudentAssessmentRow & Pick<UserDb, 'first_name' | 'last_name'>

export interface FormattedStudentAssessmentRow extends BaseStudentAssessmentRow {
  student_name: string
  assessment_status: StudentAssessmentStatus
}

export interface BaseAssessmentRow extends Pick<CourseDbOut, 'context_id'>, Pick<AssessmentDbOut, 'id' | 'name' | 'assess_type' | 'due_date' | 'late_date' | 'published'> {
  total_points: number
  total_questions: number
}

export interface AssessmentRow extends BaseAssessmentRow {
  current_window: FirstAttemptedEnum
}

