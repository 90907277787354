// This action reloads template and user questions from the API for a course's primary and additional subjectIds.
// This is needed after a user copies a template LO to get the updated QLOs.
// It then updates Redux with the reloaded templateQuestions and userQuestions and returns them as well.

import apiNext from 'api-next';
import { AppDispatch, Store } from 'types/store.types';
import activeSlice from 'store/slices/active';

export default function reloadAllQuestions() {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active: { course, instructors } } = getStore();
    const allSubjectIds = [course.subjectId, ...course.additionalSubjectIds];
    const instructorUserIds = instructors.map(({ id }) => id);
    const instructorSubjects = instructorUserIds.map(userId => {
      return allSubjectIds.map(sid => ({
        userId,
        subjectId: sid,
      }));
    }).flat();

    const reloadedTemplateQuestions = (await Promise.all(allSubjectIds.map((subjectId) => {
      return apiNext.getTemplateQuestionsBySubjectIdWithQloUserIds(subjectId, instructorUserIds);
    }))).flat();
    const reloadedUserQuestions = (await Promise.all(instructorSubjects.map(({ userId, subjectId }) => {
      return apiNext.getUserQuestionsBySubjectId(userId, subjectId);
    }))).flat();
    dispatch(activeSlice.actions.setActiveUserQuestions(reloadedUserQuestions));
    return dispatch(activeSlice.actions.setActiveTemplateQuestions(reloadedTemplateQuestions));
  })();
}
