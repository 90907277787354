import apiNext, { SimplifiedErrorResponse } from 'api-next';
import { errorMessages } from 'sharedStrings';
import activeSlice from 'store/slices/active';
import updateUserQuestionLosForUserQuestion from 'store/actions/updateUserQuestionLosForUserQuestion';
import { AppDispatch, Store } from 'types/store.types';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { AuthorAppGetItem } from 'types/backend/l8yItems.types';
import { CodonErrorCode } from 'types/backend/error.types';
import { QuestionApiOut, UserQuestionApi } from 'types/backend/questions.types';

// this needs to work for edit LOs, edit question and edit shortTitle and questionUse
export default function updateUserQuestion(
  userId: string,
  editedQuestion: Pick<UserQuestionApi, 'blooms' | 'questionUse' | 'shortTitle'> & { l8yId: string },
  learningObjectiveIds: Array<number>,
  qLoIdsToRemove?: Array<number>,
  data?: AuthorAppGetItem) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const {
      l8yId,
      questionUse,
      shortTitle,
      blooms,
    } = editedQuestion;
    const itemJson = data as AuthorAppGetItem;
    // Update user question in store.active.userQuestions
    const editedUserQuestion = await apiNext.editUserQuestion(l8yId, {
      type: LibraryTypeEnum.User,
      userId,
      itemJson,
      blooms,
      questionUse,
      shortTitle,
    });
    const { error } = editedUserQuestion as SimplifiedErrorResponse;
    if (error) {
      let message = errorMessages.NO_CORRECT_ANSWER_SELECTED;
      if (error.errorCode === CodonErrorCode.OpenResponseQuestionMissingSampleAnswer) {
        message = errorMessages.NO_SAMPLE_ANSWER_SELECTED;
      }
      return {
        error,
        message,
      };
    }
    const res = dispatch(activeSlice.actions.editActiveUserQuestion(editedUserQuestion as QuestionApiOut));
    if (!!learningObjectiveIds.length || !!qLoIdsToRemove?.length) {
      dispatch(updateUserQuestionLosForUserQuestion(l8yId, learningObjectiveIds, qLoIdsToRemove));
    }
    return res;
  })();
}
