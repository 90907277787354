/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { DateTime } from 'luxon';

import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { useAppSelector } from 'store';
import retrieveBetterClassSessions from 'store/selectors/retrieveBetterClassSessions';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import TextButton from 'shared-components/BetterButton/TextButton';
import ConfirmationPromptContainer from 'shared-components/ConfirmationPrompt/ConfirmationPromptContainer';
import { AddLoConfirmationData } from 'instructor/controllers/Course/SingleQADashboardController/SingleQADashboardController';
import { ConfirmationTypeEnum } from 'types/common.types';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import { OnChangeEvent } from 'instructor/components/Dropdown/Dropdown.types';
import './AddLoConfirmation.scss';


export default function AddLoConfirmation({
  handleAddLoToCourse,
  handleCancel,
  loData,
}: {
  handleAddLoToCourse: (loId: number, classSessionId: number) => void
  handleCancel: () => void
  loData: AddLoConfirmationData
}) {

  const classSessions = useAppSelector(retrieveBetterClassSessions);
  const [selectedClassSessionId, setSelectedClassSessionId] = useState(classSessions.find((cs) => cs.classType !== ClassTypeEnum.Special && DateTime.fromISO(cs.classDate) >= DateTime.now())?.id || classSessions[0].id);

  const classSessionOptions = classSessions.reduce((acc: Array<{ value: number; label: string }>, cs) => {
    if (cs.classType === ClassTypeEnum.Special) {
      return acc;
    }
    const label = `Class ${cs.classNumber} (${DateTime.fromISO(cs.classDate).toFormat(DateFormatEnum.WeekdayMonthDate)})`;
    return [
      ...acc,
      { value: cs.id, label },
    ];
  }, []);
  const selectedValue = classSessionOptions.find((opt) => opt.value === selectedClassSessionId);

  const handleSelectedSessionChange = (option: OnChangeEvent<number>) => {
    if (option !== null) {
      setSelectedClassSessionId(option.value);
    }
  };

  // using custom styles here to limit the height of the selectable items to prevent any hidden scrollbar issues.
  const customStyles: StylesConfig<{ value: number; label: string }, false> = {
    menu: (provided) => ({
      ...provided,
      maxHeight: 'unset',
      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      overflowY: 'auto',
      maxHeight: '200px',
    }),
  };


  return (
    <ConfirmationPromptContainer
      confirmationType={ConfirmationTypeEnum.Warn}
      title='Alignment Check'
      handleCancel={() => handleCancel()}
    >
      <div className="add-lo-confirmation">
        This LO is associated with at least one unaligned item in your course.
        <br />
        <ul>
          <li>
            <strong>{loData.topicName}</strong> {loData.title}
          </li>
        </ul>
        <div className="add-lo-confirmation__class-session">
          <div className="add-lo-confirmation__class-session-message">Would you like to add this LO to a class day?</div>
          <div className="add-lo-confirmation__class-session-select row middle around-xs">
            <label htmlFor="class-session-select col-xs-3">
              Assign LO to:
            </label>
            <Select
              name='class-session-select'
              className="col-xs-8"
              options={classSessionOptions}
              value={selectedValue}
              menuPlacement="top"
              onChange={handleSelectedSessionChange}
              styles={customStyles}
            />
          </div>
        </div>

      </div>
      <div className="confirmation-modal__button-bar">
        <BetterButton
          className="confirmation-button"
          primary
          data-dismiss="confirmation-modal"
          onClick={() => handleAddLoToCourse(loData.loId, selectedClassSessionId)}
          text="Add LO"
          autoFocus
        />
        <TextButton
          className="confirmation-text-button"
          data-dismiss="confirmation-modal"
          onClick={handleCancel}
        >
          or Cancel
        </TextButton>
      </div>
    </ConfirmationPromptContainer>
  );
}
