import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';

export default function removeAssessment(id: string) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active: { summativeAssessmentSupplements } } = getStore();
    const supplement = [...summativeAssessmentSupplements].find((supp) => supp.id === id);
    const removedAssessment = await apiNext.deleteAssessment(id);
    console.debug('removedAssessment response', removedAssessment);
    dispatch(activeSlice.actions.removeActiveAssessmentAndMaps({ id }));
    if (supplement) {
      dispatch(activeSlice.actions.removeActiveAssessmentAndMaps({ id: supplement.prepAssessmentId }));
      dispatch(activeSlice.actions.removeActiveAssessmentAndMaps({ id: supplement.practiceAssessmentId }));
      dispatch(activeSlice.actions.removeSummativeAssessmentSupplement({ id: supplement.id }));
    }
  })();
}
