// Adapted from: https://almerosteyn.com/2017/09/aria-live-regions-in-react
// NOTE: Wrapping the message in <span> is important, if it's just a string it doesn't seem to work
import React from 'react';

const AriaMessageBlock = ({ message, 'aria-live': ariaLive = 'polite' }: {
  message?: string
  'aria-live'?: 'assertive' | 'polite'
}) => {
  return (
    <div
      style={{
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: '1px',
        margin: '-1px',
        overflow: 'hidden',
        padding: 0,
        width: '1px',
        position: 'absolute',
      }}
      role="log"
      aria-live={ariaLive}
      aria-relevant="additions"
      aria-atomic="true"
    >
      {message ? <span>{message}</span> : ''}
    </div>
  );
};

export default AriaMessageBlock;
