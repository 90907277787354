import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Select, { MultiValue } from 'react-select';
import { FaTrashAlt } from 'react-icons/fa';

import { formatPlural } from 'utils/commonFormattingFunctions';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import DateTimePicker from 'shared-components/DateTimePicker/DateTimePicker';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import { EnrollmentAssessmentRow } from '../../AssessmentBuilderController.types';
import { AssessmentApiBase, AssessTypeEnum } from 'types/backend/assessments.types';
import { DropdownOption } from 'instructor/components/Dropdown/Dropdown.types';
import './Accommodations.scss';

interface AccommodationsProps {
  assessment: AssessmentApiBase
  students: Array<DropdownOption<number>>
  enrollmentAssessments: Array<EnrollmentAssessmentRow>
  saveEnrollmentAssessment: (assessType: AssessTypeEnum, enrollmentIds: Array<number>, dueDate: Date) => void
  deleteEnrollmentAssessment: (enrollmentAssessmentId: number) => void
  titleText?: string
  className?: string
}

function Accommodations({
  assessment: {
    assessType,
    dueDate,
  },
  deleteEnrollmentAssessment,
  enrollmentAssessments,
  saveEnrollmentAssessment,
  students,
  titleText,
  className = '',
}: AccommodationsProps) {

  const initDueDateL = DateTime.fromISO(dueDate);
  const nowL = DateTime.local();
  const earliestDueDate = initDueDateL > nowL ? initDueDateL : nowL;
  const defaultDueDate = initDueDateL > nowL ? initDueDateL : nowL.plus({ days: 1 }).set({
    hour: initDueDateL.hour,
    minute: initDueDateL.minute,
    second: initDueDateL.second,
  });
  const limitTime = earliestDueDate.hasSame(nowL, 'day'); // past times are never selectable for accommodations
  const [selectedDueDate, setSelectedDueDate] = useState<Date | null>(defaultDueDate.toJSDate());
  const [selectedEnrollmentIds, setSelectedEnrollmentIds] = useState([] as Array<number>);

  const selectedValues = students.filter(({ value }) => selectedEnrollmentIds.includes(value));
  const sortedEnrollmentAssessments = enrollmentAssessments.sort((a, b) => {
    if (a.lastName > b.lastName) {
      return 1;
    }
    if (a.lastName === b.lastName) {
      return a.name > b.name ? 1 : -1;
    }
    return -1;
  });

  function handleStudentChange(val: MultiValue<DropdownOption<number>>) {
    setSelectedEnrollmentIds(val.map((v: DropdownOption<number>) => v.value));
  }

  function handleSaveEnrollmentAssessment() {
    if (selectedDueDate) {
      saveEnrollmentAssessment(assessType, selectedEnrollmentIds, selectedDueDate);
      setSelectedEnrollmentIds([]);
    }
  }

  const numSelected = selectedEnrollmentIds.length;
  return (
    <div className={`accommodations ${className}`}>
      {titleText && <h3>{titleText} Due Date Accommodations</h3>}
      <p>To create a new accommodation or edit a current one, select one or more students and a due date.</p>
      <div className="accommodations__select form__field">
        <div className="accommodations__select__selections">
          <div className="accommodations__select__student">
            <label htmlFor="select-student">Select Student(s)
              {!!numSelected && (
                <span className="accommodations__select__student-count">
                  {numSelected} {formatPlural('student', numSelected)} selected
                </span>
              )}
            </label>
            <div id="select-student">
              <Select
                closeMenuOnSelect={false}
                id="student-accommodations-dropdown"
                isMulti={true}
                onChange={(val) => handleStudentChange(val)}
                options={students}
                value={selectedValues}
              />
            </div>
          </div>
          <div className="accommodations__select__due-date">
            <label htmlFor="select-due-date">Select Due Date</label>
            <DateTimePicker
              id="select-due-date"
              className="accommodations__date-picker"
              limitTime={limitTime}
              minDate={earliestDueDate.toJSDate()}
              maxDate={earliestDueDate.plus({ years: 1 }).toJSDate()}
              name="select-due-date"
              value={selectedDueDate}
              onChange={setSelectedDueDate}
            />
          </div>
        </div>
        <BetterButton
          className="accommodations__select__save"
          primary
          text="Save"
          onClick={handleSaveEnrollmentAssessment}
          disabled={!selectedEnrollmentIds.length}
        />
      </div>
      <hr/>
      {!sortedEnrollmentAssessments.length
        ? <p>There are currently no accommodations assigned for this assessment.</p>
        : (
          <div className="current-accommodations__list">
            <h4>Current {titleText || ''} Accommodations</h4>
            <table className="assigned-accommodations">
              <thead>
                <tr>
                  <th className="assigned-accommodations__header-student-name">
                    Student Name
                  </th>
                  <th className="assigned-accommodations__header-due-date-accommodation">
                    Due Date Accommodation
                  </th>
                  <th/>
                </tr>
              </thead>
              <tbody>
                {sortedEnrollmentAssessments.map(({ id: accommodationId, dueDate: eaDueDate, name: studentName }) => (
                  <tr key={`accommodation-row-${accommodationId}`}>
                    <td>{studentName}</td>
                    <td>
                      {!!eaDueDate && DateTime.fromISO(eaDueDate).toFormat(DateFormatEnum.DateAtTimeMeridean)}
                    </td>
                    <td>
                      <button onClick={() => deleteEnrollmentAssessment(accommodationId)}>
                        <FaTrashAlt/>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
    </div>
  );
}

Accommodations.propTypes = {
  assessment: PropTypes.shape({
    assessType: PropTypes.string,
    dueDate: PropTypes.string,
  }).isRequired,
  students: PropTypes.array,
  enrollmentAssessments: PropTypes.arrayOf(PropTypes.object),
  saveEnrollmentAssessment: PropTypes.func,
  deleteEnrollmentAssessment: PropTypes.func,
  titleText: PropTypes.string,
  className: PropTypes.string,
};

export default Accommodations;
