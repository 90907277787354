import React from 'react';
import PropTypes from 'prop-types';

import CodonUrls from 'urls';
import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import LoadingButton from 'shared-components/LoadingButton/LoadingButton';
import {
  ButtonTypeEnum,
  QuestionBuilderFooterButton,
  QuestionBuilderFooterButtonGroup,
  QuestionBuilderStep,
} from './QuestionBuilderController.types';

function QuestionBuilderFooter({
  editingExistingQuestion = false,
  errorMsg = '',
  navButtons,
  step,
}: {
  editingExistingQuestion: boolean
  errorMsg: string
  navButtons: QuestionBuilderFooterButtonGroup
  step: QuestionBuilderStep
}) {
  const renderButtons = (buttonsToRender: Array<QuestionBuilderFooterButton>) => buttonsToRender.map(({
    alias,
    buttonType = ButtonTypeEnum.Secondary,
    disabled = false,
    icon,
    iconAlign,
    isLoading = false,
    loadingText = '',
    label,
    onClick,
    show = true,
  }) => {
    if (!show) {
      return null;
    }
    const className = `${step}__${alias}`;
    const buttonProps = {
      className,
      key: className,
      disabled,
      onClick,
      icon,
      iconAlign,
    };
    switch (buttonType) {
      case ButtonTypeEnum.Primary: {
        return (
          <BetterButton
            primary
            text={label}
            {...buttonProps}
          />
        );
      }
      case ButtonTypeEnum.Loading: {
        return (
          <LoadingButton
            loading={isLoading}
            loadingText={loadingText}
            text={label}
            {...buttonProps}
          />
        );
      }
      case ButtonTypeEnum.Secondary:
      default: {
        return (
          <BetterButton
            secondary
            text={label}
            {...buttonProps}
          />
        );
      }
    }
  });

  const { leftButtons, rightButtons } = navButtons;

  return (
    <div className="question-builder__footer form-control row">
      <div className="button-wrap">
        {renderButtons(leftButtons)}
      </div>
      {errorMsg !== '' && (
        <div className="question-builder__error-msg">
          {errorMsg}
        </div>
      )}
      <div className="button-wrap button-wrap__right-side">
        {step === QuestionBuilderStep.ChooseLO && !editingExistingQuestion && (
          <div className="question-builder__footer__info-link">
            {externalLink(CodonUrls.SurveyItemsKB, 'Learn more about our different item types.')}
          </div>
        )}
        {renderButtons(rightButtons)}
      </div>
    </div>
  );

}

QuestionBuilderFooter.propTypes = {
  editingExistingQuestion: PropTypes.bool,
  errorMsg: PropTypes.string,
  navButtons: PropTypes.object.isRequired,
  step: PropTypes.oneOf(Object.values(QuestionBuilderStep)).isRequired,
};

export default QuestionBuilderFooter;
