/* eslint-disable jsx-a11y/no-autofocus */
/**
 * This is a more self-contained version of RemoveLosWithQuestionsConfirmation
 * This confirmation prompt handles removing:
 *   - Topics with LOs without Questions (empty Los)
 *   - Topics with LOs with Questions
 *   - LOs with Questions
 **/
import React, { useEffect, useState } from 'react';

import { delay } from 'utils';
import { formatPlural, numberToText } from 'utils/commonFormattingFunctions';
import { useAppDispatch, useAppSelector } from 'store';
import removeLoFromClassSession from 'store/actions/removeLoFromClassSession';
import removeQuestionFromAssessment from 'store/actions/removeQuestionFromAssessment';
import removeTopicFromClassSession from 'store/actions/removeTopicFromClassSession';

import BetterButton from 'shared-components/BetterButton/BetterButton';
import ConfirmationPromptContainer from 'shared-components/ConfirmationPrompt/ConfirmationPromptContainer';
import CountdownProgressBar from 'shared-components/ItemRemovalProgressMeter/ItemRemovalProgressMeter';
import TextButton from 'shared-components/BetterButton/TextButton';

import { ClassSessionApi } from 'types/backend/classSessions.types';
import { ClassSessionTopicApi } from 'types/backend/classSessionTopics.types';
import { ConfirmationTypeEnum } from 'types/common.types';
import './ConfirmRemoveTopicsLosQuestions.scss';

const confirmDelay = 600;

enum ConfirmRemoveStepEnum {
  Init = 'init',
  PromptToRemoveTopicAndEmptyLos = 'prompt-to-remove-topic-and-empty-los',
  PromptToRemoveTopicAndLosWithQuestions = 'prompt-to-remove-topic-and-los-with-questions',
  PromptToRemoveLosWithQuestions = 'prompt-to-remove-los-with-questions',
  ConfirmRemoveLoFromTopicBecauseQuestionsCoveredByOtherLos = 'confirm-remove-lo-with-questions-covered-by-other-los',
  ConfirmRemoveTopicAndLosWithQuestions = 'confirm-remove-topic-and-los-with-questions',
  Processing = 'processing',
}

export interface RemovalConfirmationData {
  classSessionId: number
  topicIdToRemove?: number // topicId is optional to allow removing just los + questions
  assessmentQuestionIdsToRemove: Array<number>
  csloIdsToRemove: Array<number>
  startedAssessmentQuestionIds: Array<number>
}

enum ConfirmationButtonType {
  AffirmAndProceed = 'affirm-and-proceed',
  ConfirmAndDone = 'confirm-and-done',
  Confirm = 'confirm',
  Dismiss = 'dismiss',
}

export default function ConfirmRemoveTopicsLosQuestions({
  handleExit,
  removalConfirmationData,
}: {
  handleExit: () => void
  removalConfirmationData: RemovalConfirmationData
}) {
  const dispatch = useAppDispatch();
  const assessmentQuestionMaps = useAppSelector(store => store.active.assessmentQuestionMaps);
  const assessments = useAppSelector(store => store.active.assessments);
  const classSessionLearningObjectives = useAppSelector(store => store.active.classSessionLearningObjectives);
  const classSessions = useAppSelector(store => store.active.classSessions);
  const classSessionTopics = useAppSelector(store => store.active.classSessionTopics);

  const {
    assessmentQuestionIdsToRemove,
    classSessionId,
    csloIdsToRemove,
    startedAssessmentQuestionIds,
    topicIdToRemove,
  } = removalConfirmationData;

  const [confirmationStep, setConfirmationStep] = useState<ConfirmRemoveStepEnum>(ConfirmRemoveStepEnum.Init);
  const [removedItemCount, setRemovedItemCount] = useState(0);

  useEffect(() => {
    if (confirmationStep === ConfirmRemoveStepEnum.Init) {
      let targetStep = null;
      console.info('init ConfirmRemoveTopicsLosQuestions', { topicIdToRemove, csloIdsToRemove, assessmentQuestionIdsToRemove });
      // on component load, determine which step we are in based removalConfirmationData state
      if (!!topicIdToRemove) {
        if (!!csloIdsToRemove.length) {
          if (!!assessmentQuestionIdsToRemove.length) {
            targetStep = ConfirmRemoveStepEnum.PromptToRemoveTopicAndLosWithQuestions;
          } else {
            targetStep = ConfirmRemoveStepEnum.PromptToRemoveTopicAndEmptyLos;
          }
        }
        // if topic has no loIds, removal does not use this confirmation
      } else if (!!csloIdsToRemove.length) { // if no topic to remove, but los to remove
        if (!!assessmentQuestionIdsToRemove.length) {
          targetStep = ConfirmRemoveStepEnum.PromptToRemoveLosWithQuestions;
        } else {
          // if there is an loId to remove but no questions are eligible for removal, prompt to just remove this LO from topic
          targetStep = ConfirmRemoveStepEnum.ConfirmRemoveLoFromTopicBecauseQuestionsCoveredByOtherLos;
        }
      }
      if (!targetStep) {
        console.warn('Invalid removalConfirmationData', { topicIdToRemove, csloIdsToRemove, assessmentQuestionIdsToRemove });
      } else {
        setConfirmationStep(targetStep);
      }
    }
  }, [assessmentQuestionIdsToRemove, confirmationStep, csloIdsToRemove, topicIdToRemove]);

  /**
   * <DISPATCHES> - API dispatches called by action handlers, not called directly from UI interactions
   **/
  // remove array of questions from assessment
  const bulkRemoveQuestionIdsFromAssessment = async (aqIds: Array<number>) => {
    if (!aqIds.length) {
      console.warn('Empty array supplied to bulkRemoveQuestionIdsFromAssessment');
      return;
    }
    return Promise.all(aqIds.map(async (aqId) => {
      return dispatch(removeQuestionFromAssessment({ assessmentQuestionId: aqId })).then(() => setRemovedItemCount(prev => prev + 1));
    }));
  };
  // remove array of class session LOs
  const bulkRemoveClassSessionLoIds = async (csloIds: Array<number>) => {
    const results: Array<boolean> = [];
    for (const csloId of csloIds) {
      const result = await dispatch(removeLoFromClassSession(csloId));
      setRemovedItemCount(prev => prev + 1);
      results.push(!!result);
    }
    return results;
  };
  const removeLo = async (classSessionLoId: number) => {
    return dispatch(removeLoFromClassSession(classSessionLoId));
  };

  const exitAfterDelay = () => delay(confirmDelay).then(handleExit);

  const removeTopicAndQuestions = async ({ topicIdToDelete, assessmentQuestionIdsToDelete }: {
    topicIdToDelete: number
    assessmentQuestionIdsToDelete: Array<number>
  }) => {
    if (!classSessionId || !topicIdToDelete || !assessmentQuestionIdsToDelete) {
      console.warn('missing topicId, aqIds or classSessionId', topicIdToDelete, assessmentQuestionIdsToDelete, classSessionId);
    } else {
      const classSessionLoIdsToDelete = classSessionLearningObjectives.reduce((acc, cslo) => {
        if (cslo.classSessionId === classSessionId && cslo.topicId === topicIdToDelete) {
          acc.push(cslo.id);
        }
        return acc;
      }, [] as Array<number>);
      const classSessionTopic = classSessionTopics.find((cst) => cst.topicId === topicIdToDelete && cst.classSessionId === classSessionId) as ClassSessionTopicApi;

      await bulkRemoveQuestionIdsFromAssessment(assessmentQuestionIdsToDelete);
      await bulkRemoveClassSessionLoIds(classSessionLoIdsToDelete);
      await dispatch(removeTopicFromClassSession(classSessionTopic.id));

      setRemovedItemCount(prev => prev + 1);
      return exitAfterDelay();
    }
  };
  const removeLoFromTopic = async (loIdToRemove: number) => {
    return dispatch(removeLoFromClassSession(loIdToRemove)).then(() => {
      return handleExit();
    });
  };
  /* </DISPATCHES> */

  /**
   * <ACTION HANDLERS> - handle user actions from the UI
   **/
  const handleRemoveTopicAndEmptyLos = async () => {
    setConfirmationStep(ConfirmRemoveStepEnum.Processing);
    await bulkRemoveClassSessionLoIds(csloIdsToRemove).then(async () => {
      const classSessionTopic = classSessionTopics.find((cst) => cst.topicId === topicIdToRemove && cst.classSessionId === classSessionId) as ClassSessionTopicApi;
      if (classSessionTopic) {
        await dispatch(removeTopicFromClassSession(classSessionTopic.id)).then(() => handleExit());
      }
    });
  };

  const handleRemoveLosWithQuestionsConfirmationClick = async () => {
    setConfirmationStep(ConfirmRemoveStepEnum.Processing);
    const assessmentQuestionIdsToDelete = assessmentQuestionIdsToRemove.filter(aqId => !startedAssessmentQuestionIds.includes(aqId));
    if (topicIdToRemove) {
      await removeTopicAndQuestions({
        topicIdToDelete: topicIdToRemove,
        assessmentQuestionIdsToDelete,
      });
    } else {
      const [csloId] = csloIdsToRemove;
      await bulkRemoveQuestionIdsFromAssessment(assessmentQuestionIdsToDelete);
      await removeLo(csloId);
      return exitAfterDelay();
    }
  };

  const handleRemoveTopicsLosLeavingQuestions = async () => {
    await bulkRemoveClassSessionLoIds(csloIdsToRemove);
    if (!!topicIdToRemove) {
      const classSessionTopic = classSessionTopics.find((cst) => cst.topicId === topicIdToRemove && cst.classSessionId === classSessionId) as ClassSessionTopicApi;
      await dispatch(removeTopicFromClassSession(classSessionTopic.id));
    }
    return exitAfterDelay();
  };
  /* </ACTION HANDLERS> */

  /* <RENDERING> */
  const qCount = assessmentQuestionIdsToRemove.length;
  const startedQCount = startedAssessmentQuestionIds.length;
  const losRemovedCount = csloIdsToRemove.length;
  const questionsRemovedCount = qCount - startedQCount;
  const topicLoString = losRemovedCount > 1 ? 'LOs' : 'this LO';

  const renderConfirmationStep = (step: ConfirmRemoveStepEnum) => {
    switch (step) {
      case ConfirmRemoveStepEnum.Init: {
        return <></>;
      }
      case ConfirmRemoveStepEnum.PromptToRemoveTopicAndEmptyLos: {
        const { classNumber } = classSessions.find((cs) => cs.id === classSessionId) as ClassSessionApi;
        const cslosToRemoveCount = csloIdsToRemove.length;
        const loMessage = ` and its ${cslosToRemoveCount} ${formatPlural('LO', cslosToRemoveCount)}`;
        const message = `Are you sure you want to remove this topic${!!cslosToRemoveCount ? loMessage : ''} from Class ${classNumber}?`;
        return (
          <div className="confirm-remove-topic-los-questions__container">
            <div className="confirm-remove-topic-los-questions__message">
              {message}
            </div>
            <div className="confirmation-modal__button-bar">
              <BetterButton
                data-confirmation-button={ConfirmationButtonType.ConfirmAndDone}
                primary
                className="confirmation-button"
                onClick={handleRemoveTopicAndEmptyLos}
                text={`Remove Topic${!!cslosToRemoveCount ? ' and LOs' : ''}`}
                autoFocus
              />
              <TextButton
                className="confirmation-text-button"
                data-dismiss="confirmation-modal"
                data-confirmation-button={ConfirmationButtonType.Dismiss}
                onClick={handleExit}
              >
                or Cancel
              </TextButton>
            </div>
          </div>
        );
      }
      case ConfirmRemoveStepEnum.PromptToRemoveLosWithQuestions:
      case ConfirmRemoveStepEnum.PromptToRemoveTopicAndLosWithQuestions: {
        const affectedAqms = assessmentQuestionMaps.filter(aq => assessmentQuestionIdsToRemove.includes(aq.id));
        const affectedAssessments = assessments.filter(assessment => affectedAqms.some(aq => aq.assessmentId === assessment.id));
        const removingTopic = step === ConfirmRemoveStepEnum.PromptToRemoveTopicAndLosWithQuestions;
        /**
         * Translation:
         * There are eleven items associated with this LO that you are removing from your course.
         * The items are in the 3 assessments listed below
         **/
        const associatedWithString = `There ${formatPlural('is', qCount)} ${numberToText(qCount)} ${formatPlural('item', qCount)} associated with`;
        const alertMessage = startedQCount > 0 ? (
          <div>
            {associatedWithString} {topicLoString} that you are removing from your course. The {qCount > 1 ? 'items are' : 'item is'} in the {formatPlural('assessment', affectedAssessments.length)} listed below.&nbsp;
            {qCount === startedQCount
              ? `${formatPlural('This', startedQCount)} ${formatPlural('item', startedQCount)}`
              : `${numberToText(startedQCount, true)} ${formatPlural('item', startedQCount)}`}
            &nbsp;
            <strong>cannot be removed</strong> because {startedQCount > 1 ? 'they have' : 'it has'} been answered by students.
          </div>
        ) : (
          <div>
            {topicIdToRemove ? `${numberToText(losRemovedCount, true)} ${formatPlural('LO', losRemovedCount)} in this topic` : 'This LO'} will be removed from your course.
            &nbsp;
            {associatedWithString} only {losRemovedCount > 1 ? 'these LOs' : 'this LO'}, in the following {formatPlural('assessment', affectedAssessments.length)}:
          </div>
        );
        const topicAnd = removingTopic ? ' Topic and' : '';
        const removeConfirmBtnText = `Remove${topicAnd} ${formatPlural('LO', losRemovedCount)} and leave the ${formatPlural('item', startedQCount)} behind`;
        const removeAndLeaveBehindBtnText = `Remove${topicAnd} ${topicLoString} and leave ${formatPlural('item', questionsRemovedCount)} behind`;
        return (
          <div className="confirm-remove-topic-los-questions__container">
            <div className="confirm-remove-topic-los-questions__message">
              {alertMessage}
              {!!affectedAssessments.length && (
                <ul>
                  {affectedAssessments.map(assessment => (
                    <li key={assessment.id}>{assessment.name}</li>
                  ))}
                </ul>
              )}
              {startedQCount !== qCount && (
                <>
                  Would you like to remove the {startedQCount > 0 ? `${numberToText(questionsRemovedCount)} unattempted ${formatPlural('item', questionsRemovedCount)}` : `${formatPlural('item', qCount)} too`}?
                </>
              )}
            </div>
            <div className="confirmation-modal__button-bar remove-los-with-questions__button-bar">
              {startedQCount !== qCount && (
                <BetterButton
                  className="confirmation-button"
                  primary
                  data-confirmation-button={ConfirmationButtonType.AffirmAndProceed}
                  data-dismiss="confirmation-modal"
                  onClick={() => setConfirmationStep(ConfirmRemoveStepEnum.ConfirmRemoveTopicAndLosWithQuestions)}
                  text={`Remove${topicAnd} ${formatPlural('LO', losRemovedCount)} and ${numberToText(questionsRemovedCount)} ${formatPlural('item', questionsRemovedCount)}`}
                  autoFocus
                />
              )}
              <div className="confirm-remove-topic-los-questions__button-message">
                Without associated LOs, students will not be able to retry {startedQCount === 0 ? formatPlural('this', questionsRemovedCount) : 'the remaining' } {formatPlural('item', questionsRemovedCount)} in the Study Path.
              </div>
              {startedQCount !== qCount ? (
                <TextButton
                  className="confirmation-text-button"
                  data-dismiss="confirmation-modal"
                  data-confirmation-button={ConfirmationButtonType.ConfirmAndDone}
                  onClick={handleRemoveTopicsLosLeavingQuestions}
                >
                  {removeAndLeaveBehindBtnText}
                </TextButton>
              ) : (
                <>
                  <BetterButton
                    className="secondary-button"
                    data-confirmation-button={ConfirmationButtonType.ConfirmAndDone}
                    secondary
                    onClick={handleRemoveTopicsLosLeavingQuestions}
                    text={removeConfirmBtnText}
                    autoFocus
                  />
                  <TextButton
                    className="confirmation-text-button"
                    data-dismiss="confirmation-modal"
                    data-confirmation-button={ConfirmationButtonType.Dismiss}
                    onClick={handleExit}
                  >
                    or Cancel
                  </TextButton>
                </>
              )}
            </div>
          </div>
        );
      }
      case ConfirmRemoveStepEnum.ConfirmRemoveTopicAndLosWithQuestions: {
        return (
          <div className="confirm-remove-topic-los-questions__container">
            <div className="confirm-remove-topic-los-questions__message">
              Are you sure you want to remove {losRemovedCount > 1 ? 'the' : ''} {topicLoString} and {numberToText(questionsRemovedCount)} {formatPlural('item', questionsRemovedCount)} from your course?
              <p/>
              This action cannot be undone.
            </div>
            <div className="confirmation-modal__button-bar">
              <BetterButton
                className="confirmation-button"
                primary
                data-dismiss="confirmation-modal"
                onClick={handleRemoveLosWithQuestionsConfirmationClick}
                data-confirmation-button={ConfirmationButtonType.ConfirmAndDone}
                text="I'm sure, remove them all!"
                autoFocus
              />
              <TextButton
                className="confirmation-text-button"
                data-dismiss="confirmation-modal"
                data-confirmation-button={ConfirmationButtonType.Dismiss}
                onClick={handleExit}
              >
                or Cancel
              </TextButton>
            </div>
          </div>
        );
      }
      case ConfirmRemoveStepEnum.ConfirmRemoveLoFromTopicBecauseQuestionsCoveredByOtherLos: {
        return (
          <div className="confirm-remove-topic-los-questions__container">
            <div className="confirm-remove-topic-los-questions__message">
              This LO has questions that are also connected to other LOs in your course.<br/>
              Do you want to remove this LO? The related question will remain in your course.
            </div>
            <div className="confirmation-modal__button-bar">
              <BetterButton
                className="confirmation-button"
                primary
                data-dismiss="confirmation-modal"
                data-confirmation-button={ConfirmationButtonType.ConfirmAndDone}
                text="Remove this LO"
                onClick={() => removeLoFromTopic(csloIdsToRemove[0])}
                autoFocus
              />
              <TextButton
                className="confirmation-text-button"
                data-dismiss="confirmation-modal"
                data-confirmation-button={ConfirmationButtonType.Dismiss}
                onClick={handleExit}
              >
                or Cancel
              </TextButton>
            </div>
          </div>
        );
      }
      case ConfirmRemoveStepEnum.Processing: {
        const itemCount = qCount - startedQCount + csloIdsToRemove.length + (topicIdToRemove ? 1 : 0);
        return (
          <div className="confirm-remove-topic-los-questions__container">
            <CountdownProgressBar removedItemCount={removedItemCount} totalItemCount={itemCount} />
          </div>
        );
      }
    }
  };

  const promptTitle = {
    [ConfirmRemoveStepEnum.Init]: 'Loading...',
    [ConfirmRemoveStepEnum.PromptToRemoveLosWithQuestions]: "Don't leave us behind!",
    [ConfirmRemoveStepEnum.PromptToRemoveTopicAndLosWithQuestions]: 'Confirm Remove Topic',
    [ConfirmRemoveStepEnum.ConfirmRemoveLoFromTopicBecauseQuestionsCoveredByOtherLos]: 'Confirm Remove LO',
    [ConfirmRemoveStepEnum.PromptToRemoveTopicAndEmptyLos]: 'Remove',
    [ConfirmRemoveStepEnum.ConfirmRemoveTopicAndLosWithQuestions]: 'Confirmation',
    [ConfirmRemoveStepEnum.Processing]: 'Making changes...',
  };
  return (
    <ConfirmationPromptContainer
      confirmationType={ConfirmationTypeEnum.Alert}
      handleCancel={confirmationStep !== ConfirmRemoveStepEnum.Processing ? handleExit : undefined}
      title={promptTitle[confirmationStep]}
      data-confirmation-step={confirmationStep}
    >
      {renderConfirmationStep(confirmationStep)}
    </ConfirmationPromptContainer>
  );
}
