import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { questionSelectorCardTooltipMessage } from '../../AssessmentBuilderConfirmationMessages';
import { AssessmentBuilderContext } from '../../AssessmentBuilderController';
import { formatPlural, formatPoints } from 'utils/commonFormattingFunctions';
import tooltips from '../../assessmentBuilderTooltips';

import { EnrichedEditingAssessment } from '../../enrichAssessmentForEditing';
import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import retrieveSortedActiveCombinedQuestions from 'store/selectors/retrieveSortedActiveCombinedQuestions';

import LoChart from '../charts/LoChart';
import BloomsChart from '../charts/BloomsChart';
import QuestionListSidebarSelectedQuestionsList from './QuestionListSidebarSelectedQuestionsList';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';

import { ActiveCombinedQuestion } from 'store/selectors/retrieveActiveCombinedQuestions';
import { PositionEnum } from 'types/common.types';
import { QuestionPreviewLaunchWithMetadata } from 'utils/getAssessmentQuestionsMetadata';
import { AssessmentApiBase } from 'types/backend/assessments.types';
import {
  AssessmentQuestionForQuestionListSidebar,
  QuestionAction,
  TabEnum,
  TabNavigate,
} from '../../AssessmentBuilderController.types';
import './QuestionListSidebar.scss';

interface QuestionListSidebarProps {
  currentAssessment: EnrichedEditingAssessment | AssessmentApiBase
  currentTab: TabEnum
  navigate: TabNavigate
  onChangeQuestionSort: (assessmentQuestionId: number, previousId: number) => void
  questionAction: QuestionAction
  setQuestionPreview: (preview: QuestionPreviewLaunchWithMetadata | null) => void
  startedQuestionIds: Array<number>
}

enum SidebarTabEnum {
  Questions = 'questions',
  LoMetrics = 'lo-metrics',
  Blooms = 'blooms',
}

function QuestionListSidebar({
  currentAssessment,
  currentTab,
  navigate,
  onChangeQuestionSort,
  questionAction,
  setQuestionPreview,
  startedQuestionIds,
}: QuestionListSidebarProps) {
  const assessmentQuestionMaps = useSelector(retrieveActiveAssessmentQuestionMaps);
  const combinedQuestions = useSelector(retrieveSortedActiveCombinedQuestions);

  const [sidebarTab, setSidebarTab] = useState(SidebarTabEnum.Questions);
  const [assessmentQuestions, setAssessmentQuestions] = useState([] as Array<AssessmentQuestionForQuestionListSidebar>);

  const { hasBeenStarted } = useContext(AssessmentBuilderContext);

  function getAssessmentPointTotal(questions: Array<AssessmentQuestionForQuestionListSidebar>) {
    const totalPoints = questions.reduce((ac, r) => ac + Number(r.points), 0);
    return totalPoints;
  }

  //Get combinedQuestion objects for assessment questions
  //TODO: there is probably some way to get this from `retrieveSortedActiveCombinedQuestions` and not recreate similar attributes here
  useEffect(() => {
    const updatedAssessmentQuestions = assessmentQuestionMaps.reduce((r, aqm) => {
      if (aqm.assessmentId === currentAssessment.id) {
        const newQ = combinedQuestions.find((cq) => cq.id === aqm.questionId) as ActiveCombinedQuestion;
        const { order = -1, points, id: assessmentQuestionId } = aqm;
        const isLocked = isAqmLocked(aqm.questionId, startedQuestionIds);
        r.push({
          ...newQ,
          order,
          points,
          assessmentQuestionId,
          isLocked,
        });
      }
      return r;
    }, [] as Array<AssessmentQuestionForQuestionListSidebar>);
    setAssessmentQuestions(updatedAssessmentQuestions);
  }, [assessmentQuestionMaps, currentAssessment, combinedQuestions, hasBeenStarted, startedQuestionIds]);

  function isAqmLocked(aqmId: number, startedAQIds: Array<number>) {
    return startedAQIds.includes(aqmId);
  }

  function renderView(tab: SidebarTabEnum) {
    switch (tab) {
      case SidebarTabEnum.Questions:
        return (
          <QuestionListSidebarSelectedQuestionsList
            assessmentQuestions={assessmentQuestions}
            currentAssessment={currentAssessment}
            hasBeenStarted={hasBeenStarted}
            key={`question-list_${currentAssessment.id}`}
            navigate={navigate}
            onChangeQuestionSort={onChangeQuestionSort}
            questionAction={questionAction}
            setAssessmentQuestions={setAssessmentQuestions}
            setQuestionPreview={setQuestionPreview}
            startedQuestionIds={startedQuestionIds}
          />
        );
      case SidebarTabEnum.LoMetrics:
        return (
          <div className="selected-questions-sidebar__chart">
            <LoChart currentAssessment={currentAssessment} />
          </div>
        );
      case SidebarTabEnum.Blooms:
        return (
          <div className="selected-questions-sidebar__chart">
            <BloomsChart currentAssessment={currentAssessment} />
          </div>
        );
      default:
        return null;
    }
  }

  const pointTotal = getAssessmentPointTotal(assessmentQuestions);
  const infoString = `${assessmentQuestions.length} ${formatPlural('item', assessmentQuestions.length)} | ${formatPoints(pointTotal)} ${formatPlural('point', pointTotal)}`;
  const showStudyPathTooltip = [TabEnum.SelectPrepQuestions, TabEnum.SelectPracticeQuestions].includes(currentTab);
  const studyPathTooltipContent = currentTab === TabEnum.SelectPrepQuestions ? tooltips.spbCheckpoint2 : tooltips.spbCheckpoint3;

  return (
    <div className="question-list-sidebar" data-assesstype={currentAssessment.assessType}>
      {hasBeenStarted && (
        <div className="question-list-sidebar__message-wrap">
          <div className="question-list-sidebar__message-badge">
            Started by students. You may want to notify students of any changes.
            <BetterTooltip
              content={() => questionSelectorCardTooltipMessage(currentAssessment.assessType)}
              indicate
              position={PositionEnum.Bottom}
            />
          </div>
        </div>
      )}
      <div className="question-list-sidebar__tab-nav">
        <div className="question-list-sidebar__tab-nav-title">Display:</div>
        <div className="question-list-sidebar__tab-nav-buttons">
          <button
            className={sidebarTab === SidebarTabEnum.Questions ? 'is-active' : ''}
            onClick={() => setSidebarTab(SidebarTabEnum.Questions)}
          >
            Items
          </button>
          <button
            className={sidebarTab === SidebarTabEnum.LoMetrics ? 'is-active' : ''}
            onClick={() => setSidebarTab(SidebarTabEnum.LoMetrics)}
          >
            LO Metrics
          </button>
          <button
            className={sidebarTab === SidebarTabEnum.Blooms ? 'is-active' : ''}
            onClick={() => setSidebarTab(SidebarTabEnum.Blooms)}
          >
            Bloom's
          </button>
        </div>
      </div>
      {sidebarTab === SidebarTabEnum.Questions && (
        <div className="assessment-info">
          <div>
            <div className="assessment-info__assessment-name">
              {currentAssessment.name}
            </div>
            <div className="assessment-info__assessment-details">
              {infoString}
            </div>
          </div>
          {showStudyPathTooltip && (
            <div>
              <BetterTooltip
                content={() => studyPathTooltipContent}
                indicate
                position={PositionEnum.Left}
                wide
              />
            </div>
          )}
        </div>
      )}
      <div className="question-list-sidebar__tab-wrap">
        {renderView(sidebarTab)}
      </div>
    </div>
  );
}

QuestionListSidebar.propTypes = {
  currentAssessment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assessType: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    classSessionIds: PropTypes.array,
  }),
  navigate: PropTypes.func,
  onChangeQuestionSort: PropTypes.func,
  questionAction: PropTypes.func.isRequired,
  setQuestionPreview: PropTypes.func.isRequired,
  startedQuestionIds: PropTypes.array.isRequired,
};

export default QuestionListSidebar;
