// adapted from modules by https://github.com/mdhnpm
export const convertRgbToHex = (red: number, green: number, blue: number) => {
  if (isValidRgb(red, green, blue)) {
    return returnHex(red) + returnHex(green) + returnHex(blue);
  } else {
    throw new Error('Invalid RGB input');
  }
};

const isValidRgb = (red: number, green: number, blue: number): boolean => {
  if (!isValidNumber(red) || !isValidNumber(green) || !isValidNumber(blue)) {
    return false;
  }
  return true;
};

const isValidNumber = (value: number) => {
  if (value > 255 || value < 0) {
    return false;
  }
  return true;
};

const returnHex = (value: number) => {
  if (value < 10) {
    return `0${value.toString(16)}`;
  } else {
    return value.toString(16);
  }
};

export const convertHexToRgb = (hex: string) => {
  const sanitisedHex = hex.replace('#', '').toLowerCase();

  if (validateHexString(sanitisedHex)) {
    const chars = [...sanitisedHex];
    return [
      parseInt(chars[0] + chars[1], 16),
      parseInt(chars[2] + chars[3], 16),
      parseInt(chars[4] + chars[5], 16),
    ];
  } else {
    throw new Error('Invalid HEX input');
  }
};

const validateHexString = (hex: string) => {
  const regex = new RegExp(/[0-9a-f]{6}/);
  if (hex.length === 6 || regex.test(hex)) {
    return true;
  }
  return false;
};

export type ColorContrastRatioCalculatorInput = string | Array<number>;

export const colorContrastRatioCalculator = (foregroundColor: ColorContrastRatioCalculatorInput,
  backgroundColor: ColorContrastRatioCalculatorInput): number => {
  const l1 = calculateRelativeLuminance(foregroundColor);
  const l2 = calculateRelativeLuminance(backgroundColor);
  if (l2 < l1) {
    const ratio = (l1 + 0.05) / (l2 + 0.05);
    return Math.round(ratio * 100) / 100;
  } else {
    const ratio = (l2 + 0.05) / (l1 + 0.05);
    return Math.round(ratio * 100) / 100;
  }
};

const calculateRelativeLuminanceComponent1 = (rgbValue: number) => {
  const relativeRgb = rgbValue / 255;
  if (relativeRgb <= 0.03928) {
    return relativeRgb / 12.92;
  }
  return ((relativeRgb + 0.055) / 1.055) ** 2.4;
};

const calculateRelativeLuminanceComponent2 = (input: Array<number>) => {
  return 0.2126 * calculateRelativeLuminanceComponent1(input[0])
    + 0.7152 * calculateRelativeLuminanceComponent1(input[1])
    + 0.0722 * calculateRelativeLuminanceComponent1(input[2]);
};

const calculateRelativeLuminance = (input: ColorContrastRatioCalculatorInput): number => {
  if (Array.isArray(input) && input.length === 3) {
    return calculateRelativeLuminanceComponent2(input);
  } else if (typeof input === 'string') {
    const rgb = convertHexToRgb(input);
    return calculateRelativeLuminanceComponent2(rgb);
  } else {
    throw new Error('Input must be array of number or string');
  }
};

export type CheckerResults = {
  regularText: {
    aa: boolean
    aaa: boolean
  }
  largeText: {
    aa: boolean
    aaa: boolean
  }
  uiComponent: {
    aa: boolean
  }
}

export const wcagContrastChecker = (
  foregroundColor: ColorContrastRatioCalculatorInput,
  backgroundColor: ColorContrastRatioCalculatorInput
): CheckerResults => {
  const ratio = colorContrastRatioCalculator(foregroundColor, backgroundColor);
  return resultsGenerator(ratio);
};

const resultsGenerator = (contrastRatio: number): CheckerResults => {

  const results: CheckerResults = {
    regularText: {
      aa: false,
      aaa: false,
    },
    largeText: {
      aa: false,
      aaa: false,
    },
    uiComponent: {
      aa: false,
    },
  };

  if (contrastRatio >= 7.5) {
    results.regularText.aa = true;
    results.regularText.aaa = true;
    results.largeText.aa = true;
    results.largeText.aaa = true;
    results.uiComponent.aa = true;
  } else if (contrastRatio >= 4.5) {
    results.regularText.aa = true;
    results.regularText.aaa = false;
    results.largeText.aa = true;
    results.largeText.aaa = true;
    results.uiComponent.aa = true;
  } else if (contrastRatio >= 3) {
    results.regularText.aa = false;
    results.regularText.aaa = false;
    results.largeText.aa = true;
    results.largeText.aaa = false;
    results.uiComponent.aa = true;
  }

  return results;
};
