import React from 'react';
import Icon, { IconEnum } from 'shared-components/Icon';
import { ClarityEnum } from 'types/backend/studentAssessmentQuestionAttempts.types';
import './ClarityRadioGroup.scss';

function ClarityRadioGroup({
  clarity,
  disabled,
  onChange,
  id,
}: {
  clarity: ClarityEnum | null
  disabled?: boolean
  onChange: (clarity: ClarityEnum) => void
  id: string
}) {
  return (
    <fieldset role="radiogroup" className="clarity-radio-group" data-clarity-selected={clarity !== null}>
      <legend className="sr-only">Select Muddy or Clear</legend>
      <label
        aria-label="This item is muddy"
        className="clarity-button"
        data-clarity={ClarityEnum.Muddy}
        data-disabled={disabled}
        data-selected={clarity === ClarityEnum.Muddy}
      >
        <input
          className="sr-only"
          disabled={disabled}
          name={`clarity-radio__${id}`}
          type="radio"
          value={ClarityEnum.Muddy}
          checked={clarity === ClarityEnum.Muddy}
          onChange={() => onChange(ClarityEnum.Muddy)}
        />
        <Icon size={32} which={IconEnum.Muddy} />
        <span>It's Muddy</span>
      </label>
      <label
        aria-label="This item is clear"
        className="clarity-button"
        data-clarity={ClarityEnum.Clear}
        data-disabled={disabled}
        data-selected={clarity === ClarityEnum.Clear}
      >
        <input
          className="sr-only"
          disabled={disabled}
          name={`clarity-radio__${id}`}
          type="radio"
          value={ClarityEnum.Clear}
          checked={clarity === ClarityEnum.Clear}
          onChange={() => onChange(ClarityEnum.Clear)}
        />
        <Icon size={32} viewBox="0 0 28 25" which={IconEnum.Clear} />
        <span>It's Clear</span>
      </label>
    </fieldset>
  );
}

export default ClarityRadioGroup;
