import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { FiChevronRight } from 'react-icons/fi';
import { TabEnum, TabNavigate } from '../../AssessmentBuilderController.types';
import { getAssessTypeShortName } from 'utils/commonFormattingFunctions';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import './AssessmentBuilderNav.scss';

function AssessmentNav({
  assessmentName,
  assessType,
  handleExit,
  currentTab,
  isSummative,
  navigate,
}: {
  assessmentName?: string
  assessType?: AssessTypeEnum
  handleExit: () => void
  currentTab: TabEnum
  isSummative: boolean
  navigate: TabNavigate
}) {
  // determine where the current tab is in the order
  const tabProgression = isSummative ? [
    TabEnum.Editing,
    TabEnum.AssessmentReview,
    TabEnum.SelectPrepQuestions,
    TabEnum.SelectPracticeQuestions,
    TabEnum.EditSummativeAccommodations,
    TabEnum.CreateQuestion,
  ] : [
    TabEnum.Editing,
    TabEnum.SelectQuestions,
    TabEnum.EditAccommodations,
    TabEnum.CreateQuestion,
  ];
  const prog = tabProgression.indexOf(currentTab);
  const assessTypeString = !!assessType ? getAssessTypeShortName(assessType) : '';

  let titleString = `${assessTypeString} Assessment Builder`;
  if (isSummative) {
    titleString = 'Study Path Builder';
  } else if (assessType === AssessTypeEnum.Readiness) {
    titleString = 'Readiness Experience Builder';
  }
  const summativeCrumbs = [
    {
      id: 'assessmentReviewNav',
      isActive: prog > 0 ? 'is-active' : 'is-inactive',
      isDisabled: currentTab === TabEnum.Creating,
      title: 'Checkpoint 1',
      subtitle: 'Assessments to Review',
      tab: TabEnum.AssessmentReview,
    },
    {
      id: 'addPrepQuestionsNav',
      isActive: prog > 1 ? 'is-active' : 'is-inactive',
      isDisabled: currentTab === TabEnum.Creating,
      title: 'Checkpoint 2',
      subtitle: 'Prep Questions',
      tab: TabEnum.SelectPrepQuestions,
    },
    {
      id: 'addPracticeTestQuestionsNav',
      isActive: prog > 2 ? 'is-active' : 'is-inactive',
      isDisabled: currentTab === TabEnum.Creating,
      title: 'Checkpoint 3',
      subtitle: 'Practice Test',
      tab: TabEnum.SelectPracticeQuestions,
    },
    {
      id: 'editSummativeAccommodations',
      isActive: prog > 3 ? 'is-active' : 'is-inactive',
      isDisabled: currentTab === TabEnum.Creating,
      title: 'Due Date Accommodations',
      tab: TabEnum.EditSummativeAccommodations,
    },
  ];

  return (
    <nav aria-label="Assessment Builder Navigation" className="assessment-nav">
      <div className="exit-block">
        <div className="assessment-nav__left-side exit-block__wrapper">
          <div className="assessment-nav__title">
            <h2>{titleString}</h2>
            {!!assessmentName && <div className="assessment-nav_assessment-name">{assessmentName}</div>}
          </div>
        </div>
      </div>
      <div className="assessment-nav__wrapper">
        <button
          className={`nav-button ${prog > 0 ? 'is-active' : ''} ${[TabEnum.Editing, TabEnum.Creating].includes(currentTab) ? 'is-selected' : ''}`}
          data-tab={TabEnum.Editing}
          onClick={() => navigate(TabEnum.Editing)}
        >
          Details
        </button>
        {isSummative ? (
          <div className="summative-nav-buttons">
            {summativeCrumbs.map(({ id, isActive, isDisabled, tab, title, subtitle }) => (
              <Fragment key={id}>
                <button
                  className={isActive}
                  disabled={isDisabled}
                  onClick={() => navigate(tab)}
                >
                  <FiChevronRight />
                </button>
                <button
                  className={`nav-button ${id} ${currentTab === tab ? 'is-selected' : ''}`}
                  disabled={isDisabled}
                  name={id}
                  data-tab={tab}
                  onClick={() => navigate(tab)}
                  title={title}
                >
                  <div className="crumb-name">
                    <div>{title}</div>
                    <div className="crumb-name__subtitle">{subtitle}</div>
                  </div>
                </button>
              </Fragment>
            ))}
          </div>
        ) : (
          <>
            <button
              className={prog > 0 ? 'is-active' : ''}
              disabled={currentTab === TabEnum.Creating}
              onClick={() => navigate(TabEnum.SelectQuestions)}
            >
              <FiChevronRight />
            </button>
            <button
              className={`nav-button ${currentTab === TabEnum.SelectQuestions ? 'is-selected' : ''}`}
              disabled={currentTab === TabEnum.Creating}
              data-tab={TabEnum.SelectQuestions}
              onClick={() => navigate(TabEnum.SelectQuestions)}
            >
              Add<br />Items
            </button>
            <button
              className={currentTab === TabEnum.EditAccommodations ? 'is-active' : 'is-inactive'}
              disabled={currentTab === TabEnum.Creating}
              onClick={() => navigate(TabEnum.EditAccommodations)}
            >
              <FiChevronRight />
            </button>
            <button
              className={`nav-button ${currentTab === TabEnum.EditAccommodations ? 'is-selected' : ''}`}
              data-tab={TabEnum.EditAccommodations}
              disabled={currentTab === TabEnum.Creating}
              onClick={() => navigate(TabEnum.EditAccommodations)}
            >
              Due Date<br/>Accommodations
            </button>
          </>
        )}
      </div>
      <button
        className="assessment-builder-nav__exit-btn exit-block__button"
        onClick={handleExit}
      >
        Close <FaTimes />
      </button>
    </nav>
  );
}

AssessmentNav.propTypes = {
  assessmentName: PropTypes.string,
  currentTab: PropTypes.oneOf(Object.values(TabEnum)).isRequired,
  handleExit: PropTypes.func.isRequired,
  isSummative: PropTypes.bool,
  navigate: PropTypes.func.isRequired,
};

export default AssessmentNav;
