/**
 * QuestionListItemRow
 *
 * Potentially Reusable
 **/
import React from 'react';
import PropTypes from 'prop-types';
import getLoDataFromCombinedQuestion from 'utils/getLoDataFromCombinedQuestion';
import { useAppSelector } from 'store';
import { ActiveCombinedQuestion } from 'store/selectors/retrieveActiveCombinedQuestions';
import retrieveFilterableQuestionTypes from 'store/selectors/retrieveFilterableQuestionTypes';
import AddRemoveButton from 'shared-components/AddRemoveButton/AddRemoveButton';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import CustomItemIcon from 'shared-components/CustomItemIcon/CustomItemIcon';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import {
  QuestionAction,
  QuestionActionEnum,
  TabEnum,
  TabNavigate,
} from '../../AssessmentBuilderController.types';
import { AddRemoveEnum, ContentTypeEnum, PositionEnum } from 'types/common.types';
import { GradingTypeTag } from 'types/backend/l8y.types';
import { AssessmentQuestionMetadata, QuestionPreviewLaunchWithMetadata } from 'utils/getAssessmentQuestionsMetadata';

const QuestionListItemRow = ({
  currentAssessmentId,
  isGrouped = false,
  isProcessing,
  navigate,
  assessmentQuestionMetadata,
  question,
  questionTitle,
  questionAction,
  setQuestionPreview,
}: {
  currentAssessmentId: string
  isGrouped?: boolean
  isProcessing?: boolean
  navigate: TabNavigate
  assessmentQuestionMetadata: Array<AssessmentQuestionMetadata>
  question: ActiveCombinedQuestion
  questionAction: QuestionAction
  questionTitle?: string
  setQuestionPreview: (preview: QuestionPreviewLaunchWithMetadata | null) => void
}) => {
  const assessments = useAppSelector((store) => store.active.assessments);
  const filterableQuestionTypes = useAppSelector(retrieveFilterableQuestionTypes);
  const user = useAppSelector((store) => store.user);
  const {
    assessmentIds: itemAssessmentIds = [],
    blooms,
    id: questionId,
    l8yId,
    type,
    userId,
    questionGroup,
    questionTypes = [],
    gradingType,
    userId: ownerUserId,
  } = question;
  const isAssigned = itemAssessmentIds.includes(currentAssessmentId);
  let abbreviation = 'Unknown';

  if (questionTypes.length === 1) {
    const qType = filterableQuestionTypes.find(({ l8yTypes }) => l8yTypes.includes(questionTypes[0]));
    if (qType) {
      ({ abbreviation } = qType);
    }
  } else if (questionTypes.length > 1) {
    abbreviation = 'Multi';
  }

  // previously assigned
  const otherAssessmentIds = itemAssessmentIds.filter(id => id !== currentAssessmentId);
  const assessmentNamesArray = itemAssessmentIds.reduce((acc: Array<string>, assessmentId: string) => {
    const { name } = assessments.find(a => a.id === assessmentId) || {};
    if (!name) {
      return acc;
    }
    return [
      ...acc,
      `Assessment: ${name}`,
    ];
  }, []);

  const { losToShow } = getLoDataFromCombinedQuestion(question);
  const { allowRemove, allowAdd } = assessmentQuestionMetadata.find(pq => pq.questionId === questionId) as AssessmentQuestionMetadata;
  const isUserQuestion = type === LibraryTypeEnum.User && userId === user.id;

  return (
    <tr className="question-list__item-row" data-l8yid={l8yId} data-isgrouped={isGrouped} data-isuserquestion={isUserQuestion}>
      <td className="question-list__item-title">
        <div className="question-list__item-title-wrap">
          {type === LibraryTypeEnum.User && (
            <CustomItemIcon
              onClick={() => navigate(TabEnum.EditQuestion, question)}
              ownerUserId={ownerUserId}
              className='question-list__edit-custom-question'
              contentType={ContentTypeEnum.Question}
            />
          )}
          <button
            className="question-list__assessment-preview"
            onClick={() => setQuestionPreview({ initialQuestionId: questionId, questions: assessmentQuestionMetadata, activeAssessmentId: currentAssessmentId })}
          >
            {isGrouped && !!questionGroup && (
              <span className="question-list__group-order">
                {questionGroup.order}
              </span>
            )}
            {questionTitle || question.title}
          </button>
          <span className="question-list__badge">
            {abbreviation}
          </span>
          {gradingType === GradingTypeTag.Survey && (
            <span className="question-list__badge">
              Survey
            </span>
          )}
        </div>
        {!!otherAssessmentIds.length && (
          <div className="question-list__assigned-pill">
            <BetterTooltip
              content={
                <div key={question.id}>
                  <p>This item has been previously assigned</p>
                  {assessmentNamesArray.map((assessmentName: string) => <p key={`${question.id}_${assessmentName}`}>{assessmentName}</p>)}
                </div>
              }
            >
              previously assigned
            </BetterTooltip>
          </div>
        )}
      </td>
      <td className="question-list__item-lo-list">
        <div className="question-list__lo-items">
          {losToShow.map(({ id, lo, title, topicName, loStringId }) => (
            <div key={id} className="question-list__lo-tooltip" data-lo-stringid={loStringId}>
              <BetterTooltip content={<><b>{topicName}</b><p>{title}</p></>} position={PositionEnum.Right}>
                <div className="question-list__lo-string">{lo}</div>
              </BetterTooltip>
            </div>
          ))}
        </div>
      </td>
      <td className="question-list__item-blooms">
        <div className="item-blooms__value">{blooms || '-'}</div>
      </td>
      <td className="question-list__info-action">
        <AddRemoveButton
          allowAdd={allowAdd}
          allowRemove={allowRemove}
          className="question-list"
          handleAdd={() => questionAction(QuestionActionEnum.AddQuestion, { questionId, assessmentId: currentAssessmentId })}
          handleRemove={() => questionAction(QuestionActionEnum.RemoveQuestion, { questionId, assessmentId: currentAssessmentId })}
          isProcessing={isProcessing}
          verb={isAssigned ? AddRemoveEnum.Remove : AddRemoveEnum.Add}
        />
      </td>
    </tr>
  );
};

QuestionListItemRow.propTypes = {
  currentAssessmentId: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
  isGrouped: PropTypes.bool,
  isProcessing: PropTypes.bool,
  assessmentQuestionMetadata: PropTypes.array.isRequired,
  question: PropTypes.object.isRequired,
  questionTitle: PropTypes.string,
  questionAction: PropTypes.func.isRequired,
  setQuestionPreview: PropTypes.func.isRequired,
};

export default QuestionListItemRow;
