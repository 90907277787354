/**
 * retrieveEnrichedCurrentClassSession
 *
 * This selector should go away IMHO, any place where we need class session should proceed from the classSessionId url query
 */
import { createSelector } from '@reduxjs/toolkit';
import cloneDeep from 'lodash-es/cloneDeep';

import retrieveEnrichedClassSessions, { EnrichedClassSession } from 'store/selectors/retrieveEnrichedClassSessions';
import { Store } from 'types/store.types';

export default createSelector(
  retrieveEnrichedClassSessions,
  (store: Store) => store.active.currentClassSession,
  (enrichedClassSessions: Array<EnrichedClassSession>, currentSession) => {
    if (!currentSession || !enrichedClassSessions) {
      return null;
    }

    const enrichedSession = enrichedClassSessions.find(es => es.id === currentSession.id) as EnrichedClassSession;
    return cloneDeep(enrichedSession);
  }
);

