import React, { MouseEvent, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FiInfo } from 'react-icons/fi';
import clsx from 'utils/clsx';
import { PortalEnum, PositionEnum } from 'types/common.types';
import './BetterTooltip.scss';

/******
 * All-purpose tooltip that should be used going forward
 * Defaults to right positioning, if you're adding a tooltip to something that's way to the right of the screen
 * update the positioning accordingly
*/
interface SharedTooltipProps {
  className?: string
  inModal?: boolean
  position?: PositionEnum
  wide?: boolean
  delayPopup?: boolean
}

function BetterTooltipPopup({
  children,
  className = '',
  inModal,
  delayPopup,
  offset,
  position = PositionEnum.Right,
  wide,
}: SharedTooltipProps & {
  children: React.ReactNode
  offset: { x: number; y: number }
}) {
  const popupClassName = `better-tooltip-popup popup-${position} ${inModal ? 'modal-tooltip' : 'standard-tooltip'}`;
  const popupOutput = (
    <div
      className={clsx(popupClassName, delayPopup && 'delay-popup')}
      style={{ 'top': `${offset.y}px`, 'left': `${offset.x}px` }}
    >
      <div className={`better-tooltip__container ${wide ? 'better-tooltip__wide' : ''} ${className}`}>{children}</div>
    </div>
  );
  const portalDiv = document.getElementById(PortalEnum.TooltipPortal);
  if (portalDiv) {
    return ReactDOM.createPortal(popupOutput, portalDiv);
  }
  console.warn('Portal div not found');
  return null;
}

const calculatePositionOffset = (domRect: DOMRect, pos: PositionEnum) => {
  // top center
  switch (pos) {
    case PositionEnum.Top: {
      // top center coords
      return {
        x: domRect.x + domRect.width / 2,
        y: domRect.y,
      };
    }
    case PositionEnum.BottomRight:
    case PositionEnum.Bottom: {
      // bottom center coords
      return {
        x: domRect.x + domRect.width / 2,
        y: domRect.y + domRect.height,
      };
    }
    case PositionEnum.BottomLeft: {
      // bottom left coords
      return {
        x: domRect.x,
        y: domRect.y + domRect.height,
      };
    }
    case PositionEnum.Left: {
      // left middle coords
      return {
        x: domRect.x,
        y: domRect.y + domRect.height / 2,
      };
    }
    case PositionEnum.Right: {
      // right middle coords
      return {
        x: domRect.x + domRect.width,
        y: domRect.y + domRect.height / 2,
      };
    }
  }
};

function BetterTooltip({
  children,
  className,
  content,
  disableAutoPosition = false,
  forceShow,
  indicate,
  inModal,
  delayPopup = false,
  position = PositionEnum.Right,
  wide,
}: SharedTooltipProps & {
  children?: React.ReactNode
  className?: string
  content: React.ReactNode
  disableAutoPosition?: boolean
  forceShow?: boolean
  indicate?: boolean
}) {
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [showPopup, setShowPopup] = useState(false);
  let pos = position || PositionEnum.Right;
  const doForce = forceShow || false;
  const isWide = wide || false;

  // handle tooltips too close to the sides of the screen
  if (disableAutoPosition) {
    const edgeMargin = 200;
    switch (pos) {
      case PositionEnum.Right:
      case PositionEnum.BottomRight: {
        const isTooNearTheRightSide = (offset.x + edgeMargin) > window.innerWidth;
        if (isTooNearTheRightSide) {
          pos = PositionEnum.Left;
        }
        break;
      }
      case PositionEnum.Left:
      case PositionEnum.BottomLeft: {
        const isTooNearTheLeftSide = offset.x < edgeMargin;
        if (isTooNearTheLeftSide) {
          pos = PositionEnum.Right;
        }
        break;
      }
    }
  }

  function handleMouseEnter(target: HTMLElement): void {
    if (!showPopup) {
      const rect = target.getBoundingClientRect();
      const calculated = calculatePositionOffset(rect, pos);
      setOffset(calculated);
      setShowPopup(true);
    }
  }

  function handleMouseLeave(e?: MouseEvent) {
    setShowPopup(false);
  }

  const tooltipClass = !!className ? className : '';
  return (
    <span
      className={`better-tooltip__wrap ${tooltipClass}`}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="better-tooltip__trigger"
        onMouseOver={({ target }) => handleMouseEnter(target as HTMLElement)}
        onFocus={({ target }) => handleMouseEnter(target)}
        onBlur={() => handleMouseLeave()}
      >
        {children} {!!indicate && <FiInfo />}
      </div>
      {(doForce || showPopup) && (
        <BetterTooltipPopup
          className={`tooltip-${pos} ${tooltipClass}`}
          inModal={inModal}
          offset={offset}
          position={pos}
          delayPopup={delayPopup}
          wide={isWide}
        >
          {typeof content === 'function' ? content() : content}
        </BetterTooltipPopup>
      )}
    </span>
  );
}

BetterTooltip.props = {
  children: PropTypes.node,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  indicate: PropTypes.bool,
  inModal: PropTypes.bool,
  position: PropTypes.oneOf(Object.values(PositionEnum)),
  forceShow: PropTypes.bool,
  wide: PropTypes.bool,
};

BetterTooltip.defaultProps = {
  indicate: false,
  inModal: false,
};

export default BetterTooltip;
