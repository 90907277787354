// minimal class session interface to support compatibility between EnrichedActiveClassSession & BetterClassSession
interface ClassSessionWithClassNumber {
  id: number
  classNumber?: number
  _derived?: {
    classNumber: number
  }
}

const getClassDaysCoveredFromClassSessionIds = (classSessionIds: Array<number>, classSessions: Array<ClassSessionWithClassNumber>) => {
  return classSessions.reduce((acc, session) => {
    if (classSessionIds.includes(session.id)) {
      const classNumber = session._derived?.classNumber || session.classNumber || 0;
      if (classNumber === 0) {
        console.error('classNumber not found in class session object', session);
      }
      return [
        ...acc,
        classNumber,
      ];
    }
    return acc;
  }, [] as Array<number>);
};

export default getClassDaysCoveredFromClassSessionIds;
