import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { formatName } from 'utils/commonFormattingFunctions';
import { validUuid } from 'utils';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import { RoleEnum } from 'types/backend/roles.types';
import { UserApi } from 'types/backend/users.types';
import './UsersContainer.scss';

type UsersContainerPropTypes = {
  users: Array<UserApi>
  moreUsersThanLimit: boolean
  getUserById: (userId: string) => void
  getUsersByEmail: (email: string) => void
  getUsersByName: (firstName: string | null, lastName: string | null) => void
  launchUserDashboard: (masqueradeUser: UserApi) => void
}

export default function UsersContainer(props: UsersContainerPropTypes) {
  const {
    users,
    moreUsersThanLimit,
    getUserById,
    getUsersByEmail,
    getUsersByName,
    launchUserDashboard,
  } = props;

  const [emailInput, setEmailInput] = useState('');
  const [idInput, setIdInput] = useState('');
  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');

  const enableSubmitEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailInput);
  const enableSubmitId = validUuid(idInput);
  const enableSubmitName = !!(firstNameInput || lastNameInput);

  function mapSystemRole(roleId: RoleEnum): string {
    switch (roleId) {
      case RoleEnum.Admin:
        return 'admin';
      case RoleEnum.Instructor:
        return 'instructor';
      case RoleEnum.Student:
        return 'student';
      case RoleEnum.Ta:
        return 'teaching assistant';
      // no default
    }
  }

  async function doSearchForUserById(event: React.SyntheticEvent) {
    event.preventDefault();
    await getUserById(idInput);
  }

  async function doSearchForUserByEmail(event: React.SyntheticEvent) {
    event.preventDefault();
    await getUsersByEmail(emailInput);
  }

  async function doSearchForUserByName(event: React.SyntheticEvent) {
    event.preventDefault();
    await getUsersByName(firstNameInput, lastNameInput);
  }

  return (
    <div className="users-container">
      <p>Search for non-admin user by email, id, or name</p>
      <div className="users-container-search">
        <div className="users-container-search__row">
          <form className="users-container-search__form" onSubmit={doSearchForUserByEmail}>
            <label htmlFor="user-email">Email</label>
            <input
              id="user-email"
              type="text"
              value={emailInput}
              placeholder="Email"
              onChange={e => setEmailInput(e.target.value)}
            />
            <BetterButton
              className="users-container-search__search-button"
              disabled={!enableSubmitEmail}
              primary
              text="Search by Email"
              onClick={doSearchForUserByEmail}
            />
          </form>
          <form className="users-container-search__form" onSubmit={doSearchForUserById}>
            <label htmlFor="user-id">User Id</label>
            <input
              id="user-id"
              type="text"
              value={idInput}
              placeholder="User Id"
              onChange={e => setIdInput(e.target.value)}
            />
            <BetterButton
              className="users-container-search__search-button"
              disabled={!enableSubmitId}
              primary
              text="Search by User Id"
              onClick={doSearchForUserById}
            />
          </form>
        </div>
        <div className="users-container-search__row">
          <form className="users-container-search__form" onSubmit={doSearchForUserByName}>
            <label htmlFor="user-first-name">First name</label>
            <input
              id="user-first-name"
              type="text"
              value={firstNameInput}
              placeholder="First Name"
              onChange={e => setFirstNameInput(e.target.value)}
            />
            <label htmlFor="user-last-name">Last name</label>
            <input
              id="user-last-name"
              type="text"
              value={lastNameInput}
              placeholder="Last Name"
              onChange={e => setLastNameInput(e.target.value)}
            />
            <BetterButton
              className="users-container-search__search-button"
              disabled={!enableSubmitName}
              primary
              text="Search by Name"
              onClick={doSearchForUserByName}
            />
          </form>
        </div>
      </div>
      <div className="users-container-table">
        {moreUsersThanLimit && (
          <p>There are more users than can be shown on this page. You may need to narrow your search results.</p>
        )}
        <table>
          <thead>
            <tr>
              <th>id</th>
              <th>name</th>
              <th>email</th>
              <th>system role</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!!users && users.map(user => {
              const { id, lastName, firstName, email, roleId } = user;
              const name = formatName(lastName, firstName);
              const role = mapSystemRole(roleId);
              return (
                <tr className="users-container-table__item-row" key={`users-row_${id}`}>
                  <td>
                    {id}
                  </td>
                  <td>
                    {name}
                  </td>
                  <td>
                    {email}
                  </td>
                  <td>
                    {role}
                  </td>
                  <td>
                    <BetterButton
                      primary
                      text="Masquerade"
                      className="users-container-table__launch-masquerade-button"
                      onClick={() => launchUserDashboard(user)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

UsersContainer.propTypes = {
  users: PropTypes.array,
  moreUsersThanLimit: PropTypes.bool,
  getUserById: PropTypes.func.isRequired,
  getUsersByEmail: PropTypes.func.isRequired,
  getUsersByName: PropTypes.func.isRequired,
  launchUserDashboard: PropTypes.func.isRequired,
};


