import React, { Dispatch, SetStateAction, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { formatPoints } from 'utils/commonFormattingFunctions';
import {
  getNextValidLateDate,
  getPresetLatePenalty,
  getMinDueDateForAssessment,
  getAssessmentStatus,
} from 'utils/assessmentFunctions';
import confirmationMsgs from '../AssessmentBuilderController/AssessmentBuilderConfirmationMessages';
import sharedStrings from 'sharedStrings';
import { useAppSelector } from 'store';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import DateTimePicker from 'shared-components/DateTimePicker/DateTimePicker';
import ToggleSwitch from 'shared-components/ToggleSwitch/ToggleSwitch';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import ChangeDueDateConfirmationPrompt, { DueDateConfirmationStepEnum } from './ChangeDueDateConfirmationPrompt';
import EnableGradeSyncConfirmationPrompt from './EnableGradeSyncConfirmationPrompt';
import { YesNo } from 'types/backend/shared.types';
import { AssessTypeEnum, GradingPolicyEnum } from 'types/backend/assessments.types';
import { AssessmentStatus, ConfirmationTypeEnum } from 'types/common.types';
import { InstructorCoursePath } from 'types/instructor.types';
import { AssessmentListEvents, AssessmentListEventData } from './AssessmentsList.types';
import { TabEnum } from '../AssessmentBuilderController/AssessmentBuilderController.types';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import { EnrichedStudentAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';
import './AssessmentsList.scss';

interface AssessmentsListItemProps {
  defaultMinOpenDate: Date
  defaultMaxDueDate: Date
  disablePublished: boolean
  isEditMode: boolean
  assessment: EnrichedStudentAssessment
  onChangeAssessment: (type: AssessmentListEvents, id: string, eventData: AssessmentListEventData) => void
  startedIds: Array<string>
  hideGradeSyncPrompt: boolean
  setHideGradeSyncPrompt: Dispatch<SetStateAction<boolean>>
}

const AssessmentsListItem = ({
  defaultMinOpenDate,
  defaultMaxDueDate,
  disablePublished,
  isEditMode,
  assessment,
  onChangeAssessment,
  startedIds,
  hideGradeSyncPrompt,
  setHideGradeSyncPrompt,
}: AssessmentsListItemProps) => {

  const { id: courseId, isGradeSyncEnabled: courseIsGradeSyncEnabled, timeZone } = useAppSelector((store) => store.active.course);
  const summativeAssessmentSupplements = useAppSelector((store) => store.active.summativeAssessmentSupplements);
  const courseAssessmentPreset = useAppSelector((store) => store.active.courseAssessmentPreset);

  const { triggerConfirmationPrompt } = useConfirmationPrompt();

  const {
    assessType,
    dueDate: defaultDueDate,
    id,
    isGradeSyncEnabled: defaultAssessmentGradeSyncEnabled,
    gradingPolicy,
    latePolicy,
    lateDate: defaultLateDate,
    latePenalty: defaultLatePenalty,
    name,
    openDate: defaultOpenDate,
    published,
    totalQuestions,
    totalPoints,
  } = assessment;
  const presetLatePenalty = getPresetLatePenalty(assessType, courseAssessmentPreset);
  const [publishedToggle, setPublishedToggle] = useState(published === YesNo.Yes);
  const [gradeSyncToggle, setGradeSyncToggle] = useState(defaultAssessmentGradeSyncEnabled);
  const [openDate, setOpenDate] = useState(new Date(defaultOpenDate));
  const [dueDate, setDueDate] = useState(new Date(defaultDueDate));
  const [latePolicyToggle, setLatePolicyToggle] = useState(latePolicy === YesNo.Yes);
  const [lateDate, setLateDate] = useState(!!defaultLateDate ? new Date(defaultLateDate) : null);
  const [latePenalty, setLatePenalty] = useState(defaultLatePenalty !== null ? defaultLatePenalty : null);
  const [hasInvalidOpenDueDates, setHasInvalidOpenDueDates] = useState(false);
  const [hasInvalidDueLateDates, setHasInvalidDueLateDates] = useState(false);
  const [isExtendingDueDate, setIsExtendingDueDate] = useState(false);
  const [newDueDate, setNewDueDate] = useState(new Date(defaultDueDate));
  const [confirmationIsShowing, setConfirmationIsShowing] = useState(false);
  const [modalStep, setModalStep] = useState(null as DueDateConfirmationStepEnum | null);
  const [gradeSyncConfirmationIsShowing, setGradeSyncConfirmationIsShowing] = useState(false);

  // if user clicks on Prep or Practice Test links we need to pass the underlying summative id to AssessmentBuilder instead of the prep or practice id
  const getSummativeAssessmentId = (assessmentId: string): string => {
    const foundSupplement = summativeAssessmentSupplements.find((summ) => Object.values(summ).includes(assessmentId));
    if (foundSupplement) {
      return foundSupplement.id;
    }
    return assessmentId;
  };

  const assessmentIdParam = getSummativeAssessmentId(id);
  const assessmentLink = `/instructor/course/${courseId}/${InstructorCoursePath.AssessmentBuilder}?assessment=${assessmentIdParam}&tab=${TabEnum.Editing}`;

  const assessmentStatus = getAssessmentStatus(startedIds.includes(id), published);

  const isStarted = assessmentStatus === AssessmentStatus.Started;
  const gradeSyncStatusString = gradeSyncToggle ? 'On' : 'Off';
  const editableAssessTypes = [
    AssessTypeEnum.Homework,
    AssessTypeEnum.Preclass,
    AssessTypeEnum.Readiness,
  ];
  const allowEditDueDate = isEditMode && editableAssessTypes.includes(assessType);
  const allowEditPublished = isEditMode && !isStarted;
  const allowEditOpenDate = allowEditDueDate && !isStarted;

  const onChangePublished = (isChecked: boolean) => {
    setPublishedToggle(isChecked);
    if (!isChecked && gradeSyncToggle) {
      onChangeGradeSync(false);
    }
    onChangeAssessment(
      AssessmentListEvents.ChangePublished,
      id,
      {
        published: isChecked ? YesNo.Yes : YesNo.No,
        hasInvalidOpenDueDates,
        hasInvalidDueLateDates,
        assessType,
      }
    );
  };

  const onChangeGradeSync = (isChecked: boolean) => {
    if (isChecked && !hideGradeSyncPrompt) {
      setGradeSyncConfirmationIsShowing(true);
    }
    setGradeSyncToggle(isChecked);
    onChangeAssessment(
      AssessmentListEvents.ChangeGradeSyncEnabled,
      id,
      {
        isGradeSyncEnabled: isChecked,
        hasInvalidOpenDueDates,
        hasInvalidDueLateDates,
        assessType,
      }
    );
  };

  const onChangeOpenDate = (dateVal: Date) => {
    if (dueDate <= dateVal) {
      setHasInvalidOpenDueDates(true);
    } else if (hasInvalidOpenDueDates && dueDate > dateVal) {
      setHasInvalidOpenDueDates(false);
    }
    setOpenDate(dateVal);
    onChangeAssessment(
      AssessmentListEvents.ChangeOpenDate,
      id,
      {
        openDate: DateTime.fromJSDate(dateVal).toISO(),
        hasInvalidOpenDueDates: dueDate <= dateVal,
        hasInvalidDueLateDates,
      }
    );
  };

  const onChangeDueDate = (dateVal: Date, invalidOpenDueDates: boolean, invalidDueLateDates: boolean) => {
    setDueDate(dateVal);
    onChangeAssessment(
      AssessmentListEvents.ChangeDueDate,
      id,
      {
        dueDate: DateTime.fromJSDate(dateVal).toISO(),
        hasInvalidOpenDueDates: invalidOpenDueDates,
        hasInvalidDueLateDates: invalidDueLateDates,
      }
    );
  };

  const onChangeDueDateCheck = (dateVal: Date) => {
    setNewDueDate(dateVal);
    if (dateVal <= openDate) {
      setHasInvalidOpenDueDates(true);
      setConfirmationIsShowing(true);
    } else if (hasInvalidOpenDueDates && dateVal > openDate) {
      setHasInvalidOpenDueDates(false);
      setConfirmationIsShowing(false);
    }
    let extendingDue = false;
    let invalidDueLate = false;
    if (!!lateDate) {
      if (dateVal >= lateDate) {
        invalidDueLate = true;
        setConfirmationIsShowing(true);
      } else if (hasInvalidDueLateDates && dateVal < lateDate) {
        invalidDueLate = false;
      }
    }
    if (isStarted) {
      if (+dateVal !== +defaultDueDate) {
        const now = DateTime.now();
        if (DateTime.fromJSDate(dateVal) >= now && DateTime.fromJSDate(dueDate) < now) {
          extendingDue = true;
          setModalStep(DueDateConfirmationStepEnum.ExtendDue);
          setConfirmationIsShowing(true);
        }
      }
    }
    setHasInvalidDueLateDates(invalidDueLate);
    setIsExtendingDueDate(extendingDue);
    if (extendingDue) {
      // don't set the date yet because we're giving the user the chance to cancel in the confirmation modal
      return;
    }
    onChangeDueDate(dateVal, dateVal <= openDate, !!lateDate && dateVal >= lateDate);
  };


  const onChangeLateDateCheck = (dateVal: Date) => {
    if (!!lateDate) {
      if (dateVal <= dueDate) {
        setHasInvalidDueLateDates(true);
      } else if (hasInvalidDueLateDates && dateVal > dueDate) {
        setHasInvalidDueLateDates(false);
      }
      if (isStarted) {
        if (defaultLateDate === null || +dateVal !== +new Date(defaultLateDate)) {
          const now = DateTime.now();
          if (DateTime.fromJSDate(dateVal) >= now && DateTime.fromJSDate(lateDate) < now) {
            triggerConfirmationPrompt({
              title: sharedStrings.EXTEND_LATE_DATE_TITLE,
              message: confirmationMsgs.changeLateDateAfterStudentsStartedConfMessage,
              confirmButtonText: sharedStrings.EXTEND_LATE_DATE_TITLE,
              onConfirm: () => onChangeLateDate(dateVal, dateVal < dueDate),
              onCancel: () => {},
              confirmationType: ConfirmationTypeEnum.Warn,
            });
            return;
          }
        }
      }
      onChangeLateDate(dateVal, dateVal <= dueDate);
    }
  };

  const onChangeLateDate = (dateVal: Date, invalidDueLateDates: boolean) => {
    setLateDate(dateVal);
    onChangeAssessment(
      AssessmentListEvents.ChangeLateDate,
      id,
      {
        lateDate: DateTime.fromJSDate(dateVal).toISO(),
        hasInvalidOpenDueDates,
        hasInvalidDueLateDates: invalidDueLateDates,
      }
    );
  };

  const onChangeLatePolicy = (isChecked: boolean) => {
    setLatePolicyToggle(isChecked);
    let newLateDate: Date | null;
    let newLatePenalty: number | null;
    if (isChecked) {
      newLateDate = getNextValidLateDate(assessType, timeZone, dueDate, courseAssessmentPreset);
      newLatePenalty = latePenalty !== null ? latePenalty : presetLatePenalty;
    } else {
      newLateDate = null;
      newLatePenalty = null;
      setHasInvalidDueLateDates(false);
    }
    setLateDate(newLateDate);
    setLatePenalty(newLatePenalty);
    onChangeAssessment(
      AssessmentListEvents.ChangeLatePolicy,
      id,
      {
        latePolicy: isChecked ? YesNo.Yes : YesNo.No,
        lateDate: newLateDate !== null ? DateTime.fromJSDate(newLateDate).toISO() : null,
        latePenalty: newLatePenalty,
        hasInvalidOpenDueDates,
        hasInvalidDueLateDates: isChecked ? hasInvalidDueLateDates : false,
      }
    );
  };

  const renderLatePolicy = () => {
    const now = DateTime.now();
    const disableEditLatePolicy = gradingPolicy === GradingPolicyEnum.NoPoints
      || (
        isStarted && latePolicy === YesNo.Yes &&
        (+now > +DateTime.fromJSDate(dueDate) || +now > +DateTime.fromJSDate(lateDate as Date))
      );
    if (isEditMode) {
      if ([GradingPolicyEnum.NoPoints, GradingPolicyEnum.External].includes(gradingPolicy)) {
        return (
          <span><em>Assessment not for points</em></span>
        );
      } else {
        return (
          <div className="assessment-list__late-date__content">
            {latePolicyToggle && (
              <DateTimePicker
                className="assessment-date__datetime-picker late-date-picker"
                disabled={false}
                invalid={hasInvalidDueLateDates}
                limitTime={isStarted}
                maxDate={defaultMaxDueDate}
                minDate={getMinDueDateForAssessment(isStarted, assessType === AssessTypeEnum.Summative, defaultMinOpenDate)}
                name="late-date-picker"
                onChange={(dateVal) => !!dateVal && onChangeLateDateCheck(dateVal)}
                value={lateDate}
              />
            )}
            <ToggleSwitch
              id={`${id}-late-policy`}
              checked={latePolicyToggle}
              disabled={disableEditLatePolicy}
              onChange={(isChecked) => onChangeLatePolicy(isChecked)}
              data-on="YES"
              data-off="NO"
            />
          </div>
        );
      }
    } else {
      if (latePolicy === YesNo.Yes) {
        return (
          <span>{DateTime.fromISO(defaultLateDate as string).toFormat(DateFormatEnum.WeekdayWithShortDateNoYear)}</span>
        );
      } else {
        return null;
      }
    }
  };

  return (
    <tr
      className="assessment-list__table-row"
      data-assesstype={assessType}
      data-published={published}
      data-assessmentid={assessmentIdParam}
    >
      <td data-cell="name" className="assessment-list__name">
        <Link to={assessmentLink}>
          {name}
        </Link>
        {!!confirmationIsShowing && (
          <ChangeDueDateConfirmationPrompt
            assessmentListItemState={{
              newDueDate,
              openDate,
              dueDate,
              lateDate,
              hasInvalidOpenDueDates,
              hasInvalidDueLateDates,
              isExtendingDueDate,
              modalStep,
            }}
            setDueDate={onChangeDueDate}
            setNewDueDate={setNewDueDate}
            setHasInvalidDueLateDates={setHasInvalidDueLateDates}
            setIsExtendingDueDate={setIsExtendingDueDate}
            setConfirmationIsShowing={setConfirmationIsShowing}
            setModalStep={setModalStep}
          />
        )}
      </td>
      {!!gradeSyncConfirmationIsShowing && (
        <EnableGradeSyncConfirmationPrompt
          setGradeSyncConfirmationIsShowing={setGradeSyncConfirmationIsShowing}
          setHideGradeSyncPrompt={setHideGradeSyncPrompt}
          hideGradeSyncPrompt={hideGradeSyncPrompt}
        />
      )}
      <td className="assessment-list__status-cell" data-cell="status">
        <div>
          {isStarted ? (
            <>
              <span className="hide-md">Started</span>
              <span className="hide-xs show-md">Started by Students</span>
            </>
          ) : (
            <span className={`${isEditMode ? 'hide-xs' : ''} show-md`}>{assessmentStatus}</span>
          )}
          {allowEditPublished && (
            <ToggleSwitch
              id={`${id}-is-published`}
              checked={publishedToggle}
              onChange={(isChecked) => onChangePublished(isChecked)}
              data-on="YES"
              data-off="NO"
              disabled={disablePublished}
            />
          )}
        </div>
      </td>
      {!!courseIsGradeSyncEnabled && (
        <td className="assessment-list__status-cell" data-cell="grade-sync">
          {isEditMode ? (
            <ToggleSwitch
              id={`${id}-grade-sync-enabled`}
              checked={gradeSyncToggle}
              onChange={(isChecked) => onChangeGradeSync(isChecked)}
              disabled={!publishedToggle}
            />
          ) : gradeSyncStatusString}
        </td>
      )}
      <td className="assessment-list__number-cell" data-cell="total-items">
        {totalQuestions}
      </td>
      <td className="assessment-list__number-cell" data-cell="total-points">
        {formatPoints(totalPoints, true)}
      </td>
      <td data-cell="open-date" className="assessment-list__date">
        {editableAssessTypes.includes(assessType) ? (
          <>
            {allowEditOpenDate ? (
              <DateTimePicker
                className="assessment-date__datetime-picker open-date-picker"
                disabled={false}
                invalid={hasInvalidOpenDueDates}
                limitTime={isStarted}
                maxDate={defaultMaxDueDate}
                minDate={defaultMinOpenDate}
                name="open-date-picker"
                onChange={(dateVal) => !!dateVal && onChangeOpenDate(dateVal)}
                value={openDate}
              />
            ) :
              DateTime.fromISO(defaultOpenDate).toFormat(DateFormatEnum.WeekdayWithShortDateNoYear)
            }
          </>
        ) : (
          <BetterTooltip
            content={assessType === AssessTypeEnum.Prep ?
              <>Students can start a topic's Prep Questions once they move that topic into Checkpoint 2. This won't happen until they finish a Homework or Pre-class assessment covering that topic.</>
              : <>Students can start the Practice Test once (1) they've completed all assignments and moved all topics into Checkpoint 3 <strong>or</strong> (2) the Study Path is due in 3 or fewer days.</>
            }
            indicate
          />
        )}
      </td>
      <td data-cell="due-date" className="assessment-list__date">
        <>
          {allowEditDueDate ? (
            <DateTimePicker
              className="assessment-date__datetime-picker due-date-picker"
              disabled={false}
              invalid={hasInvalidOpenDueDates || hasInvalidDueLateDates}
              limitTime={isStarted}
              maxDate={defaultMaxDueDate}
              minDate={getMinDueDateForAssessment(isStarted, assessType === AssessTypeEnum.Summative, defaultMinOpenDate)}
              name="due-date-picker"
              onChange={(dateVal) => !!dateVal && onChangeDueDateCheck(dateVal)}
              value={dueDate}
            />
          ) :
            DateTime.fromISO(defaultDueDate).toFormat(DateFormatEnum.WeekdayWithShortDateNoYear)
          }
        </>
      </td>
      <td data-cell="late-date" data-wide={isEditMode} className="assessment-list__date assessment-list__late-date">
        {renderLatePolicy()}
      </td>
    </tr>
  );
};

AssessmentsListItem.propTypes = {
  defaultMinOpenDate: PropTypes.object.isRequired,
  defaultMaxDueDate: PropTypes.object.isRequired,
  disablePublished: PropTypes.bool,
  isEditMode: PropTypes.bool,
  assessment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assessType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    openDate: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
    gradingPolicy: PropTypes.string.isRequired,
    latePolicy: PropTypes.string.isRequired,
    lateDate: PropTypes.string,
    latePenalty: PropTypes.number,
    isGradeSyncEnabled: PropTypes.bool,
    published: PropTypes.string.isRequired,
    totalQuestions: PropTypes.number,
    totalPoints: PropTypes.number,
  }).isRequired,
  onChangeAssessment: PropTypes.func.isRequired,
  startedIds: PropTypes.array.isRequired,
  hideGradeSyncPrompt: PropTypes.bool,
  setHideGradeSyncPrompt: PropTypes.func.isRequired,
};

export default AssessmentsListItem;
