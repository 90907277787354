import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import useClassSessionQuery from 'hooks/useClassSessionQuery';
import { useAppSelector } from 'store';
import retrieveActiveClassSessions from 'store/selectors/retrieveActiveClassSessions';
import retrieveEnrichedStudentAssessments, { EnrichedStudentAssessment, EnrichedStudentSummativeAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';
import { BetterClassSession } from 'store/selectors/retrieveBetterClassSessions';
import ClassSessionNav from 'shared-components/ClassSessionNav/ClassSessionNav';
import StudentAssessmentLink from 'student/components/StudentAssessmentLink/StudentAssessmentLink';
import DailyCard from 'shared-components/DailyCard/DailyCard';
import ClassDaysCovered from 'shared-components/ClassDaysCovered/ClassDaysCovered';
import RecapIcon from '../RecapIcon';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import { EnrichedActiveClassSession } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { EnrichedClassSessionClr, InstructionEnum } from 'types/common.types';
import { StudentAssessmentStatus } from 'types/backend/studentScoresData.types';
// this depends on some styles that live in DP, duplicate imports are ignored at build time but I'm adding this here deliberately to support future code splitting efforts
import 'instructor/controllers/Course/DailyPlannerController/DailyPlannerController.scss';
import './StudentClassSessionView.scss';

const nameClass = 'col-xs-6';
const coversClass = 'col-xs-3';
const dueDateClass = 'col-xs-3';

interface StudentClassSessionViewProps {
  currentWeekNumber: number
  selectedClassSession: BetterClassSession
}

const determineStatusText = (assessmentStatus: StudentAssessmentStatus, dueDate: DateTime) => {
  switch (assessmentStatus) {
    case StudentAssessmentStatus.CompletedBeforeDue:
      return 'Completed on time';
    case StudentAssessmentStatus.CompletedBeforeLate:
      return 'Completed late';
    case StudentAssessmentStatus.CompletedAfterLate:
      return 'Completed after late date';
    case StudentAssessmentStatus.NotStartedAfterLate:
    case StudentAssessmentStatus.InProgressAfterLate:
      return `${dueDate.toFormat(DateFormatEnum.DateAtTimeMeridean)} (incomplete)`;
    case StudentAssessmentStatus.NotStartedBeforeDue:
    case StudentAssessmentStatus.NotStartedBeforeLate:
    case StudentAssessmentStatus.InProgressBeforeDue:
    case StudentAssessmentStatus.InProgressBeforeLate:
      return `${dueDate.toFormat(DateFormatEnum.DateAtTimeMeridean)}`;
    // no default
  }
};

// this function lives with its parent component so they can share the necessary classNames
function StudentAssignmentsListTable({
  assessments,
  assessType,
  classSessions,
}: {
  assessments: Array<EnrichedStudentAssessment>
  assessType: AssessTypeEnum
  classSessions: Array<EnrichedActiveClassSession>
}) {
  const isStudyPathCard = assessType === AssessTypeEnum.Summative;
  const showClassDaysCovered = !isStudyPathCard;
  const assignmentTitleClass = isStudyPathCard ? 'col-xs-8' : nameClass;
  const dueDateTableClass = isStudyPathCard ? 'col-xs-4' : dueDateClass;
  return (
    <table aria-label="Assignment List" className="student-assessment-list" data-assesstype={assessType}>
      <thead>
        <tr className="visually-hidden">
          <th scope="col">Assignment Title</th>
          {showClassDaysCovered && <th scope="col">Class Days Covered</th>}
          <th scope="col">Due Date</th>
        </tr>
      </thead>
      <tbody>
        {assessments.map((assessment) => {
          const {
            assessmentStatus,
            classSessionIds = [],
            id: assessmentId,
            isOpen,
            mergedDueDate,
          } = assessment;
          const dueDate = DateTime.fromISO(mergedDueDate);
          return (
            <tr
              data-isopen={isOpen}
              className="student-class-session-view__card-row daily-planner__item-row row"
              key={`${assessType}AssessmentId_${assessmentId}`}
            >
              <td className={`daily-card__item-column ${assignmentTitleClass}`}>
                <StudentAssessmentLink
                  className="daily-card__item-link"
                  enrichedStudentAssessment={assessment}
                  key={`assessment-link_${assessmentId}`}
                />
                <RecapIcon assessment={assessment} />
              </td>
              {showClassDaysCovered && (
                <td className={`daily-card__item-column ${coversClass}`}>
                  <ClassDaysCovered classSessionIds={classSessionIds} classSessions={classSessions} />
                </td>
              )}
              <td className={`daily-card__item-column daily-planner__item-duedate ${dueDateTableClass}`}>
                {determineStatusText(assessmentStatus, dueDate)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function StudentClassSessionView({
  currentWeekNumber,
  selectedClassSession,
}: StudentClassSessionViewProps) {
  const classSessions = useAppSelector(retrieveActiveClassSessions);
  const enrichedStudentAssessments = useAppSelector(retrieveEnrichedStudentAssessments);
  const [, setSelectedClassSessionId] = useClassSessionQuery(classSessions);

  const {
    id: classSessionId,
    classNumber: currentClassNumber,
    label: classDayLabel,
    luxonDate,
    groupedAssessmentIds,
    iclrs,
    ooclrs,
    topics,
    classType,
  } = selectedClassSession;


  if (!selectedClassSession) {
    return null;
  }
  const currentCSIndex = classSessions.findIndex(({ id }) => id === classSessionId);
  const { id: prevClassSessionId } = classSessions[currentCSIndex - 1] || {};
  const { id: nextClassSessionId } = classSessions[currentCSIndex + 1] || {};

  const renderInstructionsList = (clrs: Array<EnrichedClassSessionClr>, instructionType: InstructionEnum) => {
    if (!clrs.length) {
      return null;
    }
    return (
      <table aria-label="Class Materials" data-instructiontype={instructionType} className="class-materials-list">
        <thead>
          <tr className="visually-hidden">
            <th scope="col">Title</th>
            <th scope="col">Class Days Covered</th>
            <th scope="col" aria-hidden={true}>Due (not applicable)</th>
          </tr>
        </thead>
        <tbody>
          {clrs.map(({ classSessionIds = [], id: clrId, url: clrUrl, title: clrTitle }) => (
            <tr className="student-class-session-view__card-row daily-planner__item-row row" key={`clr_${clrId}`}>
              <td className={`daily-card__item-column ${nameClass}`}>
                {!!clrUrl
                  ? externalLink(clrUrl, clrTitle)
                  : clrTitle
                }
              </td>
              <td className={`daily-card__item-column ${coversClass}`}>
                <ClassDaysCovered classSessionIds={classSessionIds} classSessions={classSessions} />
              </td>
              <td className={`daily-card__item-column ${dueDateClass}`} aria-hidden={true}>
                -
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const { homework = [], preclass = [], summative = [] } = groupedAssessmentIds;
  // map grouped assessments to enrichedStudentAssessments
  const summativeAssessments = summative.map((groupedId) => enrichedStudentAssessments.find(({ id }) => id === groupedId) as EnrichedStudentSummativeAssessment);
  const filteredSummativeAssessments = summativeAssessments.filter(({ assessType }) => assessType !== AssessTypeEnum.Summative);
  const homeworkAssessments = homework.map((groupedId) => enrichedStudentAssessments.find(({ id }) => id === groupedId) as EnrichedStudentAssessment);
  const preclassAssessments = preclass.map((groupedId) => enrichedStudentAssessments.find(({ id }) => id === groupedId) as EnrichedStudentAssessment);
  const hasLos = topics.some(({ courseLearningObjectives }) => !!courseLearningObjectives.length);
  const isEmpty = [
    !topics.length,
    !hasLos,
    !filteredSummativeAssessments.length,
    !preclass.length,
    !homework.length,
    !iclrs.length,
    !ooclrs.length,
  ].every(Boolean);
  const nonClassDay = classType !== ClassTypeEnum.Normal;

  return (
    <section className="class-session-card">
      <div className="class-session-card__heading">
        <div className="daily-planner__header-details">
          <div className="daily-planner__header-week-display">
            Week {currentWeekNumber}
          </div>
          <h2 className="daily-planner__header-date-display">
            {luxonDate.toFormat(DateFormatEnum.FullWeekdayMonthDate)}
          </h2>
        </div>
        <ClassSessionNav
          onPrev={() => setSelectedClassSessionId(prevClassSessionId)}
          onNext={() => setSelectedClassSessionId(nextClassSessionId)}
          prevDisabled={!prevClassSessionId}
          nextDisabled={!nextClassSessionId}
          classNumber={currentClassNumber}
          nonClassDay={nonClassDay}
        />
      </div>
      <>
        <DailyCard
          className="labeled-class-card"
          header={classDayLabel}
          cardType={classType}
          show={!!classDayLabel}
        />
        <DailyCard
          className="daily-planner__empty"
          header="Nothing Assigned for this Day"
          show={isEmpty}
        >
          Navigate to a different day
        </DailyCard>
        <DailyCard
          className="daily-planner__lo-card"
          cardType="lo-card"
          show={!!topics.length}
          header="Learning Objectives"
        >
          {topics.map(({ courseLearningObjectives, topicId, name: topicName }) => (
            <div className="daily-planner__topic-group" key={`topic-group_${topicId}`}>
              <div className="daily-planner__topic-name">{topicName}</div>
              <div className="daily-planner__topic-los">
                {courseLearningObjectives.map(({ id: courseLoId, title: loTitle, _derived: { loNumber } }) => (
                  <div className="daily-planner__topic-lo" key={`lo_${courseLoId}`}>
                    <div className="daily-planner__topic-lo-number">{loNumber}</div>
                    <div className="daily-planner__topic-lo-title">{loTitle}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </DailyCard>
        <DailyCard
          cardType={AssessTypeEnum.Preclass}
          className="daily-planner__preclass-card"
          show={!!preclassAssessments.length || !!ooclrs.length}
          hideColumnLabels={!preclassAssessments.length && !ooclrs.length}
          header={(
            <div className="student-class-session-view__card-row daily-planner__assessments-header row">
              <h3 className={`daily-card__item-column daily-card__header-title ${nameClass}`}>
                Pre-class Work
              </h3>
              <div className={`daily-card__column-label ${coversClass}`} aria-hidden={true}>
                Covers
              </div>
              <div className={`daily-card__column-label ${dueDateClass}`} aria-hidden={true}>
                Due Date
              </div>
            </div>
          )}
        >
          {renderInstructionsList(ooclrs, InstructionEnum.OutOfClass)}
          {!!ooclrs.length && !!preclassAssessments.length && <hr/>}
          <StudentAssignmentsListTable
            assessments={preclassAssessments}
            assessType={AssessTypeEnum.Preclass}
            classSessions={classSessions}
          />
        </DailyCard>
        <DailyCard
          cardType={InstructionEnum.InClass}
          className="daily-planner__class-materials-card"
          header={(
            <div className="daily-card__row row">
              <h3 className={`daily-card__item-column daily-card__header-title ${nameClass}`}>
                Class Materials
              </h3>
              <div className={`daily-card__column-label ${coversClass}`}>
                Covers
              </div>
              <div className={`daily-card__column-label ${dueDateClass}`} />
            </div>
          )}
          show={!!iclrs.length}
        >
          {renderInstructionsList(iclrs, InstructionEnum.InClass)}
        </DailyCard>
        <DailyCard
          cardType={AssessTypeEnum.Homework}
          className="daily-planner__homework-card"
          header={(
            <div className="student-class-session-view__card-row daily-planner__assessments-header row">
              <h3 className={`daily-card__item-column daily-card__header-title ${nameClass}`}>
                Homework
              </h3>
              <div className={`daily-card__column-label ${coversClass}`} aria-hidden={true}>Covers</div>
              <div className={`daily-card__column-label ${dueDateClass}`} aria-hidden={true}>Due Date</div>
            </div>
          )}
          show={!!homeworkAssessments.length}
        >
          <StudentAssignmentsListTable
            assessments={homeworkAssessments}
            assessType={AssessTypeEnum.Homework}
            classSessions={classSessions}
          />
        </DailyCard>
        <DailyCard
          cardType={AssessTypeEnum.Summative}
          className="daily-planner__assessments daily-planner__studypath"
          show={!!filteredSummativeAssessments.length}
          header={(
            <div className="student-class-session-view__card-row daily-planner__assessments-header row">
              <h3 className="daily-card__item-column daily-card__header-title col-xs-8">
                Study Path
              </h3>
              <div className="daily-card__column-label col-xs-4">Due Date</div>
            </div>
          )}
        >
          <StudentAssignmentsListTable
            assessments={filteredSummativeAssessments}
            assessType={AssessTypeEnum.Summative}
            classSessions={classSessions}
          />
        </DailyCard>
      </>
    </section>
  );
}

StudentClassSessionView.propTypes = {
  selectedClassSession: PropTypes.object,
};

export default StudentClassSessionView;
