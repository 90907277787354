/**
 * retrieveActiveCourseLearningObjectives
 *
 * JP: My goal is to phase out this deeply dependent selector in favor of retrieveLearningObjectivesWithMetadata
 * TODO: Kill this accursed selector after CA-5317 when loNumber lives in the BE
 */
import { createSelector } from '@reduxjs/toolkit';
import cloneDeep from 'lodash-es/cloneDeep';
import unionBy from 'lodash-es/unionBy';
import { DateTime } from 'luxon';

import retrieveEnrichedClassSessions, { enrichLos, EnrichedClassSession, EnrichedClassSessionLearningObjective } from 'store/selectors/retrieveEnrichedClassSessions';
import retrieveEnrichedActiveTopics, { EnrichedActiveTopic } from 'store/selectors/retrieveEnrichedActiveTopics';
import { ClassSessionApi } from 'types/backend/classSessions.types';
import { EnrichedClassSessionClr } from 'types/common.types';
import { Store } from 'types/store.types';
import { TopicApi } from 'types/backend/topics.types';
import { AssessmentApiBase } from 'types/backend/assessments.types';

export interface EnrichedCourseLearningObjective extends EnrichedClassSessionLearningObjective {
  _derived: {
    courseWideSort: number
    loIndex: number
    loDecimalString: string
    loNumber: string
    topicIndex: number
  }
}

export interface EnrichedClassSessionTopic extends TopicApi {
  classSessionTopicId: number
  topicId: number
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
}
export interface EnrichedActiveClassSessionDerived {
  assessments: Array<AssessmentApiBase>
  classNumber: number
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
  iclrs: Array<EnrichedClassSessionClr>
  luxonDate: DateTime
  ooclrs: Array<EnrichedClassSessionClr>
  topics: Array<EnrichedClassSessionTopic>
  weekNumber: number
}
export interface EnrichedActiveClassSession extends ClassSessionApi {
  date: string
  classNumber: number
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
  topics: Array<EnrichedClassSessionTopic>
  _derived: EnrichedActiveClassSessionDerived
}

// sort gets to be its own export because there are times when we want to correctly sort subsets of course LOs
export const sortCourseLearningObjectives = <T extends { _derived: { topicIndex: number; loIndex: number } }>(clos: Array<T>): Array<T> => {
  return [...clos].sort((a, b) => {
    return a._derived.topicIndex - b._derived.topicIndex || a._derived.loIndex - b._derived.loIndex;
  });
};

export function addDerivedAttributesAndSort(
  classSessionLearningObjectives: Array<EnrichedClassSessionLearningObjective>,
  classSessions: Array<EnrichedClassSession>,
  enrichedActiveTopics: Array<EnrichedActiveTopic>
) {
  if (classSessionLearningObjectives.length === 0 || classSessions.length === 0 || enrichedActiveTopics.length === 0) {
    return [];
  }

  // is this really only for generating LO number, 2 different levels of _.unionBy, no
  let sortedEnrichedActiveTopics: Array<EnrichedActiveTopic> = [];

  // get a unique array of all course LOs from all class sessions, correctly sorted
  const uniquedCourseLearningObjectives = classSessions.reduce((acc: Array<EnrichedClassSessionLearningObjective>, session) => {
    const classSessionLos = classSessionLearningObjectives.filter(clo => session.learningObjectives.find(slo => slo.id === clo.id));
    const sessionUserTopics = enrichedActiveTopics.filter(topic => classSessionLos.find(lo => lo.topicId === topic.id));
    // I still don't like this
    sortedEnrichedActiveTopics = unionBy([...sortedEnrichedActiveTopics], sessionUserTopics, 'id');
    const sortedSessionLos = sessionUserTopics.reduce((sortedSessionLosAcc: Array<EnrichedClassSessionLearningObjective>, topic) => {
      sortedSessionLosAcc.push(...classSessionLos.filter(lo => lo.topicId === topic.id));
      return sortedSessionLosAcc;
    }, []);
    if (sortedSessionLos.length > 0) {
      acc = unionBy(acc, sortedSessionLos, 'id');
    }
    return acc;
  }, []);

  //add necessary attributes to each lo in the array
  const updatedCourseLearningObjectives: Array<EnrichedCourseLearningObjective> = uniquedCourseLearningObjectives.map((updatedCourseLo) => {
    const topicIndex = sortedEnrichedActiveTopics.findIndex(topic => topic.id === updatedCourseLo.topicId);
    const enrichedActiveTopic = sortedEnrichedActiveTopics[topicIndex];
    const topicLOs = uniquedCourseLearningObjectives.filter((lo) => lo.topicId === enrichedActiveTopic.id);
    const loIndex = topicLOs.findIndex(tlo => tlo.id === updatedCourseLo.id);
    const loDecimalString = `${topicIndex + 1}.${loIndex + 1}`;
    return {
      ...updatedCourseLo,
      _derived: {
        loDecimalString,
        loIndex,
        loNumber: `LO ${loDecimalString}`, // TODO: we should rename this to `loString` or `loNumberString`, I do not like calling a string a number
        topicIndex,
        courseWideSort: -1, // set courseWideSort to -1 so we don't have to wrangle the types bc it gets updated below
      },
    };
  });
  const sorted = sortCourseLearningObjectives(updatedCourseLearningObjectives);

  return sorted.map((lo, courseLoIndex) => {
    return {
      ...lo,
      _derived: {
        ...lo._derived,
        courseWideSort: courseLoIndex,
      },
    };
  });
}

export default createSelector(
  retrieveEnrichedClassSessions,
  retrieveEnrichedActiveTopics,
  (store: Store) => store.active,
  (enrichedClassSessions, enrichedActiveTopics, active) => {
    const { classSessionLearningObjectives, learningObjectives, topics } = cloneDeep(active);
    const enrichedClassSessionLos = enrichLos(classSessionLearningObjectives, learningObjectives, topics);
    const activeCourseLearningObjectives: Array<EnrichedCourseLearningObjective> = addDerivedAttributesAndSort(enrichedClassSessionLos, enrichedClassSessions, enrichedActiveTopics);
    return activeCourseLearningObjectives;
  }
);
