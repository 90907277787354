import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import useScript from 'hooks/useScript';
import apiNext from 'api-next';
import { delay } from 'utils';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import { UserApi } from 'types/backend/users.types';
import { L8yAuthorSaveData } from 'types/l8yAuthor.types';
import { AuthorAppGetItem } from 'types/backend/l8yItems.types';
import { AuthorAppMode } from 'types/backend/authorInit.types';
import { GradingTypeTag, L8yQuestionType } from 'types/backend/l8y.types';
import { AuthorAppRef, OnL8yAuthorEvent, L8yAuthorWidgetReady } from './LearnosityAuthorContainer.types';

const l8yAuthorDomId = 'learnosity-author';

const LearnosityAuthorContainer = ({
  gradingType,
  mode = AuthorAppMode.ItemEdit,
  onL8yEvent,
  reference = false,
  renderFooter,
  setItemJson,
  subject,
  subjectId,
  user,
}: {
  gradingType: GradingTypeTag
  mode?: AuthorAppMode
  onL8yEvent?: (event: OnL8yAuthorEvent, eventData: unknown, route: string, safeToUnload: boolean) => void
  reference?: string | false
  renderFooter?: (l8yAuthoringSaveCallback: () => Promise<AuthorAppGetItem | null>) => ReactElement
  setItemJson?: React.Dispatch<React.SetStateAction<AuthorAppGetItem | null>>
  subject?: string
  subjectId: number
  user: UserApi
}) => {
  const status = useScript('https://authorapi.learnosity.com?v2023.2.LTS');
  const authorAppRef = useRef(null as AuthorAppRef | null);
  const [isLoaded, setIsLoaded] = useState(false);

  const initListeners = useCallback(() => {
    Object.values(OnL8yAuthorEvent).forEach((eventType) => {
      if (authorAppRef.current) {
        authorAppRef.current.on(eventType, (eventData: unknown) => {
          if (!authorAppRef.current) {
            console.error('authorApp not initialized');
            return null;
          }
          // get current location to send with event
          const location = authorAppRef.current.getLocation();
          const safeToUnload = authorAppRef.current.safeToUnload(mode);
          !!onL8yEvent && onL8yEvent(eventType, eventData, location.route, safeToUnload);
          switch (eventType) {
            case OnL8yAuthorEvent.RenderItem:
            case OnL8yAuthorEvent.SaveSuccess:
            case OnL8yAuthorEvent.Save: {
              const { data } = eventData as L8yAuthorSaveData;
              console.debug(':: L8y Saved: Allow Done', eventType, data);
              if (!!setItemJson) {
                const itemJson = authorAppRef.current.getItem();
                setItemJson(itemJson);
              }
              break;
            }
            case OnL8yAuthorEvent.WidgetEditWidgetReady: {
              const { type, wrapper } = eventData as L8yAuthorWidgetReady;
              if (type === L8yQuestionType.EssayRichText) {
                const qeHeading = wrapper.querySelector('.lrn-qe-heading') as Element;
                qeHeading.className = 'lrn-qe-heading lrn-active';
              }
              break;
            }
          }
        });
      }
    });
  }, [mode, onL8yEvent, setItemJson]);

  useEffect(() => {
    // remove listeners when component unloads
    return () => {
      !!authorAppRef.current && authorAppRef.current.off('');
    };
  }, []);

  useEffect(() => {
    const handleCreate = async () => {
      if (authorAppRef.current) {
        await authorAppRef.current.createItem();
      }
    };
    if (status === 'ready' && !isLoaded) {
      const initAuthoring = async () => {
        const { id: userId, email } = user;
        const authInitResponse = await apiNext.createSignedLearnosityAuthorInit({
          userId,
          email,
          mode,
          subjectId,
          subject,
          reference,
          gradingType,
        });
        if (authInitResponse) {
          // eslint-disable-next-line
          const authorMethod = await (window as any).LearnosityAuthor;
          authorAppRef.current = await authorMethod.init(authInitResponse, l8yAuthorDomId, {
            readyListener: () => {
              if (!reference) {
                handleCreate();
              }
              initListeners();
            },
            customUnload: () => {
              console.debug('beforeunload event has been triggered');
              return false;
            },
          });
          setIsLoaded(true);
        }
        return authInitResponse;
      };
      initAuthoring().catch((err: unknown) => console.error('initAuthoring error', err));
    }
  }, [initListeners, isLoaded, mode, reference, status, subject, subjectId, user, gradingType]);

  const l8yAuthoringSaveCallback = async () => {
    if (authorAppRef.current) {
      let newItem = !!authorAppRef.current ? authorAppRef.current.getItem() : null;
      const oldUpdatedAt = newItem?.item.dt_updated || null;
      let newUpdatedAt = oldUpdatedAt;

      const saved = await authorAppRef.current.save();
      if (!saved) {
        console.warn('Learnosity trying to re-save an unchanged question');
      }

      // this is, admittedly, a bit of a hack (but not as bad) to wait for l8y to finish saving, if I try to return getItem immediately I get a tags error
      for (let i = 0; i < 10; i++) {
        // eslint-disable-next-line no-loop-func
        await delay(500).then(() => {
          if (authorAppRef.current) {
            newItem = authorAppRef.current.getItem();
            newUpdatedAt = newItem.item.dt_updated;
          }
        });
        if (oldUpdatedAt !== newUpdatedAt) {
          break;
        }
      }
      return newItem;
    }
    return null;
  };

  return (
    <div className="learnosity-author-container" data-reference={reference}>
      <div className="learnosity-box">
        <div data-reference="learnosity-author" id={l8yAuthorDomId} />
        {!isLoaded && <LoadingSpinner />}
      </div>
      {isLoaded && !!renderFooter && renderFooter(l8yAuthoringSaveCallback)}
    </div>
  );
};

LearnosityAuthorContainer.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  gradingType: PropTypes.oneOf(Object.values(GradingTypeTag)).isRequired,
  mode: PropTypes.oneOf(Object.values(AuthorAppMode)),
  subjectId: PropTypes.number.isRequired,
  subject: PropTypes.string,
  reference: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setItemJson: PropTypes.func,
};

export default LearnosityAuthorContainer;
