// sourced from https://github.com/codonlearning/backend/blob/dev/src/services/studentAssessmentQuestionAttempts/studentAssessmentQuestionAttempts.types.ts
// the StudentAssessmentQuestionAttemptDb definition was removed

import {
  AssessmentLocation,
  CheckpointColumn,
  GenericObject,
  YesNo,
} from 'types/backend/shared.types';
import { FirstAttemptedEnum, StudentAssessmentQuestionApi } from 'types/backend/studentAssessmentQuestions.types';

export enum ClarityEnum {
  Muddy = 'muddy',
  Clear = 'clear'
}

export interface StudentAssessmentQuestionAttemptApi {
  id: number
  studentAssessmentId: number
  assessmentQuestionId: number
  attemptNum: number
  location: AssessmentLocation
  isCorrect: YesNo
  rawPointsEarned: number
  adjustedPointsEarned: number
  attemptData: Array<GenericObject>
  clarity: ClarityEnum | null
  createdAt: string
  updatedAt: string
  rawMaxScore: number
  gradedAdjustedPointsEarned?: number
  freePlay?: YesNo
  vatFrozen?: YesNo
}

export type StudentAssessmentQuestionAttemptApiCreateOut = StudentAssessmentQuestionAttemptApi & Partial<Pick<StudentAssessmentQuestionApi, 'gradedAdjustedPointsEarned' | 'freePlay' | 'vatFrozen' | 'latePointsDeducted'>>

export interface StudentTopicCardForStudentAssessmentAssessmentQuestion {
  checkpoint: CheckpointColumn
  enrollment__id: number
  topic_card__id: number
  student_topic_card__id: number
  has_homework: boolean
  has_prep: boolean
}

export interface StudentAssessmentQuestionAttemptApiWithWindowLatePointsDeducted extends StudentAssessmentQuestionAttemptApi, Pick<StudentAssessmentQuestionApi, 'latePointsDeducted'> {
  saqaWindow: FirstAttemptedEnum
}

export interface NewSaqaValues {
  id: number
  newAdjustedPointsEarned: number | null
}
