/**
 * reorderClassSessionLearningObjective
 *
 * This action does too much, and I intend to refactor it into two actions.
 * Moving CSLOs topic-wise is now covered by moveCsloToAnotherTopic
 * This action will likely live on but with a narrower verb: changing the order of CSLOs
 **/
import cloneDeep from 'lodash-es/cloneDeep';

import apiNext, { SimplifiedErrorResponse } from 'api-next';
import activeSlice from 'store/slices/active';
import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { AppDispatch, Store } from 'types/store.types';

export default function reorderClassSessionLearningObjective(movingCslo: ClassSessionLearningObjectiveApi, previousId: number, targetTopicId?: number) {
  // omit order for API call
  const { order, ...cslo } = movingCslo;
  const csloToUpdate = {
    ...cslo,
    previousId,
  };
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const { active } = getStore();
    const { classSessionLearningObjectives, course: { id: courseId } } = active;
    const cslos: Array<ClassSessionLearningObjectiveApi> = cloneDeep(classSessionLearningObjectives);
    let reorderedCslos: Array<ClassSessionLearningObjectiveApi> = [];
    // not targetTopicId means moving within topic
    if (!!targetTopicId) {
      // update topicId for api call
      csloToUpdate.topicId = targetTopicId;
      // movingCslo.topicId = targetTopicId;
      reorderedCslos = cslos.reduce((acc, cur) => {
        if (cur.id === csloToUpdate.id) {
          return [
            ...acc,
            {
              ...cur,
              order,
              topicId: targetTopicId,
            },
          ];
        }
        return [
          ...acc,
          cur,
        ];
      }, [] as Array<ClassSessionLearningObjectiveApi>);
    } else {
      // previd 0 means moving to the top of the topic
      const reorderedLos = [...cslos];
      const loToMoveIndex = cslos.findIndex(({ id }) => id === movingCslo.id);
      const previousLoIndex = cslos.findIndex(({ id }) => id === previousId);
      const [movingLo] = reorderedLos.splice(loToMoveIndex, 1);
      if (previousLoIndex < loToMoveIndex) {
        // this works for moving items UP
        reorderedLos.splice(previousLoIndex + 1, 0, movingLo);
      } else {
        // this works for moving items DOWN
        reorderedLos.splice(previousLoIndex, 0, movingLo);
      }
      reorderedCslos = reorderedLos.map((lo, i) => ({ ...lo, order: i + 1 }));
    }

    // update local state optimistically so that dragged item doesn't jump around
    dispatch(activeSlice.actions.setActiveClassSessionLearningObjectives(reorderedCslos));

    const result = await apiNext.editClassSessionLo(csloToUpdate);
    const { error } = result as SimplifiedErrorResponse;
    if (error) {
      console.error('editedClassSessionLo', error);
      window.alert(`Error Reordering LOs: ${error.message}`);

      // if error, reset state cslos to original array
      dispatch(activeSlice.actions.setActiveClassSessionLearningObjectives(cslos));
      return error;
    }

    // I really, REALLY want to rework this so we don't have to get ALL CSLOs for the whole course on every reorder
    // but when I tried to implement it so that reordering only affects the topic LOs but because the LO numbering depends
    // on the order in the array as it comes from the backend and you can't just replace CSLO data in situ
    //get all the new cslos for this course
    const updatedCslos = await apiNext.getClassSessionLosByCourseId(courseId);

    dispatch(activeSlice.actions.setActiveClassSessionLearningObjectives(updatedCslos));
  })();
}
