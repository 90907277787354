import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FaCaretRight,
  FaCheckCircle,
  FaExternalLinkAlt,
  FaEye,
  FaTimes,
} from 'react-icons/fa';

import useEncodedUrlPath from 'hooks/useEncodedUrlPath';
import { useToggledArray } from 'hooks/useToggledArray';
import { sortLearningObjectivesByOrder } from 'utils/topicLoFunctions';
import { useAppSelector } from 'store';
import retrieveNestedSubjectsUnitsAndTopics from 'store/selectors/retrieveNestedSubjectsUnitsAndTopics';
import retrieveLearningObjectivesWithMetadata from 'store/selectors/retrieveLearningObjectivesWithMetadata';
import retrieveSortedActiveCombinedQuestions from 'store/selectors/retrieveSortedActiveCombinedQuestions';
import AddRemoveButton from 'shared-components/AddRemoveButton/AddRemoveButton';
import CoursePlannerTopicEditor from './CoursePlannerTopicEditor';
import ClassRowCardContainer from './ClassRowCardContainer';
import CustomItemIcon from 'shared-components/CustomItemIcon/CustomItemIcon';
import LoBuilder from 'instructor/components/LoBuilder/LoBuilder';
import AddRemoveComboButton from 'shared-components/AddRemoveButton/AddRemoveComboButton';
import { AddRemoveEnum } from 'types/common.types';
import { CoursePlannerAction, SharedBCPProps } from '../BetterCoursePlannerController';
import { BetterClassSession } from 'store/selectors/retrieveBetterClassSessions';
import { InstructorCoursePath } from 'types/instructor.types';
import { LibraryTypeEnum, YesNo } from 'types/backend/shared.types';
import { TopicApi } from 'types/backend/topics.types';
import './TopicLoPicker.scss';

function TopicLoPicker({ classSession, hideTopicPicker, ...sharedClassSessionProps }: SharedBCPProps & {
  classSession: BetterClassSession
  hideTopicPicker: () => void
}) {
  const { isProcessing, handleCoursePlannerAction } = sharedClassSessionProps;
  const { id: classSessionId, topics: classSessionTopics, classNumber } = classSession;
  const nestedSubjectsUnitsAndTopics = useAppSelector(retrieveNestedSubjectsUnitsAndTopics);
  const allTopics = useAppSelector((store) => store.active.topics);
  const questions = useAppSelector(retrieveSortedActiveCombinedQuestions);
  const betterCourseLearningObjectives = useAppSelector(retrieveLearningObjectivesWithMetadata);
  const returnToLink = useEncodedUrlPath(`?csId=${classSessionId}`);
  const loSelectorLink = `${InstructorCoursePath.SelectLearningObjectives}?class-session=${classSessionId}&returnTo=${returnToLink}`;

  // get uniqued arrays of class session unit & topic ids to display default expanded units and already selected topics
  const { classSessionTopicIds, classSessionUnitIds } = classSessionTopics.reduce((acc, { id: topicId, unitId }) => ({
    classSessionTopicIds: [...new Set([...acc.classSessionTopicIds, topicId])],
    classSessionUnitIds: [...new Set([...acc.classSessionUnitIds, unitId])],
  }), {
    classSessionTopicIds: [] as Array<number>,
    classSessionUnitIds: [] as Array<number>,
  });

  const [expandedUnitIds, toggleExpandedUnitId] = useToggledArray<number>(classSessionUnitIds);
  const [showCustomTopic, setShowCustomTopic] = useState(false);
  const [showCreateLo, setShowCreateLo] = useState(false);
  const [hoveringTopicId, setHoveringTopicId] = useState<null | number>(null);

  const handleTopicClick = async (topicId: number) => {
    return handleCoursePlannerAction(CoursePlannerAction.AddClassSessionTopic, { classSessionId, topicId });
  };

  const handleAddLo = async (learningObjectiveId: number) => {
    return handleCoursePlannerAction(CoursePlannerAction.AddTopicsAndLosToClass, { classSessionId, learningObjectiveId });
  };
  const handleSaveNewLo = async (learningObjectiveId: number) => {
    // add LO to class session on create
    await handleAddLo(learningObjectiveId);
    hideTopicPicker();
  };
  const handleRemoveLo = async (csloId: number) => {
    // TODO: refine this behavior, currently it defaults to remove Lo and Questions regardless of if there are LO questions or not
    return handleCoursePlannerAction(CoursePlannerAction.RemoveLoAndQuestionsFromClassSession, { classSessionId, csloId });
  };

  const renderLosForTopic = (topicId: number | null) => {
    if (!topicId) {
      return null;
    }
    const { name: topicName } = allTopics.find((topic) => topic.id === topicId) as TopicApi;
    const topicLearningObjectives = betterCourseLearningObjectives.filter((lo) => lo.topicId === topicId);
    const sortedTopicLos = sortLearningObjectivesByOrder(topicLearningObjectives);
    const topicIsInThisClassSession = classSessionTopicIds.includes(topicId);
    return (
      <div className="topic-lo-picker__topic-los-preview">
        <div className="topic-lo-picker__display-topic">
          <strong className="topic-lo-picker__display-topic__topic-name">
            {topicName}
          </strong>
          {/* TODO: enable remove topic from this panel */}
          {!topicIsInThisClassSession && (
            <AddRemoveButton
              allowAdd={!topicIsInThisClassSession}
              allowRemove={topicIsInThisClassSession}
              className="topic-lo-picker__topic__add-remove"
              handleAdd={() => handleTopicClick(topicId)}
              handleRemove={() => {}}
              isProcessing={isProcessing}
              verb={topicIsInThisClassSession ? AddRemoveEnum.Remove : AddRemoveEnum.Add}
              title={`Add Topic ${topicName} to Class ${classNumber}`}
            />
          )}
        </div>
        <div className="topic-lo-picker__display-topic__topic-los">
          <strong>Learning Objectives</strong>
          <ul className="topic-lo-picker__display-topic__topic-los__lo-list">
            {!sortedTopicLos.length && (
              <li className="topic-lo-picker__topic__learning-objective row">
                <em>There are no learning objectives for this topic</em>
              </li>
            )}
            {sortedTopicLos.map(({ id: loId, stringId, title, metadata, type: loType, userId }) => {
              const { classSessionIds = [], loNumberString, cslos = [] } = metadata || {};
              const loIsInCurrentClassSession = classSessionIds.includes(classSessionId);
              const currentCslo = cslos.find((cslo) => cslo.classSessionId === classSessionId && cslo.learningObjectiveId === loId);
              const isCustomLo = loType === LibraryTypeEnum.User;
              const itemHasContent = !!questions.find(q => q.learningObjectiveIds.includes(loId) && q.active === YesNo.Yes);
              const btnTitle = !loIsInCurrentClassSession
                ? `Add this LO to Class ${classNumber}`
                : '';
              return (
                <li className="topic-lo-picker__topic__learning-objective row" data-stringid={stringId} key={loId}>
                  <div className="col-xs-10">
                    {loNumberString && <strong>{loNumberString}: </strong>}
                    {isCustomLo && (
                      <CustomItemIcon
                        className='custom-lo-indicator__icon'
                        ownerUserId={userId}
                      />
                    )}
                    {title}&nbsp;
                    <small><em>{stringId}</em></small>
                  </div>
                  <div className="topic-lo-picker__topic__learning-objective__action col-xs-2">
                    <AddRemoveComboButton
                      allowAdd={!loIsInCurrentClassSession}
                      allowRemove={loIsInCurrentClassSession}
                      handleAdd={() => handleAddLo(loId)}
                      handleRemove={() => !!currentCslo && handleRemoveLo(currentCslo.id)}
                      isProcessing={isProcessing}
                      items={[
                        {
                          label: 'Preview content for this LO',
                          icon: <FaEye />,
                          onClick: () => handleCoursePlannerAction(CoursePlannerAction.PreviewLoContent, { classSessionId, learningObjectiveId: loId }),
                          show: itemHasContent,
                        },
                        {
                          label: 'There is no content for this LO yet',
                          icon: <FaEye />,
                          show: !itemHasContent,
                          disabled: !itemHasContent,
                        },
                      ]}
                      verb={loIsInCurrentClassSession ? AddRemoveEnum.Remove : AddRemoveEnum.Add}
                      title={btnTitle}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };

  if (showCustomTopic) {
    return (
      <CoursePlannerTopicEditor
        handleSave={(updatedTopicName) => handleCoursePlannerAction(CoursePlannerAction.CreateCustomTopicAndAddToClassSession, {
          stringValue: updatedTopicName,
          classSessionId,
        })}
        handleCancel={() => setShowCustomTopic(false)}
      />
    );
  }

  if (showCreateLo) {
    return (
      <ClassRowCardContainer
        cardLabel="Create New Custom LO"
        cardType={LibraryTypeEnum.User}
        className="bcp__lo-builder"
        handleClose={() => setShowCreateLo(false)}
      >
        <LoBuilder
          initEditState={null}
          onSave={({ id }) => handleSaveNewLo(id)}
        />
      </ClassRowCardContainer>
    );
  }

  return (
    <ClassRowCardContainer
      cardLabel={`Add LOs and Topics to Class ${classNumber}`}
      cardType={LibraryTypeEnum.User}
      className="topic-lo-picker"
      renderActions={() => (
        <>
          <div className="bcp__header-action-group">
            <span className="bcp__header-action-group__label">
              Create Custom:
            </span>
            <button
              className="bcp__header-action-button topic-action-button topic-action-button__create-custom-topic"
              onClick={() => setShowCustomTopic(true)}
              title="Create Custom Topic"
            >
              Topic
            </button>
            <button
              className="bcp__header-action-button topic-action-button topic-action-button__create-custom-lo"
              onClick={() => setShowCreateLo(true)}
              title="Create Custom LO"
            >
              LO
            </button>
          </div>
          <Link
            className="bcp__header-action-button topic-action-button topic-action-button__advanced-lo-options"
            to={loSelectorLink}
            title="Advanced LO Options"
          >
            <FaExternalLinkAlt />
          </Link>
          <button
            className="bcp__cancel-x"
            onClick={hideTopicPicker}
          >
            <FaTimes />
          </button>
        </>
      )}
    >
      <div className="topic-lo-picker__content">
        <div className="topic-lo-picker__content__library-topics row">
          <div className="col-xs-6">
            <div className="topic-lo-picker__topic-list">
              {nestedSubjectsUnitsAndTopics.map(({ id: subjectId, name: subjectName, units }) => (
                <div className="topic-lo-picker__subject level-1" key={subjectId}>
                  <strong className="topic-lo-picker__subject-name">{subjectName}</strong>
                  <div className="topic-lo-picker__subject-units">
                    {units.map(({ id: unitId, name: unitName, stringId: unitStringId, topics, unitHasTopics }) => (
                      <div
                        className="topic-lo-picker__unit level-2"
                        key={unitId}
                        data-expanded={expandedUnitIds.includes(unitId)}
                        data-unit-stringid={unitStringId}
                      >
                        <button
                          className="topic-lo-picker__subject__unit-header expando"
                          disabled={!topics.length || !unitHasTopics}
                          onClick={() => toggleExpandedUnitId(unitId)}
                        >
                          <FaCaretRight />
                          <div className="topic-lo-picker__subject__unit-name">{unitName}</div>
                        </button>
                        {expandedUnitIds.includes(unitId) && (
                          <ul className="topic-lo-picker__unit-topics">
                            {topics.map(({ id: topicId, name: topicName, stringId: topicStringId, topicClassSessionIds, hasLos }) => {
                              const topicIsInThisClassSession = topicClassSessionIds.includes(classSessionId);
                              // if topicSelected, disable add
                              // TODO: enable remove selected topics by clicking
                              const topicSelected = classSessionTopicIds.includes(topicId);
                              return (
                                <li
                                  className="topic-lo-picker__unit-topics__topic-item level-3"
                                  data-topic-stringid={topicStringId}
                                  data-topic-showing={hoveringTopicId === topicId}
                                  key={topicId}
                                >
                                  <button
                                    data-selected={topicSelected}
                                    className="topic-item__select-topic"
                                    onMouseOver={() => setHoveringTopicId(topicId)}
                                    onFocus={() => setHoveringTopicId(topicId)}
                                  >
                                    {topicName}
                                    {topicIsInThisClassSession && <FaCheckCircle />}
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-xs-6">
            {renderLosForTopic(hoveringTopicId)}
          </div>
        </div>
      </div>
    </ClassRowCardContainer>
  );
}

export default TopicLoPicker;
