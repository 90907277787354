import React from 'react';
import PropTypes from 'prop-types';
import Select, { MultiValue } from 'react-select';
import { DateTime } from 'luxon';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import tooltips from '../../assessmentBuilderTooltips';
import getSortedUniqueLOsForClassSessions from 'utils/getSortedUniqueLOsForClassSessions';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import LoPillList from 'shared-components/LoPillList/LoPillList';
import { BetterClassSession } from 'store/selectors/retrieveBetterClassSessions';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { DropdownOption } from 'instructor/components/Dropdown/Dropdown.types';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import { PositionEnum } from 'types/common.types';

// a simple function to help sort the classdayscovered dropdown
const getClassNumber = (classNumString: string) => {
  const [, numString] = classNumString.split(' ');
  return parseInt(numString);
};

interface SelectClassDaysCoveredProps {
  onChange: (fieldValue: Array<number>) => void
  selectedClassIds: Array<number>
  classSessions: Array<BetterClassSession>
  className?: string
}

function SelectClassDaysCovered({
  classSessions,
  onChange,
  selectedClassIds = [],
  ...rest
}: SelectClassDaysCoveredProps) {
  const selectableSessions = classSessions.filter(cs => cs.classType === ClassTypeEnum.Normal);
  const options = selectableSessions.map(cs => {
    return {
      value: cs.id,
      label: `Class ${cs.classNumber} (${DateTime.fromISO(cs.classDate).toFormat(DateFormatEnum.WeekdayMonthDate)})`,
    };
  });

  const selectedValues = options.filter((option) => selectedClassIds.includes(option.value));
  const fieldVal = selectedValues
    ? selectedValues.sort(({ label: labelA }, { label: labelB }) => getClassNumber(labelA) - getClassNumber(labelB))
    : null;

  function handleOnChange(val: MultiValue<DropdownOption<number>>) {
    let updatedValue: Array<number> = [];
    if (!!val.length) {
      updatedValue = val.map((v) => v.value);
    }
    onChange(updatedValue);
  }
  const coveredLos: Array<EnrichedCourseLearningObjective> = getSortedUniqueLOsForClassSessions([...selectedClassIds], [...classSessions]);
  return (
    <>
      <label htmlFor="name">
        <div className="label-text">
          Class Days Covered
          <BetterTooltip content={() => tooltips.classDaysCovered} position={PositionEnum.BottomRight} indicate />
        </div>
      </label>
      <Select
        classNamePrefix="class-days-covered"
        options={options}
        {...rest}
        isMulti
        value={fieldVal}
        closeMenuOnSelect={false}
        onChange={handleOnChange}
      />
      {!!coveredLos.length && (
        <div className="lo-pill-list-wrap">
          <div className="lo-pill-list-subtitle">This assessment is linked to these LOs:</div>
          <LoPillList courseLearningObjectives={coveredLos} />
        </div>
      )}
    </>
  );
}

SelectClassDaysCovered.propTypes = {
  className: PropTypes.string,
  classSessions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedClassIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SelectClassDaysCovered;
