import { AssessmentControllerQuestion } from './getAssessmentControllerQuestions';
import { StudentAssessmentQuestionApiWithSaqas } from 'types/backend/studentAssessmentQuestions.types';
import {
  AttemptsHash,
  ClarityHash,
  CorrectHash,
  PointsHash,
  RecapHash,
  VatFrozenHash,
} from 'types/common.types';
import { YesNo } from 'types/backend/shared.types';

export interface VatHashes {
  attemptsHash: AttemptsHash
  clarityHash: ClarityHash
  correctHash: CorrectHash
  everCorrectHash: CorrectHash
  latePointsDeductedHash: PointsHash
  pointsHash: PointsHash
  recapHash: RecapHash
  vatFrozenHash: VatFrozenHash
}
const getVatHashesFromSaqa = (questions: Array<AssessmentControllerQuestion>, studentAssessmentQuestions: Array<StudentAssessmentQuestionApiWithSaqas>, isInstructor = false, isAfterLate = false): VatHashes => {
  if (isInstructor) {
    // default hashes
    const isAfterLateForVatFrozen = isAfterLate ? YesNo.Yes : YesNo.No;
    return questions.reduce((acc: VatHashes, q) => {
      const {
        attemptsHash = {},
        clarityHash = {},
        correctHash = {},
        everCorrectHash = {},
        latePointsDeductedHash = {},
        pointsHash = {},
        recapHash = {},
        vatFrozenHash = {},
      } = acc;
      recapHash[q.l8yId] = false;
      latePointsDeductedHash[q.l8yId] = 0;
      pointsHash[q.l8yId] = 0;
      vatFrozenHash[q.l8yId] = isAfterLateForVatFrozen;
      return {
        attemptsHash,
        clarityHash,
        correctHash,
        everCorrectHash,
        latePointsDeductedHash,
        pointsHash,
        recapHash,
        vatFrozenHash,
      };
    }, {} as VatHashes);
  }
  return questions.reduce((acc: VatHashes, q) => {
    const saq = studentAssessmentQuestions.find(s => s.assessmentQuestionId === q.assessmentQuestionId);
    if (!saq) {
      return acc;
    }
    const {
      attemptsHash = {},
      clarityHash = {},
      correctHash = {},
      everCorrectHash = {},
      latePointsDeductedHash = {},
      pointsHash = {},
      recapHash = {},
      vatFrozenHash = {},
    } = acc;
    const latestSaqaRecap = saq.latestVatStudentAssessmentQuestionAttempt ? saq.pointsAvailableToRecap && saq.pointsAvailableToRecap > 0 : false;
    recapHash[q.l8yId] = latestSaqaRecap || false;

    if (saq.latestVatStudentAssessmentQuestionAttempt) {
      const { clarity, isCorrect, attemptNum } = saq.latestVatStudentAssessmentQuestionAttempt;
      if (clarity) {
        clarityHash[q.l8yId] = clarity;
      }
      if (isCorrect) {
        correctHash[q.l8yId] = isCorrect;
      }
      if (attemptNum) {
        attemptsHash[q.l8yId] = attemptNum;
      }
    }
    const gradedSaqaPoints = saq.gradedStudentAssessmentQuestionAttempt ? saq.gradedStudentAssessmentQuestionAttempt.adjustedPointsEarned : 0;
    latePointsDeductedHash[q.l8yId] = saq.latePointsDeducted || 0;
    pointsHash[q.l8yId] = gradedSaqaPoints;
    vatFrozenHash[q.l8yId] = saq.vatFrozen;
    everCorrectHash[q.l8yId] = saq.isEverCorrect;
    return {
      attemptsHash,
      clarityHash,
      correctHash,
      everCorrectHash,
      latePointsDeductedHash,
      pointsHash,
      recapHash,
      vatFrozenHash,
    };
  }, {} as VatHashes);
};

export default getVatHashesFromSaqa;
