import React from 'react';
import { ChildrenProp } from 'types/common.types';

function CourseDetailsItem({
  children,
  errorMsg,
  hide = false,
  id,
  label,
  ...props
}: {
  children: ChildrenProp
  className?: string
  errorMsg?: string | false
  hide?: boolean
  id: string
  label: string
}) {
  if (hide) {
    return null;
  }
  return (
    <div className="course-details__item" {...props}>
      <label htmlFor={id}>{label}</label>
      <div className="course-details__item__contents">
        {children}
        {errorMsg && <div className="form-error" data-error-id={id}>{errorMsg}</div>}
      </div>
    </div>
  );
}

export default CourseDetailsItem;
