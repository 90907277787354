import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select, { MultiValue } from 'react-select';
import { DateTime } from 'luxon';
import useClassSessionQuery from 'hooks/useClassSessionQuery';
import { BetterClassSession } from 'store/selectors/retrieveBetterClassSessions';
import { useQueryParam, StringParam } from 'use-query-params';

import { getInstructionTypeShortName } from 'utils/commonFormattingFunctions';
import { validUrl } from 'utils';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import InstructionsEditableRow from './InstructionsEditableRow';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { DropdownOption } from 'instructor/components/Dropdown/Dropdown.types';
import { InstructionEnum } from 'types/common.types';
import { InstructionsActionEnum, InstructionsPayload, InstructionsRow } from '../InstructionsController.types';
import { ClassTypeEnum } from 'types/backend/classSessions.types';
import CodonUrls from 'urls';
import './Instructions.scss';

interface InstructionsProps {
  instructionHeading: string
  classSessions: Array<BetterClassSession>
  instructions: Array<InstructionsRow>
  saveInstruction: (
    classSessionIds: Array<number>,
    title: string,
    type: LibraryTypeEnum,
    url: string | null,
  ) => void
  deleteInstruction: (
    classSessionClrId: Array<number>,
    clrId: number
  ) => void
  updateInstruction: (
    clrId: number,
    classSessionIds: Array<number>,
    title: string,
    url: string | null
  ) => void
}

function InstructionsBuilder({
  instructionHeading,
  classSessions,
  instructions,
  saveInstruction,
  deleteInstruction,
  updateInstruction,
}: InstructionsProps) {
  const [selectedClassSessionId] = useClassSessionQuery(classSessions);
  const [instructionTypeQuery, setInstructionTypeQuery] = useQueryParam('type', StringParam);

  // set up class session for dropdown
  const selectableClassSessions = classSessions.filter(cs => cs.classType === ClassTypeEnum.Normal);
  const classesDropdown: Array<DropdownOption<number>> = selectableClassSessions.map(({ id, classNumber, classDate }) => ({
    value: id,
    label: `Class ${classNumber} (${DateTime.fromISO(classDate).toFormat(DateFormatEnum.WeekdayMonthDate)})`,
  }));
  const currentClass = classesDropdown.find(classItem => classItem.value === selectedClassSessionId) as DropdownOption<number>;

  const [selectedClassSessionValues, setSelectedClassSessionValues] = useState<MultiValue<DropdownOption<number>>>([currentClass]);
  const [instructionTitle, setInstructionTitle] = useState('');
  const [instructionUrl, setInstructionUrl] = useState('');
  const [editRowId, setEditRowId] = useState(-1);
  const urlIsValid = instructionUrl === '' || validUrl(instructionUrl);

  const handleClrDispatch = async (action: InstructionsActionEnum, payload?: InstructionsPayload) => {
    switch (action) {
      case InstructionsActionEnum.Create: {
        const selectedClassSessionIds = selectedClassSessionValues.map(({ value }) => value);
        const url = instructionUrl.length ? instructionUrl : null;
        const savedInstructions = await saveInstruction(selectedClassSessionIds, instructionTitle, LibraryTypeEnum.User, url);
        setInstructionTitle('');
        setInstructionUrl('');
        setSelectedClassSessionValues([]);
        return savedInstructions;
      }
      case InstructionsActionEnum.Update: {
        if (payload) {
          const { clrId, classSessionIds, title, url = null } = payload;
          setEditRowId(-1);
          return clrId && updateInstruction(clrId, classSessionIds, title, url);
        }
        break;
      }
      case InstructionsActionEnum.Delete: {
        if (payload) {
          const { classSessionIds, clrId } = payload;
          return clrId && deleteInstruction(classSessionIds, clrId);
        }
        break;
      }
    }

  };

  return (
    <main className="instructions__wrapper">
      <p className="instructions-title">Add New {instructionHeading}</p>
      <div className="instructions__add-new row">
        <div className="col-xs-12 col-sm-12 col-md-6">
          <div className="toggle-instruction-type">
            {Object.values(InstructionEnum).map((instructionType) => (
              <label key={`instruction-select_${instructionType}`}>
                <input
                  type="radio"
                  value={instructionType}
                  name="instruction-type-select"
                  onChange={({ target: { value } }) => setInstructionTypeQuery(value)}
                  checked={instructionTypeQuery === instructionType}
                />
                {getInstructionTypeShortName(instructionType)}
              </label>

            ))}
          </div>
          <div className="instructions__select form__field">
            <div className="instructions__select__selections">
              <div className="instructions__select__class-session">
                <label htmlFor="select-class-session">Select Class Session(s)</label>
                <div className="select-class-session">
                  <Select
                    options={classesDropdown}
                    className="select-class-session__dropdown"
                    closeMenuOnSelect={false}
                    isMulti
                    onChange={(val) => setSelectedClassSessionValues(val)}
                    value={selectedClassSessionValues}
                  />
                </div>
              </div>
              <div className="instructions__select__title">
                <label htmlFor="instructions__select__title">Title</label>
                <input
                  id="instructions__select__title"
                  type="text"
                  name="title"
                  onChange={e => setInstructionTitle(e.target.value)}
                  value={instructionTitle}
                  required
                />
              </div>
              <div className="instructions__select__url">
                <label className="instructions__select__url__label" htmlFor="instructions__select__url">URL (optional)</label>
                <input
                  id="instructions__select__url"
                  type="url"
                  name="url"
                  placeholder={CodonUrls.CodonSite}
                  onChange={e => setInstructionUrl(e.target.value)}
                  onBlur={({ target: { value } }) => setInstructionUrl(value.trim())}
                  value={instructionUrl ? instructionUrl : ''}
                />
                {!urlIsValid && (
                  <p className="instructions__select__url__warning-invalid">
                    Please make sure you enter a valid URL ({CodonUrls.CodonSite})
                  </p>
                )}
              </div>
              <BetterButton
                className="instructions__select__save"
                primary
                text={instructionTypeQuery === InstructionEnum.InClass ? 'Add In-Class Material' : 'Add Pre-class Resource'}
                onClick={() => handleClrDispatch(InstructionsActionEnum.Create)}
                disabled={!selectedClassSessionValues.length || !instructionTitle.length}
              />
            </div>
          </div>
        </div>
      </div>
      <hr/>
      {!instructions.length ? (
        <p>There are currently no {instructionTypeQuery === InstructionEnum.InClass ? 'materials' : 'resources'} assigned for this course.</p>
      ) : (
        <>
          <p className="instructions-title">Current {instructionHeading}</p>
          <table className="assigned-instructions">
            <thead>
              <tr>
                <th className="assigned-instructions__classes">
                  Classes
                </th>
                <th className="assigned-instructions__title">
                  Title
                </th>
                <th className="assigned-instructions__url">
                  URL
                </th>
                <th/>
              </tr>
            </thead>
            <tbody>
              {instructions.map((instruction) => (
                <InstructionsEditableRow
                  key={instruction.clrId}
                  classesDropdown={classesDropdown}
                  editingClrId={editRowId}
                  instruction={instruction}
                  toggleEdit={setEditRowId}
                  clrDispatch={handleClrDispatch}
                />
              ))}
            </tbody>
          </table>
        </>
      )}
    </main>
  );
}

export default InstructionsBuilder;

InstructionsBuilder.propTypes = {
  instructionHeading: PropTypes.string.isRequired,
  classSessions: PropTypes.array.isRequired,
  instructions: PropTypes.arrayOf(PropTypes.shape({
    ids: PropTypes.array.isRequired,
    clrId: PropTypes.number.isRequired,
    clrType: PropTypes.string.isRequired,
    classSessionIds: PropTypes.array.isRequired,
    classNumbers: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    type: PropTypes.string.isRequired,
    userId: PropTypes.string,
  })).isRequired,
  saveInstruction: PropTypes.func,
  deleteInstruction: PropTypes.func,
  updateInstruction: PropTypes.func,
};
