import React, { useRef } from 'react';
import { FaCaretDown, FaMinus, FaPlus } from 'react-icons/fa';
import useDropdownMenu from 'hooks/useDropdownMenu';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { KebabDropdownItem, KebabMenuItem } from '../KebabMenu/KebabMenu';
import { AddRemoveEnum, PositionEnum } from 'types/common.types';
import '../KebabMenu/KebabMenu.scss';

const AddRemoveComboButton = ({
  allowAdd,
  allowRemove,
  className,
  disabled,
  handleAdd,
  handleRemove,
  isProcessing,
  items,
  title,
  verb,
}: {
  allowAdd: boolean
  allowRemove: boolean
  className?: string
  disabled?: boolean
  handleAdd: React.MouseEventHandler<HTMLButtonElement>
  handleRemove: React.MouseEventHandler<HTMLButtonElement>
  isProcessing?: boolean
  items: Array<KebabMenuItem>
  title?: string
  verb?: AddRemoveEnum
}) => {
  const {
    buttonProps,
    itemProps,
    isOpen,
    setIsOpen,
  } = useDropdownMenu(items.length);
  const splitBtnRef = useRef(null);
  useOnClickOutside(splitBtnRef, () => setIsOpen(false));

  const isAdd = verb === AddRemoveEnum.Add;
  const buttonIcon = isAdd
    ? <FaPlus />
    : <FaMinus />;
  const buttonDisabled = disabled || (verb === AddRemoveEnum.Add && !allowAdd) || (verb === AddRemoveEnum.Remove && !allowRemove);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    return isAdd
      ? handleAdd(e)
      : handleRemove(e);
  };

  return (
    <div className="split-button">
      <button
        className="split-button__primary-action"
        data-isprocessing={isProcessing}
        data-verb={verb}
        disabled={buttonDisabled || isProcessing}
        onClick={handleClick}
        title={title}
      >
        {buttonIcon}
      </button>
      <div
        className={`kebab-menu__wrap ${className}`}
        data-expanded={isOpen}
        ref={splitBtnRef}
      >
        <button {...buttonProps} className="split-button__dropdown-trigger">
          <FaCaretDown />
        </button>
        <ul className={`kebab-menu__dropdown kebab-dropdown-${PositionEnum.Bottom}`}>
          {items.map((item, idx) => (
            <li key={item.label}>
              <KebabDropdownItem
                disabled={disabled || item.disabled}
                item={item}
                index={idx}
                itemProps={itemProps}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AddRemoveComboButton;
