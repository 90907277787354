export enum SelectItemTypeEnum {
  Unit = 'UNIT',
  Topic = 'TOPIC',
}

export interface ItemOption {
    label: string
    parent: {
        label: string
        value: number
    }
    type: string
    value: number
  }
