/**
 * retrieveEnrichedActiveTopics
 */

import { createSelector } from '@reduxjs/toolkit';
import cloneDeep from 'lodash-es/cloneDeep';
import uniqBy from 'lodash-es/uniqBy';
import { ClassSessionTopicApi } from 'types/backend/classSessionTopics.types';
import { TopicApi } from 'types/backend/topics.types';
import { Store } from 'types/store.types';

export interface EnrichedActiveTopic extends ClassSessionTopicApi {
  courseWideOrder: number
}
//This selector is called 'user' topics for legacy reasons but it does not distinguish
//the are returned in the order in which they first appear in classSessions
export default createSelector(
  (store: Store) => store.active.topics,
  (store: Store) => store.active.classSessionTopics,
  (topics, classSessionTopics) => {

    topics = cloneDeep(topics);
    const uniqueClassSessionTopics = uniqBy(classSessionTopics, 'topicId');

    const sortedCourseTopics: Array<EnrichedActiveTopic> = uniqueClassSessionTopics.map((cst, index) => {
      const sortedTopic = topics.find(t => t.id === cst.topicId) as TopicApi;
      if (!sortedTopic) {
        console.error(`No topic found for classSessionTopic topicId: ${cst.topicId}`, cst);
      }
      return {
        ...cst,
        ...sortedTopic,
        courseWideOrder: index,
      };
    });

    return sortedCourseTopics;
  }
);
