import uniqBy from 'lodash-es/uniqBy';
import { sortCourseLearningObjectives, EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';


const getUniqueLosSortedByLoNumber = (los: Array<EnrichedCourseLearningObjective>) => {
  const uniquedLos = uniqBy(los, 'id');
  const sortedUniquedLos = sortCourseLearningObjectives(uniquedLos);
  return sortedUniquedLos;
};

export default getUniqueLosSortedByLoNumber;
