import { AssessmentApiBase } from 'types/backend/assessments.types';
import { YesNo } from 'types/backend/shared.types';


const validateStudyPathAssessmentUpdatesForWorker = (
  studyPathPublished: YesNo,
  prevAssessmentIds: Array<string>,
  newAssessmentIds: Array<string>,
  assessments: Array<AssessmentApiBase>
): boolean => {
  // the studyPath is published
  // AND any of the added or removed assessments is published
  if (studyPathPublished === YesNo.No) {
    return false;
  }
  const addedAssessmentIds = newAssessmentIds.filter(id => !prevAssessmentIds.includes(id));
  const removedAssessmentIds = prevAssessmentIds.filter(id => !newAssessmentIds.includes(id));
  const changedAssessmentIds = addedAssessmentIds.concat(removedAssessmentIds);
  const publishedAssessmentIds = assessments.reduce((acc: Array<string>, { id, published }) => {
    if (published === YesNo.Yes) {
      acc.push(id);
    }
    return acc;
  }, []);

  return changedAssessmentIds.some(id => publishedAssessmentIds.includes(id));
};

export default validateStudyPathAssessmentUpdatesForWorker;
