/**
 * useClassSessionQuery
 *
 * Custom hook that handles state & query params in a centralized place
 * because we have several places where this is used and additional places it could be useful
 */
import { useState, useEffect } from 'react';
import { useQueryParam, NumberParam } from 'use-query-params';
import { DateTime } from 'luxon';

// this needs to support any derivative of ClassSessionApi interface
const useClassSessionQuery = (classSessions: Array<{ id: number; classDate: string }> = [], setNextIfNoQuery = true) => {
  const [classSessionIdQuery, setClassSessionIdQuery] = useQueryParam('class-session', NumberParam);
  const [selectedClassSessionId, setSelectedClassSessionId] = useState(classSessionIdQuery);
  const now = DateTime.local();

  useEffect(() => {
    // update class session id state from query change
    if (!!classSessionIdQuery && classSessionIdQuery !== selectedClassSessionId) {
      setSelectedClassSessionId(classSessionIdQuery);
    }
  }, [classSessionIdQuery, selectedClassSessionId]);

  // Return today's class session if there is one, otherwise return next chronological class session after now
  // if no class sessions after now, return last class session
  const finalClassSession = [...classSessions].pop();
  const nextClassSession = classSessions.find((cs) => {
    const luxonDate = DateTime.fromISO(cs.classDate);
    return now.hasSame(luxonDate, 'day') || +now < +luxonDate;
  });

  useEffect(() => {
    // if page is invoked without a class-session query, param, set class session to the next chronological class session
    const initialSelectedClassSession = nextClassSession || finalClassSession;
    if (!selectedClassSessionId && !!initialSelectedClassSession && setNextIfNoQuery) {
      const { id: nextClassSessionId } = initialSelectedClassSession;
      setSelectedClassSessionId(nextClassSessionId);
    }
  }, [finalClassSession, nextClassSession, selectedClassSessionId, setNextIfNoQuery, setSelectedClassSessionId]);

  return [selectedClassSessionId, setClassSessionIdQuery] as const;
};

export default useClassSessionQuery;
