import React from 'react';
import PropTypes from 'prop-types';
import styles from 'style-config.scss';
import './Charts.scss';

const DataObject = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

const BarGroupProps = {
  barData: DataObject.isRequired,
  color: PropTypes.string.isRequired,
  barHeight: PropTypes.number.isRequired,
};

function BarGroup(props: PropTypes.InferProps<typeof BarGroupProps>) {
  const barPadding = 2;
  const widthScale = (dd: number) => dd * 30;

  const { color, barData } = props;

  const width = widthScale(barData.value);
  const yMid = props.barHeight * 0.5;
  const isZero = barData.value === 0;
  return (
    <g className="bar-group">
      <text
        className="name-label"
        x="-6"
        y={yMid}
        alignmentBaseline="middle"
      >
        {barData.name}
      </text>
      <rect y={barPadding * 0.5} width={width} height={props.barHeight - barPadding} fill={color} />
      <text
        className={`value-label ${isZero ? 'is-zero' : ''}`}
        x={isZero ? width + 8 : width - 8}
        y={yMid}
        alignmentBaseline="middle"
      >
        {barData.value}
      </text>
    </g>
  );
}

const BarChartProps = {
  data: PropTypes.arrayOf(DataObject.isRequired).isRequired,
  handleHover: PropTypes.func.isRequired,
};

function BarChart(props: PropTypes.InferProps<typeof BarChartProps>) {
  const { data, handleHover } = props;
  const barHeight = 40;
  const totalHeight = (barHeight + 5) * data.length;
  return (
    <svg width="100%" height={totalHeight}>
      <g className="container">
        <g className="chart" transform="translate(60, 0)">
          {
            data.map((barData, i) => (
              <g
                transform={`translate(0, ${i * barHeight})`}
                key={`bargroup-${barData.name}`}
                onMouseEnter={() => handleHover(barData.name)}
                onMouseLeave={() => handleHover('')}
              >
                <BarGroup
                  barData={barData}
                  barHeight={barHeight}
                  color={i % 2 ? styles.purple : styles.blue }
                />
              </g>
            ))
          }
        </g>
      </g>
    </svg>
  );
}

BarChart.propTypes = BarChartProps;

export default BarChart;
