import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CodonUrls from 'urls';
import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import { MultipleAttemptPolicyEnum } from 'types/common.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';

const CombinedAttemptsPolicy = ({
  assessType,
  isDisabled = false,
  label,
  onChange,
  value,
}: {
  assessType: AssessTypeEnum
  isDisabled: boolean
  label: string
  onChange: (assessType: AssessTypeEnum, policy: MultipleAttemptPolicyEnum) => void
  value: MultipleAttemptPolicyEnum
}) => {
  const commonAssessTypes = [
    AssessTypeEnum.Preclass,
    AssessTypeEnum.Homework,
    AssessTypeEnum.Prep,
    AssessTypeEnum.Readiness,
  ];
  // setup the multiple attempt policy options
  const allGradingPolicyOptions = [
    {
      value: MultipleAttemptPolicyEnum.CorrectnessTypeOne,
      label: 'Unlimited attempts to answer correctly for full credit (100%, 100%, 100%...)',
      assessTypes: commonAssessTypes,
    },
    {
      value: MultipleAttemptPolicyEnum.CorrectnessTypeTwo,
      label: '10% point reduction per incorrect attempt, starting on 3rd attempt (100%, 100%, 90%, 80%...)',
      assessTypes: commonAssessTypes,
    },
    {
      value: MultipleAttemptPolicyEnum.CorrectnessTypeThree,
      label: '10% point reduction per incorrect attempt, starting on 2nd attempt (100%, 90%, 80%, 70%...)',
      assessTypes: commonAssessTypes,
    },
    {
      value: MultipleAttemptPolicyEnum.CorrectnessTypeFour,
      label: 'Must answer correctly on first attempt to receive credit (100%, 0%...)',
      assessTypes: [...commonAssessTypes, AssessTypeEnum.PracticeTest],
    },
    {
      value: MultipleAttemptPolicyEnum.ForCompletion,
      label: 'Credit for completion, even if incorrect',
      assessTypes: [...commonAssessTypes, AssessTypeEnum.PracticeTest],
    },
    {
      value: MultipleAttemptPolicyEnum.NotForPoints,
      label: 'Not for points',
      assessTypes: [...commonAssessTypes, AssessTypeEnum.PracticeTest],
    },
  ];
  // certain assessTypes should only show a subset of policies
  const availableOptions = allGradingPolicyOptions.filter(({ assessTypes }) => assessTypes.includes(assessType));
  const gradingPolicyTooltip = (
    <div>
      <ul>
        <li>The selected grading policy will be applied to all machine-graded questions (e.g., multiple choice, labeling).</li>
        <li>Students earn full points upon submission of open-response questions, regardless of grading policy. Instructor grading of open-response questions is not available.</li>
        <li>Learn more about {externalLink(CodonUrls.GradingPolicyKB, 'grading policies')} or {externalLink(CodonUrls.OpenResponseAssessmentBuilderKB, 'open-response questions')}.</li>
      </ul>
    </div>
  );
  return (
    <div className="combined-attempts-policy form__field">
      <label>
        {label}
        <BetterTooltip
          indicate
          content={gradingPolicyTooltip}
        />
        <Select
          className={`form-select combined-attempts-dropdown ${assessType}`}
          classNamePrefix={`combined-attempts-${assessType}`}
          isDisabled={isDisabled}
          options={availableOptions}
          value={availableOptions.find((option) => option.value === value)}
          onChange={(option) => !!option && onChange(assessType, option.value)}
        />
      </label>
    </div>
  );
};

CombinedAttemptsPolicy.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(Object.values(MultipleAttemptPolicyEnum)).isRequired,
};

export default CombinedAttemptsPolicy;
