/***
 * Adapted and TypeScriptified from https://github.com/CagriAldemir/react-cache-buster
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import { idleTimerConfig } from 'utils/sharedConfig';
const metaJson = '/meta.json';

const versionIsNew = (metaVersion: string, currentVersionString: string) => {
  if (!currentVersionString) {
    return false;
  }
  const metaVersions = metaVersion.split(/\./g);
  const currentVersions = currentVersionString.split(/\./g);

  while (metaVersions.length || currentVersions.length) {
    const a = Number(metaVersions.shift());
    const b = Number(currentVersions.shift());
    if (a === b) {
      continue;
    }
    return a > b || isNaN(b);
  }
  return false;
};

function CacheBuster({
  children,
  currentVersion,
  isEnabled = false,
  loadingComponent,
}: {
  children: JSX.Element
  currentVersion: string
  isEnabled: boolean
  loadingComponent: JSX.Element
}) {
  const [cacheStatus, setCacheStatus] = useState({
    loading: true,
    isLatestVersion: false,
  });
  const [showReloadPrompt, setShowReloadPrompt] = useState(false);
  const fetchMetaJson = async () => await fetch(metaJson).then((res) => res.json());

  useEffect(() => {
    const checkCacheStatus = async () => {
      try {
        const { version: metaVersion, buildDate } = await fetchMetaJson();
        const shouldForceRefresh = versionIsNew(metaVersion, currentVersion);
        if (shouldForceRefresh) {
          console.info(`There is a new version (v${metaVersion} > v${currentVersion}). Should force refresh.`);
          setCacheStatus({
            loading: false,
            isLatestVersion: false,
          });
        } else {
          console.info('There is no new version. No cache refresh needed.', currentVersion, buildDate);
          setCacheStatus({
            loading: false,
            isLatestVersion: true,
          });
        }
      } catch (error) {
        console.error('An error occurred while checking cache status.', error);
        setCacheStatus({
          loading: false,
          isLatestVersion: true,
        });
      }
    };
    if (isEnabled) {
      checkCacheStatus();
    } else {
      console.debug('React Cache Buster is disabled.');
    }
  }, [currentVersion, isEnabled]);

  const onActive = async () => {
    if (!isEnabled) {
      return;
    }
    const { version: metaVersion } = await fetchMetaJson();
    if (versionIsNew(metaVersion, currentVersion)) {
      console.info('New version since idle', metaVersion, currentVersion, getIdleTime());
      setShowReloadPrompt(true);
    }
  };
  const { getIdleTime } = useIdleTimer({
    ...idleTimerConfig,
    name: 'idle-timer_cache-checker',
    onActive,
  });

  const refreshCacheAndReload = async () => {
    try {
      if (window?.caches) {
        const { caches } = window;
        const cacheNames = await caches.keys();
        for (const cacheName of cacheNames) {
          caches.delete(cacheName);
        }
        console.debug('The cache has been deleted.');
        window.location.reload();
      }
    } catch (error) {
      console.error('An error occurred while deleting the cache.', error);
    }
  };

  if (!isEnabled) {
    return children;
  } else if (cacheStatus.loading) {
    return loadingComponent;
  } else if (!cacheStatus.isLatestVersion) {
    refreshCacheAndReload();
    return null;
  }
  return (
    <>
      {showReloadPrompt && (
        <div id="reload-prompt">
          A new version of the app is available.
          <button onClick={() => refreshCacheAndReload()}>
            Click here to update
          </button>
        </div>
      )}
      {children}
    </>
  );
}

CacheBuster.propTypes = {
  children: PropTypes.element.isRequired,
  currentVersion: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  loadingComponent: PropTypes.element.isRequired,
};

export default CacheBuster;
