import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import { EnrichedClassSession, EnrichedClassSessionLearningObjective } from 'store/selectors/retrieveEnrichedClassSessions';
import { LibraryTypeEnum } from 'types/backend/shared.types';
import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';
import { TopicApi } from 'types/backend/topics.types';
import { LoItem } from './CourseLoSelector.types';
import { UnitApi } from 'types/backend/units.types';
import { SubjectApi } from 'types/backend/subjects.types';
import { ClassSessionTopicApi } from 'types/backend/classSessionTopics.types';

export const enhanceLosWithListMetadata = (
  los: Array<LearningObjectiveApi>,
  selectedIds: Array<number>,
  classSessions: Array<EnrichedClassSession>,
  topics: Array<TopicApi>,
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
) => {
  const loItems = los.map((lo) => {
    // get simple object with the data we need to display class sessions
    const loClassSessions = classSessions.reduce((acc, cur) => {
      const { classNumber, id: classSessionId, learningObjectives } = cur;
      const loInClassSession = learningObjectives.find((clo: EnrichedClassSessionLearningObjective) => clo.id === lo.id);
      if (!!loInClassSession) {
        return [
          ...acc,
          {
            classNumber,
            classSessionId,
          },
        ];
      }
      return acc;
    }, [] as Array<{ classSessionId: number; classNumber: number }>);
    // get correct topic name
    const { name: libraryTopicName, type } = topics.find(topic => topic.id === lo.topicId) || {};
    const { topic: { name: courseTopicName, id: csloTopicId } } = courseLearningObjectives.find(clo => clo.id === lo.id) || { topic: {} };

    return {
      ...lo,
      loClassSessions,
      libraryTopicName,
      courseTopicName,
      selected: selectedIds.includes(lo.id),
      customTopicParent: type === LibraryTypeEnum.User,
      parentAndCsloTopicMatch: lo.topicId === csloTopicId,
    };
  });
  return loItems as Array<LoItem>;
};

export const getLosForTopicsInFilter = ({
  learningObjectives,
  classSessionLearningObjectives,
  customUnitIds,
  primarySubjectId,
  selectedFilters: {
    selectedTopicIds,
    selectedUnitIds,
  },
  topics,
  units,
}: {
  learningObjectives: Array<LearningObjectiveApi>
  classSessionLearningObjectives: Array<ClassSessionLearningObjectiveApi>
  customUnitIds: Array<number>
  primarySubjectId: number
  selectedFilters: {
    selectedTopicIds: Array<number>
    selectedUnitIds: Array<number>
  }
  topics: Array<TopicApi>
  units: Array<UnitApi>
}): Array<LearningObjectiveApi> => {
  // if no topics or units are selected, show all LOs
  if (!selectedTopicIds.length && !selectedUnitIds.length) {
    return learningObjectives;
  }
  if (!selectedTopicIds.length) {
    const subjectLearningObjectives = learningObjectives.reduce((acc, lo) => {
      const topic = topics.find(t => t.id === lo.topicId);
      if (topic) {
        const unit = units.find(u => u.id === topic.unitId);
        if (unit && unit.subjectId === primarySubjectId) {
          acc.push(lo);
        }
      }
      return acc;
    }, [] as Array<LearningObjectiveApi>);
    return subjectLearningObjectives;
  }

  //get the Codon LOs for Codon topics
  const selectedCodonTopics = topics.filter(t => selectedTopicIds.includes(t.id) && selectedUnitIds.includes(t.unitId) && t.type === LibraryTypeEnum.Template);
  const baseLosForSelectedCodonTopics = learningObjectives.filter(alo => selectedCodonTopics.find(sct => sct.id === alo.topicId) && alo.type === LibraryTypeEnum.Template);
  const clsoLosForSelectedCodonTopics = learningObjectives.filter(alo => {
    return (
      classSessionLearningObjectives.find(cslo => cslo.learningObjectiveId === alo.id && selectedCodonTopics.find(t => t.id === cslo.topicId)) &&
      alo.type === LibraryTypeEnum.Template
    );
  });

  // get Codon and user LOs for user topics
  const selectedUserTopics = topics.filter(t => selectedTopicIds.includes(t.id) && t.type === LibraryTypeEnum.User);
  const baseLosForSelectedUserTopics = learningObjectives.filter(alo => selectedUserTopics.find(sct => sct.id === alo.topicId));
  const clsoLosForSelectedUserTopics = learningObjectives.filter(alo => {
    return (
      classSessionLearningObjectives.find(cslo => cslo.learningObjectiveId === alo.id && selectedUserTopics.find(t => t.id === cslo.topicId))
    );
  });

  // get the LOs for Codon topics that show under the custom unit
  const selectedCodonTopicsInCustomUnit = topics.filter(t => selectedTopicIds.includes(t.id * -1) && selectedUnitIds.some(unitId => customUnitIds.includes(unitId)) && t.type === LibraryTypeEnum.Template);
  const cslosForSelectedCodonTopicsUnderUserUnit = learningObjectives.filter(alo => {
    return (
      alo.type === LibraryTypeEnum.User &&
      (classSessionLearningObjectives.find(cslo => cslo.learningObjectiveId === alo.id && selectedCodonTopicsInCustomUnit.find(t => t.id === cslo.topicId)) ||
      selectedCodonTopicsInCustomUnit.find(t => t.id === alo.topicId))
    );
  });

  // put them all together and dedupe
  const aggregateLos = [...baseLosForSelectedCodonTopics, ...baseLosForSelectedUserTopics, ...clsoLosForSelectedCodonTopics, ...clsoLosForSelectedUserTopics, ...cslosForSelectedCodonTopicsUnderUserUnit];
  const dedupedLos = aggregateLos.reduce((acc: Array<LearningObjectiveApi>, cur: LearningObjectiveApi) => {
    if (!acc.find(lo => lo.id === cur.id)) {
      acc.push(cur);
    }
    return acc;
  }, []);
  return dedupedLos;
};

// This function sorts LOs alphabetically by subject, then according to the `order` property of the unit, topic and LO.
// Custom LOs go at the end of each topic.
export function sortLearningObjectives(
  learningObjectives: Array<LearningObjectiveApi>,
  subjects: Array<SubjectApi>,
  units: Array<UnitApi>,
  topics: Array<TopicApi>,
  primarySubjectId: number
) {
  return [...learningObjectives].sort((loA, loB) => {

    const topicA = topics.find(topic => topic.id === loA.topicId) as TopicApi;
    const topicB = topics.find(topic => topic.id === loB.topicId) as TopicApi;

    const unitA = units.find(unit => unit.id === topicA.unitId) as UnitApi;
    const unitB = units.find(unit => unit.id === topicB.unitId) as UnitApi;

    const subjectA = subjects.find(subject => subject.id === unitA.subjectId) as SubjectApi;
    const subjectB = subjects.find(subject => subject.id === unitB.subjectId) as SubjectApi;

    // put primary subject first
    if (subjectA.id === primarySubjectId && subjectB.id !== primarySubjectId) {
      return -1;
    } else if (subjectA.id !== primarySubjectId && subjectB.id === primarySubjectId) {
      return 1;
    } else if (subjectA.name !== subjectB.name) {
      return subjectA.name.localeCompare(subjectB.name);
    }

    // always put user LOs at the end of template ones within a subject
    if (loA.type === LibraryTypeEnum.User && loB.type !== LibraryTypeEnum.User) {
      return 1;
    }

    // user LOs are sorted by topic name, this comes before the unit order because User Los might live in template Units
    if (loA.type === LibraryTypeEnum.User && loB.type === LibraryTypeEnum.User) {
      return topicA.name.localeCompare(topicB.name);
    }

    // units are probably sorted by order in the API call, but just in case
    if (unitA.order !== unitB.order) {
      return unitA.order - unitB.order;
    }

    if (topicA.order && topicB.order && topicA.order !== topicB.order) {
      return topicA.order - topicB.order;
    }

    if (loA.type === LibraryTypeEnum.User && loB.type !== LibraryTypeEnum.User) {
      return 1;
    }

    return (loA.order ?? 0) - (loB.order ?? 0);
  });
}

export const getFilterDefaultsForClassSession = ({
  classSessionId,
  classSessionLearningObjectives,
  classSessionTopics,
  learningObjectives,
  topics,
  units,
}: {
  classSessionId: number
  classSessionLearningObjectives: Array<ClassSessionLearningObjectiveApi>
  classSessionTopics: Array<ClassSessionTopicApi>
  learningObjectives: Array<LearningObjectiveApi>
  topics: Array<TopicApi>
  units: Array<UnitApi>
}) => {
  const expandedSubjectIds = new Set<number>();
  const topicIdSet = new Set<number>();
  const unitIdSet = new Set<number>();

  classSessionTopics.forEach((cst) => {
    if (cst.classSessionId === classSessionId) {
      const { topicId: cstTopicId } = cst;
      topicIdSet.add(cstTopicId);
      const customTopicId = cstTopicId * -1;

      const userLearningObjectives = learningObjectives.filter(alo => alo.type === LibraryTypeEnum.User);

      if (userLearningObjectives.some(alo => alo.topicId === cstTopicId)) {
        topicIdSet.add(customTopicId);
      } else {
        // if it hasn't already been added, check to see if there is a cslo with a custom LO for it
        const topicInCslos = userLearningObjectives.find(alo =>
          classSessionLearningObjectives.find(cslo => cslo.learningObjectiveId === alo.id && cslo.topicId === cstTopicId)
        );
        if (topicInCslos && cstTopicId > 0) {
          topicIdSet.add(customTopicId);
        }
      }
    }
  });

  // add any topics for the current classSession that have CSLOs for custom LOs, negative topicIds if Codon topics
  classSessionLearningObjectives.forEach((cslo) => {
    if (cslo.classSessionId === classSessionId) {
      const lo = learningObjectives.find(alo => alo.id === cslo.learningObjectiveId) as LearningObjectiveApi;
      if (lo.type === LibraryTypeEnum.User) {
        const parentTopic = topics.find(t => t.id === lo.topicId) as TopicApi;
        const topicIdToAdd = parentTopic.type === LibraryTypeEnum.Template ? parentTopic.id * -1 : parentTopic.id;
        topicIdSet.add(topicIdToAdd);
      }
    }
  });


  // get all the unitIds for the positive topicIds in the session
  units.forEach((unit) => {
    if (topics.find(topic => topicIdSet.has(topic.id) && topic.unitId === unit.id)) {
      expandedSubjectIds.add(unit.subjectId);
      unitIdSet.add(unit.id);
    }
  });
  // get all the unitIds for the negative topicIds in the session
  topics.forEach((topic) => {
    if (topicIdSet.has(topic.id * -1)) {
      const customUnitToAdd = units.find(u => u.subjectId === topic.subjectId && u.type === LibraryTypeEnum.User) as UnitApi;
      // Fixed as of CA-4899 but adding some safety to prevent sad piggy
      if (!!customUnitToAdd) {
        expandedSubjectIds.add(customUnitToAdd.subjectId);
        unitIdSet.add(customUnitToAdd.id);
      }
    }
  });

  return {
    subjectIds: [...expandedSubjectIds],
    topicIds: [...topicIdSet],
    unitIds: [...unitIdSet],
  };
};
