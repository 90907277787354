import React from 'react';

function MetacognitivePuddles({ confused }: {confused: boolean}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeMiterlimit: 1.5,
      }}
      viewBox="0 0 177 177"
    >
      <g transform="translate(-810.08 -214.473)">
        <path
          d="M810.376 214.805h175.962v175.962H810.376z"
          style={{
            fill: 'none',
          }}
        />
        <clipPath id="a">
          <path d="M810.376 214.805h175.962v175.962H810.376z" />
        </clipPath>
        <g clipPath="url(#a)">
          <path
            d="M5.03 10.857c-.819 1.558-1.461 3.328-1.913 5.276-.881 3.789-.434 6.852 1.329 9.106 2.097 2.68 5.98 4.081 11.54 4.163h.028c5.561-.082 9.443-1.483 11.54-4.163 1.763-2.254 2.21-5.317 1.329-9.106-.453-1.95-1.095-3.719-1.913-5.276 4.336-1.999 4.998-8.041 5.025-8.317a.937.937 0 0 0-.537-.944c-.162-.076-4.024-1.84-7.514-.493-1.565.604-2.794 1.722-3.663 3.33a11.622 11.622 0 0 0-2.182-.628 11.592 11.592 0 0 0-6.38.628c-.869-1.608-2.098-2.726-3.662-3.33C4.566-.244.704 1.52.542 1.596a.937.937 0 0 0-.537.944c.028.276.689 6.318 5.025 8.317Zm19.58-8.001c2.008-.78 4.311-.187 5.41.19-.289 1.508-1.247 5.035-4.017 6.186-1.075-1.594-2.377-2.968-4.039-3.967.65-1.171 1.538-1.979 2.646-2.409ZM14.239 5.65c3.94-.72 7.946.887 10.568 5.19.986 1.613 1.743 3.536 2.25 5.717 1.835 7.896-2.98 10.847-11.057 10.97-4.958-.075-8.348-1.234-10.077-3.444-1.396-1.784-1.726-4.316-.98-7.526 1.194-5.148 4.091-9.95 9.296-10.907ZM7.381 2.852c1.112.429 2.003 1.239 2.655 2.413-1.658.995-2.977 2.391-4.039 3.967-2.759-1.15-3.724-4.682-4.015-6.189 1.093-.378 3.382-.969 5.399-.191Z"
            style={{
              fillRule: 'nonzero',
            }}
            transform="matrix(5.07534 0 0 5.07534 817.151 321.588)"
          />
          {confused ? (
            <>
              <path
                d="M512.352 213.931c3.805-5.125 9.009-5.346 12.494-3.158 4.544 2.852 5.504 7.343 2.624 10.859-4.248 5.184-8.916 2.68-8.871 9.008"
                style={{
                  fill: 'none',
                  stroke: '#000',
                  strokeWidth: '5.4px',
                }}
                transform="matrix(1.75983 0 0 1.75983 -18.039 -110.33)"
              />
              <path
                d="M22.346 15.285a.937.937 0 1 0 .002-1.874.937.937 0 0 0-.002 1.874Z"
                style={{
                  fillRule: 'nonzero',
                }}
                transform="matrix(8.0317 0 0 8.0317 715.13 197.806)"
              />
            </>
          ) : (
            <>
              <path
                d="M725.262 393.868c0-7.718-6.266-13.984-13.985-13.984-7.718 0-13.984 6.266-13.984 13.984v-.19c0 7.719 8.281 9.52 8.281 17.238 3.802 1.194 7.604 1.086 11.407 0 0-7.718 8.281-9.519 8.281-17.238"
                style={{
                  fill: 'none',
                  stroke: '#000',
                  strokeWidth: 4,
                }}
                transform="translate(-68.534 -242.721) scale(1.35937)"
              />
              <path
                d="M705.574 410.916c3.74 1.02 7.536.889 11.407 0"
                style={{
                  fill: 'none',
                  stroke: '#000',
                  strokeWidth: '4.19px',
                }}
                transform="matrix(1.23159 0 0 1.35937 22.354 -235.991)"
              />
              <path
                d="m691.616 389.194-8.427-1.485M693.987 402.644l-7.41 4.278 7.41-4.278M735.978 406.922l-7.411-4.278 7.411 4.278ZM730.939 389.194l8.426-1.485M724.111 377.367l5.5-6.554M711.277 372.696v-8.556M698.444 377.367l-5.5-6.554"
                style={{
                  fill: 'none',
                  stroke: '#000',
                  strokeWidth: 3,
                }}
                transform="matrix(1.27254 0 0 1.27254 -6.777 -207.245)"
              />
            </>
          )}
        </g>
      </g>
    </svg>
  );
}

export default MetacognitivePuddles;
