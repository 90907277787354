import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import getAssessmentQuestionsMetadata, { QuestionPreviewLaunchWithMetadata } from 'utils/getAssessmentQuestionsMetadata';
import usePaginationWithGroupedItems from 'hooks/usePaginationWithGroupedItems';
import QuestionListItemRow from './QuestionListItemRow';
import QuestionListItemGroup from './QuestionListItemGroup';
import { AssessmentBuilderContext } from '../../AssessmentBuilderController';
import { EnrichedEditingAssessment } from '../../enrichAssessmentForEditing';
import { EnrichedQuestionGroup } from 'store/selectors/retrieveEnrichedQuestionGroups';
import { ActiveCombinedQuestion } from 'store/selectors/retrieveActiveCombinedQuestions';
import { YesNo } from 'types/backend/shared.types';
import { AssessmentApiBase } from 'types/backend/assessments.types';
import { QuestionAction, TabNavigate } from '../../AssessmentBuilderController.types';
import './QuestionList.scss';

function QuestionList({
  currentAssessment,
  questionList,
  navigate,
  questionAction,
  setQuestionPreview,
  startedQuestionIds,
}: {
  currentAssessment: EnrichedEditingAssessment | AssessmentApiBase
  questionList: Array<ActiveCombinedQuestion>
  navigate: TabNavigate
  questionAction: QuestionAction
  setQuestionPreview: (preview: QuestionPreviewLaunchWithMetadata | null) => void
  startedQuestionIds: Array<number>
}) {
  const { groupedQuestions, hasBeenStarted, isProcessing } = useContext(AssessmentBuilderContext);

  const findItemGroupIndex = useCallback((item: ActiveCombinedQuestion, itemGroups: Array<EnrichedQuestionGroup>) => {
    if (!itemGroups || !itemGroups.length) {
      return { groupIndex: -1, groupLength: 0 };
    }
    const groupIndex = itemGroups.findIndex((group) => {
      const groupQuestionsIds = group.groupQuestionIds;
      const isGrouped = groupQuestionsIds.includes(item.id);
      return isGrouped;
    });
    if (groupIndex !== -1) {
      return { groupIndex, groupLength: itemGroups[groupIndex].groupQuestionIds.length };
    } else {
      return { groupIndex: -1, groupLength: 0 };
    }
  }, []);

  const allFilteredQuestions = useMemo(() => {
    const allGroupedQuestions = groupedQuestions.map(({ groupQuestions }) => groupQuestions).flat();
    const activeQuestions = questionList.filter(q => q.active === YesNo.Yes);
    return [...allGroupedQuestions, ...activeQuestions];
  }, [groupedQuestions, questionList]);

  const {
    currentPage,
    pageLength,
    totalPages,
    displayItems,
    displayGroups,
    nextPage,
    prevPage,
    startIndex,
    endIndex,
    shouldPaginate,
  } = usePaginationWithGroupedItems({ items: allFilteredQuestions, groups: groupedQuestions, pageSize: 50, findItemGroupIndex });

  const questionsForPreview = displayGroups.map(({ groupQuestions }) => groupQuestions).reduce((acc, val) => acc.concat(val), []).concat(displayItems);
  const assessmentQuestionMetadata = getAssessmentQuestionsMetadata(questionsForPreview, currentAssessment, hasBeenStarted, startedQuestionIds);

  const itemRowSharedProps = {
    currentAssessmentId: currentAssessment.id,
    isProcessing,
    assessmentQuestionMetadata,
    questionAction,
    setQuestionPreview,
    navigate,
  };

  return (
    <div className="question-list">
      <table className="question-list__table">
        <thead className="question-list__header">
          <tr>
            <th className="question-list__item-title">
              Item Title&nbsp;
              <span className="question-list__question-count">
                (Displaying {pageLength} Items{shouldPaginate && ` of ${allFilteredQuestions.length}`})
              </span>
            </th>
            <th className="question-list__item-los">LO</th>
            <th className="question-list__item-blooms">Bloom's</th>
            <th/>
          </tr>
        </thead>
        <tbody className="question-list__wrapper">
          {!!displayGroups.length && displayGroups.map(({ questionGroupId, groupTitle, groupQuestions }) => (
            <QuestionListItemGroup
              groupQuestions={groupQuestions}
              groupTitle={groupTitle}
              key={questionGroupId}
              {...itemRowSharedProps}
            >
              {groupQuestions.map((question) => (
                <QuestionListItemRow
                  key={`question-group-item_${questionGroupId}-${question.id}`}
                  isGrouped
                  question={question}
                  questionTitle={question.shortTitle || question.title}
                  {...itemRowSharedProps}
                />
              ))}
            </QuestionListItemGroup>
          ))}
          {displayItems.map((question: ActiveCombinedQuestion) => (
            <QuestionListItemRow
              key={question.l8yId}
              question={question}
              {...itemRowSharedProps}
            />
          ))}
        </tbody>
        {shouldPaginate && (
          <tfoot><tr><th colSpan={4}>
            <div className="question-list__footer-cell">
              <button
                disabled={currentPage === 1}
                onClick={prevPage}
                aria-label="Previous page of results"
                data-action="previous-page"
              >
                <FaChevronLeft />
              </button>
              <span>
                Displaying items {startIndex} to {endIndex} of {allFilteredQuestions.length}
              </span>
              <button
                disabled={currentPage === totalPages}
                onClick={nextPage}
                aria-label="Next page of results"
                data-action="next-page"
              >
                <FaChevronRight />
              </button>
            </div>
          </th></tr></tfoot>
        )}
      </table>
    </div>
  );
}

QuestionList.propTypes = {
  currentAssessment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assessType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    classSessionIds: PropTypes.array,
  }).isRequired,
  startedQuestionIds: PropTypes.array.isRequired,
  questionAction: PropTypes.func.isRequired,
  setQuestionPreview: PropTypes.func.isRequired,
  questionList: PropTypes.array.isRequired,
};

export default QuestionList;
