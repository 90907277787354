// adapted from https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { saveLogMessage } from 'utils/saveLogMessage';
import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import sharedStrings from 'sharedStrings';
import PuddlesOhNo from 'shared-components/Icon/PuddlesOhNo';
import CodonUrls from 'urls';
import { LogoutStatusEnum } from 'types/common.types';
import { GenericObject } from 'types/backend/shared.types';
import './ErrorBoundary.scss';

const version = process.env.REACT_APP_VERSION || '0.0.0';

interface Props {
  children: ReactNode
  isInstructor?: boolean
  isAdmin?: boolean
}

interface State {
  hasError: boolean
  showMoreInfo: boolean
  errorValue: GenericObject
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorValue: {},
    showMoreInfo: false,
  };

  public static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  public async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    await saveLogMessage(`WEB (${version}) - error: ${error.message}, errorInfo: ${JSON.stringify(errorInfo, null, 2)}`);
    this.setState({ errorValue: { errorMessage: error.message, errorInfo } });
  }

  render() {
    const storedUser = JSON.parse(window.localStorage.getItem('user') || '{}');
    const isLtiUser = !!storedUser?.ltiUserId;
    const { isInstructor = false, isAdmin = false } = this.props;
    const { errorValue, hasError, showMoreInfo } = this.state;
    const { errorMessage } = errorValue;
    let homeLink = '/student';
    if (isAdmin) {
      homeLink = '/admin';
    } else if (isInstructor) {
      homeLink = '/instructor';
    }
    if (hasError) {
      const logoutHref = `/logout?status=${isLtiUser ? LogoutStatusEnum.IdleLogoutLti : LogoutStatusEnum.IdleLogout}`;
      return (
        <div className="error-boundary">
          <div className="error-boundary__content">
            <div className="pig-pen"><PuddlesOhNo /></div>
            <h1>Oh no! Something went wrong.</h1>
            <p className="error-boundary__message">
              Try re-opening your course from the <a href={homeLink}>My Courses</a> page or <a href={logoutHref}>logout</a> and&nbsp;
              {isLtiUser
                ? <>re-launch from your Learning Management System ({sharedStrings.LMS_LIST}).</>
                : <>log back in.</>
              }
              <br/>Please visit our {externalLink(CodonUrls.SupportKB, 'Support Page')} if this problem persists.
            </p>
            {!!errorMessage && <small className="">Error Message: {errorMessage}</small>}
            <div className="show-debug-info">
              <button onClick={() => this.setState({ showMoreInfo: !showMoreInfo })}>
                {showMoreInfo
                  ? 'Hide Info'
                  : 'Show Debug Info'
                }
              </button>
              {showMoreInfo && <textarea className="more-info" readOnly value={JSON.stringify(errorValue, null, 2)} />}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
