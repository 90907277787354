import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import logout from 'utils/logout';
import useQuery from 'hooks/useQuery';
import { useAppDispatch } from 'store';

export default function Logout() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useQuery();
  // if we link to `/logout` we want to be able to pass logout status query similar to idle timeout
  const statusQuery = query.get('status');
  const statusArg = statusQuery ? `status=${statusQuery}` : undefined;
  const handleLogout = useCallback(async () => {
    try {
      await logout(dispatch, history, statusArg);
    } catch (error: any) {
      console.error('Error logging out', error.message);
    }
  }, [dispatch, history, statusArg]);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return (
    <div>Logging out...</div>
  );
}
