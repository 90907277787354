// This action reloads assessmentQuestions from the API based on an array of assessmentIds
// it then updates Redux with the reloaded AQs and returns them as well.

import apiNext from 'api-next';
import activeSlice from 'store/slices/active';
import { AppDispatch, Store } from 'types/store.types';

export default function reloadAssessments(
  courseId: string
) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {

    const reloadedAssessments = await apiNext.getAssessmentsByCourseId(courseId);

    return dispatch(activeSlice.actions.setActiveAssessments(reloadedAssessments));

  })();
}
