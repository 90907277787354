/**
 * useIdleLogout
 *
 * Wrapper around useIdleTimer so we can implement logout-on-idle in the various places where it is needed
 * User is considered idle after 5 minutes, if they have been idle longer than REACT_APP_HOURS_IDLE_UNTIL_LOGOUT (default 24)
 * they will be forcibly logged out UNLESS they have LOGOUT_ON_IDLE: false in localstorage
 * Leaving this option open because it would be nice to have the option to let users stay logged in
 * if they're using the app on the same puter all the time.
 */
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import useLocalStorage from 'hooks/useLocalStorage';
import logout from 'utils/logout';
import { idleTimerConfig } from 'utils/sharedConfig';
import { useAppDispatch } from 'store';
import { UserApi } from 'types/backend/users.types';
import { LogoutStatusEnum } from 'types/common.types';

const hoursUntilLogout = Number(process.env.REACT_APP_HOURS_IDLE_UNTIL_LOGOUT) || 24;

export enum IdleLogoutInstance {
  Admin = 'admin',
  Instructor = 'instructor',
  Student = 'student',
  TermsOfService = 'terms-of-service',
}

function useIdleLogout(user: UserApi, idleId: IdleLogoutInstance, timeoutHrs?: number) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [logoutOnIdle] = useLocalStorage('LOGOUT_ON_IDLE', true);
  const idleTimeoutInHours = timeoutHrs || hoursUntilLogout;
  // ltiUserId is null on local and '' on deploy, !! catches both as falsy
  const logoutStatus = !!user.ltiUserId ? LogoutStatusEnum.IdleLogoutLti : LogoutStatusEnum.IdleLogout;
  const onActive = async () => {
    const idleForMilliseconds = getIdleTime();
    const minutesIdle = Math.round(idleForMilliseconds / 1000 / 60);
    console.debug(`:: Returning from Idle after ${minutesIdle}m, idleId: ${idleId}`);
    // only logout if logged in to prevent inadvertent logout spam
    if (!!user && logoutOnIdle && minutesIdle > (idleTimeoutInHours * 60)) {
      console.warn(`:: Idle Limit ${idleTimeoutInHours}h exceeded`, minutesIdle);
      await logout(dispatch, history, `status=${logoutStatus}`);
    } else {
      reset();
    }
  };
  const idleTimer = useIdleTimer({
    ...idleTimerConfig,
    onIdle: () => console.debug(':: IDLE'),
    onActive,
    name: `idle-timer__logout__${idleId}`,
    debounce: 500,
    crossTab: true,
  });
  const { getIdleTime, reset } = idleTimer;
  return idleTimer;
}

export default useIdleLogout;
