import React from 'react';
import Select, { SingleValue } from 'react-select';

import { useGetInstitutionsQuery } from 'api-next';
import Spinner from 'shared-components/Spinner/Spinner';
import { DropdownOption } from 'instructor/components/Dropdown/Dropdown.types';

function InstitutionDropdown({ onChange, value, name }: {
  onChange: (institutionId: number | null) => void
  value: number | null
  name?: string
}) {
  const { data: institutionsList = [], isLoading } = useGetInstitutionsQuery();
  const institutionDropdownOptions = institutionsList.map((institution) => ({ value: institution.id, label: institution.name }));
  const optionsWithNoInstitution = [{ value: null, label: 'No institution' }, ...institutionDropdownOptions];
  const selectedInstitution = optionsWithNoInstitution.find((option) => option.value === value);
  const handleChange = (newValue: SingleValue<DropdownOption<number | null>> | null) => {
    if (!newValue) {
      return;
    }
    onChange(newValue.value);
  };
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Select
      className="form-select"
      id="course-institution"
      value={selectedInstitution}
      options={institutionDropdownOptions}
      name={name}
      onChange={handleChange}
    />
  );
}

export default InstitutionDropdown;
