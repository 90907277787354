// adapted from src/hooks/usePagination.ts (previously adapted from: https://github.com/ndungu-mbau/use-pagination)
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/*
* This component is an extension of the usePagination hook.
* It is extended to allow for the pagination of items that are grouped together in addition to the standard pagination of ungrouped items.
* The findItemGroupIndex function is used to determine if an item is part of a group and if so, which group it is part of.
* And so the organization of the items within the groups does not matter, as long as the findItemGroupIndex function can determine the group index and length.
* The `items` param must contain all items, including those that are part of a group.
* Items that are part of a group will not be returned in the displayItems array, but will be returned in the displayGroups array.
* `displayItems` order will match `items` order, with the exception that items that are in a group will be removed. Group order is preserved as well
* Groups will not be broken across pages, and the full group will be displayed on the page of the first item in the group.
* Page length and other values will be adjusted accordingly.
*/

type IUsePaginationWithGroupedItems<T, G> = {
  currentPage: number
  pageLength: number
  displayItems: Array<T>
  totalPages: number
  nextPage: () => void
  prevPage: () => void
  first: () => void
  last: () => void
  set: (num: number) => void
  startIndex: number
  endIndex: number
  shouldPaginate: boolean
  displayGroups: Array<G>
};

type UsePaginationWithGroupedItemsProps <T, G> = {
  items: Array<T>
  pageSize: number
  groups: Array<G>
  findItemGroupIndex: (item: T, itemGroups: Array<G>) => {groupIndex: number; groupLength: number}
}

type PageData<T, G> = {
  items: Array<T>
  groups: Array<G>
  pageLength: number
  itemStartIndex: number
  itemEndIndex: number
}

const usePaginationWithGroupedItems = <T, G>({ items, pageSize = 50, groups, findItemGroupIndex }: UsePaginationWithGroupedItemsProps<T, G>): IUsePaginationWithGroupedItems<T, G> => {
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pages, setPages] = useState<Array<PageData<T, G>>>([{ items: [], groups: [], pageLength: 0, itemStartIndex: 0, itemEndIndex: 0 }]);

  useEffect(() => {
    const preparePages = () => {
      const pagesArray: Array<PageData<T, G>> = [];
      let pageItemCount = 0;
      let pageItems: Array<any> = [];
      let pageGroups: Array<any> = [];
      const processedGroupIndexes: Array<number> = [];
      items.forEach((item, index) => {
        const { groupIndex, groupLength } = findItemGroupIndex(item, groups);
        if (groupIndex >= 0 && !processedGroupIndexes.includes(groupIndex)) { // The item is in a group and the group has not been processed
          pageGroups = pageGroups.concat(groups[groupIndex]); // Add the group to the page
          processedGroupIndexes.push(groupIndex);
          pageItemCount += groupLength;
        } else if (groupIndex === -1) {
          pageItems.push(item); // Add the item to the page
          pageItemCount++;
        }
        if (pageItemCount >= pageSize || index === items.length - 1) { // If the page is full or we are at the end of the items array
          if (!!pageItems.length || !!pageGroups.length) { // If the page is not empty
            const itemStartIndex = !!pagesArray.length ? pagesArray[pagesArray.length - 1].itemEndIndex + 1 : 1;
            pagesArray.push({ items: pageItems, groups: pageGroups, pageLength: pageItemCount, itemStartIndex, itemEndIndex: pageItemCount + itemStartIndex - 1 });
          }
          pageItems = [];
          pageGroups = [];
          pageItemCount = 0;
        }
      });
      setPages(pagesArray);
      setTotalPages(pagesArray.length);
    };
    preparePages();
  }, [currentPageIndex, items, pageSize, groups, findItemGroupIndex]);

  useEffect(() => {
    // if page length changes and current page idx is out of range, set to last page
    if (currentPageIndex > pages.length) {
      setCurrentPageIndex(pages.length);
    }
  }, [currentPageIndex, pages.length]);

  const currentPageData = pages[currentPageIndex] || [...pages].pop() || { pageLength: 0, items: [], groups: [], itemStartIndex: 0, itemEndIndex: 0 };
  const {
    pageLength,
    items: displayItems,
    groups: displayGroups,
    itemStartIndex: startIndex,
    itemEndIndex: endIndex,
  } = currentPageData;

  return {
    currentPage: currentPageIndex + 1,
    pageLength,
    displayItems,
    displayGroups,
    totalPages,
    nextPage: () => setCurrentPageIndex(currentPageIndex + 1),
    prevPage: () => setCurrentPageIndex(currentPageIndex - 1),
    first: () => setCurrentPageIndex(1),
    last: () => setCurrentPageIndex(totalPages),
    set: (num: number) => setCurrentPageIndex(num),
    startIndex,
    endIndex,
    shouldPaginate: items.length > pageSize,
  };
};

usePaginationWithGroupedItems.PropTypes = {
  size: PropTypes.number,
  items: PropTypes.array,
  groups: PropTypes.array,
  findItemGroupIndex: PropTypes.func,
};

usePaginationWithGroupedItems.defaultProps = {
  size: 50,
  items: [],
};

export default usePaginationWithGroupedItems;
