/***
 * retrieveNestedSubjectsUnitsAndTopics
 * This selector builds a nested object of subjects, units, and topics
 * for both the primary and additional subjects in the active course
*/

import { createSelector } from 'reselect';
import { getUnitName } from 'utils/commonFormattingFunctions';
import { Store } from 'types/store.types';
import { SubjectApi } from 'types/backend/subjects.types';
import { UnitApi } from 'types/backend/units.types';
import { TopicApi } from 'types/backend/topics.types';
import { CourseApi } from 'types/backend/courses.types';
import { ClassSessionTopicApi } from 'types/backend/classSessionTopics.types';
import { UserApi, UserApiIdNameEmail } from 'types/backend/users.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';

export interface TopicsWithClassSessionIds extends TopicApi {
  topicClassSessionIds: Array<number>
  hasLos: boolean
}
export interface UnitWithTopics extends UnitApi {
  topics: Array<TopicsWithClassSessionIds>
  unitHasTopics: boolean
  unitHasTopicsWithLos: boolean
}
interface SubjectsUnitsAndTopics extends SubjectApi {
  units: Array<UnitWithTopics>
}

interface SubjectBreakdown {
  primarySubject: SubjectApi
  welcomeSubject: SubjectApi
  otherSubjects: Array<SubjectApi>
}

export default createSelector(
  (store: Store) => store.passive.subjects,
  (store: Store) => store.active.units,
  (store: Store) => store.active.topics,
  (store: Store) => store.active.learningObjectives,
  (store: Store) => store.active.course,
  (store: Store) => store.active.classSessionTopics,
  (store: Store) => store.user,
  (store: Store) => store.active.instructors,
  (
    subjects: Array<SubjectApi>,
    units: Array<UnitApi>,
    topics: Array<TopicApi>,
    learningObjectives: Array<LearningObjectiveApi>,
    course: CourseApi,
    classSessionTopics: Array<ClassSessionTopicApi>,
    user: UserApi,
    instructors: Array<UserApiIdNameEmail>
  ) => {
    const { additionalSubjectIds, subjectId: primarySubjectId } = course;

    // split subjects up so we can put primarySubject first and Codon subject last
    // primarySubject and welcomeSubject should always be defined, if there are no other subjects otherSubjects should be empty array
    const { primarySubject, welcomeSubject, otherSubjects = [] } = subjects.reduce((acc, cur) => {
      if (cur.id === primarySubjectId) {
        return {
          ...acc,
          primarySubject: cur,
        };
      }
      if (additionalSubjectIds.includes(cur.id)) {
        if (cur.stringId === 'CODON') {
          return {
            ...acc,
            welcomeSubject: cur,
          };
        }
        if (!acc.otherSubjects) {
          return {
            ...acc,
            otherSubjects: [cur],
          };
        }
        return {
          ...acc,
          otherSubjects: [
            ...acc.otherSubjects,
            cur,
          ],
        };
      }
      return acc;
      // using `as` here because the alternative would be to make primarySubject and welcomeSubject null | SubjectApi
    }, {} as SubjectBreakdown);

    if (!primarySubject || !welcomeSubject || !otherSubjects) {
      console.error(`Required subject data is missing ${JSON.stringify({ primarySubject, welcomeSubject, otherSubjects })}`);
    }

    const subjectsInOrder = [primarySubject, ...otherSubjects, welcomeSubject].filter(Boolean); // filter out undefined for safety

    // nest units under their subject and topics under their unit, this does not permute the sorting of units and topics they are in the same order as in state from the backend
    return subjectsInOrder.reduce((accSubjects, subject) => {
      const subjectUnitsWithTopics = units.reduce((accUnits, unit) => {
        let unitHasTopics = false;
        let unitHasTopicsWithLos = false;
        if (unit.subjectId === subject.id) {
          const unitTopics = topics.reduce((accTopics, topic) => {
            unitHasTopics = true;
            if (topic.unitId === unit.id) {
              // embed topicClassSessionIds into nested topic data
              const topicClassSessionIds = classSessionTopics.reduce((cstAcc, cst) => {
                if (cst.topicId === topic.id) {
                  cstAcc.push(cst.classSessionId);
                }
                return cstAcc;
              }, [] as Array<number>);

              // check if the topic has learning objectives and add some metadata to that effect
              const hasLos = learningObjectives.some((lo) => lo.topicId === topic.id);
              if (hasLos && !unitHasTopicsWithLos) {
                unitHasTopicsWithLos = true;
              }

              accTopics.push({
                ...topic,
                topicClassSessionIds,
                hasLos,
              });
            }
            return accTopics;
          }, [] as Array<TopicsWithClassSessionIds>);
          const unitName = getUnitName({ unit, unitBelongsToThisUser: unit.userId === user.id, instructors, subjectName: subject.name });
          accUnits.push(
            {
              ...unit,
              name: unitName,
              topics: unitTopics,
              unitHasTopics,
              unitHasTopicsWithLos,
            }
          );
        }
        return accUnits;
      }, [] as Array<UnitWithTopics>);
      return [
        ...accSubjects,
        {
          ...subject,
          units: subjectUnitsWithTopics,
        },
      ];
    }, [] as Array<SubjectsUnitsAndTopics>);
  });
