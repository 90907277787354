/**
 * retrieveBetterCoursePlannerWeeks
 **/
import { createSelector } from '@reduxjs/toolkit';
import uniqBy from 'lodash-es/uniqBy';
import { DateTime } from 'luxon';
import retrieveBetterClassSessions, { BetterClassSession, BetterClassSessionTopic } from 'store/selectors/retrieveBetterClassSessions';

export interface CoursePlannerWeek {
  weekNumber: number
  weekClassSessions: Array<BetterClassSession>
  weekTopics: Array<BetterClassSessionTopic>
  weekIsInPast: boolean
}

export const calculateWeeksForCoursePlanner = (classSessions: Array<BetterClassSession>) => {
  const now = DateTime.local();
  return classSessions.reduce((coursePlannerWeekArray, cur, idx) => {
    const { weekNumber, topics } = cur;
    /**
     * This feels a little spooky-action-at-a-distance but it works. It's something I've only recently understood about mutability in JS
     * so I'm going to try to explain it to myself. Though it might be a fairly basic CS concept, I was late to it
     * because a lot of my JS learning strongly favored immutability rather than modifying values in place, specifically to avoid
     * a particular strain of haunting side-effects that unintentional in-place value modification is heir to
     */
    // 1. currentWeekObject is not a value, it is a reference, it's "wired" to the item found in coursePlannerWeekArray, if it finds one.
    const currentWeekObject = coursePlannerWeekArray.find((cpcs) => cpcs.weekNumber === weekNumber);
    // 1a: If an existing week is found, we will mutably update the object, playing it where it lies
    if (!!currentWeekObject) {
      // 2. Modifying properties of currentWeekObject will "reach into" coursePlannerWeekArray to update the values, mutating the existing array in this case
      currentWeekObject.weekClassSessions.push(cur);
      const { weekTopics: existingWeekTopics = [] } = currentWeekObject;
      // 3. for weekTopics, I don't want to reach into the array because it always needs to be a unique set, which makes more sense to just replace currentWeekObject.weekTopics with the new set every loop
      currentWeekObject.weekTopics = uniqBy([...existingWeekTopics, ...topics], 'id'); // unrelated aside: much as it pains me to resort to lodash here, uniqBy is one of its legitimately difficult to replace methods IMO
      // 4. intriguingly, we don't need to return currentWeekObject, because we are manipulating all the values within the array in place

      // if every class session is past, week has past
      // weekIsInPast = currentWeekObject.every(({}))
      return coursePlannerWeekArray;
    } else {
      // if no currentWeekObject, then we are on the first class session in a new week
      // compare the date of the last class session to now to determine if the week has past
      const previousClassSession = classSessions[idx - 1];
      if (!!previousClassSession) {
        const { luxonDate: prevLuxonDate } = previousClassSession;
        if (!prevLuxonDate.hasSame(now, 'day') && prevLuxonDate < now) {
          const previousWeekObject = coursePlannerWeekArray.find((cpcs) => cpcs.weekNumber === (weekNumber - 1)) as CoursePlannerWeek; // this should always be found
          previousWeekObject.weekIsInPast = true;
        }
      }
    }
    // 1b. If week doesn't exist yet, return append new object to coursePlannerWeekArray
    return [
      ...coursePlannerWeekArray,
      {
        weekNumber,
        weekClassSessions: [cur],
        weekTopics: [...topics],
        weekIsInPast: false,
      },
    ];
  }, [] as Array<CoursePlannerWeek>);
};
// 5. Conclusion: I still generally prefer immutability, but sometimes this pattern is the right tool for the job

export default createSelector(
  retrieveBetterClassSessions,
  (classSessions: Array<BetterClassSession>) => {
    if (!classSessions.length) {
      return [];
    }

    return calculateWeeksForCoursePlanner(classSessions);
  }
);
