import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import apiNext from 'api-next';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import {
  enrichQuestionDataForSPATL8y,
  filterAndEnrichTodoPastQuestions,
  getAllAssessmentQuestionsFromEnrichedSTCLOs,
  getStudyPathHashesFromSaqa,
  QuestionDatumForSPATL8y,
} from '../../sharedStudyPathFunctions';
import { handleStudentAttemptResponse } from 'utils/assessmentFunctions';
import LearnosityContainer, { L8yContainerValidated, L8yEventPayload } from 'shared-components/LearnosityContainer/LearnosityContainer';
import StudyPathAssessmentTakerNavMenu from './StudyPathAssessmentTakerNavMenu';

import { useAppDispatch, useAppSelector } from 'store';
import loadStudentAssessmentsAndStudentAssessmentQuestions from 'store/actions/loadStudentAssessmentsAndStudentAssessmentQuestions';
import retrieveSimpleEnrichedAssessmentQuestionMaps from 'store/selectors/retrieveSimpleEnrichedAssessmentQuestionMaps';

import { L8ySessionState } from 'types/backend/l8ySessions.types';
import { RenderingTypeEnum, AssessmentModeEnum } from 'types/backend/assessmentInit.types';
import { L8yBasicItem } from 'types/backend/questions.types';
import { AssessmentLocation, CheckpointColumn } from 'types/backend/shared.types';
import { L8yContainerEvents, QuestionStatusHash } from 'shared-components/LearnosityContainer/LearnosityContainer.types';
import { EnrichedStudentTopicCardLearningObjective } from '../../StudyPathController.types';
import './StudyPathAssessmentTaker.scss';

function StudyPathAssessmentTaker({
  handleDone,
  checkpointType,
  l8ySessionId,
  learningObjectives,
  targetL8yId,
  topicName,
}: {
  handleDone: (shouldClose: false) => void
  checkpointType: CheckpointColumn
  l8ySessionId: string
  learningObjectives: Array<EnrichedStudentTopicCardLearningObjective>
  targetL8yId: string
  topicName: string
}) {
  const location = AssessmentLocation.SP;
  const dispatch = useAppDispatch();
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const studentAssessments = useAppSelector((store) => store.active.studentAssessments);
  const userId = useAppSelector((store) => store.user.id);
  const simpleAqms = useAppSelector(retrieveSimpleEnrichedAssessmentQuestionMaps);
  const allEnrichedQuestions = getAllAssessmentQuestionsFromEnrichedSTCLOs(learningObjectives);
  const { todoQuestions, pastQuestions } = filterAndEnrichTodoPastQuestions(allEnrichedQuestions, checkpointType, learningObjectives, studentAssessments, simpleAqms);
  // Get the array of LO(s) that the target question belongs to in order to show the title data
  const targetLOs = learningObjectives.filter((lo: { assessmentQuestions: Array<{ question: { l8yId: string }}> }) => lo.assessmentQuestions.map(({ question }) => question.l8yId).includes(targetL8yId));
  // active LO object for display in the interface, changes with questions
  const [activeLO, setActiveLO] = useState(targetLOs);
  const [activeQuestion, setActiveQuestion] = useState(targetL8yId);

  // compile l8yArray from [...todoQuestions, ...pastQuestions] instead of allQuestions to get the filtered q data
  const availableQuestions = [...todoQuestions, ...pastQuestions].map(({ question: { l8yId, type, gradingType } }) => ({ l8yId, type, gradingType }));
  // get all l8y items except the target l8y item
  const l8yArrayWithoutActiveQ = availableQuestions.filter(({ l8yId }) => l8yId !== activeQuestion);
  // compose l8yArray with the activeQuestion as the first item in l8y's list
  const l8yArray = [...new Set([availableQuestions.find(({ l8yId }) => l8yId === activeQuestion) as L8yBasicItem, ...l8yArrayWithoutActiveQ])];

  console.debug('StudyPathAssessmentTaker l8y data', targetL8yId, l8yArray);
  const {
    clarityHash: initClarityHash,
    correctHash: initCorrectHash,
    pointsHash: initPointsHash,
    recapHash: initRecapHash,
  } = getStudyPathHashesFromSaqa(allEnrichedQuestions);


  /*********************
   * This effect causes studentAssessments and StudentAssessmentQuestions to reload and update state
   * which in turn triggers a study path reload from the corresponding dependencies in the Course component
   */
  useEffect(() => {
    return () => {
      dispatch(loadStudentAssessmentsAndStudentAssessmentQuestions());
    };
  }, [dispatch]);

  const handleL8yEvents = async ({ type, data }: L8yEventPayload) => {
    console.debug(`LearnosityContainer event:: ${type}`, data);
    switch (type) {
      case L8yContainerEvents.ITEM_CHANGED: {
        const { activeL8yRef } = data;
        setActiveQuestion(activeL8yRef);
        const updatedLOs = learningObjectives.filter((lo: { assessmentQuestions: Array<{ question: { l8yId: string }}> }) => lo.assessmentQuestions.map(({ question }) => question.l8yId).includes(activeL8yRef));
        setActiveLO(updatedLOs);
        break;
      }
      case L8yContainerEvents.QUESTIONS_LOADED: {
        const { questionIds } = data;
        console.debug(L8yContainerEvents.QUESTIONS_LOADED, questionIds);
        break;
      }
    }
  };

  const handleValidated = async (data: L8yContainerValidated) => {
    const { assessmentQuestionId, attemptData, score, isCorrect, clarity, rawMaxScore } = data;
    const { studentAssessmentId } = questionDataForL8y.find((q) => q.assessmentQuestionId === assessmentQuestionId) as QuestionDatumForSPATL8y;
    const studentAttemptResponse = await apiNext.createStudentAttempt({
      assessmentQuestionId,
      attemptData,
      clarity,
      isCorrect,
      location,
      rawMaxScore,
      rawPointsEarned: score,
      studentAssessmentId,
    });
    return handleStudentAttemptResponse({
      assessmentQuestionId,
      location,
      studentAssessmentId,
      studentAttemptResponse,
      triggerConfirmationPrompt,
    });
  };

  const questionDataForL8y = enrichQuestionDataForSPATL8y(l8yArray, allEnrichedQuestions);

  const handleLOChange = (targetLONumber: string, currentActiveLOArray: Array<string>, l8yId: string) => {
    const targetLONumberArray = targetLONumber.split(' | ');
    if (targetLONumberArray !== currentActiveLOArray) {
      const activeLOData = learningObjectives.filter((lo: { assessmentQuestions: Array<{ question: { l8yId: string }}> }) => lo.assessmentQuestions.map(({ question }) => question.l8yId).includes(l8yId));
      setActiveLO(activeLOData);
    }
  };

  const handleQuestionNavItemChange = (l8yId: string, loNumber: string, handleItemNav: (l8yId: string) => void) => {
    const activeLOArray: Array<string> = activeLO.map(lo => lo.loNumber);
    handleLOChange(loNumber, activeLOArray, l8yId);
    setActiveQuestion(l8yId);
    handleItemNav(l8yId);
  };

  const todoQuestionL8yIds = todoQuestions.map(({ question: { l8yId } }) => l8yId);

  return (
    <div className="study-path-assessment-taker__wrap">
      <div className="study-path-assessment-taker__content">
        <div className="assessment-wrap study-path-assessment-taker__assessment-wrap">
          <div className="study-path-assessment-taker__container">
            <div className="assessment-taker__header">
              <div className="assessment-taker__header__topic-title row">
                <h1>{topicName}</h1>
              </div>
              {
                activeLO.map(({ loNumber, title }) => (
                  <div key={loNumber} className="assessment-taker__header__lo-item">
                    <span className="assessment-taker__header__lo-number">
                      {loNumber.replace('LO', 'Learning Objective')}
                    </span>
                    : {title}
                  </div>
                ))
              }
            </div>
            <LearnosityContainer
              activityId="study-path-AT"
              assessmentMode={AssessmentModeEnum.SubmitPractice}
              assessmentType={null}
              attemptLimit={null}
              handleEvents={handleL8yEvents}
              handleFinished={() => handleDone(false)}
              handleValidated={handleValidated}
              initAttemptsHash={{}}
              initClarityHash={initClarityHash}
              initCorrectHash={initCorrectHash}
              initPointsHash={initPointsHash}
              initRecapHash={initRecapHash}
              initState={L8ySessionState.review}
              inReviewMode={false}
              items={l8yArray}
              l8ySessionId={l8ySessionId}
              l8yBoxClassName="col-xs-12 col-sm-9"
              location={location || AssessmentLocation.SP}
              name="spL8yName"
              questionData={questionDataForL8y}
              renderingType={RenderingTypeEnum.Assess}
              renderItemNav={(activeL8yRef: string, handleItemNav: (l8yId: string) => void, questionStatusHash: QuestionStatusHash) => (
                <StudyPathAssessmentTakerNavMenu
                  questionStatusHash={questionStatusHash}
                  handleQuestionNav={(l8yId: string, loNumber: string) => handleQuestionNavItemChange(l8yId, loNumber, handleItemNav)}
                  activeQuestion={activeL8yRef}
                  todoQuestions={todoQuestions}
                  pastQuestions={pastQuestions}
                />
              )}
              targetL8yId={activeQuestion}
              todoQuestionL8yIds={todoQuestionL8yIds}
              userId={userId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

StudyPathAssessmentTaker.propTypes = {
  handleDone: PropTypes.func.isRequired,
  checkpointType: PropTypes.oneOf(Object.values(CheckpointColumn) as Array<CheckpointColumn>).isRequired,
  l8ySessionId: PropTypes.string.isRequired,
  learningObjectives: PropTypes.array.isRequired,
  targetL8yId: PropTypes.string.isRequired,
  topicName: PropTypes.string.isRequired,
};

export default StudyPathAssessmentTaker;
