import apiNext from 'api-next';
import { ActiveCombinedQuestion } from 'store/selectors/retrieveActiveCombinedQuestions';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';

export const sortAssessmentQuestions = (questions: Array<AssessmentQuestionApi>) => [...questions].sort(({ order: orderA }, { order: orderB }) => {
  if (!orderA && !orderB) {
    return 0;
  }
  if (!orderA) {
    return 1;
  }
  if (!orderB) {
    return -1;
  }
  return orderA - orderB;
});


export const getAssessmentQuestionIdsTaggedWithOnlySpecificCourseLos = (
  loIdsToCheck: Array<number>,
  assessmentQuestionsData: Array<{ id: number; questionId: number}>,
  questionsData: Array<ActiveCombinedQuestion>
) => {
  const taggedAssessmentQuestionIds = assessmentQuestionsData.reduce((acc, aq) => {
    const question = questionsData.find(q => q.id === aq.questionId);
    if (question) {
      const questionLoIds = question.courseLearningObjectives.map(clo => clo.id);
      const hasOnlyTheseLoIds = !!questionLoIds.length && questionLoIds.every((qloId) => loIdsToCheck.includes(qloId));
      if (hasOnlyTheseLoIds) {
        acc.push(aq.id);
      }
    }
    return acc;
  }, [] as Array<number>);

  return taggedAssessmentQuestionIds;
};

export const loadStartedAssessmentQuestionIds = async (assessmentQuestionIds: Array<number>, courseId: string) => {
  // if no aqIds or courseId, return empty array and don't call API
  if (!assessmentQuestionIds.length || !courseId) {
    return [];
  }
  const studentAttemptsForAqs = await apiNext.getSingleStudentAttemptsForAssessmentQuestions(assessmentQuestionIds, courseId);
  const startedAssessmentQuestionIds = assessmentQuestionIds.filter((aqId) => !!studentAttemptsForAqs.find(({ assessmentQuestionId }) => assessmentQuestionId === aqId));
  return startedAssessmentQuestionIds;
};
