import React, { useState } from 'react';
import { DateTime } from 'luxon';

import { useAppSelector } from 'store';
import { determineDefaultMinOpenMaxDueDatesForCourse } from 'utils/assessmentFunctions';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import DateTimePicker from 'shared-components/DateTimePicker/DateTimePicker';
import TextButton from 'shared-components/BetterButton/TextButton';
import { CoursePlannerAction, HandleCoursePlannerAction } from '../BetterCoursePlannerController';

/**
 * This is a sketch for a kind of "inline modal" that I'd like to play with, instead of taking over the whole screen
 * show an overlay in place so the user can still view the surrounding weeks for context without having to close and reopen the modal
 */
const CoursePlannerClassRowOverlay = ({
  classDate,
  classNumber,
  classSessionId,
  onClose,
  handleCoursePlannerAction,
}: {
  classDate: string
  classNumber: number
  classSessionId: number
  onClose: () => void
  handleCoursePlannerAction: HandleCoursePlannerAction
}) => {
  const course = useAppSelector((store) => store.active.course);
  const { defaultMinOpenDate, defaultMaxDueDate } = determineDefaultMinOpenMaxDueDatesForCourse(course);
  const initialDate = DateTime.fromISO(classDate).endOf('day').toJSDate();
  const [dueDate, setDueDate] = useState(initialDate);
  const [title, setTitle] = useState('');
  return (
    <div className="bcp__class-row__overlay">
      <div className="overlay__add-study-path">
        <div className="overlay__add-study-path__header">
          Add Study Path to Class {classNumber}
        </div>
        <div className="overlay__add-study-path__content">
          <div className="overlay__add-study-path__body">
            <div className="overlay__add-study-path__body__input">
              <label htmlFor="study-path-title">Title</label>
              <input
                type="text"
                id="study-path-title"
                value={title}
                onChange={({ target }) => setTitle(target.value)}
              />
            </div>
            <div className="overlay__add-study-path__body__input">
              <label htmlFor="study-path-date-picker">Due</label>
              <DateTimePicker
                className="study-path-date-picker"
                minDate={defaultMinOpenDate}
                maxDate={defaultMaxDueDate}
                name="study-path-date-picker"
                onChange={setDueDate}
                value={dueDate}
              />
            </div>
          </div>
          <div className="overlay__add-study-path__actions">
            <TextButton onClick={() => onClose()}>Cancel</TextButton>
            <BetterButton className="new-button" text="Add Items" secondary onClick={() => {}} />
            <BetterButton
              className="new-button"
              text="Create Placeholder"
              primary
              onClick={() => handleCoursePlannerAction(CoursePlannerAction.CreateStudyPathPlaceholder, { classSessionId })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePlannerClassRowOverlay;
