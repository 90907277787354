import React from 'react';
import { FaChevronUp, FaTimes } from 'react-icons/fa';
import { LibraryTypeEnum } from 'types/backend/shared.types';

export type ClassRowCardType = LibraryTypeEnum.Template | LibraryTypeEnum.User | 'study-path';

function ClassRowCardContainer({
  cardLabel,
  cardType,
  children,
  className = '',
  disableExpando = false,
  handleClickTitle,
  isEmpty = false,
  isExpanded = true,
  setIsExpanded,
  renderActions,
  replaceHeader,
  handleClose,
  ...props
}: {
  cardLabel: string | JSX.Element
  cardType: ClassRowCardType
  className?: string
  disableExpando?: boolean
  handleClickTitle?: () => void
  isExpanded?: boolean
  setIsExpanded?: React.Dispatch<React.SetStateAction<boolean>> | false
  isEmpty?: boolean
  renderActions?: () => React.ReactNode | Array<React.ReactNode>
  replaceHeader?: (() => React.ReactNode) | false
  children: React.ReactNode | Array<React.ReactNode>
  handleClose?: () => void
}) {
  const canExpand = typeof setIsExpanded === 'function';
  const titleIsClickable = typeof handleClickTitle === 'function';
  return (
    <div
      className={`bcp__class-row__card ${className}`}
      data-isempty={isEmpty}
      data-expanded={isExpanded || !canExpand}
      data-row-card-type={cardType}
      {...props}
    >
      {!!replaceHeader ? replaceHeader() : (
        <div className="bcp__class-row__card__header">
          <button
            onClick={() => titleIsClickable && handleClickTitle()}
            className="bcp__class-row__card__header-title"
            data-clickable={titleIsClickable}
          >
            {cardLabel}
          </button>
          <div className="bcp__class-row__card__header-action">
            {!!renderActions && renderActions()}
            {!!handleClose && (
              <button
                className="bcp__cancel-x"
                onClick={() => handleClose()}
              >
                <FaTimes />
              </button>
            )}
            {!isEmpty && canExpand && (
              <button
                disabled={disableExpando}
                onClick={() => setIsExpanded(!isExpanded)}
                className="bcp__expando-caret"
                data-expanded={isExpanded}
                title={isExpanded ? 'Collapse' : 'Expand'}
              >
                <FaChevronUp />
              </button>
            )}
          </div>
        </div>
      )}
      <div className="bcp__class-row__card__body">
        {(isExpanded || !canExpand) && children}
      </div>
    </div>
  );
}

export default ClassRowCardContainer;
