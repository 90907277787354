import React from 'react';
import PropTypes from 'prop-types';
import getReadableTextColor from 'utils/getReadableTextColor';
import styles from 'style-config.scss';
import './ProgressBar.scss';

const ProgressBarProps = {
  percentage: PropTypes.number.isRequired,
  height: PropTypes.number,
  color: PropTypes.string.isRequired,
  displayPercentage: PropTypes.bool,
};

const ProgressBar = (props: PropTypes.InferProps<typeof ProgressBarProps>) => {
  const { percentage, color, height = 20, displayPercentage = false } = props;
  let safePercentage = percentage;
  if (percentage < 0) {
    safePercentage = 0;
  } else if (percentage > 100) {
    safePercentage = 100;
    console.warn('Percentage out of range', percentage);
  }
  const indicatorStyle = {
    width: `${Math.round(safePercentage)}%`,
    backgroundColor: color,
  };
  // Handle when color is dark and handle showing percentage in default $black and offset if indicator is too small to provide contrast
  const smallPercentage = safePercentage < 55;
  const readableColor = smallPercentage ? styles.black : getReadableTextColor(color);
  return (
    <div className="progress-bar" style={{ height: `${height}px` }}>
      <div className="progress-bar__indicator" style={indicatorStyle}>
      </div>
      <div className="progress-bar__percentage" style={{ color: readableColor }}>
        {displayPercentage && `${Math.round(safePercentage)}%`}
      </div>
    </div>
  );
};

ProgressBar.propTypes = ProgressBarProps;

export default ProgressBar;
