import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { FaChevronLeft, FaChevronRight, FaSyncAlt } from 'react-icons/fa';

import sharedStrings from 'sharedStrings';
import CodonUrls from 'urls';
import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import { formatPoints } from 'utils/commonFormattingFunctions';
import { DateFormatEnum } from 'utils/dateFormattingFunctions';
import useLocalStorage from 'hooks/useLocalStorage';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import CompletionBar from './CompletionBar';
import {
  EnrichedAssessmentForStudentScores,
  StudentScoresRow,
  StudentScoresRowScores,
  StudentScoresViewEnum,
} from '../../studentScores.types';
import { AssessmentSummaryData } from 'types/backend/assessmentSummaryData.types';
import { YesNo } from 'types/backend/shared.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { StudentAssessmentStatus } from 'types/backend/studentScoresData.types';
import { DirectionEnum, EventKeyEnum, EventKeyTypeEnum } from 'types/common.types';
import { Store } from 'types/store.types';

const StudentScoresTableWrap = styled.table<{ resultsPerPage: number }>`
  td, th {
    max-width: calc(90% / ${p => p.resultsPerPage});
    min-width: calc(70% / ${p => p.resultsPerPage});
    width: calc(70% / ${p => p.resultsPerPage});
  }
`;

const StudentScoresTable = ({
  assessments,
  handleStudentScoresView,
  loadAndSaveStudentScoresData,
  studentScores,
  assessmentsSummaryData,
  loadAssessmentSummaryData,
}: {
  assessments: Array<EnrichedAssessmentForStudentScores>
  handleStudentScoresView: (view: StudentScoresViewEnum, assessmentId?: string) => void
  loadAndSaveStudentScoresData: () => void
  studentScores: Array<StudentScoresRow>
  assessmentsSummaryData: Array<AssessmentSummaryData>
  loadAssessmentSummaryData: (assessmentIdsToLoad: Array<string>) => Promise<void>
}) => {
  const course = useSelector((store: Store) => store.active.course);
  const filteredAssessments = assessments.filter(({ assessType }) => assessType !== AssessTypeEnum.Summative);

  const arrayLength = filteredAssessments.length;
  const defaultPerPage = Math.min(7, arrayLength) || 7; // if there are between 1 and 6 assessments in the course default to that number, otherwise default to 7.

  const [scoresViewSettings, setScoresViewSettings] = useLocalStorage(`STUDENT_SCORES_VIEW_SETTINGS__${course.id}`, { min: 0, defaultPerPage });
  const [min, setMin] = useState(scoresViewSettings.min);
  const [max, setMax] = useState(scoresViewSettings.min + scoresViewSettings.defaultPerPage);
  const [resultsPerPage, setResultsPerPage] = useState(scoresViewSettings.defaultPerPage);

  const handleNavigate = useCallback((direction: DirectionEnum) => {
    switch (direction) {
      case DirectionEnum.Prev: {
        const newMin = min - resultsPerPage;
        const newMax = max - resultsPerPage;
        if (newMin <= 0) {
          setMin(0);
          setMax(resultsPerPage);
        } else {
          setMin(newMin);
          setMax(newMax);
        }
        break;
      }
      case DirectionEnum.Next: {
        const newMin = min + resultsPerPage;
        const newMax = max + resultsPerPage;
        if (newMax >= arrayLength) {
          setMax(arrayLength);
          setMin(arrayLength - resultsPerPage);
        } else {
          setMax(newMax);
          setMin(newMin);
        }
        break;
      }
      default:
        break;
    }
  }, [arrayLength, max, min, resultsPerPage]);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    const updatedValue = parseInt(value);
    if (updatedValue >= 1 && updatedValue <= arrayLength) {
      // at the end of the results
      if (max === arrayLength) {
        setMin(arrayLength - updatedValue);
      } else {
        setMax(min + updatedValue);
      }
      setResultsPerPage(updatedValue);
    }
  };

  // this useEffect calls loadAssessmentSummaryData for displayed assessments, which then loads that data if it hasn't already been loaded
  const isLoadingSummaryData = useRef<boolean>(false);
  useEffect(() => {
    const loadData = async (ids: Array<string>) => {
      isLoadingSummaryData.current = true;
      await loadAssessmentSummaryData(ids);
      isLoadingSummaryData.current = false;
    };
    const idsToLoad = filteredAssessments.slice(min, max).map(a => a.id);
    const idsNotYetLoaded = idsToLoad.filter(id => !assessmentsSummaryData.find(asd => asd.assessmentId === id));
    if (!!idsNotYetLoaded.length && !isLoadingSummaryData.current) {
      loadData(idsToLoad);
    }
  }, [assessmentsSummaryData, max, min, resultsPerPage, filteredAssessments, loadAssessmentSummaryData]);

  useEffect(() => {
    function onKeyup(e: KeyboardEvent) {
      if (e.key === EventKeyEnum.ArrowLeft && min > 0) {
        handleNavigate(DirectionEnum.Prev);
      } else if (e.key === EventKeyEnum.ArrowRight && max < arrayLength) {
        handleNavigate(DirectionEnum.Next);
      }
    }
    window.addEventListener(EventKeyTypeEnum.KeyUp, onKeyup);
    return () => window.removeEventListener(EventKeyTypeEnum.KeyUp, onKeyup);
  }, [arrayLength, handleNavigate, min, max]);

  // this useEffect persists the values for the first assessment and the number of assessments per page to local storage when they change
  useEffect(() => {
    return () => setScoresViewSettings({ min, defaultPerPage: resultsPerPage });
  }, [min, resultsPerPage, setScoresViewSettings]);

  if (!arrayLength) {
    return (
      <table>
        <thead>
          <tr><th>There are no assessments in the course</th></tr>
        </thead>
      </table>
    );
  }

  const assessmentsView = filteredAssessments.slice(min, max);
  return (
    <>
      <div className="score-book-container__header-bar">
        <div className="score-book-container__button-bar">
          <BetterButton
            onClick={loadAndSaveStudentScoresData}
            text="Download Scores .csv File"
            className="student-scores__download-button"
            primary
          />
          {course.isGradeSyncEnabled && (
            <BetterButton
              onClick={() => handleStudentScoresView(StudentScoresViewEnum.GRADE_SYNC_VIEW)}
              text="Go to Score Sync Page"
              className="student-scores__download-button"
              icon={() => <FaSyncAlt />}
              primary
            />
          )}
        </div>
        <div className="score-book-container__header-bar__info">
          <BetterTooltip
            content={
              <div>
                Click on a column header to see students' scores and activity on each question in that assessment.<br/><br/>
                "<b>Late</b>" = One or more questions were only attempted for the first time after the due date.<br/>
                <b>Red shading</b> = No questions were ever attempted in the entire assessment.<br/><br/>
                {externalLink(CodonUrls.LatePolicyKB, 'Learn more.')}
              </div>
            }
            indicate
          >
            {sharedStrings.HOW_TO_INTERPRET}
          </BetterTooltip>
        </div>
        <div className="student-scores-table__navigation">
          <label>
            Assessments per page:
            <input
              onChange={handleInput}
              type="number"
              value={resultsPerPage}
            />
          </label>
          <div className="student-scores-table__navigation-buttons">
            <BetterButton
              disabled={min <= 0}
              icon={() => <FaChevronLeft />}
              onClick={() => handleNavigate(DirectionEnum.Prev)}
              secondary
              showText={false}
              text="Previous"
            />
            <BetterButton
              disabled={max >= arrayLength}
              icon={() => <FaChevronRight />}
              onClick={() => handleNavigate(DirectionEnum.Next)}
              secondary
              showText={false}
              text="Next"
            />
          </div>
        </div>
      </div>
      <div className="student-scores-table__wrap">
        <StudentScoresTableWrap resultsPerPage={resultsPerPage}>
          <thead>
            <tr>
              <th className="score-book-container__header-student-name">
                Student Name
              </th>
              {assessmentsView.map((assessment) => (
                <th className="score-book-container__assessment-name" key={assessment.id}>
                  <button
                    className="score-book-container__header"
                    onClick={() => handleStudentScoresView(StudentScoresViewEnum.ASSESSMENT_DETAIL_VIEW, assessment.id)}
                    onMouseEnter={() => console.debug('assessment', assessment)}
                  >
                    <div className="assessmentName">
                      {assessment.name}
                      <div className="score-book-container__assessment-dueDate">
                        Due {DateTime.fromISO(assessment.dueDate).toFormat(DateFormatEnum.DateShortYear)}
                      </div>
                    </div>
                    <div className="points-and-completion">
                      <div className="assessmentPoints">
                        {formatPoints(assessment.totalPoints)} pts
                      </div>
                      <CompletionBar summaryData={assessmentsSummaryData.find(data => data.assessmentId === assessment.id)} />
                    </div>
                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {studentScores.map(({ studentEnrollmentId, studentName, scores }) => {
              const scoresForView = assessmentsView.map(({ id }) => scores.find(({ assessmentId }) => assessmentId === id) as StudentScoresRowScores);
              return (
                <tr key={`student-score-row_${studentEnrollmentId}`}>
                  <td className="student-score-cell__student-name">{studentName}</td>
                  {scoresForView.map(({ assessmentId, pointsEarned, anyAttempts, assessmentStatus }) => {
                    const { published } = assessments.find(({ id }) => id === assessmentId) || {};
                    const pointsDisplay = published === YesNo.Yes && typeof pointsEarned === 'number' ? formatPoints(pointsEarned) : '-';
                    const showLateSuffix = [
                      StudentAssessmentStatus.InProgressBeforeLate,
                      StudentAssessmentStatus.InProgressAfterLate,
                      StudentAssessmentStatus.CompletedBeforeLate,
                      StudentAssessmentStatus.CompletedAfterLate,
                    ].includes(assessmentStatus);
                    const isAfterDue = [
                      StudentAssessmentStatus.NotStartedBeforeLate,
                      StudentAssessmentStatus.NotStartedAfterLate,
                    ].includes(assessmentStatus) || showLateSuffix;
                    return (
                      <td
                        className={`student-score-cell__points-earned ${isAfterDue && !anyAttempts ? 'unattempted-and-late' : ''}`}
                        key={`student-score-cell_${studentEnrollmentId}-${assessmentId}`}
                        title={isAfterDue ? 'Assessment Past Due' : ''}
                      >
                        {pointsDisplay}{showLateSuffix && sharedStrings.LATE_SUFFIX}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </StudentScoresTableWrap>
      </div>
    </>
  );
};

StudentScoresTable.propTypes = {
  assessments: PropTypes.array.isRequired,
  handleStudentScoresView: PropTypes.func.isRequired,
  loadAndSaveStudentScoresData: PropTypes.func.isRequired,
  studentScores: PropTypes.array.isRequired,
  assessmentsSummaryData: PropTypes.arrayOf(PropTypes.object),
  loadAssessmentSummaryData: PropTypes.func.isRequired,
};

export default StudentScoresTable;
