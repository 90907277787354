import cloneDeep from 'lodash-es/cloneDeep';

import apiNext from 'api-next';
import { ClassSessionTopicApi } from 'types/backend/classSessionTopics.types';
import { AppDispatch, Store } from 'types/store.types';
import activeSlice from 'store/slices/active';

const reorderTopics = (classSessionTopics: Array<ClassSessionTopicApi>, topicIdToMove: number, previousId: number) => {
  const topicToMoveIndex = classSessionTopics.findIndex(cst => cst.id === topicIdToMove);
  const previousTopicIndex = classSessionTopics.findIndex(({ id }) => id === previousId);
  const reorderedTopics: Array<ClassSessionTopicApi> = [...classSessionTopics];
  const [movingTopic] = reorderedTopics.splice(topicToMoveIndex, 1);
  reorderedTopics.splice(previousTopicIndex + 1, 0, movingTopic);
  return reorderedTopics;
};

export default function reorderClassSessionTopic(movingClassSessionTopicId: number, previousId: number) {
  return (dispatch: AppDispatch, getStore: () => Store) => (async () => {
    const store = getStore();
    const classSessionTopics: Array<ClassSessionTopicApi> = cloneDeep(store.active.classSessionTopics);
    const movingCst: ClassSessionTopicApi | undefined = classSessionTopics.find(cst => cst.id === movingClassSessionTopicId);

    if (movingCst === undefined) {
      return; //bail if the classSessionTopic doesn't exist, but that shouldn't happen . . .
    }

    // Handle reordering topics locally for instant ui:
    const reordered = reorderTopics(classSessionTopics, movingClassSessionTopicId, previousId);
    dispatch(activeSlice.actions.setActiveClassSessionTopics(reordered));

    // update the CST on the server
    // omit order for API call
    const { order, ...classSessionTopic } = movingCst;
    await apiNext.editClassSessionTopic(movingCst.id, {
      ...classSessionTopic,
      previousId,
    });

    // update the store with the server data
    const editingClassSessionTopics: Array<ClassSessionTopicApi> = await apiNext.getClassSessionTopicsByClassSessionId(movingCst.classSessionId);
    const combinedCsts = reordered.map((cst) => {
      const editedCst = editingClassSessionTopics.find(({ id }) => id === cst.id);
      if (editedCst) {
        return editedCst;
      }
      return cst;
    });
    dispatch(activeSlice.actions.setActiveClassSessionTopics(combinedCsts));

  })();
}
