/**
 * retrieveEnrichedClassSessions
This selector returns classSessions that have and additional array that has the learning objectives, and the LOs are themselved in enhanced with topic information
It is a legacy data structure that evolved from strapi, which added lots of extra parent attributes
But is also valuable because classSession is one of the very common data structures for building screens
Note that because it is a 'legacy' data structure it contains some denormalized attributes that are not preceded by `_derived`

We may want to extend this to include denormalized views of units, topics and assessments

The returned array is of type EnrichedClassSession, with an array of LOs for each LO that is mapped to that session, with the topic object as a child of the LO
[
  {classSession {...attributes, learningObjectives:[{lo {...attributes, {topic}}]}}
]
*/

import { createSelector } from '@reduxjs/toolkit';
import { ClassSessionApi } from 'types/backend/classSessions.types';
import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { TopicApi } from 'types/backend/topics.types';
import { Store } from 'types/store.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';

export interface EnrichedClassSessionLearningObjective extends ClassSessionLearningObjectiveApi, Pick<LearningObjectiveApi, 'stringId' | 'title' | 'topicId' | 'type' | 'userId'> {
  courseTopicId: number
  csloId: number
  libraryTopicId: number
  topic: TopicApi
}
export interface EnrichedClassSession extends ClassSessionApi {
  learningObjectives: Array<EnrichedClassSessionLearningObjective>
  date: string
}

export const enrichLos = (
  classSessionLearningObjectives: Array<ClassSessionLearningObjectiveApi> = [],
  learningObjectives: Array<LearningObjectiveApi>,
  topics: Array<TopicApi>
): Array<EnrichedClassSessionLearningObjective> => {
  return classSessionLearningObjectives.reduce((acc: Array<EnrichedClassSessionLearningObjective>, currentCslo) => {
    const learningObjective = learningObjectives.find(lo => lo.id === currentCslo.learningObjectiveId) as LearningObjectiveApi;
    if (learningObjective) {
      const topic = topics.find(t => t.id === currentCslo.topicId) as TopicApi;
      const enrichedCslo: EnrichedClassSessionLearningObjective = {
        ...currentCslo,
        ...learningObjective,
        csloId: currentCslo.id,
        libraryTopicId: learningObjective.topicId, // HERE adding libraryTopicId should replace topicId over time as needed
        topicId: currentCslo.topicId, // HERE use the topicId from classSessionLearningObjectives. This should eventually be removed. See https://codonlearning.atlassian.net/browse/CA-2492
        courseTopicId: currentCslo.topicId, // changed from: learningObjective.topicId on 11/9/2022 original comment: // HERE new courseTopicId that should replace topicId over time as needed
        topic,
      };
      return [
        ...acc,
        enrichedCslo,
      ];
    }
    return acc;
  }, []);
};

export const enrichClassSessions = (
  classSessions: Array<ClassSessionApi>,
  classSessionLearningObjectives: Array<ClassSessionLearningObjectiveApi>,
  learningObjectives: Array<LearningObjectiveApi>,
  topics: Array<TopicApi>
) => {
  // should be Array<EnrichedClassSession> but there are type complications
  return classSessions.reduce((acc, cur) => {
    const classSessionLoMap = classSessionLearningObjectives.filter((cslo) => {
      return cslo.classSessionId === cur.id;
    });
    const enrichedLosForSession: Array<EnrichedClassSessionLearningObjective> = enrichLos(classSessionLoMap, learningObjectives, topics);
    return [
      ...acc,
      {
        ...cur,
        learningObjectives: enrichedLosForSession,
        date: cur.classDate,
      },
    ];
  }, [] as Array<EnrichedClassSession>);
};

export default createSelector(
  (store: Store) => store.active.classSessions,
  (store: Store) => store.active.classSessionLearningObjectives,
  (store: Store) => store.active.learningObjectives,
  (store: Store) => store.active.topics,
  (classSessions, classSessionLearningObjectives = [], learningObjectives, topics) => {
    const enrichedClassSessions = enrichClassSessions(
      classSessions,
      classSessionLearningObjectives,
      learningObjectives,
      topics
    );

    return enrichedClassSessions;
  }
);

