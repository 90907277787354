import React from 'react';

const PuddlesOhNo = () => (
  <svg
    viewBox="0 0 1024 1010"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    role="img"
    aria-labelledby="puddles-title"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 1.5,
    }}
  >
    <title id="puddles-title">
      Puddles the Pig looking confused because there has been an error
    </title>
    <path
      d="M512 927.713c-98.122 2.496-406.887-69.133-390.896-297.809 48.143-155.959 81.994-251.696 85.494-261.44C148.265 335.282 50.969 254.597 49.396 126.16c73.105-28.815 225.433-69.301 331.015 100.951 91.177-31.684 199.722-33.115 263.354-10.017 54.073-65.656 136.938-165.284 318.213-90.674-5.123 93.776-39.153 200.779-136.054 246.239 33.982 93.817 79.78 199.76 69.257 300.694C884.658 774.288 804.864 920.262 512 927.713Z"
      style={{
        fill: '#fce4e4',
      }}
    />
    <path
      d="M16.071 24.95c3.408 0 5.353-2.263 5.262-4.115-.118-2.386-1.745-3.97-4.054-4.648a4.54 4.54 0 0 0-2.557 0c-.677.199-1.656.588-2.476 1.314-.967.857-1.513 2.009-1.578 3.334-.147 2.975 2.548 4.115 5.403 4.115Zm-4.057-4.005c.26-2.559 2.374-3.192 2.988-3.43.485-.189 1.335-.155 1.826-.011 1.187.349 2.708.938 2.943 3.499.099 1.088-.78 1.741-1.907 2.275a4.401 4.401 0 0 1-3.727 0c-1.128-.534-2.233-1.246-2.123-2.333Z"
      style={{
        fillRule: 'nonzero',
      }}
      transform="translate(16.169 -1.857) scale(30.8528)"
    />
    <path
      d="M14.404 21.756a.937.937 0 1 0 0-1.874.937.937 0 0 0 0 1.874ZM17.596 21.756a.938.938 0 1 0 0-1.876.938.938 0 0 0 0 1.876Z"
      style={{
        fillRule: 'nonzero',
      }}
      transform="translate(16.169 -1.857) scale(30.8528)"
    />
    <path
      d="M9.654 15.285a.936.936 0 1 0 0-1.875.938.938 0 0 0 0 1.875Z"
      style={{
        fillRule: 'nonzero',
      }}
      transform="matrix(41.1753 0 0 36.0378 114.502 310.763)"
    />
    <path
      d="M5.03 10.857c-.819 1.558-1.461 3.328-1.913 5.276-.881 3.789-.434 6.852 1.329 9.106 2.097 2.68 5.98 4.081 11.54 4.163h.028c5.561-.082 9.443-1.483 11.54-4.163 1.763-2.254 2.21-5.317 1.329-9.106-.453-1.95-1.095-3.719-1.913-5.276 4.336-1.999 4.998-8.041 5.025-8.317a.937.937 0 0 0-.537-.944c-.162-.076-4.024-1.84-7.514-.493-1.565.604-2.794 1.722-3.663 3.33a11.622 11.622 0 0 0-2.182-.628 11.592 11.592 0 0 0-6.38.628c-.869-1.608-2.098-2.726-3.662-3.33C4.566-.244.704 1.52.542 1.596a.937.937 0 0 0-.537.944c.028.276.689 6.318 5.025 8.317Zm19.58-8.001c2.008-.78 4.311-.187 5.41.19-.289 1.508-1.247 5.035-4.017 6.186-1.075-1.594-2.377-2.968-4.039-3.967.65-1.171 1.538-1.979 2.646-2.409ZM14.239 5.65c3.94-.72 7.946.887 10.568 5.19.986 1.613 1.743 3.536 2.25 5.717 1.835 7.896-2.98 10.847-11.057 10.97-4.958-.075-8.348-1.234-10.077-3.444-1.396-1.784-1.726-4.316-.98-7.526 1.194-5.148 4.091-9.95 9.296-10.907ZM7.381 2.852c1.112.429 2.003 1.239 2.655 2.413-1.658.995-2.977 2.391-4.039 3.967-2.759-1.15-3.724-4.682-4.015-6.189 1.093-.378 3.382-.969 5.399-.191Z"
      style={{
        fillRule: 'nonzero',
      }}
      transform="translate(18.353 49.21) scale(30.8528)"
    />
    <path
      d="M9.654 15.285a.936.936 0 1 0 0-1.875.938.938 0 0 0 0 1.875ZM22.346 15.285a.937.937 0 1 0 .002-1.874.937.937 0 0 0-.002 1.874Z"
      style={{
        fillRule: 'nonzero',
      }}
      transform="translate(18.353 49.21) scale(30.8528)"
    />
    <path
      d="m612.772 176.959 5.867-3.399"
      style={{
        fill: 'none',
        stroke: '#000',
        strokeWidth: 2,
      }}
      transform="translate(-8184.82 -1995.99) scale(13.7876)"
    />
    <path
      d="m612.772 176.959 5.867-3.399"
      style={{
        fill: 'none',
        stroke: '#000',
        strokeWidth: 2,
      }}
      transform="matrix(-13.7876 0 0 13.7876 9208.39 -1995.99)"
    />
  </svg>
);

export default PuddlesOhNo;
