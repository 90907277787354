import ReactGA from 'react-ga4';
const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

export const initializeGoogleAnalytics = () => {
  if (googleAnalyticsId) {
    ReactGA.initialize(googleAnalyticsId);
    console.debug('GA INITIALIZED');
  }
};

export const sendGoogleAnalyticsEvent = ({ category, action, label }: {
  category: string
  action: string
  label: string
}) => {
  if (googleAnalyticsId) {
    console.debug('sendGoogleAnalyticsEvent', { category, action, label });
    ReactGA.event({ category, action, label });
  }
};
