/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
// JUSTIFICATION: this component will be DEPRECATED with new CoursePlanner
// TopicGrid > TopicSelect
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaCaretDown } from 'react-icons/fa';

import sharedStrings from 'sharedStrings';
import TopicPopup from '../TopicPopup/TopicPopup';
import { UnitApi } from 'types/backend/units.types';
import { SelectItemTypeEnum, ItemOption } from '../../coursePlanner.types';
import './TopicSelect.scss';

interface TopicSelectProps {
  classPeriod: number
  topicOptions: Array<ItemOption>
  classSessionId: number
  selectType: string
  selectedTopics: any
  parentUnits: Array<UnitApi>
  courseLibrary: Array<any>
  onAddItemToClassSession: any
  onRemoveItemFromClassSession: any
  onAddCustomItem: any
  onShowAlertMessage: (msg: string) => void
}

function TopicSelect({
  classPeriod,
  topicOptions,
  classSessionId,
  selectType,
  selectedTopics,
  parentUnits,
  courseLibrary,
  onAddItemToClassSession,
  onRemoveItemFromClassSession,
  onAddCustomItem,
  onShowAlertMessage,
}: TopicSelectProps) {
  const [isTopicSelectActive, setIsTopicSelectActive] = useState(false);

  function onTopicClick() {
    if (selectType === SelectItemTypeEnum.Topic && parentUnits.length === 0) {
      onShowAlertMessage(sharedStrings.UNIT_REQUIRED_MESSAGE);
    } else {
      setIsTopicSelectActive(true);
    }
  }

  function toggleOff() {
    setIsTopicSelectActive(false);
  }

  const SelectedTopicsList = () => {
    const items = [...selectedTopics];
    if (!items || items.length === 0) {
      return <div></div>;
    }
    return (
      <>
        {items.map((item) => (
          <span
            key={`activeItem_${item.label}`}
            className="topic-select__active-item"
          >
            {item.label}
          </span>
        ))}
      </>
    );
  };

  return (
    <td>
      <div
        className="topic-select__items-container"
        id={`${selectType}-class-${classPeriod}`}
        onClick={() => onTopicClick()}
      >
        <div className="topic-select__items" key={classSessionId}>
          <div>
            <SelectedTopicsList key={`ItemList-${selectType}-${classSessionId}`} />
          </div>
        </div>
        <div>
          <FaCaretDown />
        </div>
      </div>
      {!!isTopicSelectActive && (
        <TopicPopup
          toggleOff={toggleOff}
          classSessionId={classSessionId}
          topicOptions={topicOptions}
          selectedTopics={selectedTopics}
          units={parentUnits}
          courseLibrary={courseLibrary}
          onAddItemToClassSession={onAddItemToClassSession}
          onRemoveItemFromClassSession={onRemoveItemFromClassSession}
          onAddCustomItem={onAddCustomItem}
          key={`topic-popup-${selectType}-${classSessionId}`}
        />
      )}
    </td>
  );
}

TopicSelect.propTypes = {
  classPeriod: PropTypes.number,
  topicOptions: PropTypes.array,
  classSessionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectType: PropTypes.string,
  selectedTopics: PropTypes.array,
  parentUnits: PropTypes.array,
  courseLibrary: PropTypes.object.isRequired,
  onAddItemToClassSession: PropTypes.func.isRequired,
  onRemoveItemFromClassSession: PropTypes.func.isRequired,
  onAddCustomItem: PropTypes.func.isRequired,
  onShowAlertMessage: PropTypes.func.isRequired,
};

export default TopicSelect;
