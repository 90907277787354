import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { GiPartyPopper } from 'react-icons/gi';
import { useQueryParam, StringParam } from 'use-query-params';

import { studentDashboardStrings } from 'sharedStrings';
import CodonUrls from 'urls';
import { getIsCloseToPracticeTestDue } from 'utils/assessmentFunctions';
import { externalLink } from 'shared-components/ExternalLink/ExternalLink';
import {
  derivePracticeTestData,
  filterAndEnrichTodoPastQuestions,
  getAllAssessmentQuestionsFromEnrichedSTCLOs,
  getStudentPracticeTestState,
} from '../../sharedStudyPathFunctions';
import { StudentAppContext } from 'student/StudentBase';
import StudyPathPracticeTestAssessmentTaker from 'student/controllers/Course/StudyPathController/components/StudyPathAssessmentTaker/StudyPathPracticeTestAssessmentTaker';
import { useAppDispatch, useAppSelector } from 'store';
import getStudentStudyPathData from 'store/actions/getStudentStudyPathData';
import retrieveEnrichedActiveStudyPath from 'store/selectors/retrieveEnrichedActiveStudyPath';
import retrieveSimpleEnrichedAssessmentQuestionMaps from 'store/selectors/retrieveSimpleEnrichedAssessmentQuestionMaps';
import retrieveEnrichedStudentAssessments, { EnrichedStudentSummativeAssessment } from 'store/selectors/retrieveEnrichedStudentAssessments';
import Icon, { IconEnum } from 'shared-components/Icon';
import ProgressBar from 'shared-components/ProgressBar/ProgressBar';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import BetterModal from 'shared-components/BetterModal/BetterModal';
import Spinner from 'shared-components/Spinner/Spinner';
import {
  StudyPathLaunch,
  StudyPathSummaryInfo,
  StudentPTState,
  StudentAssessmentStartedStatus,
} from 'student/controllers/Course/StudyPathController/StudyPathController.types';
import { StudentCoursePath } from 'types/student.types';
import { AssessmentLocation, CheckpointColumn } from 'types/backend/shared.types';
import { SummativeAssessmentSupplementsApi } from 'types/backend/summativeAssessmentSupplements.types';
import styles from 'style-config.scss';

const TestColumnInfoCard = ({
  percentage,
  selectedSummativeId,
  summaryInfo,
}: {
  percentage: number
  selectedSummativeId: string
  summaryInfo: StudyPathSummaryInfo
}) => {
  const dispatch = useAppDispatch();
  const [launchInto, setLaunchInto] = useQueryParam('launchInto', StringParam);
  const [showPracticeTestModal, setShowPracticeTestModal] = useState(launchInto === StudyPathLaunch.PracticeTestReview);
  const userId = useAppSelector((store) => store.user.id);
  const enrollmentId = useAppSelector((store) => store.active.enrollment.id);
  const enrichedStudentAssessments = useAppSelector(retrieveEnrichedStudentAssessments);
  const studentAssessments = useAppSelector((store) => store.active.studentAssessments);
  const simpleAqms = useAppSelector(retrieveSimpleEnrichedAssessmentQuestionMaps);

  const supplements = useAppSelector((store) => store.active.summativeAssessmentSupplements);
  const { practiceAssessmentId } = supplements.find(supp => supp.id === selectedSummativeId) as SummativeAssessmentSupplementsApi;
  const enrichedStudentPracticeAssessment = enrichedStudentAssessments.find(esa => esa.id === practiceAssessmentId) as EnrichedStudentSummativeAssessment;
  const {
    allQuestionsAnswered,
    hasBeenStarted,
    isBeforeDue,
    latestCorrectQuestionCount,
    published,
    totalQuestions,
    dueDate,
  } = enrichedStudentPracticeAssessment;
  const { checkpointZeroHasTopics, checkpointOneHasTopics, checkpointTwoHasTopics, checkpointThreeHasTopics, allAssessmentsCompleted } = summaryInfo;
  const allTopicsInCheckpoint3 = ![checkpointZeroHasTopics, checkpointOneHasTopics, checkpointTwoHasTopics].some(Boolean);
  const allVisibleTopicsInCheckpoint3 = checkpointThreeHasTopics && ![checkpointOneHasTopics, checkpointTwoHasTopics].some(Boolean);

  const isCloseToPracticeTestDue = getIsCloseToPracticeTestDue(dueDate);

  let studentAssessmentStatus = StudentAssessmentStartedStatus.NotStarted;
  if (allQuestionsAnswered) {
    studentAssessmentStatus = StudentAssessmentStartedStatus.Completed;
  } else if (hasBeenStarted) {
    studentAssessmentStatus = StudentAssessmentStartedStatus.InProgress;
  }

  const ptState = getStudentPracticeTestState({
    published,
    isBeforeDue,
    isCloseToPracticeTestDue,
    allTopicsInCheckpoint3,
    allVisibleTopicsInCheckpoint3,
    allAssessmentsCompleted,
    studentAssessmentStatus,
  });

  const [showExplain, setShowExplain] = useState(![StudentPTState.PTCompletedOrPastDue, StudentPTState.PTInProgress, StudentPTState.PTAvailable].includes(ptState));

  const selectedEnrichedStudentStudyPath = useAppSelector(retrieveEnrichedActiveStudyPath);
  const { setStudentAppState } = useContext(StudentAppContext);

  const launchPracticeTest = () => {
    setShowPracticeTestModal(true);
    setStudentAppState((prev) => ({ ...prev, modalIsOpen: true }));
  };

  const handleCloseSPPTAT = async () => {
    // refresh studentStudyPath data on SPPTAT close to get latest attempt data
    await dispatch(getStudentStudyPathData(selectedSummativeId, enrollmentId));
    setShowPracticeTestModal(false);
    setStudentAppState((prev) => ({ ...prev, modalIsOpen: false }));
    setLaunchInto(undefined); // this is intentional, removes `launchInto` from the URL queries
  };

  const renderIcon = () => {
    switch (ptState) {
      case StudentPTState.PTReadyUnpublished:
      case StudentPTState.PTPastDueUnpublished:
      case StudentPTState.AssignmentsNotCompleted:
        return (
          <div className="empty-column-icon">
            <div><GiPartyPopper/></div>
          </div>
        );
      case StudentPTState.PTCompletedOrPastDue:
        return (
          <div className="empty-column-icon">
            <Icon which={IconEnum.Test} />
            {`${latestCorrectQuestionCount}/${totalQuestions}`}
          </div>
        );
      case StudentPTState.PTInProgress:
      case StudentPTState.PTAvailable:
        return (
          <div className="empty-column-icon">
            <Icon which={IconEnum.Test} />
          </div>
        );
      case StudentPTState.CardsNotMoved:
        return (
          <div className="empty-column-icon">
            <Icon which={IconEnum.Pencil} />
          </div>
        );
      // no default
    }
  };

  const renderMeter = () => {
    if ([StudentPTState.PTPastDueUnpublished, StudentPTState.PTCompletedOrPastDue, StudentPTState.PTInProgress].includes(ptState)) {
      return <></>;
    }
    return (
      <div className="column-content__progress">
        <ProgressBar
          percentage={percentage}
          color={styles.practiceTest}
          displayPercentage
        />
      </div>
    );
  };

  const renderColumnInfo = () => {
    switch (ptState) {
      case StudentPTState.PTReadyUnpublished:
      case StudentPTState.PTPastDueUnpublished:
        return <></>;
      case StudentPTState.PTCompletedOrPastDue:
        const practiceTestData = derivePracticeTestData(selectedEnrichedStudentStudyPath);
        const { learningObjectives = [] } = practiceTestData || {};
        // Get qs from LOs and get question subsets for display in nav groups
        const allQuestions = getAllAssessmentQuestionsFromEnrichedSTCLOs(learningObjectives);
        const { todoQuestions = [], pastQuestions = [] } = filterAndEnrichTodoPastQuestions(allQuestions, CheckpointColumn.Test, learningObjectives, studentAssessments, simpleAqms);
        if (!practiceTestData || ![...todoQuestions, ...pastQuestions].length) {
          console.info('Questions not yet loaded', allQuestions, todoQuestions, pastQuestions);
          return <Spinner />;
        }
        const { l8ySessionId } = practiceTestData;
        return (
          <>
            <div className="score-description">
              <b>{studentDashboardStrings.PRACTICE_TEST_SCORE_DESCRIPTION}</b>
            </div>
            <div className="all-questions-answered__text">
              Expand each topic below to get more practice with questions related to the ones you missed on the Practice Test (PT).<br/>
            </div>
            <b className="or">OR</b>
            <BetterButton
              onClick={launchPracticeTest}
              className="all-questions-answered__open-spptat"
              text="Review Practice Test Questions"
              secondary
            />
            <BetterModal isShowing={showPracticeTestModal} hide={handleCloseSPPTAT}>
              <StudyPathPracticeTestAssessmentTaker
                handleDone={handleCloseSPPTAT}
                l8ySessionId={l8ySessionId}
                learningObjectives={learningObjectives}
                location={AssessmentLocation.SPPTAT}
                todoQuestions={todoQuestions}
                pastQuestions={pastQuestions}
                userId={userId}
              />
            </BetterModal>
          </>
        );
      case StudentPTState.PTInProgress:
        return (
          <>
            <div className="h3">Practice Test is in progress</div>
            <BetterButton
              linkTo={`${StudentCoursePath.AssessmentTaker}/${practiceAssessmentId}`}
              text="Resume Practice Test"
              className="practice-test-launch"
              primary
            />
          </>
        );
      case StudentPTState.PTAvailable:
        return (
          <>
            <div className="h3">Practice Test is now available!</div>
            <BetterButton
              linkTo={`${StudentCoursePath.AssessmentTaker}/${practiceAssessmentId}`}
              text="Take Practice Test Now"
              className="practice-test-launch"
              primary
            />
          </>
        );
      case StudentPTState.CardsNotMoved:
      case StudentPTState.AssignmentsNotCompleted:
        return (
          <div className="h3">Unlock the Practice Test</div>
        );
      // no default
    }
  };

  const renderExplain = () => {
    if (!showExplain) {
      return null;
    }
    switch (ptState) {
      case StudentPTState.PTReadyUnpublished:
      case StudentPTState.PTPastDueUnpublished:
        return (
          <div className="practice-text-explain">
            Woo hoo! Repeated practice produces learning.
            <br /><br />
            Your instructor has not yet published the Practice Test, but you will be able to start it as soon as they do. In the meantime, review topics where you marked questions muddy!
          </div>
        );
      case StudentPTState.PTCompletedOrPastDue:
        return <></>;
      case StudentPTState.PTInProgress:
      case StudentPTState.PTAvailable:
      case StudentPTState.CardsNotMoved:
        return (
          <div className="practice-text-explain">
            See if you're ready for the real thing by taking a Practice Test.
            <br /><br />
            After you've answered all the Prep Questions for a topic, the topic will move to this checkpoint. Once you've moved all your topics into Checkpoint 3, you can take the Practice Test.
          </div>
        );
      case StudentPTState.AssignmentsNotCompleted:
        return (
          <div className="practice-text-explain">
            You've moved all your topics into Checkpoint&nbsp;3, but there are assignments you haven't completed yet.
            Visit the <a href={StudentCoursePath.Assignments}>Assignments</a> page to see what's next. Or {externalLink(CodonUrls.PracticeTestLockingExplanation, 'learn more')}.
          </div>
        );
      // no default
    }
  };

  const renderShowExplainTrigger = () => {
    if (ptState === StudentPTState.PTCompletedOrPastDue) {
      return <></>;
    }
    return (
      <div
        className="show-explain__trigger"
        data-expanded={showExplain}
        style={{ visibility: 'visible' }}
      >
        <button
          className={showExplain ? 'active' : ''}
          onClick={() => setShowExplain(!showExplain)}
          style={{ fill: showExplain ? styles.blue : styles.practiceTest, cursor: 'pointer' }}
        >
          {showExplain ? 'Hide' : 'Learn More'}
        </button>
      </div>
    );
  };

  return (
    <div className="empty-column empty-column-test test-column-info">
      {renderIcon()}
      <div className="empty-column-content">
        {renderMeter()}
        {renderColumnInfo()}
        {renderExplain()}
        {renderShowExplainTrigger()}
      </div>
    </div>
  );
};

TestColumnInfoCard.propTypes = {
  percentage: PropTypes.number.isRequired,
  selectedSummativeId: PropTypes.string,
  summaryInfo: PropTypes.shape({
    checkpointZeroHasTopics: PropTypes.bool.isRequired,
    checkpointOneHasTopics: PropTypes.bool.isRequired,
    checkpointTwoHasTopics: PropTypes.bool.isRequired,
    checkpointThreeHasTopics: PropTypes.bool.isRequired,
    allAssessmentsCompleted: PropTypes.bool.isRequired,
  }).isRequired,
};

TestColumnInfoCard.defaultProps = {
  percentage: null,
};

export default TestColumnInfoCard;
