import { createSelector } from '@reduxjs/toolkit';
import cloneDeep from 'lodash-es/cloneDeep';

import { TopicApi } from 'types/backend/topics.types';
import { Store } from 'types/store.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';

export interface EnrichedActiveLearningObjective extends LearningObjectiveApi {
  _derived: {
    topic: TopicApi
  }
}

export default createSelector(
  (store: Store) => store.active.templateLearningObjectives,
  (store: Store) => store.active.topics,
  (templateLearningObjectives, topics) => {
    templateLearningObjectives = cloneDeep(templateLearningObjectives);

    //add the full topic info to the LO
    const enrichedTemplateLearningObjectives = templateLearningObjectives.map((tlo: LearningObjectiveApi) => {
      const topic = topics.find((t) => t.id === tlo.topicId) as TopicApi;
      const enrichedLo = {
        ...tlo,
        _derived: { topic },
      };
      return enrichedLo;
    });

    return enrichedTemplateLearningObjectives as Array<EnrichedActiveLearningObjective>;
  }
);
