import { AssessmentWithEnrollment } from 'store/selectors/retrieveAssessmentsWithEnrollment';
import { EnrollmentApi } from 'types/backend/enrollments.types';
import { GenericObject } from 'types/backend/shared.types';
import {
  AttemptsHash,
  ClarityHash,
  CorrectHash,
  MultipleAttemptPolicyEnum,
  PointsHash,
  RecapHash,
  VatFrozenHash,
} from 'types/common.types';
import { AssessmentControllerQuestion } from 'utils/getAssessmentControllerQuestions';

export enum Steps {
  Intro = 'Intro',
  Assessment = 'Assessment',
  Outro = 'Outro',
}

export const PageStates: GenericObject = {
  [Steps.Intro]: {
    next: Steps.Assessment,
  },
  [Steps.Assessment]: {
    prev: Steps.Assessment,
    next: Steps.Outro,
  },
  [Steps.Outro]: {
    prev: Steps.Assessment,
  },
};

export enum AssessmentTakerQuestionStage {
  INIT = 'INIT',
  ANSWER_CHANGED = 'ANSWER_CHANGED',
  CLARITY_SELECTED = 'CLARITY_SELECTED',
  VALIDATED_CORRECT = 'VALIDATED_CORRECT',
  VALIDATED_INCORRECT = 'VALIDATED_INCORRECT',
}

export interface AssessmentTakerBaseProps {
  assessmentData: AssessmentWithEnrollment
  attemptPolicy: MultipleAttemptPolicyEnum
  attemptsHash: AttemptsHash
  clarityHash: ClarityHash
  correctHash: CorrectHash
  everCorrectHash: CorrectHash
  l8ySessionId: string
  latePointsDeductedHash: PointsHash
  pointsHash: PointsHash
  questions: Array<AssessmentControllerQuestion>
  recapHash: RecapHash
  studentAssessmentId: number
  userId: string
  vatFrozenHash: VatFrozenHash
}

export interface AssessmentTakerProps extends AssessmentTakerBaseProps {
  correctQuestionCount: number
  courseId: string
  currentStep: Steps
  dueString: string
  earnedPoints: number
  enrollmentData: EnrollmentApi
  handleReviewAssessment: () => Promise<void>
  handleStartSession: () => Promise<void>
  totalLatePointsDeducted: number
  totalPoints: number
  totalQuestions: number
  unansweredQuestionCount: number
}
