/*
 This selector returns the full topic object for all topics that are connected to classSessions in the course
*/
import { createSelector } from '@reduxjs/toolkit';
import unionBy from 'lodash-es/unionBy';
import { Store } from 'types/store.types';
import { ClassSessionTopicApi } from 'types/backend/classSessionTopics.types';
import { TopicApi } from 'types/backend/topics.types';

export default createSelector(
  (store: Store) => store.active.classSessionTopics,
  (store: Store) => store.active.topics,
  (classSessionTopics: Array<ClassSessionTopicApi>, topics: Array<TopicApi>) => {

    const uniqueCsts: Array<ClassSessionTopicApi> = classSessionTopics.reduce((
      reduced: Array<ClassSessionTopicApi>,
      cst: ClassSessionTopicApi
    ) => unionBy(reduced, [cst], 'topicId'), []);
    const courseTopics = uniqueCsts.map(u => topics.find(t => t.id === u.topicId) as TopicApi);

    return courseTopics;
  });

