import { CheckpointColumn } from 'types/backend/shared.types';
import { TopicApi } from 'types/backend/topics.types';
import {
  StudentStudyPathApi,
  StudentTopicCardForStudyPath,
  StudentTopicCardLearningObjective,
  StudentTopicCardLearningObjectiveAssessmentQuestion,
} from 'types/backend/studentStudyPaths.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';

export interface EnrichedStudentTopicCard extends StudentTopicCardForStudyPath {
  topic: TopicApi
  learningObjectives: Array<EnrichedStudentTopicCardLearningObjective>
}

export interface EnrichedStudentStudyPath extends StudentStudyPathApi {
  studentTopicCardCheckpoints: EnrichedStudentTopicCardCheckpoints
  summaryInfo: StudyPathSummaryInfo
}

export interface EnrichedStudentTopicCardCheckpoints {
  [CheckpointColumn.Hidden]: Array<EnrichedStudentTopicCard>
  [CheckpointColumn.Review]: Array<EnrichedStudentTopicCard>
  [CheckpointColumn.Prep]: Array<EnrichedStudentTopicCard>
  [CheckpointColumn.Test]: Array<EnrichedStudentTopicCard>
}

export interface EnrichedStudentTopicCardLearningObjective extends StudentTopicCardLearningObjective {
  title: string
  courseWideSort: number
  stringId: string
  loNumber: string
  topicName: string
}

export interface EnrichedLearningObjective extends LearningObjectiveApi {
  title: string
  courseWideSort: number
  loNumber: string
  assessmentQuestions: Array<StudentTopicCardLearningObjectiveAssessmentQuestion>
}

export interface StudyPathSummaryInfo {
  checkpointZeroHasTopics: boolean
  checkpointOneHasTopics: boolean
  checkpointTwoHasTopics: boolean
  checkpointThreeHasTopics: boolean
  allAssessmentsCompleted: boolean
  availableLOs: number
  totalLOs: number
  questionsForRecapture: number
  reviewedTopics: number
  testMeTopics: number
  totalTopics: number
  totalPrepQuestions: number
  correctPrepQuestions: number
  muddyCount: number
}

export interface HandleCardActionData {
  studentTopicCardId: number
  topicCardId: number
  checkpoint: CheckpointColumn
}

export enum EnrichedStudentTopicCardCheckpointStateEnum {
  ActiveWithQuestions = 'active-with-questions',
  ActiveWithoutQuestions = 'active-without-questions',
  Complete = 'complete',
  NotStarted = 'not-started',
}

export enum StudyPathLaunch {
  PracticeTestReview = 'ptreview',
}

export enum StudentPTState {
  PTReadyUnpublished = 'pt-ready-unpublished', // the student could take the PT if it was published
  PTPastDueUnpublished = 'pt-past-due-unpublished',
  PTCompletedOrPastDue = 'pt-completed-or-past-due',
  PTInProgress = 'pt-in-progress',
  PTAvailable = 'pt-available',
  CardsNotMoved = 'cards-not-moved',
  AssignmentsNotCompleted = 'assignments-not-completed'
}

export enum StudentAssessmentStartedStatus {
  NotStarted = 'not-started',
  InProgress = 'in-progress',
  Completed = 'completed'
}
