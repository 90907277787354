/**
 * retrieveBetterClassSessions
 *
 * This is intended as an eventual replacement for retrieveEnrichedClassSessions and retrieveActiveClassSessions
 *  - gets rid of classSession._derived.topics[]._derived.learningObjectives[]._derived
 *  - More useful data at the top level
 *  - calculate weekNumber for display around the app instead of in situ
 *  - add groupedAssessmentIds which breaks down assessments by types
 *  - add questionCounts, a hashmap of class session assessment ids with their covered LO question count
 **/
import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import uniqBy from 'lodash-es/uniqBy';

import {
  calculateWeekNumber,
  groupAssessmentsByType,
  assessmentIdsByType,
  getAssessmentsDueBetweenClassSessions,
} from 'utils/classSessions';
import retrieveActiveCourseLearningObjectives, { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import retrieveEnrichedClassSessions, { EnrichedClassSession } from 'store/selectors/retrieveEnrichedClassSessions';
import retrieveActiveAssessmentQuestionMaps from 'store/selectors/retrieveActiveAssessmentQuestionMaps';
import retrieveAssessmentsWithEnrollment, { AssessmentWithEnrollment } from './retrieveAssessmentsWithEnrollment';
import retrieveEnrichedIclrs from './retrieveEnrichedIclrs';
import retrieveEnrichedOoclrs from './retrieveEnrichedOoclrs';
import { EnrichedClassSessionClr, MappedAssessmentQuestion } from 'types/common.types';
import { Store } from 'types/store.types';
import { ClassSessionTopicApi } from 'types/backend/classSessionTopics.types';
import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { ClassSessionApi } from 'types/backend/classSessions.types';
import { TopicApi } from 'types/backend/topics.types';

export interface ClassSessionsWithTopic {
  classDate: string
  classSessionId: number
  topicId: number
}

export interface BetterClassSessionTopic extends TopicApi {
  classSessionTopicId: number
  topicId: number
  classSessionsWithTopic: Array<ClassSessionsWithTopic>
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
}
export interface BetterClassSession extends ClassSessionApi {
  allCourseLearningObjectiveIds: Array<number>
  classSessionAssessmentIds: Array<string>
  assessments: Array<AssessmentWithEnrollment>
  assessmentsBeforeNextClass: Array<AssessmentWithEnrollment>
  groupedAssessments: { [key in AssessTypeEnum]?: Array<AssessmentWithEnrollment>}
  groupedAssessmentIds: { [key in AssessTypeEnum]?: Array<string>}
  luxonDate: DateTime
  weekNumber: number
  topicsCoveredByAssessmentIds: { [key: string]: Array<{ topicId: number; topicName: string }>}
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
  topics: Array<BetterClassSessionTopic>
  iclrs: Array<EnrichedClassSessionClr>
  ooclrs: Array<EnrichedClassSessionClr>
}

const getTopicsCoveredByAssessmentIds = (assessments: Array<AssessmentWithEnrollment>, assessmentQuestionMaps: Array<MappedAssessmentQuestion>) => {
  // there is possibly a way to do with with fewer loops
  return assessments.reduce((acc, cur) => {
    const mapData = assessmentQuestionMaps.filter(({ assessmentId }) => assessmentId === cur.id);
    const topicsCovered = mapData.map(({ _derived: { courseLearningObjectives } }) => {
      return courseLearningObjectives.map(({ topic: { id: topicId, name: topicName } }) => ({
        topicId,
        topicName,
      }));
    }).flat();
    return {
      ...acc,
      [cur.id]: uniqBy(topicsCovered, 'topicId'),
    };
  }, {} as { [key: string]: Array<{ topicId: number; topicName: string }>});
};


// build a topics array for each classSession that has topics and an LO array in each topic
// this will be built from classSessionTopics
const enrichClassSessionTopics = (
  classSessionId: number,
  classSessionTopics: Array<ClassSessionTopicApi>,
  enrichedClassSessions: Array<EnrichedClassSession>,
  topics: Array<TopicApi>,
  classSessionCourseLearningObjectives: Array<EnrichedCourseLearningObjective>
) => classSessionTopics.reduce((acc: Array<BetterClassSessionTopic>, classSessionTopic) => {
  if (classSessionTopic.classSessionId === classSessionId) {

    // we need to know which other class sessions have this same topic
    const classSessionsWithTopic = enrichedClassSessions.reduce((csts: Array<ClassSessionsWithTopic>, { id, classDate, learningObjectives }) => {
      const topicsFromLos = learningObjectives.map(({ topicId }) => topicId);
      return topicsFromLos.includes(classSessionTopic.topicId)
        ? [...csts, { classSessionId: id, classDate, topicId: classSessionTopic.topicId }]
        : csts;
    }, []);

    const fullTopic = topics.find(({ id: fullTopicId }) => fullTopicId === classSessionTopic.topicId);
    const filteredCourseLearningObjectives = classSessionCourseLearningObjectives.filter((clo: ClassSessionLearningObjectiveApi) => clo.topicId === classSessionTopic.topicId);
    return fullTopic ? [
      ...acc,
      {
        ...fullTopic,
        courseLearningObjectives: filteredCourseLearningObjectives,
        classSessionTopicId: classSessionTopic.id,
        classSessionsWithTopic,
        topicId: fullTopic.id,
      },
    ] : acc;
  }
  return acc;
}, []);

export default createSelector(
  retrieveEnrichedClassSessions,
  retrieveActiveCourseLearningObjectives,
  retrieveEnrichedIclrs,
  retrieveEnrichedOoclrs,
  retrieveActiveAssessmentQuestionMaps,
  retrieveAssessmentsWithEnrollment,
  (store: Store) => store.active.classSessionTopics,
  (store: Store) => store.active.topics,
  (store: Store) => store.active.classSessionLearningObjectives,
  (
    enrichedClassSessions: Array<EnrichedClassSession>,
    activeCourseLearningObjectives,
    iclrs,
    ooclrs,
    assessmentQuestionMaps,
    assessmentsWithEnrollment,
    classSessionTopics,
    topics,
    classSessionLearningObjectives
  ) => {
    if (!enrichedClassSessions.length) {
      return [];
    }
    // Get assessments due BEFORE FIRST class session
    const [{ classDate: firstClassDate }] = enrichedClassSessions;
    const firstClassDateStartOfDay = DateTime.fromISO(firstClassDate).startOf('day');
    const dueBeforeFirstClassSession = assessmentsWithEnrollment.filter(({ mergedDueDate }) => DateTime.fromISO(mergedDueDate).startOf('day') < firstClassDateStartOfDay);
    // Get assessments due AFTER LAST class session
    const { classDate: lastClassDate } = [...enrichedClassSessions].pop() as EnrichedClassSession;
    const lastClassDateStartOfDay = DateTime.fromISO(lastClassDate).startOf('day');
    const dueAfterLastClassSession = assessmentsWithEnrollment.filter(({ mergedDueDate }) => lastClassDateStartOfDay < DateTime.fromISO(mergedDueDate).startOf('day'));
    const startWeekNumber = DateTime.fromISO(firstClassDate).weekNumber;
    const betterClassSessions = enrichedClassSessions.map((classSession, idx) => {
      const { classDate, id: classSessionId, learningObjectives } = classSession;
      const luxonDate = DateTime.fromISO(classDate);
      const classSessionLearningObjectiveIds = learningObjectives.map(({ id }) => id);
      const activeCourseLearningObjectivesForClassSessionLos = activeCourseLearningObjectives.filter(({ id }) => classSessionLearningObjectiveIds.includes(id));

      const cslosForClassSession = classSessionLearningObjectives.filter((cslo) => cslo.classSessionId === classSessionId);
      // HACK: get metadata from activeCourseLearningObjective LO to get correct loNumber, topicId, csloId
      // TODO: after CA-5317, this should no longer be necessary
      const activeCourseLosForClassSession = cslosForClassSession.reduce((acc: Array<EnrichedCourseLearningObjective>, cslo) => {
        // get the enriched active CLO
        const aclo = activeCourseLearningObjectivesForClassSessionLos.find((clo) => clo.learningObjectiveId === cslo.learningObjectiveId);
        if (!aclo) {
          // If this is not found then LOs were not loaded correctly, make sure LO topic exists and topic subject is in course
          console.error(`activeCourseLearningObjective not found for learningObjectiveId: ${cslo.learningObjectiveId}`, cslo);
          return acc;
        }
        acc.push({
          ...aclo,
          csloId: cslo.id,
          topicId: cslo.topicId,
          order: cslo.order,
          classSessionId,
        });
        return acc;
      }, []);

      // get assessments due today and due before next class session
      const classSessionAssessmentsByDueDate = assessmentsWithEnrollment.filter(({ mergedDueDate }) => DateTime.fromISO(mergedDueDate).hasSame(luxonDate, 'day'));
      const assessmentsBeforeNextClass = getAssessmentsDueBetweenClassSessions(assessmentsWithEnrollment, enrichedClassSessions, classSessionId);
      // This is here for backwards compatibility with ClassSessionDay
      const assessmentsThatCoverThisClassSession = assessmentsWithEnrollment.filter(({ classSessionIds }) => !!classSessionIds && classSessionIds.includes(classSessionId));

      let classSessionAssessments = [...classSessionAssessmentsByDueDate, ...assessmentsBeforeNextClass];
      if (classDate === firstClassDate) {
        // is first class session, assessments before this one should be added to this day
        classSessionAssessments = [...dueBeforeFirstClassSession, ...classSessionAssessments];
      }
      if (classDate === lastClassDate) {
        // if last class sessions, add all assessments due after last class session
        classSessionAssessments = [...classSessionAssessments, ...dueAfterLastClassSession];
      }
      // this could be more elegant, get all CLO ids from assessments
      const aqmsFromAssessments = classSessionAssessments.reduce((acc, cur) => {
        const foundAqm = assessmentQuestionMaps.find(({ assessmentId }) => assessmentId === cur.id);
        return !!foundAqm ? [...acc, foundAqm] : acc;
      }, [] as Array<MappedAssessmentQuestion>);
      const allLoIdsFromAssessments = aqmsFromAssessments.reduce((acc, cur) => {
        const { _derived: { courseLearningObjectives = [] } } = cur || { _derived: {} };
        if (!!courseLearningObjectives.length) {
          const cloIds = courseLearningObjectives.map(({ id }) => id);
          return [
            ...acc,
            ...cloIds,
          ];
        }
        return acc;
      }, [] as Array<number>);
      const allCourseLearningObjectiveIds = [...new Set([...classSessionLearningObjectiveIds, ...allLoIdsFromAssessments])];
      // group assessments by assessType for displaying on class sessions
      const groupedAssessments = groupAssessmentsByType(classSessionAssessments);
      const groupedAssessmentIds = assessmentIdsByType(classSessionAssessments);
      const enrichedClassSessionTopics = enrichClassSessionTopics(classSessionId, classSessionTopics, enrichedClassSessions, topics, activeCourseLosForClassSession);

      return {
        ...classSession,
        courseLearningObjectives: activeCourseLosForClassSession,
        allCourseLearningObjectiveIds,
        classSessionAssessmentIds: classSessionAssessments.map(({ id }) => id),
        assessments: assessmentsThatCoverThisClassSession, // might want to replace this with allAssessments
        assessmentsBeforeNextClass,
        groupedAssessments,
        groupedAssessmentIds,
        topicsCoveredByAssessmentIds: getTopicsCoveredByAssessmentIds(classSessionAssessments, assessmentQuestionMaps),
        iclrs: iclrs.filter(iclr => iclr.classSessionId === classSessionId),
        luxonDate,
        ooclrs: ooclrs.filter(ooclr => ooclr.classSessionId === classSessionId),
        topics: enrichedClassSessionTopics,
        weekNumber: calculateWeekNumber(luxonDate, startWeekNumber),
      } as BetterClassSession;
    });

    return betterClassSessions;
  }
);
