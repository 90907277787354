import React from 'react';
import './ButtonRadioToggle.scss';

interface ButtonRadioToggleItem {
  label: string
  id: string
  onClick: () => void
  show?: boolean
}

const ButtonRadioToggle = ({
  className,
  items,
  name,
  onChange,
  selectedItemId,
}: {
  className: string
  items: Array<ButtonRadioToggleItem>
  name: string
  onChange: (itemId: string) => void
  selectedItemId: string
}) => {
  return (
    <div className={`button-radio-toggle ${className}`}>
      <ul>
        {items.map(({ id, label, show = true }) => show && (
          <li className="button-radio-toggle__item" data-checked={selectedItemId === id} key={label}>
            <label htmlFor={id}>
              <input
                type="radio"
                name={name}
                id={id}
                checked={selectedItemId === id}
                onChange={(e) => onChange(id)}
              />
              <span className="button-radio-toggle__label">
                {label}
              </span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ButtonRadioToggle;
