/* eslint-disable jsx-a11y/no-autofocus */
import React, { Dispatch, SetStateAction } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import BetterButton from 'shared-components/BetterButton/BetterButton';
import ConfirmationPromptContainer from 'shared-components/ConfirmationPrompt/ConfirmationPromptContainer';
import TextButton from 'shared-components/BetterButton/TextButton';
import sharedStrings from 'sharedStrings';
import confirmationMsgs from '../AssessmentBuilderController/AssessmentBuilderConfirmationMessages';
import { ConfirmationTypeEnum } from 'types/common.types';

export enum DueDateConfirmationStepEnum {
  ExtendDue = 'extendDue',
}

interface AssessmentListItemState {
  openDate: Date
  dueDate: Date
  lateDate: Date | null
  hasInvalidOpenDueDates: boolean
  hasInvalidDueLateDates: boolean
  isExtendingDueDate: boolean
  newDueDate: Date
  modalStep: DueDateConfirmationStepEnum | null
}

export default function ChangeDueDateConfirmationPrompt({
  assessmentListItemState,
  setDueDate,
  setNewDueDate,
  setHasInvalidDueLateDates,
  setIsExtendingDueDate,
  setConfirmationIsShowing,
  setModalStep,
}: {
  assessmentListItemState: AssessmentListItemState
  setDueDate: (dateVal: Date, invalidOpenDueDates: boolean, invalidDueLateDates: boolean) => void
  setNewDueDate: Dispatch<SetStateAction<Date>>
  setHasInvalidDueLateDates: Dispatch<SetStateAction<boolean>>
  setIsExtendingDueDate: Dispatch<SetStateAction<boolean>>
  setConfirmationIsShowing: Dispatch<SetStateAction<boolean>>
  setModalStep: Dispatch<SetStateAction<DueDateConfirmationStepEnum | null>>
}) {
  const { modalStep, newDueDate, dueDate, lateDate, hasInvalidOpenDueDates, hasInvalidDueLateDates } = assessmentListItemState;

  const closeModal = () => {
    setModalStep(null);
    setConfirmationIsShowing(false);
  };

  const updateDueDateAndCloseModal = () => {
    setDueDate(newDueDate, hasInvalidOpenDueDates, hasInvalidDueLateDates);
    closeModal();
  };

  const unsetDueDateAndCloseModal = () => {
    setNewDueDate(dueDate);
    setHasInvalidDueLateDates(!!lateDate ? dueDate >= lateDate : false);
    setDueDate(dueDate, hasInvalidOpenDueDates, hasInvalidDueLateDates);
    setIsExtendingDueDate(DateTime.fromJSDate(dueDate) >= DateTime.now());
    closeModal();
  };

  if (modalStep && modalStep === DueDateConfirmationStepEnum.ExtendDue) {
    return (
      <ConfirmationPromptContainer
        confirmationType={ConfirmationTypeEnum.Warn}
        title={sharedStrings.EXTEND_DUE_DATE_TITLE}
        handleCancel={() => unsetDueDateAndCloseModal()}
      >
        <div className="confirmation-modal__body">
          {confirmationMsgs.changeDueDateAfterStudentsStartedConfMessage}
        </div>
        <div className="confirmation-modal__button-bar">
          <BetterButton
            className="confirmation-button"
            primary
            data-dismiss="confirmation-modal"
            onClick={() => updateDueDateAndCloseModal()}
            text="OK"
            autoFocus
          />
          <TextButton
            className="confirmation-text-button"
            data-dismiss="confirmation-modal"
            onClick={() => unsetDueDateAndCloseModal()}
          >
            or Cancel
          </TextButton>
        </div>
      </ConfirmationPromptContainer>
    );
  } else {
    return <></>;
  }
}

ChangeDueDateConfirmationPrompt.propTypes = {
  assessmentListItemState: PropTypes.object.isRequired,
  setDueDate: PropTypes.func.isRequired,
  setNewDueDate: PropTypes.func.isRequired,
  setHasInvalidDueLateDates: PropTypes.func.isRequired,
  setIsExtendingDueDate: PropTypes.func.isRequired,
  setConfirmationIsShowing: PropTypes.func.isRequired,
  setModalStep: PropTypes.func.isRequired,
};
