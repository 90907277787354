import { MultipleAttemptPolicyEnum } from 'types/common.types';
import { LibraryTypeEnum, YesNo } from 'types/backend/shared.types';
import { EnrollmentAssessmentApi } from 'types/backend/enrollmentAssessments.types';
import { L8yQuestionType } from 'types/backend/l8y.types';
import { AssessTypeEnum, GradingPolicyEnum } from 'types/backend/assessments.types';
import { ActiveCombinedQuestion } from 'store/selectors/retrieveActiveCombinedQuestions';
import { QuestionApiOut } from 'types/backend/questions.types';

export enum TabEnum {
  Init = 'init',
  Creating = 'creating',
  Editing = 'editing',
  AssessmentReview = 'assessment-review',
  CreateQuestion = 'create-question',
  EditQuestion = 'edit-question',
  EditQuestionLos = 'edit-question-learning-objectives',
  SelectQuestions = 'select-questions',
  SelectPrepQuestions = 'select-prep-questions',
  SelectPracticeQuestions = 'select-practice-questions',
  EditAccommodations = 'edit-accommodations',
  EditSummativeAccommodations = 'edit-summative-accommodations',
  Exit = 'exit',
}

export type TabNavigatePayload = QuestionApiOut | ActiveCombinedQuestion;
export type TabNavigate = (tab: TabEnum, payload?: TabNavigatePayload) => void

export interface FormValues {
  assessType: AssessTypeEnum
  classSessionIds?: Array<number>
  dueDate: Date
  gradingPolicy: GradingPolicyEnum
  instructions: string
  isGradeSyncEnabled: boolean
  lateDate: Date | null
  latePenalty: number | null
  latePolicy: YesNo
  published: YesNo
  multipleAttemptPolicy: MultipleAttemptPolicyEnum
  name: string
  openDate: Date
  prep?: {
    id?: string
    gradingPolicy: GradingPolicyEnum
    freeAttempts: number | null
    pointPenalty: number | null
    isGradeSyncEnabled: boolean
    lateDate: Date | null
    latePenalty: number | null
    latePolicy: YesNo
    published: YesNo
  }
  practiceTest?: {
    id?: string
    gradingPolicy: GradingPolicyEnum
    freeAttempts: number | null
    pointPenalty: number | null
    isGradeSyncEnabled: boolean
    lateDate: Date | null
    latePenalty: number | null
    latePolicy: YesNo
    published: YesNo
  }
}

export interface EnrollmentAssessmentRow extends EnrollmentAssessmentApi {
  name: string
  lastName: string
}

export interface AssessmentQuestionForQuestionListSidebar extends ActiveCombinedQuestion {
  assessmentQuestionId: number
  order: number
  points: number
  isLocked: boolean
  learningObjectiveIds: Array<number>
}

export interface UneditableSelectedLearningObjective {
  id: number
  stringId: string
  title: string
  topicId: number
  type: LibraryTypeEnum
  _derived: {
    loNumber: string
  }
}

export interface UserFacingQuestionType {
  userText: string
  abbreviation: QuestionTypeAbbreviationEnum
  l8yTypes: Array<L8yQuestionType>
}

export enum QuestionTypeAbbreviationEnum {
  Fill = 'Fill',
  LabelHotspot = 'Label',
  MathAndGraphing = 'M&G',
  Match = 'Match',
  MultipleChoice = 'MC',
  Open = 'Open',
  Sequence = 'Seq',
  Sorting = 'Sort',
}

export enum QuestionActionEnum {
  AddQuestion = 'ADD_QUESTION',
  AddQuestionGroup = 'ADD_QUESTION_GROUP',
  AddQuestionGroupAfterConfirmation = 'ADD_QUESTION_GROUP_AFTER_CONFIRMATION',
  AddQuestionGroupAfterConfirmationWithoutLos = 'ADD_QUESTION_GROUP_AFTER_CONFIRMATION_WITHOUT_LOS',
  CopyQuestion = 'COPY_QUESTION',
  EditQuestionLearningObjectives = 'EDIT_QUESTION_LEARNING_OBJECTIVES',
  EditQuestion = 'EDIT_QUESTION',
  RemoveQuestionGroup = 'REMOVE_QUESTION_GROUP',
  RemoveQuestion = 'REMOVE_QUESTION',
  AdjustPoints = 'ADJUST_POINTS',
  MoveQuestionToAssessment = 'MOVE_QUESTION_TO_ASSESSMENT',
  AddQuestionWithoutLos = 'ADD_QUESTION_WITHOUT_LOS',
  AddQuestionWithLos = 'ADD_QUESTION_WITH_LOS',
  AddCslos = 'ADD_CSLOS', // This is used to add CSLOS to a question that has no aligned LOs, before the unaligned confirmation
  AddCslosAfterConfirmation = 'ADD_CSLOS_AFTER_CONFIRMATION', // This adds the CSLOs, after the unaligned question confirmation
}

export interface QuestionActionPayload {
  questionId: number
  assessmentId: string
  questionIds?: Array<number>
  points?: string
  destinationAssessmentId?: string
}

export type QuestionAction = (action: QuestionActionEnum, payload: QuestionActionPayload, isConfirmed?: boolean) => Promise<void>

export enum AssessmentWorkerChangeType {
  Dates = 'dates',
  Published = 'published'
}
